/* eslint-disable */
/* tslint:disable */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: string; output: string; }
  Email: { input: string; output: string; }
  LegacyPhone: { input: string; output: string; }
  UUID: { input: string; output: string; }
};

export type Address = {
  __typename?: 'Address';
  city: Maybe<Scalars['String']['output']>;
  cityPart: Maybe<Scalars['String']['output']>;
  descriptiveNumber: Maybe<Scalars['Int']['output']>;
  district: Maybe<Scalars['String']['output']>;
  extended: Maybe<SmartAddressExtendedInfo>;
  extendedV2: Maybe<SmartAddressSdkExtendedInfo>;
  gps: Maybe<Gps>;
  id: Scalars['Int']['output'];
  level: Maybe<Scalars['Int']['output']>;
  orientationNumber: Maybe<Scalars['Int']['output']>;
  orientationNumberCharacter: Maybe<Scalars['String']['output']>;
  region: Maybe<Scalars['String']['output']>;
  smartAddressId: Maybe<Scalars['Int']['output']>;
  street: Maybe<Scalars['String']['output']>;
  uniqueId: Scalars['String']['output'];
  zip: Maybe<Scalars['Int']['output']>;
};

export type AddressInput = {
  id: Scalars['Int']['input'];
  label: Scalars['String']['input'];
  level: Scalars['Int']['input'];
};

export type AdminProfile = {
  __typename?: 'AdminProfile';
  email: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Maybe<Scalars['String']['output']>;
  role: Array<UserRole>;
  userId: Maybe<Scalars['Int']['output']>;
  username: Maybe<Scalars['String']['output']>;
  uuid: Scalars['String']['output'];
};

export type AresData = {
  __typename?: 'AresData';
  firstname: Maybe<Scalars['String']['output']>;
  ico: Scalars['String']['output'];
  name: Scalars['String']['output'];
  smartAddressId: Scalars['Int']['output'];
  surname: Maybe<Scalars['String']['output']>;
  vatNumber: Maybe<Scalars['String']['output']>;
  vatPayer: Scalars['Boolean']['output'];
};

export type Article = {
  __typename?: 'Article';
  author: Scalars['String']['output'];
  category: ArticleCategoryListItem;
  id: Scalars['Int']['output'];
  image: Maybe<File>;
  imageAlt: Maybe<Scalars['String']['output']>;
  imageSource: Maybe<Scalars['String']['output']>;
  knowledgeBaseConceptTags: Array<Scalars['Int']['output']>;
  knowledgeBaseQuestionTags: Array<Scalars['Int']['output']>;
  metaDescription: Maybe<Scalars['String']['output']>;
  metaTitle: Scalars['String']['output'];
  perex: Scalars['String']['output'];
  publishedAt: Scalars['DateTime']['output'];
  redirectTo: Maybe<Scalars['String']['output']>;
  sections: Array<BlogSection>;
  seoName: Scalars['String']['output'];
  subVertical: Maybe<SubVertical>;
  tags: Array<Scalars['Int']['output']>;
  title: Scalars['String']['output'];
  titleSuffix: Maybe<Scalars['String']['output']>;
  vertical: Maybe<Vertical>;
};

export type ArticleCategory = {
  __typename?: 'ArticleCategory';
  articles: Maybe<Array<ArticlePreview>>;
  id: Scalars['Int']['output'];
  metaDescription: Scalars['String']['output'];
  metaImage: Maybe<File>;
  metaTitle: Scalars['String']['output'];
  name: Scalars['String']['output'];
  subVertical: SubVertical;
  titleSuffix: Maybe<Scalars['String']['output']>;
  vertical: Vertical;
};

export type ArticleCategoryListItem = {
  __typename?: 'ArticleCategoryListItem';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  subVertical: SubVertical;
  vertical: Vertical;
};

export type ArticlePreview = {
  __typename?: 'ArticlePreview';
  author: Scalars['String']['output'];
  category: ArticleCategoryListItem;
  id: Scalars['Int']['output'];
  image: Maybe<File>;
  imageAlt: Maybe<Scalars['String']['output']>;
  imageSource: Maybe<Scalars['String']['output']>;
  perex: Scalars['String']['output'];
  publishedAt: Scalars['DateTime']['output'];
  redirectTo: Maybe<Scalars['String']['output']>;
  seoName: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type ArticleTag = {
  __typename?: 'ArticleTag';
  articles: Maybe<Array<ArticlePreview>>;
  tagId: Scalars['Int']['output'];
  tagName: Scalars['String']['output'];
  tagSeoName: Scalars['String']['output'];
};

export type AuthResult = {
  __typename?: 'AuthResult';
  accessToken: Scalars['String']['output'];
  expires: Scalars['Int']['output'];
  refreshToken: Scalars['String']['output'];
};

export type AvailableRepaymentPeriodsInput = {
  amount?: InputMaybe<MoneyInput>;
  consolidationDebts: Array<FinanceConsolidationDebtInput>;
  extraLoan?: InputMaybe<MoneyInput>;
  mortgageAmount?: InputMaybe<MoneyInput>;
  mortgagePurpose?: InputMaybe<MortgagePurpose>;
  productPurpose?: InputMaybe<Scalars['Int']['input']>;
  refinancingRemainingAmount?: InputMaybe<MoneyInput>;
  subVertical: SubVertical;
};

export type BankInformationInput = {
  incomeType?: InputMaybe<CalculationIncomeType>;
  monthlyIncome?: InputMaybe<MonthlyIncomeBrackets>;
  otherLoans?: InputMaybe<Scalars['Boolean']['input']>;
  otherLoansInstallment?: InputMaybe<MoneyInput>;
};

export const BannerWithButtonType = {
  GreenThin: 'GREEN_THIN',
  YellowThin: 'YELLOW_THIN',
  YellowWolf: 'YELLOW_WOLF'
} as const;

export type BannerWithButtonType = typeof BannerWithButtonType[keyof typeof BannerWithButtonType];
export type Benefit = {
  __typename?: 'Benefit';
  buttonText: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  image: Maybe<File>;
  name: Maybe<Scalars['String']['output']>;
  perex: Maybe<Scalars['String']['output']>;
  profileBenefit: ProfileBenefit;
  template: BenefitTemplate;
  text: Maybe<Scalars['String']['output']>;
  uuid: Scalars['String']['output'];
  validFrom: Scalars['String']['output'];
  validTo: Scalars['String']['output'];
};

export type BenefitAttachment = {
  __typename?: 'BenefitAttachment';
  file: File;
  id: Scalars['Int']['output'];
};

export const BenefitTemplate = {
  BasicInfoForm: 'BASIC_INFO_FORM',
  FileUpload: 'FILE_UPLOAD',
  VehicleInsuranceForm: 'VEHICLE_INSURANCE_FORM'
} as const;

export type BenefitTemplate = typeof BenefitTemplate[keyof typeof BenefitTemplate];
export const BlogBannerWithButtonType = {
  Green: 'GREEN',
  Yellow: 'YELLOW'
} as const;

export type BlogBannerWithButtonType = typeof BlogBannerWithButtonType[keyof typeof BlogBannerWithButtonType];
export type BlogSection = BlogSectionBannerWithButton | BlogSectionBannerWithCallback | BlogSectionInternetSpeed | BlogSectionSetriCeleCesko | BlogSectionTable | BlogSectionText | BlogSectionVehicleInsuranceContractDismissalTemplateForm | BlogSectionYoutube;

export type BlogSectionBannerWithButton = {
  __typename?: 'BlogSectionBannerWithButton';
  buttonText: Scalars['String']['output'];
  buttonUrl: Scalars['String']['output'];
  campaign: Maybe<Campaign>;
  contentSerialized: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  order: Scalars['Int']['output'];
  sectionType: BlogSectionType;
  title: Scalars['String']['output'];
  type: BlogBannerWithButtonType;
};

export type BlogSectionBannerWithCallback = {
  __typename?: 'BlogSectionBannerWithCallback';
  buttonText: Scalars['String']['output'];
  campaign: Maybe<Campaign>;
  contentSerialized: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  order: Scalars['Int']['output'];
  sectionType: BlogSectionType;
  title: Scalars['String']['output'];
};

export type BlogSectionInternetSpeed = {
  __typename?: 'BlogSectionInternetSpeed';
  contentSerialized: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  order: Scalars['Int']['output'];
  sectionType: BlogSectionType;
  title: Scalars['String']['output'];
};

export type BlogSectionSetriCeleCesko = {
  __typename?: 'BlogSectionSetriCeleCesko';
  id: Scalars['Int']['output'];
  order: Scalars['Int']['output'];
  sectionType: BlogSectionType;
  setriCeleCesko: SetriCeleCeskoType;
};

export type BlogSectionTable = {
  __typename?: 'BlogSectionTable';
  columns: Scalars['Int']['output'];
  content: Array<Array<Scalars['String']['output']>>;
  id: Scalars['Int']['output'];
  order: Scalars['Int']['output'];
  rows: Scalars['Int']['output'];
  sectionType: BlogSectionType;
};

export type BlogSectionText = {
  __typename?: 'BlogSectionText';
  contentSerialized: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  order: Scalars['Int']['output'];
  sectionType: BlogSectionType;
};

export const BlogSectionType = {
  BannerWithButton: 'BANNER_WITH_BUTTON',
  BannerWithCallback: 'BANNER_WITH_CALLBACK',
  InternetSpeed: 'INTERNET_SPEED',
  SetriCeleCesko: 'SETRI_CELE_CESKO',
  Table: 'TABLE',
  Text: 'TEXT',
  VehicleInsuranceContractDismissalTemplateForm: 'VEHICLE_INSURANCE_CONTRACT_DISMISSAL_TEMPLATE_FORM',
  Youtube: 'YOUTUBE'
} as const;

export type BlogSectionType = typeof BlogSectionType[keyof typeof BlogSectionType];
export type BlogSectionVehicleInsuranceContractDismissalTemplateForm = {
  __typename?: 'BlogSectionVehicleInsuranceContractDismissalTemplateForm';
  contentSerialized: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  order: Scalars['Int']['output'];
  sectionType: BlogSectionType;
  title: Scalars['String']['output'];
};

export type BlogSectionYoutube = {
  __typename?: 'BlogSectionYoutube';
  id: Scalars['Int']['output'];
  order: Scalars['Int']['output'];
  sectionType: BlogSectionType;
  url: Scalars['String']['output'];
};

export const BuildingPrice = {
  Cheap: 'CHEAP',
  Expensive: 'EXPENSIVE',
  Medium: 'MEDIUM'
} as const;

export type BuildingPrice = typeof BuildingPrice[keyof typeof BuildingPrice];
export const BuildingSize = {
  ExtraLarge: 'EXTRA_LARGE',
  Large: 'LARGE',
  Medium: 'MEDIUM',
  Small: 'SMALL'
} as const;

export type BuildingSize = typeof BuildingSize[keyof typeof BuildingSize];
export const CacheControlScope = {
  Private: 'PRIVATE',
  Public: 'PUBLIC'
} as const;

export type CacheControlScope = typeof CacheControlScope[keyof typeof CacheControlScope];
export const CalculationIncomeType = {
  Employee: 'EMPLOYEE',
  MaternityLeave: 'MATERNITY_LEAVE',
  NoIncome: 'NO_INCOME',
  Other: 'OTHER',
  Pensioner: 'PENSIONER',
  SelfEmployed: 'SELF_EMPLOYED',
  Student: 'STUDENT'
} as const;

export type CalculationIncomeType = typeof CalculationIncomeType[keyof typeof CalculationIncomeType];
export const CalculationReasonEnum = {
  CurrentPartnerRaisedPriceOrEnded: 'CURRENT_PARTNER_RAISED_PRICE_OR_ENDED',
  Moving: 'MOVING',
  NewDistributionRateOrBreaker: 'NEW_DISTRIBUTION_RATE_OR_BREAKER',
  NewSupplyAddress: 'NEW_SUPPLY_ADDRESS',
  Other: 'OTHER',
  Saving: 'SAVING'
} as const;

export type CalculationReasonEnum = typeof CalculationReasonEnum[keyof typeof CalculationReasonEnum];
export type CalculationsByStatusesInput = {
  statuses: Array<EnergoBusinessCaseStatus>;
  type?: InputMaybe<EnergoCalculationType>;
};

export type Callback = {
  __typename?: 'Callback';
  id: Scalars['Int']['output'];
};

export const CallbackResponseTimeType = {
  AsSoonAsPossible: 'AS_SOON_AS_POSSIBLE',
  DayAfterTomorrow: 'DAY_AFTER_TOMORROW',
  TomorrowAfternoon: 'TOMORROW_AFTERNOON',
  TomorrowForenoon: 'TOMORROW_FORENOON'
} as const;

export type CallbackResponseTimeType = typeof CallbackResponseTimeType[keyof typeof CallbackResponseTimeType];
export const CallbackSourceType = {
  BackofficeRequest: 'BACKOFFICE_REQUEST',
  Blog: 'BLOG',
  CalculationErrorPage: 'CALCULATION_ERROR_PAGE',
  CannotScan: 'CANNOT_SCAN',
  CsasApiRefused: 'CSAS_API_REFUSED',
  EnergoCalculationRetention: 'ENERGO_CALCULATION_RETENTION',
  EnergoCalculationRetentionType: 'ENERGO_CALCULATION_RETENTION_TYPE',
  EnergoCalculationUnfinished: 'ENERGO_CALCULATION_UNFINISHED',
  EnergoContractResigned: 'ENERGO_CONTRACT_RESIGNED',
  EnergoResultsBanner: 'ENERGO_RESULTS_BANNER',
  EnergoResultsWatchWolfCallback: 'ENERGO_RESULTS_WATCH_WOLF_CALLBACK',
  FinanceCalculationResult: 'FINANCE_CALCULATION_RESULT',
  Floating: 'FLOATING',
  HotLead: 'HOT_LEAD',
  InvoiceWithoutData: 'INVOICE_WITHOUT_DATA',
  Popup: 'POPUP',
  PropertyInsuranceCalculation: 'PROPERTY_INSURANCE_CALCULATION',
  PropertyInsuranceMagicLinkCallback: 'PROPERTY_INSURANCE_MAGIC_LINK_CALLBACK',
  PropertyInsuranceOffer: 'PROPERTY_INSURANCE_OFFER',
  RecommendedTariffs: 'RECOMMENDED_TARIFFS',
  ResultItem: 'RESULT_ITEM',
  ReturnedToOffer: 'RETURNED_TO_OFFER',
  SalesmanSelfAssign: 'SALESMAN_SELF_ASSIGN',
  SourceExternalEnergieCz: 'SOURCE_EXTERNAL_ENERGIE_CZ',
  SupplementInsurance: 'SUPPLEMENT_INSURANCE',
  TarifDetail: 'TARIF_DETAIL',
  TelcoResultsBanner: 'TELCO_RESULTS_BANNER',
  TravelInsuranceContractError: 'TRAVEL_INSURANCE_CONTRACT_ERROR',
  TravelInsuranceOrder: 'TRAVEL_INSURANCE_ORDER',
  TravelInsuranceResultBanner: 'TRAVEL_INSURANCE_RESULT_BANNER',
  Unknown: 'UNKNOWN',
  UrgingDocument: 'URGING_DOCUMENT',
  UrgingSignature: 'URGING_SIGNATURE',
  VehicleInsuranceContractData: 'VEHICLE_INSURANCE_CONTRACT_DATA',
  VehicleInsuranceContractError: 'VEHICLE_INSURANCE_CONTRACT_ERROR',
  VehicleInsuranceFinishedOnline: 'VEHICLE_INSURANCE_FINISHED_ONLINE',
  VehicleInsuranceMagicLinkCallback: 'VEHICLE_INSURANCE_MAGIC_LINK_CALLBACK',
  VehicleInsuranceOffer: 'VEHICLE_INSURANCE_OFFER',
  VehicleInsuranceOrder: 'VEHICLE_INSURANCE_ORDER',
  VehicleInsuranceOrderError: 'VEHICLE_INSURANCE_ORDER_ERROR',
  VehicleInsuranceOrderSmartEmailing: 'VEHICLE_INSURANCE_ORDER_SMART_EMAILING',
  VehicleInsuranceWatchWolfSmartEmailing: 'VEHICLE_INSURANCE_WATCH_WOLF_SMART_EMAILING',
  WatchWolfNeedsRefine: 'WATCH_WOLF_NEEDS_REFINE'
} as const;

export type CallbackSourceType = typeof CallbackSourceType[keyof typeof CallbackSourceType];
export type CallbackZoneCallback = {
  __typename?: 'CallbackZoneCallback';
  id: Scalars['Int']['output'];
};

export type Campaign = {
  __typename?: 'Campaign';
  active: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
};

export type Category = {
  __typename?: 'Category';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  subVertical: SubVertical;
  vertical: Vertical;
};

export type CityPart = {
  __typename?: 'CityPart';
  cityId: Scalars['Int']['output'];
  cityName: Scalars['String']['output'];
  cityPartId: Scalars['Int']['output'];
  cityPartName: Scalars['String']['output'];
  zip: Scalars['String']['output'];
};

export type CityPartInput = {
  cityId: Scalars['Int']['input'];
  cityName: Scalars['String']['input'];
  cityPartId: Scalars['Int']['input'];
  cityPartName: Scalars['String']['input'];
  zip: Scalars['String']['input'];
};

export type CodeBook = {
  __typename?: 'CodeBook';
  id: Scalars['Int']['output'];
  label: Scalars['String']['output'];
  tooltipText: Maybe<Scalars['String']['output']>;
};

export type CodeBookInput = {
  id: Scalars['Int']['input'];
  label: Scalars['String']['input'];
};

export type Codebook = {
  __typename?: 'Codebook';
  id: Scalars['Int']['output'];
  label: Scalars['String']['output'];
};

export const ConsolidationDebtType = {
  CreditCard: 'CREDIT_CARD',
  Loan: 'LOAN',
  Other: 'OTHER',
  Overdraft: 'OVERDRAFT'
} as const;

export type ConsolidationDebtType = typeof ConsolidationDebtType[keyof typeof ConsolidationDebtType];
export const ConsumptionUnit = {
  MWh: 'MWh',
  KWh: 'kWh'
} as const;

export type ConsumptionUnit = typeof ConsumptionUnit[keyof typeof ConsumptionUnit];
export type ContentSection = ContentSectionBannerWithButton | ContentSectionBannerWithButtons | ContentSectionBannerWithCallbackButton | ContentSectionBlogArticles | ContentSectionContacts | ContentSectionElectricityPriceList | ContentSectionFinanceProducts | ContentSectionGasPriceList | ContentSectionInternetSpeed | ContentSectionJumbotron | ContentSectionKnowledgeBaseConcepts | ContentSectionKnowledgeBaseQuestions | ContentSectionPrimaryHeadline | ContentSectionTable | ContentSectionText | ContentSectionUsp | ContentSectionVehicleInsuranceContractDismissalDateForm;

export type ContentSectionBannerWithButton = {
  __typename?: 'ContentSectionBannerWithButton';
  buttonText: Scalars['String']['output'];
  buttonUrl: Scalars['String']['output'];
  campaign: Maybe<Campaign>;
  contentSerialized: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  order: Scalars['Int']['output'];
  sectionType: SectionType;
  title: Scalars['String']['output'];
  type: BannerWithButtonType;
};

export type ContentSectionBannerWithButtons = {
  __typename?: 'ContentSectionBannerWithButtons';
  buttons: Array<ContentSectionBannerWithButtonsButton>;
  id: Scalars['Int']['output'];
  order: Scalars['Int']['output'];
  sectionType: SectionType;
};

export type ContentSectionBannerWithButtonsButton = {
  __typename?: 'ContentSectionBannerWithButtonsButton';
  label: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type ContentSectionBannerWithCallbackButton = {
  __typename?: 'ContentSectionBannerWithCallbackButton';
  buttonText: Scalars['String']['output'];
  callbackSource: Maybe<Scalars['Int']['output']>;
  campaign: Maybe<Campaign>;
  contentSerialized: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  order: Scalars['Int']['output'];
  popupSubTitle: Scalars['String']['output'];
  popupTitle: Scalars['String']['output'];
  sectionType: SectionType;
  title: Scalars['String']['output'];
};

export type ContentSectionBlogArticles = {
  __typename?: 'ContentSectionBlogArticles';
  articles: Array<ArticlePreview>;
  id: Scalars['Int']['output'];
  order: Scalars['Int']['output'];
  sectionType: SectionType;
};

export type ContentSectionContacts = {
  __typename?: 'ContentSectionContacts';
  id: Scalars['Int']['output'];
  order: Scalars['Int']['output'];
  sectionType: SectionType;
};

export type ContentSectionElectricityPriceList = {
  __typename?: 'ContentSectionElectricityPriceList';
  id: Scalars['Int']['output'];
  order: Scalars['Int']['output'];
  prices: Array<EnergoElectricityPriceProductPreviewItem>;
  sectionType: SectionType;
};

export type ContentSectionFinanceProducts = {
  __typename?: 'ContentSectionFinanceProducts';
  id: Scalars['Int']['output'];
  order: Scalars['Int']['output'];
  productPurpose: Scalars['Int']['output'];
  productTypes: Array<FinanceProductType>;
  sectionType: SectionType;
  text: Maybe<Scalars['String']['output']>;
  titleWhite: Maybe<Scalars['String']['output']>;
  titleYellow: Maybe<Scalars['String']['output']>;
};

export type ContentSectionGasPriceList = {
  __typename?: 'ContentSectionGasPriceList';
  id: Scalars['Int']['output'];
  order: Scalars['Int']['output'];
  prices: Array<EnergoGasPriceProductPreviewItem>;
  sectionType: SectionType;
};

export type ContentSectionInternetSpeed = {
  __typename?: 'ContentSectionInternetSpeed';
  contentSerialized: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  order: Scalars['Int']['output'];
  sectionType: SectionType;
  title: Scalars['String']['output'];
};

export type ContentSectionJumbotron = {
  __typename?: 'ContentSectionJumbotron';
  buttonText: Maybe<Scalars['String']['output']>;
  buttonUrl: Maybe<Scalars['String']['output']>;
  buttons: Array<ContentSectionBannerWithButtonsButton>;
  callbackSource: Maybe<Scalars['Int']['output']>;
  campaign: Maybe<Campaign>;
  contentSerialized: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  metaDescriptionAsSubtitle: Scalars['Boolean']['output'];
  order: Scalars['Int']['output'];
  sectionType: SectionType;
  subtitle: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  type: JumbotronType;
};

export type ContentSectionKnowledgeBaseConcepts = {
  __typename?: 'ContentSectionKnowledgeBaseConcepts';
  id: Scalars['Int']['output'];
  order: Scalars['Int']['output'];
  questions: Array<KnowledgebaseQuestionPreview>;
  sectionType: SectionType;
  title: Maybe<Scalars['String']['output']>;
};

export type ContentSectionKnowledgeBaseQuestions = {
  __typename?: 'ContentSectionKnowledgeBaseQuestions';
  id: Scalars['Int']['output'];
  order: Scalars['Int']['output'];
  priorityQuestion1: Maybe<KnowledgebaseQuestionPreviewWithContent>;
  priorityQuestion2: Maybe<KnowledgebaseQuestionPreviewWithContent>;
  questions: Array<KnowledgebaseQuestionPreview>;
  sectionType: SectionType;
  title: Maybe<Scalars['String']['output']>;
};

export type ContentSectionPrimaryHeadline = {
  __typename?: 'ContentSectionPrimaryHeadline';
  id: Scalars['Int']['output'];
  order: Scalars['Int']['output'];
  primaryHeadline: Scalars['String']['output'];
  sectionType: SectionType;
};

export type ContentSectionTable = {
  __typename?: 'ContentSectionTable';
  columns: Scalars['Int']['output'];
  content: Array<Array<Scalars['String']['output']>>;
  id: Scalars['Int']['output'];
  order: Scalars['Int']['output'];
  rows: Scalars['Int']['output'];
  sectionType: SectionType;
};

export type ContentSectionText = {
  __typename?: 'ContentSectionText';
  contentSerialized: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  order: Scalars['Int']['output'];
  sectionType: SectionType;
};

export type ContentSectionUsp = {
  __typename?: 'ContentSectionUsp';
  id: Scalars['Int']['output'];
  order: Scalars['Int']['output'];
  sectionType: SectionType;
};

export type ContentSectionVehicleInsuranceContractDismissalDateForm = {
  __typename?: 'ContentSectionVehicleInsuranceContractDismissalDateForm';
  contentSerialized: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  order: Scalars['Int']['output'];
  sectionType: SectionType;
  title: Scalars['String']['output'];
};

export const CooperationMethodEnum = {
  Cooperating: 'COOPERATING',
  NotCooperating: 'NOT_COOPERATING'
} as const;

export type CooperationMethodEnum = typeof CooperationMethodEnum[keyof typeof CooperationMethodEnum];
export type CoreStatus = {
  __typename?: 'CoreStatus';
  success: Scalars['Boolean']['output'];
};

export type CreateCallbackInput = {
  energoCalculationUuid?: InputMaybe<Scalars['String']['input']>;
  energoLeadUuid?: InputMaybe<Scalars['String']['input']>;
  financeCalculationUuid?: InputMaybe<Scalars['String']['input']>;
  phone: Scalars['LegacyPhone']['input'];
  product?: InputMaybe<Scalars['String']['input']>;
  propertyInsuranceOrderUuid?: InputMaybe<Scalars['String']['input']>;
  responseTime: CallbackResponseTimeType;
  source?: InputMaybe<Scalars['Int']['input']>;
  sourceType?: InputMaybe<CallbackSourceType>;
  subVertical: SubVertical;
  telcoCalculationUuid?: InputMaybe<Scalars['String']['input']>;
  travelInsuranceOrderUuid?: InputMaybe<Scalars['String']['input']>;
  vehicleInsuranceOrderUuid?: InputMaybe<Scalars['String']['input']>;
  vertical: Vertical;
};

export type CreateEmptyEnergoWatchWolfInput = {
  email: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  subVertical: SubVertical;
};

export type CreateVehicleInsuranceCallbackInput = {
  orderUuid?: InputMaybe<Scalars['String']['input']>;
  phone: Scalars['String']['input'];
  product?: InputMaybe<Scalars['String']['input']>;
  responseTime: CallbackResponseTimeType;
  source: Scalars['String']['input'];
};

export type CreateWatchWolfInput = {
  addressId?: InputMaybe<Scalars['Int']['input']>;
  commitmentEnd?: InputMaybe<Scalars['String']['input']>;
  energoCalculationUuid?: InputMaybe<Scalars['String']['input']>;
  minSaving?: InputMaybe<Scalars['Int']['input']>;
  preferredEmail: Scalars['String']['input'];
  propertyInsuranceOrderUuid?: InputMaybe<Scalars['String']['input']>;
  registrationNumber?: InputMaybe<Scalars['String']['input']>;
  subVertical: SubVertical;
  vehicleInsuranceOrderUuid?: InputMaybe<Scalars['String']['input']>;
  vertical: Vertical;
  watchWolfType: WatchWolfType;
};

export const Currency = {
  Aed: 'AED',
  Afn: 'AFN',
  All: 'ALL',
  Amd: 'AMD',
  Ang: 'ANG',
  Aoa: 'AOA',
  Ars: 'ARS',
  Aud: 'AUD',
  Awg: 'AWG',
  Azn: 'AZN',
  Bam: 'BAM',
  Bbd: 'BBD',
  Bdt: 'BDT',
  Bgn: 'BGN',
  Bhd: 'BHD',
  Bif: 'BIF',
  Bmd: 'BMD',
  Bnd: 'BND',
  Bob: 'BOB',
  Bov: 'BOV',
  Brl: 'BRL',
  Bsd: 'BSD',
  Btn: 'BTN',
  Bwp: 'BWP',
  Byn: 'BYN',
  Bzd: 'BZD',
  Cad: 'CAD',
  Cdf: 'CDF',
  Che: 'CHE',
  Chf: 'CHF',
  Chw: 'CHW',
  Clf: 'CLF',
  Clp: 'CLP',
  Cny: 'CNY',
  Cop: 'COP',
  Cou: 'COU',
  Crc: 'CRC',
  Cuc: 'CUC',
  Cup: 'CUP',
  Cve: 'CVE',
  Czk: 'CZK',
  Djf: 'DJF',
  Dkk: 'DKK',
  Dop: 'DOP',
  Dzd: 'DZD',
  Egp: 'EGP',
  Ern: 'ERN',
  Etb: 'ETB',
  Eur: 'EUR',
  Fjd: 'FJD',
  Fkp: 'FKP',
  Gbp: 'GBP',
  Gel: 'GEL',
  Ghs: 'GHS',
  Gip: 'GIP',
  Gmd: 'GMD',
  Gnf: 'GNF',
  Gtq: 'GTQ',
  Gyd: 'GYD',
  Hkd: 'HKD',
  Hnl: 'HNL',
  Htg: 'HTG',
  Huf: 'HUF',
  Idr: 'IDR',
  Ils: 'ILS',
  Inr: 'INR',
  Iqd: 'IQD',
  Irr: 'IRR',
  Isk: 'ISK',
  Jmd: 'JMD',
  Jod: 'JOD',
  Jpy: 'JPY',
  Kes: 'KES',
  Kgs: 'KGS',
  Khr: 'KHR',
  Kmf: 'KMF',
  Kpw: 'KPW',
  Krw: 'KRW',
  Kwd: 'KWD',
  Kyd: 'KYD',
  Kzt: 'KZT',
  Lak: 'LAK',
  Lbp: 'LBP',
  Lkr: 'LKR',
  Lrd: 'LRD',
  Lsl: 'LSL',
  Lyd: 'LYD',
  Mad: 'MAD',
  Mdl: 'MDL',
  Mga: 'MGA',
  Mkd: 'MKD',
  Mmk: 'MMK',
  Mnt: 'MNT',
  Mop: 'MOP',
  Mru: 'MRU',
  Mur: 'MUR',
  Mvr: 'MVR',
  Mwk: 'MWK',
  Mxn: 'MXN',
  Mxv: 'MXV',
  Myr: 'MYR',
  Mzn: 'MZN',
  Nad: 'NAD',
  Ngn: 'NGN',
  Nio: 'NIO',
  Nok: 'NOK',
  Npr: 'NPR',
  Nzd: 'NZD',
  Omr: 'OMR',
  Pab: 'PAB',
  Pen: 'PEN',
  Pgk: 'PGK',
  Php: 'PHP',
  Pkr: 'PKR',
  Pln: 'PLN',
  Pyg: 'PYG',
  Qar: 'QAR',
  Ron: 'RON',
  Rsd: 'RSD',
  Rub: 'RUB',
  Rwf: 'RWF',
  Sar: 'SAR',
  Sbd: 'SBD',
  Scr: 'SCR',
  Sdg: 'SDG',
  Sek: 'SEK',
  Sgd: 'SGD',
  Shp: 'SHP',
  Sle: 'SLE',
  Sll: 'SLL',
  Sos: 'SOS',
  Srd: 'SRD',
  Ssp: 'SSP',
  Stn: 'STN',
  Svc: 'SVC',
  Syp: 'SYP',
  Szl: 'SZL',
  Thb: 'THB',
  Tjs: 'TJS',
  Tmt: 'TMT',
  Tnd: 'TND',
  Top: 'TOP',
  Try: 'TRY',
  Ttd: 'TTD',
  Twd: 'TWD',
  Tzs: 'TZS',
  Uah: 'UAH',
  Ugx: 'UGX',
  Usd: 'USD',
  Usn: 'USN',
  Uyi: 'UYI',
  Uyu: 'UYU',
  Uyw: 'UYW',
  Uzs: 'UZS',
  Ved: 'VED',
  Ves: 'VES',
  Vnd: 'VND',
  Vuv: 'VUV',
  Wst: 'WST',
  Xaf: 'XAF',
  Xcd: 'XCD',
  Xof: 'XOF',
  Xpf: 'XPF',
  Yer: 'YER',
  Zar: 'ZAR',
  Zmw: 'ZMW',
  Zwl: 'ZWL'
} as const;

export type Currency = typeof Currency[keyof typeof Currency];
export type CustomAddress = {
  __typename?: 'CustomAddress';
  address: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  zipCode: Scalars['String']['output'];
};

export type CustomerZoneCalculationDetail = {
  __typename?: 'CustomerZoneCalculationDetail';
  address: Maybe<Address>;
  adminLastChangeAt: Maybe<Scalars['DateTime']['output']>;
  adminLastChangeBy: Maybe<ProfileUser>;
  bestGasResultItem: Maybe<EnergoGasResultItem>;
  bestResultItem: Maybe<EnergoElectricityResultItem>;
  breaker: Maybe<EnergoBreaker>;
  createdAt: Scalars['DateTime']['output'];
  currentProductFollowingId: Maybe<Scalars['Int']['output']>;
  currentProductId: Maybe<Scalars['Int']['output']>;
  currentProductName: Maybe<Scalars['String']['output']>;
  distributionRate: Maybe<EnergoDistributionRate>;
  electricityUsages: Maybe<ElectricityUsages>;
  email: Maybe<Scalars['String']['output']>;
  energoContract: EnergoEnergoContract;
  energoOffers: Array<EnergoOffer>;
  floorAreaRange: Maybe<EnergoFloorAreaRangeCodebook>;
  gasCurrentProductFollowingId: Maybe<Scalars['Int']['output']>;
  gasCurrentProductId: Maybe<Scalars['Int']['output']>;
  gasCurrentProductName: Maybe<Scalars['String']['output']>;
  gasMonthlyAdvances: Maybe<Scalars['Float']['output']>;
  gasPartner: Maybe<EnergoGasPartner>;
  gasUnknownCurrentProduct: Maybe<Scalars['Boolean']['output']>;
  gasUnknownMonthlyAdvances: Maybe<Scalars['Boolean']['output']>;
  gasUsages: Maybe<GasUsages>;
  gasYearlyConsumption: Maybe<Scalars['Float']['output']>;
  gasYearlyConsumptionUnit: Maybe<ConsumptionUnit>;
  gasYearlyPayment: Maybe<Scalars['Float']['output']>;
  isolation: Maybe<EnergoIsolationCodebook>;
  lastViewed: Maybe<Scalars['DateTime']['output']>;
  monthlyAdvances: Maybe<Scalars['Float']['output']>;
  partner: Maybe<EnergoElectricityPartner>;
  personsCountRange: Maybe<EnergoPersonsCountRangeCodebook>;
  saving: Maybe<Scalars['Float']['output']>;
  source: EnergoCalculationSource;
  standaloneInvoice: Maybe<EnergoStandaloneInvoice>;
  type: EnergoCalculationType;
  unknownCurrentProduct: Maybe<Scalars['Boolean']['output']>;
  unknownMonthlyAdvances: Maybe<Scalars['Boolean']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  uuid: Scalars['String']['output'];
  watchWolf: Maybe<WatchWolfPreview>;
  yearlyConsumption: Maybe<Scalars['Float']['output']>;
  yearlyConsumptionLowTariff: Maybe<Scalars['Float']['output']>;
  yearlyConsumptionLowTariffUnit: Maybe<ConsumptionUnit>;
  yearlyConsumptionUnit: Maybe<ConsumptionUnit>;
  yearlyPayment: Maybe<Scalars['Float']['output']>;
};

export type ElectricityPriceProductPreviewListInput = {
  distributionRateId: Scalars['Int']['input'];
  smartAddressId: Scalars['Int']['input'];
};

export type ElectricityUsages = {
  __typename?: 'ElectricityUsages';
  basicAppliances: Maybe<Scalars['Boolean']['output']>;
  heating: Maybe<Scalars['Boolean']['output']>;
  waterHeating: Maybe<Scalars['Boolean']['output']>;
};

export type ElectricityUsagesInput = {
  basicAppliances?: InputMaybe<Scalars['Boolean']['input']>;
  heating?: InputMaybe<Scalars['Boolean']['input']>;
  waterHeating?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EnergoBreaker = {
  __typename?: 'EnergoBreaker';
  breaker: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  overLimitPayment: Maybe<Scalars['Boolean']['output']>;
  phases: Maybe<Scalars['Int']['output']>;
};

export const EnergoBusinessCaseStatus = {
  Acceptation: 'ACCEPTATION',
  AwaitingSignature: 'AWAITING_SIGNATURE',
  Canceled: 'CANCELED',
  ContractPreparation: 'CONTRACT_PREPARATION',
  Finished: 'FINISHED',
  Handover: 'HANDOVER',
  HandoverOnline: 'HANDOVER_ONLINE',
  MissingOffer: 'MISSING_OFFER',
  Offer: 'OFFER',
  OfferAccepted: 'OFFER_ACCEPTED',
  Resigned: 'RESIGNED',
  Signature: 'SIGNATURE',
  Supply: 'SUPPLY',
  WaitingForSupply: 'WAITING_FOR_SUPPLY'
} as const;

export type EnergoBusinessCaseStatus = typeof EnergoBusinessCaseStatus[keyof typeof EnergoBusinessCaseStatus];
export type EnergoCalculation = {
  __typename?: 'EnergoCalculation';
  address: Maybe<Address>;
  bestGasResultItem: Maybe<EnergoGasResultItem>;
  bestResultItem: Maybe<EnergoElectricityResultItem>;
  breaker: Maybe<EnergoBreaker>;
  createdAt: Scalars['DateTime']['output'];
  currentProductFollowingId: Maybe<Scalars['Int']['output']>;
  currentProductId: Maybe<Scalars['Int']['output']>;
  currentProductName: Maybe<Scalars['String']['output']>;
  distributionRate: Maybe<EnergoDistributionRate>;
  electricityUsages: Maybe<ElectricityUsages>;
  email: Maybe<Scalars['String']['output']>;
  floorAreaRange: Maybe<EnergoFloorAreaRangeCodebook>;
  gasCurrentProductFollowingId: Maybe<Scalars['Int']['output']>;
  gasCurrentProductId: Maybe<Scalars['Int']['output']>;
  gasCurrentProductName: Maybe<Scalars['String']['output']>;
  gasIndividualPriceExpectedMWh: Maybe<Scalars['Float']['output']>;
  gasIndividualPriceExpectedMonthly: Maybe<Scalars['Float']['output']>;
  gasMonthlyAdvances: Maybe<Scalars['Float']['output']>;
  gasPartner: Maybe<EnergoGasPartner>;
  gasUnknownCurrentProduct: Maybe<Scalars['Boolean']['output']>;
  gasUnknownMonthlyAdvances: Maybe<Scalars['Boolean']['output']>;
  gasUsages: Maybe<GasUsages>;
  gasYearlyConsumption: Maybe<Scalars['Float']['output']>;
  gasYearlyConsumptionUnit: Maybe<ConsumptionUnit>;
  gasYearlyPayment: Maybe<Scalars['Float']['output']>;
  id: Scalars['Int']['output'];
  individualOfferName: Maybe<Scalars['String']['output']>;
  individualPriceExpectedMWhHighTariff: Maybe<Scalars['Float']['output']>;
  individualPriceExpectedMWhLowTariff: Maybe<Scalars['Float']['output']>;
  individualPriceExpectedMonthly: Maybe<Scalars['Float']['output']>;
  isolation: Maybe<EnergoIsolationCodebook>;
  lastViewed: Maybe<Scalars['DateTime']['output']>;
  monthlyAdvances: Maybe<Scalars['Float']['output']>;
  partner: Maybe<EnergoElectricityPartner>;
  personsCountRange: Maybe<EnergoPersonsCountRangeCodebook>;
  phone: Maybe<Scalars['String']['output']>;
  reason: Maybe<CalculationReasonEnum>;
  saving: Maybe<Scalars['Float']['output']>;
  source: EnergoCalculationSource;
  standaloneInvoice: Maybe<EnergoStandaloneInvoice>;
  type: EnergoCalculationType;
  unknownCurrentProduct: Maybe<Scalars['Boolean']['output']>;
  unknownMonthlyAdvances: Maybe<Scalars['Boolean']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  uuid: Scalars['String']['output'];
  yearlyConsumption: Maybe<Scalars['Float']['output']>;
  yearlyConsumptionLowTariff: Maybe<Scalars['Float']['output']>;
  yearlyConsumptionLowTariffUnit: Maybe<ConsumptionUnit>;
  yearlyConsumptionUnit: Maybe<ConsumptionUnit>;
  yearlyPayment: Maybe<Scalars['Float']['output']>;
};

export type EnergoCalculationResult = {
  __typename?: 'EnergoCalculationResult';
  calculation: EnergoCalculation;
  electricityResult: Maybe<EnergoElectricityCalculationResult>;
  gasResult: Maybe<EnergoGasCalculationResult>;
  hasEnergoOffers: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
};

export const EnergoCalculationSource = {
  Billing: 'BILLING',
  None: 'NONE'
} as const;

export type EnergoCalculationSource = typeof EnergoCalculationSource[keyof typeof EnergoCalculationSource];
export const EnergoCalculationType = {
  Electricity: 'ELECTRICITY',
  Gas: 'GAS'
} as const;

export type EnergoCalculationType = typeof EnergoCalculationType[keyof typeof EnergoCalculationType];
export type EnergoCodebooks = {
  __typename?: 'EnergoCodebooks';
  breaker: Array<EnergoBreaker>;
  distributionRate: Array<EnergoDistributionRate>;
  energoPartner: Array<EnergoPartner>;
  floorAreaRange: Array<EnergoFloorAreaRangeCodebook>;
  gasPartner: Array<EnergoGasPartnerCodebook>;
  isolation: Array<EnergoIsolationCodebook>;
  partner: Array<EnergoElectricityPartnerCodebook>;
  personsCountRange: Array<EnergoPersonsCountRangeCodebook>;
};

export const EnergoContractAttachmentType = {
  AcceptanceOfNotice: 'ACCEPTANCE_OF_NOTICE',
  Affidavit: 'AFFIDAVIT',
  ConnectionRequest: 'CONNECTION_REQUEST',
  Contract: 'CONTRACT',
  ContractTerminationRequest: 'CONTRACT_TERMINATION_REQUEST',
  ContractWithTheOriginalSupplier: 'CONTRACT_WITH_THE_ORIGINAL_SUPPLIER',
  DeathCertificate: 'DEATH_CERTIFICATE',
  ExtractFromTheLandRegistry: 'EXTRACT_FROM_THE_LAND_REGISTRY',
  LastBilling: 'LAST_BILLING',
  OtherAttachments: 'OTHER_ATTACHMENTS',
  PowerOfAttorney: 'POWER_OF_ATTORNEY',
  PowerOfAttorneyOriginalCustomer: 'POWER_OF_ATTORNEY_ORIGINAL_CUSTOMER',
  PreContractualInformation: 'PRE_CONTRACTUAL_INFORMATION',
  PricingLetter: 'PRICING_LETTER',
  ProofOfIdentity: 'PROOF_OF_IDENTITY',
  ProofOfRelationToTheProperty: 'PROOF_OF_RELATION_TO_THE_PROPERTY',
  RevisionReport: 'REVISION_REPORT',
  TransferProtocol: 'TRANSFER_PROTOCOL',
  Unknown: 'UNKNOWN',
  WelcomeLetter: 'WELCOME_LETTER'
} as const;

export type EnergoContractAttachmentType = typeof EnergoContractAttachmentType[keyof typeof EnergoContractAttachmentType];
export type EnergoContractDataInput = {
  bankAccount?: InputMaybe<Scalars['String']['input']>;
  birthDate?: InputMaybe<Scalars['String']['input']>;
  birthNumber?: InputMaybe<Scalars['String']['input']>;
  permanentAddress?: InputMaybe<Scalars['Int']['input']>;
  preferredPaymentMethod?: InputMaybe<PreferredPaymentMethod>;
  sendInvoiceMethod?: InputMaybe<SendInvoiceMethod>;
  sipoNumber?: InputMaybe<Scalars['String']['input']>;
  supplyAddress?: InputMaybe<Scalars['Int']['input']>;
};

export type EnergoContractPersonalDataInput = {
  contactAddress: Scalars['Int']['input'];
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  surname: Scalars['String']['input'];
};

export type EnergoCreateCalculationFromStandaloneInput = {
  standaloneInvoiceUuid: Scalars['String']['input'];
};

export type EnergoCreateCalculationInput = {
  source: EnergoCalculationSource;
  type: EnergoCalculationType;
};

export type EnergoCreateStandaloneInvoiceFromLandingPageInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  files: Array<Scalars['String']['input']>;
  phone: Scalars['String']['input'];
  sourceUrl: Scalars['String']['input'];
  subVertical: SubVertical;
  vertical: Vertical;
};

export type EnergoCreateStandaloneInvoiceFromWolfPageInput = {
  email: Scalars['String']['input'];
  files: Array<Scalars['String']['input']>;
  phone: Scalars['String']['input'];
  source: StandaloneInvoiceSource;
  subVertical: SubVertical;
  text: Scalars['String']['input'];
  vertical: Vertical;
};

export type EnergoCreateStandaloneInvoiceWithEmailFromLandingPageInput = {
  email: Scalars['String']['input'];
  files: Array<Scalars['String']['input']>;
  phone: Scalars['String']['input'];
  sourceUrl: Scalars['String']['input'];
  subVertical: SubVertical;
  vertical: Vertical;
};

export type EnergoCreateStandaloneOptionalInvoiceFromLandingPageInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  files?: InputMaybe<Array<Scalars['String']['input']>>;
  phone: Scalars['String']['input'];
  sourceUrl: Scalars['String']['input'];
  subVertical: SubVertical;
  vertical: Vertical;
};

export type EnergoDistributionRate = {
  __typename?: 'EnergoDistributionRate';
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  lowTariff: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
};

export type EnergoElectricityCalculationResult = {
  __typename?: 'EnergoElectricityCalculationResult';
  bestGuaranteed: Maybe<EnergoElectricityResultItem>;
  bestLengthOfFixation: Maybe<EnergoElectricityResultItem>;
  bestNoFixation: Maybe<EnergoElectricityResultItem>;
  common: EnergoElectricityCalculationResultCommon;
  resultItems: Array<EnergoElectricityResultItem>;
};

export type EnergoElectricityCalculationResultCommon = {
  __typename?: 'EnergoElectricityCalculationResultCommon';
  consumptionHT: Scalars['Float']['output'];
  consumptionLT: Scalars['Float']['output'];
  priceCurrentAnnually: Scalars['Float']['output'];
  priceCurrentMonthly: Scalars['Float']['output'];
};

export type EnergoElectricityLead = {
  __typename?: 'EnergoElectricityLead';
  breaker: Maybe<EnergoBreaker>;
  calculation: EnergoCalculation;
  currentProductId: Maybe<Scalars['Int']['output']>;
  currentProductName: Maybe<Scalars['String']['output']>;
  distributionRate: Maybe<EnergoDistributionRate>;
  id: Scalars['Int']['output'];
  lead: Lead;
  partner: Maybe<EnergoElectricityPartner>;
  resultItem: EnergoElectricityResultItem;
  userResultItem: Maybe<EnergoElectricityResultItem>;
  yearlyConsumption: Maybe<Scalars['Float']['output']>;
  yearlyConsumptionLowTariff: Maybe<Scalars['Float']['output']>;
  yearlyPayment: Maybe<Scalars['Float']['output']>;
};

export type EnergoElectricityPartner = {
  __typename?: 'EnergoElectricityPartner';
  adminLastEdit: Maybe<Scalars['DateTime']['output']>;
  cooperationMethod: Maybe<CooperationMethodEnum>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  descriptionMD: Maybe<Scalars['String']['output']>;
  energoPartnerId: Maybe<Scalars['Int']['output']>;
  estimateDefaultProductId: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  isActive: Maybe<Scalars['Boolean']['output']>;
  logoSquareUrl: Maybe<Scalars['String']['output']>;
  logoUrl: Maybe<Scalars['String']['output']>;
  metaDescription: Maybe<Scalars['String']['output']>;
  metaTitle: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  rating: Maybe<NewRating>;
  ratingCustomerService: Maybe<Scalars['Int']['output']>;
  ratingInformationAccess: Maybe<Scalars['Int']['output']>;
  ratingKarma: Maybe<Scalars['Int']['output']>;
  ratingOnlineSupport: Maybe<Scalars['Int']['output']>;
  ratingProvidedService: Maybe<Scalars['Int']['output']>;
  ratingTotal: Maybe<Scalars['Int']['output']>;
  seoName: Maybe<Scalars['String']['output']>;
  termsOfServicePreview: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
  titleSuffix: Maybe<Scalars['String']['output']>;
  tollFreePhone: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type EnergoElectricityPartnerCodebook = {
  __typename?: 'EnergoElectricityPartnerCodebook';
  adminLastEdit: Maybe<Scalars['DateTime']['output']>;
  companyAddress: Maybe<Scalars['String']['output']>;
  contactEmail: Maybe<Scalars['String']['output']>;
  cooperationMethod: Maybe<CooperationMethodEnum>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  descriptionMD: Maybe<Scalars['String']['output']>;
  energoPartnerId: Maybe<Scalars['Int']['output']>;
  estimateDefaultProductId: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  invoiceHelpBreakerImage: Maybe<File>;
  invoiceHelpBreakerMD: Maybe<Scalars['String']['output']>;
  invoiceHelpCurrentProductImage: Maybe<File>;
  invoiceHelpCurrentProductMD: Maybe<Scalars['String']['output']>;
  invoiceHelpDistributionRateImage: Maybe<File>;
  invoiceHelpDistributionRateMD: Maybe<Scalars['String']['output']>;
  invoiceHelpHighTariffImage: Maybe<File>;
  invoiceHelpHighTariffMD: Maybe<Scalars['String']['output']>;
  invoiceHelpInvoicedPaymentImage: Maybe<File>;
  invoiceHelpInvoicedPaymentMD: Maybe<Scalars['String']['output']>;
  invoiceHelpLowTariffImage: Maybe<File>;
  invoiceHelpLowTariffMD: Maybe<Scalars['String']['output']>;
  isActive: Maybe<Scalars['Boolean']['output']>;
  latitude: Maybe<Scalars['Float']['output']>;
  logoSquareUrl: Maybe<Scalars['String']['output']>;
  logoUrl: Maybe<Scalars['String']['output']>;
  longArticleMD: Maybe<Scalars['String']['output']>;
  longitude: Maybe<Scalars['Float']['output']>;
  metaDescription: Maybe<Scalars['String']['output']>;
  metaTitle: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  rating: Maybe<NewRating>;
  ratingCustomerService: Maybe<Scalars['Int']['output']>;
  ratingInformationAccess: Maybe<Scalars['Int']['output']>;
  ratingKarma: Maybe<Scalars['Int']['output']>;
  ratingOnlineSupport: Maybe<Scalars['Int']['output']>;
  ratingProvidedService: Maybe<Scalars['Int']['output']>;
  ratingTotal: Maybe<Scalars['Int']['output']>;
  seoName: Maybe<Scalars['String']['output']>;
  termsOfServicePreview: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
  titleSuffix: Maybe<Scalars['String']['output']>;
  tollFreePhone: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  urlPricelist: Maybe<Scalars['String']['output']>;
  urlVop: Maybe<Scalars['String']['output']>;
  web: Maybe<Scalars['String']['output']>;
};

export type EnergoElectricityPartnerDetail = {
  __typename?: 'EnergoElectricityPartnerDetail';
  adminLastEdit: Maybe<Scalars['DateTime']['output']>;
  companyAddress: Maybe<Scalars['String']['output']>;
  contactEmail: Maybe<Scalars['String']['output']>;
  cooperationMethod: Maybe<CooperationMethodEnum>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  descriptionMD: Maybe<Scalars['String']['output']>;
  energoPartnerId: Maybe<Scalars['Int']['output']>;
  estimateDefaultProductId: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  invoiceHelpBreakerImage: Maybe<File>;
  invoiceHelpBreakerMD: Maybe<Scalars['String']['output']>;
  invoiceHelpCurrentProductImage: Maybe<File>;
  invoiceHelpCurrentProductMD: Maybe<Scalars['String']['output']>;
  invoiceHelpDistributionRateImage: Maybe<File>;
  invoiceHelpDistributionRateMD: Maybe<Scalars['String']['output']>;
  invoiceHelpHighTariffImage: Maybe<File>;
  invoiceHelpHighTariffMD: Maybe<Scalars['String']['output']>;
  invoiceHelpInvoicedPaymentImage: Maybe<File>;
  invoiceHelpInvoicedPaymentMD: Maybe<Scalars['String']['output']>;
  invoiceHelpLowTariffImage: Maybe<File>;
  invoiceHelpLowTariffMD: Maybe<Scalars['String']['output']>;
  isActive: Maybe<Scalars['Boolean']['output']>;
  latitude: Maybe<Scalars['Float']['output']>;
  logoSquareUrl: Maybe<Scalars['String']['output']>;
  logoUrl: Maybe<Scalars['String']['output']>;
  longArticleMD: Maybe<Scalars['String']['output']>;
  longitude: Maybe<Scalars['Float']['output']>;
  metaDescription: Maybe<Scalars['String']['output']>;
  metaTitle: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  rating: Maybe<NewRating>;
  ratingCustomerService: Maybe<Scalars['Int']['output']>;
  ratingInformationAccess: Maybe<Scalars['Int']['output']>;
  ratingKarma: Maybe<Scalars['Int']['output']>;
  ratingOnlineSupport: Maybe<Scalars['Int']['output']>;
  ratingProvidedService: Maybe<Scalars['Int']['output']>;
  ratingTotal: Maybe<Scalars['Int']['output']>;
  seoName: Maybe<Scalars['String']['output']>;
  termsOfServicePreview: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
  titleSuffix: Maybe<Scalars['String']['output']>;
  tollFreePhone: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  urlPricelist: Maybe<Scalars['String']['output']>;
  urlVop: Maybe<Scalars['String']['output']>;
  web: Maybe<Scalars['String']['output']>;
};

export type EnergoElectricityPartnerPreview = {
  __typename?: 'EnergoElectricityPartnerPreview';
  active: Scalars['Boolean']['output'];
  adminLastEdit: Maybe<Scalars['DateTime']['output']>;
  companyAddress: Maybe<Scalars['String']['output']>;
  contactEmail: Maybe<Scalars['String']['output']>;
  cooperationMethod: CooperationMethodEnum;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  energoPartnerId: Maybe<Scalars['Int']['output']>;
  estimateDefaultProductId: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  latitude: Maybe<Scalars['Float']['output']>;
  logoSquareUrl: Maybe<Scalars['String']['output']>;
  logoUrl: Maybe<Scalars['String']['output']>;
  longArticleMD: Maybe<Scalars['String']['output']>;
  longitude: Maybe<Scalars['Float']['output']>;
  name: Maybe<Scalars['String']['output']>;
  seoName: Maybe<Scalars['String']['output']>;
  tollFreePhone: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  urlPricelist: Maybe<Scalars['String']['output']>;
  urlVop: Maybe<Scalars['String']['output']>;
  web: Maybe<Scalars['String']['output']>;
};

export type EnergoElectricityPriceProductPreviewItem = {
  __typename?: 'EnergoElectricityPriceProductPreviewItem';
  distributionRateId: Scalars['Int']['output'];
  distributorId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  priceMWhHighTariff: Scalars['Float']['output'];
  priceMWhLowTariff: Scalars['Float']['output'];
  priceMonthly: Scalars['Float']['output'];
  product: EnergoElectricityProductPreview;
};

export type EnergoElectricityProduct = {
  __typename?: 'EnergoElectricityProduct';
  adminLastEdit: Maybe<Scalars['DateTime']['output']>;
  availableForSale: Scalars['Boolean']['output'];
  bonus: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  descriptionMD: Maybe<Scalars['String']['output']>;
  fixedEndOfCommitment: Maybe<Scalars['DateTime']['output']>;
  fixedEndOfFixation: Maybe<Scalars['DateTime']['output']>;
  followingProductId: Maybe<Scalars['Int']['output']>;
  gift: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isActive: Maybe<Scalars['Boolean']['output']>;
  isActiveForPurchase: Scalars['Boolean']['output'];
  label: Maybe<Scalars['String']['output']>;
  labelTooltip: Maybe<Scalars['String']['output']>;
  lengthOfCommitment: Scalars['Int']['output'];
  lengthOfFixation: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  partnerTransition: Scalars['Boolean']['output'];
  preferredSale: Scalars['Boolean']['output'];
  private: Maybe<Scalars['Boolean']['output']>;
  productCode: Maybe<Scalars['String']['output']>;
  /** list of markdowns */
  productInfoListBasic: Array<Scalars['String']['output']>;
  /** list of markdowns */
  productInfoListExtended: Array<Scalars['String']['output']>;
  shortName: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type EnergoElectricityProductPreview = {
  __typename?: 'EnergoElectricityProductPreview';
  activeForCurrentCustomers: Scalars['Boolean']['output'];
  activeForNewCustomers: Scalars['Boolean']['output'];
  activeForSelectCurrentEstimate: Scalars['Boolean']['output'];
  activeForSelectCurrentExact: Scalars['Boolean']['output'];
  adminLastEdit: Maybe<Scalars['DateTime']['output']>;
  availableForSale: Scalars['Boolean']['output'];
  createdAt: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  isActive: Scalars['Boolean']['output'];
  isActiveForPurchase: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  partner: EnergoElectricityPartnerPreview;
  preferredSale: Scalars['Boolean']['output'];
  private: Scalars['Boolean']['output'];
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type EnergoElectricityResultItem = {
  __typename?: 'EnergoElectricityResultItem';
  discountToInvoice: Scalars['Float']['output'];
  discountToInvoiceWithoutVat: Scalars['Float']['output'];
  entirePeriodSavingIsMax: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  kalkulatorTip: Maybe<Scalars['Boolean']['output']>;
  maxSaving: Scalars['Float']['output'];
  partner: EnergoElectricityPartner;
  /** zkalkulovana cena pro tento tarif (rocni) */
  priceCalculatedAnnually: Scalars['Float']['output'];
  /** priceCalculatedAnnually / 12 */
  priceCalculatedMonthly: Scalars['Float']['output'];
  /** stavajici cena, kterou uzivatel zadal */
  priceCurrent: Scalars['Float']['output'];
  /** tolik by platil po prumernem 15% zdrazeni */
  priceExpected: Scalars['Float']['output'];
  priceExpectedDetail: EnergoElectricityResultPriceDetail;
  priceResultDetail: EnergoElectricityResultPriceDetail;
  product: EnergoElectricityProduct;
  /** priceExpected - priceCalculatedAnnually */
  saving: Scalars['Float']['output'];
  savingEntirePeriod: Scalars['Float']['output'];
};

export type EnergoElectricityResultPriceDetail = {
  __typename?: 'EnergoElectricityResultPriceDetail';
  priceDistributionMWhHT: Maybe<Scalars['Float']['output']>;
  priceDistributionMWhLT: Maybe<Scalars['Float']['output']>;
  priceDistributionMonthly: Maybe<Scalars['Float']['output']>;
  priceProductMWhHT: Maybe<Scalars['Float']['output']>;
  priceProductMWhLT: Maybe<Scalars['Float']['output']>;
  priceProductMonthly: Maybe<Scalars['Float']['output']>;
  priceResult: Maybe<Scalars['Float']['output']>;
  yearlyDistributionPrice: Maybe<Scalars['Float']['output']>;
  yearlyPartnerPrice: Maybe<Scalars['Float']['output']>;
};

export type EnergoEnergoContract = {
  __typename?: 'EnergoEnergoContract';
  attachments: Array<EnergoEnergoContractAttachment>;
  contactAddress: Maybe<Address>;
  customContactAddress: Maybe<CustomAddress>;
  customPermanentAddress: Maybe<CustomAddress>;
  customSupplyAddress: Maybe<CustomAddress>;
  customerNote: Maybe<Scalars['String']['output']>;
  email: Maybe<Scalars['String']['output']>;
  energoOffer: Maybe<EnergoOffer>;
  expectedSupplyStartDate: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  invoiceUploaded: Scalars['Boolean']['output'];
  methodOfSendingContract: Maybe<MethodOfSendingContract>;
  name: Maybe<Scalars['String']['output']>;
  permanentAddress: Maybe<Address>;
  phone: Maybe<Scalars['String']['output']>;
  preferredPaymentMethod: Maybe<PreferredPaymentMethod>;
  sendInvoiceMethod: Maybe<SendInvoiceMethod>;
  status: EnergoBusinessCaseStatus;
  supplyAddress: Maybe<Address>;
  supplyStartDate: Maybe<Scalars['DateTime']['output']>;
  surname: Maybe<Scalars['String']['output']>;
};

export type EnergoEnergoContractAttachment = {
  __typename?: 'EnergoEnergoContractAttachment';
  createdAt: Scalars['String']['output'];
  file: File;
  id: Scalars['Int']['output'];
  type: EnergoContractAttachmentType;
};

export type EnergoFloorAreaRangeCodebook = {
  __typename?: 'EnergoFloorAreaRangeCodebook';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type EnergoGasCalculationResult = {
  __typename?: 'EnergoGasCalculationResult';
  bestGuaranteed: Maybe<EnergoGasResultItem>;
  bestLengthOfFixation: Maybe<EnergoGasResultItem>;
  bestNoFixation: Maybe<EnergoGasResultItem>;
  common: EnergoGasCalculationResultCommon;
  resultItems: Array<EnergoGasResultItem>;
};

export type EnergoGasCalculationResultCommon = {
  __typename?: 'EnergoGasCalculationResultCommon';
  consumption: Scalars['Float']['output'];
  priceCurrentAnnually: Scalars['Float']['output'];
  priceCurrentMonthly: Scalars['Float']['output'];
};

export type EnergoGasLead = {
  __typename?: 'EnergoGasLead';
  calculation: EnergoCalculation;
  currentProductId: Maybe<Scalars['Int']['output']>;
  currentProductName: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  lead: Lead;
  partner: Maybe<EnergoGasPartner>;
  resultItem: EnergoGasResultItem;
  userResultItem: Maybe<EnergoGasResultItem>;
  yearlyConsumption: Maybe<Scalars['Float']['output']>;
  yearlyPayment: Maybe<Scalars['Float']['output']>;
};

export type EnergoGasPartner = {
  __typename?: 'EnergoGasPartner';
  adminLastEdit: Maybe<Scalars['DateTime']['output']>;
  cooperationMethod: Maybe<CooperationMethodEnum>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  descriptionMD: Maybe<Scalars['String']['output']>;
  energoPartnerId: Maybe<Scalars['Int']['output']>;
  estimateDefaultProductId: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  isActive: Maybe<Scalars['Boolean']['output']>;
  logoSquareUrl: Maybe<Scalars['String']['output']>;
  logoUrl: Maybe<Scalars['String']['output']>;
  metaDescription: Maybe<Scalars['String']['output']>;
  metaTitle: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  rating: Maybe<NewRating>;
  ratingCustomerService: Maybe<Scalars['Int']['output']>;
  ratingInformationAccess: Maybe<Scalars['Int']['output']>;
  ratingKarma: Maybe<Scalars['Int']['output']>;
  ratingOnlineSupport: Maybe<Scalars['Int']['output']>;
  ratingProvidedService: Maybe<Scalars['Int']['output']>;
  ratingTotal: Maybe<Scalars['Int']['output']>;
  seoName: Maybe<Scalars['String']['output']>;
  termsOfServicePreview: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
  titleSuffix: Maybe<Scalars['String']['output']>;
  tollFreePhone: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type EnergoGasPartnerCodebook = {
  __typename?: 'EnergoGasPartnerCodebook';
  adminLastEdit: Maybe<Scalars['DateTime']['output']>;
  companyAddress: Maybe<Scalars['String']['output']>;
  contactEmail: Maybe<Scalars['String']['output']>;
  cooperationMethod: Maybe<CooperationMethodEnum>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  descriptionMD: Maybe<Scalars['String']['output']>;
  energoPartnerId: Maybe<Scalars['Int']['output']>;
  estimateDefaultProductId: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  invoiceHelpCurrentProductImage: Maybe<File>;
  invoiceHelpCurrentProductMD: Maybe<Scalars['String']['output']>;
  invoiceHelpInvoicedPaymentImage: Maybe<File>;
  invoiceHelpInvoicedPaymentMD: Maybe<Scalars['String']['output']>;
  invoiceHelpYearlyConsumptionImage: Maybe<File>;
  invoiceHelpYearlyConsumptionMD: Maybe<Scalars['String']['output']>;
  isActive: Maybe<Scalars['Boolean']['output']>;
  latitude: Maybe<Scalars['Float']['output']>;
  logoSquareUrl: Maybe<Scalars['String']['output']>;
  logoUrl: Maybe<Scalars['String']['output']>;
  longArticleMD: Maybe<Scalars['String']['output']>;
  longitude: Maybe<Scalars['Float']['output']>;
  metaDescription: Maybe<Scalars['String']['output']>;
  metaTitle: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  rating: Maybe<NewRating>;
  ratingCustomerService: Maybe<Scalars['Int']['output']>;
  ratingInformationAccess: Maybe<Scalars['Int']['output']>;
  ratingKarma: Maybe<Scalars['Int']['output']>;
  ratingOnlineSupport: Maybe<Scalars['Int']['output']>;
  ratingProvidedService: Maybe<Scalars['Int']['output']>;
  ratingTotal: Maybe<Scalars['Int']['output']>;
  seoName: Maybe<Scalars['String']['output']>;
  termsOfServicePreview: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
  titleSuffix: Maybe<Scalars['String']['output']>;
  tollFreePhone: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  urlPricelist: Maybe<Scalars['String']['output']>;
  urlVop: Maybe<Scalars['String']['output']>;
  web: Maybe<Scalars['String']['output']>;
};

export type EnergoGasPartnerDetail = {
  __typename?: 'EnergoGasPartnerDetail';
  adminLastEdit: Maybe<Scalars['DateTime']['output']>;
  companyAddress: Maybe<Scalars['String']['output']>;
  contactEmail: Maybe<Scalars['String']['output']>;
  cooperationMethod: Maybe<CooperationMethodEnum>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  descriptionMD: Maybe<Scalars['String']['output']>;
  energoPartnerId: Maybe<Scalars['Int']['output']>;
  estimateDefaultProductId: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  invoiceHelpCurrentProductImage: Maybe<File>;
  invoiceHelpCurrentProductMD: Maybe<Scalars['String']['output']>;
  invoiceHelpInvoicedPaymentImage: Maybe<File>;
  invoiceHelpInvoicedPaymentMD: Maybe<Scalars['String']['output']>;
  invoiceHelpYearlyConsumptionImage: Maybe<File>;
  invoiceHelpYearlyConsumptionMD: Maybe<Scalars['String']['output']>;
  isActive: Maybe<Scalars['Boolean']['output']>;
  latitude: Maybe<Scalars['Float']['output']>;
  logoSquareUrl: Maybe<Scalars['String']['output']>;
  logoUrl: Maybe<Scalars['String']['output']>;
  longArticleMD: Maybe<Scalars['String']['output']>;
  longitude: Maybe<Scalars['Float']['output']>;
  metaDescription: Maybe<Scalars['String']['output']>;
  metaTitle: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  rating: Maybe<NewRating>;
  ratingCustomerService: Maybe<Scalars['Int']['output']>;
  ratingInformationAccess: Maybe<Scalars['Int']['output']>;
  ratingKarma: Maybe<Scalars['Int']['output']>;
  ratingOnlineSupport: Maybe<Scalars['Int']['output']>;
  ratingProvidedService: Maybe<Scalars['Int']['output']>;
  ratingTotal: Maybe<Scalars['Int']['output']>;
  seoName: Maybe<Scalars['String']['output']>;
  termsOfServicePreview: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
  titleSuffix: Maybe<Scalars['String']['output']>;
  tollFreePhone: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  urlPricelist: Maybe<Scalars['String']['output']>;
  urlVop: Maybe<Scalars['String']['output']>;
  web: Maybe<Scalars['String']['output']>;
};

export type EnergoGasPartnerPreview = {
  __typename?: 'EnergoGasPartnerPreview';
  active: Scalars['Boolean']['output'];
  adminLastEdit: Maybe<Scalars['DateTime']['output']>;
  companyAddress: Maybe<Scalars['String']['output']>;
  contactEmail: Maybe<Scalars['String']['output']>;
  cooperationMethod: CooperationMethodEnum;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  energoPartnerId: Maybe<Scalars['Int']['output']>;
  estimateDefaultProductId: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  latitude: Maybe<Scalars['Float']['output']>;
  logoSquareUrl: Maybe<Scalars['String']['output']>;
  logoUrl: Maybe<Scalars['String']['output']>;
  longArticleMD: Maybe<Scalars['String']['output']>;
  longitude: Maybe<Scalars['Float']['output']>;
  name: Maybe<Scalars['String']['output']>;
  seoName: Maybe<Scalars['String']['output']>;
  tollFreePhone: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  urlPricelist: Maybe<Scalars['String']['output']>;
  urlVop: Maybe<Scalars['String']['output']>;
  web: Maybe<Scalars['String']['output']>;
};

export type EnergoGasPriceProductPreviewItem = {
  __typename?: 'EnergoGasPriceProductPreviewItem';
  id: Scalars['Int']['output'];
  odberovePasmoId: Scalars['Int']['output'];
  priceMWh: Scalars['Float']['output'];
  priceMonthly: Scalars['Float']['output'];
  pricePerM3: Scalars['Float']['output'];
  product: EnergoGasProductPreview;
};

export type EnergoGasProduct = {
  __typename?: 'EnergoGasProduct';
  adminLastEdit: Maybe<Scalars['DateTime']['output']>;
  availableForSale: Scalars['Boolean']['output'];
  bonus: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  descriptionMD: Maybe<Scalars['String']['output']>;
  fixedEndOfCommitment: Maybe<Scalars['DateTime']['output']>;
  fixedEndOfFixation: Maybe<Scalars['DateTime']['output']>;
  followingProductId: Maybe<Scalars['Int']['output']>;
  gift: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isActive: Maybe<Scalars['Boolean']['output']>;
  isActiveForPurchase: Scalars['Boolean']['output'];
  label: Maybe<Scalars['String']['output']>;
  labelTooltip: Maybe<Scalars['String']['output']>;
  lengthOfCommitment: Scalars['Int']['output'];
  lengthOfFixation: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  partnerTransition: Scalars['Boolean']['output'];
  preferredSale: Scalars['Boolean']['output'];
  private: Maybe<Scalars['Boolean']['output']>;
  productCode: Maybe<Scalars['String']['output']>;
  /** list of markdowns */
  productInfoListBasic: Array<Scalars['String']['output']>;
  /** list of markdowns */
  productInfoListExtended: Array<Scalars['String']['output']>;
  shortName: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type EnergoGasProductPreview = {
  __typename?: 'EnergoGasProductPreview';
  activeForCurrentCustomers: Scalars['Boolean']['output'];
  activeForNewCustomers: Scalars['Boolean']['output'];
  activeForSelectCurrentEstimate: Scalars['Boolean']['output'];
  activeForSelectCurrentExact: Scalars['Boolean']['output'];
  adminLastEdit: Maybe<Scalars['DateTime']['output']>;
  availableForSale: Scalars['Boolean']['output'];
  createdAt: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  isActive: Scalars['Boolean']['output'];
  isActiveForPurchase: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  partner: EnergoGasPartnerPreview;
  preferredSale: Scalars['Boolean']['output'];
  private: Scalars['Boolean']['output'];
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type EnergoGasResultItem = {
  __typename?: 'EnergoGasResultItem';
  discountToInvoice: Scalars['Float']['output'];
  discountToInvoiceWithoutVat: Scalars['Float']['output'];
  entirePeriodSavingIsMax: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  kalkulatorTip: Maybe<Scalars['Boolean']['output']>;
  maxSaving: Scalars['Float']['output'];
  partner: EnergoGasPartner;
  /** zkalkulovana cena pro tento tarif (rocni) */
  priceCalculatedAnnually: Scalars['Float']['output'];
  /** priceCalculatedAnnually / 12 */
  priceCalculatedMonthly: Scalars['Float']['output'];
  /** stavajici cena, kterou uzivatel zadal */
  priceCurrent: Scalars['Float']['output'];
  /** tolik by platil po prumernem 15% zdrazeni */
  priceExpected: Scalars['Float']['output'];
  priceExpectedDetail: EnergoGasResultPriceDetail;
  priceResultDetail: EnergoGasResultPriceDetail;
  product: EnergoGasProduct;
  /** priceExpected - priceCalculatedAnnually */
  saving: Scalars['Float']['output'];
  savingEntirePeriod: Scalars['Float']['output'];
};

export type EnergoGasResultPriceDetail = {
  __typename?: 'EnergoGasResultPriceDetail';
  priceDistributionMWh: Maybe<Scalars['Float']['output']>;
  priceDistributionMonthly: Maybe<Scalars['Float']['output']>;
  priceProductMWh: Maybe<Scalars['Float']['output']>;
  priceProductMonthly: Maybe<Scalars['Float']['output']>;
  priceResult: Maybe<Scalars['Float']['output']>;
  yearlyDistributionPrice: Maybe<Scalars['Float']['output']>;
  yearlyPartnerPrice: Maybe<Scalars['Float']['output']>;
};

export type EnergoIsolationCodebook = {
  __typename?: 'EnergoIsolationCodebook';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type EnergoKalkulatorEnergoElectricityProduct = {
  __typename?: 'EnergoKalkulatorEnergoElectricityProduct';
  adminLastEdit: Maybe<Scalars['DateTime']['output']>;
  availableForSale: Scalars['Boolean']['output'];
  bonus: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  descriptionMD: Maybe<Scalars['String']['output']>;
  fixedEndOfCommitment: Maybe<Scalars['DateTime']['output']>;
  fixedEndOfFixation: Maybe<Scalars['DateTime']['output']>;
  followingProductId: Maybe<Scalars['Int']['output']>;
  gift: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isActive: Maybe<Scalars['Boolean']['output']>;
  isActiveForPurchase: Scalars['Boolean']['output'];
  isActiveForSelectCurrentEstimate: Maybe<Scalars['Boolean']['output']>;
  isActiveForSelectCurrentExact: Maybe<Scalars['Boolean']['output']>;
  label: Maybe<Scalars['String']['output']>;
  labelTooltip: Maybe<Scalars['String']['output']>;
  lengthOfCommitment: Scalars['Int']['output'];
  lengthOfFixation: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  partner: Maybe<EnergoElectricityPartner>;
  partnerTransition: Scalars['Boolean']['output'];
  preferredSale: Scalars['Boolean']['output'];
  private: Maybe<Scalars['Boolean']['output']>;
  productCode: Maybe<Scalars['String']['output']>;
  /** list of markdowns */
  productInfoListBasic: Array<Scalars['String']['output']>;
  /** list of markdowns */
  productInfoListExtended: Array<Scalars['String']['output']>;
  shortName: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type EnergoKalkulatorEnergoElectricityProductDetail = {
  __typename?: 'EnergoKalkulatorEnergoElectricityProductDetail';
  adminLastEdit: Maybe<Scalars['DateTime']['output']>;
  availableForSale: Scalars['Boolean']['output'];
  bonus: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  descriptionMD: Maybe<Scalars['String']['output']>;
  fixedEndOfCommitment: Maybe<Scalars['DateTime']['output']>;
  fixedEndOfFixation: Maybe<Scalars['DateTime']['output']>;
  followingProductId: Maybe<Scalars['Int']['output']>;
  gift: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isActive: Maybe<Scalars['Boolean']['output']>;
  isActiveForPurchase: Scalars['Boolean']['output'];
  isActiveForSelectCurrentEstimate: Maybe<Scalars['Boolean']['output']>;
  isActiveForSelectCurrentExact: Maybe<Scalars['Boolean']['output']>;
  label: Maybe<Scalars['String']['output']>;
  labelTooltip: Maybe<Scalars['String']['output']>;
  lengthOfCommitment: Scalars['Int']['output'];
  lengthOfFixation: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  partner: Maybe<EnergoKalkulatorPartnerCodebook>;
  partnerId: Maybe<Scalars['Int']['output']>;
  partnerTransition: Scalars['Boolean']['output'];
  preferredSale: Scalars['Boolean']['output'];
  private: Maybe<Scalars['Boolean']['output']>;
  productCode: Maybe<Scalars['String']['output']>;
  /** list of markdowns */
  productInfoListBasic: Array<Scalars['String']['output']>;
  /** list of markdowns */
  productInfoListExtended: Array<Scalars['String']['output']>;
  shortName: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type EnergoKalkulatorEnergoGasProduct = {
  __typename?: 'EnergoKalkulatorEnergoGasProduct';
  adminLastEdit: Maybe<Scalars['DateTime']['output']>;
  availableForSale: Scalars['Boolean']['output'];
  bonus: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  descriptionMD: Maybe<Scalars['String']['output']>;
  fixedEndOfCommitment: Maybe<Scalars['DateTime']['output']>;
  fixedEndOfFixation: Maybe<Scalars['DateTime']['output']>;
  followingProductId: Maybe<Scalars['Int']['output']>;
  gift: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isActive: Maybe<Scalars['Boolean']['output']>;
  isActiveForPurchase: Scalars['Boolean']['output'];
  isActiveForSelectCurrentEstimate: Maybe<Scalars['Boolean']['output']>;
  isActiveForSelectCurrentExact: Maybe<Scalars['Boolean']['output']>;
  label: Maybe<Scalars['String']['output']>;
  labelTooltip: Maybe<Scalars['String']['output']>;
  lengthOfCommitment: Scalars['Int']['output'];
  lengthOfFixation: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  partner: Maybe<EnergoGasPartner>;
  partnerTransition: Scalars['Boolean']['output'];
  preferredSale: Scalars['Boolean']['output'];
  private: Maybe<Scalars['Boolean']['output']>;
  productCode: Maybe<Scalars['String']['output']>;
  /** list of markdowns */
  productInfoListBasic: Array<Scalars['String']['output']>;
  /** list of markdowns */
  productInfoListExtended: Array<Scalars['String']['output']>;
  shortName: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type EnergoKalkulatorEnergoGasProductDetail = {
  __typename?: 'EnergoKalkulatorEnergoGasProductDetail';
  adminLastEdit: Maybe<Scalars['DateTime']['output']>;
  availableForSale: Scalars['Boolean']['output'];
  bonus: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  descriptionMD: Maybe<Scalars['String']['output']>;
  fixedEndOfCommitment: Maybe<Scalars['DateTime']['output']>;
  fixedEndOfFixation: Maybe<Scalars['DateTime']['output']>;
  followingProductId: Maybe<Scalars['Int']['output']>;
  gift: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isActive: Maybe<Scalars['Boolean']['output']>;
  isActiveForPurchase: Scalars['Boolean']['output'];
  isActiveForSelectCurrentEstimate: Maybe<Scalars['Boolean']['output']>;
  isActiveForSelectCurrentExact: Maybe<Scalars['Boolean']['output']>;
  label: Maybe<Scalars['String']['output']>;
  labelTooltip: Maybe<Scalars['String']['output']>;
  lengthOfCommitment: Scalars['Int']['output'];
  lengthOfFixation: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  partner: Maybe<EnergoKalkulatorPartnerCodebook>;
  partnerId: Maybe<Scalars['Int']['output']>;
  partnerTransition: Scalars['Boolean']['output'];
  preferredSale: Scalars['Boolean']['output'];
  private: Maybe<Scalars['Boolean']['output']>;
  productCode: Maybe<Scalars['String']['output']>;
  /** list of markdowns */
  productInfoListBasic: Array<Scalars['String']['output']>;
  /** list of markdowns */
  productInfoListExtended: Array<Scalars['String']['output']>;
  shortName: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type EnergoKalkulatorPartnerCodebook = {
  __typename?: 'EnergoKalkulatorPartnerCodebook';
  label: Scalars['String']['output'];
  value: Scalars['Int']['output'];
};

export type EnergoLeadPersonalDataInput = {
  address: Scalars['Int']['input'];
  contactAddress: Scalars['Int']['input'];
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  sendEmailImmediately?: InputMaybe<Scalars['Boolean']['input']>;
  surname: Scalars['String']['input'];
};

export type EnergoOffer = {
  __typename?: 'EnergoOffer';
  acceptedAt: Maybe<Scalars['DateTime']['output']>;
  acceptedBy: Maybe<ProfileUser>;
  benefitResponse: Maybe<Benefit>;
  electricityResultItem: Maybe<EnergoElectricityResultItem>;
  gasResultItem: Maybe<EnergoGasResultItem>;
  id: Scalars['Int']['output'];
  status: EnergoOfferStatus;
  validTo: Maybe<Scalars['DateTime']['output']>;
};

export const EnergoOfferPeriod = {
  Annualy: 'ANNUALY',
  Monthly: 'MONTHLY',
  Quarterly: 'QUARTERLY',
  SemiAnnualy: 'SEMI_ANNUALY'
} as const;

export type EnergoOfferPeriod = typeof EnergoOfferPeriod[keyof typeof EnergoOfferPeriod];
export const EnergoOfferStatus = {
  Accepted: 'ACCEPTED',
  Canceled: 'CANCELED',
  Created: 'CREATED',
  Declined: 'DECLINED',
  Expired: 'EXPIRED'
} as const;

export type EnergoOfferStatus = typeof EnergoOfferStatus[keyof typeof EnergoOfferStatus];
export type EnergoPartner = {
  __typename?: 'EnergoPartner';
  adminLastEdit: Maybe<Scalars['DateTime']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  descriptionMD: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  metaDescription: Maybe<Scalars['String']['output']>;
  metaTitle: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  partnerElectro: Maybe<EnergoElectricityPartnerDetail>;
  partnerGas: Maybe<EnergoGasPartnerDetail>;
  seoName: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type EnergoPersonsCountRangeCodebook = {
  __typename?: 'EnergoPersonsCountRangeCodebook';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type EnergoStandaloneInvoice = {
  __typename?: 'EnergoStandaloneInvoice';
  address: Maybe<Address>;
  email: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  subVertical: SubVertical;
  uuid: Scalars['String']['output'];
  vertical: Vertical;
};

export type EnergoStandaloneInvoiceCreateInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  files: Array<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  subVertical: SubVertical;
  vertical: Vertical;
};

export type EnergoStandaloneInvoiceUpdateInput = {
  phone?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type EnergoUpdateCalculationInput = {
  address?: InputMaybe<Scalars['Int']['input']>;
  breaker?: InputMaybe<Scalars['Int']['input']>;
  currentProductId?: InputMaybe<Scalars['Int']['input']>;
  distributionRate?: InputMaybe<Scalars['Int']['input']>;
  electricityUsages?: InputMaybe<ElectricityUsagesInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  floorAreaRange?: InputMaybe<Scalars['Int']['input']>;
  gasCurrentProductId?: InputMaybe<Scalars['Int']['input']>;
  gasMonthlyAdvances?: InputMaybe<Scalars['Float']['input']>;
  gasPartner?: InputMaybe<Scalars['Int']['input']>;
  gasUnknownCurrentProduct?: InputMaybe<Scalars['Boolean']['input']>;
  gasUnknownMonthlyAdvances?: InputMaybe<Scalars['Boolean']['input']>;
  gasUsages?: InputMaybe<GasUsagesInput>;
  gasYearlyConsumption?: InputMaybe<Scalars['Float']['input']>;
  gasYearlyConsumptionUnit?: InputMaybe<ConsumptionUnit>;
  gasYearlyPayment?: InputMaybe<Scalars['Float']['input']>;
  isolation?: InputMaybe<Scalars['Int']['input']>;
  monthlyAdvances?: InputMaybe<Scalars['Float']['input']>;
  partner?: InputMaybe<Scalars['Int']['input']>;
  personsCountRange?: InputMaybe<Scalars['Int']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<CalculationReasonEnum>;
  sendEmailImmediately?: InputMaybe<Scalars['Boolean']['input']>;
  unknownCurrentProduct?: InputMaybe<Scalars['Boolean']['input']>;
  unknownMonthlyAdvances?: InputMaybe<Scalars['Boolean']['input']>;
  yearlyConsumption?: InputMaybe<Scalars['Float']['input']>;
  yearlyConsumptionLowTariff?: InputMaybe<Scalars['Float']['input']>;
  yearlyConsumptionLowTariffUnit?: InputMaybe<ConsumptionUnit>;
  yearlyConsumptionUnit?: InputMaybe<ConsumptionUnit>;
  yearlyPayment?: InputMaybe<Scalars['Float']['input']>;
};

export type Faq = {
  __typename?: 'Faq';
  answerMD: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  question: Scalars['String']['output'];
};

export type FaqComplete = {
  __typename?: 'FaqComplete';
  answerMD: Scalars['String']['output'];
  detail: Maybe<FaqDetail>;
  id: Scalars['Int']['output'];
  question: Scalars['String']['output'];
  similar: Array<FaqPreview>;
  tags: Array<FaqTag>;
};

export type FaqDetail = {
  __typename?: 'FaqDetail';
  id: Scalars['Int']['output'];
  longAnswerSerialized: Scalars['String']['output'];
  metaDescription: Scalars['String']['output'];
  metaImage: Maybe<File>;
  metaTitle: Scalars['String']['output'];
  seoName: Scalars['String']['output'];
};

export type FaqPreview = {
  __typename?: 'FaqPreview';
  answerMD: Scalars['String']['output'];
  hasDetail: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  question: Scalars['String']['output'];
  seoName: Maybe<Scalars['String']['output']>;
};

export type FaqTag = {
  __typename?: 'FaqTag';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type File = {
  __typename?: 'File';
  createdAt: Scalars['DateTime']['output'];
  extension: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  namespace: Scalars['String']['output'];
  size: Scalars['Int']['output'];
  type: Scalars['String']['output'];
  uuid: Scalars['UUID']['output'];
};

export type FileInput = {
  content: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type FinanceAmountSelectOption = {
  __typename?: 'FinanceAmountSelectOption';
  amount: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  repaymentPeriodOptions: Array<FinanceRepaymentPeriodSelectOption>;
};

export type FinanceBankInformation = {
  __typename?: 'FinanceBankInformation';
  id: Scalars['Int']['output'];
  incomeType: Maybe<CalculationIncomeType>;
  monthlyIncome: Maybe<MonthlyIncomeBrackets>;
  otherLoans: Maybe<Scalars['Boolean']['output']>;
  otherLoansInstallment: Maybe<Scalars['Int']['output']>;
};

export const FinanceBusinessCaseStatus = {
  AcceptedByPartner: 'ACCEPTED_BY_PARTNER',
  Cancelled: 'CANCELLED',
  CancelledByPartner: 'CANCELLED_BY_PARTNER',
  MissingOffer: 'MISSING_OFFER',
  Offer: 'OFFER',
  OfferSellected: 'OFFER_SELLECTED',
  OfferSent: 'OFFER_SENT'
} as const;

export type FinanceBusinessCaseStatus = typeof FinanceBusinessCaseStatus[keyof typeof FinanceBusinessCaseStatus];
export type FinanceCalculation = {
  __typename?: 'FinanceCalculation';
  amount: Maybe<Money>;
  bankInformation: Maybe<FinanceBankInformation>;
  calculationPurpose: Maybe<Scalars['Int']['output']>;
  combination: Maybe<FinanceCalculationCombination>;
  consolidationDebts: Array<FinanceConsolidationDebt>;
  contactPerson: Maybe<FinanceContactPerson>;
  email: Maybe<Scalars['String']['output']>;
  extraLoan: Maybe<Money>;
  id: Scalars['Int']['output'];
  mortgageAmount: Maybe<Money>;
  mortgagePurpose: Maybe<MortgagePurpose>;
  phone: Maybe<Scalars['String']['output']>;
  realEstatePrice: Maybe<Money>;
  refinancingRemainingAmount: Maybe<Money>;
  /**  number of months  */
  repaymentPeriod: Maybe<Scalars['Int']['output']>;
  selectedProduct: Maybe<FinanceProduct>;
  status: FinanceBusinessCaseStatus;
  subVertical: SubVertical;
  uuid: Scalars['UUID']['output'];
};

export type FinanceCalculationCombination = {
  __typename?: 'FinanceCalculationCombination';
  amount: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  repaymentPeriod: Scalars['Int']['output'];
};

export type FinanceCalculationResult = {
  __typename?: 'FinanceCalculationResult';
  id: Scalars['Int']['output'];
  items: Array<FinanceCalculationResultItem>;
};

export type FinanceCalculationResultItem = {
  __typename?: 'FinanceCalculationResultItem';
  id: Scalars['Int']['output'];
  product: FinanceProduct;
  productCombination: FinanceProductCombination;
};

export type FinanceCalculationUpdateInput = {
  amount?: InputMaybe<MoneyInput>;
  bankingInformation?: InputMaybe<BankInformationInput>;
  consolidationDebts?: InputMaybe<Array<FinanceConsolidationDebtInput>>;
  contactPerson?: InputMaybe<FinanceContactPersonInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  extraLoan?: InputMaybe<MoneyInput>;
  mortgageAmount?: InputMaybe<MoneyInput>;
  mortgagePurpose?: InputMaybe<MortgagePurpose>;
  phone?: InputMaybe<Scalars['String']['input']>;
  purpose?: InputMaybe<Scalars['Int']['input']>;
  realEstatePrice?: InputMaybe<MoneyInput>;
  refinancingRemainingAmount?: InputMaybe<MoneyInput>;
  repaymentPeriod?: InputMaybe<Scalars['Int']['input']>;
  selectedProductCombination?: InputMaybe<Scalars['Int']['input']>;
};

export type FinanceConsolidationDebt = {
  __typename?: 'FinanceConsolidationDebt';
  consolidationDebtType: ConsolidationDebtType;
  id: Scalars['Int']['output'];
  monthlyInstallment: Money;
  remainingAmount: Money;
};

export type FinanceConsolidationDebtInput = {
  consolidationDebtType: ConsolidationDebtType;
  monthlyInstallment: MoneyInput;
  remainingAmount: MoneyInput;
};

export type FinanceContactPerson = {
  __typename?: 'FinanceContactPerson';
  email: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  surname: Scalars['String']['output'];
};

export type FinanceContactPersonInput = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  surname: Scalars['String']['input'];
};

export const FinanceIncomeType = {
  Employee: 'EMPLOYEE',
  SelfEmployed: 'SELF_EMPLOYED'
} as const;

export type FinanceIncomeType = typeof FinanceIncomeType[keyof typeof FinanceIncomeType];
export type FinancePartner = {
  __typename?: 'FinancePartner';
  active: Scalars['Boolean']['output'];
  address: Maybe<Scalars['String']['output']>;
  contactEmail: Maybe<Scalars['String']['output']>;
  faqs: Array<KnowledgebaseQuestionPreview>;
  gdprTextMD: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  logo: Maybe<File>;
  logoSquare: Maybe<File>;
  longArticleMD: Maybe<Scalars['String']['output']>;
  metaDescription: Maybe<Scalars['String']['output']>;
  metaTitle: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  phone: Maybe<Scalars['String']['output']>;
  priority: Scalars['Int']['output'];
  title: Scalars['String']['output'];
  titleAfter: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  userReviews: Array<FinanceUserReview>;
  web: Maybe<Scalars['String']['output']>;
};

export type FinanceProduct = {
  __typename?: 'FinanceProduct';
  active: Scalars['Boolean']['output'];
  amountFrom: Scalars['Int']['output'];
  amountTo: Scalars['Int']['output'];
  combinations: Array<FinanceProductCombination>;
  deferredPaymentPossibility: Maybe<Scalars['Int']['output']>;
  displayIncomeType: Scalars['Boolean']['output'];
  displayNetIncome: Scalars['Boolean']['output'];
  displayOtherLoans: Scalars['Boolean']['output'];
  earlyRepaymentBonus: Maybe<Scalars['Int']['output']>;
  exampleMD: Maybe<Scalars['String']['output']>;
  faqs: Array<KnowledgebaseQuestionPreview>;
  id: Scalars['Int']['output'];
  /**  zatím se nepoužívá  */
  incomeType: Maybe<FinanceIncomeType>;
  interestRate: Scalars['String']['output'];
  listItemsAbout: Array<Scalars['String']['output']>;
  listItemsConditions: Array<Scalars['String']['output']>;
  metaDescription: Maybe<Scalars['String']['output']>;
  metaTitle: Maybe<Scalars['String']['output']>;
  mortgagePurpose: Maybe<MortgagePurpose>;
  name: Scalars['String']['output'];
  partner: FinancePartner;
  redirectToWeb: Scalars['Boolean']['output'];
  redirectUrl: Maybe<Scalars['String']['output']>;
  repaymentPeriodFrom: Scalars['Int']['output'];
  repaymentPeriodTo: Scalars['Int']['output'];
  rpsn: Maybe<Scalars['String']['output']>;
  subVertical: SubVertical;
  title: Scalars['String']['output'];
  titleAfter: Maybe<Scalars['String']['output']>;
  type: FinanceProductType;
  updatedAt: Scalars['DateTime']['output'];
};

export type FinanceProductCombination = {
  __typename?: 'FinanceProductCombination';
  amount: Scalars['Int']['output'];
  combinationId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  interest: Scalars['Float']['output'];
  monthlyInstallment: Scalars['Float']['output'];
  repaymentPeriod: Scalars['Int']['output'];
  rpsn: Scalars['Float']['output'];
  totalPayment: Scalars['Float']['output'];
};

export const FinanceProductType = {
  Banking: 'BANKING',
  NonBanking: 'NON_BANKING'
} as const;

export type FinanceProductType = typeof FinanceProductType[keyof typeof FinanceProductType];
export type FinanceRepaymentPeriodSelectOption = {
  __typename?: 'FinanceRepaymentPeriodSelectOption';
  id: Scalars['String']['output'];
  numberOfProducts: Scalars['Int']['output'];
  /**  number of months  */
  repaymentPeriod: Scalars['Int']['output'];
};

export type FinanceUserReview = {
  __typename?: 'FinanceUserReview';
  id: Scalars['Int']['output'];
};

export const FloodDamage20Years = {
  MoreThanTwo: 'MORE_THAN_TWO',
  None: 'NONE',
  NotSure: 'NOT_SURE',
  One: 'ONE',
  Two: 'TWO'
} as const;

export type FloodDamage20Years = typeof FloodDamage20Years[keyof typeof FloodDamage20Years];
export type GasPriceProductPreviewListInput = {
  odberovePasmo: Scalars['Int']['input'];
};

export type GasUsages = {
  __typename?: 'GasUsages';
  basicAppliances: Maybe<Scalars['Boolean']['output']>;
  heating: Maybe<Scalars['Boolean']['output']>;
  waterHeating: Maybe<Scalars['Boolean']['output']>;
};

export type GasUsagesInput = {
  basicAppliances?: InputMaybe<Scalars['Boolean']['input']>;
  heating?: InputMaybe<Scalars['Boolean']['input']>;
  waterHeating?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Gps = {
  __typename?: 'Gps';
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
};

export type HasPhone = {
  __typename?: 'HasPhone';
  hasPhone: Scalars['Boolean']['output'];
};

export const HouseholdPrice = {
  Cheap: 'CHEAP',
  Expensive: 'EXPENSIVE',
  Medium: 'MEDIUM'
} as const;

export type HouseholdPrice = typeof HouseholdPrice[keyof typeof HouseholdPrice];
export const HouseholdSize = {
  Large: 'LARGE',
  Medium: 'MEDIUM',
  Small: 'SMALL'
} as const;

export type HouseholdSize = typeof HouseholdSize[keyof typeof HouseholdSize];
export type Image = {
  __typename?: 'Image';
  createdAt: Scalars['DateTime']['output'];
  extension: Scalars['String']['output'];
  height: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  namespace: Scalars['String']['output'];
  size: Scalars['Int']['output'];
  type: Scalars['String']['output'];
  uuid: Scalars['UUID']['output'];
  width: Scalars['Int']['output'];
};

export const InsurancePaymentFrequency = {
  HalfYearly: 'HALF_YEARLY',
  Monthly: 'MONTHLY',
  Quarterly: 'QUARTERLY',
  Yearly: 'YEARLY'
} as const;

export type InsurancePaymentFrequency = typeof InsurancePaymentFrequency[keyof typeof InsurancePaymentFrequency];
export type InsuredSubject = {
  __typename?: 'InsuredSubject';
  address: Maybe<Address>;
  birthNumber: Maybe<Scalars['String']['output']>;
  cityPart: Maybe<CityPart>;
  companyIdNumber: Maybe<Scalars['String']['output']>;
  companyName: Maybe<Scalars['String']['output']>;
  correspondenceAddress: Maybe<Address>;
  email: Maybe<Scalars['String']['output']>;
  functionOfRepresentative: Maybe<CodeBook>;
  name: Maybe<Scalars['String']['output']>;
  nameOfRepresentative: Maybe<Scalars['String']['output']>;
  phone: Maybe<Scalars['String']['output']>;
  subjectType: VehicleInsuranceSubjectType;
  surname: Maybe<Scalars['String']['output']>;
  surnameOfRepresentative: Maybe<Scalars['String']['output']>;
  taxIdNumber: Maybe<Scalars['String']['output']>;
  uuid: Scalars['String']['output'];
};

export type InsuredSubjectInput = {
  birthNumber?: InputMaybe<Scalars['String']['input']>;
  cityPart?: InputMaybe<CityPartInput>;
  companyIdNumber?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  functionOfRepresentative?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameOfRepresentative?: InputMaybe<Scalars['String']['input']>;
  subjectType?: InputMaybe<VehicleInsuranceSubjectType>;
  surname?: InputMaybe<Scalars['String']['input']>;
  surnameOfRepresentative?: InputMaybe<Scalars['String']['input']>;
  taxIdNumber?: InputMaybe<Scalars['String']['input']>;
};

export type InsuredVehicle = {
  __typename?: 'InsuredVehicle';
  additionalEquipment: Array<CodeBook>;
  carUsageMethod: Maybe<CodeBook>;
  contractualCarService: Scalars['Boolean']['output'];
  countOfSeats: Maybe<CodeBook>;
  engineDisplacement: Maybe<CodeBook>;
  expectedKilometersPerYear: Maybe<CodeBook>;
  firstRegistrationDate: Maybe<Scalars['String']['output']>;
  fuel: Maybe<CodeBook>;
  kilometersTotal: Scalars['Int']['output'];
  manufacturer: Maybe<CodeBook>;
  maxPower: Maybe<CodeBook>;
  maxWeight: Maybe<CodeBook>;
  model: Maybe<CodeBook>;
  modelLine: Maybe<CodeBook>;
  modelType: Maybe<CodeBook>;
  newVehicle: Scalars['Boolean']['output'];
  numberOfRegistrationCertificate: Maybe<Scalars['String']['output']>;
  ownedMoreThanAYear: Scalars['Boolean']['output'];
  registrationNumber: Maybe<Scalars['String']['output']>;
  security: VehicleSecurity;
  uuid: Scalars['String']['output'];
  vehicleSearchStatus: VehicleInsuranceVehicleSearchStatus;
  vehicleType: VehicleType;
  vehicleValue: Maybe<Scalars['Int']['output']>;
  vehicleValueWithVat: Scalars['Boolean']['output'];
  vin: Maybe<Scalars['String']['output']>;
};

export type InsuredVehicleInput = {
  additionalEquipment?: InputMaybe<Array<Scalars['Int']['input']>>;
  carUsageMethod?: InputMaybe<Scalars['Int']['input']>;
  contractualCarService?: InputMaybe<Scalars['Boolean']['input']>;
  countOfSeats?: InputMaybe<CodeBookInput>;
  engineDisplacement?: InputMaybe<CodeBookInput>;
  expectedKilometersPerYear?: InputMaybe<CodeBookInput>;
  firstRegistrationDate?: InputMaybe<Scalars['String']['input']>;
  fuel?: InputMaybe<CodeBookInput>;
  kilometersTotal?: InputMaybe<Scalars['Int']['input']>;
  manufacturer?: InputMaybe<CodeBookInput>;
  maxPower?: InputMaybe<CodeBookInput>;
  maxWeight?: InputMaybe<CodeBookInput>;
  model?: InputMaybe<CodeBookInput>;
  modelLine?: InputMaybe<CodeBookInput>;
  modelType?: InputMaybe<CodeBookInput>;
  registrationNumber?: InputMaybe<Scalars['String']['input']>;
  security?: InputMaybe<VehicleSecurityInput>;
  vehicleType?: InputMaybe<VehicleType>;
  vehicleValue?: InputMaybe<Scalars['Int']['input']>;
  vehicleValueWithVat?: InputMaybe<Scalars['Boolean']['input']>;
  vin?: InputMaybe<Scalars['String']['input']>;
};

export const InternetCommitment = {
  WithoutCommitment: 'WITHOUT_COMMITMENT',
  Years_2: 'YEARS_2'
} as const;

export type InternetCommitment = typeof InternetCommitment[keyof typeof InternetCommitment];
export const InternetDevice = {
  Installment: 'INSTALLMENT',
  Onetime: 'ONETIME',
  Rent: 'RENT',
  WithoutCommitment: 'WITHOUT_COMMITMENT'
} as const;

export type InternetDevice = typeof InternetDevice[keyof typeof InternetDevice];
export type InternetProduct = {
  __typename?: 'InternetProduct';
  commitment: Maybe<InternetCommitment>;
  description: Maybe<Scalars['String']['output']>;
  device: Maybe<InternetDevice>;
  downloadSpeed: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  internetProductLine: InternetProductLine;
  metaDescription: Maybe<Scalars['String']['output']>;
  metaTitle: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  priceDevice: Maybe<Money>;
  priceForCombination: Maybe<Money>;
  priceStandard: Money;
  tags: Array<TelcoTag>;
  technology: InternetTechnology;
  uploadSpeed: Maybe<Scalars['Int']['output']>;
};

export type InternetProductLine = {
  __typename?: 'InternetProductLine';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  partner: TelcoPartner;
};

export const InternetSpeed = {
  Min_20: 'MIN_20',
  Min_100: 'MIN_100',
  Min_500: 'MIN_500'
} as const;

export type InternetSpeed = typeof InternetSpeed[keyof typeof InternetSpeed];
export const InternetTechnology = {
  Dsl: 'DSL',
  Koax: 'KOAX',
  Optical: 'OPTICAL',
  OpticalCetin: 'OPTICAL_CETIN',
  Wifi: 'WIFI'
} as const;

export type InternetTechnology = typeof InternetTechnology[keyof typeof InternetTechnology];
export const InternetTypeOfUser = {
  Demanding: 'DEMANDING',
  Medium: 'MEDIUM',
  Undemanding: 'UNDEMANDING'
} as const;

export type InternetTypeOfUser = typeof InternetTypeOfUser[keyof typeof InternetTypeOfUser];
export const JumbotronType = {
  Buttons: 'BUTTONS',
  Campaign: 'CAMPAIGN',
  DocumentsUpload: 'DOCUMENTS_UPLOAD',
  EmailGathering: 'EMAIL_GATHERING',
  EnergoForm: 'ENERGO_FORM',
  EnergoFormGreen: 'ENERGO_FORM_GREEN',
  EnergoFormWolf: 'ENERGO_FORM_WOLF',
  FinanceForm: 'FINANCE_FORM',
  FinanceFormWolf: 'FINANCE_FORM_WOLF',
  InvoiceUpload: 'INVOICE_UPLOAD',
  InvoiceUploadWithEmail: 'INVOICE_UPLOAD_WITH_EMAIL',
  OptionalInvoiceUpload: 'OPTIONAL_INVOICE_UPLOAD',
  PropertyInsuranceHouseholdForm: 'PROPERTY_INSURANCE_HOUSEHOLD_FORM',
  PropertyInsurancePropertyForm: 'PROPERTY_INSURANCE_PROPERTY_FORM',
  PropertyInsurancePropertyFormWolf: 'PROPERTY_INSURANCE_PROPERTY_FORM_WOLF',
  Registration: 'REGISTRATION',
  RegistrationCallcenter: 'REGISTRATION_CALLCENTER',
  SeoPage: 'SEO_PAGE',
  TelcoGeneral: 'TELCO_GENERAL',
  TelcoInternet: 'TELCO_INTERNET',
  TelcoInternetSpeed: 'TELCO_INTERNET_SPEED',
  TelcoMobile: 'TELCO_MOBILE',
  TelcoTv: 'TELCO_TV',
  TravelInsuranceForm: 'TRAVEL_INSURANCE_FORM',
  VehicleInsuranceContractDismissalTemplateForm: 'VEHICLE_INSURANCE_CONTRACT_DISMISSAL_TEMPLATE_FORM',
  VehicleInsuranceContractDismissalTemplateFormWolf: 'VEHICLE_INSURANCE_CONTRACT_DISMISSAL_TEMPLATE_FORM_WOLF',
  VehicleInsuranceForm: 'VEHICLE_INSURANCE_FORM',
  VehicleInsuranceFormGreen: 'VEHICLE_INSURANCE_FORM_GREEN',
  VehicleInsuranceFormWolf: 'VEHICLE_INSURANCE_FORM_WOLF',
  WithCallback: 'WITH_CALLBACK'
} as const;

export type JumbotronType = typeof JumbotronType[keyof typeof JumbotronType];
export type KnowledgebaseCategory = {
  __typename?: 'KnowledgebaseCategory';
  description: Maybe<Scalars['String']['output']>;
  icon: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  metaDescription: Scalars['String']['output'];
  metaImage: Maybe<File>;
  metaTitle: Scalars['String']['output'];
  name: Scalars['String']['output'];
  parents: Array<KnowledgebaseCategoryPreview>;
  priority: Scalars['Int']['output'];
  questions: Maybe<Array<KnowledgebaseQuestionPreview>>;
  subCategories: Maybe<Array<KnowledgebaseCategoryPreview>>;
  subVertical: SubVertical;
  titleSuffix: Maybe<Scalars['String']['output']>;
  vertical: Vertical;
};

export type KnowledgebaseCategoryPreview = {
  __typename?: 'KnowledgebaseCategoryPreview';
  description: Maybe<Scalars['String']['output']>;
  icon: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  parents: Array<KnowledgebaseCategoryPreview>;
  priority: Scalars['Int']['output'];
  subVertical: SubVertical;
  vertical: Vertical;
};

export type KnowledgebaseFultextInput = {
  searchTerm: Scalars['String']['input'];
};

export type KnowledgebaseGetByTagsInput = {
  tags: Array<Scalars['Int']['input']>;
  type: QuestionType;
};

export type KnowledgebaseGetByTypeInput = {
  categoryId?: InputMaybe<Scalars['Int']['input']>;
  type: QuestionType;
};

export type KnowledgebaseQuestionDetail = {
  __typename?: 'KnowledgebaseQuestionDetail';
  author: Maybe<Scalars['String']['output']>;
  category: Maybe<KnowledgebaseCategoryPreview>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  metaDescription: Scalars['String']['output'];
  metaImage: Maybe<File>;
  metaTitle: Scalars['String']['output'];
  normalizedContent: Scalars['String']['output'];
  perex: Maybe<Scalars['String']['output']>;
  publicVisible: Scalars['Boolean']['output'];
  publishedAt: Scalars['DateTime']['output'];
  redirectTo: Maybe<Scalars['String']['output']>;
  sections: Array<ContentSection>;
  seoName: Scalars['String']['output'];
  similar: Maybe<Array<KnowledgebaseQuestionPreview>>;
  subVertical: SubVertical;
  tags: Maybe<Array<KnowledgebaseTag>>;
  title: Scalars['String']['output'];
  titleSuffix: Maybe<Scalars['String']['output']>;
  type: Maybe<QuestionType>;
  vertical: Vertical;
};

export type KnowledgebaseQuestionPreview = {
  __typename?: 'KnowledgebaseQuestionPreview';
  author: Maybe<Scalars['String']['output']>;
  category: Maybe<KnowledgebaseCategoryPreview>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  normalizedContent: Scalars['String']['output'];
  perex: Maybe<Scalars['String']['output']>;
  publishedAt: Scalars['DateTime']['output'];
  seoName: Scalars['String']['output'];
  tags: Maybe<Array<KnowledgebaseTag>>;
  title: Scalars['String']['output'];
  type: Maybe<QuestionType>;
  updatedAt: Scalars['DateTime']['output'];
};

export type KnowledgebaseQuestionPreviewWithContent = {
  __typename?: 'KnowledgebaseQuestionPreviewWithContent';
  author: Maybe<Scalars['String']['output']>;
  category: Maybe<KnowledgebaseCategoryPreview>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  normalizedContent: Scalars['String']['output'];
  perex: Maybe<Scalars['String']['output']>;
  publishedAt: Scalars['DateTime']['output'];
  sections: Array<ContentSection>;
  seoName: Scalars['String']['output'];
  tags: Maybe<Array<KnowledgebaseTag>>;
  title: Scalars['String']['output'];
  type: Maybe<QuestionType>;
  updatedAt: Scalars['DateTime']['output'];
};

export type KnowledgebaseTag = {
  __typename?: 'KnowledgebaseTag';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  seoName: Scalars['String']['output'];
};

export type LandingPageData = {
  __typename?: 'LandingPageData';
  dedicatedUrl: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  metaDescription: Maybe<Scalars['String']['output']>;
  metaImage: Maybe<File>;
  metaTitle: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  redirectTo: Maybe<Scalars['String']['output']>;
  sections: Array<ContentSection>;
  seoName: Scalars['String']['output'];
  subVertical: SubVertical;
  uuid: Scalars['String']['output'];
  vertical: Vertical;
};

export type LandingPageSiteMapItem = {
  __typename?: 'LandingPageSiteMapItem';
  dedicatedUrl: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  seoName: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  uuid: Scalars['String']['output'];
};

export type Lead = {
  __typename?: 'Lead';
  address: Maybe<Address>;
  attachments: Array<LeadAttachment>;
  completedAt: Maybe<Scalars['DateTime']['output']>;
  contactAddress: Maybe<Address>;
  createdAt: Scalars['DateTime']['output'];
  customerNote: Maybe<Scalars['String']['output']>;
  email: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  lastStatusChange: Maybe<Scalars['DateTime']['output']>;
  name: Maybe<Scalars['String']['output']>;
  phone: Maybe<Scalars['String']['output']>;
  sendEmailImmediately: Maybe<Scalars['Boolean']['output']>;
  status: Maybe<LeadStatus>;
  surname: Maybe<Scalars['String']['output']>;
  type: LeadType;
  uuid: Scalars['String']['output'];
};

export type LeadAddFilesInput = {
  files: Array<Scalars['String']['input']>;
};

export type LeadAttachment = {
  __typename?: 'LeadAttachment';
  file: File;
  id: Scalars['Int']['output'];
};

export type LeadCompleteInput = {
  files: Array<Scalars['String']['input']>;
};

export type LeadContractDataInput = {
  address?: InputMaybe<Scalars['Int']['input']>;
  bankAccount?: InputMaybe<Scalars['String']['input']>;
  birthDate?: InputMaybe<Scalars['String']['input']>;
  birthNumber?: InputMaybe<Scalars['String']['input']>;
  permanentAddress?: InputMaybe<Scalars['Int']['input']>;
  preferredPaymentMethod?: InputMaybe<PreferredPaymentMethod>;
  sendInvoiceMethod?: InputMaybe<SendInvoiceMethod>;
  sipoNumber?: InputMaybe<Scalars['String']['input']>;
};

export const LeadStatus = {
  Cancel: 'CANCEL',
  ContractPayed: 'CONTRACT_PAYED',
  EnergoAkceptaceSmlouvy: 'ENERGO_AKCEPTACE_SMLOUVY',
  EnergoNabidka: 'ENERGO_NABIDKA',
  EnergoPodpisSmlouvy: 'ENERGO_PODPIS_SMLOUVY',
  EnergoPredaniDodavateli: 'ENERGO_PREDANI_DODAVATELI',
  EnergoSmlouva: 'ENERGO_SMLOUVA',
  EnergoSpici: 'ENERGO_SPICI',
  EnergoVyberTarifu: 'ENERGO_VYBER_TARIFU',
  EnergoVyberTarifuCekameNaFakturu: 'ENERGO_VYBER_TARIFU_CEKAME_NA_FAKTURU',
  EnergoZahajeniDodavky: 'ENERGO_ZAHAJENI_DODAVKY',
  EnergoZruseno: 'ENERGO_ZRUSENO',
  InquiryCreated: 'INQUIRY_CREATED',
  Offer: 'OFFER',
  Order: 'ORDER'
} as const;

export type LeadStatus = typeof LeadStatus[keyof typeof LeadStatus];
export const LeadType = {
  Electricity: 'ELECTRICITY',
  Gas: 'GAS'
} as const;

export type LeadType = typeof LeadType[keyof typeof LeadType];
export type LoginType = {
  __typename?: 'LoginType';
  type: LoginTypeEnum;
};

export const LoginTypeEnum = {
  MagicklinkSent: 'MAGICKLINK_SENT',
  PasswordRequired: 'PASSWORD_REQUIRED',
  UserUnknown: 'USER_UNKNOWN'
} as const;

export type LoginTypeEnum = typeof LoginTypeEnum[keyof typeof LoginTypeEnum];
export type MarketingMarketingProfileSaveInput = {
  eventCategory?: InputMaybe<Scalars['String']['input']>;
  eventName: Scalars['String']['input'];
  eventSource: Scalars['String']['input'];
  eventValueDatetime1?: InputMaybe<Scalars['String']['input']>;
  eventValueDatetime2?: InputMaybe<Scalars['String']['input']>;
  eventValueDatetime3?: InputMaybe<Scalars['String']['input']>;
  eventValueFloat1?: InputMaybe<Scalars['Int']['input']>;
  eventValueFloat2?: InputMaybe<Scalars['Int']['input']>;
  eventValueFloat3?: InputMaybe<Scalars['Int']['input']>;
  eventValueText1?: InputMaybe<Scalars['String']['input']>;
  eventValueText2?: InputMaybe<Scalars['String']['input']>;
  eventValueText3?: InputMaybe<Scalars['String']['input']>;
  eventValueText4?: InputMaybe<Scalars['String']['input']>;
  landingUrl?: InputMaybe<Scalars['String']['input']>;
  utmCampaign?: InputMaybe<Scalars['String']['input']>;
  utmMedium?: InputMaybe<Scalars['String']['input']>;
  utmSource?: InputMaybe<Scalars['String']['input']>;
};

export const MethodOfSendingContract = {
  Courier: 'COURIER',
  Email: 'EMAIL',
  Online: 'ONLINE',
  Post: 'POST'
} as const;

export type MethodOfSendingContract = typeof MethodOfSendingContract[keyof typeof MethodOfSendingContract];
export const MobileCommitment = {
  WithoutCommitment: 'WITHOUT_COMMITMENT',
  Years_2: 'YEARS_2'
} as const;

export type MobileCommitment = typeof MobileCommitment[keyof typeof MobileCommitment];
export const MobileIncludedData = {
  Min_1: 'MIN_1',
  Min_5: 'MIN_5',
  Min_10: 'MIN_10'
} as const;

export type MobileIncludedData = typeof MobileIncludedData[keyof typeof MobileIncludedData];
export const MobileIncludedMinutes = {
  Min_0: 'MIN_0',
  Min_150: 'MIN_150',
  Unlimited: 'UNLIMITED'
} as const;

export type MobileIncludedMinutes = typeof MobileIncludedMinutes[keyof typeof MobileIncludedMinutes];
export type MobileProduct = {
  __typename?: 'MobileProduct';
  commitment: Maybe<MobileCommitment>;
  dataSpeed: Maybe<Scalars['String']['output']>;
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  includedData: Maybe<Scalars['Float']['output']>;
  includedMinutes: Maybe<Scalars['Int']['output']>;
  includedSms: Maybe<Scalars['Int']['output']>;
  metaDescription: Maybe<Scalars['String']['output']>;
  metaTitle: Maybe<Scalars['String']['output']>;
  mobileProductLine: MobileProductLine;
  name: Scalars['String']['output'];
  priceCall: Maybe<Money>;
  priceData: Maybe<Money>;
  priceForCombination: Maybe<Money>;
  priceSms: Maybe<Money>;
  priceStandard: Money;
  tags: Array<TelcoTag>;
  unlimitedData: Scalars['Boolean']['output'];
  unlimitedMinutes: Scalars['Boolean']['output'];
  unlimitedSms: Scalars['Boolean']['output'];
};

export type MobileProductLine = {
  __typename?: 'MobileProductLine';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  partner: TelcoPartner;
};

export type Money = {
  __typename?: 'Money';
  amount: Scalars['String']['output'];
  currency: Currency;
};

export type MoneyInput = {
  amount: Scalars['String']['input'];
  currency: Currency;
};

export const MonthlyIncomeBrackets = {
  From1To5000: 'FROM1_TO5000',
  From5001To10000: 'FROM5001_TO10000',
  From10001To15000: 'FROM10001_TO15000',
  From15001To20000: 'FROM15001_TO20000',
  From20001To30000: 'FROM20001_TO30000',
  From30001To50000: 'FROM30001_TO50000',
  From50001To75000: 'FROM50001_TO75000',
  More: 'MORE'
} as const;

export type MonthlyIncomeBrackets = typeof MonthlyIncomeBrackets[keyof typeof MonthlyIncomeBrackets];
export const MortgagePurpose = {
  AmericanMortgage: 'AMERICAN_MORTGAGE',
  RealEstateConstruction: 'REAL_ESTATE_CONSTRUCTION',
  RealEstatePurchase: 'REAL_ESTATE_PURCHASE',
  RealEstateReconstruction: 'REAL_ESTATE_RECONSTRUCTION',
  Refinancing: 'REFINANCING'
} as const;

export type MortgagePurpose = typeof MortgagePurpose[keyof typeof MortgagePurpose];
export type Mutation = {
  __typename?: 'Mutation';
  /** Accept energo offer */
  acceptEnergoOffer: CustomerZoneCalculationDetail;
  /** Profile - add contact */
  addProfileContact: Maybe<Scalars['Boolean']['output']>;
  /** Profile - add email */
  addProfileEmail: MyProfile;
  /** Profile - add email */
  addProfilePhone: MyProfile;
  /** Activate benefits detail */
  benefitActivate: Benefit;
  callbackCreateCallback: CallbackZoneCallback;
  /** Callback - send email */
  callbackSendEmail: Callback;
  /** Activate campaign for logged profile-uuid */
  campaignActivate: Scalars['Boolean']['output'];
  /** Cancel lead */
  cancelLead: Lead;
  /** Create magic link */
  createMagicLink: Maybe<Scalars['Boolean']['output']>;
  createPropertyInsuranceInquiry: PropertyInsuranceOrder;
  /** Add files to lead */
  customerLeadAddFiles: Lead;
  /** Decline energo offer */
  declineEnergoOffer: EnergoOffer;
  /** Calculation - change source (return calculation with new uuid) */
  energoCalculationChangeSource: EnergoCalculation;
  /** Calculation - create */
  energoCalculationCreate: EnergoCalculation;
  /** Calculation - create from StandaloneInvoice */
  energoCalculationCreateFromStandaloneInvoice: EnergoCalculation;
  /** Calculation - update */
  energoCalculationUpdate: EnergoCalculation;
  /** Add files to energo contract */
  energoContractAttachments: CustomerZoneCalculationDetail;
  /** Energo contract - save personal data */
  energoContractSave: CustomerZoneCalculationDetail;
  /** Electricity Lead - create */
  energoElectricityLeadCreate: EnergoElectricityLead;
  /** Electricity Lead - save personal data */
  energoElectricityLeadSavePersonalData: EnergoElectricityLead;
  /** Gas Lead - create */
  energoGasLeadCreate: EnergoGasLead;
  /** Gas Lead - save personal data */
  energoGasLeadSavePersonalData: EnergoGasLead;
  /** StandaloneInvoice - create */
  energoStandaloneInvoiceCreate: EnergoStandaloneInvoice;
  /** StandaloneInvoice - create from LandingPage */
  energoStandaloneInvoiceCreateFromLandingPage: EnergoStandaloneInvoice;
  /** StandaloneInvoice - create from WolfPage */
  energoStandaloneInvoiceCreateFromWolfPage: EnergoStandaloneInvoice;
  /** StandaloneInvoice - create from LandingPage with email */
  energoStandaloneInvoiceWithEmailCreateFromLandingPage: EnergoStandaloneInvoice;
  /** StandaloneInvoice - create from LandingPage optional */
  energoStandaloneOptionalInvoiceCreateFromLandingPage: CoreStatus;
  financeCalculationCreate: FinanceCalculation;
  financeCalculationProductClick: Maybe<CoreStatus>;
  financeCalculationUpdate: FinanceCalculation;
  health: Scalars['Boolean']['output'];
  initPropertyInsuranceOrder: PropertyInsuranceOrder;
  /** Register user from landing page (only for call center) */
  landingPageRegister: Scalars['Boolean']['output'];
  /** Add files to lead */
  leadComplete: Lead;
  /** Password login */
  login: Maybe<Scalars['Boolean']['output']>;
  /** Login type */
  loginType: LoginType;
  /** Change user's password */
  passwordChange: Maybe<MyProfile>;
  /** Send me password change email */
  passwordChangeEmail: Maybe<CoreStatus>;
  /** Profile - add customer zone intro seen at */
  profileCustomerZoneIntroSeen: Scalars['Boolean']['output'];
  /** Calculation link - backward compatibility */
  publicEnergoCalculationLink: Scalars['Boolean']['output'];
  /** Password registration */
  registration: Maybe<Scalars['Boolean']['output']>;
  /** Registration with email only */
  registrationByEmail: Maybe<CoreStatus>;
  /** Save energo contract data */
  saveContractDataSave: CustomerZoneCalculationDetail;
  /** Save lead contract data */
  saveLeadContractData: Lead;
  /** Profile - edit */
  saveMarketingProfile: Scalars['Boolean']['output'];
  selectPropertyInsuranceOffer: PropertyInsuranceOrder;
  /** Send me magic link */
  sendMeMagicLink: Maybe<Scalars['Boolean']['output']>;
  startPropertyInsuranceCalculation: CoreStatus;
  telcoCalculationCreate: TelcoCalculation;
  telcoCalculationUpdate: TelcoCalculation;
  telcoLeadCreate: TelcoLead;
  telcoLeadUpdate: TelcoLead;
  travelInsuranceOrderCalculationStart: Scalars['Boolean']['output'];
  travelInsuranceOrderInit: TravelInsuranceOrder;
  travelInsuranceOrderSelectOffer: TravelInsuranceOrder;
  travelInsuranceOrderUpdate: TravelInsuranceOrder;
  travelInsuranceUpdateInsuredPersons: TravelInsuranceOrder;
  travelInsuranceUpdatePolicyHolder: TravelInsuranceOrder;
  /** update profile benefit */
  updateBenefit: Maybe<Benefit>;
  /** Profile - edit */
  updateProfile: MyProfile;
  updatePropertyInsuranceOrder: PropertyInsuranceOrder;
  /** Upload file */
  uploadFile: File;
  vehicleInsuranceChangeBeginDate: VehicleInsuranceOrder;
  vehicleInsuranceContractRecalculate: Scalars['Boolean']['output'];
  vehicleInsuranceCreateCallback: VehicleInsuranceCallback;
  vehicleInsuranceOfferSetPaymentFrequency: VehicleInsuranceProductOffer;
  vehicleInsuranceOrderCalculationSelectOffer: VehicleInsuranceOrder;
  vehicleInsuranceOrderCalculationStart: Scalars['Boolean']['output'];
  vehicleInsuranceOrderCalculationStartWithSpecificInsuranceType: VehicleInsuranceOrder;
  vehicleInsuranceOrderContractUpdateAdditionalInfo: VehicleInsuranceOrder;
  vehicleInsuranceOrderCopy: VehicleInsuranceOrder;
  vehicleInsuranceOrderInit: VehicleInsuranceOrder;
  vehicleInsuranceOrderInquiryCreate: VehicleInsuranceOrder;
  vehicleInsuranceOrderSaveContactPerson: VehicleInsuranceOrder;
  vehicleInsuranceOrderUpdate: VehicleInsuranceOrder;
  /**
   * Backward compatibility (links from email)
   * @deprecated Field no longer supported
   */
  vehicleInsurancePublicCalculationLink: Scalars['Boolean']['output'];
  vehicleInsuranceSetCalculationCurrentStep: VehicleInsuranceOrder;
  vehicleInsuranceSetOrderCurrentStep: VehicleInsuranceOrder;
  vehicleInsuranceSetOrderStatus: Scalars['Boolean']['output'];
  vehicleInsuranceVehicleFindByRegistrationNumber: VehicleFindByRegistrationNumberResponse;
  vehicleInsuranceVehicleSearchParameters: VehicleSearchParametersResponse;
  watchWolfActivate: Scalars['Boolean']['output'];
  watchWolfCreate: WatchWolf;
  watchWolfCreateCalculation: WatchWolf;
  watchWolfDelete: WatchWolf;
  watchWolfUpdate: WatchWolf;
};


export type MutationAcceptEnergoOfferArgs = {
  energoOffer: Scalars['Int']['input'];
};


export type MutationAddProfileContactArgs = {
  data: ProfileAddContact;
};


export type MutationAddProfileEmailArgs = {
  data: ProfileAddEmail;
};


export type MutationAddProfilePhoneArgs = {
  data: ProfileAddPhone;
};


export type MutationBenefitActivateArgs = {
  uuid: Scalars['String']['input'];
};


export type MutationCallbackCreateCallbackArgs = {
  data: CreateCallbackInput;
};


export type MutationCallbackSendEmailArgs = {
  id: Scalars['Int']['input'];
};


export type MutationCampaignActivateArgs = {
  campaignUuid: Scalars['String']['input'];
};


export type MutationCancelLeadArgs = {
  leadUuid: Scalars['String']['input'];
};


export type MutationCreateMagicLinkArgs = {
  email: Scalars['String']['input'];
};


export type MutationCreatePropertyInsuranceInquiryArgs = {
  data: PropertyInsuranceContactPersonInput;
  uuid: Scalars['String']['input'];
};


export type MutationCustomerLeadAddFilesArgs = {
  data: LeadAddFilesInput;
  leadUuid: Scalars['String']['input'];
};


export type MutationDeclineEnergoOfferArgs = {
  energoOffer: Scalars['Int']['input'];
};


export type MutationEnergoCalculationChangeSourceArgs = {
  calculationUuid: Scalars['String']['input'];
  source: EnergoCalculationSource;
};


export type MutationEnergoCalculationCreateArgs = {
  data: EnergoCreateCalculationInput;
};


export type MutationEnergoCalculationCreateFromStandaloneInvoiceArgs = {
  data: EnergoCreateCalculationFromStandaloneInput;
};


export type MutationEnergoCalculationUpdateArgs = {
  calculationUuid: Scalars['String']['input'];
  data: EnergoUpdateCalculationInput;
};


export type MutationEnergoContractAttachmentsArgs = {
  calculationUuid: Scalars['String']['input'];
  data: LeadCompleteInput;
};


export type MutationEnergoContractSaveArgs = {
  calculationUuid: Scalars['String']['input'];
  data: EnergoContractPersonalDataInput;
};


export type MutationEnergoElectricityLeadCreateArgs = {
  calculationUuid: Scalars['String']['input'];
  selectedProductId: Scalars['Int']['input'];
};


export type MutationEnergoElectricityLeadSavePersonalDataArgs = {
  data: EnergoLeadPersonalDataInput;
  leadUuid: Scalars['String']['input'];
};


export type MutationEnergoGasLeadCreateArgs = {
  calculationUuid: Scalars['String']['input'];
  selectedProductId: Scalars['Int']['input'];
};


export type MutationEnergoGasLeadSavePersonalDataArgs = {
  data: EnergoLeadPersonalDataInput;
  leadUuid: Scalars['String']['input'];
};


export type MutationEnergoStandaloneInvoiceCreateArgs = {
  data: EnergoStandaloneInvoiceCreateInput;
};


export type MutationEnergoStandaloneInvoiceCreateFromLandingPageArgs = {
  data: EnergoCreateStandaloneInvoiceFromLandingPageInput;
};


export type MutationEnergoStandaloneInvoiceCreateFromWolfPageArgs = {
  data: EnergoCreateStandaloneInvoiceFromWolfPageInput;
};


export type MutationEnergoStandaloneInvoiceWithEmailCreateFromLandingPageArgs = {
  data: EnergoCreateStandaloneInvoiceWithEmailFromLandingPageInput;
};


export type MutationEnergoStandaloneOptionalInvoiceCreateFromLandingPageArgs = {
  data: EnergoCreateStandaloneOptionalInvoiceFromLandingPageInput;
};


export type MutationFinanceCalculationCreateArgs = {
  subVertical: SubVertical;
};


export type MutationFinanceCalculationProductClickArgs = {
  calculationUuid: InputMaybe<Scalars['UUID']['input']>;
  product: Scalars['Int']['input'];
  referer: InputMaybe<Scalars['String']['input']>;
};


export type MutationFinanceCalculationUpdateArgs = {
  data: FinanceCalculationUpdateInput;
  uuid: Scalars['UUID']['input'];
};


export type MutationInitPropertyInsuranceOrderArgs = {
  data: PropertyInsuranceOrderInitInput;
};


export type MutationLandingPageRegisterArgs = {
  data: RegistrationInput;
};


export type MutationLeadCompleteArgs = {
  data: LeadCompleteInput;
  leadUuid: Scalars['String']['input'];
};


export type MutationLoginArgs = {
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationLoginTypeArgs = {
  email: Scalars['String']['input'];
};


export type MutationPasswordChangeArgs = {
  password: Scalars['String']['input'];
};


export type MutationPasswordChangeEmailArgs = {
  email: Scalars['String']['input'];
};


export type MutationPublicEnergoCalculationLinkArgs = {
  calculationUuid: Scalars['String']['input'];
  profileUuid: Scalars['String']['input'];
};


export type MutationRegistrationArgs = {
  data: RegistrationInput;
};


export type MutationRegistrationByEmailArgs = {
  data: RegistrationByEmailInput;
};


export type MutationSaveContractDataSaveArgs = {
  calculationUuid: Scalars['String']['input'];
  data: EnergoContractDataInput;
};


export type MutationSaveLeadContractDataArgs = {
  data: LeadContractDataInput;
  leadUuid: Scalars['String']['input'];
};


export type MutationSaveMarketingProfileArgs = {
  data: MarketingMarketingProfileSaveInput;
};


export type MutationSelectPropertyInsuranceOfferArgs = {
  data: PropertyInsuranceOrderOfferSelectInput;
  uuid: Scalars['String']['input'];
};


export type MutationStartPropertyInsuranceCalculationArgs = {
  uuid: Scalars['String']['input'];
};


export type MutationTelcoCalculationCreateArgs = {
  data: TelcoCalculationCreateInput;
};


export type MutationTelcoCalculationUpdateArgs = {
  data: TelcoCalculationUpdateInput;
  uuid: Scalars['UUID']['input'];
};


export type MutationTelcoLeadCreateArgs = {
  data: TelcoLeadCreateInput;
};


export type MutationTelcoLeadUpdateArgs = {
  data: TelcoLeadUpdateInput;
  lead: Scalars['UUID']['input'];
};


export type MutationTravelInsuranceOrderCalculationStartArgs = {
  uuid: Scalars['String']['input'];
};


export type MutationTravelInsuranceOrderInitArgs = {
  data: TravelInsuranceOrderInitInput;
};


export type MutationTravelInsuranceOrderSelectOfferArgs = {
  data: TravelInsuranceSelectOfferInput;
  uuid: Scalars['String']['input'];
};


export type MutationTravelInsuranceOrderUpdateArgs = {
  data: TravelInsuranceOrderUpdateInput;
  uuid: Scalars['String']['input'];
};


export type MutationTravelInsuranceUpdateInsuredPersonsArgs = {
  data: TravelInsuranceInsuredPersonsInput;
  uuid: Scalars['String']['input'];
};


export type MutationTravelInsuranceUpdatePolicyHolderArgs = {
  data: TravelInsurancePolicyHolderInput;
  uuid: Scalars['String']['input'];
};


export type MutationUpdateBenefitArgs = {
  data: UpdateProfileBenefitInput;
};


export type MutationUpdateProfileArgs = {
  data: ProfileInput;
};


export type MutationUpdatePropertyInsuranceOrderArgs = {
  data: PropertyInsuranceOrderUpdateInput;
  uuid: Scalars['String']['input'];
};


export type MutationUploadFileArgs = {
  file: FileInput;
  namespace: InputMaybe<Scalars['String']['input']>;
};


export type MutationVehicleInsuranceChangeBeginDateArgs = {
  data: VehicleInsuranceChangeBeginDateInput;
  orderUuid: Scalars['String']['input'];
};


export type MutationVehicleInsuranceContractRecalculateArgs = {
  orderUuid: Scalars['String']['input'];
};


export type MutationVehicleInsuranceCreateCallbackArgs = {
  data: CreateVehicleInsuranceCallbackInput;
};


export type MutationVehicleInsuranceOfferSetPaymentFrequencyArgs = {
  data: VehicleInsuranceOfferSetPaymentFrequencyInput;
  id: Scalars['Int']['input'];
};


export type MutationVehicleInsuranceOrderCalculationSelectOfferArgs = {
  data: VehicleInsuranceSelectOfferInput;
  uuid: Scalars['String']['input'];
};


export type MutationVehicleInsuranceOrderCalculationStartArgs = {
  uuid: Scalars['String']['input'];
};


export type MutationVehicleInsuranceOrderCalculationStartWithSpecificInsuranceTypeArgs = {
  data: VehicleInsuranceCalculationStartWithSpecificInsuranceTypeInput;
  uuid: Scalars['String']['input'];
};


export type MutationVehicleInsuranceOrderContractUpdateAdditionalInfoArgs = {
  data: VehicleInsuranceContractAdditionalInfoInput;
  uuid: Scalars['String']['input'];
};


export type MutationVehicleInsuranceOrderCopyArgs = {
  orderUuid: Scalars['String']['input'];
};


export type MutationVehicleInsuranceOrderInitArgs = {
  data: VehicleInsuranceOrderInitInput;
};


export type MutationVehicleInsuranceOrderInquiryCreateArgs = {
  data: VehicleInsuranceInquiryCreateInput;
  uuid: Scalars['String']['input'];
};


export type MutationVehicleInsuranceOrderSaveContactPersonArgs = {
  data: VehicleInsuranceContactPersonInput;
  uuid: Scalars['String']['input'];
};


export type MutationVehicleInsuranceOrderUpdateArgs = {
  data: VehicleInsuranceOrderUpdateInput;
  uuid: Scalars['String']['input'];
};


export type MutationVehicleInsurancePublicCalculationLinkArgs = {
  calculationUuid: Scalars['String']['input'];
  profileUuid: Scalars['String']['input'];
};


export type MutationVehicleInsuranceSetCalculationCurrentStepArgs = {
  data: VehicleInsuranceSetCalculationCurrentStepInput;
  orderUuid: Scalars['String']['input'];
};


export type MutationVehicleInsuranceSetOrderCurrentStepArgs = {
  data: VehicleInsuranceSetOrderCurrentStepInput;
  orderUuid: Scalars['String']['input'];
};


export type MutationVehicleInsuranceSetOrderStatusArgs = {
  data: VehicleInsuranceSetOrderStatusInput;
  orderUuid: Scalars['String']['input'];
};


export type MutationVehicleInsuranceVehicleFindByRegistrationNumberArgs = {
  data: VehicleFindByRegistrationNumberInput;
};


export type MutationVehicleInsuranceVehicleSearchParametersArgs = {
  data: VehicleSearchParametersInput;
};


export type MutationWatchWolfActivateArgs = {
  watchWolfUuid: Scalars['UUID']['input'];
};


export type MutationWatchWolfCreateArgs = {
  data: CreateWatchWolfInput;
};


export type MutationWatchWolfCreateCalculationArgs = {
  data: WatchWolfCreateCalculationInput;
};


export type MutationWatchWolfDeleteArgs = {
  watchWolfUuid: Scalars['UUID']['input'];
};


export type MutationWatchWolfUpdateArgs = {
  data: WatchWolfUpdateInput;
  watchWolfUuid: Scalars['UUID']['input'];
};

export type MyProfile = {
  __typename?: 'MyProfile';
  activeCampaigns: Array<Campaign>;
  /** @deprecated Field no longer supported */
  backofficeProfile: Maybe<AdminProfile>;
  companyName: Maybe<Scalars['String']['output']>;
  customerZoneIntroSeenAt: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  firstCompleteAddress: Maybe<Address>;
  id: Scalars['Int']['output'];
  isLogged: Scalars['Boolean']['output'];
  name: Maybe<Scalars['String']['output']>;
  notificationPolicy: Maybe<Scalars['Int']['output']>;
  otherEmails: Array<Scalars['String']['output']>;
  otherPhones: Array<Scalars['String']['output']>;
  passwordSet: Maybe<Scalars['Boolean']['output']>;
  phone: Maybe<Scalars['String']['output']>;
  preferedComunication: Maybe<Scalars['String']['output']>;
  role: Array<UserRole>;
  surname: Maybe<Scalars['String']['output']>;
  uuid: Maybe<Scalars['String']['output']>;
  vocative: Maybe<Scalars['String']['output']>;
};

export type NavbarConfig = {
  __typename?: 'NavbarConfig';
  sections: Array<NavbarConfigSection>;
};

export type NavbarConfigInput = {
  sections: Array<NavbarConfigSectionInput>;
};

export type NavbarConfigItem = {
  __typename?: 'NavbarConfigItem';
  href: Scalars['String']['output'];
  label: Scalars['String']['output'];
};

export type NavbarConfigItemInput = {
  href: Scalars['String']['input'];
  label: Scalars['String']['input'];
};

export type NavbarConfigSection = {
  __typename?: 'NavbarConfigSection';
  columns: Array<NavbarConfigSectionColumn>;
  href: Scalars['String']['output'];
  label: Scalars['String']['output'];
};

export type NavbarConfigSectionColumn = {
  __typename?: 'NavbarConfigSectionColumn';
  items: Array<NavbarConfigItem>;
};

export type NavbarConfigSectionColumnInput = {
  items: Array<NavbarConfigItemInput>;
};

export type NavbarConfigSectionInput = {
  columns: Array<NavbarConfigSectionColumnInput>;
  href: Scalars['String']['input'];
  label: Scalars['String']['input'];
};

export type NewRating = {
  __typename?: 'NewRating';
  categories: Array<Maybe<RatingCategory>>;
  id: Scalars['String']['output'];
  total: Scalars['Int']['output'];
};

export type NewSmartAddress = {
  __typename?: 'NewSmartAddress';
  city: Maybe<Scalars['String']['output']>;
  cityPart: Maybe<Scalars['String']['output']>;
  descriptiveNumber: Maybe<Scalars['Int']['output']>;
  district: Maybe<Scalars['String']['output']>;
  gps: Maybe<Gps>;
  id: Scalars['Int']['output'];
  level: Maybe<Scalars['Int']['output']>;
  orientationNumber: Maybe<Scalars['Int']['output']>;
  orientationNumberCharacter: Maybe<Scalars['String']['output']>;
  region: Maybe<Scalars['String']['output']>;
  smartAddressId: Scalars['Int']['output'];
  street: Maybe<Scalars['String']['output']>;
  zip: Maybe<Scalars['Int']['output']>;
};

export const OrderCreatedFrom = {
  Callback: 'CALLBACK',
  CallbackTask: 'CALLBACK_TASK',
  Hotlead: 'HOTLEAD',
  LeadBo: 'LEAD_BO',
  LeadCx: 'LEAD_CX',
  MissedCall: 'MISSED_CALL',
  ReworkOurContract: 'REWORK_OUR_CONTRACT',
  UnfinishedCalculation: 'UNFINISHED_CALCULATION',
  Upsell: 'UPSELL',
  WatchWolf: 'WATCH_WOLF',
  Web: 'WEB'
} as const;

export type OrderCreatedFrom = typeof OrderCreatedFrom[keyof typeof OrderCreatedFrom];
export const PartnerDiscountMethod = {
  TmobilePercents: 'TMOBILE_PERCENTS',
  VodafoneCombination: 'VODAFONE_COMBINATION',
  WithoutDiscounts: 'WITHOUT_DISCOUNTS'
} as const;

export type PartnerDiscountMethod = typeof PartnerDiscountMethod[keyof typeof PartnerDiscountMethod];
export type Person = {
  __typename?: 'Person';
  address: Maybe<Address>;
  age: Maybe<Scalars['Int']['output']>;
  birthDate: Maybe<Scalars['String']['output']>;
  birthNumber: Maybe<Scalars['String']['output']>;
  email: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  insured: Scalars['Boolean']['output'];
  name: Maybe<Scalars['String']['output']>;
  nationality: Maybe<CodeBook>;
  phone: Maybe<Scalars['String']['output']>;
  policyHolder: Scalars['Boolean']['output'];
  surname: Maybe<Scalars['String']['output']>;
  uuid: Scalars['String']['output'];
};

export const PreferredPaymentMethod = {
  Cheque: 'CHEQUE',
  Inkaso: 'INKASO',
  Sipo: 'SIPO',
  Transfer: 'TRANSFER'
} as const;

export type PreferredPaymentMethod = typeof PreferredPaymentMethod[keyof typeof PreferredPaymentMethod];
export type ProfileAddContact = {
  email: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type ProfileAddEmail = {
  email: Scalars['String']['input'];
};

export type ProfileAddPhone = {
  phone: Scalars['String']['input'];
};

export type ProfileBenefit = {
  __typename?: 'ProfileBenefit';
  attachments: Array<BenefitAttachment>;
  id: Scalars['Int']['output'];
  name: Maybe<Scalars['String']['output']>;
  phone: Maybe<Scalars['String']['output']>;
  status: ProfileBenefitStatus;
  surname: Maybe<Scalars['String']['output']>;
};

export const ProfileBenefitStatus = {
  Activated: 'ACTIVATED',
  Approved: 'APPROVED',
  Enabled: 'ENABLED',
  ToApprove: 'TO_APPROVE',
  Used: 'USED'
} as const;

export type ProfileBenefitStatus = typeof ProfileBenefitStatus[keyof typeof ProfileBenefitStatus];
export type ProfileInput = {
  notificationPolicy?: InputMaybe<Scalars['Int']['input']>;
  preferedComunication?: InputMaybe<Scalars['String']['input']>;
};

export const ProfileSource = {
  MtplTermination: 'MTPL_TERMINATION'
} as const;

export type ProfileSource = typeof ProfileSource[keyof typeof ProfileSource];
export type ProfileUser = {
  __typename?: 'ProfileUser';
  email: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export const PropertyBusinessCaseStatus = {
  Calculation: 'CALCULATION',
  Cancelled: 'CANCELLED',
  ContractPayed: 'CONTRACT_PAYED',
  InquiryCreated: 'INQUIRY_CREATED',
  Offer: 'OFFER',
  Order: 'ORDER',
  OrderFilledIn: 'ORDER_FILLED_IN',
  TerminatedWithCommission: 'TERMINATED_WITH_COMMISSION'
} as const;

export type PropertyBusinessCaseStatus = typeof PropertyBusinessCaseStatus[keyof typeof PropertyBusinessCaseStatus];
export const PropertyInsuranceBuildingType = {
  Apartment: 'APARTMENT',
  Cottage: 'COTTAGE',
  House: 'HOUSE'
} as const;

export type PropertyInsuranceBuildingType = typeof PropertyInsuranceBuildingType[keyof typeof PropertyInsuranceBuildingType];
export type PropertyInsuranceCalculationResult = {
  __typename?: 'PropertyInsuranceCalculationResult';
  cheapestOffer: Maybe<PropertyInsuranceProductOffer>;
  id: Scalars['String']['output'];
  order: PropertyInsuranceOrder;
  packages: Array<PropertyInsuranceInsurancePackage>;
  watchWolf: Maybe<WatchWolfPreview>;
};

export type PropertyInsuranceCalculationState = {
  __typename?: 'PropertyInsuranceCalculationState';
  error: Scalars['Boolean']['output'];
  finished: Scalars['Boolean']['output'];
  finishedSteps: Scalars['Int']['output'];
  maximumSteps: Scalars['Int']['output'];
};

export type PropertyInsuranceCalculationStateResult = {
  __typename?: 'PropertyInsuranceCalculationStateResult';
  result: Maybe<PropertyInsuranceCalculationResult>;
  state: PropertyInsuranceCalculationState;
};

export type PropertyInsuranceContactPerson = {
  __typename?: 'PropertyInsuranceContactPerson';
  agreementProcessPersonalData: Scalars['Boolean']['output'];
  agreementTermsAndConditions: Scalars['Boolean']['output'];
  email: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  surname: Scalars['String']['output'];
};

export type PropertyInsuranceContactPersonInput = {
  agreementProcessPersonalData: Scalars['Boolean']['input'];
  agreementTermsAndConditions: Scalars['Boolean']['input'];
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  surname: Scalars['String']['input'];
};

export type PropertyInsuranceInfo = {
  __typename?: 'PropertyInsuranceInfo';
  sumInsuredBuilding: Maybe<Scalars['Int']['output']>;
  sumInsuredHousehold: Maybe<Scalars['Int']['output']>;
};

export type PropertyInsuranceInsurancePackage = {
  __typename?: 'PropertyInsuranceInsurancePackage';
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  lowestPrice: Maybe<Scalars['Int']['output']>;
  productOffers: Array<PropertyInsuranceProductOffer>;
  title: Scalars['String']['output'];
  type: PropertyInsuranceProductCategoryType;
};

export type PropertyInsuranceOrder = {
  __typename?: 'PropertyInsuranceOrder';
  buildingPrice: Maybe<BuildingPrice>;
  buildingSize: Maybe<BuildingSize>;
  buildingType: Maybe<PropertyInsuranceBuildingType>;
  contactPerson: Maybe<PropertyInsuranceContactPerson>;
  createdAt: Scalars['String']['output'];
  groundFloorApartment: Maybe<Scalars['Boolean']['output']>;
  householdPrice: Maybe<HouseholdPrice>;
  householdSize: Maybe<HouseholdSize>;
  insuranceInfo: PropertyInsuranceInfo;
  insuranceType: PropertyInsuranceType;
  insureSecondaryBuilding: Maybe<Scalars['Boolean']['output']>;
  insuredAddress: Address;
  propertyTenantRelation: Maybe<PropertyTenantRelation>;
  requirements: Maybe<PropertyInsuranceRequirements>;
  resultEmail: Maybe<Scalars['String']['output']>;
  resultPhone: Maybe<Scalars['String']['output']>;
  riscIndicators: Maybe<PropertyInsuranceRiscIndicators>;
  selectedOffer: Maybe<PropertyInsuranceProductOffer>;
  status: PropertyBusinessCaseStatus;
  updatedAt: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
};

export type PropertyInsuranceOrderInitInput = {
  insuranceType: PropertyInsuranceType;
  insuredAddressSmartAddressId: Scalars['Int']['input'];
};

export type PropertyInsuranceOrderOfferSelectInput = {
  selectedOffer: Scalars['Int']['input'];
};

export type PropertyInsuranceOrderUpdateInput = {
  buildingPrice?: InputMaybe<BuildingPrice>;
  buildingSize?: InputMaybe<BuildingSize>;
  buildingType?: InputMaybe<PropertyInsuranceBuildingType>;
  groundFloorApartment?: InputMaybe<Scalars['Boolean']['input']>;
  householdPrice?: InputMaybe<HouseholdPrice>;
  householdSize?: InputMaybe<HouseholdSize>;
  insuranceType?: InputMaybe<PropertyInsuranceType>;
  insureSecondaryBuilding?: InputMaybe<Scalars['Boolean']['input']>;
  insuredAddressSmartAddressId?: InputMaybe<Scalars['Int']['input']>;
  propertyTenantRelation?: InputMaybe<PropertyTenantRelation>;
  requirements?: InputMaybe<PropertyInsuranceRequirementsInput>;
  resultEmail?: InputMaybe<Scalars['String']['input']>;
  resultPhone?: InputMaybe<Scalars['String']['input']>;
  riscIndicators?: InputMaybe<PropertyInsuranceRiscIndicatorsInput>;
};

export type PropertyInsuranceProduct = {
  __typename?: 'PropertyInsuranceProduct';
  bonus: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  descriptionMD: Maybe<Scalars['String']['output']>;
  extendedInfoList: Array<Scalars['String']['output']>;
  faqs: Array<FaqPreview>;
  gift: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  infoList: Array<Scalars['String']['output']>;
  label: Maybe<Scalars['String']['output']>;
  labelTooltip: Maybe<Scalars['String']['output']>;
  metaDescription: Maybe<Scalars['String']['output']>;
  metaTitle: Scalars['String']['output'];
  name: Scalars['String']['output'];
  partner: ZeteoInsurancePartner;
  productDetailFile: Maybe<File>;
  risksHousehold: Array<Scalars['String']['output']>;
  risksProperty: Array<Scalars['String']['output']>;
  shortName: Maybe<Scalars['String']['output']>;
  subLimitsHousehold: Array<PropertyInsuranceSubLimit>;
  subLimitsProperty: Array<PropertyInsuranceSubLimit>;
  title: Scalars['String']['output'];
  titleSuffix: Maybe<Scalars['String']['output']>;
  type: Maybe<PropertyInsuranceType>;
  updatedAt: Scalars['DateTime']['output'];
};

export const PropertyInsuranceProductCategoryType = {
  Minimum: 'MINIMUM',
  NotCategorized: 'NOT_CATEGORIZED',
  Optimum: 'OPTIMUM',
  Premium: 'PREMIUM'
} as const;

export type PropertyInsuranceProductCategoryType = typeof PropertyInsuranceProductCategoryType[keyof typeof PropertyInsuranceProductCategoryType];
export type PropertyInsuranceProductOffer = {
  __typename?: 'PropertyInsuranceProductOffer';
  id: Scalars['Int']['output'];
  insuranceType: PropertyInsuranceType;
  price: Scalars['Int']['output'];
  product: PropertyInsuranceProduct;
  uuid: Scalars['String']['output'];
};

export type PropertyInsuranceRequirements = {
  __typename?: 'PropertyInsuranceRequirements';
  complementLiabilityCommonRequirement: Maybe<Scalars['Boolean']['output']>;
  complementLiabilityPropertyRequirement: Maybe<Scalars['Boolean']['output']>;
  floodInsuranceRequirement: Maybe<Scalars['Boolean']['output']>;
};

export type PropertyInsuranceRequirementsInput = {
  complementLiabilityCommonRequirement: Scalars['Boolean']['input'];
  complementLiabilityPropertyRequirement: Scalars['Boolean']['input'];
  floodInsuranceRequirement: Scalars['Boolean']['input'];
};

export type PropertyInsuranceRiscIndicators = {
  __typename?: 'PropertyInsuranceRiscIndicators';
  floodCount20YearsBuilding: Maybe<FloodDamage20Years>;
  floodCount20YearsHouseHold: Maybe<FloodDamage20Years>;
};

export type PropertyInsuranceRiscIndicatorsInput = {
  floodCount20YearsBuilding?: InputMaybe<FloodDamage20Years>;
  floodCount20YearsHouseHold?: InputMaybe<FloodDamage20Years>;
};

export type PropertyInsuranceSubLimit = {
  __typename?: 'PropertyInsuranceSubLimit';
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export const PropertyInsuranceType = {
  Household: 'HOUSEHOLD',
  HouseholdProperty: 'HOUSEHOLD_PROPERTY',
  Property: 'PROPERTY'
} as const;

export type PropertyInsuranceType = typeof PropertyInsuranceType[keyof typeof PropertyInsuranceType];
export const PropertyTenantRelation = {
  CoOwner: 'CO_OWNER',
  Owner: 'OWNER',
  Tenant: 'TENANT'
} as const;

export type PropertyTenantRelation = typeof PropertyTenantRelation[keyof typeof PropertyTenantRelation];
export type PublicEnergoCalculation = {
  __typename?: 'PublicEnergoCalculation';
  bestGasResultItem: Maybe<EnergoGasResultItem>;
  bestResultItem: Maybe<EnergoElectricityResultItem>;
  breaker: Maybe<EnergoBreaker>;
  createdAt: Scalars['DateTime']['output'];
  currentProductFollowingId: Maybe<Scalars['Int']['output']>;
  currentProductId: Maybe<Scalars['Int']['output']>;
  currentProductName: Maybe<Scalars['String']['output']>;
  distributionRate: Maybe<EnergoDistributionRate>;
  electricityUsages: Maybe<ElectricityUsages>;
  floorAreaRange: Maybe<EnergoFloorAreaRangeCodebook>;
  gasCurrentProductFollowingId: Maybe<Scalars['Int']['output']>;
  gasCurrentProductId: Maybe<Scalars['Int']['output']>;
  gasCurrentProductName: Maybe<Scalars['String']['output']>;
  gasIndividualPriceExpectedMWh: Maybe<Scalars['Float']['output']>;
  gasIndividualPriceExpectedMonthly: Maybe<Scalars['Float']['output']>;
  gasMonthlyAdvances: Maybe<Scalars['Float']['output']>;
  gasPartner: Maybe<EnergoGasPartner>;
  gasUnknownCurrentProduct: Maybe<Scalars['Boolean']['output']>;
  gasUnknownMonthlyAdvances: Maybe<Scalars['Boolean']['output']>;
  gasUsages: Maybe<GasUsages>;
  gasYearlyConsumption: Maybe<Scalars['Float']['output']>;
  gasYearlyConsumptionUnit: Maybe<ConsumptionUnit>;
  gasYearlyPayment: Maybe<Scalars['Float']['output']>;
  id: Scalars['Int']['output'];
  individualOfferName: Maybe<Scalars['String']['output']>;
  individualPriceExpectedMWhHighTariff: Maybe<Scalars['Float']['output']>;
  individualPriceExpectedMWhLowTariff: Maybe<Scalars['Float']['output']>;
  individualPriceExpectedMonthly: Maybe<Scalars['Float']['output']>;
  isolation: Maybe<EnergoIsolationCodebook>;
  lastViewed: Maybe<Scalars['DateTime']['output']>;
  monthlyAdvances: Maybe<Scalars['Float']['output']>;
  partner: Maybe<EnergoElectricityPartner>;
  personsCountRange: Maybe<EnergoPersonsCountRangeCodebook>;
  saving: Maybe<Scalars['Float']['output']>;
  source: EnergoCalculationSource;
  standaloneInvoice: Maybe<EnergoStandaloneInvoice>;
  type: EnergoCalculationType;
  unknownCurrentProduct: Maybe<Scalars['Boolean']['output']>;
  unknownMonthlyAdvances: Maybe<Scalars['Boolean']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  uuid: Scalars['String']['output'];
  yearlyConsumption: Maybe<Scalars['Float']['output']>;
  yearlyConsumptionLowTariff: Maybe<Scalars['Float']['output']>;
  yearlyConsumptionLowTariffUnit: Maybe<ConsumptionUnit>;
  yearlyConsumptionUnit: Maybe<ConsumptionUnit>;
  yearlyPayment: Maybe<Scalars['Float']['output']>;
  zip: Maybe<Scalars['Int']['output']>;
};

export type Query = {
  __typename?: 'Query';
  autocompleteCityPart: Array<CityPart>;
  availableRepaymentPeriods: Array<FinanceCalculationCombination>;
  /** Get benefits detail */
  benefitGet: Benefit;
  /** Get benefits for profile */
  benefitGetAll: Array<Benefit>;
  /** Get article */
  blogArticle: Article;
  /** Get articles */
  blogArticles: Array<ArticlePreview>;
  /** Get articles by tags */
  blogArticlesByTags: Array<ArticlePreview>;
  /** Get article categories */
  blogCategories: Array<ArticleCategoryListItem>;
  /** Get article category */
  blogCategory: ArticleCategory;
  /** Get most read articles */
  blogMostReadArticles: Array<ArticlePreview>;
  /** Search articles */
  blogSearchArticles: SearchResult;
  /** Get articles by tag */
  blogTag: ArticleTag;
  /** Return energo calculation by uuid */
  customerGetEnergoCalculation: CustomerZoneCalculationDetail;
  /** Return customer's calculations */
  customerMyCalculations: Array<CustomerZoneCalculationDetail>;
  /** Return customer's calculations */
  customerMyCalculationsByStatuses: Array<CustomerZoneCalculationDetail>;
  /** Return customer's electricity leads */
  customerMyElectricityLeads: Array<EnergoElectricityLead>;
  /** Return customer's gas leads */
  customerMyGasLeads: Array<EnergoGasLead>;
  /** Return customer's travel insurance contracts */
  customerMyPropertyInsuranceContracts: Array<PropertyInsuranceOrder>;
  /** Return customer's travel insurance orders */
  customerMyPropertyInsuranceOrders: Array<PropertyInsuranceCalculationResult>;
  /** Return customer's travel insurance contracts */
  customerMyTravelInsuranceContracts: Array<TravelInsuranceOrder>;
  /** Return customer's travel insurance orders */
  customerMyTravelInsuranceOrders: Array<TravelInsuranceOrderResult>;
  /** Return customer's vehicle insurance contracts */
  customerMyVehicleInsuranceContracts: Array<VehicleInsuranceOrder>;
  /** Return customer's vehicle insurance orders */
  customerMyVehicleInsuranceOrders: Array<VehicleInsuranceOrderResult>;
  /** Return customer's watch wolves */
  customerMyWatchWolves: Array<WatchWolf>;
  /** Return codebooks for energo form */
  energoCodebooks: EnergoCodebooks;
  /** Return active electricity products by partner */
  energoGetActiveElectricityProducts: Array<EnergoKalkulatorEnergoElectricityProduct>;
  /** Return active gas products by partner */
  energoGetActiveGasProducts: Array<EnergoKalkulatorEnergoGasProduct>;
  /** Return all electricity products */
  energoGetAllElectricityProducts: Array<EnergoKalkulatorEnergoElectricityProduct>;
  /** Return all gas products */
  energoGetAllGasProducts: Array<EnergoKalkulatorEnergoGasProduct>;
  /** Return calculation by uuid */
  energoGetCalculation: EnergoCalculation;
  /** Return calculation result by uuid */
  energoGetCalculationResult: EnergoCalculationResult;
  /** Return electricity result item */
  energoGetElectricityCalculationResultItem: EnergoElectricityResultItem;
  /** Electricity lead - get by uuid */
  energoGetElectricityLead: EnergoElectricityLead;
  /** Return electricity partner */
  energoGetElectricityPartner: EnergoElectricityPartnerDetail;
  /** Return electricity price product list */
  energoGetElectricityPriceProductPreviewList: Array<EnergoElectricityPriceProductPreviewItem>;
  /** Return electricity product */
  energoGetElectricityProduct: EnergoKalkulatorEnergoElectricityProductDetail;
  /** Return electricity products by partner */
  energoGetElectricityProducts: Array<EnergoKalkulatorEnergoElectricityProduct>;
  /** Return energo partner */
  energoGetEnergoPartner: EnergoPartner;
  /** Return energo partners */
  energoGetEnergoPartners: Array<EnergoPartner>;
  /** Return gas result item */
  energoGetGasCalculationResultItem: EnergoGasResultItem;
  /** Gas lead - get by uuid */
  energoGetGasLead: EnergoGasLead;
  /** Return gas partner */
  energoGetGasPartner: EnergoGasPartnerDetail;
  /** Return electricity price product list */
  energoGetGasPriceProductPreviewList: Array<EnergoGasPriceProductPreviewItem>;
  /** Return gas product */
  energoGetGasProduct: EnergoKalkulatorEnergoGasProductDetail;
  /** Return gas products by partner */
  energoGetGasProducts: Array<EnergoKalkulatorEnergoGasProduct>;
  /** Return last energo calculation for logged user */
  energoGetLastCalculation: Maybe<EnergoCalculation>;
  /** Return calculation by uuid */
  energoPublicGetCalculation: PublicEnergoCalculation;
  financeCalculationGet: FinanceCalculation;
  financeCalculationResults: FinanceCalculationResult;
  financeEntryFormSelectOptions: Array<FinanceAmountSelectOption>;
  financePartnerGet: FinancePartner;
  financePartnerProducts: Array<FinanceProduct>;
  financePartnersGet: Array<FinancePartner>;
  financeProductGet: FinanceProduct;
  financeProductSearch: Array<FinanceProduct>;
  financeProductsGet: Array<FinanceProduct>;
  /** get ares data */
  getAresData: AresData;
  /** Return electro partner's faq */
  getElectroPartnerFaqs: Array<Faq>;
  /** Return electro producs's faq */
  getElectroProductFaqs: Array<Faq>;
  /** Return kalkulator faqs by given filter */
  getFaq: FaqComplete;
  /** Return kalkulator faqs by given filter */
  getFaqs: Array<FaqPreview>;
  /** Return gas partner's faq */
  getGasPartnerFaqs: Array<Faq>;
  /** Return gas producs's faq */
  getGasProductFaqs: Array<Faq>;
  /** Return knowledgebase category */
  getKnowledgebaseCategory: KnowledgebaseCategory;
  /** Return knowledgebase question */
  getKnowledgebaseQuestion: KnowledgebaseQuestionDetail;
  /** Return knowledgebase question fultext search */
  getKnowledgebaseQuestionFulltext: Array<KnowledgebaseQuestionDetail>;
  /** Return  knowledgebase questions by tags */
  getKnowledgebaseQuestionsByTags: Array<KnowledgebaseQuestionPreview>;
  /** Return  knowledgebase questions by type */
  getKnowledgebaseQuestionsByType: Array<KnowledgebaseQuestionPreview>;
  /** Return knowledgebase root categories */
  getKnowledgebaseRootCategories: Maybe<Array<KnowledgebaseCategoryPreview>>;
  /** Return all knowledgebase categories for sitemap */
  getKnowledgebaseSitemapCategories: Array<KnowledgebaseCategoryPreview>;
  /** Return all knowledgebase questions for sitemap */
  getKnowledgebaseSitemapQuestions: Array<KnowledgebaseQuestionPreview>;
  getLandingPageData: LandingPageData;
  getLandingPageSitemapData: Array<LandingPageSiteMapItem>;
  getPropertyInsuranceCalculationResult: PropertyInsuranceCalculationStateResult;
  getPropertyInsuranceOrder: PropertyInsuranceOrder;
  /** get watch wolf by energo calculation uuid */
  getWatchWolfByEnergoCalculationUuid: WatchWolf;
  /** get watch wolf by property insurance order uuid */
  getWatchWolfByPropertyInsuranceOrder: WatchWolf;
  /** get watch wolf by vehicle insurance order uuid */
  getWatchWolfByVehicleInsuranceOrder: WatchWolf;
  /** Return general faqs for web */
  getWebFaqs: Array<Faq>;
  /** Return general faqs for wining screen */
  getWiningScreenFaqs: Array<Faq>;
  /** Returns true if profile has phone, false if not */
  hasPhone: Maybe<HasPhone>;
  health: Scalars['Boolean']['output'];
  /** Return lead by given uuid */
  leadGet: Lead;
  /** SmartAddress autocomplete */
  locationAutocomplete: Array<SmartAddress>;
  /** SmartAddress extended info */
  locationExtendedInfo: Maybe<SmartAddressExtendedInfo>;
  /** SmartAddress find by id */
  locationFind: Maybe<SmartAddress>;
  navbarConfig: NavbarConfig;
  /** Return backoffice profile on web */
  profileAdminMe: Maybe<AdminProfile>;
  /** Return customer's profile */
  profileMe: Maybe<MyProfile>;
  telcoCalculationGet: TelcoCalculation;
  telcoCalculationResultsGet: TelcoCalculationResults;
  telcoCalculationUpsellPricesGet: TelcoCalculationUpsellPrices;
  telcoLeadGet: TelcoLead;
  travelInsuranceCalculationResultGet: TravelInsuranceCalculationStateResult;
  travelInsuranceCodeBooksGet: TravelInsuranceCodeBooks;
  travelInsuranceContractOrderCompleted: TravelInsuranceContractCompleteOrder;
  travelInsuranceContractRecapitulation: TravelInsuranceContractRecapitulation;
  travelInsuranceOrderGet: TravelInsuranceOrder;
  travelInsuranceOrderGetLast: Maybe<TravelInsuranceLastOrder>;
  travelInsuranceUpsellOfferGet: TravelInsuranceUpsellOffer;
  vehicleInsuranceCalculationResultGet: VehicleInsuranceCalculationStateResult;
  vehicleInsuranceCodeBooksGet: VehicleInsuranceCodeBooks;
  vehicleInsuranceContractOrderCompleted: VehicleInsuranceContractCompleteOrder;
  vehicleInsuranceContractRecapitulation: VehicleInsuranceContractRecapitulation;
  vehicleInsuranceOrderGet: VehicleInsuranceOrder;
  vehicleInsuranceOrderGetLast: Maybe<VehicleInsuranceLastOrder>;
  vehicleInsurancePartnerGetDetail: ZeteoInsurancePartnerDetail;
  vehicleInsurancePartnerGetList: Array<ZeteoInsurancePartner>;
  vehicleInsuranceProductGetDetail: VehicleInsuranceProduct;
  vehicleInsuranceProductGetList: Array<VehicleInsuranceProduct>;
  vehicleInsuranceProductsGetByPartner: Array<VehicleInsuranceProduct>;
  /** SmartAddress search by zip */
  zipAutocomplete: Array<SmartAddress>;
};


export type QueryAutocompleteCityPartArgs = {
  zipCode: Scalars['String']['input'];
};


export type QueryAvailableRepaymentPeriodsArgs = {
  data: AvailableRepaymentPeriodsInput;
};


export type QueryBenefitGetArgs = {
  uuid: Scalars['String']['input'];
};


export type QueryBlogArticleArgs = {
  articleId: Scalars['Int']['input'];
};


export type QueryBlogArticlesArgs = {
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
};


export type QueryBlogArticlesByTagsArgs = {
  excludedArticles: Array<Scalars['Int']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  tagIds: Array<Scalars['Int']['input']>;
};


export type QueryBlogCategoryArgs = {
  categoryId: Scalars['Int']['input'];
  limit: InputMaybe<Scalars['Int']['input']>;
  offset: InputMaybe<Scalars['Int']['input']>;
};


export type QueryBlogMostReadArticlesArgs = {
  categoryId: InputMaybe<Scalars['Int']['input']>;
};


export type QueryBlogSearchArticlesArgs = {
  searchTerm: Scalars['String']['input'];
};


export type QueryBlogTagArgs = {
  tagId: Scalars['Int']['input'];
};


export type QueryCustomerGetEnergoCalculationArgs = {
  uuid: Scalars['String']['input'];
};


export type QueryCustomerMyCalculationsArgs = {
  cursor?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<EnergoCalculationType>;
};


export type QueryCustomerMyCalculationsByStatusesArgs = {
  data: CalculationsByStatusesInput;
};


export type QueryCustomerMyElectricityLeadsArgs = {
  cursor?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCustomerMyGasLeadsArgs = {
  cursor?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCustomerMyTravelInsuranceContractsArgs = {
  cursor: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCustomerMyTravelInsuranceOrdersArgs = {
  cursor: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCustomerMyVehicleInsuranceContractsArgs = {
  cursor?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCustomerMyVehicleInsuranceOrdersArgs = {
  cursor?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCustomerMyWatchWolvesArgs = {
  cursor?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryEnergoGetActiveElectricityProductsArgs = {
  partnerId: Scalars['Int']['input'];
};


export type QueryEnergoGetActiveGasProductsArgs = {
  partnerId: Scalars['Int']['input'];
};


export type QueryEnergoGetCalculationArgs = {
  uuid: Scalars['String']['input'];
};


export type QueryEnergoGetCalculationResultArgs = {
  uuid: Scalars['String']['input'];
};


export type QueryEnergoGetElectricityCalculationResultItemArgs = {
  resultItemId: Scalars['String']['input'];
  uuid: Scalars['String']['input'];
};


export type QueryEnergoGetElectricityLeadArgs = {
  uuid: Scalars['String']['input'];
};


export type QueryEnergoGetElectricityPartnerArgs = {
  partnerId: Scalars['Int']['input'];
};


export type QueryEnergoGetElectricityPriceProductPreviewListArgs = {
  data: ElectricityPriceProductPreviewListInput;
};


export type QueryEnergoGetElectricityProductArgs = {
  productId: Scalars['Int']['input'];
};


export type QueryEnergoGetElectricityProductsArgs = {
  partnerId: Scalars['Int']['input'];
};


export type QueryEnergoGetEnergoPartnerArgs = {
  partnerId: Scalars['Int']['input'];
};


export type QueryEnergoGetGasCalculationResultItemArgs = {
  resultItemId: Scalars['String']['input'];
  uuid: Scalars['String']['input'];
};


export type QueryEnergoGetGasLeadArgs = {
  uuid: Scalars['String']['input'];
};


export type QueryEnergoGetGasPartnerArgs = {
  partnerId: Scalars['Int']['input'];
};


export type QueryEnergoGetGasPriceProductPreviewListArgs = {
  data: GasPriceProductPreviewListInput;
};


export type QueryEnergoGetGasProductArgs = {
  productId: Scalars['Int']['input'];
};


export type QueryEnergoGetGasProductsArgs = {
  partnerId: Scalars['Int']['input'];
};


export type QueryEnergoPublicGetCalculationArgs = {
  uuid: Scalars['String']['input'];
};


export type QueryFinanceCalculationGetArgs = {
  uuid: Scalars['UUID']['input'];
};


export type QueryFinanceCalculationResultsArgs = {
  uuid: Scalars['UUID']['input'];
};


export type QueryFinancePartnerGetArgs = {
  id: Scalars['Int']['input'];
};


export type QueryFinancePartnerProductsArgs = {
  partnerId: Scalars['Int']['input'];
};


export type QueryFinanceProductGetArgs = {
  id: Scalars['Int']['input'];
};


export type QueryFinanceProductSearchArgs = {
  productPurpose: Scalars['Int']['input'];
  productTypes: Array<FinanceProductType>;
};


export type QueryGetAresDataArgs = {
  ico: Scalars['String']['input'];
};


export type QueryGetElectroPartnerFaqsArgs = {
  partnerId: Scalars['Int']['input'];
};


export type QueryGetElectroProductFaqsArgs = {
  productId: Scalars['Int']['input'];
};


export type QueryGetFaqArgs = {
  faq: Scalars['Int']['input'];
};


export type QueryGetFaqsArgs = {
  tag: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetGasPartnerFaqsArgs = {
  partnerId: Scalars['Int']['input'];
};


export type QueryGetGasProductFaqsArgs = {
  productId: Scalars['Int']['input'];
};


export type QueryGetKnowledgebaseCategoryArgs = {
  category: Scalars['Int']['input'];
};


export type QueryGetKnowledgebaseQuestionArgs = {
  question: Scalars['Int']['input'];
};


export type QueryGetKnowledgebaseQuestionFulltextArgs = {
  searchTerm: Scalars['String']['input'];
  type: InputMaybe<QuestionType>;
};


export type QueryGetKnowledgebaseQuestionsByTagsArgs = {
  data: KnowledgebaseGetByTagsInput;
};


export type QueryGetKnowledgebaseQuestionsByTypeArgs = {
  data: KnowledgebaseGetByTypeInput;
};


export type QueryGetLandingPageDataArgs = {
  landingPageId: Scalars['String']['input'];
};


export type QueryGetPropertyInsuranceCalculationResultArgs = {
  uuid: Scalars['String']['input'];
};


export type QueryGetPropertyInsuranceOrderArgs = {
  uuid: Scalars['String']['input'];
};


export type QueryGetWatchWolfByEnergoCalculationUuidArgs = {
  uuid: Scalars['String']['input'];
};


export type QueryGetWatchWolfByPropertyInsuranceOrderArgs = {
  uuid: Scalars['String']['input'];
};


export type QueryGetWatchWolfByVehicleInsuranceOrderArgs = {
  uuid: Scalars['String']['input'];
};


export type QueryHasPhoneArgs = {
  email: Scalars['String']['input'];
};


export type QueryLeadGetArgs = {
  leadUuid: Scalars['String']['input'];
};


export type QueryLocationAutocompleteArgs = {
  level?: InputMaybe<Scalars['Int']['input']>;
  term: Scalars['String']['input'];
  zip: InputMaybe<Scalars['Int']['input']>;
};


export type QueryLocationExtendedInfoArgs = {
  smartAddressId: Scalars['Int']['input'];
};


export type QueryLocationFindArgs = {
  smartAddressId: Scalars['Int']['input'];
};


export type QueryTelcoCalculationGetArgs = {
  uuid: Scalars['UUID']['input'];
};


export type QueryTelcoCalculationResultsGetArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  uuid: Scalars['UUID']['input'];
};


export type QueryTelcoCalculationUpsellPricesGetArgs = {
  uuid: Scalars['UUID']['input'];
};


export type QueryTelcoLeadGetArgs = {
  lead: Scalars['UUID']['input'];
};


export type QueryTravelInsuranceCalculationResultGetArgs = {
  orderUuid: Scalars['String']['input'];
};


export type QueryTravelInsuranceContractOrderCompletedArgs = {
  orderUuid: Scalars['String']['input'];
};


export type QueryTravelInsuranceContractRecapitulationArgs = {
  orderUuid: Scalars['String']['input'];
};


export type QueryTravelInsuranceOrderGetArgs = {
  uuid: Scalars['String']['input'];
};


export type QueryTravelInsuranceUpsellOfferGetArgs = {
  orderUuid: Scalars['String']['input'];
};


export type QueryVehicleInsuranceCalculationResultGetArgs = {
  orderUuid: Scalars['String']['input'];
};


export type QueryVehicleInsuranceContractOrderCompletedArgs = {
  orderUuid: Scalars['String']['input'];
};


export type QueryVehicleInsuranceContractRecapitulationArgs = {
  orderUuid: Scalars['String']['input'];
};


export type QueryVehicleInsuranceOrderGetArgs = {
  uuid: Scalars['String']['input'];
};


export type QueryVehicleInsurancePartnerGetDetailArgs = {
  id: Scalars['Int']['input'];
};


export type QueryVehicleInsuranceProductGetDetailArgs = {
  id: Scalars['Int']['input'];
};


export type QueryVehicleInsuranceProductsGetByPartnerArgs = {
  partnerId: Scalars['Int']['input'];
};


export type QueryZipAutocompleteArgs = {
  term: Scalars['String']['input'];
};

export const QuestionType = {
  Concept: 'CONCEPT',
  Question: 'QUESTION'
} as const;

export type QuestionType = typeof QuestionType[keyof typeof QuestionType];
export type Rating = {
  __typename?: 'Rating';
  id: Scalars['Int']['output'];
  ratingCustomerService: Scalars['Int']['output'];
  ratingInformationAccess: Scalars['Int']['output'];
  ratingKarma: Scalars['Int']['output'];
  ratingOnlineSupport: Scalars['Int']['output'];
  ratingProvidedService: Scalars['Int']['output'];
  ratingTotal: Scalars['Int']['output'];
};

export type RatingCategory = {
  __typename?: 'RatingCategory';
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
  value: Scalars['Int']['output'];
};

export type RegistrationByEmailInput = {
  email: Scalars['String']['input'];
  source?: InputMaybe<ProfileSource>;
};

export type RegistrationInput = {
  campaign?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  sendEmail?: InputMaybe<Scalars['Boolean']['input']>;
  surname: Scalars['String']['input'];
};

export type SearchResult = {
  __typename?: 'SearchResult';
  contentResults: Maybe<Array<ArticlePreview>>;
  contentResultsCount: Scalars['Int']['output'];
  mixedUniqueResults: Maybe<Array<ArticlePreview>>;
  mixedUniqueResultsCount: Scalars['Int']['output'];
  titleResults: Maybe<Array<ArticlePreview>>;
  titleResultsCount: Scalars['Int']['output'];
};

export const SectionType = {
  BannerWithButton: 'BANNER_WITH_BUTTON',
  BannerWithButtons: 'BANNER_WITH_BUTTONS',
  BannerWithCallbackButton: 'BANNER_WITH_CALLBACK_BUTTON',
  BlogArticles: 'BLOG_ARTICLES',
  Contacts: 'CONTACTS',
  ElectricityPriceList: 'ELECTRICITY_PRICE_LIST',
  FinanceProducts: 'FINANCE_PRODUCTS',
  GasPriceList: 'GAS_PRICE_LIST',
  InternetSpeed: 'INTERNET_SPEED',
  Jumbotron: 'JUMBOTRON',
  KnowledgeBaseConcepts: 'KNOWLEDGE_BASE_CONCEPTS',
  KnowledgeBaseQuestions: 'KNOWLEDGE_BASE_QUESTIONS',
  PrimaryHeadline: 'PRIMARY_HEADLINE',
  Table: 'TABLE',
  Text: 'TEXT',
  Usp: 'USP',
  VehicleInsuranceContractDismissalDateForm: 'VEHICLE_INSURANCE_CONTRACT_DISMISSAL_DATE_FORM'
} as const;

export type SectionType = typeof SectionType[keyof typeof SectionType];
export const SendInvoiceMethod = {
  Email: 'EMAIL',
  Post: 'POST'
} as const;

export type SendInvoiceMethod = typeof SendInvoiceMethod[keyof typeof SendInvoiceMethod];
export const SetriCeleCeskoType = {
  Boiler: 'BOILER',
  Dishwasher: 'DISHWASHER',
  Dryer: 'DRYER',
  Fridge: 'FRIDGE',
  Heating: 'HEATING',
  Lighting: 'LIGHTING',
  WashingMashine: 'WASHING_MASHINE'
} as const;

export type SetriCeleCeskoType = typeof SetriCeleCeskoType[keyof typeof SetriCeleCeskoType];
export type SmartAddress = {
  __typename?: 'SmartAddress';
  castObceKod: Maybe<Scalars['Int']['output']>;
  castObceNazev: Maybe<Scalars['String']['output']>;
  cisloDomovni: Maybe<Scalars['Int']['output']>;
  cisloOrientacni: Maybe<Scalars['String']['output']>;
  cisloOrientacniZnak: Maybe<Scalars['String']['output']>;
  gps: Gps;
  id: Scalars['Int']['output'];
  level: Maybe<Scalars['Int']['output']>;
  momcKod: Maybe<Scalars['Int']['output']>;
  momcNazev: Maybe<Scalars['String']['output']>;
  mopKod: Maybe<Scalars['Int']['output']>;
  mopNazev: Maybe<Scalars['String']['output']>;
  obec: Maybe<Scalars['String']['output']>;
  obecKod: Scalars['Int']['output'];
  obecNazev: Scalars['String']['output'];
  psc: Maybe<Scalars['Int']['output']>;
  typSo: Maybe<Scalars['String']['output']>;
  ulice: Maybe<Scalars['String']['output']>;
  uliceKod: Maybe<Scalars['Int']['output']>;
  uliceNazev: Maybe<Scalars['String']['output']>;
};

export type SmartAddressAddressExtendedAdministrativeResponse = {
  __typename?: 'SmartAddressAddressExtendedAdministrativeResponse';
  id: Scalars['Int']['output'];
  isknBudovaId: Maybe<Scalars['String']['output']>;
  objectType: Maybe<SmartAddressObjectType>;
  objectTypeBasic: Maybe<SmartAddressObjectType>;
  parcelaKod: Maybe<Scalars['String']['output']>;
  stavebniObjektKod: Maybe<Scalars['String']['output']>;
  zpusobVyuzitiObjektu: Maybe<SmartAddressBuildingUsage>;
};

export type SmartAddressAddressExtendedBuildingResponse = {
  __typename?: 'SmartAddressAddressExtendedBuildingResponse';
  druhKonstrukce: Maybe<SmartAddressConstructionType>;
  id: Scalars['Int']['output'];
  obestavenyProstor: Maybe<Scalars['Int']['output']>;
  objectSize: Maybe<Scalars['String']['output']>;
  pocetBytu: Maybe<Scalars['Int']['output']>;
  pocetPodlazi: Maybe<Scalars['Int']['output']>;
  podlahovaPlocha: Maybe<Scalars['Int']['output']>;
  pripojeniKanalizace: Maybe<SmartAddressSewerConnectionType>;
  pripojeniVodovod: Maybe<SmartAddressWaterConnectionType>;
  typBudovy: Maybe<SmartAddressBuildingType>;
  vybaveniVytahem: Maybe<SmartAddressElevatorType>;
  zastavenaPlocha: Maybe<Scalars['Int']['output']>;
};

export type SmartAddressAddressExtendedEnergyResponse = {
  __typename?: 'SmartAddressAddressExtendedEnergyResponse';
  distributorElektrinyId: Maybe<Scalars['Int']['output']>;
  distributorElektrinyKod: Maybe<Scalars['String']['output']>;
  distributorPlynuId: Maybe<Scalars['Int']['output']>;
  distributorPlynuKod: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  pripojeniPlyn: Maybe<SmartAddressGasConnectionType>;
  zpusobVytapeni: Maybe<SmartAddressHeatingType>;
};

export type SmartAddressBasicAddressResponse = {
  __typename?: 'SmartAddressBasicAddressResponse';
  aktualizace: Maybe<Scalars['String']['output']>;
  castObceKod: Maybe<Scalars['Int']['output']>;
  castObceNazev: Maybe<Scalars['String']['output']>;
  cisloDomovni: Maybe<Scalars['Int']['output']>;
  cisloOrientacni: Maybe<Scalars['Int']['output']>;
  cisloOrientacniZnak: Maybe<Scalars['String']['output']>;
  gps: Maybe<Gps>;
  id: Scalars['Int']['output'];
  krajKod: Maybe<Scalars['Int']['output']>;
  krajNazev: Maybe<Scalars['String']['output']>;
  level: Maybe<Scalars['Int']['output']>;
  momcKod: Maybe<Scalars['Int']['output']>;
  momcNazev: Maybe<Scalars['String']['output']>;
  mopKod: Maybe<Scalars['Int']['output']>;
  mopNazev: Maybe<Scalars['String']['output']>;
  obec: Maybe<Scalars['String']['output']>;
  obecKod: Scalars['Int']['output'];
  obecNazev: Maybe<Scalars['String']['output']>;
  okresKod: Maybe<Scalars['Int']['output']>;
  okresNazev: Maybe<Scalars['String']['output']>;
  platiOd: Maybe<Scalars['String']['output']>;
  psc: Maybe<Scalars['Int']['output']>;
  search: Maybe<Scalars['String']['output']>;
  typSo: Maybe<Scalars['String']['output']>;
  ulice: Maybe<Scalars['String']['output']>;
  uliceKod: Maybe<Scalars['Int']['output']>;
  uliceNazev: Maybe<Scalars['String']['output']>;
};

export type SmartAddressBuildingType = {
  __typename?: 'SmartAddressBuildingType';
  id: Scalars['Int']['output'];
  konecPlatnosti: Maybe<Scalars['String']['output']>;
  nazev: Scalars['String']['output'];
  popis: Scalars['String']['output'];
  zacatekPlatnosti: Maybe<Scalars['String']['output']>;
  zkracenyNazev: Scalars['String']['output'];
};

export type SmartAddressBuildingUsage = {
  __typename?: 'SmartAddressBuildingUsage';
  id: Scalars['Int']['output'];
  konecPlatnosti: Maybe<Scalars['String']['output']>;
  nazev: Scalars['String']['output'];
  popis: Scalars['String']['output'];
  zacatekPlatnosti: Maybe<Scalars['String']['output']>;
  zkracenyNazev: Scalars['String']['output'];
};

export type SmartAddressConstructionType = {
  __typename?: 'SmartAddressConstructionType';
  id: Scalars['Int']['output'];
  konecPlatnosti: Maybe<Scalars['String']['output']>;
  nazev: Scalars['String']['output'];
  popis: Scalars['String']['output'];
  zacatekPlatnosti: Maybe<Scalars['String']['output']>;
  zkracenyNazev: Scalars['String']['output'];
};

export type SmartAddressElevatorType = {
  __typename?: 'SmartAddressElevatorType';
  id: Scalars['Int']['output'];
  konecPlatnosti: Maybe<Scalars['String']['output']>;
  nazev: Scalars['String']['output'];
  popis: Scalars['String']['output'];
  zacatekPlatnosti: Maybe<Scalars['String']['output']>;
  zkracenyNazev: Scalars['String']['output'];
};

export type SmartAddressExtendedInfo = {
  __typename?: 'SmartAddressExtendedInfo';
  distributorElektrinyId: Maybe<Scalars['Int']['output']>;
  distributorElektrinyKod: Maybe<Scalars['String']['output']>;
  distributorPlynuId: Maybe<Scalars['Int']['output']>;
  distributorPlynuKod: Maybe<Scalars['String']['output']>;
  druhKonstrukce: Maybe<SmartAddressConstructionType>;
  id: Scalars['Int']['output'];
  isknBudovaId: Maybe<Scalars['String']['output']>;
  obestavenyProstor: Maybe<Scalars['Int']['output']>;
  objectSize: Maybe<Scalars['String']['output']>;
  objectSizeSquare: Maybe<Scalars['Int']['output']>;
  objectType: Maybe<SmartAddressObjectType>;
  objectTypeBasic: Maybe<SmartAddressObjectType>;
  parcelaKod: Maybe<Scalars['String']['output']>;
  pocetBytu: Maybe<Scalars['Int']['output']>;
  pocetPodlazi: Maybe<Scalars['Int']['output']>;
  podlahovaPlocha: Maybe<Scalars['Int']['output']>;
  pripojeniKanalizace: Maybe<SmartAddressSewerConnectionType>;
  pripojeniPlyn: Maybe<SmartAddressGasConnectionType>;
  pripojeniVodovod: Maybe<SmartAddressWaterConnectionType>;
  stavebniObjektKod: Maybe<Scalars['String']['output']>;
  typBudovy: Maybe<SmartAddressBuildingType>;
  vybaveniVytahem: Maybe<SmartAddressElevatorType>;
  zastavenaPlocha: Maybe<Scalars['Int']['output']>;
  zpusobVytapeni: Maybe<SmartAddressHeatingType>;
  zpusobVyuzitiObjektu: Maybe<SmartAddressBuildingUsage>;
};

export type SmartAddressGasConnectionType = {
  __typename?: 'SmartAddressGasConnectionType';
  id: Scalars['Int']['output'];
  konecPlatnosti: Maybe<Scalars['String']['output']>;
  nazev: Scalars['String']['output'];
  popis: Scalars['String']['output'];
  zacatekPlatnosti: Maybe<Scalars['String']['output']>;
  zkracenyNazev: Scalars['String']['output'];
};

export type SmartAddressHeatingType = {
  __typename?: 'SmartAddressHeatingType';
  id: Scalars['Int']['output'];
  konecPlatnosti: Maybe<Scalars['String']['output']>;
  nazev: Scalars['String']['output'];
  popis: Scalars['String']['output'];
  zacatekPlatnosti: Maybe<Scalars['String']['output']>;
  zkracenyNazev: Scalars['String']['output'];
};

export type SmartAddressObjectType = {
  __typename?: 'SmartAddressObjectType';
  id: Scalars['Int']['output'];
  nazev: Maybe<Scalars['String']['output']>;
};

export type SmartAddressSdkExtendedInfo = {
  __typename?: 'SmartAddressSdkExtendedInfo';
  administrativniUdaje: Maybe<SmartAddressAddressExtendedAdministrativeResponse>;
  udajeBudovy: Maybe<SmartAddressAddressExtendedBuildingResponse>;
  udajeEnergii: Maybe<SmartAddressAddressExtendedEnergyResponse>;
  zakladniAdresa: Maybe<SmartAddressBasicAddressResponse>;
};

export type SmartAddressSewerConnectionType = {
  __typename?: 'SmartAddressSewerConnectionType';
  id: Scalars['Int']['output'];
  konecPlatnosti: Maybe<Scalars['String']['output']>;
  nazev: Scalars['String']['output'];
  popis: Scalars['String']['output'];
  zacatekPlatnosti: Maybe<Scalars['String']['output']>;
  zkracenyNazev: Scalars['String']['output'];
};

export type SmartAddressWaterConnectionType = {
  __typename?: 'SmartAddressWaterConnectionType';
  id: Scalars['Int']['output'];
  konecPlatnosti: Maybe<Scalars['String']['output']>;
  nazev: Scalars['String']['output'];
  popis: Scalars['String']['output'];
  zacatekPlatnosti: Maybe<Scalars['String']['output']>;
  zkracenyNazev: Scalars['String']['output'];
};

export const SocialAuthType = {
  Apple: 'APPLE',
  Facebook: 'FACEBOOK',
  Google: 'GOOGLE'
} as const;

export type SocialAuthType = typeof SocialAuthType[keyof typeof SocialAuthType];
export type StandaloneInvoiceAddFilesInput = {
  files: Array<Scalars['String']['input']>;
};

export const StandaloneInvoiceSource = {
  Benefit: 'BENEFIT',
  Homepage: 'HOMEPAGE',
  LandingPage: 'LANDING_PAGE',
  WolfPageContract: 'WOLF_PAGE_CONTRACT',
  WolfPageInvoice: 'WOLF_PAGE_INVOICE'
} as const;

export type StandaloneInvoiceSource = typeof StandaloneInvoiceSource[keyof typeof StandaloneInvoiceSource];
export const SubVertical = {
  Consolidation: 'CONSOLIDATION',
  Electricity: 'ELECTRICITY',
  Fotovoltaics: 'FOTOVOLTAICS',
  Gas: 'GAS',
  General: 'GENERAL',
  Household: 'HOUSEHOLD',
  HouseholdProperty: 'HOUSEHOLD_PROPERTY',
  Loan: 'LOAN',
  Mortgage: 'MORTGAGE',
  Property: 'PROPERTY',
  Refinancing: 'REFINANCING',
  Telco: 'TELCO',
  Travel: 'TRAVEL',
  Vehicle: 'VEHICLE'
} as const;

export type SubVertical = typeof SubVertical[keyof typeof SubVertical];
export type Subscription = {
  __typename?: 'Subscription';
  /** A placeholder query used by thecodingmachine/graphqlite when there are no declared subscriptions. */
  dummySubscription: Maybe<Scalars['String']['output']>;
};

export type TelcoCalculation = {
  __typename?: 'TelcoCalculation';
  email: Maybe<Scalars['Email']['output']>;
  id: Scalars['Int']['output'];
  interests: Array<TelcoCommodity>;
  internet: Maybe<TelcoCalculationInternet>;
  mobile: Maybe<TelcoCalculationMobile>;
  phone: Maybe<Scalars['LegacyPhone']['output']>;
  tv: Maybe<TelcoCalculationTv>;
  uuid: Scalars['UUID']['output'];
};

export type TelcoCalculationCreateInput = {
  interests: Array<TelcoCommodity>;
  measuredSpeed?: InputMaybe<Scalars['Float']['input']>;
};

export type TelcoCalculationInternet = {
  __typename?: 'TelcoCalculationInternet';
  id: Scalars['Int']['output'];
  speed: InternetSpeed;
  typeOfUser: Maybe<InternetTypeOfUser>;
};

export type TelcoCalculationInternetInput = {
  speed: InternetSpeed;
  typeOfUser: InternetTypeOfUser;
};

export type TelcoCalculationMobile = {
  __typename?: 'TelcoCalculationMobile';
  hasMultiplePhoneNumbers: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  includedData: MobileIncludedData;
  includedMinutes: MobileIncludedMinutes;
  isBusiness: Scalars['Boolean']['output'];
  isSenior: Scalars['Boolean']['output'];
  isStudent: Scalars['Boolean']['output'];
};

export type TelcoCalculationMobileInput = {
  hasMultiplePhoneNumbers: Scalars['Boolean']['input'];
  includedData: MobileIncludedData;
  includedMinutes: MobileIncludedMinutes;
  isBusiness: Scalars['Boolean']['input'];
  isSenior: Scalars['Boolean']['input'];
  isStudent: Scalars['Boolean']['input'];
};

export type TelcoCalculationResultItem = {
  __typename?: 'TelcoCalculationResultItem';
  partner: TelcoPartner;
  priceBasic: Money;
  priceFinal: Money;
  productInternet: Maybe<InternetProduct>;
  productMobile: Maybe<MobileProduct>;
  productTv: Maybe<TvProduct>;
  uniqueKey: Scalars['String']['output'];
};

export type TelcoCalculationResults = {
  __typename?: 'TelcoCalculationResults';
  results: Array<TelcoCalculationResultItem>;
};

export type TelcoCalculationTv = {
  __typename?: 'TelcoCalculationTv';
  id: Scalars['Int']['output'];
  programOffer: TvProgramOffer;
  programTypes: Array<TvProgramType>;
};

export type TelcoCalculationTvInput = {
  programOffer: TvProgramOffer;
  programTypes: Array<TvProgramType>;
};

export type TelcoCalculationUpdateInput = {
  email: Scalars['Email']['input'];
  finalSubmit: Scalars['Boolean']['input'];
  internet?: InputMaybe<TelcoCalculationInternetInput>;
  mobile?: InputMaybe<TelcoCalculationMobileInput>;
  phone: Scalars['LegacyPhone']['input'];
  tv?: InputMaybe<TelcoCalculationTvInput>;
};

export type TelcoCalculationUpsellPrices = {
  __typename?: 'TelcoCalculationUpsellPrices';
  priceInternet: Maybe<Money>;
  priceMobile: Maybe<Money>;
  priceTv: Maybe<Money>;
};

export const TelcoCommodity = {
  Internet: 'INTERNET',
  Mobile: 'MOBILE',
  Tv: 'TV'
} as const;

export type TelcoCommodity = typeof TelcoCommodity[keyof typeof TelcoCommodity];
export type TelcoLead = {
  __typename?: 'TelcoLead';
  address: Maybe<NewSmartAddress>;
  calculation: TelcoCalculation;
  email: Maybe<Scalars['Email']['output']>;
  internetProduct: Maybe<InternetProduct>;
  mobileProduct: Maybe<MobileProduct>;
  name: Maybe<Scalars['String']['output']>;
  phone: Maybe<Scalars['LegacyPhone']['output']>;
  priceForCombination: Maybe<Money>;
  priceStandard: Maybe<Money>;
  surname: Maybe<Scalars['String']['output']>;
  tvProduct: Maybe<TvProduct>;
  uuid: Scalars['UUID']['output'];
};

export type TelcoLeadCreateInput = {
  productInternetId?: InputMaybe<Scalars['Int']['input']>;
  productMobileId?: InputMaybe<Scalars['Int']['input']>;
  productTvId?: InputMaybe<Scalars['Int']['input']>;
  telcoCalculationUuid: Scalars['UUID']['input'];
};

export type TelcoLeadUpdateInput = {
  email?: InputMaybe<Scalars['Email']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['LegacyPhone']['input']>;
  smartAddressId?: InputMaybe<Scalars['Int']['input']>;
  surname?: InputMaybe<Scalars['String']['input']>;
};

export type TelcoPartner = {
  __typename?: 'TelcoPartner';
  address: Maybe<Scalars['String']['output']>;
  code: TelcoPartnerCode;
  contactEmail: Maybe<Scalars['String']['output']>;
  discountMethod: PartnerDiscountMethod;
  id: Scalars['Int']['output'];
  logo: Maybe<Image>;
  logoSquare: Maybe<Image>;
  longArticleMD: Maybe<Scalars['String']['output']>;
  metaDescription: Maybe<Scalars['String']['output']>;
  metaTitle: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  phone: Maybe<Scalars['LegacyPhone']['output']>;
  seoName: Scalars['String']['output'];
  web: Maybe<Scalars['String']['output']>;
};

export const TelcoPartnerCode = {
  GoMobil: 'GO_MOBIL',
  O2: 'O2',
  TMobile: 'T_MOBILE',
  Unknown: 'UNKNOWN',
  Vodafone: 'VODAFONE'
} as const;

export type TelcoPartnerCode = typeof TelcoPartnerCode[keyof typeof TelcoPartnerCode];
export type TelcoTag = {
  __typename?: 'TelcoTag';
  label: Scalars['String']['output'];
  tooltip: Maybe<Scalars['String']['output']>;
};

export const TravelBusinessCaseStatus = {
  Calculation: 'CALCULATION',
  Cancelled: 'CANCELLED',
  ContractPayed: 'CONTRACT_PAYED',
  InquiryCreated: 'INQUIRY_CREATED',
  Order: 'ORDER'
} as const;

export type TravelBusinessCaseStatus = typeof TravelBusinessCaseStatus[keyof typeof TravelBusinessCaseStatus];
export type TravelInsuranceCalculationStateResult = {
  __typename?: 'TravelInsuranceCalculationStateResult';
  result: Maybe<TravelInsuranceOrderResult>;
  state: ZeteoState;
};

export type TravelInsuranceCodeBook = {
  __typename?: 'TravelInsuranceCodeBook';
  id: Scalars['Int']['output'];
  label: Scalars['String']['output'];
};

export type TravelInsuranceCodeBooks = {
  __typename?: 'TravelInsuranceCodeBooks';
  country: Array<CodeBook>;
  destination: Array<CodeBook>;
  sport: Array<CodeBook>;
};

export type TravelInsuranceContract = {
  __typename?: 'TravelInsuranceContract';
  annualPrice: Money;
  documents: Array<Maybe<TravelInsuranceContractDocument>>;
  id: Scalars['Int']['output'];
  payed: Scalars['Boolean']['output'];
  paymentInfo: Maybe<TravelInsuranceContractPaymentInfo>;
  price: Money;
  uuid: Scalars['String']['output'];
};

export type TravelInsuranceContractCompleteOrder = {
  __typename?: 'TravelInsuranceContractCompleteOrder';
  finishOrderState: Maybe<ZeteoState>;
  order: TravelInsuranceOrder;
};

export type TravelInsuranceContractDocument = {
  __typename?: 'TravelInsuranceContractDocument';
  documentCategory: TravelInsuranceCodeBook;
  documentType: Scalars['String']['output'];
  file: Maybe<File>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type TravelInsuranceContractPaymentInfo = {
  __typename?: 'TravelInsuranceContractPaymentInfo';
  account: Scalars['String']['output'];
  amount: Money;
  bankCode: Scalars['String']['output'];
  constantSymbol: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  message: Maybe<Scalars['String']['output']>;
  paymentUrl: Maybe<Scalars['String']['output']>;
  qrCodeFile: File;
  specificSymbol: Maybe<Scalars['String']['output']>;
  variableSymbol: Maybe<Scalars['String']['output']>;
};

export type TravelInsuranceContractRecapitulation = {
  __typename?: 'TravelInsuranceContractRecapitulation';
  order: Maybe<TravelInsuranceOrder>;
  recapitulation: Maybe<TravelInsuranceRecapitulationInfo>;
  recapitulationState: Maybe<ZeteoState>;
};

export const TravelInsuranceInsurancePackageType = {
  Minimum: 'MINIMUM',
  Optimum: 'OPTIMUM',
  Premium: 'PREMIUM'
} as const;

export type TravelInsuranceInsurancePackageType = typeof TravelInsuranceInsurancePackageType[keyof typeof TravelInsuranceInsurancePackageType];
export type TravelInsuranceInsuredPersonInput = {
  addressId?: InputMaybe<Scalars['Int']['input']>;
  addressSameAsPolicyHolder: Scalars['Boolean']['input'];
  birthNumber: Scalars['String']['input'];
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  nationality: Scalars['Int']['input'];
  surname: Scalars['String']['input'];
};

export type TravelInsuranceInsuredPersonsInput = {
  persons: Array<TravelInsuranceInsuredPersonInput>;
};

export type TravelInsuranceLastOrder = {
  __typename?: 'TravelInsuranceLastOrder';
  destination: CodeBook;
  id: Scalars['Int']['output'];
  insuranceType: TravelInsuranceTravelInsuranceType;
};

export type TravelInsuranceOrder = {
  __typename?: 'TravelInsuranceOrder';
  contract: Maybe<TravelInsuranceContract>;
  createdAt: Scalars['DateTime']['output'];
  dateFrom: Maybe<Scalars['String']['output']>;
  dateTo: Maybe<Scalars['String']['output']>;
  destination: CodeBook;
  family: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  insuranceType: TravelInsuranceTravelInsuranceType;
  insuredPersons: Array<Person>;
  insuredSports: Array<CodeBook>;
  policyHolder: Maybe<Person>;
  resultEmail: Maybe<Scalars['String']['output']>;
  selectedOffer: Maybe<TravelInsuranceProductOffer>;
  status: TravelBusinessCaseStatus;
  travelPurpose: TravelInsuranceTravelPurpose;
  updatedAt: Scalars['DateTime']['output'];
  uuid: Scalars['String']['output'];
};

export type TravelInsuranceOrderInitInput = {
  destination: Scalars['Int']['input'];
  insuranceType: TravelInsuranceTravelInsuranceType;
};

export type TravelInsuranceOrderResult = {
  __typename?: 'TravelInsuranceOrderResult';
  cheapestOffer: Maybe<TravelInsuranceProductOffer>;
  id: Scalars['String']['output'];
  order: TravelInsuranceOrder;
  packages: Array<TravelInsurancePackage>;
};

export type TravelInsuranceOrderUpdateInput = {
  dateFrom?: InputMaybe<Scalars['DateTime']['input']>;
  dateTo?: InputMaybe<Scalars['DateTime']['input']>;
  family: Scalars['Boolean']['input'];
  insuredSports?: InputMaybe<Array<Scalars['Int']['input']>>;
  persons: Array<TravelInsurancePersonInput>;
  resultEmail?: InputMaybe<Scalars['String']['input']>;
};

export type TravelInsurancePackage = {
  __typename?: 'TravelInsurancePackage';
  baggageRequirement: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  injuryRequirement: Scalars['Boolean']['output'];
  liabilityRequirement: Scalars['Boolean']['output'];
  lowestPrice: Maybe<Scalars['Float']['output']>;
  medicalExpenseCovers: Array<CodeBook>;
  mobileOrder: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  productOffers: Array<TravelInsuranceProductOffer>;
  type: TravelInsuranceInsurancePackageType;
  uuid: Scalars['String']['output'];
};

export type TravelInsurancePersonInput = {
  age: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  policyHolder: Scalars['Boolean']['input'];
};

export type TravelInsurancePolicyHolderInput = {
  addressId: Scalars['Int']['input'];
  birthNumber: Scalars['String']['input'];
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  nationality: Scalars['Int']['input'];
  phone: Scalars['String']['input'];
  surname: Scalars['String']['input'];
};

export type TravelInsuranceProduct = {
  __typename?: 'TravelInsuranceProduct';
  addedValue: Maybe<Scalars['String']['output']>;
  baggageCover: Maybe<Scalars['Int']['output']>;
  bonus: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  descriptionMD: Maybe<Scalars['String']['output']>;
  extendedInfoList: Array<Scalars['String']['output']>;
  gift: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  infoList: Array<Scalars['String']['output']>;
  injuryCover: Maybe<Scalars['Int']['output']>;
  label: Maybe<Scalars['String']['output']>;
  labelTooltip: Maybe<Scalars['String']['output']>;
  liabilityHealthCover: Maybe<Scalars['Int']['output']>;
  liabilityPropertyCover: Maybe<Scalars['Int']['output']>;
  medicalExpenseCover: Maybe<Scalars['Int']['output']>;
  metaDescription: Maybe<Scalars['String']['output']>;
  metaTitle: Scalars['String']['output'];
  name: Scalars['String']['output'];
  partner: ZeteoInsurancePartner;
  productDetailFile: Maybe<File>;
  shortName: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  titleSuffix: Maybe<Scalars['String']['output']>;
  type: TravelInsuranceTravelInsuranceType;
  updatedAt: Scalars['DateTime']['output'];
};

export type TravelInsuranceProductOffer = {
  __typename?: 'TravelInsuranceProductOffer';
  hasUpsellOffer: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Int']['output'];
  insuranceType: TravelInsuranceTravelInsuranceType;
  packageType: TravelInsuranceInsurancePackageType;
  price: Scalars['Float']['output'];
  pricePerDay: Scalars['Float']['output'];
  product: Maybe<TravelInsuranceProduct>;
  uuid: Scalars['String']['output'];
};

export type TravelInsuranceRecapitulationInfo = {
  __typename?: 'TravelInsuranceRecapitulationInfo';
  oldPrice: Scalars['Float']['output'];
  priceHasChanged: Scalars['Boolean']['output'];
};

export type TravelInsuranceSelectOfferInput = {
  selectedOffer: Scalars['Int']['input'];
};

export const TravelInsuranceTravelInsuranceType = {
  Multiple: 'MULTIPLE',
  Onetime: 'ONETIME',
  Year: 'YEAR'
} as const;

export type TravelInsuranceTravelInsuranceType = typeof TravelInsuranceTravelInsuranceType[keyof typeof TravelInsuranceTravelInsuranceType];
export const TravelInsuranceTravelPurpose = {
  BusinessAdministrative: 'BUSINESS_ADMINISTRATIVE',
  BusinessManual: 'BUSINESS_MANUAL',
  Tourist: 'TOURIST'
} as const;

export type TravelInsuranceTravelPurpose = typeof TravelInsuranceTravelPurpose[keyof typeof TravelInsuranceTravelPurpose];
export type TravelInsuranceUpsellOffer = {
  __typename?: 'TravelInsuranceUpsellOffer';
  selectedOffer: TravelInsuranceProductOffer;
  selectedPackageType: TravelInsuranceInsurancePackageType;
  upsellOfferToOptimum: Maybe<TravelInsuranceProductOffer>;
  upsellOfferToPremium: Maybe<TravelInsuranceProductOffer>;
  upsellStoryContentSerialized: Scalars['String']['output'];
};

export const TvCommitment = {
  WithoutCommitment: 'WITHOUT_COMMITMENT',
  Years_2: 'YEARS_2'
} as const;

export type TvCommitment = typeof TvCommitment[keyof typeof TvCommitment];
export type TvExtensionPackage = {
  __typename?: 'TvExtensionPackage';
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  price: Money;
};

export type TvProduct = {
  __typename?: 'TvProduct';
  commitment: Maybe<TvCommitment>;
  description: Maybe<Scalars['String']['output']>;
  hdTvChannelsCount: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  metaDescription: Maybe<Scalars['String']['output']>;
  metaTitle: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  priceForCombination: Maybe<Money>;
  priceStandard: Money;
  tags: Array<TelcoTag>;
  tvChannelsCount: Scalars['Int']['output'];
  tvExtensionPackages: Array<TvExtensionPackage>;
  tvProductLine: TvProductLine;
};

export type TvProductLine = {
  __typename?: 'TvProductLine';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  partner: TelcoPartner;
};

export const TvProgramOffer = {
  Basic: 'BASIC',
  Extended: 'EXTENDED',
  Maximum: 'MAXIMUM'
} as const;

export type TvProgramOffer = typeof TvProgramOffer[keyof typeof TvProgramOffer];
export const TvProgramType = {
  Documents: 'DOCUMENTS',
  Erotic: 'EROTIC',
  Kids: 'KIDS',
  Movie: 'MOVIE',
  Sport: 'SPORT'
} as const;

export type TvProgramType = typeof TvProgramType[keyof typeof TvProgramType];
export type UpdateProfileBenefitInput = {
  files?: InputMaybe<Array<Scalars['String']['input']>>;
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  surname?: InputMaybe<Scalars['String']['input']>;
};

export const UserRole = {
  RoleAdmin: 'ROLE_ADMIN',
  RoleConectart: 'ROLE_CONECTART',
  RoleConectartApi: 'ROLE_CONECTART_API',
  RoleExternalSales: 'ROLE_EXTERNAL_SALES',
  RoleManagement: 'ROLE_MANAGEMENT',
  RoleOneClickApi: 'ROLE_ONE_CLICK_API',
  RoleProfile: 'ROLE_PROFILE',
  RoleRoot: 'ROLE_ROOT',
  RoleSales: 'ROLE_SALES',
  RoleUser: 'ROLE_USER'
} as const;

export type UserRole = typeof UserRole[keyof typeof UserRole];
export const VehicleBusinessCaseStatus = {
  Calculation: 'CALCULATION',
  CalculationInitialized: 'CALCULATION_INITIALIZED',
  Cancelled: 'CANCELLED',
  ContractPayed: 'CONTRACT_PAYED',
  InquiryCreated: 'INQUIRY_CREATED',
  Offer: 'OFFER',
  Order: 'ORDER',
  OrderFilledIn: 'ORDER_FILLED_IN',
  Recapitulation: 'RECAPITULATION',
  Sleeping: 'SLEEPING',
  TerminatedWithCommission: 'TERMINATED_WITH_COMMISSION'
} as const;

export type VehicleBusinessCaseStatus = typeof VehicleBusinessCaseStatus[keyof typeof VehicleBusinessCaseStatus];
export type VehicleFindByRegistrationNumberInput = {
  orderUuid: Scalars['String']['input'];
  registrationNumber: Scalars['String']['input'];
};

export type VehicleFindByRegistrationNumberResponse = {
  __typename?: 'VehicleFindByRegistrationNumberResponse';
  countOfSeats: Array<CodeBook>;
  engineDisplacement: Array<CodeBook>;
  fuel: Array<CodeBook>;
  manufacturer: Array<CodeBook>;
  maxPower: Array<CodeBook>;
  maxWeight: Array<CodeBook>;
  model: Array<CodeBook>;
  modelLine: Array<CodeBook>;
  modelType: Array<CodeBook>;
  vehicleType: Array<VehicleType>;
};

export type VehicleInsuranceAdditionalRequirements = {
  __typename?: 'VehicleInsuranceAdditionalRequirements';
  animalAccident: VehicleInsuranceAnimalAccidentRequirement;
  glass: VehicleInsuranceGlassRequirement;
  id: Scalars['String']['output'];
};

export type VehicleInsuranceAdditionalRequirementsFromClient = {
  __typename?: 'VehicleInsuranceAdditionalRequirementsFromClient';
  animalAccident: Maybe<Scalars['Boolean']['output']>;
  glass: Maybe<Scalars['Boolean']['output']>;
};

export type VehicleInsuranceAdditionalRequirementsInput = {
  animalAccidentRequirement: Scalars['Boolean']['input'];
  glassRequirement: Scalars['Boolean']['input'];
};

export type VehicleInsuranceAnimalAccidentRequirement = {
  __typename?: 'VehicleInsuranceAnimalAccidentRequirement';
  enabled: Scalars['Boolean']['output'];
  value: Scalars['Int']['output'];
};

export type VehicleInsuranceCalculationStartWithSpecificInsuranceTypeInput = {
  insuranceType?: InputMaybe<VehicleInsuranceInsuranceType>;
};

export type VehicleInsuranceCalculationStateResult = {
  __typename?: 'VehicleInsuranceCalculationStateResult';
  result: Maybe<VehicleInsuranceOrderResult>;
  state: ZeteoState;
};

export const VehicleInsuranceCalculationStep = {
  AboutInsurance: 'ABOUT_INSURANCE',
  InsuredPersonsData: 'INSURED_PERSONS_DATA',
  VehicleSpecification: 'VEHICLE_SPECIFICATION',
  VehicleUsage: 'VEHICLE_USAGE'
} as const;

export type VehicleInsuranceCalculationStep = typeof VehicleInsuranceCalculationStep[keyof typeof VehicleInsuranceCalculationStep];
export type VehicleInsuranceCallback = {
  __typename?: 'VehicleInsuranceCallback';
  id: Scalars['Int']['output'];
};

export type VehicleInsuranceChangeBeginDateInput = {
  beginDate: Scalars['DateTime']['input'];
};

export type VehicleInsuranceCodeBooks = {
  __typename?: 'VehicleInsuranceCodeBooks';
  additionalEquipment: Array<CodeBook>;
  carUsageMethod: Array<CodeBook>;
  country: Array<CodeBook>;
  kilometersPerYearVehicle: Array<CodeBook>;
  representativeFunctions: Array<CodeBook>;
  vehicleAcquisitionMethod: Array<CodeBook>;
};

export type VehicleInsuranceContactPerson = {
  __typename?: 'VehicleInsuranceContactPerson';
  agreementProcessPersonalData: Scalars['Boolean']['output'];
  agreementTermsAndConditions: Scalars['Boolean']['output'];
  email: Scalars['String']['output'];
  name: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  surname: Scalars['String']['output'];
};

export type VehicleInsuranceContactPersonInput = {
  agreementProcessPersonalData: Scalars['Boolean']['input'];
  agreementTermsAndConditions: Scalars['Boolean']['input'];
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  surname: Scalars['String']['input'];
};

export type VehicleInsuranceContract = {
  __typename?: 'VehicleInsuranceContract';
  annualPrice: Money;
  casco: Maybe<VehicleInsuranceProduct>;
  documents: Array<Maybe<VehicleInsuranceContractDocument>>;
  id: Scalars['Int']['output'];
  mtpl: Maybe<VehicleInsuranceProduct>;
  payed: Scalars['Boolean']['output'];
  paymentInfo: Maybe<VehicleInsuranceContractPaymentInfo>;
  paymentUrl: Maybe<Scalars['String']['output']>;
  price: Money;
  uuid: Scalars['String']['output'];
};

export type VehicleInsuranceContractAdditionalInfoInput = {
  insurableInterest: VehicleInsuranceInsurableInterest;
  numberOfRegistrationCertificate: Scalars['String']['input'];
  registrationNumber?: InputMaybe<Scalars['String']['input']>;
  subjectCarHolder?: InputMaybe<VehicleInsuranceContractAdditionalInfoSubjectInput>;
  subjectCarOwner?: InputMaybe<VehicleInsuranceContractAdditionalInfoSubjectInput>;
  subjectInsured: VehicleInsuranceContractAdditionalInfoSubjectInput;
  vin?: InputMaybe<Scalars['String']['input']>;
};

export type VehicleInsuranceContractAdditionalInfoSubjectInput = {
  cityPart: CityPartInput;
  correspondenceSmartAddressId?: InputMaybe<Scalars['Int']['input']>;
  email: Scalars['String']['input'];
  functionOfRepresentative?: InputMaybe<Scalars['Int']['input']>;
  nameOfRepresentative?: InputMaybe<Scalars['String']['input']>;
  phone: Scalars['String']['input'];
  smartAddressId: Scalars['Int']['input'];
  surnameOfRepresentative?: InputMaybe<Scalars['String']['input']>;
};

export type VehicleInsuranceContractCompleteOrder = {
  __typename?: 'VehicleInsuranceContractCompleteOrder';
  finishOrderState: Maybe<ZeteoState>;
  order: VehicleInsuranceOrder;
};

export type VehicleInsuranceContractDocument = {
  __typename?: 'VehicleInsuranceContractDocument';
  documentCategory: CodeBook;
  documentType: Scalars['String']['output'];
  file: Maybe<File>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type VehicleInsuranceContractPaymentInfo = {
  __typename?: 'VehicleInsuranceContractPaymentInfo';
  account: Scalars['String']['output'];
  amount: Maybe<Money>;
  bankCode: Scalars['String']['output'];
  constantSymbol: Maybe<Scalars['String']['output']>;
  message: Maybe<Scalars['String']['output']>;
  paymentUrl: Maybe<Scalars['String']['output']>;
  qrCodeFile: Maybe<File>;
  specificSymbol: Maybe<Scalars['String']['output']>;
  variableSymbol: Maybe<Scalars['String']['output']>;
};

export type VehicleInsuranceContractRecapitulation = {
  __typename?: 'VehicleInsuranceContractRecapitulation';
  order: Maybe<VehicleInsuranceOrder>;
  recalculationState: ZeteoState;
  recapitulation: Maybe<VehicleInsuranceRecapitulationInfo>;
  recapitulationState: Maybe<ZeteoState>;
};

export type VehicleInsuranceEstimatedPrice = {
  __typename?: 'VehicleInsuranceEstimatedPrice';
  halfYearly: Maybe<Scalars['Float']['output']>;
  monthly: Maybe<Scalars['Float']['output']>;
  quarterly: Maybe<Scalars['Float']['output']>;
  yearly: Maybe<Scalars['Float']['output']>;
};

export type VehicleInsuranceEvaluation = {
  __typename?: 'VehicleInsuranceEvaluation';
  medals: Scalars['Int']['output'];
};

export type VehicleInsuranceFinancialCompany = {
  __typename?: 'VehicleInsuranceFinancialCompany';
  code: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type VehicleInsuranceGlassRequirement = {
  __typename?: 'VehicleInsuranceGlassRequirement';
  enabled: Scalars['Boolean']['output'];
  frontGlass: Scalars['Boolean']['output'];
  rearAndSideGlass: Scalars['Boolean']['output'];
  roofGlass: Scalars['Boolean']['output'];
  value: Scalars['Int']['output'];
};

export type VehicleInsuranceInquiryCreateInput = {
  method: VehicleInsuranceInquiryCreationMethod;
};

export const VehicleInsuranceInquiryCreationMethod = {
  Online: 'ONLINE',
  Phone: 'PHONE'
} as const;

export type VehicleInsuranceInquiryCreationMethod = typeof VehicleInsuranceInquiryCreationMethod[keyof typeof VehicleInsuranceInquiryCreationMethod];
export const VehicleInsuranceInsurableInterest = {
  OwnershipByClosePerson: 'OWNERSHIP_BY_CLOSE_PERSON',
  OwnershipByCompany: 'OWNERSHIP_BY_COMPANY',
  OwnershipByInsuredPerson: 'OWNERSHIP_BY_INSURED_PERSON',
  OwnershipByThirdPerson: 'OWNERSHIP_BY_THIRD_PERSON'
} as const;

export type VehicleInsuranceInsurableInterest = typeof VehicleInsuranceInsurableInterest[keyof typeof VehicleInsuranceInsurableInterest];
export const VehicleInsuranceInsuranceType = {
  Hav: 'HAV',
  Pov: 'POV',
  PovHav: 'POV_HAV'
} as const;

export type VehicleInsuranceInsuranceType = typeof VehicleInsuranceInsuranceType[keyof typeof VehicleInsuranceInsuranceType];
export type VehicleInsuranceLastOrder = {
  __typename?: 'VehicleInsuranceLastOrder';
  cityPart: CityPart;
  id: Scalars['Int']['output'];
  registrationNumber: Maybe<Scalars['String']['output']>;
};

export type VehicleInsuranceOfferSetPaymentFrequencyInput = {
  paymentFrequency: InsurancePaymentFrequency;
};

export type VehicleInsuranceOrder = {
  __typename?: 'VehicleInsuranceOrder';
  additionalOptimumRequirements: VehicleInsuranceAdditionalRequirementsFromClient;
  beginDate: Maybe<Scalars['DateTime']['output']>;
  calculationCurrentStep: Maybe<VehicleInsuranceCalculationStep>;
  contactPerson: Maybe<VehicleInsuranceContactPerson>;
  contract: Maybe<VehicleInsuranceContract>;
  createdAt: Scalars['DateTime']['output'];
  createdFrom: Maybe<OrderCreatedFrom>;
  hasSpecificPackages: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  insurableInterest: Maybe<VehicleInsuranceInsurableInterest>;
  orderCurrentStep: Maybe<VehicleInsuranceOrderStep>;
  packagesInsuranceType: Maybe<VehicleInsuranceInsuranceType>;
  phone: Maybe<Scalars['String']['output']>;
  resultEmail: Maybe<Scalars['String']['output']>;
  selectedInsuranceType: Maybe<VehicleInsuranceInsuranceType>;
  selectedOffer: Maybe<VehicleInsuranceProductOffer>;
  status: VehicleBusinessCaseStatus;
  subjectCarHolder: Maybe<InsuredSubject>;
  subjectCarOwner: Maybe<InsuredSubject>;
  subjectInsured: InsuredSubject;
  updatedAt: Scalars['DateTime']['output'];
  uuid: Scalars['String']['output'];
  vehicle: InsuredVehicle;
};

export type VehicleInsuranceOrderInitInput = {
  cityPart: CityPartInput;
  registrationNumber?: InputMaybe<Scalars['String']['input']>;
};

export type VehicleInsuranceOrderResult = {
  __typename?: 'VehicleInsuranceOrderResult';
  cheapestOffer: Maybe<VehicleInsuranceProductOffer>;
  /** Uuid of order */
  id: Scalars['String']['output'];
  lowestPrice: Maybe<Scalars['Float']['output']>;
  order: VehicleInsuranceOrder;
  packages: Array<VehicleInsurancePackage>;
};

export const VehicleInsuranceOrderStep = {
  AdditionalInfo: 'ADDITIONAL_INFO',
  ContactPersonData: 'CONTACT_PERSON_DATA',
  OrderDataCheck: 'ORDER_DATA_CHECK',
  OrderSummary: 'ORDER_SUMMARY'
} as const;

export type VehicleInsuranceOrderStep = typeof VehicleInsuranceOrderStep[keyof typeof VehicleInsuranceOrderStep];
export type VehicleInsuranceOrderUpdateInput = {
  additionalRequirements?: InputMaybe<VehicleInsuranceAdditionalRequirementsInput>;
  beginDate?: InputMaybe<Scalars['DateTime']['input']>;
  createdFrom?: InputMaybe<OrderCreatedFrom>;
  phone?: InputMaybe<Scalars['String']['input']>;
  resultEmail?: InputMaybe<Scalars['String']['input']>;
  sendCalculationEmailImmediately?: InputMaybe<Scalars['Boolean']['input']>;
  subjectCarHolder?: InputMaybe<InsuredSubjectInput>;
  subjectCarOwner?: InputMaybe<InsuredSubjectInput>;
  subjectInsured?: InputMaybe<InsuredSubjectInput>;
  vehicle?: InputMaybe<InsuredVehicleInput>;
};

export type VehicleInsurancePackage = {
  __typename?: 'VehicleInsurancePackage';
  additionalRequirements: VehicleInsuranceAdditionalRequirements;
  id: Scalars['Int']['output'];
  lowestPrice: Maybe<Scalars['Float']['output']>;
  mobileOrder: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  participation: Scalars['Int']['output'];
  productOffers: Array<VehicleInsuranceProductOffer>;
  protectionCover: Scalars['Int']['output'];
  type: VehicleInsuranceInsuranceType;
  uuid: Scalars['String']['output'];
};

export type VehicleInsuranceProduct = {
  __typename?: 'VehicleInsuranceProduct';
  animalAccidentRequirementInfoList: Array<Scalars['String']['output']>;
  bonus: Maybe<Scalars['String']['output']>;
  cascoParticipationMinimum: Maybe<Scalars['Int']['output']>;
  cascoParticipationPercent: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  descriptionMD: Maybe<Scalars['String']['output']>;
  extendedInfoList: Array<Scalars['String']['output']>;
  gift: Maybe<Scalars['String']['output']>;
  glassRequirementInfoList: Array<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  infoList: Array<Scalars['String']['output']>;
  label: Maybe<Scalars['String']['output']>;
  labelTooltip: Maybe<Scalars['String']['output']>;
  metaDescription: Maybe<Scalars['String']['output']>;
  metaTitle: Scalars['String']['output'];
  name: Scalars['String']['output'];
  partner: ZeteoInsurancePartner;
  productDetailFile: Maybe<File>;
  protectionCover: Maybe<Scalars['Int']['output']>;
  shortName: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  titleSuffix: Maybe<Scalars['String']['output']>;
  type: VehicleInsuranceInsuranceType;
  updatedAt: Scalars['DateTime']['output'];
};

export type VehicleInsuranceProductOffer = {
  __typename?: 'VehicleInsuranceProductOffer';
  calculatedPaymentFrequency: InsurancePaymentFrequency;
  cascoProduct: Maybe<VehicleInsuranceProduct>;
  estimatedPrice: VehicleInsuranceEstimatedPrice;
  id: Scalars['Int']['output'];
  insuranceType: VehicleInsuranceInsuranceType;
  mtplProduct: Maybe<VehicleInsuranceProduct>;
  paymentFrequency: InsurancePaymentFrequency;
  price: Scalars['Float']['output'];
  uuid: Scalars['String']['output'];
};

export type VehicleInsuranceRecapitulationInfo = {
  __typename?: 'VehicleInsuranceRecapitulationInfo';
  oldPrice: Scalars['Float']['output'];
  paymentFrequency: InsurancePaymentFrequency;
  price: Scalars['Int']['output'];
};

export type VehicleInsuranceSelectOfferInput = {
  selectedOffer: Scalars['Int']['input'];
};

export type VehicleInsuranceSetCalculationCurrentStepInput = {
  calculationCurrentStep: VehicleInsuranceCalculationStep;
};

export type VehicleInsuranceSetOrderCurrentStepInput = {
  orderCurrentStep: VehicleInsuranceOrderStep;
};

export type VehicleInsuranceSetOrderStatusInput = {
  status: VehicleBusinessCaseStatus;
};

export const VehicleInsuranceSubjectType = {
  Company: 'COMPANY',
  PhysicalPerson: 'PHYSICAL_PERSON',
  SelfEmployedPerson: 'SELF_EMPLOYED_PERSON'
} as const;

export type VehicleInsuranceSubjectType = typeof VehicleInsuranceSubjectType[keyof typeof VehicleInsuranceSubjectType];
export const VehicleInsuranceVehicleSearchStatus = {
  FoundSuccessfully: 'FOUND_SUCCESSFULLY',
  LimitExceeded: 'LIMIT_EXCEEDED',
  NotFoundByVin: 'NOT_FOUND_BY_VIN',
  NotProvided: 'NOT_PROVIDED',
  VinNotFound: 'VIN_NOT_FOUND'
} as const;

export type VehicleInsuranceVehicleSearchStatus = typeof VehicleInsuranceVehicleSearchStatus[keyof typeof VehicleInsuranceVehicleSearchStatus];
export type VehicleSearchParametersInput = {
  countOfSeatsId?: InputMaybe<Scalars['Int']['input']>;
  engineDisplacementId?: InputMaybe<Scalars['Int']['input']>;
  fuelId?: InputMaybe<Scalars['Int']['input']>;
  manufacturerId?: InputMaybe<Scalars['Int']['input']>;
  maxPowerId?: InputMaybe<Scalars['Int']['input']>;
  maxWeightId?: InputMaybe<Scalars['Int']['input']>;
  modelId?: InputMaybe<Scalars['Int']['input']>;
  modelLineId?: InputMaybe<Scalars['Int']['input']>;
  orderUuid: Scalars['String']['input'];
  vehicleType: VehicleType;
};

export type VehicleSearchParametersResponse = {
  __typename?: 'VehicleSearchParametersResponse';
  countOfSeats: Array<CodeBook>;
  engineDisplacement: Array<CodeBook>;
  fuel: Array<CodeBook>;
  manufacturer: Array<CodeBook>;
  maxPower: Array<CodeBook>;
  maxWeight: Array<CodeBook>;
  model: Array<CodeBook>;
  modelLine: Array<CodeBook>;
  modelType: Array<CodeBook>;
  vehicleType: Array<VehicleType>;
};

export type VehicleSecurity = {
  __typename?: 'VehicleSecurity';
  activeSearch: Scalars['Boolean']['output'];
  alarm: Scalars['Boolean']['output'];
  glassVin: Scalars['Boolean']['output'];
  immobilizer: Scalars['Boolean']['output'];
  mechanical: Scalars['Boolean']['output'];
  none: Scalars['Boolean']['output'];
  passiveSearch: Scalars['Boolean']['output'];
};

export type VehicleSecurityInput = {
  activeSearch: Scalars['Boolean']['input'];
  alarm: Scalars['Boolean']['input'];
  glassVin: Scalars['Boolean']['input'];
  immobilizer: Scalars['Boolean']['input'];
  mechanical: Scalars['Boolean']['input'];
  none: Scalars['Boolean']['input'];
  passiveSearch: Scalars['Boolean']['input'];
};

export const VehicleType = {
  CommercialVehicle: 'COMMERCIAL_VEHICLE',
  Motorcycle: 'MOTORCYCLE',
  Trailer: 'TRAILER',
  Vehicle: 'VEHICLE'
} as const;

export type VehicleType = typeof VehicleType[keyof typeof VehicleType];
export const Vertical = {
  Energy: 'ENERGY',
  Finance: 'FINANCE',
  General: 'GENERAL',
  Insurance: 'INSURANCE',
  Telco: 'TELCO'
} as const;

export type Vertical = typeof Vertical[keyof typeof Vertical];
export type WatchWolf = {
  __typename?: 'WatchWolf';
  commitmentEnd: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  energoCalculation: Maybe<EnergoCalculation>;
  minSaving: Maybe<Scalars['Int']['output']>;
  name: Maybe<Scalars['String']['output']>;
  phone: Maybe<Scalars['String']['output']>;
  preferredEmail: Maybe<Scalars['String']['output']>;
  subVertical: SubVertical;
  type: WatchWolfType;
  uuid: Scalars['String']['output'];
  vehicleInsuranceOrder: Maybe<VehicleInsuranceOrder>;
  vertical: Vertical;
};

export type WatchWolfCreateCalculationInput = {
  commitmentEnd?: InputMaybe<Scalars['String']['input']>;
  minSaving?: InputMaybe<Scalars['Int']['input']>;
  monthlyAdvances?: InputMaybe<Scalars['Int']['input']>;
  partnerId: Scalars['Int']['input'];
  preferredEmail: Scalars['String']['input'];
  subVertical: SubVertical;
};

export type WatchWolfEmptyEnergo = {
  __typename?: 'WatchWolfEmptyEnergo';
  hasEnergoElectricityCalculations: Scalars['Boolean']['output'];
  hasEnergoGasCalculations: Scalars['Boolean']['output'];
  watchWolfUuid: Scalars['String']['output'];
};

export type WatchWolfPreview = {
  __typename?: 'WatchWolfPreview';
  commitmentEnd: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  minSaving: Maybe<Scalars['Int']['output']>;
  name: Maybe<Scalars['String']['output']>;
  phone: Maybe<Scalars['String']['output']>;
  preferredEmail: Maybe<Scalars['String']['output']>;
  subVertical: Maybe<SubVertical>;
  type: Maybe<WatchWolfType>;
  uuid: Scalars['String']['output'];
  vertical: Maybe<Vertical>;
};

export const WatchWolfType = {
  CommitmentEnd: 'COMMITMENT_END',
  Saving: 'SAVING'
} as const;

export type WatchWolfType = typeof WatchWolfType[keyof typeof WatchWolfType];
export type WatchWolfUpdateInput = {
  commitmentEnd?: InputMaybe<Scalars['String']['input']>;
  minSaving?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  preferredEmail?: InputMaybe<Scalars['String']['input']>;
};

export type ZeteoInsurancePartner = {
  __typename?: 'ZeteoInsurancePartner';
  companyAddress: Maybe<Scalars['String']['output']>;
  contactEmail: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  logo: Maybe<File>;
  logoSquare: Maybe<File>;
  metaDescription: Maybe<Scalars['String']['output']>;
  metaTitle: Scalars['String']['output'];
  name: Scalars['String']['output'];
  rating: Maybe<Rating>;
  rating2: Maybe<NewRating>;
  title: Scalars['String']['output'];
  titleSuffix: Maybe<Scalars['String']['output']>;
  tollFreePhone: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  web: Maybe<Scalars['String']['output']>;
};

export type ZeteoInsurancePartnerDetail = {
  __typename?: 'ZeteoInsurancePartnerDetail';
  active: Maybe<Scalars['Boolean']['output']>;
  companyAddress: Maybe<Scalars['String']['output']>;
  companyId: Maybe<Scalars['String']['output']>;
  contactEmail: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  descriptionMD: Maybe<Scalars['String']['output']>;
  faqs: Array<Faq>;
  id: Scalars['Int']['output'];
  internalLinkLabel1: Maybe<Scalars['String']['output']>;
  internalLinkLabel2: Maybe<Scalars['String']['output']>;
  internalLinkLabel3: Maybe<Scalars['String']['output']>;
  internalLinkUrl1: Maybe<Scalars['String']['output']>;
  internalLinkUrl2: Maybe<Scalars['String']['output']>;
  internalLinkUrl3: Maybe<Scalars['String']['output']>;
  latitude: Maybe<Scalars['Float']['output']>;
  logo: Maybe<File>;
  logoSquare: Maybe<File>;
  longArticleMD: Maybe<Scalars['String']['output']>;
  longitude: Maybe<Scalars['Float']['output']>;
  metaDescription: Maybe<Scalars['String']['output']>;
  metaTitle: Scalars['String']['output'];
  name: Scalars['String']['output'];
  order: Maybe<Scalars['String']['output']>;
  rating: Maybe<Rating>;
  rating2: Maybe<NewRating>;
  seoName: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  titleSuffix: Maybe<Scalars['String']['output']>;
  tollFreePhone: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  urlPricelist: Maybe<Scalars['String']['output']>;
  urlVop: Maybe<Scalars['String']['output']>;
  validFrom: Maybe<Scalars['DateTime']['output']>;
  validTo: Maybe<Scalars['DateTime']['output']>;
  web: Maybe<Scalars['String']['output']>;
};

export type ZeteoState = {
  __typename?: 'ZeteoState';
  error: Scalars['Boolean']['output'];
  finished: Scalars['Boolean']['output'];
  finishedSteps: Scalars['Int']['output'];
  maximumSteps: Scalars['Int']['output'];
};

export type AuthResultFragment = (
  { __typename?: 'AuthResult' }
  & Pick<AuthResult, 'accessToken' | 'refreshToken' | 'expires'>
);

export type RegistrationMutationVariables = Exact<{
  data: RegistrationInput;
}>;


export type RegistrationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'registration'>
);

export type RegistrationByEmailMutationVariables = Exact<{
  data: RegistrationByEmailInput;
}>;


export type RegistrationByEmailMutation = (
  { __typename?: 'Mutation' }
  & { registrationByEmail: Maybe<(
    { __typename?: 'CoreStatus' }
    & Pick<CoreStatus, 'success'>
  )> }
);

export type CreateMagicLinkMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type CreateMagicLinkMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createMagicLink'>
);

export type LoginMutationVariables = Exact<{
  username: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'login'>
);

export type SendMeMagicLinkMutationVariables = Exact<{ [key: string]: never; }>;


export type SendMeMagicLinkMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendMeMagicLink'>
);

export type PasswordChangeMutationVariables = Exact<{
  password: Scalars['String']['input'];
}>;


export type PasswordChangeMutation = (
  { __typename?: 'Mutation' }
  & { passwordChange: Maybe<(
    { __typename?: 'MyProfile' }
    & Pick<MyProfile, 'id' | 'uuid' | 'name' | 'surname' | 'companyName' | 'email' | 'otherPhones' | 'phone' | 'preferedComunication' | 'notificationPolicy' | 'isLogged' | 'vocative' | 'role' | 'customerZoneIntroSeenAt' | 'passwordSet'>
    & { activeCampaigns: Array<(
      { __typename?: 'Campaign' }
      & Pick<Campaign, 'id' | 'uuid' | 'active' | 'name'>
    )>, firstCompleteAddress: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
      & { extended: Maybe<(
        { __typename?: 'SmartAddressExtendedInfo' }
        & Pick<SmartAddressExtendedInfo, 'id'>
        & { objectType: Maybe<(
          { __typename?: 'SmartAddressObjectType' }
          & Pick<SmartAddressObjectType, 'id' | 'nazev'>
        )> }
      )>, extendedV2: Maybe<(
        { __typename?: 'SmartAddressSdkExtendedInfo' }
        & { administrativniUdaje: Maybe<(
          { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
          & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )> }
      )> }
    )>, backofficeProfile: Maybe<(
      { __typename?: 'AdminProfile' }
      & Pick<AdminProfile, 'id'>
    )> }
  )> }
);

export type PasswordChangeEmailMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type PasswordChangeEmailMutation = (
  { __typename?: 'Mutation' }
  & { passwordChangeEmail: Maybe<(
    { __typename?: 'CoreStatus' }
    & Pick<CoreStatus, 'success'>
  )> }
);

export type LoginTypeMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type LoginTypeMutation = (
  { __typename?: 'Mutation' }
  & { loginType: (
    { __typename?: 'LoginType' }
    & Pick<LoginType, 'type'>
  ) }
);

export type BenefitAttachmentFragment = (
  { __typename?: 'BenefitAttachment' }
  & Pick<BenefitAttachment, 'id'>
  & { file: (
    { __typename?: 'File' }
    & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
  ) }
);

export type BenefitFragment = (
  { __typename?: 'Benefit' }
  & Pick<Benefit, 'id' | 'uuid' | 'name' | 'validFrom' | 'validTo' | 'template' | 'perex' | 'text' | 'buttonText'>
  & { profileBenefit: (
    { __typename?: 'ProfileBenefit' }
    & Pick<ProfileBenefit, 'id' | 'name' | 'surname' | 'status' | 'phone'>
    & { attachments: Array<(
      { __typename?: 'BenefitAttachment' }
      & Pick<BenefitAttachment, 'id'>
      & { file: (
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      ) }
    )> }
  ), image: Maybe<(
    { __typename?: 'File' }
    & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
  )> }
);

export type ProfileBenefitFragment = (
  { __typename?: 'ProfileBenefit' }
  & Pick<ProfileBenefit, 'id' | 'name' | 'surname' | 'status' | 'phone'>
  & { attachments: Array<(
    { __typename?: 'BenefitAttachment' }
    & Pick<BenefitAttachment, 'id'>
    & { file: (
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    ) }
  )> }
);

export type UpdateBenefitMutationVariables = Exact<{
  data: UpdateProfileBenefitInput;
}>;


export type UpdateBenefitMutation = (
  { __typename?: 'Mutation' }
  & { updateBenefit: Maybe<(
    { __typename?: 'Benefit' }
    & Pick<Benefit, 'id' | 'uuid' | 'name' | 'validFrom' | 'validTo' | 'template' | 'perex' | 'text' | 'buttonText'>
    & { profileBenefit: (
      { __typename?: 'ProfileBenefit' }
      & Pick<ProfileBenefit, 'id' | 'name' | 'surname' | 'status' | 'phone'>
      & { attachments: Array<(
        { __typename?: 'BenefitAttachment' }
        & Pick<BenefitAttachment, 'id'>
        & { file: (
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        ) }
      )> }
    ), image: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    )> }
  )> }
);

export type BenefitActivateMutationVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;


export type BenefitActivateMutation = (
  { __typename?: 'Mutation' }
  & { benefitActivate: (
    { __typename?: 'Benefit' }
    & Pick<Benefit, 'id' | 'uuid' | 'name' | 'validFrom' | 'validTo' | 'template' | 'perex' | 'text' | 'buttonText'>
    & { profileBenefit: (
      { __typename?: 'ProfileBenefit' }
      & Pick<ProfileBenefit, 'id' | 'name' | 'surname' | 'status' | 'phone'>
      & { attachments: Array<(
        { __typename?: 'BenefitAttachment' }
        & Pick<BenefitAttachment, 'id'>
        & { file: (
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        ) }
      )> }
    ), image: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    )> }
  ) }
);

export type BenefitGetAllQueryVariables = Exact<{ [key: string]: never; }>;


export type BenefitGetAllQuery = (
  { __typename?: 'Query' }
  & { benefitGetAll: Array<(
    { __typename?: 'Benefit' }
    & Pick<Benefit, 'id' | 'uuid' | 'name' | 'validFrom' | 'validTo' | 'template' | 'perex' | 'text' | 'buttonText'>
    & { profileBenefit: (
      { __typename?: 'ProfileBenefit' }
      & Pick<ProfileBenefit, 'id' | 'name' | 'surname' | 'status' | 'phone'>
      & { attachments: Array<(
        { __typename?: 'BenefitAttachment' }
        & Pick<BenefitAttachment, 'id'>
        & { file: (
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        ) }
      )> }
    ), image: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    )> }
  )> }
);

export type BenefitGetQueryVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;


export type BenefitGetQuery = (
  { __typename?: 'Query' }
  & { benefitGet: (
    { __typename?: 'Benefit' }
    & Pick<Benefit, 'id' | 'uuid' | 'name' | 'validFrom' | 'validTo' | 'template' | 'perex' | 'text' | 'buttonText'>
    & { profileBenefit: (
      { __typename?: 'ProfileBenefit' }
      & Pick<ProfileBenefit, 'id' | 'name' | 'surname' | 'status' | 'phone'>
      & { attachments: Array<(
        { __typename?: 'BenefitAttachment' }
        & Pick<BenefitAttachment, 'id'>
        & { file: (
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        ) }
      )> }
    ), image: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    )> }
  ) }
);

export type ArticlePreviewFragment = (
  { __typename?: 'ArticlePreview' }
  & Pick<ArticlePreview, 'id' | 'title' | 'perex' | 'seoName' | 'imageAlt' | 'imageSource' | 'author' | 'publishedAt' | 'updatedAt' | 'redirectTo'>
  & { image: Maybe<(
    { __typename?: 'File' }
    & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
  )> }
);

export type ArticleCategoryFragment = (
  { __typename?: 'ArticleCategory' }
  & Pick<ArticleCategory, 'id' | 'name' | 'titleSuffix' | 'vertical' | 'subVertical' | 'metaTitle' | 'metaDescription'>
  & { articles: Maybe<Array<(
    { __typename?: 'ArticlePreview' }
    & Pick<ArticlePreview, 'id' | 'title' | 'perex' | 'seoName' | 'imageAlt' | 'imageSource' | 'author' | 'publishedAt' | 'updatedAt' | 'redirectTo'>
    & { image: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    )> }
  )>>, metaImage: Maybe<(
    { __typename?: 'File' }
    & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
  )> }
);

export type ArticleTagFragment = (
  { __typename?: 'ArticleTag' }
  & Pick<ArticleTag, 'tagId' | 'tagName' | 'tagSeoName'>
  & { articles: Maybe<Array<(
    { __typename?: 'ArticlePreview' }
    & Pick<ArticlePreview, 'id' | 'title' | 'perex' | 'seoName' | 'imageAlt' | 'imageSource' | 'author' | 'publishedAt' | 'updatedAt' | 'redirectTo'>
    & { image: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    )> }
  )>> }
);

export type ArticleFragment = (
  { __typename?: 'Article' }
  & Pick<Article, 'id' | 'title' | 'titleSuffix' | 'metaTitle' | 'perex' | 'seoName' | 'metaDescription' | 'imageAlt' | 'imageSource' | 'author' | 'publishedAt' | 'vertical' | 'subVertical' | 'knowledgeBaseConceptTags' | 'knowledgeBaseQuestionTags' | 'tags' | 'redirectTo'>
  & { category: (
    { __typename?: 'ArticleCategoryListItem' }
    & Pick<ArticleCategoryListItem, 'id' | 'name' | 'vertical' | 'subVertical'>
  ), image: Maybe<(
    { __typename?: 'File' }
    & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
  )>, sections: Array<(
    { __typename?: 'BlogSectionBannerWithButton' }
    & Pick<BlogSectionBannerWithButton, 'id' | 'order' | 'sectionType' | 'contentSerialized' | 'title' | 'buttonText' | 'buttonUrl'>
    & { bannerType: BlogSectionBannerWithButton['type'] }
    & { campaign: Maybe<(
      { __typename?: 'Campaign' }
      & Pick<Campaign, 'id' | 'uuid' | 'active' | 'name'>
    )> }
  ) | (
    { __typename?: 'BlogSectionBannerWithCallback' }
    & Pick<BlogSectionBannerWithCallback, 'id' | 'order' | 'sectionType' | 'contentSerialized' | 'title' | 'buttonText'>
    & { campaign: Maybe<(
      { __typename?: 'Campaign' }
      & Pick<Campaign, 'id' | 'uuid' | 'active' | 'name'>
    )> }
  ) | (
    { __typename?: 'BlogSectionInternetSpeed' }
    & Pick<BlogSectionInternetSpeed, 'id' | 'order' | 'title' | 'sectionType' | 'contentSerialized'>
  ) | (
    { __typename?: 'BlogSectionSetriCeleCesko' }
    & Pick<BlogSectionSetriCeleCesko, 'id' | 'order' | 'sectionType' | 'setriCeleCesko'>
  ) | (
    { __typename?: 'BlogSectionTable' }
    & Pick<BlogSectionTable, 'id' | 'order' | 'sectionType' | 'content' | 'rows' | 'columns'>
  ) | (
    { __typename?: 'BlogSectionText' }
    & Pick<BlogSectionText, 'id' | 'order' | 'sectionType' | 'contentSerialized'>
  ) | (
    { __typename?: 'BlogSectionVehicleInsuranceContractDismissalTemplateForm' }
    & Pick<BlogSectionVehicleInsuranceContractDismissalTemplateForm, 'id' | 'order' | 'sectionType' | 'title' | 'contentSerialized'>
  ) | (
    { __typename?: 'BlogSectionYoutube' }
    & Pick<BlogSectionYoutube, 'id' | 'order' | 'sectionType' | 'url'>
  )> }
);

export type BlogSectionBannerWithButtonFragment = (
  { __typename?: 'BlogSectionBannerWithButton' }
  & Pick<BlogSectionBannerWithButton, 'id' | 'order' | 'sectionType' | 'contentSerialized' | 'title' | 'buttonText' | 'buttonUrl'>
  & { bannerType: BlogSectionBannerWithButton['type'] }
  & { campaign: Maybe<(
    { __typename?: 'Campaign' }
    & Pick<Campaign, 'id' | 'uuid' | 'active' | 'name'>
  )> }
);

export type BlogSectionBannerWithCallbackFragment = (
  { __typename?: 'BlogSectionBannerWithCallback' }
  & Pick<BlogSectionBannerWithCallback, 'id' | 'order' | 'sectionType' | 'contentSerialized' | 'title' | 'buttonText'>
  & { campaign: Maybe<(
    { __typename?: 'Campaign' }
    & Pick<Campaign, 'id' | 'uuid' | 'active' | 'name'>
  )> }
);

export type BlogSectionTextFragment = (
  { __typename?: 'BlogSectionText' }
  & Pick<BlogSectionText, 'id' | 'order' | 'sectionType' | 'contentSerialized'>
);

export type BlogSectionInternetSpeedFragment = (
  { __typename?: 'BlogSectionInternetSpeed' }
  & Pick<BlogSectionInternetSpeed, 'id' | 'order' | 'title' | 'sectionType' | 'contentSerialized'>
);

export type BlogSectionSetriCeleCeskoFragment = (
  { __typename?: 'BlogSectionSetriCeleCesko' }
  & Pick<BlogSectionSetriCeleCesko, 'id' | 'order' | 'sectionType' | 'setriCeleCesko'>
);

export type BlogSectionYoutubeFragment = (
  { __typename?: 'BlogSectionYoutube' }
  & Pick<BlogSectionYoutube, 'id' | 'order' | 'sectionType' | 'url'>
);

export type BlogSectionVehicleInsuranceContractDismissalTemplateFormFragment = (
  { __typename?: 'BlogSectionVehicleInsuranceContractDismissalTemplateForm' }
  & Pick<BlogSectionVehicleInsuranceContractDismissalTemplateForm, 'id' | 'order' | 'sectionType' | 'title' | 'contentSerialized'>
);

export type BlogSectionTableFragment = (
  { __typename?: 'BlogSectionTable' }
  & Pick<BlogSectionTable, 'id' | 'order' | 'sectionType' | 'content' | 'rows' | 'columns'>
);

type BlogSection_BlogSectionBannerWithButton_Fragment = (
  { __typename?: 'BlogSectionBannerWithButton' }
  & Pick<BlogSectionBannerWithButton, 'id' | 'order' | 'sectionType' | 'contentSerialized' | 'title' | 'buttonText' | 'buttonUrl'>
  & { bannerType: BlogSectionBannerWithButton['type'] }
  & { campaign: Maybe<(
    { __typename?: 'Campaign' }
    & Pick<Campaign, 'id' | 'uuid' | 'active' | 'name'>
  )> }
);

type BlogSection_BlogSectionBannerWithCallback_Fragment = (
  { __typename?: 'BlogSectionBannerWithCallback' }
  & Pick<BlogSectionBannerWithCallback, 'id' | 'order' | 'sectionType' | 'contentSerialized' | 'title' | 'buttonText'>
  & { campaign: Maybe<(
    { __typename?: 'Campaign' }
    & Pick<Campaign, 'id' | 'uuid' | 'active' | 'name'>
  )> }
);

type BlogSection_BlogSectionInternetSpeed_Fragment = (
  { __typename?: 'BlogSectionInternetSpeed' }
  & Pick<BlogSectionInternetSpeed, 'id' | 'order' | 'title' | 'sectionType' | 'contentSerialized'>
);

type BlogSection_BlogSectionSetriCeleCesko_Fragment = (
  { __typename?: 'BlogSectionSetriCeleCesko' }
  & Pick<BlogSectionSetriCeleCesko, 'id' | 'order' | 'sectionType' | 'setriCeleCesko'>
);

type BlogSection_BlogSectionTable_Fragment = (
  { __typename?: 'BlogSectionTable' }
  & Pick<BlogSectionTable, 'id' | 'order' | 'sectionType' | 'content' | 'rows' | 'columns'>
);

type BlogSection_BlogSectionText_Fragment = (
  { __typename?: 'BlogSectionText' }
  & Pick<BlogSectionText, 'id' | 'order' | 'sectionType' | 'contentSerialized'>
);

type BlogSection_BlogSectionVehicleInsuranceContractDismissalTemplateForm_Fragment = (
  { __typename?: 'BlogSectionVehicleInsuranceContractDismissalTemplateForm' }
  & Pick<BlogSectionVehicleInsuranceContractDismissalTemplateForm, 'id' | 'order' | 'sectionType' | 'title' | 'contentSerialized'>
);

type BlogSection_BlogSectionYoutube_Fragment = (
  { __typename?: 'BlogSectionYoutube' }
  & Pick<BlogSectionYoutube, 'id' | 'order' | 'sectionType' | 'url'>
);

export type BlogSectionFragment = BlogSection_BlogSectionBannerWithButton_Fragment | BlogSection_BlogSectionBannerWithCallback_Fragment | BlogSection_BlogSectionInternetSpeed_Fragment | BlogSection_BlogSectionSetriCeleCesko_Fragment | BlogSection_BlogSectionTable_Fragment | BlogSection_BlogSectionText_Fragment | BlogSection_BlogSectionVehicleInsuranceContractDismissalTemplateForm_Fragment | BlogSection_BlogSectionYoutube_Fragment;

export type SearchResultFragment = (
  { __typename?: 'SearchResult' }
  & Pick<SearchResult, 'contentResultsCount' | 'titleResultsCount' | 'mixedUniqueResultsCount'>
  & { contentResults: Maybe<Array<(
    { __typename?: 'ArticlePreview' }
    & Pick<ArticlePreview, 'id' | 'title' | 'perex' | 'seoName' | 'imageAlt' | 'imageSource' | 'author' | 'publishedAt' | 'updatedAt' | 'redirectTo'>
    & { image: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    )> }
  )>>, titleResults: Maybe<Array<(
    { __typename?: 'ArticlePreview' }
    & Pick<ArticlePreview, 'id' | 'title' | 'perex' | 'seoName' | 'imageAlt' | 'imageSource' | 'author' | 'publishedAt' | 'updatedAt' | 'redirectTo'>
    & { image: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    )> }
  )>>, mixedUniqueResults: Maybe<Array<(
    { __typename?: 'ArticlePreview' }
    & Pick<ArticlePreview, 'id' | 'title' | 'perex' | 'seoName' | 'imageAlt' | 'imageSource' | 'author' | 'publishedAt' | 'updatedAt' | 'redirectTo'>
    & { image: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    )> }
  )>> }
);

export type BlogSearchArticlesQueryVariables = Exact<{
  searchTerm: Scalars['String']['input'];
}>;


export type BlogSearchArticlesQuery = (
  { __typename?: 'Query' }
  & { blogSearchArticles: (
    { __typename?: 'SearchResult' }
    & Pick<SearchResult, 'contentResultsCount' | 'titleResultsCount' | 'mixedUniqueResultsCount'>
    & { contentResults: Maybe<Array<(
      { __typename?: 'ArticlePreview' }
      & Pick<ArticlePreview, 'id' | 'title' | 'perex' | 'seoName' | 'imageAlt' | 'imageSource' | 'author' | 'publishedAt' | 'updatedAt' | 'redirectTo'>
      & { image: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )> }
    )>>, titleResults: Maybe<Array<(
      { __typename?: 'ArticlePreview' }
      & Pick<ArticlePreview, 'id' | 'title' | 'perex' | 'seoName' | 'imageAlt' | 'imageSource' | 'author' | 'publishedAt' | 'updatedAt' | 'redirectTo'>
      & { image: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )> }
    )>>, mixedUniqueResults: Maybe<Array<(
      { __typename?: 'ArticlePreview' }
      & Pick<ArticlePreview, 'id' | 'title' | 'perex' | 'seoName' | 'imageAlt' | 'imageSource' | 'author' | 'publishedAt' | 'updatedAt' | 'redirectTo'>
      & { image: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )> }
    )>> }
  ) }
);

export type GetBlogCategoryQueryVariables = Exact<{
  categoryId: Scalars['Int']['input'];
  offset: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetBlogCategoryQuery = (
  { __typename?: 'Query' }
  & { blogCategory: (
    { __typename?: 'ArticleCategory' }
    & Pick<ArticleCategory, 'id' | 'name' | 'titleSuffix' | 'vertical' | 'subVertical' | 'metaTitle' | 'metaDescription'>
    & { articles: Maybe<Array<(
      { __typename?: 'ArticlePreview' }
      & Pick<ArticlePreview, 'id' | 'title' | 'perex' | 'seoName' | 'imageAlt' | 'imageSource' | 'author' | 'publishedAt' | 'updatedAt' | 'redirectTo'>
      & { image: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )> }
    )>>, metaImage: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    )> }
  ) }
);

export type GetBlogArticleQueryVariables = Exact<{
  articleId: Scalars['Int']['input'];
}>;


export type GetBlogArticleQuery = (
  { __typename?: 'Query' }
  & { blogArticle: (
    { __typename?: 'Article' }
    & Pick<Article, 'id' | 'title' | 'titleSuffix' | 'metaTitle' | 'perex' | 'seoName' | 'metaDescription' | 'imageAlt' | 'imageSource' | 'author' | 'publishedAt' | 'vertical' | 'subVertical' | 'knowledgeBaseConceptTags' | 'knowledgeBaseQuestionTags' | 'tags' | 'redirectTo'>
    & { category: (
      { __typename?: 'ArticleCategoryListItem' }
      & Pick<ArticleCategoryListItem, 'id' | 'name' | 'vertical' | 'subVertical'>
    ), image: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    )>, sections: Array<(
      { __typename?: 'BlogSectionBannerWithButton' }
      & Pick<BlogSectionBannerWithButton, 'id' | 'order' | 'sectionType' | 'contentSerialized' | 'title' | 'buttonText' | 'buttonUrl'>
      & { bannerType: BlogSectionBannerWithButton['type'] }
      & { campaign: Maybe<(
        { __typename?: 'Campaign' }
        & Pick<Campaign, 'id' | 'uuid' | 'active' | 'name'>
      )> }
    ) | (
      { __typename?: 'BlogSectionBannerWithCallback' }
      & Pick<BlogSectionBannerWithCallback, 'id' | 'order' | 'sectionType' | 'contentSerialized' | 'title' | 'buttonText'>
      & { campaign: Maybe<(
        { __typename?: 'Campaign' }
        & Pick<Campaign, 'id' | 'uuid' | 'active' | 'name'>
      )> }
    ) | (
      { __typename?: 'BlogSectionInternetSpeed' }
      & Pick<BlogSectionInternetSpeed, 'id' | 'order' | 'title' | 'sectionType' | 'contentSerialized'>
    ) | (
      { __typename?: 'BlogSectionSetriCeleCesko' }
      & Pick<BlogSectionSetriCeleCesko, 'id' | 'order' | 'sectionType' | 'setriCeleCesko'>
    ) | (
      { __typename?: 'BlogSectionTable' }
      & Pick<BlogSectionTable, 'id' | 'order' | 'sectionType' | 'content' | 'rows' | 'columns'>
    ) | (
      { __typename?: 'BlogSectionText' }
      & Pick<BlogSectionText, 'id' | 'order' | 'sectionType' | 'contentSerialized'>
    ) | (
      { __typename?: 'BlogSectionVehicleInsuranceContractDismissalTemplateForm' }
      & Pick<BlogSectionVehicleInsuranceContractDismissalTemplateForm, 'id' | 'order' | 'sectionType' | 'title' | 'contentSerialized'>
    ) | (
      { __typename?: 'BlogSectionYoutube' }
      & Pick<BlogSectionYoutube, 'id' | 'order' | 'sectionType' | 'url'>
    )> }
  ) }
);

export type GetBlogArticlesQueryVariables = Exact<{
  offset: InputMaybe<Scalars['Int']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetBlogArticlesQuery = (
  { __typename?: 'Query' }
  & { blogArticles: Array<(
    { __typename?: 'ArticlePreview' }
    & Pick<ArticlePreview, 'id' | 'title' | 'perex' | 'seoName' | 'imageAlt' | 'imageSource' | 'author' | 'publishedAt' | 'updatedAt' | 'redirectTo'>
    & { image: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    )> }
  )> }
);

export type BlogArticlesByTagsQueryVariables = Exact<{
  tagIds: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
  excludedArticles: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
}>;


export type BlogArticlesByTagsQuery = (
  { __typename?: 'Query' }
  & { blogArticles: Array<(
    { __typename?: 'ArticlePreview' }
    & Pick<ArticlePreview, 'id' | 'title' | 'perex' | 'seoName' | 'imageAlt' | 'imageSource' | 'author' | 'publishedAt' | 'updatedAt' | 'redirectTo'>
    & { image: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    )> }
  )> }
);

export type BlogMostReadArticlesQueryVariables = Exact<{
  categoryId: InputMaybe<Scalars['Int']['input']>;
}>;


export type BlogMostReadArticlesQuery = (
  { __typename?: 'Query' }
  & { blogMostReadArticles: Array<(
    { __typename?: 'ArticlePreview' }
    & Pick<ArticlePreview, 'id' | 'title' | 'perex' | 'seoName' | 'imageAlt' | 'imageSource' | 'author' | 'publishedAt' | 'updatedAt' | 'redirectTo'>
    & { image: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    )> }
  )> }
);

export type BlogHomepageQueryVariables = Exact<{ [key: string]: never; }>;


export type BlogHomepageQuery = (
  { __typename?: 'Query' }
  & { all: Array<(
    { __typename?: 'ArticlePreview' }
    & Pick<ArticlePreview, 'id' | 'title' | 'perex' | 'seoName' | 'imageAlt' | 'imageSource' | 'author' | 'publishedAt' | 'updatedAt' | 'redirectTo'>
    & { image: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    )> }
  )>, insurance: (
    { __typename?: 'ArticleCategory' }
    & Pick<ArticleCategory, 'id' | 'name' | 'titleSuffix' | 'vertical' | 'subVertical' | 'metaTitle' | 'metaDescription'>
    & { articles: Maybe<Array<(
      { __typename?: 'ArticlePreview' }
      & Pick<ArticlePreview, 'id' | 'title' | 'perex' | 'seoName' | 'imageAlt' | 'imageSource' | 'author' | 'publishedAt' | 'updatedAt' | 'redirectTo'>
      & { image: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )> }
    )>>, metaImage: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    )> }
  ), energy: (
    { __typename?: 'ArticleCategory' }
    & Pick<ArticleCategory, 'id' | 'name' | 'titleSuffix' | 'vertical' | 'subVertical' | 'metaTitle' | 'metaDescription'>
    & { articles: Maybe<Array<(
      { __typename?: 'ArticlePreview' }
      & Pick<ArticlePreview, 'id' | 'title' | 'perex' | 'seoName' | 'imageAlt' | 'imageSource' | 'author' | 'publishedAt' | 'updatedAt' | 'redirectTo'>
      & { image: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )> }
    )>>, metaImage: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    )> }
  ), travelInsurance: (
    { __typename?: 'ArticleCategory' }
    & Pick<ArticleCategory, 'id' | 'name' | 'titleSuffix' | 'vertical' | 'subVertical' | 'metaTitle' | 'metaDescription'>
    & { articles: Maybe<Array<(
      { __typename?: 'ArticlePreview' }
      & Pick<ArticlePreview, 'id' | 'title' | 'perex' | 'seoName' | 'imageAlt' | 'imageSource' | 'author' | 'publishedAt' | 'updatedAt' | 'redirectTo'>
      & { image: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )> }
    )>>, metaImage: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    )> }
  ), finance: (
    { __typename?: 'ArticleCategory' }
    & Pick<ArticleCategory, 'id' | 'name' | 'titleSuffix' | 'vertical' | 'subVertical' | 'metaTitle' | 'metaDescription'>
    & { articles: Maybe<Array<(
      { __typename?: 'ArticlePreview' }
      & Pick<ArticlePreview, 'id' | 'title' | 'perex' | 'seoName' | 'imageAlt' | 'imageSource' | 'author' | 'publishedAt' | 'updatedAt' | 'redirectTo'>
      & { image: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )> }
    )>>, metaImage: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    )> }
  ), law: (
    { __typename?: 'ArticleCategory' }
    & Pick<ArticleCategory, 'id' | 'name' | 'titleSuffix' | 'vertical' | 'subVertical' | 'metaTitle' | 'metaDescription'>
    & { articles: Maybe<Array<(
      { __typename?: 'ArticlePreview' }
      & Pick<ArticlePreview, 'id' | 'title' | 'perex' | 'seoName' | 'imageAlt' | 'imageSource' | 'author' | 'publishedAt' | 'updatedAt' | 'redirectTo'>
      & { image: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )> }
    )>>, metaImage: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    )> }
  ) }
);

export type GetBlogTagQueryVariables = Exact<{
  tagId: Scalars['Int']['input'];
}>;


export type GetBlogTagQuery = (
  { __typename?: 'Query' }
  & { blogTag: (
    { __typename?: 'ArticleTag' }
    & Pick<ArticleTag, 'tagId' | 'tagName' | 'tagSeoName'>
    & { articles: Maybe<Array<(
      { __typename?: 'ArticlePreview' }
      & Pick<ArticlePreview, 'id' | 'title' | 'perex' | 'seoName' | 'imageAlt' | 'imageSource' | 'author' | 'publishedAt' | 'updatedAt' | 'redirectTo'>
      & { image: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )> }
    )>> }
  ) }
);

export type CallbackCreateCallbackMutationVariables = Exact<{
  data: CreateCallbackInput;
}>;


export type CallbackCreateCallbackMutation = (
  { __typename?: 'Mutation' }
  & { callbackCreateCallback: (
    { __typename?: 'CallbackZoneCallback' }
    & Pick<CallbackZoneCallback, 'id'>
  ) }
);

export type CallbackSendEmailMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type CallbackSendEmailMutation = (
  { __typename?: 'Mutation' }
  & { callbackSendEmail: (
    { __typename?: 'Callback' }
    & Pick<Callback, 'id'>
  ) }
);

export type CampaignFragment = (
  { __typename?: 'Campaign' }
  & Pick<Campaign, 'id' | 'uuid' | 'active' | 'name'>
);

export type CampaignActivateMutationVariables = Exact<{
  campaignUuid: Scalars['String']['input'];
}>;


export type CampaignActivateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'campaignActivate'>
);

export type FileFragment = (
  { __typename?: 'File' }
  & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
);

export type ImageFragment = (
  { __typename?: 'Image' }
  & Pick<Image, 'id' | 'name' | 'uuid' | 'type' | 'createdAt' | 'extension' | 'height' | 'namespace' | 'size' | 'width'>
);

export type CodebookFragment = (
  { __typename?: 'Codebook' }
  & Pick<Codebook, 'id' | 'label'>
);

export type MoneyFragment = (
  { __typename?: 'Money' }
  & Pick<Money, 'currency' | 'amount'>
);

export type AddressFragment = (
  { __typename?: 'Address' }
  & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
  & { extended: Maybe<(
    { __typename?: 'SmartAddressExtendedInfo' }
    & Pick<SmartAddressExtendedInfo, 'id'>
    & { objectType: Maybe<(
      { __typename?: 'SmartAddressObjectType' }
      & Pick<SmartAddressObjectType, 'id' | 'nazev'>
    )> }
  )>, extendedV2: Maybe<(
    { __typename?: 'SmartAddressSdkExtendedInfo' }
    & { administrativniUdaje: Maybe<(
      { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
      & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
      & { objectType: Maybe<(
        { __typename?: 'SmartAddressObjectType' }
        & Pick<SmartAddressObjectType, 'id' | 'nazev'>
      )> }
    )> }
  )> }
);

export type CustomAddressFragment = (
  { __typename?: 'CustomAddress' }
  & Pick<CustomAddress, 'address' | 'zipCode' | 'city'>
);

export type SitemapQueryVariables = Exact<{ [key: string]: never; }>;


export type SitemapQuery = (
  { __typename?: 'Query' }
  & { blogArticles: Array<(
    { __typename?: 'ArticlePreview' }
    & Pick<ArticlePreview, 'id' | 'title' | 'seoName' | 'publishedAt' | 'updatedAt' | 'redirectTo'>
    & { category: (
      { __typename?: 'ArticleCategoryListItem' }
      & Pick<ArticleCategoryListItem, 'id' | 'name'>
    ) }
  )>, blogCategories: Array<(
    { __typename?: 'ArticleCategoryListItem' }
    & Pick<ArticleCategoryListItem, 'id' | 'name'>
  )>, energoCodebooks: (
    { __typename?: 'EnergoCodebooks' }
    & { energoPartner: Array<(
      { __typename?: 'EnergoPartner' }
      & Pick<EnergoPartner, 'id' | 'name' | 'seoName' | 'createdAt' | 'updatedAt'>
      & { partnerElectro: Maybe<(
        { __typename?: 'EnergoElectricityPartnerDetail' }
        & Pick<EnergoElectricityPartnerDetail, 'id' | 'seoName' | 'isActive' | 'createdAt' | 'updatedAt'>
      )>, partnerGas: Maybe<(
        { __typename?: 'EnergoGasPartnerDetail' }
        & Pick<EnergoGasPartnerDetail, 'id' | 'seoName' | 'isActive' | 'createdAt' | 'updatedAt'>
      )> }
    )>, partner: Array<(
      { __typename?: 'EnergoElectricityPartnerCodebook' }
      & Pick<EnergoElectricityPartnerCodebook, 'id' | 'seoName' | 'name' | 'isActive' | 'createdAt' | 'updatedAt'>
    )>, gasPartner: Array<(
      { __typename?: 'EnergoGasPartnerCodebook' }
      & Pick<EnergoGasPartnerCodebook, 'id' | 'seoName' | 'name' | 'isActive' | 'createdAt' | 'updatedAt'>
    )> }
  ), energoGetAllElectricityProducts: Array<(
    { __typename?: 'EnergoKalkulatorEnergoElectricityProduct' }
    & Pick<EnergoKalkulatorEnergoElectricityProduct, 'id' | 'name' | 'isActive' | 'createdAt' | 'updatedAt'>
    & { partner: Maybe<(
      { __typename?: 'EnergoElectricityPartner' }
      & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'isActive'>
    )> }
  )>, energoGetAllGasProducts: Array<(
    { __typename?: 'EnergoKalkulatorEnergoGasProduct' }
    & Pick<EnergoKalkulatorEnergoGasProduct, 'id' | 'name' | 'isActive' | 'createdAt' | 'updatedAt'>
    & { partner: Maybe<(
      { __typename?: 'EnergoGasPartner' }
      & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'isActive'>
    )> }
  )>, vehicleInsurancePartnerGetList: Array<(
    { __typename?: 'ZeteoInsurancePartner' }
    & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'createdAt' | 'updatedAt'>
  )>, vehicleInsuranceProductGetList: Array<(
    { __typename?: 'VehicleInsuranceProduct' }
    & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'createdAt' | 'updatedAt'>
    & { partner: (
      { __typename?: 'ZeteoInsurancePartner' }
      & Pick<ZeteoInsurancePartner, 'id' | 'name'>
    ) }
  )>, getKnowledgebaseSitemapCategories: Array<(
    { __typename?: 'KnowledgebaseCategoryPreview' }
    & Pick<KnowledgebaseCategoryPreview, 'id' | 'name'>
  )>, getKnowledgebaseSitemapQuestions: Array<(
    { __typename?: 'KnowledgebaseQuestionPreview' }
    & Pick<KnowledgebaseQuestionPreview, 'id' | 'seoName' | 'updatedAt' | 'publishedAt' | 'title'>
    & { category: Maybe<(
      { __typename?: 'KnowledgebaseCategoryPreview' }
      & Pick<KnowledgebaseCategoryPreview, 'id'>
    )> }
  )>, landingPages: Array<(
    { __typename?: 'LandingPageSiteMapItem' }
    & Pick<LandingPageSiteMapItem, 'id' | 'uuid' | 'name' | 'seoName' | 'dedicatedUrl' | 'updatedAt'>
  )>, financeProducts: Array<(
    { __typename?: 'FinanceProduct' }
    & Pick<FinanceProduct, 'active' | 'id' | 'name' | 'updatedAt'>
    & { partner: (
      { __typename?: 'FinancePartner' }
      & Pick<FinancePartner, 'id' | 'name' | 'active'>
    ) }
  )>, financePartners: Array<(
    { __typename?: 'FinancePartner' }
    & Pick<FinancePartner, 'active' | 'id' | 'name' | 'updatedAt'>
  )> }
);

export type EnergoEnergoContractAttachmentFragment = (
  { __typename?: 'EnergoEnergoContractAttachment' }
  & Pick<EnergoEnergoContractAttachment, 'id' | 'type' | 'createdAt'>
  & { file: (
    { __typename?: 'File' }
    & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
  ) }
);

export type EnergoEnergoContractFragment = (
  { __typename?: 'EnergoEnergoContract' }
  & Pick<EnergoEnergoContract, 'id' | 'name' | 'surname' | 'phone' | 'email' | 'customerNote' | 'status' | 'preferredPaymentMethod' | 'sendInvoiceMethod' | 'invoiceUploaded' | 'supplyStartDate' | 'expectedSupplyStartDate' | 'methodOfSendingContract'>
  & { supplyAddress: Maybe<(
    { __typename?: 'Address' }
    & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
    & { extended: Maybe<(
      { __typename?: 'SmartAddressExtendedInfo' }
      & Pick<SmartAddressExtendedInfo, 'id'>
      & { objectType: Maybe<(
        { __typename?: 'SmartAddressObjectType' }
        & Pick<SmartAddressObjectType, 'id' | 'nazev'>
      )> }
    )>, extendedV2: Maybe<(
      { __typename?: 'SmartAddressSdkExtendedInfo' }
      & { administrativniUdaje: Maybe<(
        { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
        & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
        & { objectType: Maybe<(
          { __typename?: 'SmartAddressObjectType' }
          & Pick<SmartAddressObjectType, 'id' | 'nazev'>
        )> }
      )> }
    )> }
  )>, contactAddress: Maybe<(
    { __typename?: 'Address' }
    & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
    & { extended: Maybe<(
      { __typename?: 'SmartAddressExtendedInfo' }
      & Pick<SmartAddressExtendedInfo, 'id'>
      & { objectType: Maybe<(
        { __typename?: 'SmartAddressObjectType' }
        & Pick<SmartAddressObjectType, 'id' | 'nazev'>
      )> }
    )>, extendedV2: Maybe<(
      { __typename?: 'SmartAddressSdkExtendedInfo' }
      & { administrativniUdaje: Maybe<(
        { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
        & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
        & { objectType: Maybe<(
          { __typename?: 'SmartAddressObjectType' }
          & Pick<SmartAddressObjectType, 'id' | 'nazev'>
        )> }
      )> }
    )> }
  )>, permanentAddress: Maybe<(
    { __typename?: 'Address' }
    & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
    & { extended: Maybe<(
      { __typename?: 'SmartAddressExtendedInfo' }
      & Pick<SmartAddressExtendedInfo, 'id'>
      & { objectType: Maybe<(
        { __typename?: 'SmartAddressObjectType' }
        & Pick<SmartAddressObjectType, 'id' | 'nazev'>
      )> }
    )>, extendedV2: Maybe<(
      { __typename?: 'SmartAddressSdkExtendedInfo' }
      & { administrativniUdaje: Maybe<(
        { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
        & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
        & { objectType: Maybe<(
          { __typename?: 'SmartAddressObjectType' }
          & Pick<SmartAddressObjectType, 'id' | 'nazev'>
        )> }
      )> }
    )> }
  )>, customSupplyAddress: Maybe<(
    { __typename?: 'CustomAddress' }
    & Pick<CustomAddress, 'address' | 'zipCode' | 'city'>
  )>, customContactAddress: Maybe<(
    { __typename?: 'CustomAddress' }
    & Pick<CustomAddress, 'address' | 'zipCode' | 'city'>
  )>, customPermanentAddress: Maybe<(
    { __typename?: 'CustomAddress' }
    & Pick<CustomAddress, 'address' | 'zipCode' | 'city'>
  )>, attachments: Array<(
    { __typename?: 'EnergoEnergoContractAttachment' }
    & Pick<EnergoEnergoContractAttachment, 'id' | 'type' | 'createdAt'>
    & { file: (
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    ) }
  )>, energoOffer: Maybe<(
    { __typename?: 'EnergoOffer' }
    & Pick<EnergoOffer, 'id' | 'status' | 'validTo' | 'acceptedAt'>
    & { benefitResponse: Maybe<(
      { __typename?: 'Benefit' }
      & Pick<Benefit, 'id' | 'uuid' | 'name' | 'validFrom' | 'validTo' | 'template' | 'perex' | 'text' | 'buttonText'>
      & { profileBenefit: (
        { __typename?: 'ProfileBenefit' }
        & Pick<ProfileBenefit, 'id' | 'name' | 'surname' | 'status' | 'phone'>
        & { attachments: Array<(
          { __typename?: 'BenefitAttachment' }
          & Pick<BenefitAttachment, 'id'>
          & { file: (
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          ) }
        )> }
      ), image: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )> }
    )>, electricityResultItem: Maybe<(
      { __typename?: 'EnergoElectricityResultItem' }
      & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoElectricityPartner' }
        & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoElectricityProduct' }
        & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    )>, gasResultItem: Maybe<(
      { __typename?: 'EnergoGasResultItem' }
      & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoGasPartner' }
        & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoGasProduct' }
        & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    )>, acceptedBy: Maybe<(
      { __typename?: 'ProfileUser' }
      & Pick<ProfileUser, 'email' | 'name'>
    )> }
  )> }
);

export type CustomerZoneCalculationDetailFragment = (
  { __typename?: 'CustomerZoneCalculationDetail' }
  & Pick<CustomerZoneCalculationDetail, 'uuid' | 'type' | 'source' | 'yearlyConsumption' | 'yearlyConsumptionUnit' | 'yearlyConsumptionLowTariff' | 'yearlyConsumptionLowTariffUnit' | 'yearlyPayment' | 'gasYearlyPayment' | 'gasYearlyConsumption' | 'gasYearlyConsumptionUnit' | 'gasMonthlyAdvances' | 'gasUnknownMonthlyAdvances' | 'monthlyAdvances' | 'unknownMonthlyAdvances' | 'email' | 'saving' | 'createdAt' | 'updatedAt' | 'lastViewed' | 'currentProductId' | 'currentProductName' | 'currentProductFollowingId' | 'unknownCurrentProduct' | 'gasCurrentProductId' | 'gasCurrentProductName' | 'gasCurrentProductFollowingId' | 'gasUnknownCurrentProduct' | 'adminLastChangeAt'>
  & { address: Maybe<(
    { __typename?: 'Address' }
    & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
    & { extended: Maybe<(
      { __typename?: 'SmartAddressExtendedInfo' }
      & Pick<SmartAddressExtendedInfo, 'id'>
      & { objectType: Maybe<(
        { __typename?: 'SmartAddressObjectType' }
        & Pick<SmartAddressObjectType, 'id' | 'nazev'>
      )> }
    )>, extendedV2: Maybe<(
      { __typename?: 'SmartAddressSdkExtendedInfo' }
      & { administrativniUdaje: Maybe<(
        { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
        & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
        & { objectType: Maybe<(
          { __typename?: 'SmartAddressObjectType' }
          & Pick<SmartAddressObjectType, 'id' | 'nazev'>
        )> }
      )> }
    )> }
  )>, partner: Maybe<(
    { __typename?: 'EnergoElectricityPartner' }
    & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
    & { rating: Maybe<(
      { __typename?: 'NewRating' }
      & Pick<NewRating, 'id' | 'total'>
      & { categories: Array<Maybe<(
        { __typename?: 'RatingCategory' }
        & Pick<RatingCategory, 'id' | 'label' | 'value'>
      )>> }
    )> }
  )>, gasPartner: Maybe<(
    { __typename?: 'EnergoGasPartner' }
    & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
    & { rating: Maybe<(
      { __typename?: 'NewRating' }
      & Pick<NewRating, 'id' | 'total'>
      & { categories: Array<Maybe<(
        { __typename?: 'RatingCategory' }
        & Pick<RatingCategory, 'id' | 'label' | 'value'>
      )>> }
    )> }
  )>, gasUsages: Maybe<(
    { __typename?: 'GasUsages' }
    & Pick<GasUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
  )>, electricityUsages: Maybe<(
    { __typename?: 'ElectricityUsages' }
    & Pick<ElectricityUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
  )>, floorAreaRange: Maybe<(
    { __typename?: 'EnergoFloorAreaRangeCodebook' }
    & Pick<EnergoFloorAreaRangeCodebook, 'id'>
    & { label: EnergoFloorAreaRangeCodebook['name'] }
  )>, isolation: Maybe<(
    { __typename?: 'EnergoIsolationCodebook' }
    & Pick<EnergoIsolationCodebook, 'id'>
    & { label: EnergoIsolationCodebook['name'] }
  )>, personsCountRange: Maybe<(
    { __typename?: 'EnergoPersonsCountRangeCodebook' }
    & Pick<EnergoPersonsCountRangeCodebook, 'id'>
    & { label: EnergoPersonsCountRangeCodebook['name'] }
  )>, distributionRate: Maybe<(
    { __typename?: 'EnergoDistributionRate' }
    & Pick<EnergoDistributionRate, 'id' | 'name' | 'lowTariff'>
  )>, breaker: Maybe<(
    { __typename?: 'EnergoBreaker' }
    & Pick<EnergoBreaker, 'id' | 'name'>
  )>, standaloneInvoice: Maybe<(
    { __typename?: 'EnergoStandaloneInvoice' }
    & Pick<EnergoStandaloneInvoice, 'id' | 'uuid'>
  )>, bestResultItem: Maybe<(
    { __typename?: 'EnergoElectricityResultItem' }
    & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
    & { partner: (
      { __typename?: 'EnergoElectricityPartner' }
      & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    ), product: (
      { __typename?: 'EnergoElectricityProduct' }
      & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
    ), priceExpectedDetail: (
      { __typename?: 'EnergoElectricityResultPriceDetail' }
      & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
    ), priceResultDetail: (
      { __typename?: 'EnergoElectricityResultPriceDetail' }
      & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
    ) }
  )>, bestGasResultItem: Maybe<(
    { __typename?: 'EnergoGasResultItem' }
    & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
    & { partner: (
      { __typename?: 'EnergoGasPartner' }
      & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    ), product: (
      { __typename?: 'EnergoGasProduct' }
      & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
    ), priceExpectedDetail: (
      { __typename?: 'EnergoGasResultPriceDetail' }
      & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
    ), priceResultDetail: (
      { __typename?: 'EnergoGasResultPriceDetail' }
      & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
    ) }
  )>, energoOffers: Array<(
    { __typename?: 'EnergoOffer' }
    & Pick<EnergoOffer, 'id' | 'status' | 'validTo' | 'acceptedAt'>
    & { benefitResponse: Maybe<(
      { __typename?: 'Benefit' }
      & Pick<Benefit, 'id' | 'uuid' | 'name' | 'validFrom' | 'validTo' | 'template' | 'perex' | 'text' | 'buttonText'>
      & { profileBenefit: (
        { __typename?: 'ProfileBenefit' }
        & Pick<ProfileBenefit, 'id' | 'name' | 'surname' | 'status' | 'phone'>
        & { attachments: Array<(
          { __typename?: 'BenefitAttachment' }
          & Pick<BenefitAttachment, 'id'>
          & { file: (
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          ) }
        )> }
      ), image: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )> }
    )>, electricityResultItem: Maybe<(
      { __typename?: 'EnergoElectricityResultItem' }
      & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoElectricityPartner' }
        & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoElectricityProduct' }
        & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    )>, gasResultItem: Maybe<(
      { __typename?: 'EnergoGasResultItem' }
      & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoGasPartner' }
        & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoGasProduct' }
        & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    )>, acceptedBy: Maybe<(
      { __typename?: 'ProfileUser' }
      & Pick<ProfileUser, 'email' | 'name'>
    )> }
  )>, energoContract: (
    { __typename?: 'EnergoEnergoContract' }
    & Pick<EnergoEnergoContract, 'id' | 'name' | 'surname' | 'phone' | 'email' | 'customerNote' | 'status' | 'preferredPaymentMethod' | 'sendInvoiceMethod' | 'invoiceUploaded' | 'supplyStartDate' | 'expectedSupplyStartDate' | 'methodOfSendingContract'>
    & { supplyAddress: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
      & { extended: Maybe<(
        { __typename?: 'SmartAddressExtendedInfo' }
        & Pick<SmartAddressExtendedInfo, 'id'>
        & { objectType: Maybe<(
          { __typename?: 'SmartAddressObjectType' }
          & Pick<SmartAddressObjectType, 'id' | 'nazev'>
        )> }
      )>, extendedV2: Maybe<(
        { __typename?: 'SmartAddressSdkExtendedInfo' }
        & { administrativniUdaje: Maybe<(
          { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
          & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )> }
      )> }
    )>, contactAddress: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
      & { extended: Maybe<(
        { __typename?: 'SmartAddressExtendedInfo' }
        & Pick<SmartAddressExtendedInfo, 'id'>
        & { objectType: Maybe<(
          { __typename?: 'SmartAddressObjectType' }
          & Pick<SmartAddressObjectType, 'id' | 'nazev'>
        )> }
      )>, extendedV2: Maybe<(
        { __typename?: 'SmartAddressSdkExtendedInfo' }
        & { administrativniUdaje: Maybe<(
          { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
          & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )> }
      )> }
    )>, permanentAddress: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
      & { extended: Maybe<(
        { __typename?: 'SmartAddressExtendedInfo' }
        & Pick<SmartAddressExtendedInfo, 'id'>
        & { objectType: Maybe<(
          { __typename?: 'SmartAddressObjectType' }
          & Pick<SmartAddressObjectType, 'id' | 'nazev'>
        )> }
      )>, extendedV2: Maybe<(
        { __typename?: 'SmartAddressSdkExtendedInfo' }
        & { administrativniUdaje: Maybe<(
          { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
          & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )> }
      )> }
    )>, customSupplyAddress: Maybe<(
      { __typename?: 'CustomAddress' }
      & Pick<CustomAddress, 'address' | 'zipCode' | 'city'>
    )>, customContactAddress: Maybe<(
      { __typename?: 'CustomAddress' }
      & Pick<CustomAddress, 'address' | 'zipCode' | 'city'>
    )>, customPermanentAddress: Maybe<(
      { __typename?: 'CustomAddress' }
      & Pick<CustomAddress, 'address' | 'zipCode' | 'city'>
    )>, attachments: Array<(
      { __typename?: 'EnergoEnergoContractAttachment' }
      & Pick<EnergoEnergoContractAttachment, 'id' | 'type' | 'createdAt'>
      & { file: (
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      ) }
    )>, energoOffer: Maybe<(
      { __typename?: 'EnergoOffer' }
      & Pick<EnergoOffer, 'id' | 'status' | 'validTo' | 'acceptedAt'>
      & { benefitResponse: Maybe<(
        { __typename?: 'Benefit' }
        & Pick<Benefit, 'id' | 'uuid' | 'name' | 'validFrom' | 'validTo' | 'template' | 'perex' | 'text' | 'buttonText'>
        & { profileBenefit: (
          { __typename?: 'ProfileBenefit' }
          & Pick<ProfileBenefit, 'id' | 'name' | 'surname' | 'status' | 'phone'>
          & { attachments: Array<(
            { __typename?: 'BenefitAttachment' }
            & Pick<BenefitAttachment, 'id'>
            & { file: (
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            ) }
          )> }
        ), image: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, electricityResultItem: Maybe<(
        { __typename?: 'EnergoElectricityResultItem' }
        & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
        & { partner: (
          { __typename?: 'EnergoElectricityPartner' }
          & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
          & { rating: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), product: (
          { __typename?: 'EnergoElectricityProduct' }
          & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
        ), priceExpectedDetail: (
          { __typename?: 'EnergoElectricityResultPriceDetail' }
          & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ), priceResultDetail: (
          { __typename?: 'EnergoElectricityResultPriceDetail' }
          & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ) }
      )>, gasResultItem: Maybe<(
        { __typename?: 'EnergoGasResultItem' }
        & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
        & { partner: (
          { __typename?: 'EnergoGasPartner' }
          & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
          & { rating: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), product: (
          { __typename?: 'EnergoGasProduct' }
          & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
        ), priceExpectedDetail: (
          { __typename?: 'EnergoGasResultPriceDetail' }
          & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ), priceResultDetail: (
          { __typename?: 'EnergoGasResultPriceDetail' }
          & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ) }
      )>, acceptedBy: Maybe<(
        { __typename?: 'ProfileUser' }
        & Pick<ProfileUser, 'email' | 'name'>
      )> }
    )> }
  ), adminLastChangeBy: Maybe<(
    { __typename?: 'ProfileUser' }
    & Pick<ProfileUser, 'email' | 'name'>
  )>, watchWolf: Maybe<(
    { __typename?: 'WatchWolfPreview' }
    & Pick<WatchWolfPreview, 'uuid'>
  )> }
);

export type EnergoOfferFragment = (
  { __typename?: 'EnergoOffer' }
  & Pick<EnergoOffer, 'id' | 'status' | 'validTo' | 'acceptedAt'>
  & { benefitResponse: Maybe<(
    { __typename?: 'Benefit' }
    & Pick<Benefit, 'id' | 'uuid' | 'name' | 'validFrom' | 'validTo' | 'template' | 'perex' | 'text' | 'buttonText'>
    & { profileBenefit: (
      { __typename?: 'ProfileBenefit' }
      & Pick<ProfileBenefit, 'id' | 'name' | 'surname' | 'status' | 'phone'>
      & { attachments: Array<(
        { __typename?: 'BenefitAttachment' }
        & Pick<BenefitAttachment, 'id'>
        & { file: (
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        ) }
      )> }
    ), image: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    )> }
  )>, electricityResultItem: Maybe<(
    { __typename?: 'EnergoElectricityResultItem' }
    & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
    & { partner: (
      { __typename?: 'EnergoElectricityPartner' }
      & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    ), product: (
      { __typename?: 'EnergoElectricityProduct' }
      & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
    ), priceExpectedDetail: (
      { __typename?: 'EnergoElectricityResultPriceDetail' }
      & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
    ), priceResultDetail: (
      { __typename?: 'EnergoElectricityResultPriceDetail' }
      & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
    ) }
  )>, gasResultItem: Maybe<(
    { __typename?: 'EnergoGasResultItem' }
    & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
    & { partner: (
      { __typename?: 'EnergoGasPartner' }
      & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    ), product: (
      { __typename?: 'EnergoGasProduct' }
      & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
    ), priceExpectedDetail: (
      { __typename?: 'EnergoGasResultPriceDetail' }
      & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
    ), priceResultDetail: (
      { __typename?: 'EnergoGasResultPriceDetail' }
      & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
    ) }
  )>, acceptedBy: Maybe<(
    { __typename?: 'ProfileUser' }
    & Pick<ProfileUser, 'email' | 'name'>
  )> }
);

export type CustomerLeadAddFilesMutationVariables = Exact<{
  leadUuid: Scalars['String']['input'];
  data: LeadAddFilesInput;
}>;


export type CustomerLeadAddFilesMutation = (
  { __typename?: 'Mutation' }
  & { customerLeadAddFiles: (
    { __typename?: 'Lead' }
    & Pick<Lead, 'id' | 'type' | 'uuid' | 'name' | 'surname' | 'email' | 'phone' | 'status' | 'customerNote' | 'lastStatusChange' | 'completedAt' | 'sendEmailImmediately'>
    & { address: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
      & { extended: Maybe<(
        { __typename?: 'SmartAddressExtendedInfo' }
        & Pick<SmartAddressExtendedInfo, 'id'>
        & { objectType: Maybe<(
          { __typename?: 'SmartAddressObjectType' }
          & Pick<SmartAddressObjectType, 'id' | 'nazev'>
        )> }
      )>, extendedV2: Maybe<(
        { __typename?: 'SmartAddressSdkExtendedInfo' }
        & { administrativniUdaje: Maybe<(
          { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
          & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )> }
      )> }
    )>, contactAddress: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
      & { extended: Maybe<(
        { __typename?: 'SmartAddressExtendedInfo' }
        & Pick<SmartAddressExtendedInfo, 'id'>
        & { objectType: Maybe<(
          { __typename?: 'SmartAddressObjectType' }
          & Pick<SmartAddressObjectType, 'id' | 'nazev'>
        )> }
      )>, extendedV2: Maybe<(
        { __typename?: 'SmartAddressSdkExtendedInfo' }
        & { administrativniUdaje: Maybe<(
          { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
          & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )> }
      )> }
    )>, attachments: Array<(
      { __typename?: 'LeadAttachment' }
      & Pick<LeadAttachment, 'id'>
      & { file: (
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      ) }
    )> }
  ) }
);

export type CustomerMyCalculationsQueryVariables = Exact<{ [key: string]: never; }>;


export type CustomerMyCalculationsQuery = (
  { __typename?: 'Query' }
  & { customerMyCalculations: Array<(
    { __typename?: 'CustomerZoneCalculationDetail' }
    & Pick<CustomerZoneCalculationDetail, 'uuid' | 'type' | 'source' | 'yearlyConsumption' | 'yearlyConsumptionUnit' | 'yearlyConsumptionLowTariff' | 'yearlyConsumptionLowTariffUnit' | 'yearlyPayment' | 'gasYearlyPayment' | 'gasYearlyConsumption' | 'gasYearlyConsumptionUnit' | 'gasMonthlyAdvances' | 'gasUnknownMonthlyAdvances' | 'monthlyAdvances' | 'unknownMonthlyAdvances' | 'email' | 'saving' | 'createdAt' | 'updatedAt' | 'lastViewed' | 'currentProductId' | 'currentProductName' | 'currentProductFollowingId' | 'unknownCurrentProduct' | 'gasCurrentProductId' | 'gasCurrentProductName' | 'gasCurrentProductFollowingId' | 'gasUnknownCurrentProduct' | 'adminLastChangeAt'>
    & { address: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
      & { extended: Maybe<(
        { __typename?: 'SmartAddressExtendedInfo' }
        & Pick<SmartAddressExtendedInfo, 'id'>
        & { objectType: Maybe<(
          { __typename?: 'SmartAddressObjectType' }
          & Pick<SmartAddressObjectType, 'id' | 'nazev'>
        )> }
      )>, extendedV2: Maybe<(
        { __typename?: 'SmartAddressSdkExtendedInfo' }
        & { administrativniUdaje: Maybe<(
          { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
          & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )> }
      )> }
    )>, partner: Maybe<(
      { __typename?: 'EnergoElectricityPartner' }
      & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    )>, gasPartner: Maybe<(
      { __typename?: 'EnergoGasPartner' }
      & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    )>, gasUsages: Maybe<(
      { __typename?: 'GasUsages' }
      & Pick<GasUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
    )>, electricityUsages: Maybe<(
      { __typename?: 'ElectricityUsages' }
      & Pick<ElectricityUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
    )>, floorAreaRange: Maybe<(
      { __typename?: 'EnergoFloorAreaRangeCodebook' }
      & Pick<EnergoFloorAreaRangeCodebook, 'id'>
      & { label: EnergoFloorAreaRangeCodebook['name'] }
    )>, isolation: Maybe<(
      { __typename?: 'EnergoIsolationCodebook' }
      & Pick<EnergoIsolationCodebook, 'id'>
      & { label: EnergoIsolationCodebook['name'] }
    )>, personsCountRange: Maybe<(
      { __typename?: 'EnergoPersonsCountRangeCodebook' }
      & Pick<EnergoPersonsCountRangeCodebook, 'id'>
      & { label: EnergoPersonsCountRangeCodebook['name'] }
    )>, distributionRate: Maybe<(
      { __typename?: 'EnergoDistributionRate' }
      & Pick<EnergoDistributionRate, 'id' | 'name' | 'lowTariff'>
    )>, breaker: Maybe<(
      { __typename?: 'EnergoBreaker' }
      & Pick<EnergoBreaker, 'id' | 'name'>
    )>, standaloneInvoice: Maybe<(
      { __typename?: 'EnergoStandaloneInvoice' }
      & Pick<EnergoStandaloneInvoice, 'id' | 'uuid'>
    )>, bestResultItem: Maybe<(
      { __typename?: 'EnergoElectricityResultItem' }
      & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoElectricityPartner' }
        & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoElectricityProduct' }
        & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    )>, bestGasResultItem: Maybe<(
      { __typename?: 'EnergoGasResultItem' }
      & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoGasPartner' }
        & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoGasProduct' }
        & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    )>, energoOffers: Array<(
      { __typename?: 'EnergoOffer' }
      & Pick<EnergoOffer, 'id' | 'status' | 'validTo' | 'acceptedAt'>
      & { benefitResponse: Maybe<(
        { __typename?: 'Benefit' }
        & Pick<Benefit, 'id' | 'uuid' | 'name' | 'validFrom' | 'validTo' | 'template' | 'perex' | 'text' | 'buttonText'>
        & { profileBenefit: (
          { __typename?: 'ProfileBenefit' }
          & Pick<ProfileBenefit, 'id' | 'name' | 'surname' | 'status' | 'phone'>
          & { attachments: Array<(
            { __typename?: 'BenefitAttachment' }
            & Pick<BenefitAttachment, 'id'>
            & { file: (
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            ) }
          )> }
        ), image: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, electricityResultItem: Maybe<(
        { __typename?: 'EnergoElectricityResultItem' }
        & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
        & { partner: (
          { __typename?: 'EnergoElectricityPartner' }
          & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
          & { rating: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), product: (
          { __typename?: 'EnergoElectricityProduct' }
          & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
        ), priceExpectedDetail: (
          { __typename?: 'EnergoElectricityResultPriceDetail' }
          & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ), priceResultDetail: (
          { __typename?: 'EnergoElectricityResultPriceDetail' }
          & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ) }
      )>, gasResultItem: Maybe<(
        { __typename?: 'EnergoGasResultItem' }
        & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
        & { partner: (
          { __typename?: 'EnergoGasPartner' }
          & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
          & { rating: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), product: (
          { __typename?: 'EnergoGasProduct' }
          & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
        ), priceExpectedDetail: (
          { __typename?: 'EnergoGasResultPriceDetail' }
          & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ), priceResultDetail: (
          { __typename?: 'EnergoGasResultPriceDetail' }
          & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ) }
      )>, acceptedBy: Maybe<(
        { __typename?: 'ProfileUser' }
        & Pick<ProfileUser, 'email' | 'name'>
      )> }
    )>, energoContract: (
      { __typename?: 'EnergoEnergoContract' }
      & Pick<EnergoEnergoContract, 'id' | 'name' | 'surname' | 'phone' | 'email' | 'customerNote' | 'status' | 'preferredPaymentMethod' | 'sendInvoiceMethod' | 'invoiceUploaded' | 'supplyStartDate' | 'expectedSupplyStartDate' | 'methodOfSendingContract'>
      & { supplyAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, contactAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, permanentAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, customSupplyAddress: Maybe<(
        { __typename?: 'CustomAddress' }
        & Pick<CustomAddress, 'address' | 'zipCode' | 'city'>
      )>, customContactAddress: Maybe<(
        { __typename?: 'CustomAddress' }
        & Pick<CustomAddress, 'address' | 'zipCode' | 'city'>
      )>, customPermanentAddress: Maybe<(
        { __typename?: 'CustomAddress' }
        & Pick<CustomAddress, 'address' | 'zipCode' | 'city'>
      )>, attachments: Array<(
        { __typename?: 'EnergoEnergoContractAttachment' }
        & Pick<EnergoEnergoContractAttachment, 'id' | 'type' | 'createdAt'>
        & { file: (
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        ) }
      )>, energoOffer: Maybe<(
        { __typename?: 'EnergoOffer' }
        & Pick<EnergoOffer, 'id' | 'status' | 'validTo' | 'acceptedAt'>
        & { benefitResponse: Maybe<(
          { __typename?: 'Benefit' }
          & Pick<Benefit, 'id' | 'uuid' | 'name' | 'validFrom' | 'validTo' | 'template' | 'perex' | 'text' | 'buttonText'>
          & { profileBenefit: (
            { __typename?: 'ProfileBenefit' }
            & Pick<ProfileBenefit, 'id' | 'name' | 'surname' | 'status' | 'phone'>
            & { attachments: Array<(
              { __typename?: 'BenefitAttachment' }
              & Pick<BenefitAttachment, 'id'>
              & { file: (
                { __typename?: 'File' }
                & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
              ) }
            )> }
          ), image: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, electricityResultItem: Maybe<(
          { __typename?: 'EnergoElectricityResultItem' }
          & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
          & { partner: (
            { __typename?: 'EnergoElectricityPartner' }
            & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
            & { rating: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), product: (
            { __typename?: 'EnergoElectricityProduct' }
            & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
          ), priceExpectedDetail: (
            { __typename?: 'EnergoElectricityResultPriceDetail' }
            & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
          ), priceResultDetail: (
            { __typename?: 'EnergoElectricityResultPriceDetail' }
            & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
          ) }
        )>, gasResultItem: Maybe<(
          { __typename?: 'EnergoGasResultItem' }
          & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
          & { partner: (
            { __typename?: 'EnergoGasPartner' }
            & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
            & { rating: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), product: (
            { __typename?: 'EnergoGasProduct' }
            & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
          ), priceExpectedDetail: (
            { __typename?: 'EnergoGasResultPriceDetail' }
            & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
          ), priceResultDetail: (
            { __typename?: 'EnergoGasResultPriceDetail' }
            & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
          ) }
        )>, acceptedBy: Maybe<(
          { __typename?: 'ProfileUser' }
          & Pick<ProfileUser, 'email' | 'name'>
        )> }
      )> }
    ), adminLastChangeBy: Maybe<(
      { __typename?: 'ProfileUser' }
      & Pick<ProfileUser, 'email' | 'name'>
    )>, watchWolf: Maybe<(
      { __typename?: 'WatchWolfPreview' }
      & Pick<WatchWolfPreview, 'uuid'>
    )> }
  )>, customerMyVehicleInsuranceOrders: Array<(
    { __typename?: 'VehicleInsuranceOrderResult' }
    & Pick<VehicleInsuranceOrderResult, 'id' | 'lowestPrice'>
    & { order: (
      { __typename?: 'VehicleInsuranceOrder' }
      & Pick<VehicleInsuranceOrder, 'id' | 'uuid' | 'createdAt' | 'status' | 'packagesInsuranceType' | 'selectedInsuranceType' | 'insurableInterest' | 'resultEmail' | 'phone' | 'calculationCurrentStep' | 'orderCurrentStep' | 'updatedAt' | 'hasSpecificPackages' | 'beginDate'>
      & { vehicle: (
        { __typename?: 'InsuredVehicle' }
        & Pick<InsuredVehicle, 'uuid' | 'vehicleType' | 'registrationNumber' | 'vin' | 'kilometersTotal' | 'ownedMoreThanAYear' | 'newVehicle' | 'contractualCarService' | 'vehicleValue' | 'vehicleValueWithVat' | 'firstRegistrationDate' | 'numberOfRegistrationCertificate' | 'vehicleSearchStatus'>
        & { expectedKilometersPerYear: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, manufacturer: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, fuel: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, engineDisplacement: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, maxPower: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, countOfSeats: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, maxWeight: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, modelLine: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, model: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, modelType: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, security: (
          { __typename?: 'VehicleSecurity' }
          & Pick<VehicleSecurity, 'none' | 'mechanical' | 'passiveSearch' | 'activeSearch' | 'glassVin' | 'immobilizer' | 'alarm'>
        ), carUsageMethod: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, additionalEquipment: Array<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )> }
      ), subjectInsured: (
        { __typename?: 'InsuredSubject' }
        & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
        & { cityPart: Maybe<(
          { __typename?: 'CityPart' }
          & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
        )>, address: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, correspondenceAddress: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, functionOfRepresentative: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )> }
      ), subjectCarHolder: Maybe<(
        { __typename?: 'InsuredSubject' }
        & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
        & { cityPart: Maybe<(
          { __typename?: 'CityPart' }
          & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
        )>, address: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, correspondenceAddress: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, functionOfRepresentative: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )> }
      )>, subjectCarOwner: Maybe<(
        { __typename?: 'InsuredSubject' }
        & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
        & { cityPart: Maybe<(
          { __typename?: 'CityPart' }
          & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
        )>, address: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, correspondenceAddress: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, functionOfRepresentative: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )> }
      )>, selectedOffer: Maybe<(
        { __typename?: 'VehicleInsuranceProductOffer' }
        & Pick<VehicleInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'paymentFrequency' | 'calculatedPaymentFrequency'>
        & { mtplProduct: Maybe<(
          { __typename?: 'VehicleInsuranceProduct' }
          & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, cascoProduct: Maybe<(
          { __typename?: 'VehicleInsuranceProduct' }
          & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, estimatedPrice: (
          { __typename?: 'VehicleInsuranceEstimatedPrice' }
          & Pick<VehicleInsuranceEstimatedPrice, 'yearly' | 'quarterly' | 'halfYearly' | 'monthly'>
        ) }
      )>, contactPerson: Maybe<(
        { __typename?: 'VehicleInsuranceContactPerson' }
        & Pick<VehicleInsuranceContactPerson, 'name' | 'surname' | 'email' | 'phone' | 'agreementProcessPersonalData' | 'agreementTermsAndConditions'>
      )>, contract: Maybe<(
        { __typename?: 'VehicleInsuranceContract' }
        & Pick<VehicleInsuranceContract, 'id' | 'uuid' | 'payed' | 'paymentUrl'>
        & { price: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'currency'>
        ), annualPrice: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'currency'>
        ), documents: Array<Maybe<(
          { __typename?: 'VehicleInsuranceContractDocument' }
          & Pick<VehicleInsuranceContractDocument, 'id' | 'name' | 'documentType'>
          & { documentCategory: (
            { __typename?: 'CodeBook' }
            & Pick<CodeBook, 'id' | 'label'>
          ), file: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>>, paymentInfo: Maybe<(
          { __typename?: 'VehicleInsuranceContractPaymentInfo' }
          & Pick<VehicleInsuranceContractPaymentInfo, 'account' | 'bankCode' | 'constantSymbol' | 'specificSymbol' | 'variableSymbol' | 'message' | 'paymentUrl'>
          & { amount: Maybe<(
            { __typename?: 'Money' }
            & Pick<Money, 'amount' | 'currency'>
          )>, qrCodeFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, casco: Maybe<(
          { __typename?: 'VehicleInsuranceProduct' }
          & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, mtpl: Maybe<(
          { __typename?: 'VehicleInsuranceProduct' }
          & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )> }
      )>, additionalOptimumRequirements: (
        { __typename?: 'VehicleInsuranceAdditionalRequirementsFromClient' }
        & Pick<VehicleInsuranceAdditionalRequirementsFromClient, 'glass' | 'animalAccident'>
      ) }
    ), packages: Array<(
      { __typename?: 'VehicleInsurancePackage' }
      & Pick<VehicleInsurancePackage, 'id' | 'uuid' | 'type' | 'name' | 'protectionCover' | 'lowestPrice' | 'participation' | 'mobileOrder'>
      & { productOffers: Array<(
        { __typename?: 'VehicleInsuranceProductOffer' }
        & Pick<VehicleInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'paymentFrequency' | 'calculatedPaymentFrequency'>
        & { mtplProduct: Maybe<(
          { __typename?: 'VehicleInsuranceProduct' }
          & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, cascoProduct: Maybe<(
          { __typename?: 'VehicleInsuranceProduct' }
          & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, estimatedPrice: (
          { __typename?: 'VehicleInsuranceEstimatedPrice' }
          & Pick<VehicleInsuranceEstimatedPrice, 'yearly' | 'quarterly' | 'halfYearly' | 'monthly'>
        ) }
      )>, additionalRequirements: (
        { __typename?: 'VehicleInsuranceAdditionalRequirements' }
        & Pick<VehicleInsuranceAdditionalRequirements, 'id'>
        & { glass: (
          { __typename?: 'VehicleInsuranceGlassRequirement' }
          & Pick<VehicleInsuranceGlassRequirement, 'enabled' | 'value'>
        ), animalAccident: (
          { __typename?: 'VehicleInsuranceAnimalAccidentRequirement' }
          & Pick<VehicleInsuranceAnimalAccidentRequirement, 'enabled' | 'value'>
        ) }
      ) }
    )>, cheapestOffer: Maybe<(
      { __typename?: 'VehicleInsuranceProductOffer' }
      & Pick<VehicleInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'paymentFrequency' | 'calculatedPaymentFrequency'>
      & { mtplProduct: Maybe<(
        { __typename?: 'VehicleInsuranceProduct' }
        & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, cascoProduct: Maybe<(
        { __typename?: 'VehicleInsuranceProduct' }
        & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, estimatedPrice: (
        { __typename?: 'VehicleInsuranceEstimatedPrice' }
        & Pick<VehicleInsuranceEstimatedPrice, 'yearly' | 'quarterly' | 'halfYearly' | 'monthly'>
      ) }
    )> }
  )>, customerMyTravelInsuranceOrders: Array<(
    { __typename?: 'TravelInsuranceOrderResult' }
    & Pick<TravelInsuranceOrderResult, 'id'>
    & { order: (
      { __typename?: 'TravelInsuranceOrder' }
      & Pick<TravelInsuranceOrder, 'id' | 'uuid' | 'dateFrom' | 'dateTo' | 'insuranceType' | 'travelPurpose' | 'family' | 'resultEmail' | 'createdAt' | 'updatedAt' | 'status'>
      & { destination: (
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      ), policyHolder: Maybe<(
        { __typename?: 'Person' }
        & Pick<Person, 'id' | 'uuid' | 'name' | 'surname' | 'birthDate' | 'birthNumber' | 'phone' | 'email' | 'policyHolder' | 'insured' | 'age'>
        & { address: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, nationality: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )> }
      )>, insuredPersons: Array<(
        { __typename?: 'Person' }
        & Pick<Person, 'id' | 'uuid' | 'name' | 'surname' | 'birthDate' | 'birthNumber' | 'phone' | 'email' | 'policyHolder' | 'insured' | 'age'>
        & { address: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, nationality: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )> }
      )>, insuredSports: Array<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, selectedOffer: Maybe<(
        { __typename?: 'TravelInsuranceProductOffer' }
        & Pick<TravelInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'pricePerDay' | 'hasUpsellOffer' | 'packageType'>
        & { product: Maybe<(
          { __typename?: 'TravelInsuranceProduct' }
          & Pick<TravelInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'medicalExpenseCover' | 'liabilityHealthCover' | 'liabilityPropertyCover' | 'baggageCover' | 'injuryCover' | 'type' | 'addedValue' | 'gift' | 'bonus' | 'label' | 'labelTooltip'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )> }
      )>, contract: Maybe<(
        { __typename?: 'TravelInsuranceContract' }
        & Pick<TravelInsuranceContract, 'id' | 'uuid' | 'payed'>
        & { price: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'currency'>
        ), annualPrice: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'currency'>
        ), documents: Array<Maybe<(
          { __typename?: 'TravelInsuranceContractDocument' }
          & Pick<TravelInsuranceContractDocument, 'id' | 'name' | 'documentType'>
          & { documentCategory: (
            { __typename?: 'TravelInsuranceCodeBook' }
            & Pick<TravelInsuranceCodeBook, 'id' | 'label'>
          ), file: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>>, paymentInfo: Maybe<(
          { __typename?: 'TravelInsuranceContractPaymentInfo' }
          & Pick<TravelInsuranceContractPaymentInfo, 'id' | 'account' | 'bankCode' | 'constantSymbol' | 'specificSymbol' | 'variableSymbol' | 'message' | 'paymentUrl'>
          & { amount: (
            { __typename?: 'Money' }
            & Pick<Money, 'amount' | 'currency'>
          ), qrCodeFile: (
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          ) }
        )> }
      )> }
    ), packages: Array<(
      { __typename?: 'TravelInsurancePackage' }
      & Pick<TravelInsurancePackage, 'id' | 'uuid' | 'type' | 'name' | 'baggageRequirement' | 'injuryRequirement' | 'liabilityRequirement' | 'lowestPrice' | 'mobileOrder'>
      & { medicalExpenseCovers: Array<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, productOffers: Array<(
        { __typename?: 'TravelInsuranceProductOffer' }
        & Pick<TravelInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'pricePerDay' | 'hasUpsellOffer' | 'packageType'>
        & { product: Maybe<(
          { __typename?: 'TravelInsuranceProduct' }
          & Pick<TravelInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'medicalExpenseCover' | 'liabilityHealthCover' | 'liabilityPropertyCover' | 'baggageCover' | 'injuryCover' | 'type' | 'addedValue' | 'gift' | 'bonus' | 'label' | 'labelTooltip'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )> }
      )> }
    )>, cheapestOffer: Maybe<(
      { __typename?: 'TravelInsuranceProductOffer' }
      & Pick<TravelInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'pricePerDay' | 'hasUpsellOffer' | 'packageType'>
      & { product: Maybe<(
        { __typename?: 'TravelInsuranceProduct' }
        & Pick<TravelInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'medicalExpenseCover' | 'liabilityHealthCover' | 'liabilityPropertyCover' | 'baggageCover' | 'injuryCover' | 'type' | 'addedValue' | 'gift' | 'bonus' | 'label' | 'labelTooltip'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )> }
    )> }
  )>, customerMyPropertyInsuranceOrders: Array<(
    { __typename?: 'PropertyInsuranceCalculationResult' }
    & Pick<PropertyInsuranceCalculationResult, 'id'>
    & { order: (
      { __typename?: 'PropertyInsuranceOrder' }
      & Pick<PropertyInsuranceOrder, 'uuid' | 'buildingPrice' | 'buildingSize' | 'buildingType' | 'groundFloorApartment' | 'householdPrice' | 'householdSize' | 'insuranceType' | 'insureSecondaryBuilding' | 'propertyTenantRelation' | 'resultEmail' | 'resultPhone' | 'createdAt' | 'updatedAt' | 'status'>
      & { insuredAddress: (
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      ), requirements: Maybe<(
        { __typename?: 'PropertyInsuranceRequirements' }
        & Pick<PropertyInsuranceRequirements, 'complementLiabilityCommonRequirement' | 'complementLiabilityPropertyRequirement' | 'floodInsuranceRequirement'>
      )>, riscIndicators: Maybe<(
        { __typename?: 'PropertyInsuranceRiscIndicators' }
        & Pick<PropertyInsuranceRiscIndicators, 'floodCount20YearsBuilding' | 'floodCount20YearsHouseHold'>
      )>, insuranceInfo: (
        { __typename?: 'PropertyInsuranceInfo' }
        & Pick<PropertyInsuranceInfo, 'sumInsuredBuilding' | 'sumInsuredHousehold'>
      ), selectedOffer: Maybe<(
        { __typename?: 'PropertyInsuranceProductOffer' }
        & Pick<PropertyInsuranceProductOffer, 'id' | 'uuid' | 'price' | 'insuranceType'>
        & { product: (
          { __typename?: 'PropertyInsuranceProduct' }
          & Pick<PropertyInsuranceProduct, 'id' | 'shortName' | 'name' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'type' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'risksHousehold' | 'risksProperty'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, subLimitsHousehold: Array<(
            { __typename?: 'PropertyInsuranceSubLimit' }
            & Pick<PropertyInsuranceSubLimit, 'id' | 'label' | 'description' | 'value'>
          )>, faqs: Array<(
            { __typename?: 'FaqPreview' }
            & Pick<FaqPreview, 'id' | 'question' | 'answerMD' | 'hasDetail' | 'seoName'>
          )>, subLimitsProperty: Array<(
            { __typename?: 'PropertyInsuranceSubLimit' }
            & Pick<PropertyInsuranceSubLimit, 'id' | 'label' | 'description' | 'value'>
          )> }
        ) }
      )>, contactPerson: Maybe<(
        { __typename?: 'PropertyInsuranceContactPerson' }
        & Pick<PropertyInsuranceContactPerson, 'id' | 'agreementProcessPersonalData' | 'agreementTermsAndConditions' | 'email' | 'name' | 'phone' | 'surname'>
      )> }
    ), packages: Array<(
      { __typename?: 'PropertyInsuranceInsurancePackage' }
      & Pick<PropertyInsuranceInsurancePackage, 'id' | 'title' | 'description' | 'type' | 'lowestPrice'>
      & { productOffers: Array<(
        { __typename?: 'PropertyInsuranceProductOffer' }
        & Pick<PropertyInsuranceProductOffer, 'id' | 'uuid' | 'price' | 'insuranceType'>
        & { product: (
          { __typename?: 'PropertyInsuranceProduct' }
          & Pick<PropertyInsuranceProduct, 'id' | 'shortName' | 'name' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'type' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'risksHousehold' | 'risksProperty'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, subLimitsHousehold: Array<(
            { __typename?: 'PropertyInsuranceSubLimit' }
            & Pick<PropertyInsuranceSubLimit, 'id' | 'label' | 'description' | 'value'>
          )>, faqs: Array<(
            { __typename?: 'FaqPreview' }
            & Pick<FaqPreview, 'id' | 'question' | 'answerMD' | 'hasDetail' | 'seoName'>
          )>, subLimitsProperty: Array<(
            { __typename?: 'PropertyInsuranceSubLimit' }
            & Pick<PropertyInsuranceSubLimit, 'id' | 'label' | 'description' | 'value'>
          )> }
        ) }
      )> }
    )>, cheapestOffer: Maybe<(
      { __typename?: 'PropertyInsuranceProductOffer' }
      & Pick<PropertyInsuranceProductOffer, 'id' | 'uuid' | 'price' | 'insuranceType'>
      & { product: (
        { __typename?: 'PropertyInsuranceProduct' }
        & Pick<PropertyInsuranceProduct, 'id' | 'shortName' | 'name' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'type' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'risksHousehold' | 'risksProperty'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )>, subLimitsHousehold: Array<(
          { __typename?: 'PropertyInsuranceSubLimit' }
          & Pick<PropertyInsuranceSubLimit, 'id' | 'label' | 'description' | 'value'>
        )>, faqs: Array<(
          { __typename?: 'FaqPreview' }
          & Pick<FaqPreview, 'id' | 'question' | 'answerMD' | 'hasDetail' | 'seoName'>
        )>, subLimitsProperty: Array<(
          { __typename?: 'PropertyInsuranceSubLimit' }
          & Pick<PropertyInsuranceSubLimit, 'id' | 'label' | 'description' | 'value'>
        )> }
      ) }
    )>, watchWolf: Maybe<(
      { __typename?: 'WatchWolfPreview' }
      & Pick<WatchWolfPreview, 'uuid' | 'name' | 'type' | 'phone' | 'preferredEmail' | 'minSaving' | 'commitmentEnd' | 'vertical' | 'subVertical' | 'createdAt'>
    )> }
  )> }
);

export type CustomerMyCalculationsByStatusesQueryVariables = Exact<{
  data: CalculationsByStatusesInput;
}>;


export type CustomerMyCalculationsByStatusesQuery = (
  { __typename?: 'Query' }
  & { customerMyCalculationsByStatuses: Array<(
    { __typename?: 'CustomerZoneCalculationDetail' }
    & Pick<CustomerZoneCalculationDetail, 'uuid' | 'type' | 'source' | 'yearlyConsumption' | 'yearlyConsumptionUnit' | 'yearlyConsumptionLowTariff' | 'yearlyConsumptionLowTariffUnit' | 'yearlyPayment' | 'gasYearlyPayment' | 'gasYearlyConsumption' | 'gasYearlyConsumptionUnit' | 'gasMonthlyAdvances' | 'gasUnknownMonthlyAdvances' | 'monthlyAdvances' | 'unknownMonthlyAdvances' | 'email' | 'saving' | 'createdAt' | 'updatedAt' | 'lastViewed' | 'currentProductId' | 'currentProductName' | 'currentProductFollowingId' | 'unknownCurrentProduct' | 'gasCurrentProductId' | 'gasCurrentProductName' | 'gasCurrentProductFollowingId' | 'gasUnknownCurrentProduct' | 'adminLastChangeAt'>
    & { address: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
      & { extended: Maybe<(
        { __typename?: 'SmartAddressExtendedInfo' }
        & Pick<SmartAddressExtendedInfo, 'id'>
        & { objectType: Maybe<(
          { __typename?: 'SmartAddressObjectType' }
          & Pick<SmartAddressObjectType, 'id' | 'nazev'>
        )> }
      )>, extendedV2: Maybe<(
        { __typename?: 'SmartAddressSdkExtendedInfo' }
        & { administrativniUdaje: Maybe<(
          { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
          & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )> }
      )> }
    )>, partner: Maybe<(
      { __typename?: 'EnergoElectricityPartner' }
      & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    )>, gasPartner: Maybe<(
      { __typename?: 'EnergoGasPartner' }
      & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    )>, gasUsages: Maybe<(
      { __typename?: 'GasUsages' }
      & Pick<GasUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
    )>, electricityUsages: Maybe<(
      { __typename?: 'ElectricityUsages' }
      & Pick<ElectricityUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
    )>, floorAreaRange: Maybe<(
      { __typename?: 'EnergoFloorAreaRangeCodebook' }
      & Pick<EnergoFloorAreaRangeCodebook, 'id'>
      & { label: EnergoFloorAreaRangeCodebook['name'] }
    )>, isolation: Maybe<(
      { __typename?: 'EnergoIsolationCodebook' }
      & Pick<EnergoIsolationCodebook, 'id'>
      & { label: EnergoIsolationCodebook['name'] }
    )>, personsCountRange: Maybe<(
      { __typename?: 'EnergoPersonsCountRangeCodebook' }
      & Pick<EnergoPersonsCountRangeCodebook, 'id'>
      & { label: EnergoPersonsCountRangeCodebook['name'] }
    )>, distributionRate: Maybe<(
      { __typename?: 'EnergoDistributionRate' }
      & Pick<EnergoDistributionRate, 'id' | 'name' | 'lowTariff'>
    )>, breaker: Maybe<(
      { __typename?: 'EnergoBreaker' }
      & Pick<EnergoBreaker, 'id' | 'name'>
    )>, standaloneInvoice: Maybe<(
      { __typename?: 'EnergoStandaloneInvoice' }
      & Pick<EnergoStandaloneInvoice, 'id' | 'uuid'>
    )>, bestResultItem: Maybe<(
      { __typename?: 'EnergoElectricityResultItem' }
      & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoElectricityPartner' }
        & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoElectricityProduct' }
        & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    )>, bestGasResultItem: Maybe<(
      { __typename?: 'EnergoGasResultItem' }
      & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoGasPartner' }
        & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoGasProduct' }
        & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    )>, energoOffers: Array<(
      { __typename?: 'EnergoOffer' }
      & Pick<EnergoOffer, 'id' | 'status' | 'validTo' | 'acceptedAt'>
      & { benefitResponse: Maybe<(
        { __typename?: 'Benefit' }
        & Pick<Benefit, 'id' | 'uuid' | 'name' | 'validFrom' | 'validTo' | 'template' | 'perex' | 'text' | 'buttonText'>
        & { profileBenefit: (
          { __typename?: 'ProfileBenefit' }
          & Pick<ProfileBenefit, 'id' | 'name' | 'surname' | 'status' | 'phone'>
          & { attachments: Array<(
            { __typename?: 'BenefitAttachment' }
            & Pick<BenefitAttachment, 'id'>
            & { file: (
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            ) }
          )> }
        ), image: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, electricityResultItem: Maybe<(
        { __typename?: 'EnergoElectricityResultItem' }
        & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
        & { partner: (
          { __typename?: 'EnergoElectricityPartner' }
          & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
          & { rating: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), product: (
          { __typename?: 'EnergoElectricityProduct' }
          & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
        ), priceExpectedDetail: (
          { __typename?: 'EnergoElectricityResultPriceDetail' }
          & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ), priceResultDetail: (
          { __typename?: 'EnergoElectricityResultPriceDetail' }
          & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ) }
      )>, gasResultItem: Maybe<(
        { __typename?: 'EnergoGasResultItem' }
        & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
        & { partner: (
          { __typename?: 'EnergoGasPartner' }
          & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
          & { rating: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), product: (
          { __typename?: 'EnergoGasProduct' }
          & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
        ), priceExpectedDetail: (
          { __typename?: 'EnergoGasResultPriceDetail' }
          & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ), priceResultDetail: (
          { __typename?: 'EnergoGasResultPriceDetail' }
          & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ) }
      )>, acceptedBy: Maybe<(
        { __typename?: 'ProfileUser' }
        & Pick<ProfileUser, 'email' | 'name'>
      )> }
    )>, energoContract: (
      { __typename?: 'EnergoEnergoContract' }
      & Pick<EnergoEnergoContract, 'id' | 'name' | 'surname' | 'phone' | 'email' | 'customerNote' | 'status' | 'preferredPaymentMethod' | 'sendInvoiceMethod' | 'invoiceUploaded' | 'supplyStartDate' | 'expectedSupplyStartDate' | 'methodOfSendingContract'>
      & { supplyAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, contactAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, permanentAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, customSupplyAddress: Maybe<(
        { __typename?: 'CustomAddress' }
        & Pick<CustomAddress, 'address' | 'zipCode' | 'city'>
      )>, customContactAddress: Maybe<(
        { __typename?: 'CustomAddress' }
        & Pick<CustomAddress, 'address' | 'zipCode' | 'city'>
      )>, customPermanentAddress: Maybe<(
        { __typename?: 'CustomAddress' }
        & Pick<CustomAddress, 'address' | 'zipCode' | 'city'>
      )>, attachments: Array<(
        { __typename?: 'EnergoEnergoContractAttachment' }
        & Pick<EnergoEnergoContractAttachment, 'id' | 'type' | 'createdAt'>
        & { file: (
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        ) }
      )>, energoOffer: Maybe<(
        { __typename?: 'EnergoOffer' }
        & Pick<EnergoOffer, 'id' | 'status' | 'validTo' | 'acceptedAt'>
        & { benefitResponse: Maybe<(
          { __typename?: 'Benefit' }
          & Pick<Benefit, 'id' | 'uuid' | 'name' | 'validFrom' | 'validTo' | 'template' | 'perex' | 'text' | 'buttonText'>
          & { profileBenefit: (
            { __typename?: 'ProfileBenefit' }
            & Pick<ProfileBenefit, 'id' | 'name' | 'surname' | 'status' | 'phone'>
            & { attachments: Array<(
              { __typename?: 'BenefitAttachment' }
              & Pick<BenefitAttachment, 'id'>
              & { file: (
                { __typename?: 'File' }
                & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
              ) }
            )> }
          ), image: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, electricityResultItem: Maybe<(
          { __typename?: 'EnergoElectricityResultItem' }
          & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
          & { partner: (
            { __typename?: 'EnergoElectricityPartner' }
            & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
            & { rating: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), product: (
            { __typename?: 'EnergoElectricityProduct' }
            & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
          ), priceExpectedDetail: (
            { __typename?: 'EnergoElectricityResultPriceDetail' }
            & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
          ), priceResultDetail: (
            { __typename?: 'EnergoElectricityResultPriceDetail' }
            & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
          ) }
        )>, gasResultItem: Maybe<(
          { __typename?: 'EnergoGasResultItem' }
          & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
          & { partner: (
            { __typename?: 'EnergoGasPartner' }
            & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
            & { rating: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), product: (
            { __typename?: 'EnergoGasProduct' }
            & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
          ), priceExpectedDetail: (
            { __typename?: 'EnergoGasResultPriceDetail' }
            & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
          ), priceResultDetail: (
            { __typename?: 'EnergoGasResultPriceDetail' }
            & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
          ) }
        )>, acceptedBy: Maybe<(
          { __typename?: 'ProfileUser' }
          & Pick<ProfileUser, 'email' | 'name'>
        )> }
      )> }
    ), adminLastChangeBy: Maybe<(
      { __typename?: 'ProfileUser' }
      & Pick<ProfileUser, 'email' | 'name'>
    )>, watchWolf: Maybe<(
      { __typename?: 'WatchWolfPreview' }
      & Pick<WatchWolfPreview, 'uuid'>
    )> }
  )>, customerMyVehicleInsuranceContracts: Array<(
    { __typename?: 'VehicleInsuranceOrder' }
    & Pick<VehicleInsuranceOrder, 'id' | 'uuid' | 'createdAt' | 'status' | 'packagesInsuranceType' | 'selectedInsuranceType' | 'insurableInterest' | 'resultEmail' | 'phone' | 'calculationCurrentStep' | 'orderCurrentStep' | 'updatedAt' | 'hasSpecificPackages' | 'beginDate'>
    & { vehicle: (
      { __typename?: 'InsuredVehicle' }
      & Pick<InsuredVehicle, 'uuid' | 'vehicleType' | 'registrationNumber' | 'vin' | 'kilometersTotal' | 'ownedMoreThanAYear' | 'newVehicle' | 'contractualCarService' | 'vehicleValue' | 'vehicleValueWithVat' | 'firstRegistrationDate' | 'numberOfRegistrationCertificate' | 'vehicleSearchStatus'>
      & { expectedKilometersPerYear: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, manufacturer: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, fuel: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, engineDisplacement: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, maxPower: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, countOfSeats: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, maxWeight: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, modelLine: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, model: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, modelType: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, security: (
        { __typename?: 'VehicleSecurity' }
        & Pick<VehicleSecurity, 'none' | 'mechanical' | 'passiveSearch' | 'activeSearch' | 'glassVin' | 'immobilizer' | 'alarm'>
      ), carUsageMethod: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, additionalEquipment: Array<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    ), subjectInsured: (
      { __typename?: 'InsuredSubject' }
      & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
      & { cityPart: Maybe<(
        { __typename?: 'CityPart' }
        & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
      )>, address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, correspondenceAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, functionOfRepresentative: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    ), subjectCarHolder: Maybe<(
      { __typename?: 'InsuredSubject' }
      & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
      & { cityPart: Maybe<(
        { __typename?: 'CityPart' }
        & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
      )>, address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, correspondenceAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, functionOfRepresentative: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    )>, subjectCarOwner: Maybe<(
      { __typename?: 'InsuredSubject' }
      & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
      & { cityPart: Maybe<(
        { __typename?: 'CityPart' }
        & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
      )>, address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, correspondenceAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, functionOfRepresentative: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    )>, selectedOffer: Maybe<(
      { __typename?: 'VehicleInsuranceProductOffer' }
      & Pick<VehicleInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'paymentFrequency' | 'calculatedPaymentFrequency'>
      & { mtplProduct: Maybe<(
        { __typename?: 'VehicleInsuranceProduct' }
        & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, cascoProduct: Maybe<(
        { __typename?: 'VehicleInsuranceProduct' }
        & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, estimatedPrice: (
        { __typename?: 'VehicleInsuranceEstimatedPrice' }
        & Pick<VehicleInsuranceEstimatedPrice, 'yearly' | 'quarterly' | 'halfYearly' | 'monthly'>
      ) }
    )>, contactPerson: Maybe<(
      { __typename?: 'VehicleInsuranceContactPerson' }
      & Pick<VehicleInsuranceContactPerson, 'name' | 'surname' | 'email' | 'phone' | 'agreementProcessPersonalData' | 'agreementTermsAndConditions'>
    )>, contract: Maybe<(
      { __typename?: 'VehicleInsuranceContract' }
      & Pick<VehicleInsuranceContract, 'id' | 'uuid' | 'payed' | 'paymentUrl'>
      & { price: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency'>
      ), annualPrice: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency'>
      ), documents: Array<Maybe<(
        { __typename?: 'VehicleInsuranceContractDocument' }
        & Pick<VehicleInsuranceContractDocument, 'id' | 'name' | 'documentType'>
        & { documentCategory: (
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        ), file: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>>, paymentInfo: Maybe<(
        { __typename?: 'VehicleInsuranceContractPaymentInfo' }
        & Pick<VehicleInsuranceContractPaymentInfo, 'account' | 'bankCode' | 'constantSymbol' | 'specificSymbol' | 'variableSymbol' | 'message' | 'paymentUrl'>
        & { amount: Maybe<(
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'currency'>
        )>, qrCodeFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, casco: Maybe<(
        { __typename?: 'VehicleInsuranceProduct' }
        & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, mtpl: Maybe<(
        { __typename?: 'VehicleInsuranceProduct' }
        & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )> }
    )>, additionalOptimumRequirements: (
      { __typename?: 'VehicleInsuranceAdditionalRequirementsFromClient' }
      & Pick<VehicleInsuranceAdditionalRequirementsFromClient, 'glass' | 'animalAccident'>
    ) }
  )>, customerMyTravelInsuranceContracts: Array<(
    { __typename?: 'TravelInsuranceOrder' }
    & Pick<TravelInsuranceOrder, 'id' | 'uuid' | 'dateFrom' | 'dateTo' | 'insuranceType' | 'travelPurpose' | 'family' | 'resultEmail' | 'createdAt' | 'updatedAt' | 'status'>
    & { destination: (
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    ), policyHolder: Maybe<(
      { __typename?: 'Person' }
      & Pick<Person, 'id' | 'uuid' | 'name' | 'surname' | 'birthDate' | 'birthNumber' | 'phone' | 'email' | 'policyHolder' | 'insured' | 'age'>
      & { address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, nationality: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    )>, insuredPersons: Array<(
      { __typename?: 'Person' }
      & Pick<Person, 'id' | 'uuid' | 'name' | 'surname' | 'birthDate' | 'birthNumber' | 'phone' | 'email' | 'policyHolder' | 'insured' | 'age'>
      & { address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, nationality: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    )>, insuredSports: Array<(
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    )>, selectedOffer: Maybe<(
      { __typename?: 'TravelInsuranceProductOffer' }
      & Pick<TravelInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'pricePerDay' | 'hasUpsellOffer' | 'packageType'>
      & { product: Maybe<(
        { __typename?: 'TravelInsuranceProduct' }
        & Pick<TravelInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'medicalExpenseCover' | 'liabilityHealthCover' | 'liabilityPropertyCover' | 'baggageCover' | 'injuryCover' | 'type' | 'addedValue' | 'gift' | 'bonus' | 'label' | 'labelTooltip'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )> }
    )>, contract: Maybe<(
      { __typename?: 'TravelInsuranceContract' }
      & Pick<TravelInsuranceContract, 'id' | 'uuid' | 'payed'>
      & { price: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency'>
      ), annualPrice: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency'>
      ), documents: Array<Maybe<(
        { __typename?: 'TravelInsuranceContractDocument' }
        & Pick<TravelInsuranceContractDocument, 'id' | 'name' | 'documentType'>
        & { documentCategory: (
          { __typename?: 'TravelInsuranceCodeBook' }
          & Pick<TravelInsuranceCodeBook, 'id' | 'label'>
        ), file: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>>, paymentInfo: Maybe<(
        { __typename?: 'TravelInsuranceContractPaymentInfo' }
        & Pick<TravelInsuranceContractPaymentInfo, 'id' | 'account' | 'bankCode' | 'constantSymbol' | 'specificSymbol' | 'variableSymbol' | 'message' | 'paymentUrl'>
        & { amount: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'currency'>
        ), qrCodeFile: (
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        ) }
      )> }
    )> }
  )>, customerMyPropertyInsuranceContracts: Array<(
    { __typename?: 'PropertyInsuranceOrder' }
    & Pick<PropertyInsuranceOrder, 'uuid' | 'buildingPrice' | 'buildingSize' | 'buildingType' | 'groundFloorApartment' | 'householdPrice' | 'householdSize' | 'insuranceType' | 'insureSecondaryBuilding' | 'propertyTenantRelation' | 'resultEmail' | 'resultPhone' | 'createdAt' | 'updatedAt' | 'status'>
    & { insuredAddress: (
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
      & { extended: Maybe<(
        { __typename?: 'SmartAddressExtendedInfo' }
        & Pick<SmartAddressExtendedInfo, 'id'>
        & { objectType: Maybe<(
          { __typename?: 'SmartAddressObjectType' }
          & Pick<SmartAddressObjectType, 'id' | 'nazev'>
        )> }
      )>, extendedV2: Maybe<(
        { __typename?: 'SmartAddressSdkExtendedInfo' }
        & { administrativniUdaje: Maybe<(
          { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
          & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )> }
      )> }
    ), requirements: Maybe<(
      { __typename?: 'PropertyInsuranceRequirements' }
      & Pick<PropertyInsuranceRequirements, 'complementLiabilityCommonRequirement' | 'complementLiabilityPropertyRequirement' | 'floodInsuranceRequirement'>
    )>, riscIndicators: Maybe<(
      { __typename?: 'PropertyInsuranceRiscIndicators' }
      & Pick<PropertyInsuranceRiscIndicators, 'floodCount20YearsBuilding' | 'floodCount20YearsHouseHold'>
    )>, insuranceInfo: (
      { __typename?: 'PropertyInsuranceInfo' }
      & Pick<PropertyInsuranceInfo, 'sumInsuredBuilding' | 'sumInsuredHousehold'>
    ), selectedOffer: Maybe<(
      { __typename?: 'PropertyInsuranceProductOffer' }
      & Pick<PropertyInsuranceProductOffer, 'id' | 'uuid' | 'price' | 'insuranceType'>
      & { product: (
        { __typename?: 'PropertyInsuranceProduct' }
        & Pick<PropertyInsuranceProduct, 'id' | 'shortName' | 'name' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'type' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'risksHousehold' | 'risksProperty'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )>, subLimitsHousehold: Array<(
          { __typename?: 'PropertyInsuranceSubLimit' }
          & Pick<PropertyInsuranceSubLimit, 'id' | 'label' | 'description' | 'value'>
        )>, faqs: Array<(
          { __typename?: 'FaqPreview' }
          & Pick<FaqPreview, 'id' | 'question' | 'answerMD' | 'hasDetail' | 'seoName'>
        )>, subLimitsProperty: Array<(
          { __typename?: 'PropertyInsuranceSubLimit' }
          & Pick<PropertyInsuranceSubLimit, 'id' | 'label' | 'description' | 'value'>
        )> }
      ) }
    )>, contactPerson: Maybe<(
      { __typename?: 'PropertyInsuranceContactPerson' }
      & Pick<PropertyInsuranceContactPerson, 'id' | 'agreementProcessPersonalData' | 'agreementTermsAndConditions' | 'email' | 'name' | 'phone' | 'surname'>
    )> }
  )> }
);

export type GetCustomerLeadsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCustomerLeadsQuery = (
  { __typename?: 'Query' }
  & { customerMyGasLeads: Array<(
    { __typename?: 'EnergoGasLead' }
    & Pick<EnergoGasLead, 'id' | 'yearlyPayment' | 'yearlyConsumption' | 'currentProductId' | 'currentProductName'>
    & { lead: (
      { __typename?: 'Lead' }
      & Pick<Lead, 'id' | 'type' | 'uuid' | 'name' | 'surname' | 'email' | 'phone' | 'status' | 'customerNote' | 'lastStatusChange' | 'completedAt' | 'sendEmailImmediately'>
      & { address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, contactAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, attachments: Array<(
        { __typename?: 'LeadAttachment' }
        & Pick<LeadAttachment, 'id'>
        & { file: (
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        ) }
      )> }
    ), calculation: (
      { __typename?: 'EnergoCalculation' }
      & Pick<EnergoCalculation, 'id' | 'uuid' | 'type' | 'source' | 'yearlyConsumption' | 'yearlyConsumptionUnit' | 'yearlyConsumptionLowTariff' | 'yearlyConsumptionLowTariffUnit' | 'yearlyPayment' | 'gasYearlyPayment' | 'gasYearlyConsumption' | 'gasYearlyConsumptionUnit' | 'gasMonthlyAdvances' | 'gasUnknownMonthlyAdvances' | 'monthlyAdvances' | 'unknownMonthlyAdvances' | 'email' | 'saving' | 'createdAt' | 'updatedAt' | 'lastViewed' | 'currentProductId' | 'currentProductName' | 'currentProductFollowingId' | 'unknownCurrentProduct' | 'gasCurrentProductId' | 'gasCurrentProductName' | 'gasCurrentProductFollowingId' | 'gasUnknownCurrentProduct' | 'phone' | 'individualPriceExpectedMonthly' | 'individualPriceExpectedMWhHighTariff' | 'individualPriceExpectedMWhLowTariff' | 'gasIndividualPriceExpectedMonthly' | 'gasIndividualPriceExpectedMWh' | 'individualOfferName' | 'reason'>
      & { address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, partner: Maybe<(
        { __typename?: 'EnergoElectricityPartner' }
        & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      )>, gasPartner: Maybe<(
        { __typename?: 'EnergoGasPartner' }
        & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      )>, gasUsages: Maybe<(
        { __typename?: 'GasUsages' }
        & Pick<GasUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
      )>, electricityUsages: Maybe<(
        { __typename?: 'ElectricityUsages' }
        & Pick<ElectricityUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
      )>, floorAreaRange: Maybe<(
        { __typename?: 'EnergoFloorAreaRangeCodebook' }
        & Pick<EnergoFloorAreaRangeCodebook, 'id'>
        & { label: EnergoFloorAreaRangeCodebook['name'] }
      )>, isolation: Maybe<(
        { __typename?: 'EnergoIsolationCodebook' }
        & Pick<EnergoIsolationCodebook, 'id'>
        & { label: EnergoIsolationCodebook['name'] }
      )>, personsCountRange: Maybe<(
        { __typename?: 'EnergoPersonsCountRangeCodebook' }
        & Pick<EnergoPersonsCountRangeCodebook, 'id'>
        & { label: EnergoPersonsCountRangeCodebook['name'] }
      )>, distributionRate: Maybe<(
        { __typename?: 'EnergoDistributionRate' }
        & Pick<EnergoDistributionRate, 'id' | 'name' | 'lowTariff'>
      )>, breaker: Maybe<(
        { __typename?: 'EnergoBreaker' }
        & Pick<EnergoBreaker, 'id' | 'name'>
      )>, standaloneInvoice: Maybe<(
        { __typename?: 'EnergoStandaloneInvoice' }
        & Pick<EnergoStandaloneInvoice, 'id' | 'uuid'>
      )>, bestResultItem: Maybe<(
        { __typename?: 'EnergoElectricityResultItem' }
        & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
        & { partner: (
          { __typename?: 'EnergoElectricityPartner' }
          & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
          & { rating: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), product: (
          { __typename?: 'EnergoElectricityProduct' }
          & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
        ), priceExpectedDetail: (
          { __typename?: 'EnergoElectricityResultPriceDetail' }
          & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ), priceResultDetail: (
          { __typename?: 'EnergoElectricityResultPriceDetail' }
          & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ) }
      )>, bestGasResultItem: Maybe<(
        { __typename?: 'EnergoGasResultItem' }
        & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
        & { partner: (
          { __typename?: 'EnergoGasPartner' }
          & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
          & { rating: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), product: (
          { __typename?: 'EnergoGasProduct' }
          & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
        ), priceExpectedDetail: (
          { __typename?: 'EnergoGasResultPriceDetail' }
          & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ), priceResultDetail: (
          { __typename?: 'EnergoGasResultPriceDetail' }
          & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ) }
      )> }
    ), resultItem: (
      { __typename?: 'EnergoGasResultItem' }
      & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoGasPartner' }
        & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoGasProduct' }
        & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    ), userResultItem: Maybe<(
      { __typename?: 'EnergoGasResultItem' }
      & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoGasPartner' }
        & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoGasProduct' }
        & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    )>, partner: Maybe<(
      { __typename?: 'EnergoGasPartner' }
      & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    )> }
  )>, customerMyElectricityLeads: Array<(
    { __typename?: 'EnergoElectricityLead' }
    & Pick<EnergoElectricityLead, 'id' | 'yearlyPayment' | 'yearlyConsumption' | 'yearlyConsumptionLowTariff' | 'currentProductId' | 'currentProductName'>
    & { lead: (
      { __typename?: 'Lead' }
      & Pick<Lead, 'id' | 'type' | 'uuid' | 'name' | 'surname' | 'email' | 'phone' | 'status' | 'customerNote' | 'lastStatusChange' | 'completedAt' | 'sendEmailImmediately'>
      & { address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, contactAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, attachments: Array<(
        { __typename?: 'LeadAttachment' }
        & Pick<LeadAttachment, 'id'>
        & { file: (
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        ) }
      )> }
    ), calculation: (
      { __typename?: 'EnergoCalculation' }
      & Pick<EnergoCalculation, 'id' | 'uuid' | 'type' | 'source' | 'yearlyConsumption' | 'yearlyConsumptionUnit' | 'yearlyConsumptionLowTariff' | 'yearlyConsumptionLowTariffUnit' | 'yearlyPayment' | 'gasYearlyPayment' | 'gasYearlyConsumption' | 'gasYearlyConsumptionUnit' | 'gasMonthlyAdvances' | 'gasUnknownMonthlyAdvances' | 'monthlyAdvances' | 'unknownMonthlyAdvances' | 'email' | 'saving' | 'createdAt' | 'updatedAt' | 'lastViewed' | 'currentProductId' | 'currentProductName' | 'currentProductFollowingId' | 'unknownCurrentProduct' | 'gasCurrentProductId' | 'gasCurrentProductName' | 'gasCurrentProductFollowingId' | 'gasUnknownCurrentProduct' | 'phone' | 'individualPriceExpectedMonthly' | 'individualPriceExpectedMWhHighTariff' | 'individualPriceExpectedMWhLowTariff' | 'gasIndividualPriceExpectedMonthly' | 'gasIndividualPriceExpectedMWh' | 'individualOfferName' | 'reason'>
      & { address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, partner: Maybe<(
        { __typename?: 'EnergoElectricityPartner' }
        & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      )>, gasPartner: Maybe<(
        { __typename?: 'EnergoGasPartner' }
        & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      )>, gasUsages: Maybe<(
        { __typename?: 'GasUsages' }
        & Pick<GasUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
      )>, electricityUsages: Maybe<(
        { __typename?: 'ElectricityUsages' }
        & Pick<ElectricityUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
      )>, floorAreaRange: Maybe<(
        { __typename?: 'EnergoFloorAreaRangeCodebook' }
        & Pick<EnergoFloorAreaRangeCodebook, 'id'>
        & { label: EnergoFloorAreaRangeCodebook['name'] }
      )>, isolation: Maybe<(
        { __typename?: 'EnergoIsolationCodebook' }
        & Pick<EnergoIsolationCodebook, 'id'>
        & { label: EnergoIsolationCodebook['name'] }
      )>, personsCountRange: Maybe<(
        { __typename?: 'EnergoPersonsCountRangeCodebook' }
        & Pick<EnergoPersonsCountRangeCodebook, 'id'>
        & { label: EnergoPersonsCountRangeCodebook['name'] }
      )>, distributionRate: Maybe<(
        { __typename?: 'EnergoDistributionRate' }
        & Pick<EnergoDistributionRate, 'id' | 'name' | 'lowTariff'>
      )>, breaker: Maybe<(
        { __typename?: 'EnergoBreaker' }
        & Pick<EnergoBreaker, 'id' | 'name'>
      )>, standaloneInvoice: Maybe<(
        { __typename?: 'EnergoStandaloneInvoice' }
        & Pick<EnergoStandaloneInvoice, 'id' | 'uuid'>
      )>, bestResultItem: Maybe<(
        { __typename?: 'EnergoElectricityResultItem' }
        & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
        & { partner: (
          { __typename?: 'EnergoElectricityPartner' }
          & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
          & { rating: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), product: (
          { __typename?: 'EnergoElectricityProduct' }
          & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
        ), priceExpectedDetail: (
          { __typename?: 'EnergoElectricityResultPriceDetail' }
          & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ), priceResultDetail: (
          { __typename?: 'EnergoElectricityResultPriceDetail' }
          & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ) }
      )>, bestGasResultItem: Maybe<(
        { __typename?: 'EnergoGasResultItem' }
        & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
        & { partner: (
          { __typename?: 'EnergoGasPartner' }
          & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
          & { rating: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), product: (
          { __typename?: 'EnergoGasProduct' }
          & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
        ), priceExpectedDetail: (
          { __typename?: 'EnergoGasResultPriceDetail' }
          & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ), priceResultDetail: (
          { __typename?: 'EnergoGasResultPriceDetail' }
          & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ) }
      )> }
    ), resultItem: (
      { __typename?: 'EnergoElectricityResultItem' }
      & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoElectricityPartner' }
        & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoElectricityProduct' }
        & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    ), userResultItem: Maybe<(
      { __typename?: 'EnergoElectricityResultItem' }
      & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoElectricityPartner' }
        & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoElectricityProduct' }
        & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    )>, partner: Maybe<(
      { __typename?: 'EnergoElectricityPartner' }
      & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    )>, breaker: Maybe<(
      { __typename?: 'EnergoBreaker' }
      & Pick<EnergoBreaker, 'id'>
      & { label: EnergoBreaker['name'] }
    )>, distributionRate: Maybe<(
      { __typename?: 'EnergoDistributionRate' }
      & Pick<EnergoDistributionRate, 'id' | 'description' | 'lowTariff'>
      & { label: EnergoDistributionRate['name'] }
    )> }
  )>, customerMyVehicleInsuranceContracts: Array<(
    { __typename?: 'VehicleInsuranceOrder' }
    & Pick<VehicleInsuranceOrder, 'id' | 'uuid' | 'createdAt' | 'status' | 'packagesInsuranceType' | 'selectedInsuranceType' | 'insurableInterest' | 'resultEmail' | 'phone' | 'calculationCurrentStep' | 'orderCurrentStep' | 'updatedAt' | 'hasSpecificPackages' | 'beginDate'>
    & { vehicle: (
      { __typename?: 'InsuredVehicle' }
      & Pick<InsuredVehicle, 'uuid' | 'vehicleType' | 'registrationNumber' | 'vin' | 'kilometersTotal' | 'ownedMoreThanAYear' | 'newVehicle' | 'contractualCarService' | 'vehicleValue' | 'vehicleValueWithVat' | 'firstRegistrationDate' | 'numberOfRegistrationCertificate' | 'vehicleSearchStatus'>
      & { expectedKilometersPerYear: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, manufacturer: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, fuel: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, engineDisplacement: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, maxPower: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, countOfSeats: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, maxWeight: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, modelLine: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, model: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, modelType: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, security: (
        { __typename?: 'VehicleSecurity' }
        & Pick<VehicleSecurity, 'none' | 'mechanical' | 'passiveSearch' | 'activeSearch' | 'glassVin' | 'immobilizer' | 'alarm'>
      ), carUsageMethod: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, additionalEquipment: Array<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    ), subjectInsured: (
      { __typename?: 'InsuredSubject' }
      & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
      & { cityPart: Maybe<(
        { __typename?: 'CityPart' }
        & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
      )>, address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, correspondenceAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, functionOfRepresentative: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    ), subjectCarHolder: Maybe<(
      { __typename?: 'InsuredSubject' }
      & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
      & { cityPart: Maybe<(
        { __typename?: 'CityPart' }
        & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
      )>, address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, correspondenceAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, functionOfRepresentative: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    )>, subjectCarOwner: Maybe<(
      { __typename?: 'InsuredSubject' }
      & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
      & { cityPart: Maybe<(
        { __typename?: 'CityPart' }
        & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
      )>, address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, correspondenceAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, functionOfRepresentative: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    )>, selectedOffer: Maybe<(
      { __typename?: 'VehicleInsuranceProductOffer' }
      & Pick<VehicleInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'paymentFrequency' | 'calculatedPaymentFrequency'>
      & { mtplProduct: Maybe<(
        { __typename?: 'VehicleInsuranceProduct' }
        & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, cascoProduct: Maybe<(
        { __typename?: 'VehicleInsuranceProduct' }
        & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, estimatedPrice: (
        { __typename?: 'VehicleInsuranceEstimatedPrice' }
        & Pick<VehicleInsuranceEstimatedPrice, 'yearly' | 'quarterly' | 'halfYearly' | 'monthly'>
      ) }
    )>, contactPerson: Maybe<(
      { __typename?: 'VehicleInsuranceContactPerson' }
      & Pick<VehicleInsuranceContactPerson, 'name' | 'surname' | 'email' | 'phone' | 'agreementProcessPersonalData' | 'agreementTermsAndConditions'>
    )>, contract: Maybe<(
      { __typename?: 'VehicleInsuranceContract' }
      & Pick<VehicleInsuranceContract, 'id' | 'uuid' | 'payed' | 'paymentUrl'>
      & { price: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency'>
      ), annualPrice: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency'>
      ), documents: Array<Maybe<(
        { __typename?: 'VehicleInsuranceContractDocument' }
        & Pick<VehicleInsuranceContractDocument, 'id' | 'name' | 'documentType'>
        & { documentCategory: (
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        ), file: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>>, paymentInfo: Maybe<(
        { __typename?: 'VehicleInsuranceContractPaymentInfo' }
        & Pick<VehicleInsuranceContractPaymentInfo, 'account' | 'bankCode' | 'constantSymbol' | 'specificSymbol' | 'variableSymbol' | 'message' | 'paymentUrl'>
        & { amount: Maybe<(
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'currency'>
        )>, qrCodeFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, casco: Maybe<(
        { __typename?: 'VehicleInsuranceProduct' }
        & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, mtpl: Maybe<(
        { __typename?: 'VehicleInsuranceProduct' }
        & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )> }
    )>, additionalOptimumRequirements: (
      { __typename?: 'VehicleInsuranceAdditionalRequirementsFromClient' }
      & Pick<VehicleInsuranceAdditionalRequirementsFromClient, 'glass' | 'animalAccident'>
    ) }
  )>, customerMyTravelInsuranceContracts: Array<(
    { __typename?: 'TravelInsuranceOrder' }
    & Pick<TravelInsuranceOrder, 'id' | 'uuid' | 'dateFrom' | 'dateTo' | 'insuranceType' | 'travelPurpose' | 'family' | 'resultEmail' | 'createdAt' | 'updatedAt' | 'status'>
    & { destination: (
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    ), policyHolder: Maybe<(
      { __typename?: 'Person' }
      & Pick<Person, 'id' | 'uuid' | 'name' | 'surname' | 'birthDate' | 'birthNumber' | 'phone' | 'email' | 'policyHolder' | 'insured' | 'age'>
      & { address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, nationality: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    )>, insuredPersons: Array<(
      { __typename?: 'Person' }
      & Pick<Person, 'id' | 'uuid' | 'name' | 'surname' | 'birthDate' | 'birthNumber' | 'phone' | 'email' | 'policyHolder' | 'insured' | 'age'>
      & { address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, nationality: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    )>, insuredSports: Array<(
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    )>, selectedOffer: Maybe<(
      { __typename?: 'TravelInsuranceProductOffer' }
      & Pick<TravelInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'pricePerDay' | 'hasUpsellOffer' | 'packageType'>
      & { product: Maybe<(
        { __typename?: 'TravelInsuranceProduct' }
        & Pick<TravelInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'medicalExpenseCover' | 'liabilityHealthCover' | 'liabilityPropertyCover' | 'baggageCover' | 'injuryCover' | 'type' | 'addedValue' | 'gift' | 'bonus' | 'label' | 'labelTooltip'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )> }
    )>, contract: Maybe<(
      { __typename?: 'TravelInsuranceContract' }
      & Pick<TravelInsuranceContract, 'id' | 'uuid' | 'payed'>
      & { price: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency'>
      ), annualPrice: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency'>
      ), documents: Array<Maybe<(
        { __typename?: 'TravelInsuranceContractDocument' }
        & Pick<TravelInsuranceContractDocument, 'id' | 'name' | 'documentType'>
        & { documentCategory: (
          { __typename?: 'TravelInsuranceCodeBook' }
          & Pick<TravelInsuranceCodeBook, 'id' | 'label'>
        ), file: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>>, paymentInfo: Maybe<(
        { __typename?: 'TravelInsuranceContractPaymentInfo' }
        & Pick<TravelInsuranceContractPaymentInfo, 'id' | 'account' | 'bankCode' | 'constantSymbol' | 'specificSymbol' | 'variableSymbol' | 'message' | 'paymentUrl'>
        & { amount: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'currency'>
        ), qrCodeFile: (
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        ) }
      )> }
    )> }
  )> }
);

export type GetCustomerWatchWolvesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCustomerWatchWolvesQuery = (
  { __typename?: 'Query' }
  & { customerMyWatchWolves: Array<(
    { __typename?: 'WatchWolf' }
    & Pick<WatchWolf, 'uuid' | 'type' | 'phone' | 'preferredEmail' | 'minSaving' | 'commitmentEnd' | 'vertical' | 'subVertical' | 'createdAt' | 'name'>
    & { energoCalculation: Maybe<(
      { __typename?: 'EnergoCalculation' }
      & Pick<EnergoCalculation, 'id' | 'uuid' | 'type' | 'source' | 'yearlyConsumption' | 'yearlyConsumptionUnit' | 'yearlyConsumptionLowTariff' | 'yearlyConsumptionLowTariffUnit' | 'yearlyPayment' | 'gasYearlyPayment' | 'gasYearlyConsumption' | 'gasYearlyConsumptionUnit' | 'gasMonthlyAdvances' | 'gasUnknownMonthlyAdvances' | 'monthlyAdvances' | 'unknownMonthlyAdvances' | 'email' | 'saving' | 'createdAt' | 'updatedAt' | 'lastViewed' | 'currentProductId' | 'currentProductName' | 'currentProductFollowingId' | 'unknownCurrentProduct' | 'gasCurrentProductId' | 'gasCurrentProductName' | 'gasCurrentProductFollowingId' | 'gasUnknownCurrentProduct' | 'phone' | 'individualPriceExpectedMonthly' | 'individualPriceExpectedMWhHighTariff' | 'individualPriceExpectedMWhLowTariff' | 'gasIndividualPriceExpectedMonthly' | 'gasIndividualPriceExpectedMWh' | 'individualOfferName' | 'reason'>
      & { address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, partner: Maybe<(
        { __typename?: 'EnergoElectricityPartner' }
        & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      )>, gasPartner: Maybe<(
        { __typename?: 'EnergoGasPartner' }
        & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      )>, gasUsages: Maybe<(
        { __typename?: 'GasUsages' }
        & Pick<GasUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
      )>, electricityUsages: Maybe<(
        { __typename?: 'ElectricityUsages' }
        & Pick<ElectricityUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
      )>, floorAreaRange: Maybe<(
        { __typename?: 'EnergoFloorAreaRangeCodebook' }
        & Pick<EnergoFloorAreaRangeCodebook, 'id'>
        & { label: EnergoFloorAreaRangeCodebook['name'] }
      )>, isolation: Maybe<(
        { __typename?: 'EnergoIsolationCodebook' }
        & Pick<EnergoIsolationCodebook, 'id'>
        & { label: EnergoIsolationCodebook['name'] }
      )>, personsCountRange: Maybe<(
        { __typename?: 'EnergoPersonsCountRangeCodebook' }
        & Pick<EnergoPersonsCountRangeCodebook, 'id'>
        & { label: EnergoPersonsCountRangeCodebook['name'] }
      )>, distributionRate: Maybe<(
        { __typename?: 'EnergoDistributionRate' }
        & Pick<EnergoDistributionRate, 'id' | 'name' | 'lowTariff'>
      )>, breaker: Maybe<(
        { __typename?: 'EnergoBreaker' }
        & Pick<EnergoBreaker, 'id' | 'name'>
      )>, standaloneInvoice: Maybe<(
        { __typename?: 'EnergoStandaloneInvoice' }
        & Pick<EnergoStandaloneInvoice, 'id' | 'uuid'>
      )>, bestResultItem: Maybe<(
        { __typename?: 'EnergoElectricityResultItem' }
        & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
        & { partner: (
          { __typename?: 'EnergoElectricityPartner' }
          & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
          & { rating: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), product: (
          { __typename?: 'EnergoElectricityProduct' }
          & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
        ), priceExpectedDetail: (
          { __typename?: 'EnergoElectricityResultPriceDetail' }
          & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ), priceResultDetail: (
          { __typename?: 'EnergoElectricityResultPriceDetail' }
          & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ) }
      )>, bestGasResultItem: Maybe<(
        { __typename?: 'EnergoGasResultItem' }
        & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
        & { partner: (
          { __typename?: 'EnergoGasPartner' }
          & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
          & { rating: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), product: (
          { __typename?: 'EnergoGasProduct' }
          & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
        ), priceExpectedDetail: (
          { __typename?: 'EnergoGasResultPriceDetail' }
          & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ), priceResultDetail: (
          { __typename?: 'EnergoGasResultPriceDetail' }
          & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ) }
      )> }
    )>, vehicleInsuranceOrder: Maybe<(
      { __typename?: 'VehicleInsuranceOrder' }
      & Pick<VehicleInsuranceOrder, 'id' | 'uuid' | 'createdAt' | 'status' | 'packagesInsuranceType' | 'selectedInsuranceType' | 'insurableInterest' | 'resultEmail' | 'phone' | 'calculationCurrentStep' | 'orderCurrentStep' | 'updatedAt' | 'hasSpecificPackages' | 'beginDate'>
      & { vehicle: (
        { __typename?: 'InsuredVehicle' }
        & Pick<InsuredVehicle, 'uuid' | 'vehicleType' | 'registrationNumber' | 'vin' | 'kilometersTotal' | 'ownedMoreThanAYear' | 'newVehicle' | 'contractualCarService' | 'vehicleValue' | 'vehicleValueWithVat' | 'firstRegistrationDate' | 'numberOfRegistrationCertificate' | 'vehicleSearchStatus'>
        & { expectedKilometersPerYear: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, manufacturer: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, fuel: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, engineDisplacement: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, maxPower: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, countOfSeats: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, maxWeight: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, modelLine: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, model: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, modelType: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, security: (
          { __typename?: 'VehicleSecurity' }
          & Pick<VehicleSecurity, 'none' | 'mechanical' | 'passiveSearch' | 'activeSearch' | 'glassVin' | 'immobilizer' | 'alarm'>
        ), carUsageMethod: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, additionalEquipment: Array<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )> }
      ), subjectInsured: (
        { __typename?: 'InsuredSubject' }
        & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
        & { cityPart: Maybe<(
          { __typename?: 'CityPart' }
          & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
        )>, address: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, correspondenceAddress: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, functionOfRepresentative: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )> }
      ), subjectCarHolder: Maybe<(
        { __typename?: 'InsuredSubject' }
        & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
        & { cityPart: Maybe<(
          { __typename?: 'CityPart' }
          & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
        )>, address: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, correspondenceAddress: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, functionOfRepresentative: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )> }
      )>, subjectCarOwner: Maybe<(
        { __typename?: 'InsuredSubject' }
        & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
        & { cityPart: Maybe<(
          { __typename?: 'CityPart' }
          & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
        )>, address: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, correspondenceAddress: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, functionOfRepresentative: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )> }
      )>, selectedOffer: Maybe<(
        { __typename?: 'VehicleInsuranceProductOffer' }
        & Pick<VehicleInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'paymentFrequency' | 'calculatedPaymentFrequency'>
        & { mtplProduct: Maybe<(
          { __typename?: 'VehicleInsuranceProduct' }
          & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, cascoProduct: Maybe<(
          { __typename?: 'VehicleInsuranceProduct' }
          & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, estimatedPrice: (
          { __typename?: 'VehicleInsuranceEstimatedPrice' }
          & Pick<VehicleInsuranceEstimatedPrice, 'yearly' | 'quarterly' | 'halfYearly' | 'monthly'>
        ) }
      )>, contactPerson: Maybe<(
        { __typename?: 'VehicleInsuranceContactPerson' }
        & Pick<VehicleInsuranceContactPerson, 'name' | 'surname' | 'email' | 'phone' | 'agreementProcessPersonalData' | 'agreementTermsAndConditions'>
      )>, contract: Maybe<(
        { __typename?: 'VehicleInsuranceContract' }
        & Pick<VehicleInsuranceContract, 'id' | 'uuid' | 'payed' | 'paymentUrl'>
        & { price: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'currency'>
        ), annualPrice: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'currency'>
        ), documents: Array<Maybe<(
          { __typename?: 'VehicleInsuranceContractDocument' }
          & Pick<VehicleInsuranceContractDocument, 'id' | 'name' | 'documentType'>
          & { documentCategory: (
            { __typename?: 'CodeBook' }
            & Pick<CodeBook, 'id' | 'label'>
          ), file: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>>, paymentInfo: Maybe<(
          { __typename?: 'VehicleInsuranceContractPaymentInfo' }
          & Pick<VehicleInsuranceContractPaymentInfo, 'account' | 'bankCode' | 'constantSymbol' | 'specificSymbol' | 'variableSymbol' | 'message' | 'paymentUrl'>
          & { amount: Maybe<(
            { __typename?: 'Money' }
            & Pick<Money, 'amount' | 'currency'>
          )>, qrCodeFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, casco: Maybe<(
          { __typename?: 'VehicleInsuranceProduct' }
          & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, mtpl: Maybe<(
          { __typename?: 'VehicleInsuranceProduct' }
          & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )> }
      )>, additionalOptimumRequirements: (
        { __typename?: 'VehicleInsuranceAdditionalRequirementsFromClient' }
        & Pick<VehicleInsuranceAdditionalRequirementsFromClient, 'glass' | 'animalAccident'>
      ) }
    )> }
  )>, customerMyCalculations: Array<(
    { __typename?: 'CustomerZoneCalculationDetail' }
    & Pick<CustomerZoneCalculationDetail, 'uuid' | 'type' | 'source' | 'yearlyConsumption' | 'yearlyConsumptionUnit' | 'yearlyConsumptionLowTariff' | 'yearlyConsumptionLowTariffUnit' | 'yearlyPayment' | 'gasYearlyPayment' | 'gasYearlyConsumption' | 'gasYearlyConsumptionUnit' | 'gasMonthlyAdvances' | 'gasUnknownMonthlyAdvances' | 'monthlyAdvances' | 'unknownMonthlyAdvances' | 'email' | 'saving' | 'createdAt' | 'updatedAt' | 'lastViewed' | 'currentProductId' | 'currentProductName' | 'currentProductFollowingId' | 'unknownCurrentProduct' | 'gasCurrentProductId' | 'gasCurrentProductName' | 'gasCurrentProductFollowingId' | 'gasUnknownCurrentProduct' | 'adminLastChangeAt'>
    & { address: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
      & { extended: Maybe<(
        { __typename?: 'SmartAddressExtendedInfo' }
        & Pick<SmartAddressExtendedInfo, 'id'>
        & { objectType: Maybe<(
          { __typename?: 'SmartAddressObjectType' }
          & Pick<SmartAddressObjectType, 'id' | 'nazev'>
        )> }
      )>, extendedV2: Maybe<(
        { __typename?: 'SmartAddressSdkExtendedInfo' }
        & { administrativniUdaje: Maybe<(
          { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
          & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )> }
      )> }
    )>, partner: Maybe<(
      { __typename?: 'EnergoElectricityPartner' }
      & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    )>, gasPartner: Maybe<(
      { __typename?: 'EnergoGasPartner' }
      & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    )>, gasUsages: Maybe<(
      { __typename?: 'GasUsages' }
      & Pick<GasUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
    )>, electricityUsages: Maybe<(
      { __typename?: 'ElectricityUsages' }
      & Pick<ElectricityUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
    )>, floorAreaRange: Maybe<(
      { __typename?: 'EnergoFloorAreaRangeCodebook' }
      & Pick<EnergoFloorAreaRangeCodebook, 'id'>
      & { label: EnergoFloorAreaRangeCodebook['name'] }
    )>, isolation: Maybe<(
      { __typename?: 'EnergoIsolationCodebook' }
      & Pick<EnergoIsolationCodebook, 'id'>
      & { label: EnergoIsolationCodebook['name'] }
    )>, personsCountRange: Maybe<(
      { __typename?: 'EnergoPersonsCountRangeCodebook' }
      & Pick<EnergoPersonsCountRangeCodebook, 'id'>
      & { label: EnergoPersonsCountRangeCodebook['name'] }
    )>, distributionRate: Maybe<(
      { __typename?: 'EnergoDistributionRate' }
      & Pick<EnergoDistributionRate, 'id' | 'name' | 'lowTariff'>
    )>, breaker: Maybe<(
      { __typename?: 'EnergoBreaker' }
      & Pick<EnergoBreaker, 'id' | 'name'>
    )>, standaloneInvoice: Maybe<(
      { __typename?: 'EnergoStandaloneInvoice' }
      & Pick<EnergoStandaloneInvoice, 'id' | 'uuid'>
    )>, bestResultItem: Maybe<(
      { __typename?: 'EnergoElectricityResultItem' }
      & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoElectricityPartner' }
        & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoElectricityProduct' }
        & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    )>, bestGasResultItem: Maybe<(
      { __typename?: 'EnergoGasResultItem' }
      & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoGasPartner' }
        & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoGasProduct' }
        & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    )>, energoOffers: Array<(
      { __typename?: 'EnergoOffer' }
      & Pick<EnergoOffer, 'id' | 'status' | 'validTo' | 'acceptedAt'>
      & { benefitResponse: Maybe<(
        { __typename?: 'Benefit' }
        & Pick<Benefit, 'id' | 'uuid' | 'name' | 'validFrom' | 'validTo' | 'template' | 'perex' | 'text' | 'buttonText'>
        & { profileBenefit: (
          { __typename?: 'ProfileBenefit' }
          & Pick<ProfileBenefit, 'id' | 'name' | 'surname' | 'status' | 'phone'>
          & { attachments: Array<(
            { __typename?: 'BenefitAttachment' }
            & Pick<BenefitAttachment, 'id'>
            & { file: (
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            ) }
          )> }
        ), image: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, electricityResultItem: Maybe<(
        { __typename?: 'EnergoElectricityResultItem' }
        & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
        & { partner: (
          { __typename?: 'EnergoElectricityPartner' }
          & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
          & { rating: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), product: (
          { __typename?: 'EnergoElectricityProduct' }
          & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
        ), priceExpectedDetail: (
          { __typename?: 'EnergoElectricityResultPriceDetail' }
          & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ), priceResultDetail: (
          { __typename?: 'EnergoElectricityResultPriceDetail' }
          & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ) }
      )>, gasResultItem: Maybe<(
        { __typename?: 'EnergoGasResultItem' }
        & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
        & { partner: (
          { __typename?: 'EnergoGasPartner' }
          & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
          & { rating: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), product: (
          { __typename?: 'EnergoGasProduct' }
          & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
        ), priceExpectedDetail: (
          { __typename?: 'EnergoGasResultPriceDetail' }
          & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ), priceResultDetail: (
          { __typename?: 'EnergoGasResultPriceDetail' }
          & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ) }
      )>, acceptedBy: Maybe<(
        { __typename?: 'ProfileUser' }
        & Pick<ProfileUser, 'email' | 'name'>
      )> }
    )>, energoContract: (
      { __typename?: 'EnergoEnergoContract' }
      & Pick<EnergoEnergoContract, 'id' | 'name' | 'surname' | 'phone' | 'email' | 'customerNote' | 'status' | 'preferredPaymentMethod' | 'sendInvoiceMethod' | 'invoiceUploaded' | 'supplyStartDate' | 'expectedSupplyStartDate' | 'methodOfSendingContract'>
      & { supplyAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, contactAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, permanentAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, customSupplyAddress: Maybe<(
        { __typename?: 'CustomAddress' }
        & Pick<CustomAddress, 'address' | 'zipCode' | 'city'>
      )>, customContactAddress: Maybe<(
        { __typename?: 'CustomAddress' }
        & Pick<CustomAddress, 'address' | 'zipCode' | 'city'>
      )>, customPermanentAddress: Maybe<(
        { __typename?: 'CustomAddress' }
        & Pick<CustomAddress, 'address' | 'zipCode' | 'city'>
      )>, attachments: Array<(
        { __typename?: 'EnergoEnergoContractAttachment' }
        & Pick<EnergoEnergoContractAttachment, 'id' | 'type' | 'createdAt'>
        & { file: (
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        ) }
      )>, energoOffer: Maybe<(
        { __typename?: 'EnergoOffer' }
        & Pick<EnergoOffer, 'id' | 'status' | 'validTo' | 'acceptedAt'>
        & { benefitResponse: Maybe<(
          { __typename?: 'Benefit' }
          & Pick<Benefit, 'id' | 'uuid' | 'name' | 'validFrom' | 'validTo' | 'template' | 'perex' | 'text' | 'buttonText'>
          & { profileBenefit: (
            { __typename?: 'ProfileBenefit' }
            & Pick<ProfileBenefit, 'id' | 'name' | 'surname' | 'status' | 'phone'>
            & { attachments: Array<(
              { __typename?: 'BenefitAttachment' }
              & Pick<BenefitAttachment, 'id'>
              & { file: (
                { __typename?: 'File' }
                & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
              ) }
            )> }
          ), image: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, electricityResultItem: Maybe<(
          { __typename?: 'EnergoElectricityResultItem' }
          & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
          & { partner: (
            { __typename?: 'EnergoElectricityPartner' }
            & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
            & { rating: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), product: (
            { __typename?: 'EnergoElectricityProduct' }
            & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
          ), priceExpectedDetail: (
            { __typename?: 'EnergoElectricityResultPriceDetail' }
            & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
          ), priceResultDetail: (
            { __typename?: 'EnergoElectricityResultPriceDetail' }
            & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
          ) }
        )>, gasResultItem: Maybe<(
          { __typename?: 'EnergoGasResultItem' }
          & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
          & { partner: (
            { __typename?: 'EnergoGasPartner' }
            & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
            & { rating: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), product: (
            { __typename?: 'EnergoGasProduct' }
            & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
          ), priceExpectedDetail: (
            { __typename?: 'EnergoGasResultPriceDetail' }
            & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
          ), priceResultDetail: (
            { __typename?: 'EnergoGasResultPriceDetail' }
            & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
          ) }
        )>, acceptedBy: Maybe<(
          { __typename?: 'ProfileUser' }
          & Pick<ProfileUser, 'email' | 'name'>
        )> }
      )> }
    ), adminLastChangeBy: Maybe<(
      { __typename?: 'ProfileUser' }
      & Pick<ProfileUser, 'email' | 'name'>
    )>, watchWolf: Maybe<(
      { __typename?: 'WatchWolfPreview' }
      & Pick<WatchWolfPreview, 'uuid'>
    )> }
  )> }
);

export type CustomerMyVehicleInsuranceOrdersQueryVariables = Exact<{
  cursor?: InputMaybe<Scalars['Int']['input']>;
}>;


export type CustomerMyVehicleInsuranceOrdersQuery = (
  { __typename?: 'Query' }
  & { customerMyVehicleInsuranceOrders: Array<(
    { __typename?: 'VehicleInsuranceOrderResult' }
    & Pick<VehicleInsuranceOrderResult, 'id' | 'lowestPrice'>
    & { order: (
      { __typename?: 'VehicleInsuranceOrder' }
      & Pick<VehicleInsuranceOrder, 'id' | 'uuid' | 'createdAt' | 'status' | 'packagesInsuranceType' | 'selectedInsuranceType' | 'insurableInterest' | 'resultEmail' | 'phone' | 'calculationCurrentStep' | 'orderCurrentStep' | 'updatedAt' | 'hasSpecificPackages' | 'beginDate'>
      & { vehicle: (
        { __typename?: 'InsuredVehicle' }
        & Pick<InsuredVehicle, 'uuid' | 'vehicleType' | 'registrationNumber' | 'vin' | 'kilometersTotal' | 'ownedMoreThanAYear' | 'newVehicle' | 'contractualCarService' | 'vehicleValue' | 'vehicleValueWithVat' | 'firstRegistrationDate' | 'numberOfRegistrationCertificate' | 'vehicleSearchStatus'>
        & { expectedKilometersPerYear: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, manufacturer: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, fuel: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, engineDisplacement: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, maxPower: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, countOfSeats: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, maxWeight: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, modelLine: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, model: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, modelType: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, security: (
          { __typename?: 'VehicleSecurity' }
          & Pick<VehicleSecurity, 'none' | 'mechanical' | 'passiveSearch' | 'activeSearch' | 'glassVin' | 'immobilizer' | 'alarm'>
        ), carUsageMethod: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, additionalEquipment: Array<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )> }
      ), subjectInsured: (
        { __typename?: 'InsuredSubject' }
        & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
        & { cityPart: Maybe<(
          { __typename?: 'CityPart' }
          & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
        )>, address: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, correspondenceAddress: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, functionOfRepresentative: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )> }
      ), subjectCarHolder: Maybe<(
        { __typename?: 'InsuredSubject' }
        & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
        & { cityPart: Maybe<(
          { __typename?: 'CityPart' }
          & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
        )>, address: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, correspondenceAddress: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, functionOfRepresentative: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )> }
      )>, subjectCarOwner: Maybe<(
        { __typename?: 'InsuredSubject' }
        & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
        & { cityPart: Maybe<(
          { __typename?: 'CityPart' }
          & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
        )>, address: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, correspondenceAddress: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, functionOfRepresentative: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )> }
      )>, selectedOffer: Maybe<(
        { __typename?: 'VehicleInsuranceProductOffer' }
        & Pick<VehicleInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'paymentFrequency' | 'calculatedPaymentFrequency'>
        & { mtplProduct: Maybe<(
          { __typename?: 'VehicleInsuranceProduct' }
          & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, cascoProduct: Maybe<(
          { __typename?: 'VehicleInsuranceProduct' }
          & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, estimatedPrice: (
          { __typename?: 'VehicleInsuranceEstimatedPrice' }
          & Pick<VehicleInsuranceEstimatedPrice, 'yearly' | 'quarterly' | 'halfYearly' | 'monthly'>
        ) }
      )>, contactPerson: Maybe<(
        { __typename?: 'VehicleInsuranceContactPerson' }
        & Pick<VehicleInsuranceContactPerson, 'name' | 'surname' | 'email' | 'phone' | 'agreementProcessPersonalData' | 'agreementTermsAndConditions'>
      )>, contract: Maybe<(
        { __typename?: 'VehicleInsuranceContract' }
        & Pick<VehicleInsuranceContract, 'id' | 'uuid' | 'payed' | 'paymentUrl'>
        & { price: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'currency'>
        ), annualPrice: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'currency'>
        ), documents: Array<Maybe<(
          { __typename?: 'VehicleInsuranceContractDocument' }
          & Pick<VehicleInsuranceContractDocument, 'id' | 'name' | 'documentType'>
          & { documentCategory: (
            { __typename?: 'CodeBook' }
            & Pick<CodeBook, 'id' | 'label'>
          ), file: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>>, paymentInfo: Maybe<(
          { __typename?: 'VehicleInsuranceContractPaymentInfo' }
          & Pick<VehicleInsuranceContractPaymentInfo, 'account' | 'bankCode' | 'constantSymbol' | 'specificSymbol' | 'variableSymbol' | 'message' | 'paymentUrl'>
          & { amount: Maybe<(
            { __typename?: 'Money' }
            & Pick<Money, 'amount' | 'currency'>
          )>, qrCodeFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, casco: Maybe<(
          { __typename?: 'VehicleInsuranceProduct' }
          & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, mtpl: Maybe<(
          { __typename?: 'VehicleInsuranceProduct' }
          & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )> }
      )>, additionalOptimumRequirements: (
        { __typename?: 'VehicleInsuranceAdditionalRequirementsFromClient' }
        & Pick<VehicleInsuranceAdditionalRequirementsFromClient, 'glass' | 'animalAccident'>
      ) }
    ), packages: Array<(
      { __typename?: 'VehicleInsurancePackage' }
      & Pick<VehicleInsurancePackage, 'id' | 'uuid' | 'type' | 'name' | 'protectionCover' | 'lowestPrice' | 'participation' | 'mobileOrder'>
      & { productOffers: Array<(
        { __typename?: 'VehicleInsuranceProductOffer' }
        & Pick<VehicleInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'paymentFrequency' | 'calculatedPaymentFrequency'>
        & { mtplProduct: Maybe<(
          { __typename?: 'VehicleInsuranceProduct' }
          & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, cascoProduct: Maybe<(
          { __typename?: 'VehicleInsuranceProduct' }
          & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, estimatedPrice: (
          { __typename?: 'VehicleInsuranceEstimatedPrice' }
          & Pick<VehicleInsuranceEstimatedPrice, 'yearly' | 'quarterly' | 'halfYearly' | 'monthly'>
        ) }
      )>, additionalRequirements: (
        { __typename?: 'VehicleInsuranceAdditionalRequirements' }
        & Pick<VehicleInsuranceAdditionalRequirements, 'id'>
        & { glass: (
          { __typename?: 'VehicleInsuranceGlassRequirement' }
          & Pick<VehicleInsuranceGlassRequirement, 'enabled' | 'value'>
        ), animalAccident: (
          { __typename?: 'VehicleInsuranceAnimalAccidentRequirement' }
          & Pick<VehicleInsuranceAnimalAccidentRequirement, 'enabled' | 'value'>
        ) }
      ) }
    )>, cheapestOffer: Maybe<(
      { __typename?: 'VehicleInsuranceProductOffer' }
      & Pick<VehicleInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'paymentFrequency' | 'calculatedPaymentFrequency'>
      & { mtplProduct: Maybe<(
        { __typename?: 'VehicleInsuranceProduct' }
        & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, cascoProduct: Maybe<(
        { __typename?: 'VehicleInsuranceProduct' }
        & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, estimatedPrice: (
        { __typename?: 'VehicleInsuranceEstimatedPrice' }
        & Pick<VehicleInsuranceEstimatedPrice, 'yearly' | 'quarterly' | 'halfYearly' | 'monthly'>
      ) }
    )> }
  )> }
);

export type CustomerGetEnergoCalculationQueryVariables = Exact<{
  calculationUuid: Scalars['String']['input'];
}>;


export type CustomerGetEnergoCalculationQuery = (
  { __typename?: 'Query' }
  & { customerGetEnergoCalculation: (
    { __typename?: 'CustomerZoneCalculationDetail' }
    & Pick<CustomerZoneCalculationDetail, 'uuid' | 'type' | 'source' | 'yearlyConsumption' | 'yearlyConsumptionUnit' | 'yearlyConsumptionLowTariff' | 'yearlyConsumptionLowTariffUnit' | 'yearlyPayment' | 'gasYearlyPayment' | 'gasYearlyConsumption' | 'gasYearlyConsumptionUnit' | 'gasMonthlyAdvances' | 'gasUnknownMonthlyAdvances' | 'monthlyAdvances' | 'unknownMonthlyAdvances' | 'email' | 'saving' | 'createdAt' | 'updatedAt' | 'lastViewed' | 'currentProductId' | 'currentProductName' | 'currentProductFollowingId' | 'unknownCurrentProduct' | 'gasCurrentProductId' | 'gasCurrentProductName' | 'gasCurrentProductFollowingId' | 'gasUnknownCurrentProduct' | 'adminLastChangeAt'>
    & { address: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
      & { extended: Maybe<(
        { __typename?: 'SmartAddressExtendedInfo' }
        & Pick<SmartAddressExtendedInfo, 'id'>
        & { objectType: Maybe<(
          { __typename?: 'SmartAddressObjectType' }
          & Pick<SmartAddressObjectType, 'id' | 'nazev'>
        )> }
      )>, extendedV2: Maybe<(
        { __typename?: 'SmartAddressSdkExtendedInfo' }
        & { administrativniUdaje: Maybe<(
          { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
          & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )> }
      )> }
    )>, partner: Maybe<(
      { __typename?: 'EnergoElectricityPartner' }
      & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    )>, gasPartner: Maybe<(
      { __typename?: 'EnergoGasPartner' }
      & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    )>, gasUsages: Maybe<(
      { __typename?: 'GasUsages' }
      & Pick<GasUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
    )>, electricityUsages: Maybe<(
      { __typename?: 'ElectricityUsages' }
      & Pick<ElectricityUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
    )>, floorAreaRange: Maybe<(
      { __typename?: 'EnergoFloorAreaRangeCodebook' }
      & Pick<EnergoFloorAreaRangeCodebook, 'id'>
      & { label: EnergoFloorAreaRangeCodebook['name'] }
    )>, isolation: Maybe<(
      { __typename?: 'EnergoIsolationCodebook' }
      & Pick<EnergoIsolationCodebook, 'id'>
      & { label: EnergoIsolationCodebook['name'] }
    )>, personsCountRange: Maybe<(
      { __typename?: 'EnergoPersonsCountRangeCodebook' }
      & Pick<EnergoPersonsCountRangeCodebook, 'id'>
      & { label: EnergoPersonsCountRangeCodebook['name'] }
    )>, distributionRate: Maybe<(
      { __typename?: 'EnergoDistributionRate' }
      & Pick<EnergoDistributionRate, 'id' | 'name' | 'lowTariff'>
    )>, breaker: Maybe<(
      { __typename?: 'EnergoBreaker' }
      & Pick<EnergoBreaker, 'id' | 'name'>
    )>, standaloneInvoice: Maybe<(
      { __typename?: 'EnergoStandaloneInvoice' }
      & Pick<EnergoStandaloneInvoice, 'id' | 'uuid'>
    )>, bestResultItem: Maybe<(
      { __typename?: 'EnergoElectricityResultItem' }
      & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoElectricityPartner' }
        & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoElectricityProduct' }
        & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    )>, bestGasResultItem: Maybe<(
      { __typename?: 'EnergoGasResultItem' }
      & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoGasPartner' }
        & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoGasProduct' }
        & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    )>, energoOffers: Array<(
      { __typename?: 'EnergoOffer' }
      & Pick<EnergoOffer, 'id' | 'status' | 'validTo' | 'acceptedAt'>
      & { benefitResponse: Maybe<(
        { __typename?: 'Benefit' }
        & Pick<Benefit, 'id' | 'uuid' | 'name' | 'validFrom' | 'validTo' | 'template' | 'perex' | 'text' | 'buttonText'>
        & { profileBenefit: (
          { __typename?: 'ProfileBenefit' }
          & Pick<ProfileBenefit, 'id' | 'name' | 'surname' | 'status' | 'phone'>
          & { attachments: Array<(
            { __typename?: 'BenefitAttachment' }
            & Pick<BenefitAttachment, 'id'>
            & { file: (
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            ) }
          )> }
        ), image: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, electricityResultItem: Maybe<(
        { __typename?: 'EnergoElectricityResultItem' }
        & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
        & { partner: (
          { __typename?: 'EnergoElectricityPartner' }
          & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
          & { rating: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), product: (
          { __typename?: 'EnergoElectricityProduct' }
          & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
        ), priceExpectedDetail: (
          { __typename?: 'EnergoElectricityResultPriceDetail' }
          & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ), priceResultDetail: (
          { __typename?: 'EnergoElectricityResultPriceDetail' }
          & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ) }
      )>, gasResultItem: Maybe<(
        { __typename?: 'EnergoGasResultItem' }
        & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
        & { partner: (
          { __typename?: 'EnergoGasPartner' }
          & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
          & { rating: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), product: (
          { __typename?: 'EnergoGasProduct' }
          & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
        ), priceExpectedDetail: (
          { __typename?: 'EnergoGasResultPriceDetail' }
          & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ), priceResultDetail: (
          { __typename?: 'EnergoGasResultPriceDetail' }
          & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ) }
      )>, acceptedBy: Maybe<(
        { __typename?: 'ProfileUser' }
        & Pick<ProfileUser, 'email' | 'name'>
      )> }
    )>, energoContract: (
      { __typename?: 'EnergoEnergoContract' }
      & Pick<EnergoEnergoContract, 'id' | 'name' | 'surname' | 'phone' | 'email' | 'customerNote' | 'status' | 'preferredPaymentMethod' | 'sendInvoiceMethod' | 'invoiceUploaded' | 'supplyStartDate' | 'expectedSupplyStartDate' | 'methodOfSendingContract'>
      & { supplyAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, contactAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, permanentAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, customSupplyAddress: Maybe<(
        { __typename?: 'CustomAddress' }
        & Pick<CustomAddress, 'address' | 'zipCode' | 'city'>
      )>, customContactAddress: Maybe<(
        { __typename?: 'CustomAddress' }
        & Pick<CustomAddress, 'address' | 'zipCode' | 'city'>
      )>, customPermanentAddress: Maybe<(
        { __typename?: 'CustomAddress' }
        & Pick<CustomAddress, 'address' | 'zipCode' | 'city'>
      )>, attachments: Array<(
        { __typename?: 'EnergoEnergoContractAttachment' }
        & Pick<EnergoEnergoContractAttachment, 'id' | 'type' | 'createdAt'>
        & { file: (
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        ) }
      )>, energoOffer: Maybe<(
        { __typename?: 'EnergoOffer' }
        & Pick<EnergoOffer, 'id' | 'status' | 'validTo' | 'acceptedAt'>
        & { benefitResponse: Maybe<(
          { __typename?: 'Benefit' }
          & Pick<Benefit, 'id' | 'uuid' | 'name' | 'validFrom' | 'validTo' | 'template' | 'perex' | 'text' | 'buttonText'>
          & { profileBenefit: (
            { __typename?: 'ProfileBenefit' }
            & Pick<ProfileBenefit, 'id' | 'name' | 'surname' | 'status' | 'phone'>
            & { attachments: Array<(
              { __typename?: 'BenefitAttachment' }
              & Pick<BenefitAttachment, 'id'>
              & { file: (
                { __typename?: 'File' }
                & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
              ) }
            )> }
          ), image: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, electricityResultItem: Maybe<(
          { __typename?: 'EnergoElectricityResultItem' }
          & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
          & { partner: (
            { __typename?: 'EnergoElectricityPartner' }
            & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
            & { rating: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), product: (
            { __typename?: 'EnergoElectricityProduct' }
            & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
          ), priceExpectedDetail: (
            { __typename?: 'EnergoElectricityResultPriceDetail' }
            & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
          ), priceResultDetail: (
            { __typename?: 'EnergoElectricityResultPriceDetail' }
            & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
          ) }
        )>, gasResultItem: Maybe<(
          { __typename?: 'EnergoGasResultItem' }
          & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
          & { partner: (
            { __typename?: 'EnergoGasPartner' }
            & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
            & { rating: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), product: (
            { __typename?: 'EnergoGasProduct' }
            & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
          ), priceExpectedDetail: (
            { __typename?: 'EnergoGasResultPriceDetail' }
            & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
          ), priceResultDetail: (
            { __typename?: 'EnergoGasResultPriceDetail' }
            & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
          ) }
        )>, acceptedBy: Maybe<(
          { __typename?: 'ProfileUser' }
          & Pick<ProfileUser, 'email' | 'name'>
        )> }
      )> }
    ), adminLastChangeBy: Maybe<(
      { __typename?: 'ProfileUser' }
      & Pick<ProfileUser, 'email' | 'name'>
    )>, watchWolf: Maybe<(
      { __typename?: 'WatchWolfPreview' }
      & Pick<WatchWolfPreview, 'uuid'>
    )> }
  ) }
);

export type EnergoBreakerFragment = (
  { __typename?: 'EnergoBreaker' }
  & Pick<EnergoBreaker, 'id' | 'name'>
);

export type EnergoDistributionRateFragment = (
  { __typename?: 'EnergoDistributionRate' }
  & Pick<EnergoDistributionRate, 'id' | 'name' | 'lowTariff'>
);

export type EnergoCalculationFragment = (
  { __typename?: 'EnergoCalculation' }
  & Pick<EnergoCalculation, 'id' | 'uuid' | 'type' | 'source' | 'yearlyConsumption' | 'yearlyConsumptionUnit' | 'yearlyConsumptionLowTariff' | 'yearlyConsumptionLowTariffUnit' | 'yearlyPayment' | 'gasYearlyPayment' | 'gasYearlyConsumption' | 'gasYearlyConsumptionUnit' | 'gasMonthlyAdvances' | 'gasUnknownMonthlyAdvances' | 'monthlyAdvances' | 'unknownMonthlyAdvances' | 'email' | 'saving' | 'createdAt' | 'updatedAt' | 'lastViewed' | 'currentProductId' | 'currentProductName' | 'currentProductFollowingId' | 'unknownCurrentProduct' | 'gasCurrentProductId' | 'gasCurrentProductName' | 'gasCurrentProductFollowingId' | 'gasUnknownCurrentProduct' | 'phone' | 'individualPriceExpectedMonthly' | 'individualPriceExpectedMWhHighTariff' | 'individualPriceExpectedMWhLowTariff' | 'gasIndividualPriceExpectedMonthly' | 'gasIndividualPriceExpectedMWh' | 'individualOfferName' | 'reason'>
  & { address: Maybe<(
    { __typename?: 'Address' }
    & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
    & { extended: Maybe<(
      { __typename?: 'SmartAddressExtendedInfo' }
      & Pick<SmartAddressExtendedInfo, 'id'>
      & { objectType: Maybe<(
        { __typename?: 'SmartAddressObjectType' }
        & Pick<SmartAddressObjectType, 'id' | 'nazev'>
      )> }
    )>, extendedV2: Maybe<(
      { __typename?: 'SmartAddressSdkExtendedInfo' }
      & { administrativniUdaje: Maybe<(
        { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
        & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
        & { objectType: Maybe<(
          { __typename?: 'SmartAddressObjectType' }
          & Pick<SmartAddressObjectType, 'id' | 'nazev'>
        )> }
      )> }
    )> }
  )>, partner: Maybe<(
    { __typename?: 'EnergoElectricityPartner' }
    & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
    & { rating: Maybe<(
      { __typename?: 'NewRating' }
      & Pick<NewRating, 'id' | 'total'>
      & { categories: Array<Maybe<(
        { __typename?: 'RatingCategory' }
        & Pick<RatingCategory, 'id' | 'label' | 'value'>
      )>> }
    )> }
  )>, gasPartner: Maybe<(
    { __typename?: 'EnergoGasPartner' }
    & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
    & { rating: Maybe<(
      { __typename?: 'NewRating' }
      & Pick<NewRating, 'id' | 'total'>
      & { categories: Array<Maybe<(
        { __typename?: 'RatingCategory' }
        & Pick<RatingCategory, 'id' | 'label' | 'value'>
      )>> }
    )> }
  )>, gasUsages: Maybe<(
    { __typename?: 'GasUsages' }
    & Pick<GasUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
  )>, electricityUsages: Maybe<(
    { __typename?: 'ElectricityUsages' }
    & Pick<ElectricityUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
  )>, floorAreaRange: Maybe<(
    { __typename?: 'EnergoFloorAreaRangeCodebook' }
    & Pick<EnergoFloorAreaRangeCodebook, 'id'>
    & { label: EnergoFloorAreaRangeCodebook['name'] }
  )>, isolation: Maybe<(
    { __typename?: 'EnergoIsolationCodebook' }
    & Pick<EnergoIsolationCodebook, 'id'>
    & { label: EnergoIsolationCodebook['name'] }
  )>, personsCountRange: Maybe<(
    { __typename?: 'EnergoPersonsCountRangeCodebook' }
    & Pick<EnergoPersonsCountRangeCodebook, 'id'>
    & { label: EnergoPersonsCountRangeCodebook['name'] }
  )>, distributionRate: Maybe<(
    { __typename?: 'EnergoDistributionRate' }
    & Pick<EnergoDistributionRate, 'id' | 'name' | 'lowTariff'>
  )>, breaker: Maybe<(
    { __typename?: 'EnergoBreaker' }
    & Pick<EnergoBreaker, 'id' | 'name'>
  )>, standaloneInvoice: Maybe<(
    { __typename?: 'EnergoStandaloneInvoice' }
    & Pick<EnergoStandaloneInvoice, 'id' | 'uuid'>
  )>, bestResultItem: Maybe<(
    { __typename?: 'EnergoElectricityResultItem' }
    & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
    & { partner: (
      { __typename?: 'EnergoElectricityPartner' }
      & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    ), product: (
      { __typename?: 'EnergoElectricityProduct' }
      & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
    ), priceExpectedDetail: (
      { __typename?: 'EnergoElectricityResultPriceDetail' }
      & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
    ), priceResultDetail: (
      { __typename?: 'EnergoElectricityResultPriceDetail' }
      & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
    ) }
  )>, bestGasResultItem: Maybe<(
    { __typename?: 'EnergoGasResultItem' }
    & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
    & { partner: (
      { __typename?: 'EnergoGasPartner' }
      & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    ), product: (
      { __typename?: 'EnergoGasProduct' }
      & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
    ), priceExpectedDetail: (
      { __typename?: 'EnergoGasResultPriceDetail' }
      & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
    ), priceResultDetail: (
      { __typename?: 'EnergoGasResultPriceDetail' }
      & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
    ) }
  )> }
);

export type PublicEnergoCalculationFragment = (
  { __typename?: 'PublicEnergoCalculation' }
  & Pick<PublicEnergoCalculation, 'id' | 'uuid' | 'type' | 'source' | 'yearlyConsumption' | 'yearlyConsumptionUnit' | 'yearlyConsumptionLowTariff' | 'yearlyConsumptionLowTariffUnit' | 'yearlyPayment' | 'gasYearlyPayment' | 'gasYearlyConsumption' | 'gasYearlyConsumptionUnit' | 'gasMonthlyAdvances' | 'gasUnknownMonthlyAdvances' | 'monthlyAdvances' | 'unknownMonthlyAdvances' | 'saving' | 'createdAt' | 'updatedAt' | 'lastViewed' | 'currentProductId' | 'currentProductName' | 'currentProductFollowingId' | 'unknownCurrentProduct' | 'gasCurrentProductId' | 'gasCurrentProductName' | 'gasCurrentProductFollowingId' | 'gasUnknownCurrentProduct' | 'individualPriceExpectedMonthly' | 'individualPriceExpectedMWhHighTariff' | 'individualPriceExpectedMWhLowTariff' | 'gasIndividualPriceExpectedMonthly' | 'gasIndividualPriceExpectedMWh' | 'individualOfferName' | 'zip'>
  & { partner: Maybe<(
    { __typename?: 'EnergoElectricityPartner' }
    & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
    & { rating: Maybe<(
      { __typename?: 'NewRating' }
      & Pick<NewRating, 'id' | 'total'>
      & { categories: Array<Maybe<(
        { __typename?: 'RatingCategory' }
        & Pick<RatingCategory, 'id' | 'label' | 'value'>
      )>> }
    )> }
  )>, gasPartner: Maybe<(
    { __typename?: 'EnergoGasPartner' }
    & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
    & { rating: Maybe<(
      { __typename?: 'NewRating' }
      & Pick<NewRating, 'id' | 'total'>
      & { categories: Array<Maybe<(
        { __typename?: 'RatingCategory' }
        & Pick<RatingCategory, 'id' | 'label' | 'value'>
      )>> }
    )> }
  )>, gasUsages: Maybe<(
    { __typename?: 'GasUsages' }
    & Pick<GasUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
  )>, electricityUsages: Maybe<(
    { __typename?: 'ElectricityUsages' }
    & Pick<ElectricityUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
  )>, floorAreaRange: Maybe<(
    { __typename?: 'EnergoFloorAreaRangeCodebook' }
    & Pick<EnergoFloorAreaRangeCodebook, 'id'>
    & { label: EnergoFloorAreaRangeCodebook['name'] }
  )>, isolation: Maybe<(
    { __typename?: 'EnergoIsolationCodebook' }
    & Pick<EnergoIsolationCodebook, 'id'>
    & { label: EnergoIsolationCodebook['name'] }
  )>, personsCountRange: Maybe<(
    { __typename?: 'EnergoPersonsCountRangeCodebook' }
    & Pick<EnergoPersonsCountRangeCodebook, 'id'>
    & { label: EnergoPersonsCountRangeCodebook['name'] }
  )>, distributionRate: Maybe<(
    { __typename?: 'EnergoDistributionRate' }
    & Pick<EnergoDistributionRate, 'id' | 'name' | 'lowTariff'>
  )>, breaker: Maybe<(
    { __typename?: 'EnergoBreaker' }
    & Pick<EnergoBreaker, 'id' | 'name'>
  )>, standaloneInvoice: Maybe<(
    { __typename?: 'EnergoStandaloneInvoice' }
    & Pick<EnergoStandaloneInvoice, 'id' | 'uuid'>
  )>, bestResultItem: Maybe<(
    { __typename?: 'EnergoElectricityResultItem' }
    & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
    & { partner: (
      { __typename?: 'EnergoElectricityPartner' }
      & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    ), product: (
      { __typename?: 'EnergoElectricityProduct' }
      & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
    ), priceExpectedDetail: (
      { __typename?: 'EnergoElectricityResultPriceDetail' }
      & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
    ), priceResultDetail: (
      { __typename?: 'EnergoElectricityResultPriceDetail' }
      & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
    ) }
  )>, bestGasResultItem: Maybe<(
    { __typename?: 'EnergoGasResultItem' }
    & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
    & { partner: (
      { __typename?: 'EnergoGasPartner' }
      & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    ), product: (
      { __typename?: 'EnergoGasProduct' }
      & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
    ), priceExpectedDetail: (
      { __typename?: 'EnergoGasResultPriceDetail' }
      & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
    ), priceResultDetail: (
      { __typename?: 'EnergoGasResultPriceDetail' }
      & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
    ) }
  )> }
);

export type EnergoCalculationResultFragment = (
  { __typename?: 'EnergoCalculationResult' }
  & Pick<EnergoCalculationResult, 'id' | 'hasEnergoOffers'>
  & { calculation: (
    { __typename?: 'EnergoCalculation' }
    & Pick<EnergoCalculation, 'id' | 'uuid' | 'type' | 'source' | 'yearlyConsumption' | 'yearlyConsumptionUnit' | 'yearlyConsumptionLowTariff' | 'yearlyConsumptionLowTariffUnit' | 'yearlyPayment' | 'gasYearlyPayment' | 'gasYearlyConsumption' | 'gasYearlyConsumptionUnit' | 'gasMonthlyAdvances' | 'gasUnknownMonthlyAdvances' | 'monthlyAdvances' | 'unknownMonthlyAdvances' | 'email' | 'saving' | 'createdAt' | 'updatedAt' | 'lastViewed' | 'currentProductId' | 'currentProductName' | 'currentProductFollowingId' | 'unknownCurrentProduct' | 'gasCurrentProductId' | 'gasCurrentProductName' | 'gasCurrentProductFollowingId' | 'gasUnknownCurrentProduct' | 'phone' | 'individualPriceExpectedMonthly' | 'individualPriceExpectedMWhHighTariff' | 'individualPriceExpectedMWhLowTariff' | 'gasIndividualPriceExpectedMonthly' | 'gasIndividualPriceExpectedMWh' | 'individualOfferName' | 'reason'>
    & { address: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
      & { extended: Maybe<(
        { __typename?: 'SmartAddressExtendedInfo' }
        & Pick<SmartAddressExtendedInfo, 'id'>
        & { objectType: Maybe<(
          { __typename?: 'SmartAddressObjectType' }
          & Pick<SmartAddressObjectType, 'id' | 'nazev'>
        )> }
      )>, extendedV2: Maybe<(
        { __typename?: 'SmartAddressSdkExtendedInfo' }
        & { administrativniUdaje: Maybe<(
          { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
          & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )> }
      )> }
    )>, partner: Maybe<(
      { __typename?: 'EnergoElectricityPartner' }
      & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    )>, gasPartner: Maybe<(
      { __typename?: 'EnergoGasPartner' }
      & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    )>, gasUsages: Maybe<(
      { __typename?: 'GasUsages' }
      & Pick<GasUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
    )>, electricityUsages: Maybe<(
      { __typename?: 'ElectricityUsages' }
      & Pick<ElectricityUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
    )>, floorAreaRange: Maybe<(
      { __typename?: 'EnergoFloorAreaRangeCodebook' }
      & Pick<EnergoFloorAreaRangeCodebook, 'id'>
      & { label: EnergoFloorAreaRangeCodebook['name'] }
    )>, isolation: Maybe<(
      { __typename?: 'EnergoIsolationCodebook' }
      & Pick<EnergoIsolationCodebook, 'id'>
      & { label: EnergoIsolationCodebook['name'] }
    )>, personsCountRange: Maybe<(
      { __typename?: 'EnergoPersonsCountRangeCodebook' }
      & Pick<EnergoPersonsCountRangeCodebook, 'id'>
      & { label: EnergoPersonsCountRangeCodebook['name'] }
    )>, distributionRate: Maybe<(
      { __typename?: 'EnergoDistributionRate' }
      & Pick<EnergoDistributionRate, 'id' | 'name' | 'lowTariff'>
    )>, breaker: Maybe<(
      { __typename?: 'EnergoBreaker' }
      & Pick<EnergoBreaker, 'id' | 'name'>
    )>, standaloneInvoice: Maybe<(
      { __typename?: 'EnergoStandaloneInvoice' }
      & Pick<EnergoStandaloneInvoice, 'id' | 'uuid'>
    )>, bestResultItem: Maybe<(
      { __typename?: 'EnergoElectricityResultItem' }
      & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoElectricityPartner' }
        & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoElectricityProduct' }
        & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    )>, bestGasResultItem: Maybe<(
      { __typename?: 'EnergoGasResultItem' }
      & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoGasPartner' }
        & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoGasProduct' }
        & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    )> }
  ), electricityResult: Maybe<(
    { __typename?: 'EnergoElectricityCalculationResult' }
    & { resultItems: Array<(
      { __typename?: 'EnergoElectricityResultItem' }
      & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoElectricityPartner' }
        & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoElectricityProduct' }
        & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    )>, common: (
      { __typename?: 'EnergoElectricityCalculationResultCommon' }
      & Pick<EnergoElectricityCalculationResultCommon, 'priceCurrentAnnually' | 'priceCurrentMonthly' | 'consumptionHT' | 'consumptionLT'>
    ), bestGuaranteed: Maybe<(
      { __typename?: 'EnergoElectricityResultItem' }
      & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoElectricityPartner' }
        & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoElectricityProduct' }
        & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    )>, bestLengthOfFixation: Maybe<(
      { __typename?: 'EnergoElectricityResultItem' }
      & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoElectricityPartner' }
        & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoElectricityProduct' }
        & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    )>, bestNoFixation: Maybe<(
      { __typename?: 'EnergoElectricityResultItem' }
      & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoElectricityPartner' }
        & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoElectricityProduct' }
        & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    )> }
  )>, gasResult: Maybe<(
    { __typename?: 'EnergoGasCalculationResult' }
    & { resultItems: Array<(
      { __typename?: 'EnergoGasResultItem' }
      & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoGasPartner' }
        & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoGasProduct' }
        & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    )>, common: (
      { __typename?: 'EnergoGasCalculationResultCommon' }
      & Pick<EnergoGasCalculationResultCommon, 'priceCurrentAnnually' | 'priceCurrentMonthly' | 'consumption'>
    ), bestGuaranteed: Maybe<(
      { __typename?: 'EnergoGasResultItem' }
      & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoGasPartner' }
        & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoGasProduct' }
        & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    )>, bestLengthOfFixation: Maybe<(
      { __typename?: 'EnergoGasResultItem' }
      & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoGasPartner' }
        & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoGasProduct' }
        & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    )>, bestNoFixation: Maybe<(
      { __typename?: 'EnergoGasResultItem' }
      & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoGasPartner' }
        & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoGasProduct' }
        & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    )> }
  )> }
);

export type EnergoElectricityResultItemFragment = (
  { __typename?: 'EnergoElectricityResultItem' }
  & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
  & { partner: (
    { __typename?: 'EnergoElectricityPartner' }
    & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
    & { rating: Maybe<(
      { __typename?: 'NewRating' }
      & Pick<NewRating, 'id' | 'total'>
      & { categories: Array<Maybe<(
        { __typename?: 'RatingCategory' }
        & Pick<RatingCategory, 'id' | 'label' | 'value'>
      )>> }
    )> }
  ), product: (
    { __typename?: 'EnergoElectricityProduct' }
    & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
  ), priceExpectedDetail: (
    { __typename?: 'EnergoElectricityResultPriceDetail' }
    & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
  ), priceResultDetail: (
    { __typename?: 'EnergoElectricityResultPriceDetail' }
    & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
  ) }
);

export type EnergoElectricityLeadFragment = (
  { __typename?: 'EnergoElectricityLead' }
  & Pick<EnergoElectricityLead, 'id' | 'yearlyPayment' | 'yearlyConsumption' | 'yearlyConsumptionLowTariff' | 'currentProductId' | 'currentProductName'>
  & { lead: (
    { __typename?: 'Lead' }
    & Pick<Lead, 'id' | 'type' | 'uuid' | 'name' | 'surname' | 'email' | 'phone' | 'status' | 'customerNote' | 'lastStatusChange' | 'completedAt' | 'sendEmailImmediately'>
    & { address: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
      & { extended: Maybe<(
        { __typename?: 'SmartAddressExtendedInfo' }
        & Pick<SmartAddressExtendedInfo, 'id'>
        & { objectType: Maybe<(
          { __typename?: 'SmartAddressObjectType' }
          & Pick<SmartAddressObjectType, 'id' | 'nazev'>
        )> }
      )>, extendedV2: Maybe<(
        { __typename?: 'SmartAddressSdkExtendedInfo' }
        & { administrativniUdaje: Maybe<(
          { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
          & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )> }
      )> }
    )>, contactAddress: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
      & { extended: Maybe<(
        { __typename?: 'SmartAddressExtendedInfo' }
        & Pick<SmartAddressExtendedInfo, 'id'>
        & { objectType: Maybe<(
          { __typename?: 'SmartAddressObjectType' }
          & Pick<SmartAddressObjectType, 'id' | 'nazev'>
        )> }
      )>, extendedV2: Maybe<(
        { __typename?: 'SmartAddressSdkExtendedInfo' }
        & { administrativniUdaje: Maybe<(
          { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
          & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )> }
      )> }
    )>, attachments: Array<(
      { __typename?: 'LeadAttachment' }
      & Pick<LeadAttachment, 'id'>
      & { file: (
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      ) }
    )> }
  ), calculation: (
    { __typename?: 'EnergoCalculation' }
    & Pick<EnergoCalculation, 'id' | 'uuid' | 'type' | 'source' | 'yearlyConsumption' | 'yearlyConsumptionUnit' | 'yearlyConsumptionLowTariff' | 'yearlyConsumptionLowTariffUnit' | 'yearlyPayment' | 'gasYearlyPayment' | 'gasYearlyConsumption' | 'gasYearlyConsumptionUnit' | 'gasMonthlyAdvances' | 'gasUnknownMonthlyAdvances' | 'monthlyAdvances' | 'unknownMonthlyAdvances' | 'email' | 'saving' | 'createdAt' | 'updatedAt' | 'lastViewed' | 'currentProductId' | 'currentProductName' | 'currentProductFollowingId' | 'unknownCurrentProduct' | 'gasCurrentProductId' | 'gasCurrentProductName' | 'gasCurrentProductFollowingId' | 'gasUnknownCurrentProduct' | 'phone' | 'individualPriceExpectedMonthly' | 'individualPriceExpectedMWhHighTariff' | 'individualPriceExpectedMWhLowTariff' | 'gasIndividualPriceExpectedMonthly' | 'gasIndividualPriceExpectedMWh' | 'individualOfferName' | 'reason'>
    & { address: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
      & { extended: Maybe<(
        { __typename?: 'SmartAddressExtendedInfo' }
        & Pick<SmartAddressExtendedInfo, 'id'>
        & { objectType: Maybe<(
          { __typename?: 'SmartAddressObjectType' }
          & Pick<SmartAddressObjectType, 'id' | 'nazev'>
        )> }
      )>, extendedV2: Maybe<(
        { __typename?: 'SmartAddressSdkExtendedInfo' }
        & { administrativniUdaje: Maybe<(
          { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
          & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )> }
      )> }
    )>, partner: Maybe<(
      { __typename?: 'EnergoElectricityPartner' }
      & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    )>, gasPartner: Maybe<(
      { __typename?: 'EnergoGasPartner' }
      & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    )>, gasUsages: Maybe<(
      { __typename?: 'GasUsages' }
      & Pick<GasUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
    )>, electricityUsages: Maybe<(
      { __typename?: 'ElectricityUsages' }
      & Pick<ElectricityUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
    )>, floorAreaRange: Maybe<(
      { __typename?: 'EnergoFloorAreaRangeCodebook' }
      & Pick<EnergoFloorAreaRangeCodebook, 'id'>
      & { label: EnergoFloorAreaRangeCodebook['name'] }
    )>, isolation: Maybe<(
      { __typename?: 'EnergoIsolationCodebook' }
      & Pick<EnergoIsolationCodebook, 'id'>
      & { label: EnergoIsolationCodebook['name'] }
    )>, personsCountRange: Maybe<(
      { __typename?: 'EnergoPersonsCountRangeCodebook' }
      & Pick<EnergoPersonsCountRangeCodebook, 'id'>
      & { label: EnergoPersonsCountRangeCodebook['name'] }
    )>, distributionRate: Maybe<(
      { __typename?: 'EnergoDistributionRate' }
      & Pick<EnergoDistributionRate, 'id' | 'name' | 'lowTariff'>
    )>, breaker: Maybe<(
      { __typename?: 'EnergoBreaker' }
      & Pick<EnergoBreaker, 'id' | 'name'>
    )>, standaloneInvoice: Maybe<(
      { __typename?: 'EnergoStandaloneInvoice' }
      & Pick<EnergoStandaloneInvoice, 'id' | 'uuid'>
    )>, bestResultItem: Maybe<(
      { __typename?: 'EnergoElectricityResultItem' }
      & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoElectricityPartner' }
        & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoElectricityProduct' }
        & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    )>, bestGasResultItem: Maybe<(
      { __typename?: 'EnergoGasResultItem' }
      & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoGasPartner' }
        & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoGasProduct' }
        & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    )> }
  ), resultItem: (
    { __typename?: 'EnergoElectricityResultItem' }
    & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
    & { partner: (
      { __typename?: 'EnergoElectricityPartner' }
      & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    ), product: (
      { __typename?: 'EnergoElectricityProduct' }
      & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
    ), priceExpectedDetail: (
      { __typename?: 'EnergoElectricityResultPriceDetail' }
      & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
    ), priceResultDetail: (
      { __typename?: 'EnergoElectricityResultPriceDetail' }
      & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
    ) }
  ), userResultItem: Maybe<(
    { __typename?: 'EnergoElectricityResultItem' }
    & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
    & { partner: (
      { __typename?: 'EnergoElectricityPartner' }
      & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    ), product: (
      { __typename?: 'EnergoElectricityProduct' }
      & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
    ), priceExpectedDetail: (
      { __typename?: 'EnergoElectricityResultPriceDetail' }
      & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
    ), priceResultDetail: (
      { __typename?: 'EnergoElectricityResultPriceDetail' }
      & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
    ) }
  )>, partner: Maybe<(
    { __typename?: 'EnergoElectricityPartner' }
    & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
    & { rating: Maybe<(
      { __typename?: 'NewRating' }
      & Pick<NewRating, 'id' | 'total'>
      & { categories: Array<Maybe<(
        { __typename?: 'RatingCategory' }
        & Pick<RatingCategory, 'id' | 'label' | 'value'>
      )>> }
    )> }
  )>, breaker: Maybe<(
    { __typename?: 'EnergoBreaker' }
    & Pick<EnergoBreaker, 'id'>
    & { label: EnergoBreaker['name'] }
  )>, distributionRate: Maybe<(
    { __typename?: 'EnergoDistributionRate' }
    & Pick<EnergoDistributionRate, 'id' | 'description' | 'lowTariff'>
    & { label: EnergoDistributionRate['name'] }
  )> }
);

export type EnergoGasLeadFragment = (
  { __typename?: 'EnergoGasLead' }
  & Pick<EnergoGasLead, 'id' | 'yearlyPayment' | 'yearlyConsumption' | 'currentProductId' | 'currentProductName'>
  & { lead: (
    { __typename?: 'Lead' }
    & Pick<Lead, 'id' | 'type' | 'uuid' | 'name' | 'surname' | 'email' | 'phone' | 'status' | 'customerNote' | 'lastStatusChange' | 'completedAt' | 'sendEmailImmediately'>
    & { address: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
      & { extended: Maybe<(
        { __typename?: 'SmartAddressExtendedInfo' }
        & Pick<SmartAddressExtendedInfo, 'id'>
        & { objectType: Maybe<(
          { __typename?: 'SmartAddressObjectType' }
          & Pick<SmartAddressObjectType, 'id' | 'nazev'>
        )> }
      )>, extendedV2: Maybe<(
        { __typename?: 'SmartAddressSdkExtendedInfo' }
        & { administrativniUdaje: Maybe<(
          { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
          & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )> }
      )> }
    )>, contactAddress: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
      & { extended: Maybe<(
        { __typename?: 'SmartAddressExtendedInfo' }
        & Pick<SmartAddressExtendedInfo, 'id'>
        & { objectType: Maybe<(
          { __typename?: 'SmartAddressObjectType' }
          & Pick<SmartAddressObjectType, 'id' | 'nazev'>
        )> }
      )>, extendedV2: Maybe<(
        { __typename?: 'SmartAddressSdkExtendedInfo' }
        & { administrativniUdaje: Maybe<(
          { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
          & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )> }
      )> }
    )>, attachments: Array<(
      { __typename?: 'LeadAttachment' }
      & Pick<LeadAttachment, 'id'>
      & { file: (
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      ) }
    )> }
  ), calculation: (
    { __typename?: 'EnergoCalculation' }
    & Pick<EnergoCalculation, 'id' | 'uuid' | 'type' | 'source' | 'yearlyConsumption' | 'yearlyConsumptionUnit' | 'yearlyConsumptionLowTariff' | 'yearlyConsumptionLowTariffUnit' | 'yearlyPayment' | 'gasYearlyPayment' | 'gasYearlyConsumption' | 'gasYearlyConsumptionUnit' | 'gasMonthlyAdvances' | 'gasUnknownMonthlyAdvances' | 'monthlyAdvances' | 'unknownMonthlyAdvances' | 'email' | 'saving' | 'createdAt' | 'updatedAt' | 'lastViewed' | 'currentProductId' | 'currentProductName' | 'currentProductFollowingId' | 'unknownCurrentProduct' | 'gasCurrentProductId' | 'gasCurrentProductName' | 'gasCurrentProductFollowingId' | 'gasUnknownCurrentProduct' | 'phone' | 'individualPriceExpectedMonthly' | 'individualPriceExpectedMWhHighTariff' | 'individualPriceExpectedMWhLowTariff' | 'gasIndividualPriceExpectedMonthly' | 'gasIndividualPriceExpectedMWh' | 'individualOfferName' | 'reason'>
    & { address: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
      & { extended: Maybe<(
        { __typename?: 'SmartAddressExtendedInfo' }
        & Pick<SmartAddressExtendedInfo, 'id'>
        & { objectType: Maybe<(
          { __typename?: 'SmartAddressObjectType' }
          & Pick<SmartAddressObjectType, 'id' | 'nazev'>
        )> }
      )>, extendedV2: Maybe<(
        { __typename?: 'SmartAddressSdkExtendedInfo' }
        & { administrativniUdaje: Maybe<(
          { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
          & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )> }
      )> }
    )>, partner: Maybe<(
      { __typename?: 'EnergoElectricityPartner' }
      & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    )>, gasPartner: Maybe<(
      { __typename?: 'EnergoGasPartner' }
      & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    )>, gasUsages: Maybe<(
      { __typename?: 'GasUsages' }
      & Pick<GasUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
    )>, electricityUsages: Maybe<(
      { __typename?: 'ElectricityUsages' }
      & Pick<ElectricityUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
    )>, floorAreaRange: Maybe<(
      { __typename?: 'EnergoFloorAreaRangeCodebook' }
      & Pick<EnergoFloorAreaRangeCodebook, 'id'>
      & { label: EnergoFloorAreaRangeCodebook['name'] }
    )>, isolation: Maybe<(
      { __typename?: 'EnergoIsolationCodebook' }
      & Pick<EnergoIsolationCodebook, 'id'>
      & { label: EnergoIsolationCodebook['name'] }
    )>, personsCountRange: Maybe<(
      { __typename?: 'EnergoPersonsCountRangeCodebook' }
      & Pick<EnergoPersonsCountRangeCodebook, 'id'>
      & { label: EnergoPersonsCountRangeCodebook['name'] }
    )>, distributionRate: Maybe<(
      { __typename?: 'EnergoDistributionRate' }
      & Pick<EnergoDistributionRate, 'id' | 'name' | 'lowTariff'>
    )>, breaker: Maybe<(
      { __typename?: 'EnergoBreaker' }
      & Pick<EnergoBreaker, 'id' | 'name'>
    )>, standaloneInvoice: Maybe<(
      { __typename?: 'EnergoStandaloneInvoice' }
      & Pick<EnergoStandaloneInvoice, 'id' | 'uuid'>
    )>, bestResultItem: Maybe<(
      { __typename?: 'EnergoElectricityResultItem' }
      & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoElectricityPartner' }
        & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoElectricityProduct' }
        & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    )>, bestGasResultItem: Maybe<(
      { __typename?: 'EnergoGasResultItem' }
      & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoGasPartner' }
        & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoGasProduct' }
        & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    )> }
  ), resultItem: (
    { __typename?: 'EnergoGasResultItem' }
    & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
    & { partner: (
      { __typename?: 'EnergoGasPartner' }
      & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    ), product: (
      { __typename?: 'EnergoGasProduct' }
      & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
    ), priceExpectedDetail: (
      { __typename?: 'EnergoGasResultPriceDetail' }
      & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
    ), priceResultDetail: (
      { __typename?: 'EnergoGasResultPriceDetail' }
      & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
    ) }
  ), userResultItem: Maybe<(
    { __typename?: 'EnergoGasResultItem' }
    & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
    & { partner: (
      { __typename?: 'EnergoGasPartner' }
      & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    ), product: (
      { __typename?: 'EnergoGasProduct' }
      & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
    ), priceExpectedDetail: (
      { __typename?: 'EnergoGasResultPriceDetail' }
      & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
    ), priceResultDetail: (
      { __typename?: 'EnergoGasResultPriceDetail' }
      & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
    ) }
  )>, partner: Maybe<(
    { __typename?: 'EnergoGasPartner' }
    & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
    & { rating: Maybe<(
      { __typename?: 'NewRating' }
      & Pick<NewRating, 'id' | 'total'>
      & { categories: Array<Maybe<(
        { __typename?: 'RatingCategory' }
        & Pick<RatingCategory, 'id' | 'label' | 'value'>
      )>> }
    )> }
  )> }
);

export type EnergoElectricityProductFragment = (
  { __typename?: 'EnergoElectricityProduct' }
  & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
);

export type EnergoKalkulatorEnergoElectricityProductFragment = (
  { __typename?: 'EnergoKalkulatorEnergoElectricityProduct' }
  & Pick<EnergoKalkulatorEnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'isActiveForSelectCurrentEstimate' | 'isActiveForSelectCurrentExact' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip' | 'createdAt' | 'updatedAt' | 'adminLastEdit'>
  & { partner: Maybe<(
    { __typename?: 'EnergoElectricityPartner' }
    & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
    & { rating: Maybe<(
      { __typename?: 'NewRating' }
      & Pick<NewRating, 'id' | 'total'>
      & { categories: Array<Maybe<(
        { __typename?: 'RatingCategory' }
        & Pick<RatingCategory, 'id' | 'label' | 'value'>
      )>> }
    )> }
  )> }
);

export type EnergoKalkulatorEnergoElectricityProductDetailFragment = (
  { __typename?: 'EnergoKalkulatorEnergoElectricityProductDetail' }
  & Pick<EnergoKalkulatorEnergoElectricityProductDetail, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'isActiveForSelectCurrentEstimate' | 'isActiveForSelectCurrentExact' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'partnerId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip' | 'createdAt' | 'updatedAt' | 'adminLastEdit'>
  & { partner: Maybe<(
    { __typename?: 'EnergoKalkulatorPartnerCodebook' }
    & Pick<EnergoKalkulatorPartnerCodebook, 'label' | 'value'>
  )> }
);

export type EnergoElectricityPartnerFragment = (
  { __typename?: 'EnergoElectricityPartner' }
  & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
  & { rating: Maybe<(
    { __typename?: 'NewRating' }
    & Pick<NewRating, 'id' | 'total'>
    & { categories: Array<Maybe<(
      { __typename?: 'RatingCategory' }
      & Pick<RatingCategory, 'id' | 'label' | 'value'>
    )>> }
  )> }
);

export type EnergoElectricityPartnerDetailFragment = (
  { __typename?: 'EnergoElectricityPartnerDetail' }
  & Pick<EnergoElectricityPartnerDetail, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'cooperationMethod' | 'termsOfServicePreview' | 'companyAddress' | 'contactEmail' | 'tollFreePhone' | 'latitude' | 'longitude' | 'web' | 'urlVop' | 'urlPricelist' | 'longArticleMD' | 'isActive' | 'energoPartnerId' | 'title' | 'titleSuffix' | 'metaTitle' | 'metaDescription'>
  & { rating: Maybe<(
    { __typename?: 'NewRating' }
    & Pick<NewRating, 'id' | 'total'>
    & { categories: Array<Maybe<(
      { __typename?: 'RatingCategory' }
      & Pick<RatingCategory, 'id' | 'label' | 'value'>
    )>> }
  )> }
);

export type EnergoGasProductFragment = (
  { __typename?: 'EnergoGasProduct' }
  & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
);

export type EnergoKalkulatorEnergoGasProductFragment = (
  { __typename?: 'EnergoKalkulatorEnergoGasProduct' }
  & Pick<EnergoKalkulatorEnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'isActiveForSelectCurrentEstimate' | 'isActiveForSelectCurrentExact' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip' | 'createdAt' | 'updatedAt' | 'adminLastEdit'>
  & { partner: Maybe<(
    { __typename?: 'EnergoGasPartner' }
    & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
    & { rating: Maybe<(
      { __typename?: 'NewRating' }
      & Pick<NewRating, 'id' | 'total'>
      & { categories: Array<Maybe<(
        { __typename?: 'RatingCategory' }
        & Pick<RatingCategory, 'id' | 'label' | 'value'>
      )>> }
    )> }
  )> }
);

export type EnergoKalkulatorEnergoGasProductDetailFragment = (
  { __typename?: 'EnergoKalkulatorEnergoGasProductDetail' }
  & Pick<EnergoKalkulatorEnergoGasProductDetail, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'isActiveForSelectCurrentEstimate' | 'isActiveForSelectCurrentExact' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'partnerId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip' | 'createdAt' | 'updatedAt' | 'adminLastEdit'>
  & { partner: Maybe<(
    { __typename?: 'EnergoKalkulatorPartnerCodebook' }
    & Pick<EnergoKalkulatorPartnerCodebook, 'label' | 'value'>
  )> }
);

export type EnergoGasPartnerFragment = (
  { __typename?: 'EnergoGasPartner' }
  & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
  & { rating: Maybe<(
    { __typename?: 'NewRating' }
    & Pick<NewRating, 'id' | 'total'>
    & { categories: Array<Maybe<(
      { __typename?: 'RatingCategory' }
      & Pick<RatingCategory, 'id' | 'label' | 'value'>
    )>> }
  )> }
);

export type EnergoGasPartnerDetailFragment = (
  { __typename?: 'EnergoGasPartnerDetail' }
  & Pick<EnergoGasPartnerDetail, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'cooperationMethod' | 'termsOfServicePreview' | 'companyAddress' | 'contactEmail' | 'tollFreePhone' | 'latitude' | 'longitude' | 'web' | 'urlVop' | 'urlPricelist' | 'longArticleMD' | 'isActive' | 'energoPartnerId' | 'title' | 'titleSuffix' | 'metaTitle' | 'metaDescription'>
  & { rating: Maybe<(
    { __typename?: 'NewRating' }
    & Pick<NewRating, 'id' | 'total'>
    & { categories: Array<Maybe<(
      { __typename?: 'RatingCategory' }
      & Pick<RatingCategory, 'id' | 'label' | 'value'>
    )>> }
  )> }
);

export type EnergoFormCodebooksFragment = (
  { __typename?: 'EnergoCodebooks' }
  & { breaker: Array<(
    { __typename?: 'EnergoBreaker' }
    & Pick<EnergoBreaker, 'id'>
    & { label: EnergoBreaker['name'] }
  )>, partner: Array<(
    { __typename?: 'EnergoElectricityPartnerCodebook' }
    & Pick<EnergoElectricityPartnerCodebook, 'id' | 'isActive' | 'seoName' | 'logoUrl' | 'invoiceHelpDistributionRateMD' | 'invoiceHelpInvoicedPaymentMD' | 'invoiceHelpHighTariffMD' | 'invoiceHelpLowTariffMD' | 'invoiceHelpBreakerMD' | 'invoiceHelpCurrentProductMD' | 'cooperationMethod' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'createdAt' | 'updatedAt' | 'adminLastEdit'>
    & { label: EnergoElectricityPartnerCodebook['name'] }
    & { invoiceHelpDistributionRateImage: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    )>, invoiceHelpInvoicedPaymentImage: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    )>, invoiceHelpHighTariffImage: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    )>, invoiceHelpLowTariffImage: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    )>, invoiceHelpBreakerImage: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    )>, invoiceHelpCurrentProductImage: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    )> }
  )>, gasPartner: Array<(
    { __typename?: 'EnergoGasPartnerCodebook' }
    & Pick<EnergoGasPartnerCodebook, 'id' | 'isActive' | 'seoName' | 'logoUrl' | 'invoiceHelpYearlyConsumptionMD' | 'invoiceHelpInvoicedPaymentMD' | 'invoiceHelpCurrentProductMD' | 'cooperationMethod' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'createdAt' | 'updatedAt' | 'adminLastEdit'>
    & { label: EnergoGasPartnerCodebook['name'] }
    & { invoiceHelpYearlyConsumptionImage: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    )>, invoiceHelpInvoicedPaymentImage: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    )>, invoiceHelpCurrentProductImage: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    )> }
  )>, distributionRate: Array<(
    { __typename?: 'EnergoDistributionRate' }
    & Pick<EnergoDistributionRate, 'id' | 'description' | 'lowTariff'>
    & { label: EnergoDistributionRate['name'] }
  )>, personsCountRange: Array<(
    { __typename?: 'EnergoPersonsCountRangeCodebook' }
    & Pick<EnergoPersonsCountRangeCodebook, 'id'>
    & { label: EnergoPersonsCountRangeCodebook['name'] }
  )>, floorAreaRange: Array<(
    { __typename?: 'EnergoFloorAreaRangeCodebook' }
    & Pick<EnergoFloorAreaRangeCodebook, 'id'>
    & { label: EnergoFloorAreaRangeCodebook['name'] }
  )>, isolation: Array<(
    { __typename?: 'EnergoIsolationCodebook' }
    & Pick<EnergoIsolationCodebook, 'id'>
    & { label: EnergoIsolationCodebook['name'] }
  )> }
);

export type EnergoFloorAreaRangeCodebookFragment = (
  { __typename?: 'EnergoFloorAreaRangeCodebook' }
  & Pick<EnergoFloorAreaRangeCodebook, 'id'>
  & { label: EnergoFloorAreaRangeCodebook['name'] }
);

export type EnergoPersonsCountRangeCodebookFragment = (
  { __typename?: 'EnergoPersonsCountRangeCodebook' }
  & Pick<EnergoPersonsCountRangeCodebook, 'id'>
  & { label: EnergoPersonsCountRangeCodebook['name'] }
);

export type EnergoIsolationCodebookFragment = (
  { __typename?: 'EnergoIsolationCodebook' }
  & Pick<EnergoIsolationCodebook, 'id'>
  & { label: EnergoIsolationCodebook['name'] }
);

export type EnergoElectricityCalculationResultCommonFragment = (
  { __typename?: 'EnergoElectricityCalculationResultCommon' }
  & Pick<EnergoElectricityCalculationResultCommon, 'priceCurrentAnnually' | 'priceCurrentMonthly' | 'consumptionHT' | 'consumptionLT'>
);

export type EnergoGasCalculationResultCommonFragment = (
  { __typename?: 'EnergoGasCalculationResultCommon' }
  & Pick<EnergoGasCalculationResultCommon, 'priceCurrentAnnually' | 'priceCurrentMonthly' | 'consumption'>
);

export type EnergoElectricityCalculationResultFragment = (
  { __typename?: 'EnergoElectricityCalculationResult' }
  & { resultItems: Array<(
    { __typename?: 'EnergoElectricityResultItem' }
    & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
    & { partner: (
      { __typename?: 'EnergoElectricityPartner' }
      & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    ), product: (
      { __typename?: 'EnergoElectricityProduct' }
      & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
    ), priceExpectedDetail: (
      { __typename?: 'EnergoElectricityResultPriceDetail' }
      & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
    ), priceResultDetail: (
      { __typename?: 'EnergoElectricityResultPriceDetail' }
      & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
    ) }
  )>, common: (
    { __typename?: 'EnergoElectricityCalculationResultCommon' }
    & Pick<EnergoElectricityCalculationResultCommon, 'priceCurrentAnnually' | 'priceCurrentMonthly' | 'consumptionHT' | 'consumptionLT'>
  ), bestGuaranteed: Maybe<(
    { __typename?: 'EnergoElectricityResultItem' }
    & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
    & { partner: (
      { __typename?: 'EnergoElectricityPartner' }
      & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    ), product: (
      { __typename?: 'EnergoElectricityProduct' }
      & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
    ), priceExpectedDetail: (
      { __typename?: 'EnergoElectricityResultPriceDetail' }
      & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
    ), priceResultDetail: (
      { __typename?: 'EnergoElectricityResultPriceDetail' }
      & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
    ) }
  )>, bestLengthOfFixation: Maybe<(
    { __typename?: 'EnergoElectricityResultItem' }
    & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
    & { partner: (
      { __typename?: 'EnergoElectricityPartner' }
      & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    ), product: (
      { __typename?: 'EnergoElectricityProduct' }
      & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
    ), priceExpectedDetail: (
      { __typename?: 'EnergoElectricityResultPriceDetail' }
      & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
    ), priceResultDetail: (
      { __typename?: 'EnergoElectricityResultPriceDetail' }
      & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
    ) }
  )>, bestNoFixation: Maybe<(
    { __typename?: 'EnergoElectricityResultItem' }
    & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
    & { partner: (
      { __typename?: 'EnergoElectricityPartner' }
      & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    ), product: (
      { __typename?: 'EnergoElectricityProduct' }
      & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
    ), priceExpectedDetail: (
      { __typename?: 'EnergoElectricityResultPriceDetail' }
      & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
    ), priceResultDetail: (
      { __typename?: 'EnergoElectricityResultPriceDetail' }
      & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
    ) }
  )> }
);

export type EnergoGasCalculationResultFragment = (
  { __typename?: 'EnergoGasCalculationResult' }
  & { resultItems: Array<(
    { __typename?: 'EnergoGasResultItem' }
    & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
    & { partner: (
      { __typename?: 'EnergoGasPartner' }
      & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    ), product: (
      { __typename?: 'EnergoGasProduct' }
      & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
    ), priceExpectedDetail: (
      { __typename?: 'EnergoGasResultPriceDetail' }
      & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
    ), priceResultDetail: (
      { __typename?: 'EnergoGasResultPriceDetail' }
      & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
    ) }
  )>, common: (
    { __typename?: 'EnergoGasCalculationResultCommon' }
    & Pick<EnergoGasCalculationResultCommon, 'priceCurrentAnnually' | 'priceCurrentMonthly' | 'consumption'>
  ), bestGuaranteed: Maybe<(
    { __typename?: 'EnergoGasResultItem' }
    & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
    & { partner: (
      { __typename?: 'EnergoGasPartner' }
      & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    ), product: (
      { __typename?: 'EnergoGasProduct' }
      & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
    ), priceExpectedDetail: (
      { __typename?: 'EnergoGasResultPriceDetail' }
      & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
    ), priceResultDetail: (
      { __typename?: 'EnergoGasResultPriceDetail' }
      & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
    ) }
  )>, bestLengthOfFixation: Maybe<(
    { __typename?: 'EnergoGasResultItem' }
    & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
    & { partner: (
      { __typename?: 'EnergoGasPartner' }
      & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    ), product: (
      { __typename?: 'EnergoGasProduct' }
      & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
    ), priceExpectedDetail: (
      { __typename?: 'EnergoGasResultPriceDetail' }
      & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
    ), priceResultDetail: (
      { __typename?: 'EnergoGasResultPriceDetail' }
      & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
    ) }
  )>, bestNoFixation: Maybe<(
    { __typename?: 'EnergoGasResultItem' }
    & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
    & { partner: (
      { __typename?: 'EnergoGasPartner' }
      & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    ), product: (
      { __typename?: 'EnergoGasProduct' }
      & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
    ), priceExpectedDetail: (
      { __typename?: 'EnergoGasResultPriceDetail' }
      & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
    ), priceResultDetail: (
      { __typename?: 'EnergoGasResultPriceDetail' }
      & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
    ) }
  )> }
);

export type EnergoGasResultItemFragment = (
  { __typename?: 'EnergoGasResultItem' }
  & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
  & { partner: (
    { __typename?: 'EnergoGasPartner' }
    & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
    & { rating: Maybe<(
      { __typename?: 'NewRating' }
      & Pick<NewRating, 'id' | 'total'>
      & { categories: Array<Maybe<(
        { __typename?: 'RatingCategory' }
        & Pick<RatingCategory, 'id' | 'label' | 'value'>
      )>> }
    )> }
  ), product: (
    { __typename?: 'EnergoGasProduct' }
    & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
  ), priceExpectedDetail: (
    { __typename?: 'EnergoGasResultPriceDetail' }
    & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
  ), priceResultDetail: (
    { __typename?: 'EnergoGasResultPriceDetail' }
    & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
  ) }
);

export type EnergoBreakerCodebookFragment = (
  { __typename?: 'EnergoBreaker' }
  & Pick<EnergoBreaker, 'id'>
  & { label: EnergoBreaker['name'] }
);

export type EnergoElectricityPartnerCodebookFragment = (
  { __typename?: 'EnergoElectricityPartnerCodebook' }
  & Pick<EnergoElectricityPartnerCodebook, 'id' | 'isActive' | 'seoName' | 'logoUrl' | 'invoiceHelpDistributionRateMD' | 'invoiceHelpInvoicedPaymentMD' | 'invoiceHelpHighTariffMD' | 'invoiceHelpLowTariffMD' | 'invoiceHelpBreakerMD' | 'invoiceHelpCurrentProductMD' | 'cooperationMethod' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'createdAt' | 'updatedAt' | 'adminLastEdit'>
  & { label: EnergoElectricityPartnerCodebook['name'] }
  & { invoiceHelpDistributionRateImage: Maybe<(
    { __typename?: 'File' }
    & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
  )>, invoiceHelpInvoicedPaymentImage: Maybe<(
    { __typename?: 'File' }
    & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
  )>, invoiceHelpHighTariffImage: Maybe<(
    { __typename?: 'File' }
    & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
  )>, invoiceHelpLowTariffImage: Maybe<(
    { __typename?: 'File' }
    & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
  )>, invoiceHelpBreakerImage: Maybe<(
    { __typename?: 'File' }
    & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
  )>, invoiceHelpCurrentProductImage: Maybe<(
    { __typename?: 'File' }
    & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
  )> }
);

export type EnergoGasPartnerCodebookFragment = (
  { __typename?: 'EnergoGasPartnerCodebook' }
  & Pick<EnergoGasPartnerCodebook, 'id' | 'isActive' | 'seoName' | 'logoUrl' | 'invoiceHelpYearlyConsumptionMD' | 'invoiceHelpInvoicedPaymentMD' | 'invoiceHelpCurrentProductMD' | 'cooperationMethod' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'createdAt' | 'updatedAt' | 'adminLastEdit'>
  & { label: EnergoGasPartnerCodebook['name'] }
  & { invoiceHelpYearlyConsumptionImage: Maybe<(
    { __typename?: 'File' }
    & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
  )>, invoiceHelpInvoicedPaymentImage: Maybe<(
    { __typename?: 'File' }
    & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
  )>, invoiceHelpCurrentProductImage: Maybe<(
    { __typename?: 'File' }
    & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
  )> }
);

export type EnergoDistributionRateCodebookFragment = (
  { __typename?: 'EnergoDistributionRate' }
  & Pick<EnergoDistributionRate, 'id' | 'description' | 'lowTariff'>
  & { label: EnergoDistributionRate['name'] }
);

export type EnergoStandaloneInvoiceFragment = (
  { __typename?: 'EnergoStandaloneInvoice' }
  & Pick<EnergoStandaloneInvoice, 'id' | 'uuid'>
);

export type EnergoElectricityResultPriceDetailFragment = (
  { __typename?: 'EnergoElectricityResultPriceDetail' }
  & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
);

export type EnergoGasResultPriceDetailFragment = (
  { __typename?: 'EnergoGasResultPriceDetail' }
  & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
);

export type EnergoPartnerFragment = (
  { __typename?: 'EnergoPartner' }
  & Pick<EnergoPartner, 'id' | 'name' | 'seoName' | 'descriptionMD' | 'title' | 'metaDescription' | 'metaTitle'>
  & { partnerElectro: Maybe<(
    { __typename?: 'EnergoElectricityPartnerDetail' }
    & Pick<EnergoElectricityPartnerDetail, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'cooperationMethod' | 'termsOfServicePreview' | 'companyAddress' | 'contactEmail' | 'tollFreePhone' | 'latitude' | 'longitude' | 'web' | 'urlVop' | 'urlPricelist' | 'longArticleMD' | 'isActive' | 'energoPartnerId' | 'title' | 'titleSuffix' | 'metaTitle' | 'metaDescription'>
    & { rating: Maybe<(
      { __typename?: 'NewRating' }
      & Pick<NewRating, 'id' | 'total'>
      & { categories: Array<Maybe<(
        { __typename?: 'RatingCategory' }
        & Pick<RatingCategory, 'id' | 'label' | 'value'>
      )>> }
    )> }
  )>, partnerGas: Maybe<(
    { __typename?: 'EnergoGasPartnerDetail' }
    & Pick<EnergoGasPartnerDetail, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'cooperationMethod' | 'termsOfServicePreview' | 'companyAddress' | 'contactEmail' | 'tollFreePhone' | 'latitude' | 'longitude' | 'web' | 'urlVop' | 'urlPricelist' | 'longArticleMD' | 'isActive' | 'energoPartnerId' | 'title' | 'titleSuffix' | 'metaTitle' | 'metaDescription'>
    & { rating: Maybe<(
      { __typename?: 'NewRating' }
      & Pick<NewRating, 'id' | 'total'>
      & { categories: Array<Maybe<(
        { __typename?: 'RatingCategory' }
        & Pick<RatingCategory, 'id' | 'label' | 'value'>
      )>> }
    )> }
  )> }
);

export type EnergoElectricityPriceProductPreviewItemFragment = (
  { __typename?: 'EnergoElectricityPriceProductPreviewItem' }
  & Pick<EnergoElectricityPriceProductPreviewItem, 'id' | 'priceMonthly' | 'priceMWhHighTariff' | 'priceMWhLowTariff' | 'distributionRateId' | 'distributorId'>
  & { product: (
    { __typename?: 'EnergoElectricityProductPreview' }
    & Pick<EnergoElectricityProductPreview, 'id' | 'name' | 'isActive' | 'isActiveForPurchase' | 'activeForNewCustomers' | 'activeForCurrentCustomers' | 'activeForSelectCurrentExact' | 'activeForSelectCurrentEstimate' | 'private' | 'preferredSale' | 'availableForSale'>
    & { partner: (
      { __typename?: 'EnergoElectricityPartnerPreview' }
      & Pick<EnergoElectricityPartnerPreview, 'id' | 'name' | 'energoPartnerId' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'cooperationMethod' | 'companyAddress' | 'contactEmail' | 'tollFreePhone' | 'longitude' | 'latitude' | 'web' | 'urlVop' | 'urlPricelist' | 'longArticleMD' | 'active' | 'estimateDefaultProductId'>
    ) }
  ) }
);

export type EnergoElectricityProductPreviewFragment = (
  { __typename?: 'EnergoElectricityProductPreview' }
  & Pick<EnergoElectricityProductPreview, 'id' | 'name' | 'isActive' | 'isActiveForPurchase' | 'activeForNewCustomers' | 'activeForCurrentCustomers' | 'activeForSelectCurrentExact' | 'activeForSelectCurrentEstimate' | 'private' | 'preferredSale' | 'availableForSale'>
  & { partner: (
    { __typename?: 'EnergoElectricityPartnerPreview' }
    & Pick<EnergoElectricityPartnerPreview, 'id' | 'name' | 'energoPartnerId' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'cooperationMethod' | 'companyAddress' | 'contactEmail' | 'tollFreePhone' | 'longitude' | 'latitude' | 'web' | 'urlVop' | 'urlPricelist' | 'longArticleMD' | 'active' | 'estimateDefaultProductId'>
  ) }
);

export type EnergoElectricityPartnerPreviewFragment = (
  { __typename?: 'EnergoElectricityPartnerPreview' }
  & Pick<EnergoElectricityPartnerPreview, 'id' | 'name' | 'energoPartnerId' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'cooperationMethod' | 'companyAddress' | 'contactEmail' | 'tollFreePhone' | 'longitude' | 'latitude' | 'web' | 'urlVop' | 'urlPricelist' | 'longArticleMD' | 'active' | 'estimateDefaultProductId'>
);

export type EnergoGasPriceProductPreviewItemFragment = (
  { __typename?: 'EnergoGasPriceProductPreviewItem' }
  & Pick<EnergoGasPriceProductPreviewItem, 'id' | 'pricePerM3' | 'priceMonthly' | 'priceMWh' | 'odberovePasmoId'>
  & { product: (
    { __typename?: 'EnergoGasProductPreview' }
    & Pick<EnergoGasProductPreview, 'id' | 'name' | 'isActive' | 'isActiveForPurchase' | 'activeForNewCustomers' | 'activeForCurrentCustomers' | 'activeForSelectCurrentExact' | 'activeForSelectCurrentEstimate' | 'private' | 'preferredSale' | 'availableForSale'>
    & { partner: (
      { __typename?: 'EnergoGasPartnerPreview' }
      & Pick<EnergoGasPartnerPreview, 'id' | 'name' | 'energoPartnerId' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'cooperationMethod' | 'companyAddress' | 'contactEmail' | 'tollFreePhone' | 'longitude' | 'latitude' | 'web' | 'urlVop' | 'urlPricelist' | 'longArticleMD' | 'active' | 'estimateDefaultProductId'>
    ) }
  ) }
);

export type EnergoGasProductPreviewFragment = (
  { __typename?: 'EnergoGasProductPreview' }
  & Pick<EnergoGasProductPreview, 'id' | 'name' | 'isActive' | 'isActiveForPurchase' | 'activeForNewCustomers' | 'activeForCurrentCustomers' | 'activeForSelectCurrentExact' | 'activeForSelectCurrentEstimate' | 'private' | 'preferredSale' | 'availableForSale'>
  & { partner: (
    { __typename?: 'EnergoGasPartnerPreview' }
    & Pick<EnergoGasPartnerPreview, 'id' | 'name' | 'energoPartnerId' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'cooperationMethod' | 'companyAddress' | 'contactEmail' | 'tollFreePhone' | 'longitude' | 'latitude' | 'web' | 'urlVop' | 'urlPricelist' | 'longArticleMD' | 'active' | 'estimateDefaultProductId'>
  ) }
);

export type EnergoGasPartnerPreviewFragment = (
  { __typename?: 'EnergoGasPartnerPreview' }
  & Pick<EnergoGasPartnerPreview, 'id' | 'name' | 'energoPartnerId' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'cooperationMethod' | 'companyAddress' | 'contactEmail' | 'tollFreePhone' | 'longitude' | 'latitude' | 'web' | 'urlVop' | 'urlPricelist' | 'longArticleMD' | 'active' | 'estimateDefaultProductId'>
);

export type EnergoCreateCalculationMutationVariables = Exact<{
  data: EnergoCreateCalculationInput;
}>;


export type EnergoCreateCalculationMutation = (
  { __typename?: 'Mutation' }
  & { energoCalculationCreate: (
    { __typename?: 'EnergoCalculation' }
    & Pick<EnergoCalculation, 'id' | 'uuid' | 'type' | 'source' | 'yearlyConsumption' | 'yearlyConsumptionUnit' | 'yearlyConsumptionLowTariff' | 'yearlyConsumptionLowTariffUnit' | 'yearlyPayment' | 'gasYearlyPayment' | 'gasYearlyConsumption' | 'gasYearlyConsumptionUnit' | 'gasMonthlyAdvances' | 'gasUnknownMonthlyAdvances' | 'monthlyAdvances' | 'unknownMonthlyAdvances' | 'email' | 'saving' | 'createdAt' | 'updatedAt' | 'lastViewed' | 'currentProductId' | 'currentProductName' | 'currentProductFollowingId' | 'unknownCurrentProduct' | 'gasCurrentProductId' | 'gasCurrentProductName' | 'gasCurrentProductFollowingId' | 'gasUnknownCurrentProduct' | 'phone' | 'individualPriceExpectedMonthly' | 'individualPriceExpectedMWhHighTariff' | 'individualPriceExpectedMWhLowTariff' | 'gasIndividualPriceExpectedMonthly' | 'gasIndividualPriceExpectedMWh' | 'individualOfferName' | 'reason'>
    & { address: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
      & { extended: Maybe<(
        { __typename?: 'SmartAddressExtendedInfo' }
        & Pick<SmartAddressExtendedInfo, 'id'>
        & { objectType: Maybe<(
          { __typename?: 'SmartAddressObjectType' }
          & Pick<SmartAddressObjectType, 'id' | 'nazev'>
        )> }
      )>, extendedV2: Maybe<(
        { __typename?: 'SmartAddressSdkExtendedInfo' }
        & { administrativniUdaje: Maybe<(
          { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
          & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )> }
      )> }
    )>, partner: Maybe<(
      { __typename?: 'EnergoElectricityPartner' }
      & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    )>, gasPartner: Maybe<(
      { __typename?: 'EnergoGasPartner' }
      & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    )>, gasUsages: Maybe<(
      { __typename?: 'GasUsages' }
      & Pick<GasUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
    )>, electricityUsages: Maybe<(
      { __typename?: 'ElectricityUsages' }
      & Pick<ElectricityUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
    )>, floorAreaRange: Maybe<(
      { __typename?: 'EnergoFloorAreaRangeCodebook' }
      & Pick<EnergoFloorAreaRangeCodebook, 'id'>
      & { label: EnergoFloorAreaRangeCodebook['name'] }
    )>, isolation: Maybe<(
      { __typename?: 'EnergoIsolationCodebook' }
      & Pick<EnergoIsolationCodebook, 'id'>
      & { label: EnergoIsolationCodebook['name'] }
    )>, personsCountRange: Maybe<(
      { __typename?: 'EnergoPersonsCountRangeCodebook' }
      & Pick<EnergoPersonsCountRangeCodebook, 'id'>
      & { label: EnergoPersonsCountRangeCodebook['name'] }
    )>, distributionRate: Maybe<(
      { __typename?: 'EnergoDistributionRate' }
      & Pick<EnergoDistributionRate, 'id' | 'name' | 'lowTariff'>
    )>, breaker: Maybe<(
      { __typename?: 'EnergoBreaker' }
      & Pick<EnergoBreaker, 'id' | 'name'>
    )>, standaloneInvoice: Maybe<(
      { __typename?: 'EnergoStandaloneInvoice' }
      & Pick<EnergoStandaloneInvoice, 'id' | 'uuid'>
    )>, bestResultItem: Maybe<(
      { __typename?: 'EnergoElectricityResultItem' }
      & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoElectricityPartner' }
        & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoElectricityProduct' }
        & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    )>, bestGasResultItem: Maybe<(
      { __typename?: 'EnergoGasResultItem' }
      & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoGasPartner' }
        & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoGasProduct' }
        & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    )> }
  ) }
);

export type EnergoCreateCalculationFromStandaloneInvoiceMutationVariables = Exact<{
  data: EnergoCreateCalculationFromStandaloneInput;
}>;


export type EnergoCreateCalculationFromStandaloneInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { energoCalculationCreateFromStandaloneInvoice: (
    { __typename?: 'EnergoCalculation' }
    & Pick<EnergoCalculation, 'id' | 'uuid' | 'type' | 'source' | 'yearlyConsumption' | 'yearlyConsumptionUnit' | 'yearlyConsumptionLowTariff' | 'yearlyConsumptionLowTariffUnit' | 'yearlyPayment' | 'gasYearlyPayment' | 'gasYearlyConsumption' | 'gasYearlyConsumptionUnit' | 'gasMonthlyAdvances' | 'gasUnknownMonthlyAdvances' | 'monthlyAdvances' | 'unknownMonthlyAdvances' | 'email' | 'saving' | 'createdAt' | 'updatedAt' | 'lastViewed' | 'currentProductId' | 'currentProductName' | 'currentProductFollowingId' | 'unknownCurrentProduct' | 'gasCurrentProductId' | 'gasCurrentProductName' | 'gasCurrentProductFollowingId' | 'gasUnknownCurrentProduct' | 'phone' | 'individualPriceExpectedMonthly' | 'individualPriceExpectedMWhHighTariff' | 'individualPriceExpectedMWhLowTariff' | 'gasIndividualPriceExpectedMonthly' | 'gasIndividualPriceExpectedMWh' | 'individualOfferName' | 'reason'>
    & { address: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
      & { extended: Maybe<(
        { __typename?: 'SmartAddressExtendedInfo' }
        & Pick<SmartAddressExtendedInfo, 'id'>
        & { objectType: Maybe<(
          { __typename?: 'SmartAddressObjectType' }
          & Pick<SmartAddressObjectType, 'id' | 'nazev'>
        )> }
      )>, extendedV2: Maybe<(
        { __typename?: 'SmartAddressSdkExtendedInfo' }
        & { administrativniUdaje: Maybe<(
          { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
          & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )> }
      )> }
    )>, partner: Maybe<(
      { __typename?: 'EnergoElectricityPartner' }
      & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    )>, gasPartner: Maybe<(
      { __typename?: 'EnergoGasPartner' }
      & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    )>, gasUsages: Maybe<(
      { __typename?: 'GasUsages' }
      & Pick<GasUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
    )>, electricityUsages: Maybe<(
      { __typename?: 'ElectricityUsages' }
      & Pick<ElectricityUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
    )>, floorAreaRange: Maybe<(
      { __typename?: 'EnergoFloorAreaRangeCodebook' }
      & Pick<EnergoFloorAreaRangeCodebook, 'id'>
      & { label: EnergoFloorAreaRangeCodebook['name'] }
    )>, isolation: Maybe<(
      { __typename?: 'EnergoIsolationCodebook' }
      & Pick<EnergoIsolationCodebook, 'id'>
      & { label: EnergoIsolationCodebook['name'] }
    )>, personsCountRange: Maybe<(
      { __typename?: 'EnergoPersonsCountRangeCodebook' }
      & Pick<EnergoPersonsCountRangeCodebook, 'id'>
      & { label: EnergoPersonsCountRangeCodebook['name'] }
    )>, distributionRate: Maybe<(
      { __typename?: 'EnergoDistributionRate' }
      & Pick<EnergoDistributionRate, 'id' | 'name' | 'lowTariff'>
    )>, breaker: Maybe<(
      { __typename?: 'EnergoBreaker' }
      & Pick<EnergoBreaker, 'id' | 'name'>
    )>, standaloneInvoice: Maybe<(
      { __typename?: 'EnergoStandaloneInvoice' }
      & Pick<EnergoStandaloneInvoice, 'id' | 'uuid'>
    )>, bestResultItem: Maybe<(
      { __typename?: 'EnergoElectricityResultItem' }
      & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoElectricityPartner' }
        & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoElectricityProduct' }
        & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    )>, bestGasResultItem: Maybe<(
      { __typename?: 'EnergoGasResultItem' }
      & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoGasPartner' }
        & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoGasProduct' }
        & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    )> }
  ) }
);

export type EnergoStandaloneInvoiceCreateMutationVariables = Exact<{
  data: EnergoStandaloneInvoiceCreateInput;
}>;


export type EnergoStandaloneInvoiceCreateMutation = (
  { __typename?: 'Mutation' }
  & { energoStandaloneInvoiceCreate: (
    { __typename?: 'EnergoStandaloneInvoice' }
    & Pick<EnergoStandaloneInvoice, 'id' | 'uuid'>
  ) }
);

export type EnergoCreateStandaloneInvoiceFromLandingPageMutationVariables = Exact<{
  data: EnergoCreateStandaloneInvoiceFromLandingPageInput;
}>;


export type EnergoCreateStandaloneInvoiceFromLandingPageMutation = (
  { __typename?: 'Mutation' }
  & { energoStandaloneInvoiceCreateFromLandingPage: (
    { __typename?: 'EnergoStandaloneInvoice' }
    & Pick<EnergoStandaloneInvoice, 'id' | 'uuid'>
  ) }
);

export type EnergoCreateStandaloneInvoiceWithEmailFromLandingPageMutationVariables = Exact<{
  data: EnergoCreateStandaloneInvoiceWithEmailFromLandingPageInput;
}>;


export type EnergoCreateStandaloneInvoiceWithEmailFromLandingPageMutation = (
  { __typename?: 'Mutation' }
  & { energoStandaloneInvoiceWithEmailCreateFromLandingPage: (
    { __typename?: 'EnergoStandaloneInvoice' }
    & Pick<EnergoStandaloneInvoice, 'id' | 'uuid'>
  ) }
);

export type EnergoCreateStandaloneOptionalInvoiceFromLandingPageMutationVariables = Exact<{
  data: EnergoCreateStandaloneOptionalInvoiceFromLandingPageInput;
}>;


export type EnergoCreateStandaloneOptionalInvoiceFromLandingPageMutation = (
  { __typename?: 'Mutation' }
  & { energoStandaloneOptionalInvoiceCreateFromLandingPage: (
    { __typename?: 'CoreStatus' }
    & Pick<CoreStatus, 'success'>
  ) }
);

export type EnergoCreateStandaloneInvoiceFromWolfPageMutationVariables = Exact<{
  data: EnergoCreateStandaloneInvoiceFromWolfPageInput;
}>;


export type EnergoCreateStandaloneInvoiceFromWolfPageMutation = (
  { __typename?: 'Mutation' }
  & { energoStandaloneInvoiceCreateFromWolfPage: (
    { __typename?: 'EnergoStandaloneInvoice' }
    & Pick<EnergoStandaloneInvoice, 'id' | 'uuid'>
  ) }
);

export type EnergoUpdateCalculationMutationVariables = Exact<{
  calculationUuid: Scalars['String']['input'];
  data: EnergoUpdateCalculationInput;
}>;


export type EnergoUpdateCalculationMutation = (
  { __typename?: 'Mutation' }
  & { energoCalculationUpdate: (
    { __typename?: 'EnergoCalculation' }
    & Pick<EnergoCalculation, 'id' | 'uuid' | 'type' | 'source' | 'yearlyConsumption' | 'yearlyConsumptionUnit' | 'yearlyConsumptionLowTariff' | 'yearlyConsumptionLowTariffUnit' | 'yearlyPayment' | 'gasYearlyPayment' | 'gasYearlyConsumption' | 'gasYearlyConsumptionUnit' | 'gasMonthlyAdvances' | 'gasUnknownMonthlyAdvances' | 'monthlyAdvances' | 'unknownMonthlyAdvances' | 'email' | 'saving' | 'createdAt' | 'updatedAt' | 'lastViewed' | 'currentProductId' | 'currentProductName' | 'currentProductFollowingId' | 'unknownCurrentProduct' | 'gasCurrentProductId' | 'gasCurrentProductName' | 'gasCurrentProductFollowingId' | 'gasUnknownCurrentProduct' | 'phone' | 'individualPriceExpectedMonthly' | 'individualPriceExpectedMWhHighTariff' | 'individualPriceExpectedMWhLowTariff' | 'gasIndividualPriceExpectedMonthly' | 'gasIndividualPriceExpectedMWh' | 'individualOfferName' | 'reason'>
    & { address: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
      & { extended: Maybe<(
        { __typename?: 'SmartAddressExtendedInfo' }
        & Pick<SmartAddressExtendedInfo, 'id'>
        & { objectType: Maybe<(
          { __typename?: 'SmartAddressObjectType' }
          & Pick<SmartAddressObjectType, 'id' | 'nazev'>
        )> }
      )>, extendedV2: Maybe<(
        { __typename?: 'SmartAddressSdkExtendedInfo' }
        & { administrativniUdaje: Maybe<(
          { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
          & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )> }
      )> }
    )>, partner: Maybe<(
      { __typename?: 'EnergoElectricityPartner' }
      & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    )>, gasPartner: Maybe<(
      { __typename?: 'EnergoGasPartner' }
      & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    )>, gasUsages: Maybe<(
      { __typename?: 'GasUsages' }
      & Pick<GasUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
    )>, electricityUsages: Maybe<(
      { __typename?: 'ElectricityUsages' }
      & Pick<ElectricityUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
    )>, floorAreaRange: Maybe<(
      { __typename?: 'EnergoFloorAreaRangeCodebook' }
      & Pick<EnergoFloorAreaRangeCodebook, 'id'>
      & { label: EnergoFloorAreaRangeCodebook['name'] }
    )>, isolation: Maybe<(
      { __typename?: 'EnergoIsolationCodebook' }
      & Pick<EnergoIsolationCodebook, 'id'>
      & { label: EnergoIsolationCodebook['name'] }
    )>, personsCountRange: Maybe<(
      { __typename?: 'EnergoPersonsCountRangeCodebook' }
      & Pick<EnergoPersonsCountRangeCodebook, 'id'>
      & { label: EnergoPersonsCountRangeCodebook['name'] }
    )>, distributionRate: Maybe<(
      { __typename?: 'EnergoDistributionRate' }
      & Pick<EnergoDistributionRate, 'id' | 'name' | 'lowTariff'>
    )>, breaker: Maybe<(
      { __typename?: 'EnergoBreaker' }
      & Pick<EnergoBreaker, 'id' | 'name'>
    )>, standaloneInvoice: Maybe<(
      { __typename?: 'EnergoStandaloneInvoice' }
      & Pick<EnergoStandaloneInvoice, 'id' | 'uuid'>
    )>, bestResultItem: Maybe<(
      { __typename?: 'EnergoElectricityResultItem' }
      & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoElectricityPartner' }
        & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoElectricityProduct' }
        & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    )>, bestGasResultItem: Maybe<(
      { __typename?: 'EnergoGasResultItem' }
      & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoGasPartner' }
        & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoGasProduct' }
        & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    )> }
  ) }
);

export type EnergoCreateElectricityLeadMutationVariables = Exact<{
  calculationUuid: Scalars['String']['input'];
  selectedProductId: Scalars['Int']['input'];
}>;


export type EnergoCreateElectricityLeadMutation = (
  { __typename?: 'Mutation' }
  & { energoElectricityLeadCreate: (
    { __typename?: 'EnergoElectricityLead' }
    & Pick<EnergoElectricityLead, 'id' | 'yearlyPayment' | 'yearlyConsumption' | 'yearlyConsumptionLowTariff' | 'currentProductId' | 'currentProductName'>
    & { lead: (
      { __typename?: 'Lead' }
      & Pick<Lead, 'id' | 'type' | 'uuid' | 'name' | 'surname' | 'email' | 'phone' | 'status' | 'customerNote' | 'lastStatusChange' | 'completedAt' | 'sendEmailImmediately'>
      & { address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, contactAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, attachments: Array<(
        { __typename?: 'LeadAttachment' }
        & Pick<LeadAttachment, 'id'>
        & { file: (
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        ) }
      )> }
    ), calculation: (
      { __typename?: 'EnergoCalculation' }
      & Pick<EnergoCalculation, 'id' | 'uuid' | 'type' | 'source' | 'yearlyConsumption' | 'yearlyConsumptionUnit' | 'yearlyConsumptionLowTariff' | 'yearlyConsumptionLowTariffUnit' | 'yearlyPayment' | 'gasYearlyPayment' | 'gasYearlyConsumption' | 'gasYearlyConsumptionUnit' | 'gasMonthlyAdvances' | 'gasUnknownMonthlyAdvances' | 'monthlyAdvances' | 'unknownMonthlyAdvances' | 'email' | 'saving' | 'createdAt' | 'updatedAt' | 'lastViewed' | 'currentProductId' | 'currentProductName' | 'currentProductFollowingId' | 'unknownCurrentProduct' | 'gasCurrentProductId' | 'gasCurrentProductName' | 'gasCurrentProductFollowingId' | 'gasUnknownCurrentProduct' | 'phone' | 'individualPriceExpectedMonthly' | 'individualPriceExpectedMWhHighTariff' | 'individualPriceExpectedMWhLowTariff' | 'gasIndividualPriceExpectedMonthly' | 'gasIndividualPriceExpectedMWh' | 'individualOfferName' | 'reason'>
      & { address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, partner: Maybe<(
        { __typename?: 'EnergoElectricityPartner' }
        & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      )>, gasPartner: Maybe<(
        { __typename?: 'EnergoGasPartner' }
        & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      )>, gasUsages: Maybe<(
        { __typename?: 'GasUsages' }
        & Pick<GasUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
      )>, electricityUsages: Maybe<(
        { __typename?: 'ElectricityUsages' }
        & Pick<ElectricityUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
      )>, floorAreaRange: Maybe<(
        { __typename?: 'EnergoFloorAreaRangeCodebook' }
        & Pick<EnergoFloorAreaRangeCodebook, 'id'>
        & { label: EnergoFloorAreaRangeCodebook['name'] }
      )>, isolation: Maybe<(
        { __typename?: 'EnergoIsolationCodebook' }
        & Pick<EnergoIsolationCodebook, 'id'>
        & { label: EnergoIsolationCodebook['name'] }
      )>, personsCountRange: Maybe<(
        { __typename?: 'EnergoPersonsCountRangeCodebook' }
        & Pick<EnergoPersonsCountRangeCodebook, 'id'>
        & { label: EnergoPersonsCountRangeCodebook['name'] }
      )>, distributionRate: Maybe<(
        { __typename?: 'EnergoDistributionRate' }
        & Pick<EnergoDistributionRate, 'id' | 'name' | 'lowTariff'>
      )>, breaker: Maybe<(
        { __typename?: 'EnergoBreaker' }
        & Pick<EnergoBreaker, 'id' | 'name'>
      )>, standaloneInvoice: Maybe<(
        { __typename?: 'EnergoStandaloneInvoice' }
        & Pick<EnergoStandaloneInvoice, 'id' | 'uuid'>
      )>, bestResultItem: Maybe<(
        { __typename?: 'EnergoElectricityResultItem' }
        & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
        & { partner: (
          { __typename?: 'EnergoElectricityPartner' }
          & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
          & { rating: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), product: (
          { __typename?: 'EnergoElectricityProduct' }
          & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
        ), priceExpectedDetail: (
          { __typename?: 'EnergoElectricityResultPriceDetail' }
          & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ), priceResultDetail: (
          { __typename?: 'EnergoElectricityResultPriceDetail' }
          & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ) }
      )>, bestGasResultItem: Maybe<(
        { __typename?: 'EnergoGasResultItem' }
        & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
        & { partner: (
          { __typename?: 'EnergoGasPartner' }
          & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
          & { rating: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), product: (
          { __typename?: 'EnergoGasProduct' }
          & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
        ), priceExpectedDetail: (
          { __typename?: 'EnergoGasResultPriceDetail' }
          & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ), priceResultDetail: (
          { __typename?: 'EnergoGasResultPriceDetail' }
          & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ) }
      )> }
    ), resultItem: (
      { __typename?: 'EnergoElectricityResultItem' }
      & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoElectricityPartner' }
        & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoElectricityProduct' }
        & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    ), userResultItem: Maybe<(
      { __typename?: 'EnergoElectricityResultItem' }
      & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoElectricityPartner' }
        & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoElectricityProduct' }
        & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    )>, partner: Maybe<(
      { __typename?: 'EnergoElectricityPartner' }
      & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    )>, breaker: Maybe<(
      { __typename?: 'EnergoBreaker' }
      & Pick<EnergoBreaker, 'id'>
      & { label: EnergoBreaker['name'] }
    )>, distributionRate: Maybe<(
      { __typename?: 'EnergoDistributionRate' }
      & Pick<EnergoDistributionRate, 'id' | 'description' | 'lowTariff'>
      & { label: EnergoDistributionRate['name'] }
    )> }
  ) }
);

export type EnergoCreateGasLeadMutationVariables = Exact<{
  calculationUuid: Scalars['String']['input'];
  selectedProductId: Scalars['Int']['input'];
}>;


export type EnergoCreateGasLeadMutation = (
  { __typename?: 'Mutation' }
  & { energoGasLeadCreate: (
    { __typename?: 'EnergoGasLead' }
    & Pick<EnergoGasLead, 'id' | 'yearlyPayment' | 'yearlyConsumption' | 'currentProductId' | 'currentProductName'>
    & { lead: (
      { __typename?: 'Lead' }
      & Pick<Lead, 'id' | 'type' | 'uuid' | 'name' | 'surname' | 'email' | 'phone' | 'status' | 'customerNote' | 'lastStatusChange' | 'completedAt' | 'sendEmailImmediately'>
      & { address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, contactAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, attachments: Array<(
        { __typename?: 'LeadAttachment' }
        & Pick<LeadAttachment, 'id'>
        & { file: (
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        ) }
      )> }
    ), calculation: (
      { __typename?: 'EnergoCalculation' }
      & Pick<EnergoCalculation, 'id' | 'uuid' | 'type' | 'source' | 'yearlyConsumption' | 'yearlyConsumptionUnit' | 'yearlyConsumptionLowTariff' | 'yearlyConsumptionLowTariffUnit' | 'yearlyPayment' | 'gasYearlyPayment' | 'gasYearlyConsumption' | 'gasYearlyConsumptionUnit' | 'gasMonthlyAdvances' | 'gasUnknownMonthlyAdvances' | 'monthlyAdvances' | 'unknownMonthlyAdvances' | 'email' | 'saving' | 'createdAt' | 'updatedAt' | 'lastViewed' | 'currentProductId' | 'currentProductName' | 'currentProductFollowingId' | 'unknownCurrentProduct' | 'gasCurrentProductId' | 'gasCurrentProductName' | 'gasCurrentProductFollowingId' | 'gasUnknownCurrentProduct' | 'phone' | 'individualPriceExpectedMonthly' | 'individualPriceExpectedMWhHighTariff' | 'individualPriceExpectedMWhLowTariff' | 'gasIndividualPriceExpectedMonthly' | 'gasIndividualPriceExpectedMWh' | 'individualOfferName' | 'reason'>
      & { address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, partner: Maybe<(
        { __typename?: 'EnergoElectricityPartner' }
        & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      )>, gasPartner: Maybe<(
        { __typename?: 'EnergoGasPartner' }
        & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      )>, gasUsages: Maybe<(
        { __typename?: 'GasUsages' }
        & Pick<GasUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
      )>, electricityUsages: Maybe<(
        { __typename?: 'ElectricityUsages' }
        & Pick<ElectricityUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
      )>, floorAreaRange: Maybe<(
        { __typename?: 'EnergoFloorAreaRangeCodebook' }
        & Pick<EnergoFloorAreaRangeCodebook, 'id'>
        & { label: EnergoFloorAreaRangeCodebook['name'] }
      )>, isolation: Maybe<(
        { __typename?: 'EnergoIsolationCodebook' }
        & Pick<EnergoIsolationCodebook, 'id'>
        & { label: EnergoIsolationCodebook['name'] }
      )>, personsCountRange: Maybe<(
        { __typename?: 'EnergoPersonsCountRangeCodebook' }
        & Pick<EnergoPersonsCountRangeCodebook, 'id'>
        & { label: EnergoPersonsCountRangeCodebook['name'] }
      )>, distributionRate: Maybe<(
        { __typename?: 'EnergoDistributionRate' }
        & Pick<EnergoDistributionRate, 'id' | 'name' | 'lowTariff'>
      )>, breaker: Maybe<(
        { __typename?: 'EnergoBreaker' }
        & Pick<EnergoBreaker, 'id' | 'name'>
      )>, standaloneInvoice: Maybe<(
        { __typename?: 'EnergoStandaloneInvoice' }
        & Pick<EnergoStandaloneInvoice, 'id' | 'uuid'>
      )>, bestResultItem: Maybe<(
        { __typename?: 'EnergoElectricityResultItem' }
        & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
        & { partner: (
          { __typename?: 'EnergoElectricityPartner' }
          & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
          & { rating: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), product: (
          { __typename?: 'EnergoElectricityProduct' }
          & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
        ), priceExpectedDetail: (
          { __typename?: 'EnergoElectricityResultPriceDetail' }
          & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ), priceResultDetail: (
          { __typename?: 'EnergoElectricityResultPriceDetail' }
          & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ) }
      )>, bestGasResultItem: Maybe<(
        { __typename?: 'EnergoGasResultItem' }
        & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
        & { partner: (
          { __typename?: 'EnergoGasPartner' }
          & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
          & { rating: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), product: (
          { __typename?: 'EnergoGasProduct' }
          & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
        ), priceExpectedDetail: (
          { __typename?: 'EnergoGasResultPriceDetail' }
          & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ), priceResultDetail: (
          { __typename?: 'EnergoGasResultPriceDetail' }
          & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ) }
      )> }
    ), resultItem: (
      { __typename?: 'EnergoGasResultItem' }
      & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoGasPartner' }
        & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoGasProduct' }
        & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    ), userResultItem: Maybe<(
      { __typename?: 'EnergoGasResultItem' }
      & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoGasPartner' }
        & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoGasProduct' }
        & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    )>, partner: Maybe<(
      { __typename?: 'EnergoGasPartner' }
      & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    )> }
  ) }
);

export type EnergoChangeCalculationSourceMutationVariables = Exact<{
  uuid: Scalars['String']['input'];
  source: EnergoCalculationSource;
}>;


export type EnergoChangeCalculationSourceMutation = (
  { __typename?: 'Mutation' }
  & { energoCalculationChangeSource: (
    { __typename?: 'EnergoCalculation' }
    & Pick<EnergoCalculation, 'id' | 'uuid' | 'type' | 'source' | 'yearlyConsumption' | 'yearlyConsumptionUnit' | 'yearlyConsumptionLowTariff' | 'yearlyConsumptionLowTariffUnit' | 'yearlyPayment' | 'gasYearlyPayment' | 'gasYearlyConsumption' | 'gasYearlyConsumptionUnit' | 'gasMonthlyAdvances' | 'gasUnknownMonthlyAdvances' | 'monthlyAdvances' | 'unknownMonthlyAdvances' | 'email' | 'saving' | 'createdAt' | 'updatedAt' | 'lastViewed' | 'currentProductId' | 'currentProductName' | 'currentProductFollowingId' | 'unknownCurrentProduct' | 'gasCurrentProductId' | 'gasCurrentProductName' | 'gasCurrentProductFollowingId' | 'gasUnknownCurrentProduct' | 'phone' | 'individualPriceExpectedMonthly' | 'individualPriceExpectedMWhHighTariff' | 'individualPriceExpectedMWhLowTariff' | 'gasIndividualPriceExpectedMonthly' | 'gasIndividualPriceExpectedMWh' | 'individualOfferName' | 'reason'>
    & { address: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
      & { extended: Maybe<(
        { __typename?: 'SmartAddressExtendedInfo' }
        & Pick<SmartAddressExtendedInfo, 'id'>
        & { objectType: Maybe<(
          { __typename?: 'SmartAddressObjectType' }
          & Pick<SmartAddressObjectType, 'id' | 'nazev'>
        )> }
      )>, extendedV2: Maybe<(
        { __typename?: 'SmartAddressSdkExtendedInfo' }
        & { administrativniUdaje: Maybe<(
          { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
          & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )> }
      )> }
    )>, partner: Maybe<(
      { __typename?: 'EnergoElectricityPartner' }
      & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    )>, gasPartner: Maybe<(
      { __typename?: 'EnergoGasPartner' }
      & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    )>, gasUsages: Maybe<(
      { __typename?: 'GasUsages' }
      & Pick<GasUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
    )>, electricityUsages: Maybe<(
      { __typename?: 'ElectricityUsages' }
      & Pick<ElectricityUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
    )>, floorAreaRange: Maybe<(
      { __typename?: 'EnergoFloorAreaRangeCodebook' }
      & Pick<EnergoFloorAreaRangeCodebook, 'id'>
      & { label: EnergoFloorAreaRangeCodebook['name'] }
    )>, isolation: Maybe<(
      { __typename?: 'EnergoIsolationCodebook' }
      & Pick<EnergoIsolationCodebook, 'id'>
      & { label: EnergoIsolationCodebook['name'] }
    )>, personsCountRange: Maybe<(
      { __typename?: 'EnergoPersonsCountRangeCodebook' }
      & Pick<EnergoPersonsCountRangeCodebook, 'id'>
      & { label: EnergoPersonsCountRangeCodebook['name'] }
    )>, distributionRate: Maybe<(
      { __typename?: 'EnergoDistributionRate' }
      & Pick<EnergoDistributionRate, 'id' | 'name' | 'lowTariff'>
    )>, breaker: Maybe<(
      { __typename?: 'EnergoBreaker' }
      & Pick<EnergoBreaker, 'id' | 'name'>
    )>, standaloneInvoice: Maybe<(
      { __typename?: 'EnergoStandaloneInvoice' }
      & Pick<EnergoStandaloneInvoice, 'id' | 'uuid'>
    )>, bestResultItem: Maybe<(
      { __typename?: 'EnergoElectricityResultItem' }
      & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoElectricityPartner' }
        & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoElectricityProduct' }
        & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    )>, bestGasResultItem: Maybe<(
      { __typename?: 'EnergoGasResultItem' }
      & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoGasPartner' }
        & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoGasProduct' }
        & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    )> }
  ) }
);

export type CancelLeadMutationVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;


export type CancelLeadMutation = (
  { __typename?: 'Mutation' }
  & { cancelLead: (
    { __typename?: 'Lead' }
    & Pick<Lead, 'id' | 'status' | 'lastStatusChange'>
  ) }
);

export type EnergoElectricityLeadSavePersonalDataMutationVariables = Exact<{
  leadUuid: Scalars['String']['input'];
  data: EnergoLeadPersonalDataInput;
}>;


export type EnergoElectricityLeadSavePersonalDataMutation = (
  { __typename?: 'Mutation' }
  & { energoElectricityLeadSavePersonalData: (
    { __typename?: 'EnergoElectricityLead' }
    & Pick<EnergoElectricityLead, 'id' | 'yearlyPayment' | 'yearlyConsumption' | 'yearlyConsumptionLowTariff' | 'currentProductId' | 'currentProductName'>
    & { lead: (
      { __typename?: 'Lead' }
      & Pick<Lead, 'id' | 'type' | 'uuid' | 'name' | 'surname' | 'email' | 'phone' | 'status' | 'customerNote' | 'lastStatusChange' | 'completedAt' | 'sendEmailImmediately'>
      & { address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, contactAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, attachments: Array<(
        { __typename?: 'LeadAttachment' }
        & Pick<LeadAttachment, 'id'>
        & { file: (
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        ) }
      )> }
    ), calculation: (
      { __typename?: 'EnergoCalculation' }
      & Pick<EnergoCalculation, 'id' | 'uuid' | 'type' | 'source' | 'yearlyConsumption' | 'yearlyConsumptionUnit' | 'yearlyConsumptionLowTariff' | 'yearlyConsumptionLowTariffUnit' | 'yearlyPayment' | 'gasYearlyPayment' | 'gasYearlyConsumption' | 'gasYearlyConsumptionUnit' | 'gasMonthlyAdvances' | 'gasUnknownMonthlyAdvances' | 'monthlyAdvances' | 'unknownMonthlyAdvances' | 'email' | 'saving' | 'createdAt' | 'updatedAt' | 'lastViewed' | 'currentProductId' | 'currentProductName' | 'currentProductFollowingId' | 'unknownCurrentProduct' | 'gasCurrentProductId' | 'gasCurrentProductName' | 'gasCurrentProductFollowingId' | 'gasUnknownCurrentProduct' | 'phone' | 'individualPriceExpectedMonthly' | 'individualPriceExpectedMWhHighTariff' | 'individualPriceExpectedMWhLowTariff' | 'gasIndividualPriceExpectedMonthly' | 'gasIndividualPriceExpectedMWh' | 'individualOfferName' | 'reason'>
      & { address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, partner: Maybe<(
        { __typename?: 'EnergoElectricityPartner' }
        & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      )>, gasPartner: Maybe<(
        { __typename?: 'EnergoGasPartner' }
        & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      )>, gasUsages: Maybe<(
        { __typename?: 'GasUsages' }
        & Pick<GasUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
      )>, electricityUsages: Maybe<(
        { __typename?: 'ElectricityUsages' }
        & Pick<ElectricityUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
      )>, floorAreaRange: Maybe<(
        { __typename?: 'EnergoFloorAreaRangeCodebook' }
        & Pick<EnergoFloorAreaRangeCodebook, 'id'>
        & { label: EnergoFloorAreaRangeCodebook['name'] }
      )>, isolation: Maybe<(
        { __typename?: 'EnergoIsolationCodebook' }
        & Pick<EnergoIsolationCodebook, 'id'>
        & { label: EnergoIsolationCodebook['name'] }
      )>, personsCountRange: Maybe<(
        { __typename?: 'EnergoPersonsCountRangeCodebook' }
        & Pick<EnergoPersonsCountRangeCodebook, 'id'>
        & { label: EnergoPersonsCountRangeCodebook['name'] }
      )>, distributionRate: Maybe<(
        { __typename?: 'EnergoDistributionRate' }
        & Pick<EnergoDistributionRate, 'id' | 'name' | 'lowTariff'>
      )>, breaker: Maybe<(
        { __typename?: 'EnergoBreaker' }
        & Pick<EnergoBreaker, 'id' | 'name'>
      )>, standaloneInvoice: Maybe<(
        { __typename?: 'EnergoStandaloneInvoice' }
        & Pick<EnergoStandaloneInvoice, 'id' | 'uuid'>
      )>, bestResultItem: Maybe<(
        { __typename?: 'EnergoElectricityResultItem' }
        & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
        & { partner: (
          { __typename?: 'EnergoElectricityPartner' }
          & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
          & { rating: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), product: (
          { __typename?: 'EnergoElectricityProduct' }
          & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
        ), priceExpectedDetail: (
          { __typename?: 'EnergoElectricityResultPriceDetail' }
          & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ), priceResultDetail: (
          { __typename?: 'EnergoElectricityResultPriceDetail' }
          & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ) }
      )>, bestGasResultItem: Maybe<(
        { __typename?: 'EnergoGasResultItem' }
        & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
        & { partner: (
          { __typename?: 'EnergoGasPartner' }
          & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
          & { rating: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), product: (
          { __typename?: 'EnergoGasProduct' }
          & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
        ), priceExpectedDetail: (
          { __typename?: 'EnergoGasResultPriceDetail' }
          & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ), priceResultDetail: (
          { __typename?: 'EnergoGasResultPriceDetail' }
          & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ) }
      )> }
    ), resultItem: (
      { __typename?: 'EnergoElectricityResultItem' }
      & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoElectricityPartner' }
        & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoElectricityProduct' }
        & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    ), userResultItem: Maybe<(
      { __typename?: 'EnergoElectricityResultItem' }
      & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoElectricityPartner' }
        & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoElectricityProduct' }
        & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    )>, partner: Maybe<(
      { __typename?: 'EnergoElectricityPartner' }
      & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    )>, breaker: Maybe<(
      { __typename?: 'EnergoBreaker' }
      & Pick<EnergoBreaker, 'id'>
      & { label: EnergoBreaker['name'] }
    )>, distributionRate: Maybe<(
      { __typename?: 'EnergoDistributionRate' }
      & Pick<EnergoDistributionRate, 'id' | 'description' | 'lowTariff'>
      & { label: EnergoDistributionRate['name'] }
    )> }
  ) }
);

export type EnergoGasLeadSavePersonalDataMutationVariables = Exact<{
  leadUuid: Scalars['String']['input'];
  data: EnergoLeadPersonalDataInput;
}>;


export type EnergoGasLeadSavePersonalDataMutation = (
  { __typename?: 'Mutation' }
  & { energoGasLeadSavePersonalData: (
    { __typename?: 'EnergoGasLead' }
    & Pick<EnergoGasLead, 'id' | 'yearlyPayment' | 'yearlyConsumption' | 'currentProductId' | 'currentProductName'>
    & { lead: (
      { __typename?: 'Lead' }
      & Pick<Lead, 'id' | 'type' | 'uuid' | 'name' | 'surname' | 'email' | 'phone' | 'status' | 'customerNote' | 'lastStatusChange' | 'completedAt' | 'sendEmailImmediately'>
      & { address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, contactAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, attachments: Array<(
        { __typename?: 'LeadAttachment' }
        & Pick<LeadAttachment, 'id'>
        & { file: (
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        ) }
      )> }
    ), calculation: (
      { __typename?: 'EnergoCalculation' }
      & Pick<EnergoCalculation, 'id' | 'uuid' | 'type' | 'source' | 'yearlyConsumption' | 'yearlyConsumptionUnit' | 'yearlyConsumptionLowTariff' | 'yearlyConsumptionLowTariffUnit' | 'yearlyPayment' | 'gasYearlyPayment' | 'gasYearlyConsumption' | 'gasYearlyConsumptionUnit' | 'gasMonthlyAdvances' | 'gasUnknownMonthlyAdvances' | 'monthlyAdvances' | 'unknownMonthlyAdvances' | 'email' | 'saving' | 'createdAt' | 'updatedAt' | 'lastViewed' | 'currentProductId' | 'currentProductName' | 'currentProductFollowingId' | 'unknownCurrentProduct' | 'gasCurrentProductId' | 'gasCurrentProductName' | 'gasCurrentProductFollowingId' | 'gasUnknownCurrentProduct' | 'phone' | 'individualPriceExpectedMonthly' | 'individualPriceExpectedMWhHighTariff' | 'individualPriceExpectedMWhLowTariff' | 'gasIndividualPriceExpectedMonthly' | 'gasIndividualPriceExpectedMWh' | 'individualOfferName' | 'reason'>
      & { address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, partner: Maybe<(
        { __typename?: 'EnergoElectricityPartner' }
        & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      )>, gasPartner: Maybe<(
        { __typename?: 'EnergoGasPartner' }
        & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      )>, gasUsages: Maybe<(
        { __typename?: 'GasUsages' }
        & Pick<GasUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
      )>, electricityUsages: Maybe<(
        { __typename?: 'ElectricityUsages' }
        & Pick<ElectricityUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
      )>, floorAreaRange: Maybe<(
        { __typename?: 'EnergoFloorAreaRangeCodebook' }
        & Pick<EnergoFloorAreaRangeCodebook, 'id'>
        & { label: EnergoFloorAreaRangeCodebook['name'] }
      )>, isolation: Maybe<(
        { __typename?: 'EnergoIsolationCodebook' }
        & Pick<EnergoIsolationCodebook, 'id'>
        & { label: EnergoIsolationCodebook['name'] }
      )>, personsCountRange: Maybe<(
        { __typename?: 'EnergoPersonsCountRangeCodebook' }
        & Pick<EnergoPersonsCountRangeCodebook, 'id'>
        & { label: EnergoPersonsCountRangeCodebook['name'] }
      )>, distributionRate: Maybe<(
        { __typename?: 'EnergoDistributionRate' }
        & Pick<EnergoDistributionRate, 'id' | 'name' | 'lowTariff'>
      )>, breaker: Maybe<(
        { __typename?: 'EnergoBreaker' }
        & Pick<EnergoBreaker, 'id' | 'name'>
      )>, standaloneInvoice: Maybe<(
        { __typename?: 'EnergoStandaloneInvoice' }
        & Pick<EnergoStandaloneInvoice, 'id' | 'uuid'>
      )>, bestResultItem: Maybe<(
        { __typename?: 'EnergoElectricityResultItem' }
        & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
        & { partner: (
          { __typename?: 'EnergoElectricityPartner' }
          & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
          & { rating: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), product: (
          { __typename?: 'EnergoElectricityProduct' }
          & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
        ), priceExpectedDetail: (
          { __typename?: 'EnergoElectricityResultPriceDetail' }
          & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ), priceResultDetail: (
          { __typename?: 'EnergoElectricityResultPriceDetail' }
          & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ) }
      )>, bestGasResultItem: Maybe<(
        { __typename?: 'EnergoGasResultItem' }
        & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
        & { partner: (
          { __typename?: 'EnergoGasPartner' }
          & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
          & { rating: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), product: (
          { __typename?: 'EnergoGasProduct' }
          & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
        ), priceExpectedDetail: (
          { __typename?: 'EnergoGasResultPriceDetail' }
          & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ), priceResultDetail: (
          { __typename?: 'EnergoGasResultPriceDetail' }
          & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ) }
      )> }
    ), resultItem: (
      { __typename?: 'EnergoGasResultItem' }
      & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoGasPartner' }
        & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoGasProduct' }
        & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    ), userResultItem: Maybe<(
      { __typename?: 'EnergoGasResultItem' }
      & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoGasPartner' }
        & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoGasProduct' }
        & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    )>, partner: Maybe<(
      { __typename?: 'EnergoGasPartner' }
      & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    )> }
  ) }
);

export type EnergoContractSaveMutationVariables = Exact<{
  calculationUuid: Scalars['String']['input'];
  data: EnergoContractPersonalDataInput;
}>;


export type EnergoContractSaveMutation = (
  { __typename?: 'Mutation' }
  & { energoContractSave: (
    { __typename?: 'CustomerZoneCalculationDetail' }
    & Pick<CustomerZoneCalculationDetail, 'uuid' | 'type' | 'source' | 'yearlyConsumption' | 'yearlyConsumptionUnit' | 'yearlyConsumptionLowTariff' | 'yearlyConsumptionLowTariffUnit' | 'yearlyPayment' | 'gasYearlyPayment' | 'gasYearlyConsumption' | 'gasYearlyConsumptionUnit' | 'gasMonthlyAdvances' | 'gasUnknownMonthlyAdvances' | 'monthlyAdvances' | 'unknownMonthlyAdvances' | 'email' | 'saving' | 'createdAt' | 'updatedAt' | 'lastViewed' | 'currentProductId' | 'currentProductName' | 'currentProductFollowingId' | 'unknownCurrentProduct' | 'gasCurrentProductId' | 'gasCurrentProductName' | 'gasCurrentProductFollowingId' | 'gasUnknownCurrentProduct' | 'adminLastChangeAt'>
    & { address: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
      & { extended: Maybe<(
        { __typename?: 'SmartAddressExtendedInfo' }
        & Pick<SmartAddressExtendedInfo, 'id'>
        & { objectType: Maybe<(
          { __typename?: 'SmartAddressObjectType' }
          & Pick<SmartAddressObjectType, 'id' | 'nazev'>
        )> }
      )>, extendedV2: Maybe<(
        { __typename?: 'SmartAddressSdkExtendedInfo' }
        & { administrativniUdaje: Maybe<(
          { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
          & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )> }
      )> }
    )>, partner: Maybe<(
      { __typename?: 'EnergoElectricityPartner' }
      & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    )>, gasPartner: Maybe<(
      { __typename?: 'EnergoGasPartner' }
      & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    )>, gasUsages: Maybe<(
      { __typename?: 'GasUsages' }
      & Pick<GasUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
    )>, electricityUsages: Maybe<(
      { __typename?: 'ElectricityUsages' }
      & Pick<ElectricityUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
    )>, floorAreaRange: Maybe<(
      { __typename?: 'EnergoFloorAreaRangeCodebook' }
      & Pick<EnergoFloorAreaRangeCodebook, 'id'>
      & { label: EnergoFloorAreaRangeCodebook['name'] }
    )>, isolation: Maybe<(
      { __typename?: 'EnergoIsolationCodebook' }
      & Pick<EnergoIsolationCodebook, 'id'>
      & { label: EnergoIsolationCodebook['name'] }
    )>, personsCountRange: Maybe<(
      { __typename?: 'EnergoPersonsCountRangeCodebook' }
      & Pick<EnergoPersonsCountRangeCodebook, 'id'>
      & { label: EnergoPersonsCountRangeCodebook['name'] }
    )>, distributionRate: Maybe<(
      { __typename?: 'EnergoDistributionRate' }
      & Pick<EnergoDistributionRate, 'id' | 'name' | 'lowTariff'>
    )>, breaker: Maybe<(
      { __typename?: 'EnergoBreaker' }
      & Pick<EnergoBreaker, 'id' | 'name'>
    )>, standaloneInvoice: Maybe<(
      { __typename?: 'EnergoStandaloneInvoice' }
      & Pick<EnergoStandaloneInvoice, 'id' | 'uuid'>
    )>, bestResultItem: Maybe<(
      { __typename?: 'EnergoElectricityResultItem' }
      & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoElectricityPartner' }
        & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoElectricityProduct' }
        & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    )>, bestGasResultItem: Maybe<(
      { __typename?: 'EnergoGasResultItem' }
      & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoGasPartner' }
        & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoGasProduct' }
        & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    )>, energoOffers: Array<(
      { __typename?: 'EnergoOffer' }
      & Pick<EnergoOffer, 'id' | 'status' | 'validTo' | 'acceptedAt'>
      & { benefitResponse: Maybe<(
        { __typename?: 'Benefit' }
        & Pick<Benefit, 'id' | 'uuid' | 'name' | 'validFrom' | 'validTo' | 'template' | 'perex' | 'text' | 'buttonText'>
        & { profileBenefit: (
          { __typename?: 'ProfileBenefit' }
          & Pick<ProfileBenefit, 'id' | 'name' | 'surname' | 'status' | 'phone'>
          & { attachments: Array<(
            { __typename?: 'BenefitAttachment' }
            & Pick<BenefitAttachment, 'id'>
            & { file: (
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            ) }
          )> }
        ), image: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, electricityResultItem: Maybe<(
        { __typename?: 'EnergoElectricityResultItem' }
        & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
        & { partner: (
          { __typename?: 'EnergoElectricityPartner' }
          & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
          & { rating: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), product: (
          { __typename?: 'EnergoElectricityProduct' }
          & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
        ), priceExpectedDetail: (
          { __typename?: 'EnergoElectricityResultPriceDetail' }
          & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ), priceResultDetail: (
          { __typename?: 'EnergoElectricityResultPriceDetail' }
          & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ) }
      )>, gasResultItem: Maybe<(
        { __typename?: 'EnergoGasResultItem' }
        & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
        & { partner: (
          { __typename?: 'EnergoGasPartner' }
          & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
          & { rating: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), product: (
          { __typename?: 'EnergoGasProduct' }
          & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
        ), priceExpectedDetail: (
          { __typename?: 'EnergoGasResultPriceDetail' }
          & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ), priceResultDetail: (
          { __typename?: 'EnergoGasResultPriceDetail' }
          & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ) }
      )>, acceptedBy: Maybe<(
        { __typename?: 'ProfileUser' }
        & Pick<ProfileUser, 'email' | 'name'>
      )> }
    )>, energoContract: (
      { __typename?: 'EnergoEnergoContract' }
      & Pick<EnergoEnergoContract, 'id' | 'name' | 'surname' | 'phone' | 'email' | 'customerNote' | 'status' | 'preferredPaymentMethod' | 'sendInvoiceMethod' | 'invoiceUploaded' | 'supplyStartDate' | 'expectedSupplyStartDate' | 'methodOfSendingContract'>
      & { supplyAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, contactAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, permanentAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, customSupplyAddress: Maybe<(
        { __typename?: 'CustomAddress' }
        & Pick<CustomAddress, 'address' | 'zipCode' | 'city'>
      )>, customContactAddress: Maybe<(
        { __typename?: 'CustomAddress' }
        & Pick<CustomAddress, 'address' | 'zipCode' | 'city'>
      )>, customPermanentAddress: Maybe<(
        { __typename?: 'CustomAddress' }
        & Pick<CustomAddress, 'address' | 'zipCode' | 'city'>
      )>, attachments: Array<(
        { __typename?: 'EnergoEnergoContractAttachment' }
        & Pick<EnergoEnergoContractAttachment, 'id' | 'type' | 'createdAt'>
        & { file: (
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        ) }
      )>, energoOffer: Maybe<(
        { __typename?: 'EnergoOffer' }
        & Pick<EnergoOffer, 'id' | 'status' | 'validTo' | 'acceptedAt'>
        & { benefitResponse: Maybe<(
          { __typename?: 'Benefit' }
          & Pick<Benefit, 'id' | 'uuid' | 'name' | 'validFrom' | 'validTo' | 'template' | 'perex' | 'text' | 'buttonText'>
          & { profileBenefit: (
            { __typename?: 'ProfileBenefit' }
            & Pick<ProfileBenefit, 'id' | 'name' | 'surname' | 'status' | 'phone'>
            & { attachments: Array<(
              { __typename?: 'BenefitAttachment' }
              & Pick<BenefitAttachment, 'id'>
              & { file: (
                { __typename?: 'File' }
                & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
              ) }
            )> }
          ), image: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, electricityResultItem: Maybe<(
          { __typename?: 'EnergoElectricityResultItem' }
          & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
          & { partner: (
            { __typename?: 'EnergoElectricityPartner' }
            & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
            & { rating: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), product: (
            { __typename?: 'EnergoElectricityProduct' }
            & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
          ), priceExpectedDetail: (
            { __typename?: 'EnergoElectricityResultPriceDetail' }
            & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
          ), priceResultDetail: (
            { __typename?: 'EnergoElectricityResultPriceDetail' }
            & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
          ) }
        )>, gasResultItem: Maybe<(
          { __typename?: 'EnergoGasResultItem' }
          & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
          & { partner: (
            { __typename?: 'EnergoGasPartner' }
            & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
            & { rating: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), product: (
            { __typename?: 'EnergoGasProduct' }
            & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
          ), priceExpectedDetail: (
            { __typename?: 'EnergoGasResultPriceDetail' }
            & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
          ), priceResultDetail: (
            { __typename?: 'EnergoGasResultPriceDetail' }
            & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
          ) }
        )>, acceptedBy: Maybe<(
          { __typename?: 'ProfileUser' }
          & Pick<ProfileUser, 'email' | 'name'>
        )> }
      )> }
    ), adminLastChangeBy: Maybe<(
      { __typename?: 'ProfileUser' }
      & Pick<ProfileUser, 'email' | 'name'>
    )>, watchWolf: Maybe<(
      { __typename?: 'WatchWolfPreview' }
      & Pick<WatchWolfPreview, 'uuid'>
    )> }
  ) }
);

export type EnergoContractAttachmentsMutationVariables = Exact<{
  calculationUuid: Scalars['String']['input'];
  data: LeadCompleteInput;
}>;


export type EnergoContractAttachmentsMutation = (
  { __typename?: 'Mutation' }
  & { energoContractAttachments: (
    { __typename?: 'CustomerZoneCalculationDetail' }
    & Pick<CustomerZoneCalculationDetail, 'uuid' | 'type' | 'source' | 'yearlyConsumption' | 'yearlyConsumptionUnit' | 'yearlyConsumptionLowTariff' | 'yearlyConsumptionLowTariffUnit' | 'yearlyPayment' | 'gasYearlyPayment' | 'gasYearlyConsumption' | 'gasYearlyConsumptionUnit' | 'gasMonthlyAdvances' | 'gasUnknownMonthlyAdvances' | 'monthlyAdvances' | 'unknownMonthlyAdvances' | 'email' | 'saving' | 'createdAt' | 'updatedAt' | 'lastViewed' | 'currentProductId' | 'currentProductName' | 'currentProductFollowingId' | 'unknownCurrentProduct' | 'gasCurrentProductId' | 'gasCurrentProductName' | 'gasCurrentProductFollowingId' | 'gasUnknownCurrentProduct' | 'adminLastChangeAt'>
    & { address: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
      & { extended: Maybe<(
        { __typename?: 'SmartAddressExtendedInfo' }
        & Pick<SmartAddressExtendedInfo, 'id'>
        & { objectType: Maybe<(
          { __typename?: 'SmartAddressObjectType' }
          & Pick<SmartAddressObjectType, 'id' | 'nazev'>
        )> }
      )>, extendedV2: Maybe<(
        { __typename?: 'SmartAddressSdkExtendedInfo' }
        & { administrativniUdaje: Maybe<(
          { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
          & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )> }
      )> }
    )>, partner: Maybe<(
      { __typename?: 'EnergoElectricityPartner' }
      & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    )>, gasPartner: Maybe<(
      { __typename?: 'EnergoGasPartner' }
      & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    )>, gasUsages: Maybe<(
      { __typename?: 'GasUsages' }
      & Pick<GasUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
    )>, electricityUsages: Maybe<(
      { __typename?: 'ElectricityUsages' }
      & Pick<ElectricityUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
    )>, floorAreaRange: Maybe<(
      { __typename?: 'EnergoFloorAreaRangeCodebook' }
      & Pick<EnergoFloorAreaRangeCodebook, 'id'>
      & { label: EnergoFloorAreaRangeCodebook['name'] }
    )>, isolation: Maybe<(
      { __typename?: 'EnergoIsolationCodebook' }
      & Pick<EnergoIsolationCodebook, 'id'>
      & { label: EnergoIsolationCodebook['name'] }
    )>, personsCountRange: Maybe<(
      { __typename?: 'EnergoPersonsCountRangeCodebook' }
      & Pick<EnergoPersonsCountRangeCodebook, 'id'>
      & { label: EnergoPersonsCountRangeCodebook['name'] }
    )>, distributionRate: Maybe<(
      { __typename?: 'EnergoDistributionRate' }
      & Pick<EnergoDistributionRate, 'id' | 'name' | 'lowTariff'>
    )>, breaker: Maybe<(
      { __typename?: 'EnergoBreaker' }
      & Pick<EnergoBreaker, 'id' | 'name'>
    )>, standaloneInvoice: Maybe<(
      { __typename?: 'EnergoStandaloneInvoice' }
      & Pick<EnergoStandaloneInvoice, 'id' | 'uuid'>
    )>, bestResultItem: Maybe<(
      { __typename?: 'EnergoElectricityResultItem' }
      & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoElectricityPartner' }
        & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoElectricityProduct' }
        & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    )>, bestGasResultItem: Maybe<(
      { __typename?: 'EnergoGasResultItem' }
      & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoGasPartner' }
        & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoGasProduct' }
        & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    )>, energoOffers: Array<(
      { __typename?: 'EnergoOffer' }
      & Pick<EnergoOffer, 'id' | 'status' | 'validTo' | 'acceptedAt'>
      & { benefitResponse: Maybe<(
        { __typename?: 'Benefit' }
        & Pick<Benefit, 'id' | 'uuid' | 'name' | 'validFrom' | 'validTo' | 'template' | 'perex' | 'text' | 'buttonText'>
        & { profileBenefit: (
          { __typename?: 'ProfileBenefit' }
          & Pick<ProfileBenefit, 'id' | 'name' | 'surname' | 'status' | 'phone'>
          & { attachments: Array<(
            { __typename?: 'BenefitAttachment' }
            & Pick<BenefitAttachment, 'id'>
            & { file: (
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            ) }
          )> }
        ), image: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, electricityResultItem: Maybe<(
        { __typename?: 'EnergoElectricityResultItem' }
        & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
        & { partner: (
          { __typename?: 'EnergoElectricityPartner' }
          & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
          & { rating: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), product: (
          { __typename?: 'EnergoElectricityProduct' }
          & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
        ), priceExpectedDetail: (
          { __typename?: 'EnergoElectricityResultPriceDetail' }
          & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ), priceResultDetail: (
          { __typename?: 'EnergoElectricityResultPriceDetail' }
          & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ) }
      )>, gasResultItem: Maybe<(
        { __typename?: 'EnergoGasResultItem' }
        & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
        & { partner: (
          { __typename?: 'EnergoGasPartner' }
          & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
          & { rating: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), product: (
          { __typename?: 'EnergoGasProduct' }
          & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
        ), priceExpectedDetail: (
          { __typename?: 'EnergoGasResultPriceDetail' }
          & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ), priceResultDetail: (
          { __typename?: 'EnergoGasResultPriceDetail' }
          & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ) }
      )>, acceptedBy: Maybe<(
        { __typename?: 'ProfileUser' }
        & Pick<ProfileUser, 'email' | 'name'>
      )> }
    )>, energoContract: (
      { __typename?: 'EnergoEnergoContract' }
      & Pick<EnergoEnergoContract, 'id' | 'name' | 'surname' | 'phone' | 'email' | 'customerNote' | 'status' | 'preferredPaymentMethod' | 'sendInvoiceMethod' | 'invoiceUploaded' | 'supplyStartDate' | 'expectedSupplyStartDate' | 'methodOfSendingContract'>
      & { supplyAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, contactAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, permanentAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, customSupplyAddress: Maybe<(
        { __typename?: 'CustomAddress' }
        & Pick<CustomAddress, 'address' | 'zipCode' | 'city'>
      )>, customContactAddress: Maybe<(
        { __typename?: 'CustomAddress' }
        & Pick<CustomAddress, 'address' | 'zipCode' | 'city'>
      )>, customPermanentAddress: Maybe<(
        { __typename?: 'CustomAddress' }
        & Pick<CustomAddress, 'address' | 'zipCode' | 'city'>
      )>, attachments: Array<(
        { __typename?: 'EnergoEnergoContractAttachment' }
        & Pick<EnergoEnergoContractAttachment, 'id' | 'type' | 'createdAt'>
        & { file: (
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        ) }
      )>, energoOffer: Maybe<(
        { __typename?: 'EnergoOffer' }
        & Pick<EnergoOffer, 'id' | 'status' | 'validTo' | 'acceptedAt'>
        & { benefitResponse: Maybe<(
          { __typename?: 'Benefit' }
          & Pick<Benefit, 'id' | 'uuid' | 'name' | 'validFrom' | 'validTo' | 'template' | 'perex' | 'text' | 'buttonText'>
          & { profileBenefit: (
            { __typename?: 'ProfileBenefit' }
            & Pick<ProfileBenefit, 'id' | 'name' | 'surname' | 'status' | 'phone'>
            & { attachments: Array<(
              { __typename?: 'BenefitAttachment' }
              & Pick<BenefitAttachment, 'id'>
              & { file: (
                { __typename?: 'File' }
                & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
              ) }
            )> }
          ), image: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, electricityResultItem: Maybe<(
          { __typename?: 'EnergoElectricityResultItem' }
          & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
          & { partner: (
            { __typename?: 'EnergoElectricityPartner' }
            & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
            & { rating: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), product: (
            { __typename?: 'EnergoElectricityProduct' }
            & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
          ), priceExpectedDetail: (
            { __typename?: 'EnergoElectricityResultPriceDetail' }
            & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
          ), priceResultDetail: (
            { __typename?: 'EnergoElectricityResultPriceDetail' }
            & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
          ) }
        )>, gasResultItem: Maybe<(
          { __typename?: 'EnergoGasResultItem' }
          & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
          & { partner: (
            { __typename?: 'EnergoGasPartner' }
            & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
            & { rating: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), product: (
            { __typename?: 'EnergoGasProduct' }
            & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
          ), priceExpectedDetail: (
            { __typename?: 'EnergoGasResultPriceDetail' }
            & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
          ), priceResultDetail: (
            { __typename?: 'EnergoGasResultPriceDetail' }
            & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
          ) }
        )>, acceptedBy: Maybe<(
          { __typename?: 'ProfileUser' }
          & Pick<ProfileUser, 'email' | 'name'>
        )> }
      )> }
    ), adminLastChangeBy: Maybe<(
      { __typename?: 'ProfileUser' }
      & Pick<ProfileUser, 'email' | 'name'>
    )>, watchWolf: Maybe<(
      { __typename?: 'WatchWolfPreview' }
      & Pick<WatchWolfPreview, 'uuid'>
    )> }
  ) }
);

export type SaveContractDataSaveMutationVariables = Exact<{
  calculationUuid: Scalars['String']['input'];
  data: EnergoContractDataInput;
}>;


export type SaveContractDataSaveMutation = (
  { __typename?: 'Mutation' }
  & { saveContractDataSave: (
    { __typename?: 'CustomerZoneCalculationDetail' }
    & Pick<CustomerZoneCalculationDetail, 'uuid' | 'type' | 'source' | 'yearlyConsumption' | 'yearlyConsumptionUnit' | 'yearlyConsumptionLowTariff' | 'yearlyConsumptionLowTariffUnit' | 'yearlyPayment' | 'gasYearlyPayment' | 'gasYearlyConsumption' | 'gasYearlyConsumptionUnit' | 'gasMonthlyAdvances' | 'gasUnknownMonthlyAdvances' | 'monthlyAdvances' | 'unknownMonthlyAdvances' | 'email' | 'saving' | 'createdAt' | 'updatedAt' | 'lastViewed' | 'currentProductId' | 'currentProductName' | 'currentProductFollowingId' | 'unknownCurrentProduct' | 'gasCurrentProductId' | 'gasCurrentProductName' | 'gasCurrentProductFollowingId' | 'gasUnknownCurrentProduct' | 'adminLastChangeAt'>
    & { address: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
      & { extended: Maybe<(
        { __typename?: 'SmartAddressExtendedInfo' }
        & Pick<SmartAddressExtendedInfo, 'id'>
        & { objectType: Maybe<(
          { __typename?: 'SmartAddressObjectType' }
          & Pick<SmartAddressObjectType, 'id' | 'nazev'>
        )> }
      )>, extendedV2: Maybe<(
        { __typename?: 'SmartAddressSdkExtendedInfo' }
        & { administrativniUdaje: Maybe<(
          { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
          & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )> }
      )> }
    )>, partner: Maybe<(
      { __typename?: 'EnergoElectricityPartner' }
      & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    )>, gasPartner: Maybe<(
      { __typename?: 'EnergoGasPartner' }
      & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    )>, gasUsages: Maybe<(
      { __typename?: 'GasUsages' }
      & Pick<GasUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
    )>, electricityUsages: Maybe<(
      { __typename?: 'ElectricityUsages' }
      & Pick<ElectricityUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
    )>, floorAreaRange: Maybe<(
      { __typename?: 'EnergoFloorAreaRangeCodebook' }
      & Pick<EnergoFloorAreaRangeCodebook, 'id'>
      & { label: EnergoFloorAreaRangeCodebook['name'] }
    )>, isolation: Maybe<(
      { __typename?: 'EnergoIsolationCodebook' }
      & Pick<EnergoIsolationCodebook, 'id'>
      & { label: EnergoIsolationCodebook['name'] }
    )>, personsCountRange: Maybe<(
      { __typename?: 'EnergoPersonsCountRangeCodebook' }
      & Pick<EnergoPersonsCountRangeCodebook, 'id'>
      & { label: EnergoPersonsCountRangeCodebook['name'] }
    )>, distributionRate: Maybe<(
      { __typename?: 'EnergoDistributionRate' }
      & Pick<EnergoDistributionRate, 'id' | 'name' | 'lowTariff'>
    )>, breaker: Maybe<(
      { __typename?: 'EnergoBreaker' }
      & Pick<EnergoBreaker, 'id' | 'name'>
    )>, standaloneInvoice: Maybe<(
      { __typename?: 'EnergoStandaloneInvoice' }
      & Pick<EnergoStandaloneInvoice, 'id' | 'uuid'>
    )>, bestResultItem: Maybe<(
      { __typename?: 'EnergoElectricityResultItem' }
      & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoElectricityPartner' }
        & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoElectricityProduct' }
        & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    )>, bestGasResultItem: Maybe<(
      { __typename?: 'EnergoGasResultItem' }
      & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoGasPartner' }
        & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoGasProduct' }
        & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    )>, energoOffers: Array<(
      { __typename?: 'EnergoOffer' }
      & Pick<EnergoOffer, 'id' | 'status' | 'validTo' | 'acceptedAt'>
      & { benefitResponse: Maybe<(
        { __typename?: 'Benefit' }
        & Pick<Benefit, 'id' | 'uuid' | 'name' | 'validFrom' | 'validTo' | 'template' | 'perex' | 'text' | 'buttonText'>
        & { profileBenefit: (
          { __typename?: 'ProfileBenefit' }
          & Pick<ProfileBenefit, 'id' | 'name' | 'surname' | 'status' | 'phone'>
          & { attachments: Array<(
            { __typename?: 'BenefitAttachment' }
            & Pick<BenefitAttachment, 'id'>
            & { file: (
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            ) }
          )> }
        ), image: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, electricityResultItem: Maybe<(
        { __typename?: 'EnergoElectricityResultItem' }
        & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
        & { partner: (
          { __typename?: 'EnergoElectricityPartner' }
          & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
          & { rating: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), product: (
          { __typename?: 'EnergoElectricityProduct' }
          & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
        ), priceExpectedDetail: (
          { __typename?: 'EnergoElectricityResultPriceDetail' }
          & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ), priceResultDetail: (
          { __typename?: 'EnergoElectricityResultPriceDetail' }
          & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ) }
      )>, gasResultItem: Maybe<(
        { __typename?: 'EnergoGasResultItem' }
        & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
        & { partner: (
          { __typename?: 'EnergoGasPartner' }
          & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
          & { rating: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), product: (
          { __typename?: 'EnergoGasProduct' }
          & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
        ), priceExpectedDetail: (
          { __typename?: 'EnergoGasResultPriceDetail' }
          & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ), priceResultDetail: (
          { __typename?: 'EnergoGasResultPriceDetail' }
          & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ) }
      )>, acceptedBy: Maybe<(
        { __typename?: 'ProfileUser' }
        & Pick<ProfileUser, 'email' | 'name'>
      )> }
    )>, energoContract: (
      { __typename?: 'EnergoEnergoContract' }
      & Pick<EnergoEnergoContract, 'id' | 'name' | 'surname' | 'phone' | 'email' | 'customerNote' | 'status' | 'preferredPaymentMethod' | 'sendInvoiceMethod' | 'invoiceUploaded' | 'supplyStartDate' | 'expectedSupplyStartDate' | 'methodOfSendingContract'>
      & { supplyAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, contactAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, permanentAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, customSupplyAddress: Maybe<(
        { __typename?: 'CustomAddress' }
        & Pick<CustomAddress, 'address' | 'zipCode' | 'city'>
      )>, customContactAddress: Maybe<(
        { __typename?: 'CustomAddress' }
        & Pick<CustomAddress, 'address' | 'zipCode' | 'city'>
      )>, customPermanentAddress: Maybe<(
        { __typename?: 'CustomAddress' }
        & Pick<CustomAddress, 'address' | 'zipCode' | 'city'>
      )>, attachments: Array<(
        { __typename?: 'EnergoEnergoContractAttachment' }
        & Pick<EnergoEnergoContractAttachment, 'id' | 'type' | 'createdAt'>
        & { file: (
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        ) }
      )>, energoOffer: Maybe<(
        { __typename?: 'EnergoOffer' }
        & Pick<EnergoOffer, 'id' | 'status' | 'validTo' | 'acceptedAt'>
        & { benefitResponse: Maybe<(
          { __typename?: 'Benefit' }
          & Pick<Benefit, 'id' | 'uuid' | 'name' | 'validFrom' | 'validTo' | 'template' | 'perex' | 'text' | 'buttonText'>
          & { profileBenefit: (
            { __typename?: 'ProfileBenefit' }
            & Pick<ProfileBenefit, 'id' | 'name' | 'surname' | 'status' | 'phone'>
            & { attachments: Array<(
              { __typename?: 'BenefitAttachment' }
              & Pick<BenefitAttachment, 'id'>
              & { file: (
                { __typename?: 'File' }
                & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
              ) }
            )> }
          ), image: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, electricityResultItem: Maybe<(
          { __typename?: 'EnergoElectricityResultItem' }
          & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
          & { partner: (
            { __typename?: 'EnergoElectricityPartner' }
            & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
            & { rating: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), product: (
            { __typename?: 'EnergoElectricityProduct' }
            & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
          ), priceExpectedDetail: (
            { __typename?: 'EnergoElectricityResultPriceDetail' }
            & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
          ), priceResultDetail: (
            { __typename?: 'EnergoElectricityResultPriceDetail' }
            & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
          ) }
        )>, gasResultItem: Maybe<(
          { __typename?: 'EnergoGasResultItem' }
          & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
          & { partner: (
            { __typename?: 'EnergoGasPartner' }
            & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
            & { rating: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), product: (
            { __typename?: 'EnergoGasProduct' }
            & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
          ), priceExpectedDetail: (
            { __typename?: 'EnergoGasResultPriceDetail' }
            & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
          ), priceResultDetail: (
            { __typename?: 'EnergoGasResultPriceDetail' }
            & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
          ) }
        )>, acceptedBy: Maybe<(
          { __typename?: 'ProfileUser' }
          & Pick<ProfileUser, 'email' | 'name'>
        )> }
      )> }
    ), adminLastChangeBy: Maybe<(
      { __typename?: 'ProfileUser' }
      & Pick<ProfileUser, 'email' | 'name'>
    )>, watchWolf: Maybe<(
      { __typename?: 'WatchWolfPreview' }
      & Pick<WatchWolfPreview, 'uuid'>
    )> }
  ) }
);

export type AcceptEnergoOfferMutationVariables = Exact<{
  energoOffer: Scalars['Int']['input'];
}>;


export type AcceptEnergoOfferMutation = (
  { __typename?: 'Mutation' }
  & { acceptEnergoOffer: (
    { __typename?: 'CustomerZoneCalculationDetail' }
    & Pick<CustomerZoneCalculationDetail, 'uuid' | 'type' | 'source' | 'yearlyConsumption' | 'yearlyConsumptionUnit' | 'yearlyConsumptionLowTariff' | 'yearlyConsumptionLowTariffUnit' | 'yearlyPayment' | 'gasYearlyPayment' | 'gasYearlyConsumption' | 'gasYearlyConsumptionUnit' | 'gasMonthlyAdvances' | 'gasUnknownMonthlyAdvances' | 'monthlyAdvances' | 'unknownMonthlyAdvances' | 'email' | 'saving' | 'createdAt' | 'updatedAt' | 'lastViewed' | 'currentProductId' | 'currentProductName' | 'currentProductFollowingId' | 'unknownCurrentProduct' | 'gasCurrentProductId' | 'gasCurrentProductName' | 'gasCurrentProductFollowingId' | 'gasUnknownCurrentProduct' | 'adminLastChangeAt'>
    & { address: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
      & { extended: Maybe<(
        { __typename?: 'SmartAddressExtendedInfo' }
        & Pick<SmartAddressExtendedInfo, 'id'>
        & { objectType: Maybe<(
          { __typename?: 'SmartAddressObjectType' }
          & Pick<SmartAddressObjectType, 'id' | 'nazev'>
        )> }
      )>, extendedV2: Maybe<(
        { __typename?: 'SmartAddressSdkExtendedInfo' }
        & { administrativniUdaje: Maybe<(
          { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
          & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )> }
      )> }
    )>, partner: Maybe<(
      { __typename?: 'EnergoElectricityPartner' }
      & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    )>, gasPartner: Maybe<(
      { __typename?: 'EnergoGasPartner' }
      & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    )>, gasUsages: Maybe<(
      { __typename?: 'GasUsages' }
      & Pick<GasUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
    )>, electricityUsages: Maybe<(
      { __typename?: 'ElectricityUsages' }
      & Pick<ElectricityUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
    )>, floorAreaRange: Maybe<(
      { __typename?: 'EnergoFloorAreaRangeCodebook' }
      & Pick<EnergoFloorAreaRangeCodebook, 'id'>
      & { label: EnergoFloorAreaRangeCodebook['name'] }
    )>, isolation: Maybe<(
      { __typename?: 'EnergoIsolationCodebook' }
      & Pick<EnergoIsolationCodebook, 'id'>
      & { label: EnergoIsolationCodebook['name'] }
    )>, personsCountRange: Maybe<(
      { __typename?: 'EnergoPersonsCountRangeCodebook' }
      & Pick<EnergoPersonsCountRangeCodebook, 'id'>
      & { label: EnergoPersonsCountRangeCodebook['name'] }
    )>, distributionRate: Maybe<(
      { __typename?: 'EnergoDistributionRate' }
      & Pick<EnergoDistributionRate, 'id' | 'name' | 'lowTariff'>
    )>, breaker: Maybe<(
      { __typename?: 'EnergoBreaker' }
      & Pick<EnergoBreaker, 'id' | 'name'>
    )>, standaloneInvoice: Maybe<(
      { __typename?: 'EnergoStandaloneInvoice' }
      & Pick<EnergoStandaloneInvoice, 'id' | 'uuid'>
    )>, bestResultItem: Maybe<(
      { __typename?: 'EnergoElectricityResultItem' }
      & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoElectricityPartner' }
        & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoElectricityProduct' }
        & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    )>, bestGasResultItem: Maybe<(
      { __typename?: 'EnergoGasResultItem' }
      & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoGasPartner' }
        & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoGasProduct' }
        & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    )>, energoOffers: Array<(
      { __typename?: 'EnergoOffer' }
      & Pick<EnergoOffer, 'id' | 'status' | 'validTo' | 'acceptedAt'>
      & { benefitResponse: Maybe<(
        { __typename?: 'Benefit' }
        & Pick<Benefit, 'id' | 'uuid' | 'name' | 'validFrom' | 'validTo' | 'template' | 'perex' | 'text' | 'buttonText'>
        & { profileBenefit: (
          { __typename?: 'ProfileBenefit' }
          & Pick<ProfileBenefit, 'id' | 'name' | 'surname' | 'status' | 'phone'>
          & { attachments: Array<(
            { __typename?: 'BenefitAttachment' }
            & Pick<BenefitAttachment, 'id'>
            & { file: (
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            ) }
          )> }
        ), image: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, electricityResultItem: Maybe<(
        { __typename?: 'EnergoElectricityResultItem' }
        & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
        & { partner: (
          { __typename?: 'EnergoElectricityPartner' }
          & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
          & { rating: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), product: (
          { __typename?: 'EnergoElectricityProduct' }
          & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
        ), priceExpectedDetail: (
          { __typename?: 'EnergoElectricityResultPriceDetail' }
          & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ), priceResultDetail: (
          { __typename?: 'EnergoElectricityResultPriceDetail' }
          & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ) }
      )>, gasResultItem: Maybe<(
        { __typename?: 'EnergoGasResultItem' }
        & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
        & { partner: (
          { __typename?: 'EnergoGasPartner' }
          & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
          & { rating: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), product: (
          { __typename?: 'EnergoGasProduct' }
          & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
        ), priceExpectedDetail: (
          { __typename?: 'EnergoGasResultPriceDetail' }
          & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ), priceResultDetail: (
          { __typename?: 'EnergoGasResultPriceDetail' }
          & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ) }
      )>, acceptedBy: Maybe<(
        { __typename?: 'ProfileUser' }
        & Pick<ProfileUser, 'email' | 'name'>
      )> }
    )>, energoContract: (
      { __typename?: 'EnergoEnergoContract' }
      & Pick<EnergoEnergoContract, 'id' | 'name' | 'surname' | 'phone' | 'email' | 'customerNote' | 'status' | 'preferredPaymentMethod' | 'sendInvoiceMethod' | 'invoiceUploaded' | 'supplyStartDate' | 'expectedSupplyStartDate' | 'methodOfSendingContract'>
      & { supplyAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, contactAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, permanentAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, customSupplyAddress: Maybe<(
        { __typename?: 'CustomAddress' }
        & Pick<CustomAddress, 'address' | 'zipCode' | 'city'>
      )>, customContactAddress: Maybe<(
        { __typename?: 'CustomAddress' }
        & Pick<CustomAddress, 'address' | 'zipCode' | 'city'>
      )>, customPermanentAddress: Maybe<(
        { __typename?: 'CustomAddress' }
        & Pick<CustomAddress, 'address' | 'zipCode' | 'city'>
      )>, attachments: Array<(
        { __typename?: 'EnergoEnergoContractAttachment' }
        & Pick<EnergoEnergoContractAttachment, 'id' | 'type' | 'createdAt'>
        & { file: (
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        ) }
      )>, energoOffer: Maybe<(
        { __typename?: 'EnergoOffer' }
        & Pick<EnergoOffer, 'id' | 'status' | 'validTo' | 'acceptedAt'>
        & { benefitResponse: Maybe<(
          { __typename?: 'Benefit' }
          & Pick<Benefit, 'id' | 'uuid' | 'name' | 'validFrom' | 'validTo' | 'template' | 'perex' | 'text' | 'buttonText'>
          & { profileBenefit: (
            { __typename?: 'ProfileBenefit' }
            & Pick<ProfileBenefit, 'id' | 'name' | 'surname' | 'status' | 'phone'>
            & { attachments: Array<(
              { __typename?: 'BenefitAttachment' }
              & Pick<BenefitAttachment, 'id'>
              & { file: (
                { __typename?: 'File' }
                & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
              ) }
            )> }
          ), image: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, electricityResultItem: Maybe<(
          { __typename?: 'EnergoElectricityResultItem' }
          & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
          & { partner: (
            { __typename?: 'EnergoElectricityPartner' }
            & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
            & { rating: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), product: (
            { __typename?: 'EnergoElectricityProduct' }
            & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
          ), priceExpectedDetail: (
            { __typename?: 'EnergoElectricityResultPriceDetail' }
            & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
          ), priceResultDetail: (
            { __typename?: 'EnergoElectricityResultPriceDetail' }
            & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
          ) }
        )>, gasResultItem: Maybe<(
          { __typename?: 'EnergoGasResultItem' }
          & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
          & { partner: (
            { __typename?: 'EnergoGasPartner' }
            & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
            & { rating: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), product: (
            { __typename?: 'EnergoGasProduct' }
            & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
          ), priceExpectedDetail: (
            { __typename?: 'EnergoGasResultPriceDetail' }
            & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
          ), priceResultDetail: (
            { __typename?: 'EnergoGasResultPriceDetail' }
            & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
          ) }
        )>, acceptedBy: Maybe<(
          { __typename?: 'ProfileUser' }
          & Pick<ProfileUser, 'email' | 'name'>
        )> }
      )> }
    ), adminLastChangeBy: Maybe<(
      { __typename?: 'ProfileUser' }
      & Pick<ProfileUser, 'email' | 'name'>
    )>, watchWolf: Maybe<(
      { __typename?: 'WatchWolfPreview' }
      & Pick<WatchWolfPreview, 'uuid'>
    )> }
  ) }
);

export type DeclineEnergoOfferMutationVariables = Exact<{
  energoOffer: Scalars['Int']['input'];
}>;


export type DeclineEnergoOfferMutation = (
  { __typename?: 'Mutation' }
  & { declineEnergoOffer: (
    { __typename?: 'EnergoOffer' }
    & Pick<EnergoOffer, 'id' | 'status' | 'validTo' | 'acceptedAt'>
    & { benefitResponse: Maybe<(
      { __typename?: 'Benefit' }
      & Pick<Benefit, 'id' | 'uuid' | 'name' | 'validFrom' | 'validTo' | 'template' | 'perex' | 'text' | 'buttonText'>
      & { profileBenefit: (
        { __typename?: 'ProfileBenefit' }
        & Pick<ProfileBenefit, 'id' | 'name' | 'surname' | 'status' | 'phone'>
        & { attachments: Array<(
          { __typename?: 'BenefitAttachment' }
          & Pick<BenefitAttachment, 'id'>
          & { file: (
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          ) }
        )> }
      ), image: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )> }
    )>, electricityResultItem: Maybe<(
      { __typename?: 'EnergoElectricityResultItem' }
      & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoElectricityPartner' }
        & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoElectricityProduct' }
        & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    )>, gasResultItem: Maybe<(
      { __typename?: 'EnergoGasResultItem' }
      & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoGasPartner' }
        & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoGasProduct' }
        & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    )>, acceptedBy: Maybe<(
      { __typename?: 'ProfileUser' }
      & Pick<ProfileUser, 'email' | 'name'>
    )> }
  ) }
);

export type EnergoGetLastCalculationQueryVariables = Exact<{ [key: string]: never; }>;


export type EnergoGetLastCalculationQuery = (
  { __typename?: 'Query' }
  & { energoGetLastCalculation: Maybe<(
    { __typename?: 'EnergoCalculation' }
    & Pick<EnergoCalculation, 'id' | 'uuid' | 'type' | 'source' | 'yearlyConsumption' | 'yearlyConsumptionUnit' | 'yearlyConsumptionLowTariff' | 'yearlyConsumptionLowTariffUnit' | 'yearlyPayment' | 'gasYearlyPayment' | 'gasYearlyConsumption' | 'gasYearlyConsumptionUnit' | 'gasMonthlyAdvances' | 'gasUnknownMonthlyAdvances' | 'monthlyAdvances' | 'unknownMonthlyAdvances' | 'email' | 'saving' | 'createdAt' | 'updatedAt' | 'lastViewed' | 'currentProductId' | 'currentProductName' | 'currentProductFollowingId' | 'unknownCurrentProduct' | 'gasCurrentProductId' | 'gasCurrentProductName' | 'gasCurrentProductFollowingId' | 'gasUnknownCurrentProduct' | 'phone' | 'individualPriceExpectedMonthly' | 'individualPriceExpectedMWhHighTariff' | 'individualPriceExpectedMWhLowTariff' | 'gasIndividualPriceExpectedMonthly' | 'gasIndividualPriceExpectedMWh' | 'individualOfferName' | 'reason'>
    & { address: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
      & { extended: Maybe<(
        { __typename?: 'SmartAddressExtendedInfo' }
        & Pick<SmartAddressExtendedInfo, 'id'>
        & { objectType: Maybe<(
          { __typename?: 'SmartAddressObjectType' }
          & Pick<SmartAddressObjectType, 'id' | 'nazev'>
        )> }
      )>, extendedV2: Maybe<(
        { __typename?: 'SmartAddressSdkExtendedInfo' }
        & { administrativniUdaje: Maybe<(
          { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
          & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )> }
      )> }
    )>, partner: Maybe<(
      { __typename?: 'EnergoElectricityPartner' }
      & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    )>, gasPartner: Maybe<(
      { __typename?: 'EnergoGasPartner' }
      & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    )>, gasUsages: Maybe<(
      { __typename?: 'GasUsages' }
      & Pick<GasUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
    )>, electricityUsages: Maybe<(
      { __typename?: 'ElectricityUsages' }
      & Pick<ElectricityUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
    )>, floorAreaRange: Maybe<(
      { __typename?: 'EnergoFloorAreaRangeCodebook' }
      & Pick<EnergoFloorAreaRangeCodebook, 'id'>
      & { label: EnergoFloorAreaRangeCodebook['name'] }
    )>, isolation: Maybe<(
      { __typename?: 'EnergoIsolationCodebook' }
      & Pick<EnergoIsolationCodebook, 'id'>
      & { label: EnergoIsolationCodebook['name'] }
    )>, personsCountRange: Maybe<(
      { __typename?: 'EnergoPersonsCountRangeCodebook' }
      & Pick<EnergoPersonsCountRangeCodebook, 'id'>
      & { label: EnergoPersonsCountRangeCodebook['name'] }
    )>, distributionRate: Maybe<(
      { __typename?: 'EnergoDistributionRate' }
      & Pick<EnergoDistributionRate, 'id' | 'name' | 'lowTariff'>
    )>, breaker: Maybe<(
      { __typename?: 'EnergoBreaker' }
      & Pick<EnergoBreaker, 'id' | 'name'>
    )>, standaloneInvoice: Maybe<(
      { __typename?: 'EnergoStandaloneInvoice' }
      & Pick<EnergoStandaloneInvoice, 'id' | 'uuid'>
    )>, bestResultItem: Maybe<(
      { __typename?: 'EnergoElectricityResultItem' }
      & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoElectricityPartner' }
        & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoElectricityProduct' }
        & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    )>, bestGasResultItem: Maybe<(
      { __typename?: 'EnergoGasResultItem' }
      & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoGasPartner' }
        & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoGasProduct' }
        & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    )> }
  )> }
);

export type EnergoGetCalculationQueryVariables = Exact<{
  calculationUuid: Scalars['String']['input'];
}>;


export type EnergoGetCalculationQuery = (
  { __typename?: 'Query' }
  & { energoGetCalculation: (
    { __typename?: 'EnergoCalculation' }
    & Pick<EnergoCalculation, 'id' | 'uuid' | 'type' | 'source' | 'yearlyConsumption' | 'yearlyConsumptionUnit' | 'yearlyConsumptionLowTariff' | 'yearlyConsumptionLowTariffUnit' | 'yearlyPayment' | 'gasYearlyPayment' | 'gasYearlyConsumption' | 'gasYearlyConsumptionUnit' | 'gasMonthlyAdvances' | 'gasUnknownMonthlyAdvances' | 'monthlyAdvances' | 'unknownMonthlyAdvances' | 'email' | 'saving' | 'createdAt' | 'updatedAt' | 'lastViewed' | 'currentProductId' | 'currentProductName' | 'currentProductFollowingId' | 'unknownCurrentProduct' | 'gasCurrentProductId' | 'gasCurrentProductName' | 'gasCurrentProductFollowingId' | 'gasUnknownCurrentProduct' | 'phone' | 'individualPriceExpectedMonthly' | 'individualPriceExpectedMWhHighTariff' | 'individualPriceExpectedMWhLowTariff' | 'gasIndividualPriceExpectedMonthly' | 'gasIndividualPriceExpectedMWh' | 'individualOfferName' | 'reason'>
    & { address: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
      & { extended: Maybe<(
        { __typename?: 'SmartAddressExtendedInfo' }
        & Pick<SmartAddressExtendedInfo, 'id'>
        & { objectType: Maybe<(
          { __typename?: 'SmartAddressObjectType' }
          & Pick<SmartAddressObjectType, 'id' | 'nazev'>
        )> }
      )>, extendedV2: Maybe<(
        { __typename?: 'SmartAddressSdkExtendedInfo' }
        & { administrativniUdaje: Maybe<(
          { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
          & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )> }
      )> }
    )>, partner: Maybe<(
      { __typename?: 'EnergoElectricityPartner' }
      & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    )>, gasPartner: Maybe<(
      { __typename?: 'EnergoGasPartner' }
      & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    )>, gasUsages: Maybe<(
      { __typename?: 'GasUsages' }
      & Pick<GasUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
    )>, electricityUsages: Maybe<(
      { __typename?: 'ElectricityUsages' }
      & Pick<ElectricityUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
    )>, floorAreaRange: Maybe<(
      { __typename?: 'EnergoFloorAreaRangeCodebook' }
      & Pick<EnergoFloorAreaRangeCodebook, 'id'>
      & { label: EnergoFloorAreaRangeCodebook['name'] }
    )>, isolation: Maybe<(
      { __typename?: 'EnergoIsolationCodebook' }
      & Pick<EnergoIsolationCodebook, 'id'>
      & { label: EnergoIsolationCodebook['name'] }
    )>, personsCountRange: Maybe<(
      { __typename?: 'EnergoPersonsCountRangeCodebook' }
      & Pick<EnergoPersonsCountRangeCodebook, 'id'>
      & { label: EnergoPersonsCountRangeCodebook['name'] }
    )>, distributionRate: Maybe<(
      { __typename?: 'EnergoDistributionRate' }
      & Pick<EnergoDistributionRate, 'id' | 'name' | 'lowTariff'>
    )>, breaker: Maybe<(
      { __typename?: 'EnergoBreaker' }
      & Pick<EnergoBreaker, 'id' | 'name'>
    )>, standaloneInvoice: Maybe<(
      { __typename?: 'EnergoStandaloneInvoice' }
      & Pick<EnergoStandaloneInvoice, 'id' | 'uuid'>
    )>, bestResultItem: Maybe<(
      { __typename?: 'EnergoElectricityResultItem' }
      & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoElectricityPartner' }
        & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoElectricityProduct' }
        & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    )>, bestGasResultItem: Maybe<(
      { __typename?: 'EnergoGasResultItem' }
      & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoGasPartner' }
        & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoGasProduct' }
        & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    )> }
  ) }
);

export type EnergoPublicGetCalculationQueryVariables = Exact<{
  calculationUuid: Scalars['String']['input'];
}>;


export type EnergoPublicGetCalculationQuery = (
  { __typename?: 'Query' }
  & { energoPublicGetCalculation: (
    { __typename?: 'PublicEnergoCalculation' }
    & Pick<PublicEnergoCalculation, 'id' | 'uuid' | 'type' | 'source' | 'yearlyConsumption' | 'yearlyConsumptionUnit' | 'yearlyConsumptionLowTariff' | 'yearlyConsumptionLowTariffUnit' | 'yearlyPayment' | 'gasYearlyPayment' | 'gasYearlyConsumption' | 'gasYearlyConsumptionUnit' | 'gasMonthlyAdvances' | 'gasUnknownMonthlyAdvances' | 'monthlyAdvances' | 'unknownMonthlyAdvances' | 'saving' | 'createdAt' | 'updatedAt' | 'lastViewed' | 'currentProductId' | 'currentProductName' | 'currentProductFollowingId' | 'unknownCurrentProduct' | 'gasCurrentProductId' | 'gasCurrentProductName' | 'gasCurrentProductFollowingId' | 'gasUnknownCurrentProduct' | 'individualPriceExpectedMonthly' | 'individualPriceExpectedMWhHighTariff' | 'individualPriceExpectedMWhLowTariff' | 'gasIndividualPriceExpectedMonthly' | 'gasIndividualPriceExpectedMWh' | 'individualOfferName' | 'zip'>
    & { partner: Maybe<(
      { __typename?: 'EnergoElectricityPartner' }
      & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    )>, gasPartner: Maybe<(
      { __typename?: 'EnergoGasPartner' }
      & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    )>, gasUsages: Maybe<(
      { __typename?: 'GasUsages' }
      & Pick<GasUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
    )>, electricityUsages: Maybe<(
      { __typename?: 'ElectricityUsages' }
      & Pick<ElectricityUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
    )>, floorAreaRange: Maybe<(
      { __typename?: 'EnergoFloorAreaRangeCodebook' }
      & Pick<EnergoFloorAreaRangeCodebook, 'id'>
      & { label: EnergoFloorAreaRangeCodebook['name'] }
    )>, isolation: Maybe<(
      { __typename?: 'EnergoIsolationCodebook' }
      & Pick<EnergoIsolationCodebook, 'id'>
      & { label: EnergoIsolationCodebook['name'] }
    )>, personsCountRange: Maybe<(
      { __typename?: 'EnergoPersonsCountRangeCodebook' }
      & Pick<EnergoPersonsCountRangeCodebook, 'id'>
      & { label: EnergoPersonsCountRangeCodebook['name'] }
    )>, distributionRate: Maybe<(
      { __typename?: 'EnergoDistributionRate' }
      & Pick<EnergoDistributionRate, 'id' | 'name' | 'lowTariff'>
    )>, breaker: Maybe<(
      { __typename?: 'EnergoBreaker' }
      & Pick<EnergoBreaker, 'id' | 'name'>
    )>, standaloneInvoice: Maybe<(
      { __typename?: 'EnergoStandaloneInvoice' }
      & Pick<EnergoStandaloneInvoice, 'id' | 'uuid'>
    )>, bestResultItem: Maybe<(
      { __typename?: 'EnergoElectricityResultItem' }
      & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoElectricityPartner' }
        & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoElectricityProduct' }
        & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    )>, bestGasResultItem: Maybe<(
      { __typename?: 'EnergoGasResultItem' }
      & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoGasPartner' }
        & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoGasProduct' }
        & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    )> }
  ) }
);

export type EnergoGetElectricityLeadQueryVariables = Exact<{
  leadUuid: Scalars['String']['input'];
}>;


export type EnergoGetElectricityLeadQuery = (
  { __typename?: 'Query' }
  & { energoGetElectricityLead: (
    { __typename?: 'EnergoElectricityLead' }
    & Pick<EnergoElectricityLead, 'id' | 'yearlyPayment' | 'yearlyConsumption' | 'yearlyConsumptionLowTariff' | 'currentProductId' | 'currentProductName'>
    & { lead: (
      { __typename?: 'Lead' }
      & Pick<Lead, 'id' | 'type' | 'uuid' | 'name' | 'surname' | 'email' | 'phone' | 'status' | 'customerNote' | 'lastStatusChange' | 'completedAt' | 'sendEmailImmediately'>
      & { address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, contactAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, attachments: Array<(
        { __typename?: 'LeadAttachment' }
        & Pick<LeadAttachment, 'id'>
        & { file: (
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        ) }
      )> }
    ), calculation: (
      { __typename?: 'EnergoCalculation' }
      & Pick<EnergoCalculation, 'id' | 'uuid' | 'type' | 'source' | 'yearlyConsumption' | 'yearlyConsumptionUnit' | 'yearlyConsumptionLowTariff' | 'yearlyConsumptionLowTariffUnit' | 'yearlyPayment' | 'gasYearlyPayment' | 'gasYearlyConsumption' | 'gasYearlyConsumptionUnit' | 'gasMonthlyAdvances' | 'gasUnknownMonthlyAdvances' | 'monthlyAdvances' | 'unknownMonthlyAdvances' | 'email' | 'saving' | 'createdAt' | 'updatedAt' | 'lastViewed' | 'currentProductId' | 'currentProductName' | 'currentProductFollowingId' | 'unknownCurrentProduct' | 'gasCurrentProductId' | 'gasCurrentProductName' | 'gasCurrentProductFollowingId' | 'gasUnknownCurrentProduct' | 'phone' | 'individualPriceExpectedMonthly' | 'individualPriceExpectedMWhHighTariff' | 'individualPriceExpectedMWhLowTariff' | 'gasIndividualPriceExpectedMonthly' | 'gasIndividualPriceExpectedMWh' | 'individualOfferName' | 'reason'>
      & { address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, partner: Maybe<(
        { __typename?: 'EnergoElectricityPartner' }
        & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      )>, gasPartner: Maybe<(
        { __typename?: 'EnergoGasPartner' }
        & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      )>, gasUsages: Maybe<(
        { __typename?: 'GasUsages' }
        & Pick<GasUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
      )>, electricityUsages: Maybe<(
        { __typename?: 'ElectricityUsages' }
        & Pick<ElectricityUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
      )>, floorAreaRange: Maybe<(
        { __typename?: 'EnergoFloorAreaRangeCodebook' }
        & Pick<EnergoFloorAreaRangeCodebook, 'id'>
        & { label: EnergoFloorAreaRangeCodebook['name'] }
      )>, isolation: Maybe<(
        { __typename?: 'EnergoIsolationCodebook' }
        & Pick<EnergoIsolationCodebook, 'id'>
        & { label: EnergoIsolationCodebook['name'] }
      )>, personsCountRange: Maybe<(
        { __typename?: 'EnergoPersonsCountRangeCodebook' }
        & Pick<EnergoPersonsCountRangeCodebook, 'id'>
        & { label: EnergoPersonsCountRangeCodebook['name'] }
      )>, distributionRate: Maybe<(
        { __typename?: 'EnergoDistributionRate' }
        & Pick<EnergoDistributionRate, 'id' | 'name' | 'lowTariff'>
      )>, breaker: Maybe<(
        { __typename?: 'EnergoBreaker' }
        & Pick<EnergoBreaker, 'id' | 'name'>
      )>, standaloneInvoice: Maybe<(
        { __typename?: 'EnergoStandaloneInvoice' }
        & Pick<EnergoStandaloneInvoice, 'id' | 'uuid'>
      )>, bestResultItem: Maybe<(
        { __typename?: 'EnergoElectricityResultItem' }
        & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
        & { partner: (
          { __typename?: 'EnergoElectricityPartner' }
          & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
          & { rating: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), product: (
          { __typename?: 'EnergoElectricityProduct' }
          & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
        ), priceExpectedDetail: (
          { __typename?: 'EnergoElectricityResultPriceDetail' }
          & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ), priceResultDetail: (
          { __typename?: 'EnergoElectricityResultPriceDetail' }
          & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ) }
      )>, bestGasResultItem: Maybe<(
        { __typename?: 'EnergoGasResultItem' }
        & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
        & { partner: (
          { __typename?: 'EnergoGasPartner' }
          & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
          & { rating: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), product: (
          { __typename?: 'EnergoGasProduct' }
          & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
        ), priceExpectedDetail: (
          { __typename?: 'EnergoGasResultPriceDetail' }
          & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ), priceResultDetail: (
          { __typename?: 'EnergoGasResultPriceDetail' }
          & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ) }
      )> }
    ), resultItem: (
      { __typename?: 'EnergoElectricityResultItem' }
      & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoElectricityPartner' }
        & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoElectricityProduct' }
        & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    ), userResultItem: Maybe<(
      { __typename?: 'EnergoElectricityResultItem' }
      & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoElectricityPartner' }
        & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoElectricityProduct' }
        & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    )>, partner: Maybe<(
      { __typename?: 'EnergoElectricityPartner' }
      & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    )>, breaker: Maybe<(
      { __typename?: 'EnergoBreaker' }
      & Pick<EnergoBreaker, 'id'>
      & { label: EnergoBreaker['name'] }
    )>, distributionRate: Maybe<(
      { __typename?: 'EnergoDistributionRate' }
      & Pick<EnergoDistributionRate, 'id' | 'description' | 'lowTariff'>
      & { label: EnergoDistributionRate['name'] }
    )> }
  ) }
);

export type EnergoGetGasLeadQueryVariables = Exact<{
  leadUuid: Scalars['String']['input'];
}>;


export type EnergoGetGasLeadQuery = (
  { __typename?: 'Query' }
  & { energoGetGasLead: (
    { __typename?: 'EnergoGasLead' }
    & Pick<EnergoGasLead, 'id' | 'yearlyPayment' | 'yearlyConsumption' | 'currentProductId' | 'currentProductName'>
    & { lead: (
      { __typename?: 'Lead' }
      & Pick<Lead, 'id' | 'type' | 'uuid' | 'name' | 'surname' | 'email' | 'phone' | 'status' | 'customerNote' | 'lastStatusChange' | 'completedAt' | 'sendEmailImmediately'>
      & { address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, contactAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, attachments: Array<(
        { __typename?: 'LeadAttachment' }
        & Pick<LeadAttachment, 'id'>
        & { file: (
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        ) }
      )> }
    ), calculation: (
      { __typename?: 'EnergoCalculation' }
      & Pick<EnergoCalculation, 'id' | 'uuid' | 'type' | 'source' | 'yearlyConsumption' | 'yearlyConsumptionUnit' | 'yearlyConsumptionLowTariff' | 'yearlyConsumptionLowTariffUnit' | 'yearlyPayment' | 'gasYearlyPayment' | 'gasYearlyConsumption' | 'gasYearlyConsumptionUnit' | 'gasMonthlyAdvances' | 'gasUnknownMonthlyAdvances' | 'monthlyAdvances' | 'unknownMonthlyAdvances' | 'email' | 'saving' | 'createdAt' | 'updatedAt' | 'lastViewed' | 'currentProductId' | 'currentProductName' | 'currentProductFollowingId' | 'unknownCurrentProduct' | 'gasCurrentProductId' | 'gasCurrentProductName' | 'gasCurrentProductFollowingId' | 'gasUnknownCurrentProduct' | 'phone' | 'individualPriceExpectedMonthly' | 'individualPriceExpectedMWhHighTariff' | 'individualPriceExpectedMWhLowTariff' | 'gasIndividualPriceExpectedMonthly' | 'gasIndividualPriceExpectedMWh' | 'individualOfferName' | 'reason'>
      & { address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, partner: Maybe<(
        { __typename?: 'EnergoElectricityPartner' }
        & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      )>, gasPartner: Maybe<(
        { __typename?: 'EnergoGasPartner' }
        & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      )>, gasUsages: Maybe<(
        { __typename?: 'GasUsages' }
        & Pick<GasUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
      )>, electricityUsages: Maybe<(
        { __typename?: 'ElectricityUsages' }
        & Pick<ElectricityUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
      )>, floorAreaRange: Maybe<(
        { __typename?: 'EnergoFloorAreaRangeCodebook' }
        & Pick<EnergoFloorAreaRangeCodebook, 'id'>
        & { label: EnergoFloorAreaRangeCodebook['name'] }
      )>, isolation: Maybe<(
        { __typename?: 'EnergoIsolationCodebook' }
        & Pick<EnergoIsolationCodebook, 'id'>
        & { label: EnergoIsolationCodebook['name'] }
      )>, personsCountRange: Maybe<(
        { __typename?: 'EnergoPersonsCountRangeCodebook' }
        & Pick<EnergoPersonsCountRangeCodebook, 'id'>
        & { label: EnergoPersonsCountRangeCodebook['name'] }
      )>, distributionRate: Maybe<(
        { __typename?: 'EnergoDistributionRate' }
        & Pick<EnergoDistributionRate, 'id' | 'name' | 'lowTariff'>
      )>, breaker: Maybe<(
        { __typename?: 'EnergoBreaker' }
        & Pick<EnergoBreaker, 'id' | 'name'>
      )>, standaloneInvoice: Maybe<(
        { __typename?: 'EnergoStandaloneInvoice' }
        & Pick<EnergoStandaloneInvoice, 'id' | 'uuid'>
      )>, bestResultItem: Maybe<(
        { __typename?: 'EnergoElectricityResultItem' }
        & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
        & { partner: (
          { __typename?: 'EnergoElectricityPartner' }
          & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
          & { rating: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), product: (
          { __typename?: 'EnergoElectricityProduct' }
          & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
        ), priceExpectedDetail: (
          { __typename?: 'EnergoElectricityResultPriceDetail' }
          & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ), priceResultDetail: (
          { __typename?: 'EnergoElectricityResultPriceDetail' }
          & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ) }
      )>, bestGasResultItem: Maybe<(
        { __typename?: 'EnergoGasResultItem' }
        & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
        & { partner: (
          { __typename?: 'EnergoGasPartner' }
          & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
          & { rating: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), product: (
          { __typename?: 'EnergoGasProduct' }
          & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
        ), priceExpectedDetail: (
          { __typename?: 'EnergoGasResultPriceDetail' }
          & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ), priceResultDetail: (
          { __typename?: 'EnergoGasResultPriceDetail' }
          & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ) }
      )> }
    ), resultItem: (
      { __typename?: 'EnergoGasResultItem' }
      & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoGasPartner' }
        & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoGasProduct' }
        & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    ), userResultItem: Maybe<(
      { __typename?: 'EnergoGasResultItem' }
      & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoGasPartner' }
        & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoGasProduct' }
        & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    )>, partner: Maybe<(
      { __typename?: 'EnergoGasPartner' }
      & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    )> }
  ) }
);

export type EnergoGetCalculationResultQueryVariables = Exact<{
  calculationUuid: Scalars['String']['input'];
}>;


export type EnergoGetCalculationResultQuery = (
  { __typename?: 'Query' }
  & { energoGetCalculationResult: (
    { __typename?: 'EnergoCalculationResult' }
    & Pick<EnergoCalculationResult, 'id' | 'hasEnergoOffers'>
    & { calculation: (
      { __typename?: 'EnergoCalculation' }
      & Pick<EnergoCalculation, 'id' | 'uuid' | 'type' | 'source' | 'yearlyConsumption' | 'yearlyConsumptionUnit' | 'yearlyConsumptionLowTariff' | 'yearlyConsumptionLowTariffUnit' | 'yearlyPayment' | 'gasYearlyPayment' | 'gasYearlyConsumption' | 'gasYearlyConsumptionUnit' | 'gasMonthlyAdvances' | 'gasUnknownMonthlyAdvances' | 'monthlyAdvances' | 'unknownMonthlyAdvances' | 'email' | 'saving' | 'createdAt' | 'updatedAt' | 'lastViewed' | 'currentProductId' | 'currentProductName' | 'currentProductFollowingId' | 'unknownCurrentProduct' | 'gasCurrentProductId' | 'gasCurrentProductName' | 'gasCurrentProductFollowingId' | 'gasUnknownCurrentProduct' | 'phone' | 'individualPriceExpectedMonthly' | 'individualPriceExpectedMWhHighTariff' | 'individualPriceExpectedMWhLowTariff' | 'gasIndividualPriceExpectedMonthly' | 'gasIndividualPriceExpectedMWh' | 'individualOfferName' | 'reason'>
      & { address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, partner: Maybe<(
        { __typename?: 'EnergoElectricityPartner' }
        & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      )>, gasPartner: Maybe<(
        { __typename?: 'EnergoGasPartner' }
        & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      )>, gasUsages: Maybe<(
        { __typename?: 'GasUsages' }
        & Pick<GasUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
      )>, electricityUsages: Maybe<(
        { __typename?: 'ElectricityUsages' }
        & Pick<ElectricityUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
      )>, floorAreaRange: Maybe<(
        { __typename?: 'EnergoFloorAreaRangeCodebook' }
        & Pick<EnergoFloorAreaRangeCodebook, 'id'>
        & { label: EnergoFloorAreaRangeCodebook['name'] }
      )>, isolation: Maybe<(
        { __typename?: 'EnergoIsolationCodebook' }
        & Pick<EnergoIsolationCodebook, 'id'>
        & { label: EnergoIsolationCodebook['name'] }
      )>, personsCountRange: Maybe<(
        { __typename?: 'EnergoPersonsCountRangeCodebook' }
        & Pick<EnergoPersonsCountRangeCodebook, 'id'>
        & { label: EnergoPersonsCountRangeCodebook['name'] }
      )>, distributionRate: Maybe<(
        { __typename?: 'EnergoDistributionRate' }
        & Pick<EnergoDistributionRate, 'id' | 'name' | 'lowTariff'>
      )>, breaker: Maybe<(
        { __typename?: 'EnergoBreaker' }
        & Pick<EnergoBreaker, 'id' | 'name'>
      )>, standaloneInvoice: Maybe<(
        { __typename?: 'EnergoStandaloneInvoice' }
        & Pick<EnergoStandaloneInvoice, 'id' | 'uuid'>
      )>, bestResultItem: Maybe<(
        { __typename?: 'EnergoElectricityResultItem' }
        & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
        & { partner: (
          { __typename?: 'EnergoElectricityPartner' }
          & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
          & { rating: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), product: (
          { __typename?: 'EnergoElectricityProduct' }
          & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
        ), priceExpectedDetail: (
          { __typename?: 'EnergoElectricityResultPriceDetail' }
          & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ), priceResultDetail: (
          { __typename?: 'EnergoElectricityResultPriceDetail' }
          & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ) }
      )>, bestGasResultItem: Maybe<(
        { __typename?: 'EnergoGasResultItem' }
        & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
        & { partner: (
          { __typename?: 'EnergoGasPartner' }
          & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
          & { rating: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), product: (
          { __typename?: 'EnergoGasProduct' }
          & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
        ), priceExpectedDetail: (
          { __typename?: 'EnergoGasResultPriceDetail' }
          & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ), priceResultDetail: (
          { __typename?: 'EnergoGasResultPriceDetail' }
          & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ) }
      )> }
    ), electricityResult: Maybe<(
      { __typename?: 'EnergoElectricityCalculationResult' }
      & { resultItems: Array<(
        { __typename?: 'EnergoElectricityResultItem' }
        & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
        & { partner: (
          { __typename?: 'EnergoElectricityPartner' }
          & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
          & { rating: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), product: (
          { __typename?: 'EnergoElectricityProduct' }
          & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
        ), priceExpectedDetail: (
          { __typename?: 'EnergoElectricityResultPriceDetail' }
          & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ), priceResultDetail: (
          { __typename?: 'EnergoElectricityResultPriceDetail' }
          & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ) }
      )>, common: (
        { __typename?: 'EnergoElectricityCalculationResultCommon' }
        & Pick<EnergoElectricityCalculationResultCommon, 'priceCurrentAnnually' | 'priceCurrentMonthly' | 'consumptionHT' | 'consumptionLT'>
      ), bestGuaranteed: Maybe<(
        { __typename?: 'EnergoElectricityResultItem' }
        & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
        & { partner: (
          { __typename?: 'EnergoElectricityPartner' }
          & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
          & { rating: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), product: (
          { __typename?: 'EnergoElectricityProduct' }
          & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
        ), priceExpectedDetail: (
          { __typename?: 'EnergoElectricityResultPriceDetail' }
          & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ), priceResultDetail: (
          { __typename?: 'EnergoElectricityResultPriceDetail' }
          & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ) }
      )>, bestLengthOfFixation: Maybe<(
        { __typename?: 'EnergoElectricityResultItem' }
        & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
        & { partner: (
          { __typename?: 'EnergoElectricityPartner' }
          & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
          & { rating: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), product: (
          { __typename?: 'EnergoElectricityProduct' }
          & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
        ), priceExpectedDetail: (
          { __typename?: 'EnergoElectricityResultPriceDetail' }
          & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ), priceResultDetail: (
          { __typename?: 'EnergoElectricityResultPriceDetail' }
          & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ) }
      )>, bestNoFixation: Maybe<(
        { __typename?: 'EnergoElectricityResultItem' }
        & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
        & { partner: (
          { __typename?: 'EnergoElectricityPartner' }
          & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
          & { rating: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), product: (
          { __typename?: 'EnergoElectricityProduct' }
          & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
        ), priceExpectedDetail: (
          { __typename?: 'EnergoElectricityResultPriceDetail' }
          & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ), priceResultDetail: (
          { __typename?: 'EnergoElectricityResultPriceDetail' }
          & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ) }
      )> }
    )>, gasResult: Maybe<(
      { __typename?: 'EnergoGasCalculationResult' }
      & { resultItems: Array<(
        { __typename?: 'EnergoGasResultItem' }
        & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
        & { partner: (
          { __typename?: 'EnergoGasPartner' }
          & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
          & { rating: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), product: (
          { __typename?: 'EnergoGasProduct' }
          & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
        ), priceExpectedDetail: (
          { __typename?: 'EnergoGasResultPriceDetail' }
          & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ), priceResultDetail: (
          { __typename?: 'EnergoGasResultPriceDetail' }
          & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ) }
      )>, common: (
        { __typename?: 'EnergoGasCalculationResultCommon' }
        & Pick<EnergoGasCalculationResultCommon, 'priceCurrentAnnually' | 'priceCurrentMonthly' | 'consumption'>
      ), bestGuaranteed: Maybe<(
        { __typename?: 'EnergoGasResultItem' }
        & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
        & { partner: (
          { __typename?: 'EnergoGasPartner' }
          & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
          & { rating: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), product: (
          { __typename?: 'EnergoGasProduct' }
          & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
        ), priceExpectedDetail: (
          { __typename?: 'EnergoGasResultPriceDetail' }
          & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ), priceResultDetail: (
          { __typename?: 'EnergoGasResultPriceDetail' }
          & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ) }
      )>, bestLengthOfFixation: Maybe<(
        { __typename?: 'EnergoGasResultItem' }
        & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
        & { partner: (
          { __typename?: 'EnergoGasPartner' }
          & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
          & { rating: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), product: (
          { __typename?: 'EnergoGasProduct' }
          & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
        ), priceExpectedDetail: (
          { __typename?: 'EnergoGasResultPriceDetail' }
          & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ), priceResultDetail: (
          { __typename?: 'EnergoGasResultPriceDetail' }
          & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ) }
      )>, bestNoFixation: Maybe<(
        { __typename?: 'EnergoGasResultItem' }
        & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
        & { partner: (
          { __typename?: 'EnergoGasPartner' }
          & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
          & { rating: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), product: (
          { __typename?: 'EnergoGasProduct' }
          & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
        ), priceExpectedDetail: (
          { __typename?: 'EnergoGasResultPriceDetail' }
          & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ), priceResultDetail: (
          { __typename?: 'EnergoGasResultPriceDetail' }
          & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ) }
      )> }
    )> }
  ) }
);

export type EnergoCodebooksQueryVariables = Exact<{ [key: string]: never; }>;


export type EnergoCodebooksQuery = (
  { __typename?: 'Query' }
  & { energoCodebooks: (
    { __typename?: 'EnergoCodebooks' }
    & { breaker: Array<(
      { __typename?: 'EnergoBreaker' }
      & Pick<EnergoBreaker, 'id'>
      & { label: EnergoBreaker['name'] }
    )>, partner: Array<(
      { __typename?: 'EnergoElectricityPartnerCodebook' }
      & Pick<EnergoElectricityPartnerCodebook, 'id' | 'isActive' | 'seoName' | 'logoUrl' | 'invoiceHelpDistributionRateMD' | 'invoiceHelpInvoicedPaymentMD' | 'invoiceHelpHighTariffMD' | 'invoiceHelpLowTariffMD' | 'invoiceHelpBreakerMD' | 'invoiceHelpCurrentProductMD' | 'cooperationMethod' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'createdAt' | 'updatedAt' | 'adminLastEdit'>
      & { label: EnergoElectricityPartnerCodebook['name'] }
      & { invoiceHelpDistributionRateImage: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )>, invoiceHelpInvoicedPaymentImage: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )>, invoiceHelpHighTariffImage: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )>, invoiceHelpLowTariffImage: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )>, invoiceHelpBreakerImage: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )>, invoiceHelpCurrentProductImage: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )> }
    )>, gasPartner: Array<(
      { __typename?: 'EnergoGasPartnerCodebook' }
      & Pick<EnergoGasPartnerCodebook, 'id' | 'isActive' | 'seoName' | 'logoUrl' | 'invoiceHelpYearlyConsumptionMD' | 'invoiceHelpInvoicedPaymentMD' | 'invoiceHelpCurrentProductMD' | 'cooperationMethod' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'createdAt' | 'updatedAt' | 'adminLastEdit'>
      & { label: EnergoGasPartnerCodebook['name'] }
      & { invoiceHelpYearlyConsumptionImage: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )>, invoiceHelpInvoicedPaymentImage: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )>, invoiceHelpCurrentProductImage: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )> }
    )>, distributionRate: Array<(
      { __typename?: 'EnergoDistributionRate' }
      & Pick<EnergoDistributionRate, 'id' | 'description' | 'lowTariff'>
      & { label: EnergoDistributionRate['name'] }
    )>, personsCountRange: Array<(
      { __typename?: 'EnergoPersonsCountRangeCodebook' }
      & Pick<EnergoPersonsCountRangeCodebook, 'id'>
      & { label: EnergoPersonsCountRangeCodebook['name'] }
    )>, floorAreaRange: Array<(
      { __typename?: 'EnergoFloorAreaRangeCodebook' }
      & Pick<EnergoFloorAreaRangeCodebook, 'id'>
      & { label: EnergoFloorAreaRangeCodebook['name'] }
    )>, isolation: Array<(
      { __typename?: 'EnergoIsolationCodebook' }
      & Pick<EnergoIsolationCodebook, 'id'>
      & { label: EnergoIsolationCodebook['name'] }
    )> }
  ) }
);

export type EnergoGetElectricityProductsCodebookQueryVariables = Exact<{
  partnerId: Scalars['Int']['input'];
}>;


export type EnergoGetElectricityProductsCodebookQuery = (
  { __typename?: 'Query' }
  & { energoGetElectricityProducts: Array<(
    { __typename?: 'EnergoKalkulatorEnergoElectricityProduct' }
    & Pick<EnergoKalkulatorEnergoElectricityProduct, 'id' | 'isActiveForSelectCurrentEstimate' | 'isActiveForSelectCurrentExact'>
    & { label: EnergoKalkulatorEnergoElectricityProduct['name'] }
  )> }
);

export type EnergoGetGasProductsCodebookQueryVariables = Exact<{
  partnerId: Scalars['Int']['input'];
}>;


export type EnergoGetGasProductsCodebookQuery = (
  { __typename?: 'Query' }
  & { energoGetGasProducts: Array<(
    { __typename?: 'EnergoKalkulatorEnergoGasProduct' }
    & Pick<EnergoKalkulatorEnergoGasProduct, 'id' | 'isActiveForSelectCurrentEstimate' | 'isActiveForSelectCurrentExact'>
    & { label: EnergoKalkulatorEnergoGasProduct['name'] }
  )> }
);

export type EnergoGetElectricityPartnerDetailQueryVariables = Exact<{
  partnerId: Scalars['Int']['input'];
}>;


export type EnergoGetElectricityPartnerDetailQuery = (
  { __typename?: 'Query' }
  & { energoGetActiveElectricityProducts: Array<(
    { __typename?: 'EnergoKalkulatorEnergoElectricityProduct' }
    & Pick<EnergoKalkulatorEnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'isActiveForSelectCurrentEstimate' | 'isActiveForSelectCurrentExact' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip' | 'createdAt' | 'updatedAt' | 'adminLastEdit'>
    & { partner: Maybe<(
      { __typename?: 'EnergoElectricityPartner' }
      & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    )> }
  )>, energoGetElectricityPartner: (
    { __typename?: 'EnergoElectricityPartnerDetail' }
    & Pick<EnergoElectricityPartnerDetail, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'cooperationMethod' | 'termsOfServicePreview' | 'companyAddress' | 'contactEmail' | 'tollFreePhone' | 'latitude' | 'longitude' | 'web' | 'urlVop' | 'urlPricelist' | 'longArticleMD' | 'isActive' | 'energoPartnerId' | 'title' | 'titleSuffix' | 'metaTitle' | 'metaDescription'>
    & { rating: Maybe<(
      { __typename?: 'NewRating' }
      & Pick<NewRating, 'id' | 'total'>
      & { categories: Array<Maybe<(
        { __typename?: 'RatingCategory' }
        & Pick<RatingCategory, 'id' | 'label' | 'value'>
      )>> }
    )> }
  ) }
);

export type EnergoGetGasPartnerDetailQueryVariables = Exact<{
  partnerId: Scalars['Int']['input'];
}>;


export type EnergoGetGasPartnerDetailQuery = (
  { __typename?: 'Query' }
  & { energoGetActiveGasProducts: Array<(
    { __typename?: 'EnergoKalkulatorEnergoGasProduct' }
    & Pick<EnergoKalkulatorEnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'isActiveForSelectCurrentEstimate' | 'isActiveForSelectCurrentExact' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip' | 'createdAt' | 'updatedAt' | 'adminLastEdit'>
    & { partner: Maybe<(
      { __typename?: 'EnergoGasPartner' }
      & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    )> }
  )>, energoGetGasPartner: (
    { __typename?: 'EnergoGasPartnerDetail' }
    & Pick<EnergoGasPartnerDetail, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'cooperationMethod' | 'termsOfServicePreview' | 'companyAddress' | 'contactEmail' | 'tollFreePhone' | 'latitude' | 'longitude' | 'web' | 'urlVop' | 'urlPricelist' | 'longArticleMD' | 'isActive' | 'energoPartnerId' | 'title' | 'titleSuffix' | 'metaTitle' | 'metaDescription'>
    & { rating: Maybe<(
      { __typename?: 'NewRating' }
      & Pick<NewRating, 'id' | 'total'>
      & { categories: Array<Maybe<(
        { __typename?: 'RatingCategory' }
        & Pick<RatingCategory, 'id' | 'label' | 'value'>
      )>> }
    )> }
  ) }
);

export type EnergoGetElectricityDetailQueryVariables = Exact<{
  partnerId: Scalars['Int']['input'];
  productId: Scalars['Int']['input'];
}>;


export type EnergoGetElectricityDetailQuery = (
  { __typename?: 'Query' }
  & { energoGetElectricityProduct: (
    { __typename?: 'EnergoKalkulatorEnergoElectricityProductDetail' }
    & Pick<EnergoKalkulatorEnergoElectricityProductDetail, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'isActiveForSelectCurrentEstimate' | 'isActiveForSelectCurrentExact' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'partnerId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip' | 'createdAt' | 'updatedAt' | 'adminLastEdit'>
    & { partner: Maybe<(
      { __typename?: 'EnergoKalkulatorPartnerCodebook' }
      & Pick<EnergoKalkulatorPartnerCodebook, 'label' | 'value'>
    )> }
  ), energoGetElectricityPartner: (
    { __typename?: 'EnergoElectricityPartnerDetail' }
    & Pick<EnergoElectricityPartnerDetail, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'cooperationMethod' | 'termsOfServicePreview' | 'companyAddress' | 'contactEmail' | 'tollFreePhone' | 'latitude' | 'longitude' | 'web' | 'urlVop' | 'urlPricelist' | 'longArticleMD' | 'isActive' | 'energoPartnerId' | 'title' | 'titleSuffix' | 'metaTitle' | 'metaDescription'>
    & { rating: Maybe<(
      { __typename?: 'NewRating' }
      & Pick<NewRating, 'id' | 'total'>
      & { categories: Array<Maybe<(
        { __typename?: 'RatingCategory' }
        & Pick<RatingCategory, 'id' | 'label' | 'value'>
      )>> }
    )> }
  ) }
);

export type EnergoGetGasDetailQueryVariables = Exact<{
  partnerId: Scalars['Int']['input'];
  productId: Scalars['Int']['input'];
}>;


export type EnergoGetGasDetailQuery = (
  { __typename?: 'Query' }
  & { energoGetGasProduct: (
    { __typename?: 'EnergoKalkulatorEnergoGasProductDetail' }
    & Pick<EnergoKalkulatorEnergoGasProductDetail, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'isActiveForSelectCurrentEstimate' | 'isActiveForSelectCurrentExact' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'partnerId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip' | 'createdAt' | 'updatedAt' | 'adminLastEdit'>
    & { partner: Maybe<(
      { __typename?: 'EnergoKalkulatorPartnerCodebook' }
      & Pick<EnergoKalkulatorPartnerCodebook, 'label' | 'value'>
    )> }
  ), energoGetGasPartner: (
    { __typename?: 'EnergoGasPartnerDetail' }
    & Pick<EnergoGasPartnerDetail, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'cooperationMethod' | 'termsOfServicePreview' | 'companyAddress' | 'contactEmail' | 'tollFreePhone' | 'latitude' | 'longitude' | 'web' | 'urlVop' | 'urlPricelist' | 'longArticleMD' | 'isActive' | 'energoPartnerId' | 'title' | 'titleSuffix' | 'metaTitle' | 'metaDescription'>
    & { rating: Maybe<(
      { __typename?: 'NewRating' }
      & Pick<NewRating, 'id' | 'total'>
      & { categories: Array<Maybe<(
        { __typename?: 'RatingCategory' }
        & Pick<RatingCategory, 'id' | 'label' | 'value'>
      )>> }
    )> }
  ) }
);

export type EnergoGetElectricityProductQueryVariables = Exact<{
  productId: Scalars['Int']['input'];
}>;


export type EnergoGetElectricityProductQuery = (
  { __typename?: 'Query' }
  & { energoGetElectricityProduct: (
    { __typename?: 'EnergoKalkulatorEnergoElectricityProductDetail' }
    & Pick<EnergoKalkulatorEnergoElectricityProductDetail, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'isActiveForSelectCurrentEstimate' | 'isActiveForSelectCurrentExact' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'partnerId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip' | 'createdAt' | 'updatedAt' | 'adminLastEdit'>
    & { partner: Maybe<(
      { __typename?: 'EnergoKalkulatorPartnerCodebook' }
      & Pick<EnergoKalkulatorPartnerCodebook, 'label' | 'value'>
    )> }
  ) }
);

export type EnergoGetGasProductQueryVariables = Exact<{
  productId: Scalars['Int']['input'];
}>;


export type EnergoGetGasProductQuery = (
  { __typename?: 'Query' }
  & { energoGetGasProduct: (
    { __typename?: 'EnergoKalkulatorEnergoGasProductDetail' }
    & Pick<EnergoKalkulatorEnergoGasProductDetail, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'isActiveForSelectCurrentEstimate' | 'isActiveForSelectCurrentExact' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'partnerId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip' | 'createdAt' | 'updatedAt' | 'adminLastEdit'>
    & { partner: Maybe<(
      { __typename?: 'EnergoKalkulatorPartnerCodebook' }
      & Pick<EnergoKalkulatorPartnerCodebook, 'label' | 'value'>
    )> }
  ) }
);

export type EnergoGetElectricityPartnerQueryVariables = Exact<{
  partnerId: Scalars['Int']['input'];
}>;


export type EnergoGetElectricityPartnerQuery = (
  { __typename?: 'Query' }
  & { energoGetElectricityPartner: (
    { __typename?: 'EnergoElectricityPartnerDetail' }
    & Pick<EnergoElectricityPartnerDetail, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'cooperationMethod' | 'termsOfServicePreview' | 'companyAddress' | 'contactEmail' | 'tollFreePhone' | 'latitude' | 'longitude' | 'web' | 'urlVop' | 'urlPricelist' | 'longArticleMD' | 'isActive' | 'energoPartnerId' | 'title' | 'titleSuffix' | 'metaTitle' | 'metaDescription'>
    & { rating: Maybe<(
      { __typename?: 'NewRating' }
      & Pick<NewRating, 'id' | 'total'>
      & { categories: Array<Maybe<(
        { __typename?: 'RatingCategory' }
        & Pick<RatingCategory, 'id' | 'label' | 'value'>
      )>> }
    )> }
  ) }
);

export type EnergoGetGasPartnerQueryVariables = Exact<{
  partnerId: Scalars['Int']['input'];
}>;


export type EnergoGetGasPartnerQuery = (
  { __typename?: 'Query' }
  & { energoGetGasPartner: (
    { __typename?: 'EnergoGasPartnerDetail' }
    & Pick<EnergoGasPartnerDetail, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'cooperationMethod' | 'termsOfServicePreview' | 'companyAddress' | 'contactEmail' | 'tollFreePhone' | 'latitude' | 'longitude' | 'web' | 'urlVop' | 'urlPricelist' | 'longArticleMD' | 'isActive' | 'energoPartnerId' | 'title' | 'titleSuffix' | 'metaTitle' | 'metaDescription'>
    & { rating: Maybe<(
      { __typename?: 'NewRating' }
      & Pick<NewRating, 'id' | 'total'>
      & { categories: Array<Maybe<(
        { __typename?: 'RatingCategory' }
        & Pick<RatingCategory, 'id' | 'label' | 'value'>
      )>> }
    )> }
  ) }
);

export type EnergoGetActiveElectricityProductsQueryVariables = Exact<{
  partnerId: Scalars['Int']['input'];
}>;


export type EnergoGetActiveElectricityProductsQuery = (
  { __typename?: 'Query' }
  & { energoGetActiveElectricityProducts: Array<(
    { __typename?: 'EnergoKalkulatorEnergoElectricityProduct' }
    & Pick<EnergoKalkulatorEnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'isActiveForSelectCurrentEstimate' | 'isActiveForSelectCurrentExact' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip' | 'createdAt' | 'updatedAt' | 'adminLastEdit'>
    & { partner: Maybe<(
      { __typename?: 'EnergoElectricityPartner' }
      & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    )> }
  )> }
);

export type EnergoGetActiveGasProductsQueryVariables = Exact<{
  partnerId: Scalars['Int']['input'];
}>;


export type EnergoGetActiveGasProductsQuery = (
  { __typename?: 'Query' }
  & { energoGetActiveGasProducts: Array<(
    { __typename?: 'EnergoKalkulatorEnergoGasProduct' }
    & Pick<EnergoKalkulatorEnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'isActiveForSelectCurrentEstimate' | 'isActiveForSelectCurrentExact' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip' | 'createdAt' | 'updatedAt' | 'adminLastEdit'>
    & { partner: Maybe<(
      { __typename?: 'EnergoGasPartner' }
      & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    )> }
  )> }
);

export type EnergoGetAllElectricityProductsQueryVariables = Exact<{ [key: string]: never; }>;


export type EnergoGetAllElectricityProductsQuery = (
  { __typename?: 'Query' }
  & { energoGetAllElectricityProducts: Array<(
    { __typename?: 'EnergoKalkulatorEnergoElectricityProduct' }
    & Pick<EnergoKalkulatorEnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'isActiveForSelectCurrentEstimate' | 'isActiveForSelectCurrentExact' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip' | 'createdAt' | 'updatedAt' | 'adminLastEdit'>
    & { partner: Maybe<(
      { __typename?: 'EnergoElectricityPartner' }
      & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    )> }
  )> }
);

export type EnergoGetAllGasProductsQueryVariables = Exact<{ [key: string]: never; }>;


export type EnergoGetAllGasProductsQuery = (
  { __typename?: 'Query' }
  & { energoGetAllGasProducts: Array<(
    { __typename?: 'EnergoKalkulatorEnergoGasProduct' }
    & Pick<EnergoKalkulatorEnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'isActiveForSelectCurrentEstimate' | 'isActiveForSelectCurrentExact' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip' | 'createdAt' | 'updatedAt' | 'adminLastEdit'>
    & { partner: Maybe<(
      { __typename?: 'EnergoGasPartner' }
      & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    )> }
  )> }
);

export type EnergoGetEnergoPartnersQueryVariables = Exact<{ [key: string]: never; }>;


export type EnergoGetEnergoPartnersQuery = (
  { __typename?: 'Query' }
  & { energoGetEnergoPartners: Array<(
    { __typename?: 'EnergoPartner' }
    & Pick<EnergoPartner, 'id' | 'name' | 'seoName' | 'descriptionMD' | 'title' | 'metaDescription' | 'metaTitle'>
    & { partnerElectro: Maybe<(
      { __typename?: 'EnergoElectricityPartnerDetail' }
      & Pick<EnergoElectricityPartnerDetail, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'cooperationMethod' | 'termsOfServicePreview' | 'companyAddress' | 'contactEmail' | 'tollFreePhone' | 'latitude' | 'longitude' | 'web' | 'urlVop' | 'urlPricelist' | 'longArticleMD' | 'isActive' | 'energoPartnerId' | 'title' | 'titleSuffix' | 'metaTitle' | 'metaDescription'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    )>, partnerGas: Maybe<(
      { __typename?: 'EnergoGasPartnerDetail' }
      & Pick<EnergoGasPartnerDetail, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'cooperationMethod' | 'termsOfServicePreview' | 'companyAddress' | 'contactEmail' | 'tollFreePhone' | 'latitude' | 'longitude' | 'web' | 'urlVop' | 'urlPricelist' | 'longArticleMD' | 'isActive' | 'energoPartnerId' | 'title' | 'titleSuffix' | 'metaTitle' | 'metaDescription'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    )> }
  )> }
);

export type EnergoGetEnergoPartnerQueryVariables = Exact<{
  partnerId: Scalars['Int']['input'];
}>;


export type EnergoGetEnergoPartnerQuery = (
  { __typename?: 'Query' }
  & { energoGetEnergoPartner: (
    { __typename?: 'EnergoPartner' }
    & Pick<EnergoPartner, 'id' | 'name' | 'seoName' | 'descriptionMD' | 'title' | 'metaDescription' | 'metaTitle'>
    & { partnerElectro: Maybe<(
      { __typename?: 'EnergoElectricityPartnerDetail' }
      & Pick<EnergoElectricityPartnerDetail, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'cooperationMethod' | 'termsOfServicePreview' | 'companyAddress' | 'contactEmail' | 'tollFreePhone' | 'latitude' | 'longitude' | 'web' | 'urlVop' | 'urlPricelist' | 'longArticleMD' | 'isActive' | 'energoPartnerId' | 'title' | 'titleSuffix' | 'metaTitle' | 'metaDescription'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    )>, partnerGas: Maybe<(
      { __typename?: 'EnergoGasPartnerDetail' }
      & Pick<EnergoGasPartnerDetail, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'cooperationMethod' | 'termsOfServicePreview' | 'companyAddress' | 'contactEmail' | 'tollFreePhone' | 'latitude' | 'longitude' | 'web' | 'urlVop' | 'urlPricelist' | 'longArticleMD' | 'isActive' | 'energoPartnerId' | 'title' | 'titleSuffix' | 'metaTitle' | 'metaDescription'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    )> }
  ) }
);

export type EnergoGetElectricityPriceProductPreviewListQueryVariables = Exact<{
  data: ElectricityPriceProductPreviewListInput;
}>;


export type EnergoGetElectricityPriceProductPreviewListQuery = (
  { __typename?: 'Query' }
  & { energoGetElectricityPriceProductPreviewList: Array<(
    { __typename?: 'EnergoElectricityPriceProductPreviewItem' }
    & Pick<EnergoElectricityPriceProductPreviewItem, 'id' | 'priceMonthly' | 'priceMWhHighTariff' | 'priceMWhLowTariff' | 'distributionRateId' | 'distributorId'>
    & { product: (
      { __typename?: 'EnergoElectricityProductPreview' }
      & Pick<EnergoElectricityProductPreview, 'id' | 'name' | 'isActive' | 'isActiveForPurchase' | 'activeForNewCustomers' | 'activeForCurrentCustomers' | 'activeForSelectCurrentExact' | 'activeForSelectCurrentEstimate' | 'private' | 'preferredSale' | 'availableForSale'>
      & { partner: (
        { __typename?: 'EnergoElectricityPartnerPreview' }
        & Pick<EnergoElectricityPartnerPreview, 'id' | 'name' | 'energoPartnerId' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'cooperationMethod' | 'companyAddress' | 'contactEmail' | 'tollFreePhone' | 'longitude' | 'latitude' | 'web' | 'urlVop' | 'urlPricelist' | 'longArticleMD' | 'active' | 'estimateDefaultProductId'>
      ) }
    ) }
  )> }
);

export type EnergoGetGasPriceProductPreviewListQueryVariables = Exact<{
  data: GasPriceProductPreviewListInput;
}>;


export type EnergoGetGasPriceProductPreviewListQuery = (
  { __typename?: 'Query' }
  & { energoGetGasPriceProductPreviewList: Array<(
    { __typename?: 'EnergoGasPriceProductPreviewItem' }
    & Pick<EnergoGasPriceProductPreviewItem, 'id' | 'pricePerM3' | 'priceMonthly' | 'priceMWh' | 'odberovePasmoId'>
    & { product: (
      { __typename?: 'EnergoGasProductPreview' }
      & Pick<EnergoGasProductPreview, 'id' | 'name' | 'isActive' | 'isActiveForPurchase' | 'activeForNewCustomers' | 'activeForCurrentCustomers' | 'activeForSelectCurrentExact' | 'activeForSelectCurrentEstimate' | 'private' | 'preferredSale' | 'availableForSale'>
      & { partner: (
        { __typename?: 'EnergoGasPartnerPreview' }
        & Pick<EnergoGasPartnerPreview, 'id' | 'name' | 'energoPartnerId' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'cooperationMethod' | 'companyAddress' | 'contactEmail' | 'tollFreePhone' | 'longitude' | 'latitude' | 'web' | 'urlVop' | 'urlPricelist' | 'longArticleMD' | 'active' | 'estimateDefaultProductId'>
      ) }
    ) }
  )> }
);

export type EnergoGetElectricityCalculationResultItemQueryVariables = Exact<{
  uuid: Scalars['String']['input'];
  resultItemId: Scalars['String']['input'];
}>;


export type EnergoGetElectricityCalculationResultItemQuery = (
  { __typename?: 'Query' }
  & { energoGetElectricityCalculationResultItem: (
    { __typename?: 'EnergoElectricityResultItem' }
    & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
    & { partner: (
      { __typename?: 'EnergoElectricityPartner' }
      & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    ), product: (
      { __typename?: 'EnergoElectricityProduct' }
      & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
    ), priceExpectedDetail: (
      { __typename?: 'EnergoElectricityResultPriceDetail' }
      & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
    ), priceResultDetail: (
      { __typename?: 'EnergoElectricityResultPriceDetail' }
      & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
    ) }
  ) }
);

export type EnergoGetGasCalculationResultItemQueryVariables = Exact<{
  uuid: Scalars['String']['input'];
  resultItemId: Scalars['String']['input'];
}>;


export type EnergoGetGasCalculationResultItemQuery = (
  { __typename?: 'Query' }
  & { energoGetGasCalculationResultItem: (
    { __typename?: 'EnergoGasResultItem' }
    & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
    & { partner: (
      { __typename?: 'EnergoGasPartner' }
      & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    ), product: (
      { __typename?: 'EnergoGasProduct' }
      & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
    ), priceExpectedDetail: (
      { __typename?: 'EnergoGasResultPriceDetail' }
      & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
    ), priceResultDetail: (
      { __typename?: 'EnergoGasResultPriceDetail' }
      & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
    ) }
  ) }
);

export type FaqFragment = (
  { __typename?: 'Faq' }
  & Pick<Faq, 'id' | 'question' | 'answerMD'>
);

export type FaqPreviewFragment = (
  { __typename?: 'FaqPreview' }
  & Pick<FaqPreview, 'id' | 'question' | 'answerMD' | 'hasDetail' | 'seoName'>
);

export type FaqCompleteFragment = (
  { __typename?: 'FaqComplete' }
  & Pick<FaqComplete, 'id' | 'question' | 'answerMD'>
  & { detail: Maybe<(
    { __typename?: 'FaqDetail' }
    & Pick<FaqDetail, 'id' | 'seoName' | 'metaTitle' | 'metaDescription' | 'longAnswerSerialized'>
    & { metaImage: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    )> }
  )>, tags: Array<(
    { __typename?: 'FaqTag' }
    & Pick<FaqTag, 'id' | 'name'>
  )>, similar: Array<(
    { __typename?: 'FaqPreview' }
    & Pick<FaqPreview, 'id' | 'question' | 'answerMD' | 'hasDetail' | 'seoName'>
  )> }
);

export type FaqTagFragment = (
  { __typename?: 'FaqTag' }
  & Pick<FaqTag, 'id' | 'name'>
);

export type FaqDetailFragment = (
  { __typename?: 'FaqDetail' }
  & Pick<FaqDetail, 'id' | 'seoName' | 'metaTitle' | 'metaDescription' | 'longAnswerSerialized'>
  & { metaImage: Maybe<(
    { __typename?: 'File' }
    & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
  )> }
);

export type GetGasProductFaqsQueryVariables = Exact<{
  productId: Scalars['Int']['input'];
}>;


export type GetGasProductFaqsQuery = (
  { __typename?: 'Query' }
  & { getGasProductFaqs: Array<(
    { __typename?: 'Faq' }
    & Pick<Faq, 'id' | 'question' | 'answerMD'>
  )> }
);

export type GetElectroProductFaqsQueryVariables = Exact<{
  productId: Scalars['Int']['input'];
}>;


export type GetElectroProductFaqsQuery = (
  { __typename?: 'Query' }
  & { getElectroProductFaqs: Array<(
    { __typename?: 'Faq' }
    & Pick<Faq, 'id' | 'question' | 'answerMD'>
  )> }
);

export type GetGasPartnerFaqsQueryVariables = Exact<{
  partnerId: Scalars['Int']['input'];
}>;


export type GetGasPartnerFaqsQuery = (
  { __typename?: 'Query' }
  & { getGasPartnerFaqs: Array<(
    { __typename?: 'Faq' }
    & Pick<Faq, 'id' | 'question' | 'answerMD'>
  )> }
);

export type GetElectroPartnerFaqsQueryVariables = Exact<{
  partnerId: Scalars['Int']['input'];
}>;


export type GetElectroPartnerFaqsQuery = (
  { __typename?: 'Query' }
  & { getElectroPartnerFaqs: Array<(
    { __typename?: 'Faq' }
    & Pick<Faq, 'id' | 'question' | 'answerMD'>
  )> }
);

export type GetWiningScreenFaqsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetWiningScreenFaqsQuery = (
  { __typename?: 'Query' }
  & { getWiningScreenFaqs: Array<(
    { __typename?: 'Faq' }
    & Pick<Faq, 'id' | 'question' | 'answerMD'>
  )> }
);

export type GetWebFaqsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetWebFaqsQuery = (
  { __typename?: 'Query' }
  & { getWebFaqs: Array<(
    { __typename?: 'Faq' }
    & Pick<Faq, 'id' | 'question' | 'answerMD'>
  )> }
);

export type GetFaqsQueryVariables = Exact<{
  tag: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetFaqsQuery = (
  { __typename?: 'Query' }
  & { getFaqs: Array<(
    { __typename?: 'FaqPreview' }
    & Pick<FaqPreview, 'id' | 'question' | 'answerMD' | 'hasDetail' | 'seoName'>
  )> }
);

export type GetFaqQueryVariables = Exact<{
  faq: Scalars['Int']['input'];
}>;


export type GetFaqQuery = (
  { __typename?: 'Query' }
  & { getFaq: (
    { __typename?: 'FaqComplete' }
    & Pick<FaqComplete, 'id' | 'question' | 'answerMD'>
    & { detail: Maybe<(
      { __typename?: 'FaqDetail' }
      & Pick<FaqDetail, 'id' | 'seoName' | 'metaTitle' | 'metaDescription' | 'longAnswerSerialized'>
      & { metaImage: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )> }
    )>, tags: Array<(
      { __typename?: 'FaqTag' }
      & Pick<FaqTag, 'id' | 'name'>
    )>, similar: Array<(
      { __typename?: 'FaqPreview' }
      & Pick<FaqPreview, 'id' | 'question' | 'answerMD' | 'hasDetail' | 'seoName'>
    )> }
  ) }
);

export type FinanceCalculationFragment = (
  { __typename?: 'FinanceCalculation' }
  & Pick<FinanceCalculation, 'id' | 'uuid' | 'calculationPurpose' | 'repaymentPeriod' | 'email' | 'phone' | 'status' | 'subVertical' | 'mortgagePurpose'>
  & { amount: Maybe<(
    { __typename?: 'Money' }
    & Pick<Money, 'currency' | 'amount'>
  )>, combination: Maybe<(
    { __typename?: 'FinanceCalculationCombination' }
    & Pick<FinanceCalculationCombination, 'id' | 'amount' | 'repaymentPeriod'>
  )>, selectedProduct: Maybe<(
    { __typename?: 'FinanceProduct' }
    & Pick<FinanceProduct, 'id' | 'name' | 'type' | 'amountFrom' | 'amountTo' | 'interestRate' | 'repaymentPeriodFrom' | 'repaymentPeriodTo' | 'listItemsAbout' | 'listItemsConditions' | 'rpsn' | 'exampleMD' | 'redirectToWeb' | 'redirectUrl' | 'updatedAt' | 'displayNetIncome' | 'displayIncomeType' | 'displayOtherLoans' | 'subVertical' | 'mortgagePurpose'>
    & { partner: (
      { __typename?: 'FinancePartner' }
      & Pick<FinancePartner, 'id' | 'name' | 'web' | 'priority' | 'gdprTextMD'>
      & { logo: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )>, logoSquare: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )>, userReviews: Array<(
        { __typename?: 'FinanceUserReview' }
        & Pick<FinanceUserReview, 'id'>
      )> }
    ), combinations: Array<(
      { __typename?: 'FinanceProductCombination' }
      & Pick<FinanceProductCombination, 'id' | 'amount' | 'combinationId' | 'repaymentPeriod' | 'interest' | 'rpsn' | 'monthlyInstallment' | 'totalPayment'>
    )> }
  )>, contactPerson: Maybe<(
    { __typename?: 'FinanceContactPerson' }
    & Pick<FinanceContactPerson, 'id' | 'name' | 'surname' | 'email' | 'phone'>
  )>, refinancingRemainingAmount: Maybe<(
    { __typename?: 'Money' }
    & Pick<Money, 'currency' | 'amount'>
  )>, extraLoan: Maybe<(
    { __typename?: 'Money' }
    & Pick<Money, 'currency' | 'amount'>
  )>, consolidationDebts: Array<(
    { __typename?: 'FinanceConsolidationDebt' }
    & Pick<FinanceConsolidationDebt, 'id' | 'consolidationDebtType'>
    & { remainingAmount: (
      { __typename?: 'Money' }
      & Pick<Money, 'currency' | 'amount'>
    ), monthlyInstallment: (
      { __typename?: 'Money' }
      & Pick<Money, 'currency' | 'amount'>
    ) }
  )>, mortgageAmount: Maybe<(
    { __typename?: 'Money' }
    & Pick<Money, 'currency' | 'amount'>
  )>, realEstatePrice: Maybe<(
    { __typename?: 'Money' }
    & Pick<Money, 'currency' | 'amount'>
  )> }
);

export type FinanceConsolidationDebtFragment = (
  { __typename?: 'FinanceConsolidationDebt' }
  & Pick<FinanceConsolidationDebt, 'id' | 'consolidationDebtType'>
  & { remainingAmount: (
    { __typename?: 'Money' }
    & Pick<Money, 'currency' | 'amount'>
  ), monthlyInstallment: (
    { __typename?: 'Money' }
    & Pick<Money, 'currency' | 'amount'>
  ) }
);

export type FinanceProductCombinationFragment = (
  { __typename?: 'FinanceProductCombination' }
  & Pick<FinanceProductCombination, 'id' | 'amount' | 'combinationId' | 'repaymentPeriod' | 'interest' | 'rpsn' | 'monthlyInstallment' | 'totalPayment'>
);

export type FinanceCalculationCombinationFragment = (
  { __typename?: 'FinanceCalculationCombination' }
  & Pick<FinanceCalculationCombination, 'id' | 'amount' | 'repaymentPeriod'>
);

export type FinanceBankInformationFragment = (
  { __typename?: 'FinanceBankInformation' }
  & Pick<FinanceBankInformation, 'id' | 'monthlyIncome' | 'incomeType' | 'otherLoans' | 'otherLoansInstallment'>
);

export type FinanceContactPersonFragment = (
  { __typename?: 'FinanceContactPerson' }
  & Pick<FinanceContactPerson, 'id' | 'name' | 'surname' | 'email' | 'phone'>
);

export type FinancePartnerFragment = (
  { __typename?: 'FinancePartner' }
  & Pick<FinancePartner, 'id' | 'name' | 'web' | 'priority' | 'gdprTextMD'>
  & { logo: Maybe<(
    { __typename?: 'File' }
    & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
  )>, logoSquare: Maybe<(
    { __typename?: 'File' }
    & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
  )>, userReviews: Array<(
    { __typename?: 'FinanceUserReview' }
    & Pick<FinanceUserReview, 'id'>
  )> }
);

export type FinancePartnerDetailFragment = (
  { __typename?: 'FinancePartner' }
  & Pick<FinancePartner, 'id' | 'name' | 'phone' | 'contactEmail' | 'web' | 'longArticleMD' | 'address' | 'metaTitle' | 'metaDescription' | 'title' | 'titleAfter' | 'priority' | 'gdprTextMD'>
  & { logo: Maybe<(
    { __typename?: 'File' }
    & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
  )>, logoSquare: Maybe<(
    { __typename?: 'File' }
    & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
  )>, userReviews: Array<(
    { __typename?: 'FinanceUserReview' }
    & Pick<FinanceUserReview, 'id'>
  )>, faqs: Array<(
    { __typename?: 'KnowledgebaseQuestionPreview' }
    & Pick<KnowledgebaseQuestionPreview, 'id' | 'title' | 'seoName' | 'perex' | 'type'>
  )> }
);

export type FinanceProductFragment = (
  { __typename?: 'FinanceProduct' }
  & Pick<FinanceProduct, 'id' | 'name' | 'type' | 'amountFrom' | 'amountTo' | 'interestRate' | 'repaymentPeriodFrom' | 'repaymentPeriodTo' | 'listItemsAbout' | 'listItemsConditions' | 'rpsn' | 'exampleMD' | 'redirectToWeb' | 'redirectUrl' | 'updatedAt' | 'displayNetIncome' | 'displayIncomeType' | 'displayOtherLoans' | 'subVertical' | 'mortgagePurpose'>
  & { partner: (
    { __typename?: 'FinancePartner' }
    & Pick<FinancePartner, 'id' | 'name' | 'web' | 'priority' | 'gdprTextMD'>
    & { logo: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    )>, logoSquare: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    )>, userReviews: Array<(
      { __typename?: 'FinanceUserReview' }
      & Pick<FinanceUserReview, 'id'>
    )> }
  ), combinations: Array<(
    { __typename?: 'FinanceProductCombination' }
    & Pick<FinanceProductCombination, 'id' | 'amount' | 'combinationId' | 'repaymentPeriod' | 'interest' | 'rpsn' | 'monthlyInstallment' | 'totalPayment'>
  )> }
);

export type FinanceProductDetailFragment = (
  { __typename?: 'FinanceProduct' }
  & Pick<FinanceProduct, 'id' | 'name' | 'type' | 'amountFrom' | 'amountTo' | 'interestRate' | 'repaymentPeriodFrom' | 'repaymentPeriodTo' | 'listItemsAbout' | 'listItemsConditions' | 'rpsn' | 'exampleMD' | 'metaTitle' | 'metaDescription' | 'title' | 'titleAfter' | 'redirectToWeb' | 'redirectUrl' | 'displayNetIncome' | 'displayIncomeType' | 'displayOtherLoans' | 'subVertical' | 'mortgagePurpose'>
  & { partner: (
    { __typename?: 'FinancePartner' }
    & Pick<FinancePartner, 'id' | 'name' | 'web' | 'priority' | 'gdprTextMD'>
    & { logo: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    )>, logoSquare: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    )>, userReviews: Array<(
      { __typename?: 'FinanceUserReview' }
      & Pick<FinanceUserReview, 'id'>
    )> }
  ), faqs: Array<(
    { __typename?: 'KnowledgebaseQuestionPreview' }
    & Pick<KnowledgebaseQuestionPreview, 'id' | 'title' | 'seoName' | 'perex' | 'type'>
  )>, combinations: Array<(
    { __typename?: 'FinanceProductCombination' }
    & Pick<FinanceProductCombination, 'id' | 'amount' | 'combinationId' | 'repaymentPeriod' | 'interest' | 'rpsn' | 'monthlyInstallment' | 'totalPayment'>
  )> }
);

export type FinanceCalculationResultItemFragment = (
  { __typename?: 'FinanceCalculationResultItem' }
  & Pick<FinanceCalculationResultItem, 'id'>
  & { product: (
    { __typename?: 'FinanceProduct' }
    & Pick<FinanceProduct, 'id' | 'name' | 'type' | 'amountFrom' | 'amountTo' | 'interestRate' | 'repaymentPeriodFrom' | 'repaymentPeriodTo' | 'listItemsAbout' | 'listItemsConditions' | 'rpsn' | 'exampleMD' | 'redirectToWeb' | 'redirectUrl' | 'updatedAt' | 'displayNetIncome' | 'displayIncomeType' | 'displayOtherLoans' | 'subVertical' | 'mortgagePurpose'>
    & { partner: (
      { __typename?: 'FinancePartner' }
      & Pick<FinancePartner, 'id' | 'name' | 'web' | 'priority' | 'gdprTextMD'>
      & { logo: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )>, logoSquare: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )>, userReviews: Array<(
        { __typename?: 'FinanceUserReview' }
        & Pick<FinanceUserReview, 'id'>
      )> }
    ), combinations: Array<(
      { __typename?: 'FinanceProductCombination' }
      & Pick<FinanceProductCombination, 'id' | 'amount' | 'combinationId' | 'repaymentPeriod' | 'interest' | 'rpsn' | 'monthlyInstallment' | 'totalPayment'>
    )> }
  ), productCombination: (
    { __typename?: 'FinanceProductCombination' }
    & Pick<FinanceProductCombination, 'id' | 'amount' | 'combinationId' | 'repaymentPeriod' | 'interest' | 'rpsn' | 'monthlyInstallment' | 'totalPayment'>
  ) }
);

export type FinanceCalculationResultFragment = (
  { __typename?: 'FinanceCalculationResult' }
  & Pick<FinanceCalculationResult, 'id'>
  & { items: Array<(
    { __typename?: 'FinanceCalculationResultItem' }
    & Pick<FinanceCalculationResultItem, 'id'>
    & { product: (
      { __typename?: 'FinanceProduct' }
      & Pick<FinanceProduct, 'id' | 'name' | 'type' | 'amountFrom' | 'amountTo' | 'interestRate' | 'repaymentPeriodFrom' | 'repaymentPeriodTo' | 'listItemsAbout' | 'listItemsConditions' | 'rpsn' | 'exampleMD' | 'redirectToWeb' | 'redirectUrl' | 'updatedAt' | 'displayNetIncome' | 'displayIncomeType' | 'displayOtherLoans' | 'subVertical' | 'mortgagePurpose'>
      & { partner: (
        { __typename?: 'FinancePartner' }
        & Pick<FinancePartner, 'id' | 'name' | 'web' | 'priority' | 'gdprTextMD'>
        & { logo: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )>, logoSquare: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )>, userReviews: Array<(
          { __typename?: 'FinanceUserReview' }
          & Pick<FinanceUserReview, 'id'>
        )> }
      ), combinations: Array<(
        { __typename?: 'FinanceProductCombination' }
        & Pick<FinanceProductCombination, 'id' | 'amount' | 'combinationId' | 'repaymentPeriod' | 'interest' | 'rpsn' | 'monthlyInstallment' | 'totalPayment'>
      )> }
    ), productCombination: (
      { __typename?: 'FinanceProductCombination' }
      & Pick<FinanceProductCombination, 'id' | 'amount' | 'combinationId' | 'repaymentPeriod' | 'interest' | 'rpsn' | 'monthlyInstallment' | 'totalPayment'>
    ) }
  )> }
);

export type FinanceCalculationCreateMutationVariables = Exact<{
  subVertical: SubVertical;
}>;


export type FinanceCalculationCreateMutation = (
  { __typename?: 'Mutation' }
  & { calculation: (
    { __typename?: 'FinanceCalculation' }
    & Pick<FinanceCalculation, 'id' | 'uuid' | 'calculationPurpose' | 'repaymentPeriod' | 'email' | 'phone' | 'status' | 'subVertical' | 'mortgagePurpose'>
    & { amount: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'currency' | 'amount'>
    )>, combination: Maybe<(
      { __typename?: 'FinanceCalculationCombination' }
      & Pick<FinanceCalculationCombination, 'id' | 'amount' | 'repaymentPeriod'>
    )>, selectedProduct: Maybe<(
      { __typename?: 'FinanceProduct' }
      & Pick<FinanceProduct, 'id' | 'name' | 'type' | 'amountFrom' | 'amountTo' | 'interestRate' | 'repaymentPeriodFrom' | 'repaymentPeriodTo' | 'listItemsAbout' | 'listItemsConditions' | 'rpsn' | 'exampleMD' | 'redirectToWeb' | 'redirectUrl' | 'updatedAt' | 'displayNetIncome' | 'displayIncomeType' | 'displayOtherLoans' | 'subVertical' | 'mortgagePurpose'>
      & { partner: (
        { __typename?: 'FinancePartner' }
        & Pick<FinancePartner, 'id' | 'name' | 'web' | 'priority' | 'gdprTextMD'>
        & { logo: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )>, logoSquare: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )>, userReviews: Array<(
          { __typename?: 'FinanceUserReview' }
          & Pick<FinanceUserReview, 'id'>
        )> }
      ), combinations: Array<(
        { __typename?: 'FinanceProductCombination' }
        & Pick<FinanceProductCombination, 'id' | 'amount' | 'combinationId' | 'repaymentPeriod' | 'interest' | 'rpsn' | 'monthlyInstallment' | 'totalPayment'>
      )> }
    )>, contactPerson: Maybe<(
      { __typename?: 'FinanceContactPerson' }
      & Pick<FinanceContactPerson, 'id' | 'name' | 'surname' | 'email' | 'phone'>
    )>, refinancingRemainingAmount: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'currency' | 'amount'>
    )>, extraLoan: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'currency' | 'amount'>
    )>, consolidationDebts: Array<(
      { __typename?: 'FinanceConsolidationDebt' }
      & Pick<FinanceConsolidationDebt, 'id' | 'consolidationDebtType'>
      & { remainingAmount: (
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'amount'>
      ), monthlyInstallment: (
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'amount'>
      ) }
    )>, mortgageAmount: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'currency' | 'amount'>
    )>, realEstatePrice: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'currency' | 'amount'>
    )> }
  ) }
);

export type FinanceCalculationUpdateMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  data: FinanceCalculationUpdateInput;
}>;


export type FinanceCalculationUpdateMutation = (
  { __typename?: 'Mutation' }
  & { calculation: (
    { __typename?: 'FinanceCalculation' }
    & Pick<FinanceCalculation, 'id' | 'uuid' | 'calculationPurpose' | 'repaymentPeriod' | 'email' | 'phone' | 'status' | 'subVertical' | 'mortgagePurpose'>
    & { amount: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'currency' | 'amount'>
    )>, combination: Maybe<(
      { __typename?: 'FinanceCalculationCombination' }
      & Pick<FinanceCalculationCombination, 'id' | 'amount' | 'repaymentPeriod'>
    )>, selectedProduct: Maybe<(
      { __typename?: 'FinanceProduct' }
      & Pick<FinanceProduct, 'id' | 'name' | 'type' | 'amountFrom' | 'amountTo' | 'interestRate' | 'repaymentPeriodFrom' | 'repaymentPeriodTo' | 'listItemsAbout' | 'listItemsConditions' | 'rpsn' | 'exampleMD' | 'redirectToWeb' | 'redirectUrl' | 'updatedAt' | 'displayNetIncome' | 'displayIncomeType' | 'displayOtherLoans' | 'subVertical' | 'mortgagePurpose'>
      & { partner: (
        { __typename?: 'FinancePartner' }
        & Pick<FinancePartner, 'id' | 'name' | 'web' | 'priority' | 'gdprTextMD'>
        & { logo: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )>, logoSquare: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )>, userReviews: Array<(
          { __typename?: 'FinanceUserReview' }
          & Pick<FinanceUserReview, 'id'>
        )> }
      ), combinations: Array<(
        { __typename?: 'FinanceProductCombination' }
        & Pick<FinanceProductCombination, 'id' | 'amount' | 'combinationId' | 'repaymentPeriod' | 'interest' | 'rpsn' | 'monthlyInstallment' | 'totalPayment'>
      )> }
    )>, contactPerson: Maybe<(
      { __typename?: 'FinanceContactPerson' }
      & Pick<FinanceContactPerson, 'id' | 'name' | 'surname' | 'email' | 'phone'>
    )>, refinancingRemainingAmount: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'currency' | 'amount'>
    )>, extraLoan: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'currency' | 'amount'>
    )>, consolidationDebts: Array<(
      { __typename?: 'FinanceConsolidationDebt' }
      & Pick<FinanceConsolidationDebt, 'id' | 'consolidationDebtType'>
      & { remainingAmount: (
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'amount'>
      ), monthlyInstallment: (
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'amount'>
      ) }
    )>, mortgageAmount: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'currency' | 'amount'>
    )>, realEstatePrice: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'currency' | 'amount'>
    )> }
  ) }
);

export type FinanceCalculationProductClickMutationVariables = Exact<{
  calculationUuid: InputMaybe<Scalars['UUID']['input']>;
  product: Scalars['Int']['input'];
  referer: InputMaybe<Scalars['String']['input']>;
}>;


export type FinanceCalculationProductClickMutation = (
  { __typename?: 'Mutation' }
  & { financeCalculationProductClick: Maybe<(
    { __typename?: 'CoreStatus' }
    & Pick<CoreStatus, 'success'>
  )> }
);

export type FinanceCalculationGetQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;


export type FinanceCalculationGetQuery = (
  { __typename?: 'Query' }
  & { financeCalculationGet: (
    { __typename?: 'FinanceCalculation' }
    & Pick<FinanceCalculation, 'id' | 'uuid' | 'calculationPurpose' | 'repaymentPeriod' | 'email' | 'phone' | 'status' | 'subVertical' | 'mortgagePurpose'>
    & { amount: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'currency' | 'amount'>
    )>, combination: Maybe<(
      { __typename?: 'FinanceCalculationCombination' }
      & Pick<FinanceCalculationCombination, 'id' | 'amount' | 'repaymentPeriod'>
    )>, selectedProduct: Maybe<(
      { __typename?: 'FinanceProduct' }
      & Pick<FinanceProduct, 'id' | 'name' | 'type' | 'amountFrom' | 'amountTo' | 'interestRate' | 'repaymentPeriodFrom' | 'repaymentPeriodTo' | 'listItemsAbout' | 'listItemsConditions' | 'rpsn' | 'exampleMD' | 'redirectToWeb' | 'redirectUrl' | 'updatedAt' | 'displayNetIncome' | 'displayIncomeType' | 'displayOtherLoans' | 'subVertical' | 'mortgagePurpose'>
      & { partner: (
        { __typename?: 'FinancePartner' }
        & Pick<FinancePartner, 'id' | 'name' | 'web' | 'priority' | 'gdprTextMD'>
        & { logo: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )>, logoSquare: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )>, userReviews: Array<(
          { __typename?: 'FinanceUserReview' }
          & Pick<FinanceUserReview, 'id'>
        )> }
      ), combinations: Array<(
        { __typename?: 'FinanceProductCombination' }
        & Pick<FinanceProductCombination, 'id' | 'amount' | 'combinationId' | 'repaymentPeriod' | 'interest' | 'rpsn' | 'monthlyInstallment' | 'totalPayment'>
      )> }
    )>, contactPerson: Maybe<(
      { __typename?: 'FinanceContactPerson' }
      & Pick<FinanceContactPerson, 'id' | 'name' | 'surname' | 'email' | 'phone'>
    )>, refinancingRemainingAmount: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'currency' | 'amount'>
    )>, extraLoan: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'currency' | 'amount'>
    )>, consolidationDebts: Array<(
      { __typename?: 'FinanceConsolidationDebt' }
      & Pick<FinanceConsolidationDebt, 'id' | 'consolidationDebtType'>
      & { remainingAmount: (
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'amount'>
      ), monthlyInstallment: (
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'amount'>
      ) }
    )>, mortgageAmount: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'currency' | 'amount'>
    )>, realEstatePrice: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'currency' | 'amount'>
    )> }
  ) }
);

export type FinanceCalculationResultsQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;


export type FinanceCalculationResultsQuery = (
  { __typename?: 'Query' }
  & { financeCalculationResults: (
    { __typename?: 'FinanceCalculationResult' }
    & Pick<FinanceCalculationResult, 'id'>
    & { items: Array<(
      { __typename?: 'FinanceCalculationResultItem' }
      & Pick<FinanceCalculationResultItem, 'id'>
      & { product: (
        { __typename?: 'FinanceProduct' }
        & Pick<FinanceProduct, 'id' | 'name' | 'type' | 'amountFrom' | 'amountTo' | 'interestRate' | 'repaymentPeriodFrom' | 'repaymentPeriodTo' | 'listItemsAbout' | 'listItemsConditions' | 'rpsn' | 'exampleMD' | 'redirectToWeb' | 'redirectUrl' | 'updatedAt' | 'displayNetIncome' | 'displayIncomeType' | 'displayOtherLoans' | 'subVertical' | 'mortgagePurpose'>
        & { partner: (
          { __typename?: 'FinancePartner' }
          & Pick<FinancePartner, 'id' | 'name' | 'web' | 'priority' | 'gdprTextMD'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, userReviews: Array<(
            { __typename?: 'FinanceUserReview' }
            & Pick<FinanceUserReview, 'id'>
          )> }
        ), combinations: Array<(
          { __typename?: 'FinanceProductCombination' }
          & Pick<FinanceProductCombination, 'id' | 'amount' | 'combinationId' | 'repaymentPeriod' | 'interest' | 'rpsn' | 'monthlyInstallment' | 'totalPayment'>
        )> }
      ), productCombination: (
        { __typename?: 'FinanceProductCombination' }
        & Pick<FinanceProductCombination, 'id' | 'amount' | 'combinationId' | 'repaymentPeriod' | 'interest' | 'rpsn' | 'monthlyInstallment' | 'totalPayment'>
      ) }
    )> }
  ) }
);

export type FinanceProductGetQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type FinanceProductGetQuery = (
  { __typename?: 'Query' }
  & { product: (
    { __typename?: 'FinanceProduct' }
    & Pick<FinanceProduct, 'id' | 'name' | 'type' | 'amountFrom' | 'amountTo' | 'interestRate' | 'repaymentPeriodFrom' | 'repaymentPeriodTo' | 'listItemsAbout' | 'listItemsConditions' | 'rpsn' | 'exampleMD' | 'metaTitle' | 'metaDescription' | 'title' | 'titleAfter' | 'redirectToWeb' | 'redirectUrl' | 'displayNetIncome' | 'displayIncomeType' | 'displayOtherLoans' | 'subVertical' | 'mortgagePurpose'>
    & { partner: (
      { __typename?: 'FinancePartner' }
      & Pick<FinancePartner, 'id' | 'name' | 'web' | 'priority' | 'gdprTextMD'>
      & { logo: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )>, logoSquare: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )>, userReviews: Array<(
        { __typename?: 'FinanceUserReview' }
        & Pick<FinanceUserReview, 'id'>
      )> }
    ), faqs: Array<(
      { __typename?: 'KnowledgebaseQuestionPreview' }
      & Pick<KnowledgebaseQuestionPreview, 'id' | 'title' | 'seoName' | 'perex' | 'type'>
    )>, combinations: Array<(
      { __typename?: 'FinanceProductCombination' }
      & Pick<FinanceProductCombination, 'id' | 'amount' | 'combinationId' | 'repaymentPeriod' | 'interest' | 'rpsn' | 'monthlyInstallment' | 'totalPayment'>
    )> }
  ) }
);

export type FinanceProductSearchQueryVariables = Exact<{
  productTypes: Array<FinanceProductType> | FinanceProductType;
  productPurpose: Scalars['Int']['input'];
}>;


export type FinanceProductSearchQuery = (
  { __typename?: 'Query' }
  & { products: Array<(
    { __typename?: 'FinanceProduct' }
    & Pick<FinanceProduct, 'id' | 'name' | 'type' | 'amountFrom' | 'amountTo' | 'interestRate' | 'repaymentPeriodFrom' | 'repaymentPeriodTo' | 'listItemsAbout' | 'listItemsConditions' | 'rpsn' | 'exampleMD' | 'redirectToWeb' | 'redirectUrl' | 'updatedAt' | 'displayNetIncome' | 'displayIncomeType' | 'displayOtherLoans' | 'subVertical' | 'mortgagePurpose'>
    & { partner: (
      { __typename?: 'FinancePartner' }
      & Pick<FinancePartner, 'id' | 'name' | 'web' | 'priority' | 'gdprTextMD'>
      & { logo: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )>, logoSquare: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )>, userReviews: Array<(
        { __typename?: 'FinanceUserReview' }
        & Pick<FinanceUserReview, 'id'>
      )> }
    ), combinations: Array<(
      { __typename?: 'FinanceProductCombination' }
      & Pick<FinanceProductCombination, 'id' | 'amount' | 'combinationId' | 'repaymentPeriod' | 'interest' | 'rpsn' | 'monthlyInstallment' | 'totalPayment'>
    )> }
  )> }
);

export type FinanceLeadFormQueryQueryVariables = Exact<{
  calculationUuid: Scalars['UUID']['input'];
  productId: Scalars['Int']['input'];
}>;


export type FinanceLeadFormQueryQuery = (
  { __typename?: 'Query' }
  & { calculation: (
    { __typename?: 'FinanceCalculation' }
    & Pick<FinanceCalculation, 'id' | 'uuid' | 'calculationPurpose' | 'repaymentPeriod' | 'email' | 'phone' | 'status' | 'subVertical' | 'mortgagePurpose'>
    & { amount: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'currency' | 'amount'>
    )>, combination: Maybe<(
      { __typename?: 'FinanceCalculationCombination' }
      & Pick<FinanceCalculationCombination, 'id' | 'amount' | 'repaymentPeriod'>
    )>, selectedProduct: Maybe<(
      { __typename?: 'FinanceProduct' }
      & Pick<FinanceProduct, 'id' | 'name' | 'type' | 'amountFrom' | 'amountTo' | 'interestRate' | 'repaymentPeriodFrom' | 'repaymentPeriodTo' | 'listItemsAbout' | 'listItemsConditions' | 'rpsn' | 'exampleMD' | 'redirectToWeb' | 'redirectUrl' | 'updatedAt' | 'displayNetIncome' | 'displayIncomeType' | 'displayOtherLoans' | 'subVertical' | 'mortgagePurpose'>
      & { partner: (
        { __typename?: 'FinancePartner' }
        & Pick<FinancePartner, 'id' | 'name' | 'web' | 'priority' | 'gdprTextMD'>
        & { logo: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )>, logoSquare: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )>, userReviews: Array<(
          { __typename?: 'FinanceUserReview' }
          & Pick<FinanceUserReview, 'id'>
        )> }
      ), combinations: Array<(
        { __typename?: 'FinanceProductCombination' }
        & Pick<FinanceProductCombination, 'id' | 'amount' | 'combinationId' | 'repaymentPeriod' | 'interest' | 'rpsn' | 'monthlyInstallment' | 'totalPayment'>
      )> }
    )>, contactPerson: Maybe<(
      { __typename?: 'FinanceContactPerson' }
      & Pick<FinanceContactPerson, 'id' | 'name' | 'surname' | 'email' | 'phone'>
    )>, refinancingRemainingAmount: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'currency' | 'amount'>
    )>, extraLoan: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'currency' | 'amount'>
    )>, consolidationDebts: Array<(
      { __typename?: 'FinanceConsolidationDebt' }
      & Pick<FinanceConsolidationDebt, 'id' | 'consolidationDebtType'>
      & { remainingAmount: (
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'amount'>
      ), monthlyInstallment: (
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'amount'>
      ) }
    )>, mortgageAmount: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'currency' | 'amount'>
    )>, realEstatePrice: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'currency' | 'amount'>
    )> }
  ), product: (
    { __typename?: 'FinanceProduct' }
    & Pick<FinanceProduct, 'id' | 'name' | 'type' | 'amountFrom' | 'amountTo' | 'interestRate' | 'repaymentPeriodFrom' | 'repaymentPeriodTo' | 'listItemsAbout' | 'listItemsConditions' | 'rpsn' | 'exampleMD' | 'redirectToWeb' | 'redirectUrl' | 'updatedAt' | 'displayNetIncome' | 'displayIncomeType' | 'displayOtherLoans' | 'subVertical' | 'mortgagePurpose'>
    & { partner: (
      { __typename?: 'FinancePartner' }
      & Pick<FinancePartner, 'id' | 'name' | 'web' | 'priority' | 'gdprTextMD'>
      & { logo: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )>, logoSquare: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )>, userReviews: Array<(
        { __typename?: 'FinanceUserReview' }
        & Pick<FinanceUserReview, 'id'>
      )> }
    ), combinations: Array<(
      { __typename?: 'FinanceProductCombination' }
      & Pick<FinanceProductCombination, 'id' | 'amount' | 'combinationId' | 'repaymentPeriod' | 'interest' | 'rpsn' | 'monthlyInstallment' | 'totalPayment'>
    )> }
  ) }
);

export type FinancePartnerPageQueryVariables = Exact<{
  partnerId: Scalars['Int']['input'];
}>;


export type FinancePartnerPageQuery = (
  { __typename?: 'Query' }
  & { partner: (
    { __typename?: 'FinancePartner' }
    & Pick<FinancePartner, 'id' | 'name' | 'phone' | 'contactEmail' | 'web' | 'longArticleMD' | 'address' | 'metaTitle' | 'metaDescription' | 'title' | 'titleAfter' | 'priority' | 'gdprTextMD'>
    & { logo: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    )>, logoSquare: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    )>, userReviews: Array<(
      { __typename?: 'FinanceUserReview' }
      & Pick<FinanceUserReview, 'id'>
    )>, faqs: Array<(
      { __typename?: 'KnowledgebaseQuestionPreview' }
      & Pick<KnowledgebaseQuestionPreview, 'id' | 'title' | 'seoName' | 'perex' | 'type'>
    )> }
  ), products: Array<(
    { __typename?: 'FinanceProduct' }
    & Pick<FinanceProduct, 'id' | 'name' | 'type' | 'amountFrom' | 'amountTo' | 'interestRate' | 'repaymentPeriodFrom' | 'repaymentPeriodTo' | 'listItemsAbout' | 'listItemsConditions' | 'rpsn' | 'exampleMD' | 'redirectToWeb' | 'redirectUrl' | 'updatedAt' | 'displayNetIncome' | 'displayIncomeType' | 'displayOtherLoans' | 'subVertical' | 'mortgagePurpose'>
    & { partner: (
      { __typename?: 'FinancePartner' }
      & Pick<FinancePartner, 'id' | 'name' | 'web' | 'priority' | 'gdprTextMD'>
      & { logo: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )>, logoSquare: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )>, userReviews: Array<(
        { __typename?: 'FinanceUserReview' }
        & Pick<FinanceUserReview, 'id'>
      )> }
    ), combinations: Array<(
      { __typename?: 'FinanceProductCombination' }
      & Pick<FinanceProductCombination, 'id' | 'amount' | 'combinationId' | 'repaymentPeriod' | 'interest' | 'rpsn' | 'monthlyInstallment' | 'totalPayment'>
    )> }
  )> }
);

export type FinanceEntryFormSelectOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type FinanceEntryFormSelectOptionsQuery = (
  { __typename?: 'Query' }
  & { amountOptions: Array<(
    { __typename?: 'FinanceAmountSelectOption' }
    & Pick<FinanceAmountSelectOption, 'id' | 'amount'>
    & { repaymentPeriodOptions: Array<(
      { __typename?: 'FinanceRepaymentPeriodSelectOption' }
      & Pick<FinanceRepaymentPeriodSelectOption, 'id' | 'repaymentPeriod' | 'numberOfProducts'>
    )> }
  )> }
);

export type FinanceAvailableRepaymentPeriodsQueryVariables = Exact<{
  data: AvailableRepaymentPeriodsInput;
}>;


export type FinanceAvailableRepaymentPeriodsQuery = (
  { __typename?: 'Query' }
  & { products: Array<(
    { __typename?: 'FinanceCalculationCombination' }
    & Pick<FinanceCalculationCombination, 'id' | 'amount' | 'repaymentPeriod'>
  )> }
);

export type KnowledgebaseTagFragment = (
  { __typename?: 'KnowledgebaseTag' }
  & Pick<KnowledgebaseTag, 'id' | 'name'>
);

export type KnowledgebaseQuestionPreviewFragment = (
  { __typename?: 'KnowledgebaseQuestionPreview' }
  & Pick<KnowledgebaseQuestionPreview, 'id' | 'title' | 'seoName' | 'perex' | 'type'>
);

export type KnowledgebaseQuestionPreviewWithContentFragment = (
  { __typename?: 'KnowledgebaseQuestionPreviewWithContent' }
  & Pick<KnowledgebaseQuestionPreviewWithContent, 'id' | 'title' | 'seoName' | 'perex' | 'type'>
  & { sections: Array<(
    { __typename?: 'ContentSectionBannerWithButton' }
    & Pick<ContentSectionBannerWithButton, 'id' | 'order' | 'sectionType' | 'contentSerialized' | 'title' | 'buttonText' | 'buttonUrl'>
    & { bannerType: ContentSectionBannerWithButton['type'] }
    & { campaign: Maybe<(
      { __typename?: 'Campaign' }
      & Pick<Campaign, 'id' | 'uuid' | 'active' | 'name'>
    )> }
  ) | { __typename?: 'ContentSectionBannerWithButtons' } | (
    { __typename?: 'ContentSectionBannerWithCallbackButton' }
    & Pick<ContentSectionBannerWithCallbackButton, 'id' | 'order' | 'sectionType' | 'contentSerialized' | 'title' | 'buttonText' | 'callbackSource' | 'popupTitle' | 'popupSubTitle'>
    & { campaign: Maybe<(
      { __typename?: 'Campaign' }
      & Pick<Campaign, 'id' | 'uuid' | 'active' | 'name'>
    )> }
  ) | (
    { __typename?: 'ContentSectionBlogArticles' }
    & Pick<ContentSectionBlogArticles, 'id' | 'order' | 'sectionType'>
    & { articles: Array<(
      { __typename?: 'ArticlePreview' }
      & Pick<ArticlePreview, 'id' | 'title' | 'perex' | 'seoName' | 'imageAlt' | 'imageSource' | 'author' | 'publishedAt' | 'updatedAt' | 'redirectTo'>
      & { image: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )> }
    )> }
  ) | (
    { __typename?: 'ContentSectionContacts' }
    & Pick<ContentSectionContacts, 'id' | 'order' | 'sectionType'>
  ) | { __typename?: 'ContentSectionElectricityPriceList' } | { __typename?: 'ContentSectionFinanceProducts' } | { __typename?: 'ContentSectionGasPriceList' } | { __typename?: 'ContentSectionInternetSpeed' } | (
    { __typename?: 'ContentSectionJumbotron' }
    & Pick<ContentSectionJumbotron, 'id' | 'order' | 'sectionType' | 'contentSerialized' | 'subtitle' | 'title' | 'type' | 'metaDescriptionAsSubtitle' | 'callbackSource'>
    & { btnText: ContentSectionJumbotron['buttonText'], btnUrl: ContentSectionJumbotron['buttonUrl'] }
    & { campaign: Maybe<(
      { __typename?: 'Campaign' }
      & Pick<Campaign, 'id' | 'uuid' | 'active' | 'name'>
    )>, buttons: Array<(
      { __typename?: 'ContentSectionBannerWithButtonsButton' }
      & Pick<ContentSectionBannerWithButtonsButton, 'label' | 'url'>
    )> }
  ) | (
    { __typename?: 'ContentSectionKnowledgeBaseConcepts' }
    & Pick<ContentSectionKnowledgeBaseConcepts, 'id' | 'order' | 'sectionType'>
    & { knowledbaseTitle: ContentSectionKnowledgeBaseConcepts['title'] }
    & { questions: Array<(
      { __typename?: 'KnowledgebaseQuestionPreview' }
      & Pick<KnowledgebaseQuestionPreview, 'id' | 'title' | 'seoName' | 'perex' | 'type'>
    )> }
  ) | { __typename?: 'ContentSectionKnowledgeBaseQuestions' } | { __typename?: 'ContentSectionPrimaryHeadline' } | (
    { __typename?: 'ContentSectionTable' }
    & Pick<ContentSectionTable, 'id' | 'order' | 'sectionType' | 'content' | 'rows' | 'columns'>
  ) | (
    { __typename?: 'ContentSectionText' }
    & Pick<ContentSectionText, 'id' | 'order' | 'sectionType' | 'contentSerialized'>
  ) | (
    { __typename?: 'ContentSectionUsp' }
    & Pick<ContentSectionUsp, 'id' | 'order' | 'sectionType'>
  ) | (
    { __typename?: 'ContentSectionVehicleInsuranceContractDismissalDateForm' }
    & Pick<ContentSectionVehicleInsuranceContractDismissalDateForm, 'id' | 'order' | 'title' | 'sectionType' | 'contentSerialized'>
  )> }
);

export type KnowledgebaseQuestionDetailFragment = (
  { __typename?: 'KnowledgebaseQuestionDetail' }
  & Pick<KnowledgebaseQuestionDetail, 'id' | 'title' | 'titleSuffix' | 'seoName' | 'metaTitle' | 'metaDescription' | 'perex' | 'publicVisible' | 'publishedAt' | 'type' | 'vertical' | 'subVertical' | 'redirectTo'>
  & { metaImage: Maybe<(
    { __typename?: 'File' }
    & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
  )>, category: Maybe<(
    { __typename?: 'KnowledgebaseCategoryPreview' }
    & Pick<KnowledgebaseCategoryPreview, 'id' | 'name' | 'description' | 'vertical' | 'subVertical'>
    & { parents: Array<(
      { __typename?: 'KnowledgebaseCategoryPreview' }
      & Pick<KnowledgebaseCategoryPreview, 'id' | 'name'>
    )> }
  )>, similar: Maybe<Array<(
    { __typename?: 'KnowledgebaseQuestionPreview' }
    & Pick<KnowledgebaseQuestionPreview, 'id' | 'title' | 'seoName' | 'perex' | 'type'>
  )>>, sections: Array<(
    { __typename?: 'ContentSectionBannerWithButton' }
    & Pick<ContentSectionBannerWithButton, 'id' | 'order' | 'sectionType' | 'contentSerialized' | 'title' | 'buttonText' | 'buttonUrl'>
    & { bannerType: ContentSectionBannerWithButton['type'] }
    & { campaign: Maybe<(
      { __typename?: 'Campaign' }
      & Pick<Campaign, 'id' | 'uuid' | 'active' | 'name'>
    )> }
  ) | { __typename?: 'ContentSectionBannerWithButtons' } | (
    { __typename?: 'ContentSectionBannerWithCallbackButton' }
    & Pick<ContentSectionBannerWithCallbackButton, 'id' | 'order' | 'sectionType' | 'contentSerialized' | 'title' | 'buttonText' | 'callbackSource' | 'popupTitle' | 'popupSubTitle'>
    & { campaign: Maybe<(
      { __typename?: 'Campaign' }
      & Pick<Campaign, 'id' | 'uuid' | 'active' | 'name'>
    )> }
  ) | (
    { __typename?: 'ContentSectionBlogArticles' }
    & Pick<ContentSectionBlogArticles, 'id' | 'order' | 'sectionType'>
    & { articles: Array<(
      { __typename?: 'ArticlePreview' }
      & Pick<ArticlePreview, 'id' | 'title' | 'perex' | 'seoName' | 'imageAlt' | 'imageSource' | 'author' | 'publishedAt' | 'updatedAt' | 'redirectTo'>
      & { image: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )> }
    )> }
  ) | (
    { __typename?: 'ContentSectionContacts' }
    & Pick<ContentSectionContacts, 'id' | 'order' | 'sectionType'>
  ) | { __typename?: 'ContentSectionElectricityPriceList' } | { __typename?: 'ContentSectionFinanceProducts' } | { __typename?: 'ContentSectionGasPriceList' } | { __typename?: 'ContentSectionInternetSpeed' } | (
    { __typename?: 'ContentSectionJumbotron' }
    & Pick<ContentSectionJumbotron, 'id' | 'order' | 'sectionType' | 'contentSerialized' | 'subtitle' | 'title' | 'type' | 'metaDescriptionAsSubtitle' | 'callbackSource'>
    & { btnText: ContentSectionJumbotron['buttonText'], btnUrl: ContentSectionJumbotron['buttonUrl'] }
    & { campaign: Maybe<(
      { __typename?: 'Campaign' }
      & Pick<Campaign, 'id' | 'uuid' | 'active' | 'name'>
    )>, buttons: Array<(
      { __typename?: 'ContentSectionBannerWithButtonsButton' }
      & Pick<ContentSectionBannerWithButtonsButton, 'label' | 'url'>
    )> }
  ) | (
    { __typename?: 'ContentSectionKnowledgeBaseConcepts' }
    & Pick<ContentSectionKnowledgeBaseConcepts, 'id' | 'order' | 'sectionType'>
    & { knowledbaseTitle: ContentSectionKnowledgeBaseConcepts['title'] }
    & { questions: Array<(
      { __typename?: 'KnowledgebaseQuestionPreview' }
      & Pick<KnowledgebaseQuestionPreview, 'id' | 'title' | 'seoName' | 'perex' | 'type'>
    )> }
  ) | { __typename?: 'ContentSectionKnowledgeBaseQuestions' } | { __typename?: 'ContentSectionPrimaryHeadline' } | (
    { __typename?: 'ContentSectionTable' }
    & Pick<ContentSectionTable, 'id' | 'order' | 'sectionType' | 'content' | 'rows' | 'columns'>
  ) | (
    { __typename?: 'ContentSectionText' }
    & Pick<ContentSectionText, 'id' | 'order' | 'sectionType' | 'contentSerialized'>
  ) | (
    { __typename?: 'ContentSectionUsp' }
    & Pick<ContentSectionUsp, 'id' | 'order' | 'sectionType'>
  ) | (
    { __typename?: 'ContentSectionVehicleInsuranceContractDismissalDateForm' }
    & Pick<ContentSectionVehicleInsuranceContractDismissalDateForm, 'id' | 'order' | 'title' | 'sectionType' | 'contentSerialized'>
  )> }
);

export type KnowledgebaseCategoryPreviewFragment = (
  { __typename?: 'KnowledgebaseCategoryPreview' }
  & Pick<KnowledgebaseCategoryPreview, 'id' | 'name' | 'description' | 'vertical' | 'subVertical'>
  & { parents: Array<(
    { __typename?: 'KnowledgebaseCategoryPreview' }
    & Pick<KnowledgebaseCategoryPreview, 'id' | 'name'>
  )> }
);

export type KnowledgebaseCategoryFragment = (
  { __typename?: 'KnowledgebaseCategory' }
  & Pick<KnowledgebaseCategory, 'id' | 'name' | 'titleSuffix' | 'description' | 'metaTitle' | 'metaDescription' | 'vertical' | 'subVertical'>
  & { metaImage: Maybe<(
    { __typename?: 'File' }
    & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
  )>, parents: Array<(
    { __typename?: 'KnowledgebaseCategoryPreview' }
    & Pick<KnowledgebaseCategoryPreview, 'id' | 'name'>
  )>, subCategories: Maybe<Array<(
    { __typename?: 'KnowledgebaseCategoryPreview' }
    & Pick<KnowledgebaseCategoryPreview, 'id' | 'name' | 'description' | 'vertical' | 'subVertical'>
    & { parents: Array<(
      { __typename?: 'KnowledgebaseCategoryPreview' }
      & Pick<KnowledgebaseCategoryPreview, 'id' | 'name'>
    )> }
  )>>, questions: Maybe<Array<(
    { __typename?: 'KnowledgebaseQuestionPreview' }
    & Pick<KnowledgebaseQuestionPreview, 'id' | 'title' | 'seoName' | 'perex' | 'type'>
  )>> }
);

type ContentSection_ContentSectionBannerWithButton_Fragment = (
  { __typename?: 'ContentSectionBannerWithButton' }
  & Pick<ContentSectionBannerWithButton, 'id' | 'order' | 'sectionType' | 'contentSerialized' | 'title' | 'buttonText' | 'buttonUrl'>
  & { bannerType: ContentSectionBannerWithButton['type'] }
  & { campaign: Maybe<(
    { __typename?: 'Campaign' }
    & Pick<Campaign, 'id' | 'uuid' | 'active' | 'name'>
  )> }
);

type ContentSection_ContentSectionBannerWithButtons_Fragment = (
  { __typename?: 'ContentSectionBannerWithButtons' }
  & Pick<ContentSectionBannerWithButtons, 'id' | 'order' | 'sectionType'>
  & { buttons: Array<(
    { __typename?: 'ContentSectionBannerWithButtonsButton' }
    & Pick<ContentSectionBannerWithButtonsButton, 'label' | 'url'>
  )> }
);

type ContentSection_ContentSectionBannerWithCallbackButton_Fragment = (
  { __typename?: 'ContentSectionBannerWithCallbackButton' }
  & Pick<ContentSectionBannerWithCallbackButton, 'id' | 'order' | 'sectionType' | 'contentSerialized' | 'title' | 'buttonText' | 'callbackSource' | 'popupTitle' | 'popupSubTitle'>
  & { campaign: Maybe<(
    { __typename?: 'Campaign' }
    & Pick<Campaign, 'id' | 'uuid' | 'active' | 'name'>
  )> }
);

type ContentSection_ContentSectionBlogArticles_Fragment = (
  { __typename?: 'ContentSectionBlogArticles' }
  & Pick<ContentSectionBlogArticles, 'id' | 'order' | 'sectionType'>
  & { articles: Array<(
    { __typename?: 'ArticlePreview' }
    & Pick<ArticlePreview, 'id' | 'title' | 'perex' | 'seoName' | 'imageAlt' | 'imageSource' | 'author' | 'publishedAt' | 'updatedAt' | 'redirectTo'>
    & { image: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    )> }
  )> }
);

type ContentSection_ContentSectionContacts_Fragment = (
  { __typename?: 'ContentSectionContacts' }
  & Pick<ContentSectionContacts, 'id' | 'order' | 'sectionType'>
);

type ContentSection_ContentSectionElectricityPriceList_Fragment = (
  { __typename?: 'ContentSectionElectricityPriceList' }
  & Pick<ContentSectionElectricityPriceList, 'id' | 'order' | 'sectionType'>
  & { prices: Array<(
    { __typename?: 'EnergoElectricityPriceProductPreviewItem' }
    & Pick<EnergoElectricityPriceProductPreviewItem, 'id' | 'priceMonthly' | 'priceMWhHighTariff' | 'priceMWhLowTariff' | 'distributionRateId' | 'distributorId'>
    & { product: (
      { __typename?: 'EnergoElectricityProductPreview' }
      & Pick<EnergoElectricityProductPreview, 'id' | 'name' | 'isActive' | 'isActiveForPurchase' | 'activeForNewCustomers' | 'activeForCurrentCustomers' | 'activeForSelectCurrentExact' | 'activeForSelectCurrentEstimate' | 'private' | 'preferredSale' | 'availableForSale'>
      & { partner: (
        { __typename?: 'EnergoElectricityPartnerPreview' }
        & Pick<EnergoElectricityPartnerPreview, 'id' | 'name' | 'energoPartnerId' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'cooperationMethod' | 'companyAddress' | 'contactEmail' | 'tollFreePhone' | 'longitude' | 'latitude' | 'web' | 'urlVop' | 'urlPricelist' | 'longArticleMD' | 'active' | 'estimateDefaultProductId'>
      ) }
    ) }
  )> }
);

type ContentSection_ContentSectionFinanceProducts_Fragment = (
  { __typename?: 'ContentSectionFinanceProducts' }
  & Pick<ContentSectionFinanceProducts, 'id' | 'order' | 'sectionType' | 'titleWhite' | 'titleYellow' | 'text' | 'productTypes' | 'productPurpose'>
);

type ContentSection_ContentSectionGasPriceList_Fragment = (
  { __typename?: 'ContentSectionGasPriceList' }
  & Pick<ContentSectionGasPriceList, 'id' | 'order' | 'sectionType'>
  & { prices: Array<(
    { __typename?: 'EnergoGasPriceProductPreviewItem' }
    & Pick<EnergoGasPriceProductPreviewItem, 'id' | 'pricePerM3' | 'priceMonthly' | 'priceMWh' | 'odberovePasmoId'>
    & { product: (
      { __typename?: 'EnergoGasProductPreview' }
      & Pick<EnergoGasProductPreview, 'id' | 'name' | 'isActive' | 'isActiveForPurchase' | 'activeForNewCustomers' | 'activeForCurrentCustomers' | 'activeForSelectCurrentExact' | 'activeForSelectCurrentEstimate' | 'private' | 'preferredSale' | 'availableForSale'>
      & { partner: (
        { __typename?: 'EnergoGasPartnerPreview' }
        & Pick<EnergoGasPartnerPreview, 'id' | 'name' | 'energoPartnerId' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'cooperationMethod' | 'companyAddress' | 'contactEmail' | 'tollFreePhone' | 'longitude' | 'latitude' | 'web' | 'urlVop' | 'urlPricelist' | 'longArticleMD' | 'active' | 'estimateDefaultProductId'>
      ) }
    ) }
  )> }
);

type ContentSection_ContentSectionInternetSpeed_Fragment = (
  { __typename?: 'ContentSectionInternetSpeed' }
  & Pick<ContentSectionInternetSpeed, 'id' | 'order' | 'title' | 'sectionType' | 'contentSerialized'>
);

type ContentSection_ContentSectionJumbotron_Fragment = (
  { __typename?: 'ContentSectionJumbotron' }
  & Pick<ContentSectionJumbotron, 'id' | 'order' | 'sectionType' | 'contentSerialized' | 'subtitle' | 'title' | 'type' | 'metaDescriptionAsSubtitle' | 'callbackSource'>
  & { btnText: ContentSectionJumbotron['buttonText'], btnUrl: ContentSectionJumbotron['buttonUrl'] }
  & { campaign: Maybe<(
    { __typename?: 'Campaign' }
    & Pick<Campaign, 'id' | 'uuid' | 'active' | 'name'>
  )>, buttons: Array<(
    { __typename?: 'ContentSectionBannerWithButtonsButton' }
    & Pick<ContentSectionBannerWithButtonsButton, 'label' | 'url'>
  )> }
);

type ContentSection_ContentSectionKnowledgeBaseConcepts_Fragment = (
  { __typename?: 'ContentSectionKnowledgeBaseConcepts' }
  & Pick<ContentSectionKnowledgeBaseConcepts, 'id' | 'order' | 'sectionType'>
  & { knowledbaseTitle: ContentSectionKnowledgeBaseConcepts['title'] }
  & { questions: Array<(
    { __typename?: 'KnowledgebaseQuestionPreview' }
    & Pick<KnowledgebaseQuestionPreview, 'id' | 'title' | 'seoName' | 'perex' | 'type'>
  )> }
);

type ContentSection_ContentSectionKnowledgeBaseQuestions_Fragment = (
  { __typename?: 'ContentSectionKnowledgeBaseQuestions' }
  & Pick<ContentSectionKnowledgeBaseQuestions, 'id' | 'order' | 'sectionType'>
  & { knowledbaseTitle: ContentSectionKnowledgeBaseQuestions['title'] }
  & { questions: Array<(
    { __typename?: 'KnowledgebaseQuestionPreview' }
    & Pick<KnowledgebaseQuestionPreview, 'id' | 'title' | 'seoName' | 'perex' | 'type'>
  )>, priorityQuestion1: Maybe<(
    { __typename?: 'KnowledgebaseQuestionPreviewWithContent' }
    & Pick<KnowledgebaseQuestionPreviewWithContent, 'id' | 'title' | 'seoName' | 'perex' | 'type'>
    & { sections: Array<(
      { __typename?: 'ContentSectionBannerWithButton' }
      & Pick<ContentSectionBannerWithButton, 'id' | 'order' | 'sectionType' | 'contentSerialized' | 'title' | 'buttonText' | 'buttonUrl'>
      & { bannerType: ContentSectionBannerWithButton['type'] }
      & { campaign: Maybe<(
        { __typename?: 'Campaign' }
        & Pick<Campaign, 'id' | 'uuid' | 'active' | 'name'>
      )> }
    ) | { __typename?: 'ContentSectionBannerWithButtons' } | (
      { __typename?: 'ContentSectionBannerWithCallbackButton' }
      & Pick<ContentSectionBannerWithCallbackButton, 'id' | 'order' | 'sectionType' | 'contentSerialized' | 'title' | 'buttonText' | 'callbackSource' | 'popupTitle' | 'popupSubTitle'>
      & { campaign: Maybe<(
        { __typename?: 'Campaign' }
        & Pick<Campaign, 'id' | 'uuid' | 'active' | 'name'>
      )> }
    ) | (
      { __typename?: 'ContentSectionBlogArticles' }
      & Pick<ContentSectionBlogArticles, 'id' | 'order' | 'sectionType'>
      & { articles: Array<(
        { __typename?: 'ArticlePreview' }
        & Pick<ArticlePreview, 'id' | 'title' | 'perex' | 'seoName' | 'imageAlt' | 'imageSource' | 'author' | 'publishedAt' | 'updatedAt' | 'redirectTo'>
        & { image: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )> }
    ) | (
      { __typename?: 'ContentSectionContacts' }
      & Pick<ContentSectionContacts, 'id' | 'order' | 'sectionType'>
    ) | { __typename?: 'ContentSectionElectricityPriceList' } | { __typename?: 'ContentSectionFinanceProducts' } | { __typename?: 'ContentSectionGasPriceList' } | { __typename?: 'ContentSectionInternetSpeed' } | (
      { __typename?: 'ContentSectionJumbotron' }
      & Pick<ContentSectionJumbotron, 'id' | 'order' | 'sectionType' | 'contentSerialized' | 'subtitle' | 'title' | 'type' | 'metaDescriptionAsSubtitle' | 'callbackSource'>
      & { btnText: ContentSectionJumbotron['buttonText'], btnUrl: ContentSectionJumbotron['buttonUrl'] }
      & { campaign: Maybe<(
        { __typename?: 'Campaign' }
        & Pick<Campaign, 'id' | 'uuid' | 'active' | 'name'>
      )>, buttons: Array<(
        { __typename?: 'ContentSectionBannerWithButtonsButton' }
        & Pick<ContentSectionBannerWithButtonsButton, 'label' | 'url'>
      )> }
    ) | (
      { __typename?: 'ContentSectionKnowledgeBaseConcepts' }
      & Pick<ContentSectionKnowledgeBaseConcepts, 'id' | 'order' | 'sectionType'>
      & { knowledbaseTitle: ContentSectionKnowledgeBaseConcepts['title'] }
      & { questions: Array<(
        { __typename?: 'KnowledgebaseQuestionPreview' }
        & Pick<KnowledgebaseQuestionPreview, 'id' | 'title' | 'seoName' | 'perex' | 'type'>
      )> }
    ) | { __typename?: 'ContentSectionKnowledgeBaseQuestions' } | { __typename?: 'ContentSectionPrimaryHeadline' } | (
      { __typename?: 'ContentSectionTable' }
      & Pick<ContentSectionTable, 'id' | 'order' | 'sectionType' | 'content' | 'rows' | 'columns'>
    ) | (
      { __typename?: 'ContentSectionText' }
      & Pick<ContentSectionText, 'id' | 'order' | 'sectionType' | 'contentSerialized'>
    ) | (
      { __typename?: 'ContentSectionUsp' }
      & Pick<ContentSectionUsp, 'id' | 'order' | 'sectionType'>
    ) | (
      { __typename?: 'ContentSectionVehicleInsuranceContractDismissalDateForm' }
      & Pick<ContentSectionVehicleInsuranceContractDismissalDateForm, 'id' | 'order' | 'title' | 'sectionType' | 'contentSerialized'>
    )> }
  )>, priorityQuestion2: Maybe<(
    { __typename?: 'KnowledgebaseQuestionPreviewWithContent' }
    & Pick<KnowledgebaseQuestionPreviewWithContent, 'id' | 'title' | 'seoName' | 'perex' | 'type'>
    & { sections: Array<(
      { __typename?: 'ContentSectionBannerWithButton' }
      & Pick<ContentSectionBannerWithButton, 'id' | 'order' | 'sectionType' | 'contentSerialized' | 'title' | 'buttonText' | 'buttonUrl'>
      & { bannerType: ContentSectionBannerWithButton['type'] }
      & { campaign: Maybe<(
        { __typename?: 'Campaign' }
        & Pick<Campaign, 'id' | 'uuid' | 'active' | 'name'>
      )> }
    ) | { __typename?: 'ContentSectionBannerWithButtons' } | (
      { __typename?: 'ContentSectionBannerWithCallbackButton' }
      & Pick<ContentSectionBannerWithCallbackButton, 'id' | 'order' | 'sectionType' | 'contentSerialized' | 'title' | 'buttonText' | 'callbackSource' | 'popupTitle' | 'popupSubTitle'>
      & { campaign: Maybe<(
        { __typename?: 'Campaign' }
        & Pick<Campaign, 'id' | 'uuid' | 'active' | 'name'>
      )> }
    ) | (
      { __typename?: 'ContentSectionBlogArticles' }
      & Pick<ContentSectionBlogArticles, 'id' | 'order' | 'sectionType'>
      & { articles: Array<(
        { __typename?: 'ArticlePreview' }
        & Pick<ArticlePreview, 'id' | 'title' | 'perex' | 'seoName' | 'imageAlt' | 'imageSource' | 'author' | 'publishedAt' | 'updatedAt' | 'redirectTo'>
        & { image: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )> }
    ) | (
      { __typename?: 'ContentSectionContacts' }
      & Pick<ContentSectionContacts, 'id' | 'order' | 'sectionType'>
    ) | { __typename?: 'ContentSectionElectricityPriceList' } | { __typename?: 'ContentSectionFinanceProducts' } | { __typename?: 'ContentSectionGasPriceList' } | { __typename?: 'ContentSectionInternetSpeed' } | (
      { __typename?: 'ContentSectionJumbotron' }
      & Pick<ContentSectionJumbotron, 'id' | 'order' | 'sectionType' | 'contentSerialized' | 'subtitle' | 'title' | 'type' | 'metaDescriptionAsSubtitle' | 'callbackSource'>
      & { btnText: ContentSectionJumbotron['buttonText'], btnUrl: ContentSectionJumbotron['buttonUrl'] }
      & { campaign: Maybe<(
        { __typename?: 'Campaign' }
        & Pick<Campaign, 'id' | 'uuid' | 'active' | 'name'>
      )>, buttons: Array<(
        { __typename?: 'ContentSectionBannerWithButtonsButton' }
        & Pick<ContentSectionBannerWithButtonsButton, 'label' | 'url'>
      )> }
    ) | (
      { __typename?: 'ContentSectionKnowledgeBaseConcepts' }
      & Pick<ContentSectionKnowledgeBaseConcepts, 'id' | 'order' | 'sectionType'>
      & { knowledbaseTitle: ContentSectionKnowledgeBaseConcepts['title'] }
      & { questions: Array<(
        { __typename?: 'KnowledgebaseQuestionPreview' }
        & Pick<KnowledgebaseQuestionPreview, 'id' | 'title' | 'seoName' | 'perex' | 'type'>
      )> }
    ) | { __typename?: 'ContentSectionKnowledgeBaseQuestions' } | { __typename?: 'ContentSectionPrimaryHeadline' } | (
      { __typename?: 'ContentSectionTable' }
      & Pick<ContentSectionTable, 'id' | 'order' | 'sectionType' | 'content' | 'rows' | 'columns'>
    ) | (
      { __typename?: 'ContentSectionText' }
      & Pick<ContentSectionText, 'id' | 'order' | 'sectionType' | 'contentSerialized'>
    ) | (
      { __typename?: 'ContentSectionUsp' }
      & Pick<ContentSectionUsp, 'id' | 'order' | 'sectionType'>
    ) | (
      { __typename?: 'ContentSectionVehicleInsuranceContractDismissalDateForm' }
      & Pick<ContentSectionVehicleInsuranceContractDismissalDateForm, 'id' | 'order' | 'title' | 'sectionType' | 'contentSerialized'>
    )> }
  )> }
);

type ContentSection_ContentSectionPrimaryHeadline_Fragment = (
  { __typename?: 'ContentSectionPrimaryHeadline' }
  & Pick<ContentSectionPrimaryHeadline, 'id' | 'order' | 'sectionType' | 'primaryHeadline'>
);

type ContentSection_ContentSectionTable_Fragment = (
  { __typename?: 'ContentSectionTable' }
  & Pick<ContentSectionTable, 'id' | 'order' | 'sectionType' | 'content' | 'rows' | 'columns'>
);

type ContentSection_ContentSectionText_Fragment = (
  { __typename?: 'ContentSectionText' }
  & Pick<ContentSectionText, 'id' | 'order' | 'sectionType' | 'contentSerialized'>
);

type ContentSection_ContentSectionUsp_Fragment = (
  { __typename?: 'ContentSectionUsp' }
  & Pick<ContentSectionUsp, 'id' | 'order' | 'sectionType'>
);

type ContentSection_ContentSectionVehicleInsuranceContractDismissalDateForm_Fragment = (
  { __typename?: 'ContentSectionVehicleInsuranceContractDismissalDateForm' }
  & Pick<ContentSectionVehicleInsuranceContractDismissalDateForm, 'id' | 'order' | 'title' | 'sectionType' | 'contentSerialized'>
);

export type ContentSectionFragment = ContentSection_ContentSectionBannerWithButton_Fragment | ContentSection_ContentSectionBannerWithButtons_Fragment | ContentSection_ContentSectionBannerWithCallbackButton_Fragment | ContentSection_ContentSectionBlogArticles_Fragment | ContentSection_ContentSectionContacts_Fragment | ContentSection_ContentSectionElectricityPriceList_Fragment | ContentSection_ContentSectionFinanceProducts_Fragment | ContentSection_ContentSectionGasPriceList_Fragment | ContentSection_ContentSectionInternetSpeed_Fragment | ContentSection_ContentSectionJumbotron_Fragment | ContentSection_ContentSectionKnowledgeBaseConcepts_Fragment | ContentSection_ContentSectionKnowledgeBaseQuestions_Fragment | ContentSection_ContentSectionPrimaryHeadline_Fragment | ContentSection_ContentSectionTable_Fragment | ContentSection_ContentSectionText_Fragment | ContentSection_ContentSectionUsp_Fragment | ContentSection_ContentSectionVehicleInsuranceContractDismissalDateForm_Fragment;

export type ContentSectionJumbotronFragment = (
  { __typename?: 'ContentSectionJumbotron' }
  & Pick<ContentSectionJumbotron, 'id' | 'order' | 'sectionType' | 'contentSerialized' | 'subtitle' | 'title' | 'type' | 'metaDescriptionAsSubtitle' | 'callbackSource'>
  & { btnText: ContentSectionJumbotron['buttonText'], btnUrl: ContentSectionJumbotron['buttonUrl'] }
  & { campaign: Maybe<(
    { __typename?: 'Campaign' }
    & Pick<Campaign, 'id' | 'uuid' | 'active' | 'name'>
  )>, buttons: Array<(
    { __typename?: 'ContentSectionBannerWithButtonsButton' }
    & Pick<ContentSectionBannerWithButtonsButton, 'label' | 'url'>
  )> }
);

export type ContentSectionBannerWithButtonFragment = (
  { __typename?: 'ContentSectionBannerWithButton' }
  & Pick<ContentSectionBannerWithButton, 'id' | 'order' | 'sectionType' | 'contentSerialized' | 'title' | 'buttonText' | 'buttonUrl'>
  & { bannerType: ContentSectionBannerWithButton['type'] }
  & { campaign: Maybe<(
    { __typename?: 'Campaign' }
    & Pick<Campaign, 'id' | 'uuid' | 'active' | 'name'>
  )> }
);

export type ContentSectionBannerWithButtonsButtonFragment = (
  { __typename?: 'ContentSectionBannerWithButtonsButton' }
  & Pick<ContentSectionBannerWithButtonsButton, 'label' | 'url'>
);

export type ContentSectionBannerWithButtonsFragment = (
  { __typename?: 'ContentSectionBannerWithButtons' }
  & Pick<ContentSectionBannerWithButtons, 'id' | 'order' | 'sectionType'>
  & { buttons: Array<(
    { __typename?: 'ContentSectionBannerWithButtonsButton' }
    & Pick<ContentSectionBannerWithButtonsButton, 'label' | 'url'>
  )> }
);

export type ContentSectionGasPriceListFragment = (
  { __typename?: 'ContentSectionGasPriceList' }
  & Pick<ContentSectionGasPriceList, 'id' | 'order' | 'sectionType'>
  & { prices: Array<(
    { __typename?: 'EnergoGasPriceProductPreviewItem' }
    & Pick<EnergoGasPriceProductPreviewItem, 'id' | 'pricePerM3' | 'priceMonthly' | 'priceMWh' | 'odberovePasmoId'>
    & { product: (
      { __typename?: 'EnergoGasProductPreview' }
      & Pick<EnergoGasProductPreview, 'id' | 'name' | 'isActive' | 'isActiveForPurchase' | 'activeForNewCustomers' | 'activeForCurrentCustomers' | 'activeForSelectCurrentExact' | 'activeForSelectCurrentEstimate' | 'private' | 'preferredSale' | 'availableForSale'>
      & { partner: (
        { __typename?: 'EnergoGasPartnerPreview' }
        & Pick<EnergoGasPartnerPreview, 'id' | 'name' | 'energoPartnerId' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'cooperationMethod' | 'companyAddress' | 'contactEmail' | 'tollFreePhone' | 'longitude' | 'latitude' | 'web' | 'urlVop' | 'urlPricelist' | 'longArticleMD' | 'active' | 'estimateDefaultProductId'>
      ) }
    ) }
  )> }
);

export type ContentSectionElectricityPriceListFragment = (
  { __typename?: 'ContentSectionElectricityPriceList' }
  & Pick<ContentSectionElectricityPriceList, 'id' | 'order' | 'sectionType'>
  & { prices: Array<(
    { __typename?: 'EnergoElectricityPriceProductPreviewItem' }
    & Pick<EnergoElectricityPriceProductPreviewItem, 'id' | 'priceMonthly' | 'priceMWhHighTariff' | 'priceMWhLowTariff' | 'distributionRateId' | 'distributorId'>
    & { product: (
      { __typename?: 'EnergoElectricityProductPreview' }
      & Pick<EnergoElectricityProductPreview, 'id' | 'name' | 'isActive' | 'isActiveForPurchase' | 'activeForNewCustomers' | 'activeForCurrentCustomers' | 'activeForSelectCurrentExact' | 'activeForSelectCurrentEstimate' | 'private' | 'preferredSale' | 'availableForSale'>
      & { partner: (
        { __typename?: 'EnergoElectricityPartnerPreview' }
        & Pick<EnergoElectricityPartnerPreview, 'id' | 'name' | 'energoPartnerId' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'cooperationMethod' | 'companyAddress' | 'contactEmail' | 'tollFreePhone' | 'longitude' | 'latitude' | 'web' | 'urlVop' | 'urlPricelist' | 'longArticleMD' | 'active' | 'estimateDefaultProductId'>
      ) }
    ) }
  )> }
);

export type ContentSectionBannerWithCallbackButtonFragment = (
  { __typename?: 'ContentSectionBannerWithCallbackButton' }
  & Pick<ContentSectionBannerWithCallbackButton, 'id' | 'order' | 'sectionType' | 'contentSerialized' | 'title' | 'buttonText' | 'callbackSource' | 'popupTitle' | 'popupSubTitle'>
  & { campaign: Maybe<(
    { __typename?: 'Campaign' }
    & Pick<Campaign, 'id' | 'uuid' | 'active' | 'name'>
  )> }
);

export type ContentSectionBlogArticlesFragment = (
  { __typename?: 'ContentSectionBlogArticles' }
  & Pick<ContentSectionBlogArticles, 'id' | 'order' | 'sectionType'>
  & { articles: Array<(
    { __typename?: 'ArticlePreview' }
    & Pick<ArticlePreview, 'id' | 'title' | 'perex' | 'seoName' | 'imageAlt' | 'imageSource' | 'author' | 'publishedAt' | 'updatedAt' | 'redirectTo'>
    & { image: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    )> }
  )> }
);

export type ContentSectionContactsFragment = (
  { __typename?: 'ContentSectionContacts' }
  & Pick<ContentSectionContacts, 'id' | 'order' | 'sectionType'>
);

export type ContentSectionUspFragment = (
  { __typename?: 'ContentSectionUsp' }
  & Pick<ContentSectionUsp, 'id' | 'order' | 'sectionType'>
);

export type ContentSectionTextFragment = (
  { __typename?: 'ContentSectionText' }
  & Pick<ContentSectionText, 'id' | 'order' | 'sectionType' | 'contentSerialized'>
);

export type ContentSectionInternetSpeedFragment = (
  { __typename?: 'ContentSectionInternetSpeed' }
  & Pick<ContentSectionInternetSpeed, 'id' | 'order' | 'title' | 'sectionType' | 'contentSerialized'>
);

export type ContentSectionPrimaryHeadlineFragment = (
  { __typename?: 'ContentSectionPrimaryHeadline' }
  & Pick<ContentSectionPrimaryHeadline, 'id' | 'order' | 'sectionType' | 'primaryHeadline'>
);

export type ContentSectionTableFragment = (
  { __typename?: 'ContentSectionTable' }
  & Pick<ContentSectionTable, 'id' | 'order' | 'sectionType' | 'content' | 'rows' | 'columns'>
);

export type ContentSectionKnowledgeBaseConceptsFragment = (
  { __typename?: 'ContentSectionKnowledgeBaseConcepts' }
  & Pick<ContentSectionKnowledgeBaseConcepts, 'id' | 'order' | 'sectionType'>
  & { knowledbaseTitle: ContentSectionKnowledgeBaseConcepts['title'] }
  & { questions: Array<(
    { __typename?: 'KnowledgebaseQuestionPreview' }
    & Pick<KnowledgebaseQuestionPreview, 'id' | 'title' | 'seoName' | 'perex' | 'type'>
  )> }
);

export type ContentSectionFinanceProductsFragment = (
  { __typename?: 'ContentSectionFinanceProducts' }
  & Pick<ContentSectionFinanceProducts, 'id' | 'order' | 'sectionType' | 'titleWhite' | 'titleYellow' | 'text' | 'productTypes' | 'productPurpose'>
);

export type ContentSectionKnowledgeBaseQuestionsFragment = (
  { __typename?: 'ContentSectionKnowledgeBaseQuestions' }
  & Pick<ContentSectionKnowledgeBaseQuestions, 'id' | 'order' | 'sectionType'>
  & { knowledbaseTitle: ContentSectionKnowledgeBaseQuestions['title'] }
  & { questions: Array<(
    { __typename?: 'KnowledgebaseQuestionPreview' }
    & Pick<KnowledgebaseQuestionPreview, 'id' | 'title' | 'seoName' | 'perex' | 'type'>
  )>, priorityQuestion1: Maybe<(
    { __typename?: 'KnowledgebaseQuestionPreviewWithContent' }
    & Pick<KnowledgebaseQuestionPreviewWithContent, 'id' | 'title' | 'seoName' | 'perex' | 'type'>
    & { sections: Array<(
      { __typename?: 'ContentSectionBannerWithButton' }
      & Pick<ContentSectionBannerWithButton, 'id' | 'order' | 'sectionType' | 'contentSerialized' | 'title' | 'buttonText' | 'buttonUrl'>
      & { bannerType: ContentSectionBannerWithButton['type'] }
      & { campaign: Maybe<(
        { __typename?: 'Campaign' }
        & Pick<Campaign, 'id' | 'uuid' | 'active' | 'name'>
      )> }
    ) | { __typename?: 'ContentSectionBannerWithButtons' } | (
      { __typename?: 'ContentSectionBannerWithCallbackButton' }
      & Pick<ContentSectionBannerWithCallbackButton, 'id' | 'order' | 'sectionType' | 'contentSerialized' | 'title' | 'buttonText' | 'callbackSource' | 'popupTitle' | 'popupSubTitle'>
      & { campaign: Maybe<(
        { __typename?: 'Campaign' }
        & Pick<Campaign, 'id' | 'uuid' | 'active' | 'name'>
      )> }
    ) | (
      { __typename?: 'ContentSectionBlogArticles' }
      & Pick<ContentSectionBlogArticles, 'id' | 'order' | 'sectionType'>
      & { articles: Array<(
        { __typename?: 'ArticlePreview' }
        & Pick<ArticlePreview, 'id' | 'title' | 'perex' | 'seoName' | 'imageAlt' | 'imageSource' | 'author' | 'publishedAt' | 'updatedAt' | 'redirectTo'>
        & { image: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )> }
    ) | (
      { __typename?: 'ContentSectionContacts' }
      & Pick<ContentSectionContacts, 'id' | 'order' | 'sectionType'>
    ) | { __typename?: 'ContentSectionElectricityPriceList' } | { __typename?: 'ContentSectionFinanceProducts' } | { __typename?: 'ContentSectionGasPriceList' } | { __typename?: 'ContentSectionInternetSpeed' } | (
      { __typename?: 'ContentSectionJumbotron' }
      & Pick<ContentSectionJumbotron, 'id' | 'order' | 'sectionType' | 'contentSerialized' | 'subtitle' | 'title' | 'type' | 'metaDescriptionAsSubtitle' | 'callbackSource'>
      & { btnText: ContentSectionJumbotron['buttonText'], btnUrl: ContentSectionJumbotron['buttonUrl'] }
      & { campaign: Maybe<(
        { __typename?: 'Campaign' }
        & Pick<Campaign, 'id' | 'uuid' | 'active' | 'name'>
      )>, buttons: Array<(
        { __typename?: 'ContentSectionBannerWithButtonsButton' }
        & Pick<ContentSectionBannerWithButtonsButton, 'label' | 'url'>
      )> }
    ) | (
      { __typename?: 'ContentSectionKnowledgeBaseConcepts' }
      & Pick<ContentSectionKnowledgeBaseConcepts, 'id' | 'order' | 'sectionType'>
      & { knowledbaseTitle: ContentSectionKnowledgeBaseConcepts['title'] }
      & { questions: Array<(
        { __typename?: 'KnowledgebaseQuestionPreview' }
        & Pick<KnowledgebaseQuestionPreview, 'id' | 'title' | 'seoName' | 'perex' | 'type'>
      )> }
    ) | { __typename?: 'ContentSectionKnowledgeBaseQuestions' } | { __typename?: 'ContentSectionPrimaryHeadline' } | (
      { __typename?: 'ContentSectionTable' }
      & Pick<ContentSectionTable, 'id' | 'order' | 'sectionType' | 'content' | 'rows' | 'columns'>
    ) | (
      { __typename?: 'ContentSectionText' }
      & Pick<ContentSectionText, 'id' | 'order' | 'sectionType' | 'contentSerialized'>
    ) | (
      { __typename?: 'ContentSectionUsp' }
      & Pick<ContentSectionUsp, 'id' | 'order' | 'sectionType'>
    ) | (
      { __typename?: 'ContentSectionVehicleInsuranceContractDismissalDateForm' }
      & Pick<ContentSectionVehicleInsuranceContractDismissalDateForm, 'id' | 'order' | 'title' | 'sectionType' | 'contentSerialized'>
    )> }
  )>, priorityQuestion2: Maybe<(
    { __typename?: 'KnowledgebaseQuestionPreviewWithContent' }
    & Pick<KnowledgebaseQuestionPreviewWithContent, 'id' | 'title' | 'seoName' | 'perex' | 'type'>
    & { sections: Array<(
      { __typename?: 'ContentSectionBannerWithButton' }
      & Pick<ContentSectionBannerWithButton, 'id' | 'order' | 'sectionType' | 'contentSerialized' | 'title' | 'buttonText' | 'buttonUrl'>
      & { bannerType: ContentSectionBannerWithButton['type'] }
      & { campaign: Maybe<(
        { __typename?: 'Campaign' }
        & Pick<Campaign, 'id' | 'uuid' | 'active' | 'name'>
      )> }
    ) | { __typename?: 'ContentSectionBannerWithButtons' } | (
      { __typename?: 'ContentSectionBannerWithCallbackButton' }
      & Pick<ContentSectionBannerWithCallbackButton, 'id' | 'order' | 'sectionType' | 'contentSerialized' | 'title' | 'buttonText' | 'callbackSource' | 'popupTitle' | 'popupSubTitle'>
      & { campaign: Maybe<(
        { __typename?: 'Campaign' }
        & Pick<Campaign, 'id' | 'uuid' | 'active' | 'name'>
      )> }
    ) | (
      { __typename?: 'ContentSectionBlogArticles' }
      & Pick<ContentSectionBlogArticles, 'id' | 'order' | 'sectionType'>
      & { articles: Array<(
        { __typename?: 'ArticlePreview' }
        & Pick<ArticlePreview, 'id' | 'title' | 'perex' | 'seoName' | 'imageAlt' | 'imageSource' | 'author' | 'publishedAt' | 'updatedAt' | 'redirectTo'>
        & { image: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )> }
    ) | (
      { __typename?: 'ContentSectionContacts' }
      & Pick<ContentSectionContacts, 'id' | 'order' | 'sectionType'>
    ) | { __typename?: 'ContentSectionElectricityPriceList' } | { __typename?: 'ContentSectionFinanceProducts' } | { __typename?: 'ContentSectionGasPriceList' } | { __typename?: 'ContentSectionInternetSpeed' } | (
      { __typename?: 'ContentSectionJumbotron' }
      & Pick<ContentSectionJumbotron, 'id' | 'order' | 'sectionType' | 'contentSerialized' | 'subtitle' | 'title' | 'type' | 'metaDescriptionAsSubtitle' | 'callbackSource'>
      & { btnText: ContentSectionJumbotron['buttonText'], btnUrl: ContentSectionJumbotron['buttonUrl'] }
      & { campaign: Maybe<(
        { __typename?: 'Campaign' }
        & Pick<Campaign, 'id' | 'uuid' | 'active' | 'name'>
      )>, buttons: Array<(
        { __typename?: 'ContentSectionBannerWithButtonsButton' }
        & Pick<ContentSectionBannerWithButtonsButton, 'label' | 'url'>
      )> }
    ) | (
      { __typename?: 'ContentSectionKnowledgeBaseConcepts' }
      & Pick<ContentSectionKnowledgeBaseConcepts, 'id' | 'order' | 'sectionType'>
      & { knowledbaseTitle: ContentSectionKnowledgeBaseConcepts['title'] }
      & { questions: Array<(
        { __typename?: 'KnowledgebaseQuestionPreview' }
        & Pick<KnowledgebaseQuestionPreview, 'id' | 'title' | 'seoName' | 'perex' | 'type'>
      )> }
    ) | { __typename?: 'ContentSectionKnowledgeBaseQuestions' } | { __typename?: 'ContentSectionPrimaryHeadline' } | (
      { __typename?: 'ContentSectionTable' }
      & Pick<ContentSectionTable, 'id' | 'order' | 'sectionType' | 'content' | 'rows' | 'columns'>
    ) | (
      { __typename?: 'ContentSectionText' }
      & Pick<ContentSectionText, 'id' | 'order' | 'sectionType' | 'contentSerialized'>
    ) | (
      { __typename?: 'ContentSectionUsp' }
      & Pick<ContentSectionUsp, 'id' | 'order' | 'sectionType'>
    ) | (
      { __typename?: 'ContentSectionVehicleInsuranceContractDismissalDateForm' }
      & Pick<ContentSectionVehicleInsuranceContractDismissalDateForm, 'id' | 'order' | 'title' | 'sectionType' | 'contentSerialized'>
    )> }
  )> }
);

export type ContentSectionVehicleInsuranceContractDismissalDateFormFragment = (
  { __typename?: 'ContentSectionVehicleInsuranceContractDismissalDateForm' }
  & Pick<ContentSectionVehicleInsuranceContractDismissalDateForm, 'id' | 'order' | 'title' | 'sectionType' | 'contentSerialized'>
);

export type GetKnowledgebaseRootCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetKnowledgebaseRootCategoriesQuery = (
  { __typename?: 'Query' }
  & { getKnowledgebaseRootCategories: Maybe<Array<(
    { __typename?: 'KnowledgebaseCategoryPreview' }
    & Pick<KnowledgebaseCategoryPreview, 'id' | 'name' | 'description' | 'vertical' | 'subVertical'>
    & { parents: Array<(
      { __typename?: 'KnowledgebaseCategoryPreview' }
      & Pick<KnowledgebaseCategoryPreview, 'id' | 'name'>
    )> }
  )>> }
);

export type GetKnowledgebaseCategoryQueryVariables = Exact<{
  category: Scalars['Int']['input'];
}>;


export type GetKnowledgebaseCategoryQuery = (
  { __typename?: 'Query' }
  & { getKnowledgebaseCategory: (
    { __typename?: 'KnowledgebaseCategory' }
    & Pick<KnowledgebaseCategory, 'id' | 'name' | 'titleSuffix' | 'description' | 'metaTitle' | 'metaDescription' | 'vertical' | 'subVertical'>
    & { metaImage: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    )>, parents: Array<(
      { __typename?: 'KnowledgebaseCategoryPreview' }
      & Pick<KnowledgebaseCategoryPreview, 'id' | 'name'>
    )>, subCategories: Maybe<Array<(
      { __typename?: 'KnowledgebaseCategoryPreview' }
      & Pick<KnowledgebaseCategoryPreview, 'id' | 'name' | 'description' | 'vertical' | 'subVertical'>
      & { parents: Array<(
        { __typename?: 'KnowledgebaseCategoryPreview' }
        & Pick<KnowledgebaseCategoryPreview, 'id' | 'name'>
      )> }
    )>>, questions: Maybe<Array<(
      { __typename?: 'KnowledgebaseQuestionPreview' }
      & Pick<KnowledgebaseQuestionPreview, 'id' | 'title' | 'seoName' | 'perex' | 'type'>
    )>> }
  ) }
);

export type GetKnowledgebaseQuestionQueryVariables = Exact<{
  question: Scalars['Int']['input'];
}>;


export type GetKnowledgebaseQuestionQuery = (
  { __typename?: 'Query' }
  & { getKnowledgebaseQuestion: (
    { __typename?: 'KnowledgebaseQuestionDetail' }
    & Pick<KnowledgebaseQuestionDetail, 'id' | 'title' | 'titleSuffix' | 'seoName' | 'metaTitle' | 'metaDescription' | 'perex' | 'publicVisible' | 'publishedAt' | 'type' | 'vertical' | 'subVertical' | 'redirectTo'>
    & { metaImage: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    )>, category: Maybe<(
      { __typename?: 'KnowledgebaseCategoryPreview' }
      & Pick<KnowledgebaseCategoryPreview, 'id' | 'name' | 'description' | 'vertical' | 'subVertical'>
      & { parents: Array<(
        { __typename?: 'KnowledgebaseCategoryPreview' }
        & Pick<KnowledgebaseCategoryPreview, 'id' | 'name'>
      )> }
    )>, similar: Maybe<Array<(
      { __typename?: 'KnowledgebaseQuestionPreview' }
      & Pick<KnowledgebaseQuestionPreview, 'id' | 'title' | 'seoName' | 'perex' | 'type'>
    )>>, sections: Array<(
      { __typename?: 'ContentSectionBannerWithButton' }
      & Pick<ContentSectionBannerWithButton, 'id' | 'order' | 'sectionType' | 'contentSerialized' | 'title' | 'buttonText' | 'buttonUrl'>
      & { bannerType: ContentSectionBannerWithButton['type'] }
      & { campaign: Maybe<(
        { __typename?: 'Campaign' }
        & Pick<Campaign, 'id' | 'uuid' | 'active' | 'name'>
      )> }
    ) | { __typename?: 'ContentSectionBannerWithButtons' } | (
      { __typename?: 'ContentSectionBannerWithCallbackButton' }
      & Pick<ContentSectionBannerWithCallbackButton, 'id' | 'order' | 'sectionType' | 'contentSerialized' | 'title' | 'buttonText' | 'callbackSource' | 'popupTitle' | 'popupSubTitle'>
      & { campaign: Maybe<(
        { __typename?: 'Campaign' }
        & Pick<Campaign, 'id' | 'uuid' | 'active' | 'name'>
      )> }
    ) | (
      { __typename?: 'ContentSectionBlogArticles' }
      & Pick<ContentSectionBlogArticles, 'id' | 'order' | 'sectionType'>
      & { articles: Array<(
        { __typename?: 'ArticlePreview' }
        & Pick<ArticlePreview, 'id' | 'title' | 'perex' | 'seoName' | 'imageAlt' | 'imageSource' | 'author' | 'publishedAt' | 'updatedAt' | 'redirectTo'>
        & { image: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )> }
    ) | (
      { __typename?: 'ContentSectionContacts' }
      & Pick<ContentSectionContacts, 'id' | 'order' | 'sectionType'>
    ) | { __typename?: 'ContentSectionElectricityPriceList' } | { __typename?: 'ContentSectionFinanceProducts' } | { __typename?: 'ContentSectionGasPriceList' } | { __typename?: 'ContentSectionInternetSpeed' } | (
      { __typename?: 'ContentSectionJumbotron' }
      & Pick<ContentSectionJumbotron, 'id' | 'order' | 'sectionType' | 'contentSerialized' | 'subtitle' | 'title' | 'type' | 'metaDescriptionAsSubtitle' | 'callbackSource'>
      & { btnText: ContentSectionJumbotron['buttonText'], btnUrl: ContentSectionJumbotron['buttonUrl'] }
      & { campaign: Maybe<(
        { __typename?: 'Campaign' }
        & Pick<Campaign, 'id' | 'uuid' | 'active' | 'name'>
      )>, buttons: Array<(
        { __typename?: 'ContentSectionBannerWithButtonsButton' }
        & Pick<ContentSectionBannerWithButtonsButton, 'label' | 'url'>
      )> }
    ) | (
      { __typename?: 'ContentSectionKnowledgeBaseConcepts' }
      & Pick<ContentSectionKnowledgeBaseConcepts, 'id' | 'order' | 'sectionType'>
      & { knowledbaseTitle: ContentSectionKnowledgeBaseConcepts['title'] }
      & { questions: Array<(
        { __typename?: 'KnowledgebaseQuestionPreview' }
        & Pick<KnowledgebaseQuestionPreview, 'id' | 'title' | 'seoName' | 'perex' | 'type'>
      )> }
    ) | { __typename?: 'ContentSectionKnowledgeBaseQuestions' } | { __typename?: 'ContentSectionPrimaryHeadline' } | (
      { __typename?: 'ContentSectionTable' }
      & Pick<ContentSectionTable, 'id' | 'order' | 'sectionType' | 'content' | 'rows' | 'columns'>
    ) | (
      { __typename?: 'ContentSectionText' }
      & Pick<ContentSectionText, 'id' | 'order' | 'sectionType' | 'contentSerialized'>
    ) | (
      { __typename?: 'ContentSectionUsp' }
      & Pick<ContentSectionUsp, 'id' | 'order' | 'sectionType'>
    ) | (
      { __typename?: 'ContentSectionVehicleInsuranceContractDismissalDateForm' }
      & Pick<ContentSectionVehicleInsuranceContractDismissalDateForm, 'id' | 'order' | 'title' | 'sectionType' | 'contentSerialized'>
    )> }
  ) }
);

export type GetKnowledgebaseQuestionFulltextQueryVariables = Exact<{
  searchTerm: Scalars['String']['input'];
  type: InputMaybe<QuestionType>;
}>;


export type GetKnowledgebaseQuestionFulltextQuery = (
  { __typename?: 'Query' }
  & { getKnowledgebaseQuestionFulltext: Array<(
    { __typename?: 'KnowledgebaseQuestionDetail' }
    & Pick<KnowledgebaseQuestionDetail, 'id' | 'title' | 'titleSuffix' | 'seoName' | 'metaTitle' | 'metaDescription' | 'perex' | 'publicVisible' | 'publishedAt' | 'type' | 'vertical' | 'subVertical' | 'redirectTo'>
    & { metaImage: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    )>, category: Maybe<(
      { __typename?: 'KnowledgebaseCategoryPreview' }
      & Pick<KnowledgebaseCategoryPreview, 'id' | 'name' | 'description' | 'vertical' | 'subVertical'>
      & { parents: Array<(
        { __typename?: 'KnowledgebaseCategoryPreview' }
        & Pick<KnowledgebaseCategoryPreview, 'id' | 'name'>
      )> }
    )>, similar: Maybe<Array<(
      { __typename?: 'KnowledgebaseQuestionPreview' }
      & Pick<KnowledgebaseQuestionPreview, 'id' | 'title' | 'seoName' | 'perex' | 'type'>
    )>>, sections: Array<(
      { __typename?: 'ContentSectionBannerWithButton' }
      & Pick<ContentSectionBannerWithButton, 'id' | 'order' | 'sectionType' | 'contentSerialized' | 'title' | 'buttonText' | 'buttonUrl'>
      & { bannerType: ContentSectionBannerWithButton['type'] }
      & { campaign: Maybe<(
        { __typename?: 'Campaign' }
        & Pick<Campaign, 'id' | 'uuid' | 'active' | 'name'>
      )> }
    ) | { __typename?: 'ContentSectionBannerWithButtons' } | (
      { __typename?: 'ContentSectionBannerWithCallbackButton' }
      & Pick<ContentSectionBannerWithCallbackButton, 'id' | 'order' | 'sectionType' | 'contentSerialized' | 'title' | 'buttonText' | 'callbackSource' | 'popupTitle' | 'popupSubTitle'>
      & { campaign: Maybe<(
        { __typename?: 'Campaign' }
        & Pick<Campaign, 'id' | 'uuid' | 'active' | 'name'>
      )> }
    ) | (
      { __typename?: 'ContentSectionBlogArticles' }
      & Pick<ContentSectionBlogArticles, 'id' | 'order' | 'sectionType'>
      & { articles: Array<(
        { __typename?: 'ArticlePreview' }
        & Pick<ArticlePreview, 'id' | 'title' | 'perex' | 'seoName' | 'imageAlt' | 'imageSource' | 'author' | 'publishedAt' | 'updatedAt' | 'redirectTo'>
        & { image: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )> }
    ) | (
      { __typename?: 'ContentSectionContacts' }
      & Pick<ContentSectionContacts, 'id' | 'order' | 'sectionType'>
    ) | { __typename?: 'ContentSectionElectricityPriceList' } | { __typename?: 'ContentSectionFinanceProducts' } | { __typename?: 'ContentSectionGasPriceList' } | { __typename?: 'ContentSectionInternetSpeed' } | (
      { __typename?: 'ContentSectionJumbotron' }
      & Pick<ContentSectionJumbotron, 'id' | 'order' | 'sectionType' | 'contentSerialized' | 'subtitle' | 'title' | 'type' | 'metaDescriptionAsSubtitle' | 'callbackSource'>
      & { btnText: ContentSectionJumbotron['buttonText'], btnUrl: ContentSectionJumbotron['buttonUrl'] }
      & { campaign: Maybe<(
        { __typename?: 'Campaign' }
        & Pick<Campaign, 'id' | 'uuid' | 'active' | 'name'>
      )>, buttons: Array<(
        { __typename?: 'ContentSectionBannerWithButtonsButton' }
        & Pick<ContentSectionBannerWithButtonsButton, 'label' | 'url'>
      )> }
    ) | (
      { __typename?: 'ContentSectionKnowledgeBaseConcepts' }
      & Pick<ContentSectionKnowledgeBaseConcepts, 'id' | 'order' | 'sectionType'>
      & { knowledbaseTitle: ContentSectionKnowledgeBaseConcepts['title'] }
      & { questions: Array<(
        { __typename?: 'KnowledgebaseQuestionPreview' }
        & Pick<KnowledgebaseQuestionPreview, 'id' | 'title' | 'seoName' | 'perex' | 'type'>
      )> }
    ) | { __typename?: 'ContentSectionKnowledgeBaseQuestions' } | { __typename?: 'ContentSectionPrimaryHeadline' } | (
      { __typename?: 'ContentSectionTable' }
      & Pick<ContentSectionTable, 'id' | 'order' | 'sectionType' | 'content' | 'rows' | 'columns'>
    ) | (
      { __typename?: 'ContentSectionText' }
      & Pick<ContentSectionText, 'id' | 'order' | 'sectionType' | 'contentSerialized'>
    ) | (
      { __typename?: 'ContentSectionUsp' }
      & Pick<ContentSectionUsp, 'id' | 'order' | 'sectionType'>
    ) | (
      { __typename?: 'ContentSectionVehicleInsuranceContractDismissalDateForm' }
      & Pick<ContentSectionVehicleInsuranceContractDismissalDateForm, 'id' | 'order' | 'title' | 'sectionType' | 'contentSerialized'>
    )> }
  )> }
);

export type GetKnowledgebaseQuestionsByTagsQueryVariables = Exact<{
  data: KnowledgebaseGetByTagsInput;
}>;


export type GetKnowledgebaseQuestionsByTagsQuery = (
  { __typename?: 'Query' }
  & { getKnowledgebaseQuestionsByTags: Array<(
    { __typename?: 'KnowledgebaseQuestionPreview' }
    & Pick<KnowledgebaseQuestionPreview, 'id' | 'title' | 'seoName' | 'perex' | 'type'>
  )> }
);

export type GetKnowledgebaseQuestionsByTypeQueryVariables = Exact<{
  data: KnowledgebaseGetByTypeInput;
}>;


export type GetKnowledgebaseQuestionsByTypeQuery = (
  { __typename?: 'Query' }
  & { getKnowledgebaseQuestionsByType: Array<(
    { __typename?: 'KnowledgebaseQuestionPreview' }
    & Pick<KnowledgebaseQuestionPreview, 'id' | 'title' | 'seoName' | 'perex' | 'type'>
  )> }
);

export type LandingPageDataFragment = (
  { __typename?: 'LandingPageData' }
  & Pick<LandingPageData, 'id' | 'uuid' | 'name' | 'seoName' | 'metaTitle' | 'vertical' | 'subVertical' | 'metaDescription' | 'dedicatedUrl' | 'redirectTo'>
  & { metaImage: Maybe<(
    { __typename?: 'File' }
    & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
  )>, sections: Array<(
    { __typename?: 'ContentSectionBannerWithButton' }
    & Pick<ContentSectionBannerWithButton, 'id' | 'order' | 'sectionType' | 'contentSerialized' | 'title' | 'buttonText' | 'buttonUrl'>
    & { bannerType: ContentSectionBannerWithButton['type'] }
    & { campaign: Maybe<(
      { __typename?: 'Campaign' }
      & Pick<Campaign, 'id' | 'uuid' | 'active' | 'name'>
    )> }
  ) | (
    { __typename?: 'ContentSectionBannerWithButtons' }
    & Pick<ContentSectionBannerWithButtons, 'id' | 'order' | 'sectionType'>
    & { buttons: Array<(
      { __typename?: 'ContentSectionBannerWithButtonsButton' }
      & Pick<ContentSectionBannerWithButtonsButton, 'label' | 'url'>
    )> }
  ) | (
    { __typename?: 'ContentSectionBannerWithCallbackButton' }
    & Pick<ContentSectionBannerWithCallbackButton, 'id' | 'order' | 'sectionType' | 'contentSerialized' | 'title' | 'buttonText' | 'callbackSource' | 'popupTitle' | 'popupSubTitle'>
    & { campaign: Maybe<(
      { __typename?: 'Campaign' }
      & Pick<Campaign, 'id' | 'uuid' | 'active' | 'name'>
    )> }
  ) | (
    { __typename?: 'ContentSectionBlogArticles' }
    & Pick<ContentSectionBlogArticles, 'id' | 'order' | 'sectionType'>
    & { articles: Array<(
      { __typename?: 'ArticlePreview' }
      & Pick<ArticlePreview, 'id' | 'title' | 'perex' | 'seoName' | 'imageAlt' | 'imageSource' | 'author' | 'publishedAt' | 'updatedAt' | 'redirectTo'>
      & { image: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )> }
    )> }
  ) | (
    { __typename?: 'ContentSectionContacts' }
    & Pick<ContentSectionContacts, 'id' | 'order' | 'sectionType'>
  ) | (
    { __typename?: 'ContentSectionElectricityPriceList' }
    & Pick<ContentSectionElectricityPriceList, 'id' | 'order' | 'sectionType'>
    & { prices: Array<(
      { __typename?: 'EnergoElectricityPriceProductPreviewItem' }
      & Pick<EnergoElectricityPriceProductPreviewItem, 'id' | 'priceMonthly' | 'priceMWhHighTariff' | 'priceMWhLowTariff' | 'distributionRateId' | 'distributorId'>
      & { product: (
        { __typename?: 'EnergoElectricityProductPreview' }
        & Pick<EnergoElectricityProductPreview, 'id' | 'name' | 'isActive' | 'isActiveForPurchase' | 'activeForNewCustomers' | 'activeForCurrentCustomers' | 'activeForSelectCurrentExact' | 'activeForSelectCurrentEstimate' | 'private' | 'preferredSale' | 'availableForSale'>
        & { partner: (
          { __typename?: 'EnergoElectricityPartnerPreview' }
          & Pick<EnergoElectricityPartnerPreview, 'id' | 'name' | 'energoPartnerId' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'cooperationMethod' | 'companyAddress' | 'contactEmail' | 'tollFreePhone' | 'longitude' | 'latitude' | 'web' | 'urlVop' | 'urlPricelist' | 'longArticleMD' | 'active' | 'estimateDefaultProductId'>
        ) }
      ) }
    )> }
  ) | (
    { __typename?: 'ContentSectionFinanceProducts' }
    & Pick<ContentSectionFinanceProducts, 'id' | 'order' | 'sectionType' | 'titleWhite' | 'titleYellow' | 'text' | 'productTypes' | 'productPurpose'>
  ) | (
    { __typename?: 'ContentSectionGasPriceList' }
    & Pick<ContentSectionGasPriceList, 'id' | 'order' | 'sectionType'>
    & { prices: Array<(
      { __typename?: 'EnergoGasPriceProductPreviewItem' }
      & Pick<EnergoGasPriceProductPreviewItem, 'id' | 'pricePerM3' | 'priceMonthly' | 'priceMWh' | 'odberovePasmoId'>
      & { product: (
        { __typename?: 'EnergoGasProductPreview' }
        & Pick<EnergoGasProductPreview, 'id' | 'name' | 'isActive' | 'isActiveForPurchase' | 'activeForNewCustomers' | 'activeForCurrentCustomers' | 'activeForSelectCurrentExact' | 'activeForSelectCurrentEstimate' | 'private' | 'preferredSale' | 'availableForSale'>
        & { partner: (
          { __typename?: 'EnergoGasPartnerPreview' }
          & Pick<EnergoGasPartnerPreview, 'id' | 'name' | 'energoPartnerId' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'cooperationMethod' | 'companyAddress' | 'contactEmail' | 'tollFreePhone' | 'longitude' | 'latitude' | 'web' | 'urlVop' | 'urlPricelist' | 'longArticleMD' | 'active' | 'estimateDefaultProductId'>
        ) }
      ) }
    )> }
  ) | (
    { __typename?: 'ContentSectionInternetSpeed' }
    & Pick<ContentSectionInternetSpeed, 'id' | 'order' | 'title' | 'sectionType' | 'contentSerialized'>
  ) | (
    { __typename?: 'ContentSectionJumbotron' }
    & Pick<ContentSectionJumbotron, 'id' | 'order' | 'sectionType' | 'contentSerialized' | 'subtitle' | 'title' | 'type' | 'metaDescriptionAsSubtitle' | 'callbackSource'>
    & { btnText: ContentSectionJumbotron['buttonText'], btnUrl: ContentSectionJumbotron['buttonUrl'] }
    & { campaign: Maybe<(
      { __typename?: 'Campaign' }
      & Pick<Campaign, 'id' | 'uuid' | 'active' | 'name'>
    )>, buttons: Array<(
      { __typename?: 'ContentSectionBannerWithButtonsButton' }
      & Pick<ContentSectionBannerWithButtonsButton, 'label' | 'url'>
    )> }
  ) | (
    { __typename?: 'ContentSectionKnowledgeBaseConcepts' }
    & Pick<ContentSectionKnowledgeBaseConcepts, 'id' | 'order' | 'sectionType'>
    & { knowledbaseTitle: ContentSectionKnowledgeBaseConcepts['title'] }
    & { questions: Array<(
      { __typename?: 'KnowledgebaseQuestionPreview' }
      & Pick<KnowledgebaseQuestionPreview, 'id' | 'title' | 'seoName' | 'perex' | 'type'>
    )> }
  ) | (
    { __typename?: 'ContentSectionKnowledgeBaseQuestions' }
    & Pick<ContentSectionKnowledgeBaseQuestions, 'id' | 'order' | 'sectionType'>
    & { knowledbaseTitle: ContentSectionKnowledgeBaseQuestions['title'] }
    & { questions: Array<(
      { __typename?: 'KnowledgebaseQuestionPreview' }
      & Pick<KnowledgebaseQuestionPreview, 'id' | 'title' | 'seoName' | 'perex' | 'type'>
    )>, priorityQuestion1: Maybe<(
      { __typename?: 'KnowledgebaseQuestionPreviewWithContent' }
      & Pick<KnowledgebaseQuestionPreviewWithContent, 'id' | 'title' | 'seoName' | 'perex' | 'type'>
      & { sections: Array<(
        { __typename?: 'ContentSectionBannerWithButton' }
        & Pick<ContentSectionBannerWithButton, 'id' | 'order' | 'sectionType' | 'contentSerialized' | 'title' | 'buttonText' | 'buttonUrl'>
        & { bannerType: ContentSectionBannerWithButton['type'] }
        & { campaign: Maybe<(
          { __typename?: 'Campaign' }
          & Pick<Campaign, 'id' | 'uuid' | 'active' | 'name'>
        )> }
      ) | { __typename?: 'ContentSectionBannerWithButtons' } | (
        { __typename?: 'ContentSectionBannerWithCallbackButton' }
        & Pick<ContentSectionBannerWithCallbackButton, 'id' | 'order' | 'sectionType' | 'contentSerialized' | 'title' | 'buttonText' | 'callbackSource' | 'popupTitle' | 'popupSubTitle'>
        & { campaign: Maybe<(
          { __typename?: 'Campaign' }
          & Pick<Campaign, 'id' | 'uuid' | 'active' | 'name'>
        )> }
      ) | (
        { __typename?: 'ContentSectionBlogArticles' }
        & Pick<ContentSectionBlogArticles, 'id' | 'order' | 'sectionType'>
        & { articles: Array<(
          { __typename?: 'ArticlePreview' }
          & Pick<ArticlePreview, 'id' | 'title' | 'perex' | 'seoName' | 'imageAlt' | 'imageSource' | 'author' | 'publishedAt' | 'updatedAt' | 'redirectTo'>
          & { image: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )> }
      ) | (
        { __typename?: 'ContentSectionContacts' }
        & Pick<ContentSectionContacts, 'id' | 'order' | 'sectionType'>
      ) | { __typename?: 'ContentSectionElectricityPriceList' } | { __typename?: 'ContentSectionFinanceProducts' } | { __typename?: 'ContentSectionGasPriceList' } | { __typename?: 'ContentSectionInternetSpeed' } | (
        { __typename?: 'ContentSectionJumbotron' }
        & Pick<ContentSectionJumbotron, 'id' | 'order' | 'sectionType' | 'contentSerialized' | 'subtitle' | 'title' | 'type' | 'metaDescriptionAsSubtitle' | 'callbackSource'>
        & { btnText: ContentSectionJumbotron['buttonText'], btnUrl: ContentSectionJumbotron['buttonUrl'] }
        & { campaign: Maybe<(
          { __typename?: 'Campaign' }
          & Pick<Campaign, 'id' | 'uuid' | 'active' | 'name'>
        )>, buttons: Array<(
          { __typename?: 'ContentSectionBannerWithButtonsButton' }
          & Pick<ContentSectionBannerWithButtonsButton, 'label' | 'url'>
        )> }
      ) | (
        { __typename?: 'ContentSectionKnowledgeBaseConcepts' }
        & Pick<ContentSectionKnowledgeBaseConcepts, 'id' | 'order' | 'sectionType'>
        & { knowledbaseTitle: ContentSectionKnowledgeBaseConcepts['title'] }
        & { questions: Array<(
          { __typename?: 'KnowledgebaseQuestionPreview' }
          & Pick<KnowledgebaseQuestionPreview, 'id' | 'title' | 'seoName' | 'perex' | 'type'>
        )> }
      ) | { __typename?: 'ContentSectionKnowledgeBaseQuestions' } | { __typename?: 'ContentSectionPrimaryHeadline' } | (
        { __typename?: 'ContentSectionTable' }
        & Pick<ContentSectionTable, 'id' | 'order' | 'sectionType' | 'content' | 'rows' | 'columns'>
      ) | (
        { __typename?: 'ContentSectionText' }
        & Pick<ContentSectionText, 'id' | 'order' | 'sectionType' | 'contentSerialized'>
      ) | (
        { __typename?: 'ContentSectionUsp' }
        & Pick<ContentSectionUsp, 'id' | 'order' | 'sectionType'>
      ) | (
        { __typename?: 'ContentSectionVehicleInsuranceContractDismissalDateForm' }
        & Pick<ContentSectionVehicleInsuranceContractDismissalDateForm, 'id' | 'order' | 'title' | 'sectionType' | 'contentSerialized'>
      )> }
    )>, priorityQuestion2: Maybe<(
      { __typename?: 'KnowledgebaseQuestionPreviewWithContent' }
      & Pick<KnowledgebaseQuestionPreviewWithContent, 'id' | 'title' | 'seoName' | 'perex' | 'type'>
      & { sections: Array<(
        { __typename?: 'ContentSectionBannerWithButton' }
        & Pick<ContentSectionBannerWithButton, 'id' | 'order' | 'sectionType' | 'contentSerialized' | 'title' | 'buttonText' | 'buttonUrl'>
        & { bannerType: ContentSectionBannerWithButton['type'] }
        & { campaign: Maybe<(
          { __typename?: 'Campaign' }
          & Pick<Campaign, 'id' | 'uuid' | 'active' | 'name'>
        )> }
      ) | { __typename?: 'ContentSectionBannerWithButtons' } | (
        { __typename?: 'ContentSectionBannerWithCallbackButton' }
        & Pick<ContentSectionBannerWithCallbackButton, 'id' | 'order' | 'sectionType' | 'contentSerialized' | 'title' | 'buttonText' | 'callbackSource' | 'popupTitle' | 'popupSubTitle'>
        & { campaign: Maybe<(
          { __typename?: 'Campaign' }
          & Pick<Campaign, 'id' | 'uuid' | 'active' | 'name'>
        )> }
      ) | (
        { __typename?: 'ContentSectionBlogArticles' }
        & Pick<ContentSectionBlogArticles, 'id' | 'order' | 'sectionType'>
        & { articles: Array<(
          { __typename?: 'ArticlePreview' }
          & Pick<ArticlePreview, 'id' | 'title' | 'perex' | 'seoName' | 'imageAlt' | 'imageSource' | 'author' | 'publishedAt' | 'updatedAt' | 'redirectTo'>
          & { image: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )> }
      ) | (
        { __typename?: 'ContentSectionContacts' }
        & Pick<ContentSectionContacts, 'id' | 'order' | 'sectionType'>
      ) | { __typename?: 'ContentSectionElectricityPriceList' } | { __typename?: 'ContentSectionFinanceProducts' } | { __typename?: 'ContentSectionGasPriceList' } | { __typename?: 'ContentSectionInternetSpeed' } | (
        { __typename?: 'ContentSectionJumbotron' }
        & Pick<ContentSectionJumbotron, 'id' | 'order' | 'sectionType' | 'contentSerialized' | 'subtitle' | 'title' | 'type' | 'metaDescriptionAsSubtitle' | 'callbackSource'>
        & { btnText: ContentSectionJumbotron['buttonText'], btnUrl: ContentSectionJumbotron['buttonUrl'] }
        & { campaign: Maybe<(
          { __typename?: 'Campaign' }
          & Pick<Campaign, 'id' | 'uuid' | 'active' | 'name'>
        )>, buttons: Array<(
          { __typename?: 'ContentSectionBannerWithButtonsButton' }
          & Pick<ContentSectionBannerWithButtonsButton, 'label' | 'url'>
        )> }
      ) | (
        { __typename?: 'ContentSectionKnowledgeBaseConcepts' }
        & Pick<ContentSectionKnowledgeBaseConcepts, 'id' | 'order' | 'sectionType'>
        & { knowledbaseTitle: ContentSectionKnowledgeBaseConcepts['title'] }
        & { questions: Array<(
          { __typename?: 'KnowledgebaseQuestionPreview' }
          & Pick<KnowledgebaseQuestionPreview, 'id' | 'title' | 'seoName' | 'perex' | 'type'>
        )> }
      ) | { __typename?: 'ContentSectionKnowledgeBaseQuestions' } | { __typename?: 'ContentSectionPrimaryHeadline' } | (
        { __typename?: 'ContentSectionTable' }
        & Pick<ContentSectionTable, 'id' | 'order' | 'sectionType' | 'content' | 'rows' | 'columns'>
      ) | (
        { __typename?: 'ContentSectionText' }
        & Pick<ContentSectionText, 'id' | 'order' | 'sectionType' | 'contentSerialized'>
      ) | (
        { __typename?: 'ContentSectionUsp' }
        & Pick<ContentSectionUsp, 'id' | 'order' | 'sectionType'>
      ) | (
        { __typename?: 'ContentSectionVehicleInsuranceContractDismissalDateForm' }
        & Pick<ContentSectionVehicleInsuranceContractDismissalDateForm, 'id' | 'order' | 'title' | 'sectionType' | 'contentSerialized'>
      )> }
    )> }
  ) | (
    { __typename?: 'ContentSectionPrimaryHeadline' }
    & Pick<ContentSectionPrimaryHeadline, 'id' | 'order' | 'sectionType' | 'primaryHeadline'>
  ) | (
    { __typename?: 'ContentSectionTable' }
    & Pick<ContentSectionTable, 'id' | 'order' | 'sectionType' | 'content' | 'rows' | 'columns'>
  ) | (
    { __typename?: 'ContentSectionText' }
    & Pick<ContentSectionText, 'id' | 'order' | 'sectionType' | 'contentSerialized'>
  ) | (
    { __typename?: 'ContentSectionUsp' }
    & Pick<ContentSectionUsp, 'id' | 'order' | 'sectionType'>
  ) | (
    { __typename?: 'ContentSectionVehicleInsuranceContractDismissalDateForm' }
    & Pick<ContentSectionVehicleInsuranceContractDismissalDateForm, 'id' | 'order' | 'title' | 'sectionType' | 'contentSerialized'>
  )> }
);

export type LandingPageSitemapItemFragment = (
  { __typename?: 'LandingPageSiteMapItem' }
  & Pick<LandingPageSiteMapItem, 'id' | 'uuid' | 'name' | 'seoName' | 'dedicatedUrl' | 'updatedAt'>
);

export type LandingPageRegisterMutationVariables = Exact<{
  data: RegistrationInput;
}>;


export type LandingPageRegisterMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'landingPageRegister'>
);

export type GetLandingPageDataQueryVariables = Exact<{
  landingPageId: Scalars['String']['input'];
}>;


export type GetLandingPageDataQuery = (
  { __typename?: 'Query' }
  & { getLandingPageData: (
    { __typename?: 'LandingPageData' }
    & Pick<LandingPageData, 'id' | 'uuid' | 'name' | 'seoName' | 'metaTitle' | 'vertical' | 'subVertical' | 'metaDescription' | 'dedicatedUrl' | 'redirectTo'>
    & { metaImage: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    )>, sections: Array<(
      { __typename?: 'ContentSectionBannerWithButton' }
      & Pick<ContentSectionBannerWithButton, 'id' | 'order' | 'sectionType' | 'contentSerialized' | 'title' | 'buttonText' | 'buttonUrl'>
      & { bannerType: ContentSectionBannerWithButton['type'] }
      & { campaign: Maybe<(
        { __typename?: 'Campaign' }
        & Pick<Campaign, 'id' | 'uuid' | 'active' | 'name'>
      )> }
    ) | (
      { __typename?: 'ContentSectionBannerWithButtons' }
      & Pick<ContentSectionBannerWithButtons, 'id' | 'order' | 'sectionType'>
      & { buttons: Array<(
        { __typename?: 'ContentSectionBannerWithButtonsButton' }
        & Pick<ContentSectionBannerWithButtonsButton, 'label' | 'url'>
      )> }
    ) | (
      { __typename?: 'ContentSectionBannerWithCallbackButton' }
      & Pick<ContentSectionBannerWithCallbackButton, 'id' | 'order' | 'sectionType' | 'contentSerialized' | 'title' | 'buttonText' | 'callbackSource' | 'popupTitle' | 'popupSubTitle'>
      & { campaign: Maybe<(
        { __typename?: 'Campaign' }
        & Pick<Campaign, 'id' | 'uuid' | 'active' | 'name'>
      )> }
    ) | (
      { __typename?: 'ContentSectionBlogArticles' }
      & Pick<ContentSectionBlogArticles, 'id' | 'order' | 'sectionType'>
      & { articles: Array<(
        { __typename?: 'ArticlePreview' }
        & Pick<ArticlePreview, 'id' | 'title' | 'perex' | 'seoName' | 'imageAlt' | 'imageSource' | 'author' | 'publishedAt' | 'updatedAt' | 'redirectTo'>
        & { image: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )> }
    ) | (
      { __typename?: 'ContentSectionContacts' }
      & Pick<ContentSectionContacts, 'id' | 'order' | 'sectionType'>
    ) | (
      { __typename?: 'ContentSectionElectricityPriceList' }
      & Pick<ContentSectionElectricityPriceList, 'id' | 'order' | 'sectionType'>
      & { prices: Array<(
        { __typename?: 'EnergoElectricityPriceProductPreviewItem' }
        & Pick<EnergoElectricityPriceProductPreviewItem, 'id' | 'priceMonthly' | 'priceMWhHighTariff' | 'priceMWhLowTariff' | 'distributionRateId' | 'distributorId'>
        & { product: (
          { __typename?: 'EnergoElectricityProductPreview' }
          & Pick<EnergoElectricityProductPreview, 'id' | 'name' | 'isActive' | 'isActiveForPurchase' | 'activeForNewCustomers' | 'activeForCurrentCustomers' | 'activeForSelectCurrentExact' | 'activeForSelectCurrentEstimate' | 'private' | 'preferredSale' | 'availableForSale'>
          & { partner: (
            { __typename?: 'EnergoElectricityPartnerPreview' }
            & Pick<EnergoElectricityPartnerPreview, 'id' | 'name' | 'energoPartnerId' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'cooperationMethod' | 'companyAddress' | 'contactEmail' | 'tollFreePhone' | 'longitude' | 'latitude' | 'web' | 'urlVop' | 'urlPricelist' | 'longArticleMD' | 'active' | 'estimateDefaultProductId'>
          ) }
        ) }
      )> }
    ) | (
      { __typename?: 'ContentSectionFinanceProducts' }
      & Pick<ContentSectionFinanceProducts, 'id' | 'order' | 'sectionType' | 'titleWhite' | 'titleYellow' | 'text' | 'productTypes' | 'productPurpose'>
    ) | (
      { __typename?: 'ContentSectionGasPriceList' }
      & Pick<ContentSectionGasPriceList, 'id' | 'order' | 'sectionType'>
      & { prices: Array<(
        { __typename?: 'EnergoGasPriceProductPreviewItem' }
        & Pick<EnergoGasPriceProductPreviewItem, 'id' | 'pricePerM3' | 'priceMonthly' | 'priceMWh' | 'odberovePasmoId'>
        & { product: (
          { __typename?: 'EnergoGasProductPreview' }
          & Pick<EnergoGasProductPreview, 'id' | 'name' | 'isActive' | 'isActiveForPurchase' | 'activeForNewCustomers' | 'activeForCurrentCustomers' | 'activeForSelectCurrentExact' | 'activeForSelectCurrentEstimate' | 'private' | 'preferredSale' | 'availableForSale'>
          & { partner: (
            { __typename?: 'EnergoGasPartnerPreview' }
            & Pick<EnergoGasPartnerPreview, 'id' | 'name' | 'energoPartnerId' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'cooperationMethod' | 'companyAddress' | 'contactEmail' | 'tollFreePhone' | 'longitude' | 'latitude' | 'web' | 'urlVop' | 'urlPricelist' | 'longArticleMD' | 'active' | 'estimateDefaultProductId'>
          ) }
        ) }
      )> }
    ) | (
      { __typename?: 'ContentSectionInternetSpeed' }
      & Pick<ContentSectionInternetSpeed, 'id' | 'order' | 'title' | 'sectionType' | 'contentSerialized'>
    ) | (
      { __typename?: 'ContentSectionJumbotron' }
      & Pick<ContentSectionJumbotron, 'id' | 'order' | 'sectionType' | 'contentSerialized' | 'subtitle' | 'title' | 'type' | 'metaDescriptionAsSubtitle' | 'callbackSource'>
      & { btnText: ContentSectionJumbotron['buttonText'], btnUrl: ContentSectionJumbotron['buttonUrl'] }
      & { campaign: Maybe<(
        { __typename?: 'Campaign' }
        & Pick<Campaign, 'id' | 'uuid' | 'active' | 'name'>
      )>, buttons: Array<(
        { __typename?: 'ContentSectionBannerWithButtonsButton' }
        & Pick<ContentSectionBannerWithButtonsButton, 'label' | 'url'>
      )> }
    ) | (
      { __typename?: 'ContentSectionKnowledgeBaseConcepts' }
      & Pick<ContentSectionKnowledgeBaseConcepts, 'id' | 'order' | 'sectionType'>
      & { knowledbaseTitle: ContentSectionKnowledgeBaseConcepts['title'] }
      & { questions: Array<(
        { __typename?: 'KnowledgebaseQuestionPreview' }
        & Pick<KnowledgebaseQuestionPreview, 'id' | 'title' | 'seoName' | 'perex' | 'type'>
      )> }
    ) | (
      { __typename?: 'ContentSectionKnowledgeBaseQuestions' }
      & Pick<ContentSectionKnowledgeBaseQuestions, 'id' | 'order' | 'sectionType'>
      & { knowledbaseTitle: ContentSectionKnowledgeBaseQuestions['title'] }
      & { questions: Array<(
        { __typename?: 'KnowledgebaseQuestionPreview' }
        & Pick<KnowledgebaseQuestionPreview, 'id' | 'title' | 'seoName' | 'perex' | 'type'>
      )>, priorityQuestion1: Maybe<(
        { __typename?: 'KnowledgebaseQuestionPreviewWithContent' }
        & Pick<KnowledgebaseQuestionPreviewWithContent, 'id' | 'title' | 'seoName' | 'perex' | 'type'>
        & { sections: Array<(
          { __typename?: 'ContentSectionBannerWithButton' }
          & Pick<ContentSectionBannerWithButton, 'id' | 'order' | 'sectionType' | 'contentSerialized' | 'title' | 'buttonText' | 'buttonUrl'>
          & { bannerType: ContentSectionBannerWithButton['type'] }
          & { campaign: Maybe<(
            { __typename?: 'Campaign' }
            & Pick<Campaign, 'id' | 'uuid' | 'active' | 'name'>
          )> }
        ) | { __typename?: 'ContentSectionBannerWithButtons' } | (
          { __typename?: 'ContentSectionBannerWithCallbackButton' }
          & Pick<ContentSectionBannerWithCallbackButton, 'id' | 'order' | 'sectionType' | 'contentSerialized' | 'title' | 'buttonText' | 'callbackSource' | 'popupTitle' | 'popupSubTitle'>
          & { campaign: Maybe<(
            { __typename?: 'Campaign' }
            & Pick<Campaign, 'id' | 'uuid' | 'active' | 'name'>
          )> }
        ) | (
          { __typename?: 'ContentSectionBlogArticles' }
          & Pick<ContentSectionBlogArticles, 'id' | 'order' | 'sectionType'>
          & { articles: Array<(
            { __typename?: 'ArticlePreview' }
            & Pick<ArticlePreview, 'id' | 'title' | 'perex' | 'seoName' | 'imageAlt' | 'imageSource' | 'author' | 'publishedAt' | 'updatedAt' | 'redirectTo'>
            & { image: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )> }
          )> }
        ) | (
          { __typename?: 'ContentSectionContacts' }
          & Pick<ContentSectionContacts, 'id' | 'order' | 'sectionType'>
        ) | { __typename?: 'ContentSectionElectricityPriceList' } | { __typename?: 'ContentSectionFinanceProducts' } | { __typename?: 'ContentSectionGasPriceList' } | { __typename?: 'ContentSectionInternetSpeed' } | (
          { __typename?: 'ContentSectionJumbotron' }
          & Pick<ContentSectionJumbotron, 'id' | 'order' | 'sectionType' | 'contentSerialized' | 'subtitle' | 'title' | 'type' | 'metaDescriptionAsSubtitle' | 'callbackSource'>
          & { btnText: ContentSectionJumbotron['buttonText'], btnUrl: ContentSectionJumbotron['buttonUrl'] }
          & { campaign: Maybe<(
            { __typename?: 'Campaign' }
            & Pick<Campaign, 'id' | 'uuid' | 'active' | 'name'>
          )>, buttons: Array<(
            { __typename?: 'ContentSectionBannerWithButtonsButton' }
            & Pick<ContentSectionBannerWithButtonsButton, 'label' | 'url'>
          )> }
        ) | (
          { __typename?: 'ContentSectionKnowledgeBaseConcepts' }
          & Pick<ContentSectionKnowledgeBaseConcepts, 'id' | 'order' | 'sectionType'>
          & { knowledbaseTitle: ContentSectionKnowledgeBaseConcepts['title'] }
          & { questions: Array<(
            { __typename?: 'KnowledgebaseQuestionPreview' }
            & Pick<KnowledgebaseQuestionPreview, 'id' | 'title' | 'seoName' | 'perex' | 'type'>
          )> }
        ) | { __typename?: 'ContentSectionKnowledgeBaseQuestions' } | { __typename?: 'ContentSectionPrimaryHeadline' } | (
          { __typename?: 'ContentSectionTable' }
          & Pick<ContentSectionTable, 'id' | 'order' | 'sectionType' | 'content' | 'rows' | 'columns'>
        ) | (
          { __typename?: 'ContentSectionText' }
          & Pick<ContentSectionText, 'id' | 'order' | 'sectionType' | 'contentSerialized'>
        ) | (
          { __typename?: 'ContentSectionUsp' }
          & Pick<ContentSectionUsp, 'id' | 'order' | 'sectionType'>
        ) | (
          { __typename?: 'ContentSectionVehicleInsuranceContractDismissalDateForm' }
          & Pick<ContentSectionVehicleInsuranceContractDismissalDateForm, 'id' | 'order' | 'title' | 'sectionType' | 'contentSerialized'>
        )> }
      )>, priorityQuestion2: Maybe<(
        { __typename?: 'KnowledgebaseQuestionPreviewWithContent' }
        & Pick<KnowledgebaseQuestionPreviewWithContent, 'id' | 'title' | 'seoName' | 'perex' | 'type'>
        & { sections: Array<(
          { __typename?: 'ContentSectionBannerWithButton' }
          & Pick<ContentSectionBannerWithButton, 'id' | 'order' | 'sectionType' | 'contentSerialized' | 'title' | 'buttonText' | 'buttonUrl'>
          & { bannerType: ContentSectionBannerWithButton['type'] }
          & { campaign: Maybe<(
            { __typename?: 'Campaign' }
            & Pick<Campaign, 'id' | 'uuid' | 'active' | 'name'>
          )> }
        ) | { __typename?: 'ContentSectionBannerWithButtons' } | (
          { __typename?: 'ContentSectionBannerWithCallbackButton' }
          & Pick<ContentSectionBannerWithCallbackButton, 'id' | 'order' | 'sectionType' | 'contentSerialized' | 'title' | 'buttonText' | 'callbackSource' | 'popupTitle' | 'popupSubTitle'>
          & { campaign: Maybe<(
            { __typename?: 'Campaign' }
            & Pick<Campaign, 'id' | 'uuid' | 'active' | 'name'>
          )> }
        ) | (
          { __typename?: 'ContentSectionBlogArticles' }
          & Pick<ContentSectionBlogArticles, 'id' | 'order' | 'sectionType'>
          & { articles: Array<(
            { __typename?: 'ArticlePreview' }
            & Pick<ArticlePreview, 'id' | 'title' | 'perex' | 'seoName' | 'imageAlt' | 'imageSource' | 'author' | 'publishedAt' | 'updatedAt' | 'redirectTo'>
            & { image: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )> }
          )> }
        ) | (
          { __typename?: 'ContentSectionContacts' }
          & Pick<ContentSectionContacts, 'id' | 'order' | 'sectionType'>
        ) | { __typename?: 'ContentSectionElectricityPriceList' } | { __typename?: 'ContentSectionFinanceProducts' } | { __typename?: 'ContentSectionGasPriceList' } | { __typename?: 'ContentSectionInternetSpeed' } | (
          { __typename?: 'ContentSectionJumbotron' }
          & Pick<ContentSectionJumbotron, 'id' | 'order' | 'sectionType' | 'contentSerialized' | 'subtitle' | 'title' | 'type' | 'metaDescriptionAsSubtitle' | 'callbackSource'>
          & { btnText: ContentSectionJumbotron['buttonText'], btnUrl: ContentSectionJumbotron['buttonUrl'] }
          & { campaign: Maybe<(
            { __typename?: 'Campaign' }
            & Pick<Campaign, 'id' | 'uuid' | 'active' | 'name'>
          )>, buttons: Array<(
            { __typename?: 'ContentSectionBannerWithButtonsButton' }
            & Pick<ContentSectionBannerWithButtonsButton, 'label' | 'url'>
          )> }
        ) | (
          { __typename?: 'ContentSectionKnowledgeBaseConcepts' }
          & Pick<ContentSectionKnowledgeBaseConcepts, 'id' | 'order' | 'sectionType'>
          & { knowledbaseTitle: ContentSectionKnowledgeBaseConcepts['title'] }
          & { questions: Array<(
            { __typename?: 'KnowledgebaseQuestionPreview' }
            & Pick<KnowledgebaseQuestionPreview, 'id' | 'title' | 'seoName' | 'perex' | 'type'>
          )> }
        ) | { __typename?: 'ContentSectionKnowledgeBaseQuestions' } | { __typename?: 'ContentSectionPrimaryHeadline' } | (
          { __typename?: 'ContentSectionTable' }
          & Pick<ContentSectionTable, 'id' | 'order' | 'sectionType' | 'content' | 'rows' | 'columns'>
        ) | (
          { __typename?: 'ContentSectionText' }
          & Pick<ContentSectionText, 'id' | 'order' | 'sectionType' | 'contentSerialized'>
        ) | (
          { __typename?: 'ContentSectionUsp' }
          & Pick<ContentSectionUsp, 'id' | 'order' | 'sectionType'>
        ) | (
          { __typename?: 'ContentSectionVehicleInsuranceContractDismissalDateForm' }
          & Pick<ContentSectionVehicleInsuranceContractDismissalDateForm, 'id' | 'order' | 'title' | 'sectionType' | 'contentSerialized'>
        )> }
      )> }
    ) | (
      { __typename?: 'ContentSectionPrimaryHeadline' }
      & Pick<ContentSectionPrimaryHeadline, 'id' | 'order' | 'sectionType' | 'primaryHeadline'>
    ) | (
      { __typename?: 'ContentSectionTable' }
      & Pick<ContentSectionTable, 'id' | 'order' | 'sectionType' | 'content' | 'rows' | 'columns'>
    ) | (
      { __typename?: 'ContentSectionText' }
      & Pick<ContentSectionText, 'id' | 'order' | 'sectionType' | 'contentSerialized'>
    ) | (
      { __typename?: 'ContentSectionUsp' }
      & Pick<ContentSectionUsp, 'id' | 'order' | 'sectionType'>
    ) | (
      { __typename?: 'ContentSectionVehicleInsuranceContractDismissalDateForm' }
      & Pick<ContentSectionVehicleInsuranceContractDismissalDateForm, 'id' | 'order' | 'title' | 'sectionType' | 'contentSerialized'>
    )> }
  ) }
);

export type LeadFragment = (
  { __typename?: 'Lead' }
  & Pick<Lead, 'id' | 'type' | 'uuid' | 'name' | 'surname' | 'email' | 'phone' | 'status' | 'customerNote' | 'lastStatusChange' | 'completedAt' | 'sendEmailImmediately'>
  & { address: Maybe<(
    { __typename?: 'Address' }
    & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
    & { extended: Maybe<(
      { __typename?: 'SmartAddressExtendedInfo' }
      & Pick<SmartAddressExtendedInfo, 'id'>
      & { objectType: Maybe<(
        { __typename?: 'SmartAddressObjectType' }
        & Pick<SmartAddressObjectType, 'id' | 'nazev'>
      )> }
    )>, extendedV2: Maybe<(
      { __typename?: 'SmartAddressSdkExtendedInfo' }
      & { administrativniUdaje: Maybe<(
        { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
        & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
        & { objectType: Maybe<(
          { __typename?: 'SmartAddressObjectType' }
          & Pick<SmartAddressObjectType, 'id' | 'nazev'>
        )> }
      )> }
    )> }
  )>, contactAddress: Maybe<(
    { __typename?: 'Address' }
    & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
    & { extended: Maybe<(
      { __typename?: 'SmartAddressExtendedInfo' }
      & Pick<SmartAddressExtendedInfo, 'id'>
      & { objectType: Maybe<(
        { __typename?: 'SmartAddressObjectType' }
        & Pick<SmartAddressObjectType, 'id' | 'nazev'>
      )> }
    )>, extendedV2: Maybe<(
      { __typename?: 'SmartAddressSdkExtendedInfo' }
      & { administrativniUdaje: Maybe<(
        { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
        & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
        & { objectType: Maybe<(
          { __typename?: 'SmartAddressObjectType' }
          & Pick<SmartAddressObjectType, 'id' | 'nazev'>
        )> }
      )> }
    )> }
  )>, attachments: Array<(
    { __typename?: 'LeadAttachment' }
    & Pick<LeadAttachment, 'id'>
    & { file: (
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    ) }
  )> }
);

export type LeadCompleteMutationVariables = Exact<{
  leadUuid: Scalars['String']['input'];
  data: LeadCompleteInput;
}>;


export type LeadCompleteMutation = (
  { __typename?: 'Mutation' }
  & { leadComplete: (
    { __typename?: 'Lead' }
    & Pick<Lead, 'id' | 'type' | 'uuid' | 'name' | 'surname' | 'email' | 'phone' | 'status' | 'customerNote' | 'lastStatusChange' | 'completedAt' | 'sendEmailImmediately'>
    & { address: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
      & { extended: Maybe<(
        { __typename?: 'SmartAddressExtendedInfo' }
        & Pick<SmartAddressExtendedInfo, 'id'>
        & { objectType: Maybe<(
          { __typename?: 'SmartAddressObjectType' }
          & Pick<SmartAddressObjectType, 'id' | 'nazev'>
        )> }
      )>, extendedV2: Maybe<(
        { __typename?: 'SmartAddressSdkExtendedInfo' }
        & { administrativniUdaje: Maybe<(
          { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
          & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )> }
      )> }
    )>, contactAddress: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
      & { extended: Maybe<(
        { __typename?: 'SmartAddressExtendedInfo' }
        & Pick<SmartAddressExtendedInfo, 'id'>
        & { objectType: Maybe<(
          { __typename?: 'SmartAddressObjectType' }
          & Pick<SmartAddressObjectType, 'id' | 'nazev'>
        )> }
      )>, extendedV2: Maybe<(
        { __typename?: 'SmartAddressSdkExtendedInfo' }
        & { administrativniUdaje: Maybe<(
          { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
          & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )> }
      )> }
    )>, attachments: Array<(
      { __typename?: 'LeadAttachment' }
      & Pick<LeadAttachment, 'id'>
      & { file: (
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      ) }
    )> }
  ) }
);

export type SaveLeadContractDataMutationVariables = Exact<{
  leadUuid: Scalars['String']['input'];
  data: LeadContractDataInput;
}>;


export type SaveLeadContractDataMutation = (
  { __typename?: 'Mutation' }
  & { saveLeadContractData: (
    { __typename?: 'Lead' }
    & Pick<Lead, 'id' | 'type' | 'uuid' | 'name' | 'surname' | 'email' | 'phone' | 'status' | 'customerNote' | 'lastStatusChange' | 'completedAt' | 'sendEmailImmediately'>
    & { address: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
      & { extended: Maybe<(
        { __typename?: 'SmartAddressExtendedInfo' }
        & Pick<SmartAddressExtendedInfo, 'id'>
        & { objectType: Maybe<(
          { __typename?: 'SmartAddressObjectType' }
          & Pick<SmartAddressObjectType, 'id' | 'nazev'>
        )> }
      )>, extendedV2: Maybe<(
        { __typename?: 'SmartAddressSdkExtendedInfo' }
        & { administrativniUdaje: Maybe<(
          { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
          & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )> }
      )> }
    )>, contactAddress: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
      & { extended: Maybe<(
        { __typename?: 'SmartAddressExtendedInfo' }
        & Pick<SmartAddressExtendedInfo, 'id'>
        & { objectType: Maybe<(
          { __typename?: 'SmartAddressObjectType' }
          & Pick<SmartAddressObjectType, 'id' | 'nazev'>
        )> }
      )>, extendedV2: Maybe<(
        { __typename?: 'SmartAddressSdkExtendedInfo' }
        & { administrativniUdaje: Maybe<(
          { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
          & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )> }
      )> }
    )>, attachments: Array<(
      { __typename?: 'LeadAttachment' }
      & Pick<LeadAttachment, 'id'>
      & { file: (
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      ) }
    )> }
  ) }
);

export type LeadGetQueryVariables = Exact<{
  leadUuid: Scalars['String']['input'];
}>;


export type LeadGetQuery = (
  { __typename?: 'Query' }
  & { leadGet: (
    { __typename?: 'Lead' }
    & Pick<Lead, 'id' | 'type' | 'uuid' | 'name' | 'surname' | 'email' | 'phone' | 'status' | 'customerNote' | 'lastStatusChange' | 'completedAt' | 'sendEmailImmediately'>
    & { address: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
      & { extended: Maybe<(
        { __typename?: 'SmartAddressExtendedInfo' }
        & Pick<SmartAddressExtendedInfo, 'id'>
        & { objectType: Maybe<(
          { __typename?: 'SmartAddressObjectType' }
          & Pick<SmartAddressObjectType, 'id' | 'nazev'>
        )> }
      )>, extendedV2: Maybe<(
        { __typename?: 'SmartAddressSdkExtendedInfo' }
        & { administrativniUdaje: Maybe<(
          { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
          & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )> }
      )> }
    )>, contactAddress: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
      & { extended: Maybe<(
        { __typename?: 'SmartAddressExtendedInfo' }
        & Pick<SmartAddressExtendedInfo, 'id'>
        & { objectType: Maybe<(
          { __typename?: 'SmartAddressObjectType' }
          & Pick<SmartAddressObjectType, 'id' | 'nazev'>
        )> }
      )>, extendedV2: Maybe<(
        { __typename?: 'SmartAddressSdkExtendedInfo' }
        & { administrativniUdaje: Maybe<(
          { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
          & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )> }
      )> }
    )>, attachments: Array<(
      { __typename?: 'LeadAttachment' }
      & Pick<LeadAttachment, 'id'>
      & { file: (
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      ) }
    )> }
  ) }
);

export type SaveMarketingProfileMutationVariables = Exact<{
  data: MarketingMarketingProfileSaveInput;
}>;


export type SaveMarketingProfileMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'saveMarketingProfile'>
);

export type NavbarConfigItemFragment = (
  { __typename?: 'NavbarConfigItem' }
  & Pick<NavbarConfigItem, 'label' | 'href'>
);

export type NavbarConfigSectionColumnFragment = (
  { __typename?: 'NavbarConfigSectionColumn' }
  & { items: Array<(
    { __typename?: 'NavbarConfigItem' }
    & Pick<NavbarConfigItem, 'label' | 'href'>
  )> }
);

export type NavbarConfigSectionFragment = (
  { __typename?: 'NavbarConfigSection' }
  & Pick<NavbarConfigSection, 'label' | 'href'>
  & { columns: Array<(
    { __typename?: 'NavbarConfigSectionColumn' }
    & { items: Array<(
      { __typename?: 'NavbarConfigItem' }
      & Pick<NavbarConfigItem, 'label' | 'href'>
    )> }
  )> }
);

export type NavbarConfigFragment = (
  { __typename?: 'NavbarConfig' }
  & { sections: Array<(
    { __typename?: 'NavbarConfigSection' }
    & Pick<NavbarConfigSection, 'label' | 'href'>
    & { columns: Array<(
      { __typename?: 'NavbarConfigSectionColumn' }
      & { items: Array<(
        { __typename?: 'NavbarConfigItem' }
        & Pick<NavbarConfigItem, 'label' | 'href'>
      )> }
    )> }
  )> }
);

export type NavbarConfigQueryVariables = Exact<{ [key: string]: never; }>;


export type NavbarConfigQuery = (
  { __typename?: 'Query' }
  & { navbarConfig: (
    { __typename?: 'NavbarConfig' }
    & { sections: Array<(
      { __typename?: 'NavbarConfigSection' }
      & Pick<NavbarConfigSection, 'label' | 'href'>
      & { columns: Array<(
        { __typename?: 'NavbarConfigSectionColumn' }
        & { items: Array<(
          { __typename?: 'NavbarConfigItem' }
          & Pick<NavbarConfigItem, 'label' | 'href'>
        )> }
      )> }
    )> }
  ) }
);

export type MyProfileFragment = (
  { __typename?: 'MyProfile' }
  & Pick<MyProfile, 'id' | 'uuid' | 'name' | 'surname' | 'companyName' | 'email' | 'otherPhones' | 'phone' | 'preferedComunication' | 'notificationPolicy' | 'isLogged' | 'vocative' | 'role' | 'customerZoneIntroSeenAt' | 'passwordSet'>
  & { activeCampaigns: Array<(
    { __typename?: 'Campaign' }
    & Pick<Campaign, 'id' | 'uuid' | 'active' | 'name'>
  )>, firstCompleteAddress: Maybe<(
    { __typename?: 'Address' }
    & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
    & { extended: Maybe<(
      { __typename?: 'SmartAddressExtendedInfo' }
      & Pick<SmartAddressExtendedInfo, 'id'>
      & { objectType: Maybe<(
        { __typename?: 'SmartAddressObjectType' }
        & Pick<SmartAddressObjectType, 'id' | 'nazev'>
      )> }
    )>, extendedV2: Maybe<(
      { __typename?: 'SmartAddressSdkExtendedInfo' }
      & { administrativniUdaje: Maybe<(
        { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
        & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
        & { objectType: Maybe<(
          { __typename?: 'SmartAddressObjectType' }
          & Pick<SmartAddressObjectType, 'id' | 'nazev'>
        )> }
      )> }
    )> }
  )>, backofficeProfile: Maybe<(
    { __typename?: 'AdminProfile' }
    & Pick<AdminProfile, 'id'>
  )> }
);

export type ProfileUserFragment = (
  { __typename?: 'ProfileUser' }
  & Pick<ProfileUser, 'email' | 'name'>
);

export type AdminProfileFragment = (
  { __typename?: 'AdminProfile' }
  & Pick<AdminProfile, 'id' | 'userId' | 'uuid' | 'name' | 'username' | 'email' | 'role'>
);

export type UpdateProfileMutationVariables = Exact<{
  data: ProfileInput;
}>;


export type UpdateProfileMutation = (
  { __typename?: 'Mutation' }
  & { updateProfile: (
    { __typename?: 'MyProfile' }
    & Pick<MyProfile, 'id' | 'notificationPolicy' | 'preferedComunication'>
  ) }
);

export type AddProfileEmailMutationVariables = Exact<{
  data: ProfileAddEmail;
}>;


export type AddProfileEmailMutation = (
  { __typename?: 'Mutation' }
  & { addProfileEmail: (
    { __typename?: 'MyProfile' }
    & Pick<MyProfile, 'id' | 'otherEmails'>
  ) }
);

export type AddProfilePhoneMutationVariables = Exact<{
  data: ProfileAddPhone;
}>;


export type AddProfilePhoneMutation = (
  { __typename?: 'Mutation' }
  & { addProfilePhone: (
    { __typename?: 'MyProfile' }
    & Pick<MyProfile, 'id' | 'otherPhones'>
  ) }
);

export type AddProfileContactMutationVariables = Exact<{
  data: ProfileAddContact;
}>;


export type AddProfileContactMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addProfileContact'>
);

export type EditProfileCustomerZoneIntroSeenMutationVariables = Exact<{ [key: string]: never; }>;


export type EditProfileCustomerZoneIntroSeenMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'profileCustomerZoneIntroSeen'>
);

export type ProfileMeQueryVariables = Exact<{ [key: string]: never; }>;


export type ProfileMeQuery = (
  { __typename?: 'Query' }
  & { profileMe: Maybe<(
    { __typename?: 'MyProfile' }
    & Pick<MyProfile, 'id' | 'uuid' | 'name' | 'surname' | 'companyName' | 'email' | 'otherPhones' | 'phone' | 'preferedComunication' | 'notificationPolicy' | 'isLogged' | 'vocative' | 'role' | 'customerZoneIntroSeenAt' | 'passwordSet'>
    & { activeCampaigns: Array<(
      { __typename?: 'Campaign' }
      & Pick<Campaign, 'id' | 'uuid' | 'active' | 'name'>
    )>, firstCompleteAddress: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
      & { extended: Maybe<(
        { __typename?: 'SmartAddressExtendedInfo' }
        & Pick<SmartAddressExtendedInfo, 'id'>
        & { objectType: Maybe<(
          { __typename?: 'SmartAddressObjectType' }
          & Pick<SmartAddressObjectType, 'id' | 'nazev'>
        )> }
      )>, extendedV2: Maybe<(
        { __typename?: 'SmartAddressSdkExtendedInfo' }
        & { administrativniUdaje: Maybe<(
          { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
          & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )> }
      )> }
    )>, backofficeProfile: Maybe<(
      { __typename?: 'AdminProfile' }
      & Pick<AdminProfile, 'id'>
    )> }
  )> }
);

export type ProfileAdminMeQueryVariables = Exact<{ [key: string]: never; }>;


export type ProfileAdminMeQuery = (
  { __typename?: 'Query' }
  & { profileAdminMe: Maybe<(
    { __typename?: 'AdminProfile' }
    & Pick<AdminProfile, 'id' | 'userId' | 'uuid' | 'name' | 'username' | 'email' | 'role'>
  )> }
);

export type ProfileHasPhoneQueryVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type ProfileHasPhoneQuery = (
  { __typename?: 'Query' }
  & { hasPhone: Maybe<(
    { __typename?: 'HasPhone' }
    & Pick<HasPhone, 'hasPhone'>
  )> }
);

export type PropertyInsuranceRequirementsFragment = (
  { __typename?: 'PropertyInsuranceRequirements' }
  & Pick<PropertyInsuranceRequirements, 'complementLiabilityCommonRequirement' | 'complementLiabilityPropertyRequirement' | 'floodInsuranceRequirement'>
);

export type PropertyInsuranceRiscIndicatorsFragment = (
  { __typename?: 'PropertyInsuranceRiscIndicators' }
  & Pick<PropertyInsuranceRiscIndicators, 'floodCount20YearsBuilding' | 'floodCount20YearsHouseHold'>
);

export type PropertyInsuranceInfoFragment = (
  { __typename?: 'PropertyInsuranceInfo' }
  & Pick<PropertyInsuranceInfo, 'sumInsuredBuilding' | 'sumInsuredHousehold'>
);

export type PropertyInsuranceContactPersonFragment = (
  { __typename?: 'PropertyInsuranceContactPerson' }
  & Pick<PropertyInsuranceContactPerson, 'id' | 'agreementProcessPersonalData' | 'agreementTermsAndConditions' | 'email' | 'name' | 'phone' | 'surname'>
);

export type PropertyInsuranceOrderFragment = (
  { __typename?: 'PropertyInsuranceOrder' }
  & Pick<PropertyInsuranceOrder, 'uuid' | 'buildingPrice' | 'buildingSize' | 'buildingType' | 'groundFloorApartment' | 'householdPrice' | 'householdSize' | 'insuranceType' | 'insureSecondaryBuilding' | 'propertyTenantRelation' | 'resultEmail' | 'resultPhone' | 'createdAt' | 'updatedAt' | 'status'>
  & { insuredAddress: (
    { __typename?: 'Address' }
    & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
    & { extended: Maybe<(
      { __typename?: 'SmartAddressExtendedInfo' }
      & Pick<SmartAddressExtendedInfo, 'id'>
      & { objectType: Maybe<(
        { __typename?: 'SmartAddressObjectType' }
        & Pick<SmartAddressObjectType, 'id' | 'nazev'>
      )> }
    )>, extendedV2: Maybe<(
      { __typename?: 'SmartAddressSdkExtendedInfo' }
      & { administrativniUdaje: Maybe<(
        { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
        & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
        & { objectType: Maybe<(
          { __typename?: 'SmartAddressObjectType' }
          & Pick<SmartAddressObjectType, 'id' | 'nazev'>
        )> }
      )> }
    )> }
  ), requirements: Maybe<(
    { __typename?: 'PropertyInsuranceRequirements' }
    & Pick<PropertyInsuranceRequirements, 'complementLiabilityCommonRequirement' | 'complementLiabilityPropertyRequirement' | 'floodInsuranceRequirement'>
  )>, riscIndicators: Maybe<(
    { __typename?: 'PropertyInsuranceRiscIndicators' }
    & Pick<PropertyInsuranceRiscIndicators, 'floodCount20YearsBuilding' | 'floodCount20YearsHouseHold'>
  )>, insuranceInfo: (
    { __typename?: 'PropertyInsuranceInfo' }
    & Pick<PropertyInsuranceInfo, 'sumInsuredBuilding' | 'sumInsuredHousehold'>
  ), selectedOffer: Maybe<(
    { __typename?: 'PropertyInsuranceProductOffer' }
    & Pick<PropertyInsuranceProductOffer, 'id' | 'uuid' | 'price' | 'insuranceType'>
    & { product: (
      { __typename?: 'PropertyInsuranceProduct' }
      & Pick<PropertyInsuranceProduct, 'id' | 'shortName' | 'name' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'type' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'risksHousehold' | 'risksProperty'>
      & { partner: (
        { __typename?: 'ZeteoInsurancePartner' }
        & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
        & { logo: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )>, logoSquare: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )>, rating2: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), productDetailFile: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )>, subLimitsHousehold: Array<(
        { __typename?: 'PropertyInsuranceSubLimit' }
        & Pick<PropertyInsuranceSubLimit, 'id' | 'label' | 'description' | 'value'>
      )>, faqs: Array<(
        { __typename?: 'FaqPreview' }
        & Pick<FaqPreview, 'id' | 'question' | 'answerMD' | 'hasDetail' | 'seoName'>
      )>, subLimitsProperty: Array<(
        { __typename?: 'PropertyInsuranceSubLimit' }
        & Pick<PropertyInsuranceSubLimit, 'id' | 'label' | 'description' | 'value'>
      )> }
    ) }
  )>, contactPerson: Maybe<(
    { __typename?: 'PropertyInsuranceContactPerson' }
    & Pick<PropertyInsuranceContactPerson, 'id' | 'agreementProcessPersonalData' | 'agreementTermsAndConditions' | 'email' | 'name' | 'phone' | 'surname'>
  )> }
);

export type PropertyInsuranceCalculationResultFragment = (
  { __typename?: 'PropertyInsuranceCalculationResult' }
  & Pick<PropertyInsuranceCalculationResult, 'id'>
  & { order: (
    { __typename?: 'PropertyInsuranceOrder' }
    & Pick<PropertyInsuranceOrder, 'uuid' | 'buildingPrice' | 'buildingSize' | 'buildingType' | 'groundFloorApartment' | 'householdPrice' | 'householdSize' | 'insuranceType' | 'insureSecondaryBuilding' | 'propertyTenantRelation' | 'resultEmail' | 'resultPhone' | 'createdAt' | 'updatedAt' | 'status'>
    & { insuredAddress: (
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
      & { extended: Maybe<(
        { __typename?: 'SmartAddressExtendedInfo' }
        & Pick<SmartAddressExtendedInfo, 'id'>
        & { objectType: Maybe<(
          { __typename?: 'SmartAddressObjectType' }
          & Pick<SmartAddressObjectType, 'id' | 'nazev'>
        )> }
      )>, extendedV2: Maybe<(
        { __typename?: 'SmartAddressSdkExtendedInfo' }
        & { administrativniUdaje: Maybe<(
          { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
          & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )> }
      )> }
    ), requirements: Maybe<(
      { __typename?: 'PropertyInsuranceRequirements' }
      & Pick<PropertyInsuranceRequirements, 'complementLiabilityCommonRequirement' | 'complementLiabilityPropertyRequirement' | 'floodInsuranceRequirement'>
    )>, riscIndicators: Maybe<(
      { __typename?: 'PropertyInsuranceRiscIndicators' }
      & Pick<PropertyInsuranceRiscIndicators, 'floodCount20YearsBuilding' | 'floodCount20YearsHouseHold'>
    )>, insuranceInfo: (
      { __typename?: 'PropertyInsuranceInfo' }
      & Pick<PropertyInsuranceInfo, 'sumInsuredBuilding' | 'sumInsuredHousehold'>
    ), selectedOffer: Maybe<(
      { __typename?: 'PropertyInsuranceProductOffer' }
      & Pick<PropertyInsuranceProductOffer, 'id' | 'uuid' | 'price' | 'insuranceType'>
      & { product: (
        { __typename?: 'PropertyInsuranceProduct' }
        & Pick<PropertyInsuranceProduct, 'id' | 'shortName' | 'name' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'type' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'risksHousehold' | 'risksProperty'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )>, subLimitsHousehold: Array<(
          { __typename?: 'PropertyInsuranceSubLimit' }
          & Pick<PropertyInsuranceSubLimit, 'id' | 'label' | 'description' | 'value'>
        )>, faqs: Array<(
          { __typename?: 'FaqPreview' }
          & Pick<FaqPreview, 'id' | 'question' | 'answerMD' | 'hasDetail' | 'seoName'>
        )>, subLimitsProperty: Array<(
          { __typename?: 'PropertyInsuranceSubLimit' }
          & Pick<PropertyInsuranceSubLimit, 'id' | 'label' | 'description' | 'value'>
        )> }
      ) }
    )>, contactPerson: Maybe<(
      { __typename?: 'PropertyInsuranceContactPerson' }
      & Pick<PropertyInsuranceContactPerson, 'id' | 'agreementProcessPersonalData' | 'agreementTermsAndConditions' | 'email' | 'name' | 'phone' | 'surname'>
    )> }
  ), packages: Array<(
    { __typename?: 'PropertyInsuranceInsurancePackage' }
    & Pick<PropertyInsuranceInsurancePackage, 'id' | 'title' | 'description' | 'type' | 'lowestPrice'>
    & { productOffers: Array<(
      { __typename?: 'PropertyInsuranceProductOffer' }
      & Pick<PropertyInsuranceProductOffer, 'id' | 'uuid' | 'price' | 'insuranceType'>
      & { product: (
        { __typename?: 'PropertyInsuranceProduct' }
        & Pick<PropertyInsuranceProduct, 'id' | 'shortName' | 'name' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'type' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'risksHousehold' | 'risksProperty'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )>, subLimitsHousehold: Array<(
          { __typename?: 'PropertyInsuranceSubLimit' }
          & Pick<PropertyInsuranceSubLimit, 'id' | 'label' | 'description' | 'value'>
        )>, faqs: Array<(
          { __typename?: 'FaqPreview' }
          & Pick<FaqPreview, 'id' | 'question' | 'answerMD' | 'hasDetail' | 'seoName'>
        )>, subLimitsProperty: Array<(
          { __typename?: 'PropertyInsuranceSubLimit' }
          & Pick<PropertyInsuranceSubLimit, 'id' | 'label' | 'description' | 'value'>
        )> }
      ) }
    )> }
  )>, cheapestOffer: Maybe<(
    { __typename?: 'PropertyInsuranceProductOffer' }
    & Pick<PropertyInsuranceProductOffer, 'id' | 'uuid' | 'price' | 'insuranceType'>
    & { product: (
      { __typename?: 'PropertyInsuranceProduct' }
      & Pick<PropertyInsuranceProduct, 'id' | 'shortName' | 'name' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'type' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'risksHousehold' | 'risksProperty'>
      & { partner: (
        { __typename?: 'ZeteoInsurancePartner' }
        & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
        & { logo: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )>, logoSquare: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )>, rating2: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), productDetailFile: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )>, subLimitsHousehold: Array<(
        { __typename?: 'PropertyInsuranceSubLimit' }
        & Pick<PropertyInsuranceSubLimit, 'id' | 'label' | 'description' | 'value'>
      )>, faqs: Array<(
        { __typename?: 'FaqPreview' }
        & Pick<FaqPreview, 'id' | 'question' | 'answerMD' | 'hasDetail' | 'seoName'>
      )>, subLimitsProperty: Array<(
        { __typename?: 'PropertyInsuranceSubLimit' }
        & Pick<PropertyInsuranceSubLimit, 'id' | 'label' | 'description' | 'value'>
      )> }
    ) }
  )>, watchWolf: Maybe<(
    { __typename?: 'WatchWolfPreview' }
    & Pick<WatchWolfPreview, 'uuid' | 'name' | 'type' | 'phone' | 'preferredEmail' | 'minSaving' | 'commitmentEnd' | 'vertical' | 'subVertical' | 'createdAt'>
  )> }
);

export type WatchWolfPreviewFragment = (
  { __typename?: 'WatchWolfPreview' }
  & Pick<WatchWolfPreview, 'uuid' | 'name' | 'type' | 'phone' | 'preferredEmail' | 'minSaving' | 'commitmentEnd' | 'vertical' | 'subVertical' | 'createdAt'>
);

export type PropertyInsuranceCalculationStateFragment = (
  { __typename?: 'PropertyInsuranceCalculationState' }
  & Pick<PropertyInsuranceCalculationState, 'maximumSteps' | 'finishedSteps' | 'finished' | 'error'>
);

export type PropertyInsuranceCalculationStateResultFragment = (
  { __typename?: 'PropertyInsuranceCalculationStateResult' }
  & { state: (
    { __typename?: 'PropertyInsuranceCalculationState' }
    & Pick<PropertyInsuranceCalculationState, 'maximumSteps' | 'finishedSteps' | 'finished' | 'error'>
  ), result: Maybe<(
    { __typename?: 'PropertyInsuranceCalculationResult' }
    & Pick<PropertyInsuranceCalculationResult, 'id'>
    & { order: (
      { __typename?: 'PropertyInsuranceOrder' }
      & Pick<PropertyInsuranceOrder, 'uuid' | 'buildingPrice' | 'buildingSize' | 'buildingType' | 'groundFloorApartment' | 'householdPrice' | 'householdSize' | 'insuranceType' | 'insureSecondaryBuilding' | 'propertyTenantRelation' | 'resultEmail' | 'resultPhone' | 'createdAt' | 'updatedAt' | 'status'>
      & { insuredAddress: (
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      ), requirements: Maybe<(
        { __typename?: 'PropertyInsuranceRequirements' }
        & Pick<PropertyInsuranceRequirements, 'complementLiabilityCommonRequirement' | 'complementLiabilityPropertyRequirement' | 'floodInsuranceRequirement'>
      )>, riscIndicators: Maybe<(
        { __typename?: 'PropertyInsuranceRiscIndicators' }
        & Pick<PropertyInsuranceRiscIndicators, 'floodCount20YearsBuilding' | 'floodCount20YearsHouseHold'>
      )>, insuranceInfo: (
        { __typename?: 'PropertyInsuranceInfo' }
        & Pick<PropertyInsuranceInfo, 'sumInsuredBuilding' | 'sumInsuredHousehold'>
      ), selectedOffer: Maybe<(
        { __typename?: 'PropertyInsuranceProductOffer' }
        & Pick<PropertyInsuranceProductOffer, 'id' | 'uuid' | 'price' | 'insuranceType'>
        & { product: (
          { __typename?: 'PropertyInsuranceProduct' }
          & Pick<PropertyInsuranceProduct, 'id' | 'shortName' | 'name' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'type' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'risksHousehold' | 'risksProperty'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, subLimitsHousehold: Array<(
            { __typename?: 'PropertyInsuranceSubLimit' }
            & Pick<PropertyInsuranceSubLimit, 'id' | 'label' | 'description' | 'value'>
          )>, faqs: Array<(
            { __typename?: 'FaqPreview' }
            & Pick<FaqPreview, 'id' | 'question' | 'answerMD' | 'hasDetail' | 'seoName'>
          )>, subLimitsProperty: Array<(
            { __typename?: 'PropertyInsuranceSubLimit' }
            & Pick<PropertyInsuranceSubLimit, 'id' | 'label' | 'description' | 'value'>
          )> }
        ) }
      )>, contactPerson: Maybe<(
        { __typename?: 'PropertyInsuranceContactPerson' }
        & Pick<PropertyInsuranceContactPerson, 'id' | 'agreementProcessPersonalData' | 'agreementTermsAndConditions' | 'email' | 'name' | 'phone' | 'surname'>
      )> }
    ), packages: Array<(
      { __typename?: 'PropertyInsuranceInsurancePackage' }
      & Pick<PropertyInsuranceInsurancePackage, 'id' | 'title' | 'description' | 'type' | 'lowestPrice'>
      & { productOffers: Array<(
        { __typename?: 'PropertyInsuranceProductOffer' }
        & Pick<PropertyInsuranceProductOffer, 'id' | 'uuid' | 'price' | 'insuranceType'>
        & { product: (
          { __typename?: 'PropertyInsuranceProduct' }
          & Pick<PropertyInsuranceProduct, 'id' | 'shortName' | 'name' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'type' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'risksHousehold' | 'risksProperty'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, subLimitsHousehold: Array<(
            { __typename?: 'PropertyInsuranceSubLimit' }
            & Pick<PropertyInsuranceSubLimit, 'id' | 'label' | 'description' | 'value'>
          )>, faqs: Array<(
            { __typename?: 'FaqPreview' }
            & Pick<FaqPreview, 'id' | 'question' | 'answerMD' | 'hasDetail' | 'seoName'>
          )>, subLimitsProperty: Array<(
            { __typename?: 'PropertyInsuranceSubLimit' }
            & Pick<PropertyInsuranceSubLimit, 'id' | 'label' | 'description' | 'value'>
          )> }
        ) }
      )> }
    )>, cheapestOffer: Maybe<(
      { __typename?: 'PropertyInsuranceProductOffer' }
      & Pick<PropertyInsuranceProductOffer, 'id' | 'uuid' | 'price' | 'insuranceType'>
      & { product: (
        { __typename?: 'PropertyInsuranceProduct' }
        & Pick<PropertyInsuranceProduct, 'id' | 'shortName' | 'name' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'type' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'risksHousehold' | 'risksProperty'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )>, subLimitsHousehold: Array<(
          { __typename?: 'PropertyInsuranceSubLimit' }
          & Pick<PropertyInsuranceSubLimit, 'id' | 'label' | 'description' | 'value'>
        )>, faqs: Array<(
          { __typename?: 'FaqPreview' }
          & Pick<FaqPreview, 'id' | 'question' | 'answerMD' | 'hasDetail' | 'seoName'>
        )>, subLimitsProperty: Array<(
          { __typename?: 'PropertyInsuranceSubLimit' }
          & Pick<PropertyInsuranceSubLimit, 'id' | 'label' | 'description' | 'value'>
        )> }
      ) }
    )>, watchWolf: Maybe<(
      { __typename?: 'WatchWolfPreview' }
      & Pick<WatchWolfPreview, 'uuid' | 'name' | 'type' | 'phone' | 'preferredEmail' | 'minSaving' | 'commitmentEnd' | 'vertical' | 'subVertical' | 'createdAt'>
    )> }
  )> }
);

export type PropertyInsuranceInsurancePackageFragment = (
  { __typename?: 'PropertyInsuranceInsurancePackage' }
  & Pick<PropertyInsuranceInsurancePackage, 'id' | 'title' | 'description' | 'type' | 'lowestPrice'>
  & { productOffers: Array<(
    { __typename?: 'PropertyInsuranceProductOffer' }
    & Pick<PropertyInsuranceProductOffer, 'id' | 'uuid' | 'price' | 'insuranceType'>
    & { product: (
      { __typename?: 'PropertyInsuranceProduct' }
      & Pick<PropertyInsuranceProduct, 'id' | 'shortName' | 'name' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'type' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'risksHousehold' | 'risksProperty'>
      & { partner: (
        { __typename?: 'ZeteoInsurancePartner' }
        & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
        & { logo: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )>, logoSquare: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )>, rating2: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), productDetailFile: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )>, subLimitsHousehold: Array<(
        { __typename?: 'PropertyInsuranceSubLimit' }
        & Pick<PropertyInsuranceSubLimit, 'id' | 'label' | 'description' | 'value'>
      )>, faqs: Array<(
        { __typename?: 'FaqPreview' }
        & Pick<FaqPreview, 'id' | 'question' | 'answerMD' | 'hasDetail' | 'seoName'>
      )>, subLimitsProperty: Array<(
        { __typename?: 'PropertyInsuranceSubLimit' }
        & Pick<PropertyInsuranceSubLimit, 'id' | 'label' | 'description' | 'value'>
      )> }
    ) }
  )> }
);

export type PropertyInsuranceProductOfferFragment = (
  { __typename?: 'PropertyInsuranceProductOffer' }
  & Pick<PropertyInsuranceProductOffer, 'id' | 'uuid' | 'price' | 'insuranceType'>
  & { product: (
    { __typename?: 'PropertyInsuranceProduct' }
    & Pick<PropertyInsuranceProduct, 'id' | 'shortName' | 'name' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'type' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'risksHousehold' | 'risksProperty'>
    & { partner: (
      { __typename?: 'ZeteoInsurancePartner' }
      & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
      & { logo: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )>, logoSquare: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )>, rating2: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    ), productDetailFile: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    )>, subLimitsHousehold: Array<(
      { __typename?: 'PropertyInsuranceSubLimit' }
      & Pick<PropertyInsuranceSubLimit, 'id' | 'label' | 'description' | 'value'>
    )>, faqs: Array<(
      { __typename?: 'FaqPreview' }
      & Pick<FaqPreview, 'id' | 'question' | 'answerMD' | 'hasDetail' | 'seoName'>
    )>, subLimitsProperty: Array<(
      { __typename?: 'PropertyInsuranceSubLimit' }
      & Pick<PropertyInsuranceSubLimit, 'id' | 'label' | 'description' | 'value'>
    )> }
  ) }
);

export type PropertyInsuranceSubLimitFragment = (
  { __typename?: 'PropertyInsuranceSubLimit' }
  & Pick<PropertyInsuranceSubLimit, 'id' | 'label' | 'description' | 'value'>
);

export type PropertyInsuranceProductFragment = (
  { __typename?: 'PropertyInsuranceProduct' }
  & Pick<PropertyInsuranceProduct, 'id' | 'shortName' | 'name' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'type' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'risksHousehold' | 'risksProperty'>
  & { partner: (
    { __typename?: 'ZeteoInsurancePartner' }
    & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
    & { logo: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    )>, logoSquare: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    )>, rating2: Maybe<(
      { __typename?: 'NewRating' }
      & Pick<NewRating, 'id' | 'total'>
      & { categories: Array<Maybe<(
        { __typename?: 'RatingCategory' }
        & Pick<RatingCategory, 'id' | 'label' | 'value'>
      )>> }
    )> }
  ), productDetailFile: Maybe<(
    { __typename?: 'File' }
    & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
  )>, subLimitsHousehold: Array<(
    { __typename?: 'PropertyInsuranceSubLimit' }
    & Pick<PropertyInsuranceSubLimit, 'id' | 'label' | 'description' | 'value'>
  )>, faqs: Array<(
    { __typename?: 'FaqPreview' }
    & Pick<FaqPreview, 'id' | 'question' | 'answerMD' | 'hasDetail' | 'seoName'>
  )>, subLimitsProperty: Array<(
    { __typename?: 'PropertyInsuranceSubLimit' }
    & Pick<PropertyInsuranceSubLimit, 'id' | 'label' | 'description' | 'value'>
  )> }
);

export type InitPropertyInsuranceOrderMutationVariables = Exact<{
  data: PropertyInsuranceOrderInitInput;
}>;


export type InitPropertyInsuranceOrderMutation = (
  { __typename?: 'Mutation' }
  & { order: (
    { __typename?: 'PropertyInsuranceOrder' }
    & Pick<PropertyInsuranceOrder, 'uuid' | 'buildingPrice' | 'buildingSize' | 'buildingType' | 'groundFloorApartment' | 'householdPrice' | 'householdSize' | 'insuranceType' | 'insureSecondaryBuilding' | 'propertyTenantRelation' | 'resultEmail' | 'resultPhone' | 'createdAt' | 'updatedAt' | 'status'>
    & { insuredAddress: (
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
      & { extended: Maybe<(
        { __typename?: 'SmartAddressExtendedInfo' }
        & Pick<SmartAddressExtendedInfo, 'id'>
        & { objectType: Maybe<(
          { __typename?: 'SmartAddressObjectType' }
          & Pick<SmartAddressObjectType, 'id' | 'nazev'>
        )> }
      )>, extendedV2: Maybe<(
        { __typename?: 'SmartAddressSdkExtendedInfo' }
        & { administrativniUdaje: Maybe<(
          { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
          & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )> }
      )> }
    ), requirements: Maybe<(
      { __typename?: 'PropertyInsuranceRequirements' }
      & Pick<PropertyInsuranceRequirements, 'complementLiabilityCommonRequirement' | 'complementLiabilityPropertyRequirement' | 'floodInsuranceRequirement'>
    )>, riscIndicators: Maybe<(
      { __typename?: 'PropertyInsuranceRiscIndicators' }
      & Pick<PropertyInsuranceRiscIndicators, 'floodCount20YearsBuilding' | 'floodCount20YearsHouseHold'>
    )>, insuranceInfo: (
      { __typename?: 'PropertyInsuranceInfo' }
      & Pick<PropertyInsuranceInfo, 'sumInsuredBuilding' | 'sumInsuredHousehold'>
    ), selectedOffer: Maybe<(
      { __typename?: 'PropertyInsuranceProductOffer' }
      & Pick<PropertyInsuranceProductOffer, 'id' | 'uuid' | 'price' | 'insuranceType'>
      & { product: (
        { __typename?: 'PropertyInsuranceProduct' }
        & Pick<PropertyInsuranceProduct, 'id' | 'shortName' | 'name' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'type' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'risksHousehold' | 'risksProperty'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )>, subLimitsHousehold: Array<(
          { __typename?: 'PropertyInsuranceSubLimit' }
          & Pick<PropertyInsuranceSubLimit, 'id' | 'label' | 'description' | 'value'>
        )>, faqs: Array<(
          { __typename?: 'FaqPreview' }
          & Pick<FaqPreview, 'id' | 'question' | 'answerMD' | 'hasDetail' | 'seoName'>
        )>, subLimitsProperty: Array<(
          { __typename?: 'PropertyInsuranceSubLimit' }
          & Pick<PropertyInsuranceSubLimit, 'id' | 'label' | 'description' | 'value'>
        )> }
      ) }
    )>, contactPerson: Maybe<(
      { __typename?: 'PropertyInsuranceContactPerson' }
      & Pick<PropertyInsuranceContactPerson, 'id' | 'agreementProcessPersonalData' | 'agreementTermsAndConditions' | 'email' | 'name' | 'phone' | 'surname'>
    )> }
  ) }
);

export type UpdatePropertyInsuranceOrderMutationVariables = Exact<{
  uuid: Scalars['String']['input'];
  data: PropertyInsuranceOrderUpdateInput;
}>;


export type UpdatePropertyInsuranceOrderMutation = (
  { __typename?: 'Mutation' }
  & { order: (
    { __typename?: 'PropertyInsuranceOrder' }
    & Pick<PropertyInsuranceOrder, 'uuid' | 'buildingPrice' | 'buildingSize' | 'buildingType' | 'groundFloorApartment' | 'householdPrice' | 'householdSize' | 'insuranceType' | 'insureSecondaryBuilding' | 'propertyTenantRelation' | 'resultEmail' | 'resultPhone' | 'createdAt' | 'updatedAt' | 'status'>
    & { insuredAddress: (
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
      & { extended: Maybe<(
        { __typename?: 'SmartAddressExtendedInfo' }
        & Pick<SmartAddressExtendedInfo, 'id'>
        & { objectType: Maybe<(
          { __typename?: 'SmartAddressObjectType' }
          & Pick<SmartAddressObjectType, 'id' | 'nazev'>
        )> }
      )>, extendedV2: Maybe<(
        { __typename?: 'SmartAddressSdkExtendedInfo' }
        & { administrativniUdaje: Maybe<(
          { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
          & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )> }
      )> }
    ), requirements: Maybe<(
      { __typename?: 'PropertyInsuranceRequirements' }
      & Pick<PropertyInsuranceRequirements, 'complementLiabilityCommonRequirement' | 'complementLiabilityPropertyRequirement' | 'floodInsuranceRequirement'>
    )>, riscIndicators: Maybe<(
      { __typename?: 'PropertyInsuranceRiscIndicators' }
      & Pick<PropertyInsuranceRiscIndicators, 'floodCount20YearsBuilding' | 'floodCount20YearsHouseHold'>
    )>, insuranceInfo: (
      { __typename?: 'PropertyInsuranceInfo' }
      & Pick<PropertyInsuranceInfo, 'sumInsuredBuilding' | 'sumInsuredHousehold'>
    ), selectedOffer: Maybe<(
      { __typename?: 'PropertyInsuranceProductOffer' }
      & Pick<PropertyInsuranceProductOffer, 'id' | 'uuid' | 'price' | 'insuranceType'>
      & { product: (
        { __typename?: 'PropertyInsuranceProduct' }
        & Pick<PropertyInsuranceProduct, 'id' | 'shortName' | 'name' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'type' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'risksHousehold' | 'risksProperty'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )>, subLimitsHousehold: Array<(
          { __typename?: 'PropertyInsuranceSubLimit' }
          & Pick<PropertyInsuranceSubLimit, 'id' | 'label' | 'description' | 'value'>
        )>, faqs: Array<(
          { __typename?: 'FaqPreview' }
          & Pick<FaqPreview, 'id' | 'question' | 'answerMD' | 'hasDetail' | 'seoName'>
        )>, subLimitsProperty: Array<(
          { __typename?: 'PropertyInsuranceSubLimit' }
          & Pick<PropertyInsuranceSubLimit, 'id' | 'label' | 'description' | 'value'>
        )> }
      ) }
    )>, contactPerson: Maybe<(
      { __typename?: 'PropertyInsuranceContactPerson' }
      & Pick<PropertyInsuranceContactPerson, 'id' | 'agreementProcessPersonalData' | 'agreementTermsAndConditions' | 'email' | 'name' | 'phone' | 'surname'>
    )> }
  ) }
);

export type StartPropertyInsuranceCalculationMutationVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;


export type StartPropertyInsuranceCalculationMutation = (
  { __typename?: 'Mutation' }
  & { state: (
    { __typename?: 'CoreStatus' }
    & Pick<CoreStatus, 'success'>
  ) }
);

export type SelectPropertyInsuranceOfferMutationVariables = Exact<{
  uuid: Scalars['String']['input'];
  data: PropertyInsuranceOrderOfferSelectInput;
}>;


export type SelectPropertyInsuranceOfferMutation = (
  { __typename?: 'Mutation' }
  & { order: (
    { __typename?: 'PropertyInsuranceOrder' }
    & Pick<PropertyInsuranceOrder, 'uuid' | 'buildingPrice' | 'buildingSize' | 'buildingType' | 'groundFloorApartment' | 'householdPrice' | 'householdSize' | 'insuranceType' | 'insureSecondaryBuilding' | 'propertyTenantRelation' | 'resultEmail' | 'resultPhone' | 'createdAt' | 'updatedAt' | 'status'>
    & { insuredAddress: (
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
      & { extended: Maybe<(
        { __typename?: 'SmartAddressExtendedInfo' }
        & Pick<SmartAddressExtendedInfo, 'id'>
        & { objectType: Maybe<(
          { __typename?: 'SmartAddressObjectType' }
          & Pick<SmartAddressObjectType, 'id' | 'nazev'>
        )> }
      )>, extendedV2: Maybe<(
        { __typename?: 'SmartAddressSdkExtendedInfo' }
        & { administrativniUdaje: Maybe<(
          { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
          & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )> }
      )> }
    ), requirements: Maybe<(
      { __typename?: 'PropertyInsuranceRequirements' }
      & Pick<PropertyInsuranceRequirements, 'complementLiabilityCommonRequirement' | 'complementLiabilityPropertyRequirement' | 'floodInsuranceRequirement'>
    )>, riscIndicators: Maybe<(
      { __typename?: 'PropertyInsuranceRiscIndicators' }
      & Pick<PropertyInsuranceRiscIndicators, 'floodCount20YearsBuilding' | 'floodCount20YearsHouseHold'>
    )>, insuranceInfo: (
      { __typename?: 'PropertyInsuranceInfo' }
      & Pick<PropertyInsuranceInfo, 'sumInsuredBuilding' | 'sumInsuredHousehold'>
    ), selectedOffer: Maybe<(
      { __typename?: 'PropertyInsuranceProductOffer' }
      & Pick<PropertyInsuranceProductOffer, 'id' | 'uuid' | 'price' | 'insuranceType'>
      & { product: (
        { __typename?: 'PropertyInsuranceProduct' }
        & Pick<PropertyInsuranceProduct, 'id' | 'shortName' | 'name' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'type' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'risksHousehold' | 'risksProperty'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )>, subLimitsHousehold: Array<(
          { __typename?: 'PropertyInsuranceSubLimit' }
          & Pick<PropertyInsuranceSubLimit, 'id' | 'label' | 'description' | 'value'>
        )>, faqs: Array<(
          { __typename?: 'FaqPreview' }
          & Pick<FaqPreview, 'id' | 'question' | 'answerMD' | 'hasDetail' | 'seoName'>
        )>, subLimitsProperty: Array<(
          { __typename?: 'PropertyInsuranceSubLimit' }
          & Pick<PropertyInsuranceSubLimit, 'id' | 'label' | 'description' | 'value'>
        )> }
      ) }
    )>, contactPerson: Maybe<(
      { __typename?: 'PropertyInsuranceContactPerson' }
      & Pick<PropertyInsuranceContactPerson, 'id' | 'agreementProcessPersonalData' | 'agreementTermsAndConditions' | 'email' | 'name' | 'phone' | 'surname'>
    )> }
  ) }
);

export type CreatePropertyInsuranceInquiryMutationVariables = Exact<{
  uuid: Scalars['String']['input'];
  data: PropertyInsuranceContactPersonInput;
}>;


export type CreatePropertyInsuranceInquiryMutation = (
  { __typename?: 'Mutation' }
  & { state: (
    { __typename?: 'PropertyInsuranceOrder' }
    & Pick<PropertyInsuranceOrder, 'uuid' | 'buildingPrice' | 'buildingSize' | 'buildingType' | 'groundFloorApartment' | 'householdPrice' | 'householdSize' | 'insuranceType' | 'insureSecondaryBuilding' | 'propertyTenantRelation' | 'resultEmail' | 'resultPhone' | 'createdAt' | 'updatedAt' | 'status'>
    & { insuredAddress: (
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
      & { extended: Maybe<(
        { __typename?: 'SmartAddressExtendedInfo' }
        & Pick<SmartAddressExtendedInfo, 'id'>
        & { objectType: Maybe<(
          { __typename?: 'SmartAddressObjectType' }
          & Pick<SmartAddressObjectType, 'id' | 'nazev'>
        )> }
      )>, extendedV2: Maybe<(
        { __typename?: 'SmartAddressSdkExtendedInfo' }
        & { administrativniUdaje: Maybe<(
          { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
          & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )> }
      )> }
    ), requirements: Maybe<(
      { __typename?: 'PropertyInsuranceRequirements' }
      & Pick<PropertyInsuranceRequirements, 'complementLiabilityCommonRequirement' | 'complementLiabilityPropertyRequirement' | 'floodInsuranceRequirement'>
    )>, riscIndicators: Maybe<(
      { __typename?: 'PropertyInsuranceRiscIndicators' }
      & Pick<PropertyInsuranceRiscIndicators, 'floodCount20YearsBuilding' | 'floodCount20YearsHouseHold'>
    )>, insuranceInfo: (
      { __typename?: 'PropertyInsuranceInfo' }
      & Pick<PropertyInsuranceInfo, 'sumInsuredBuilding' | 'sumInsuredHousehold'>
    ), selectedOffer: Maybe<(
      { __typename?: 'PropertyInsuranceProductOffer' }
      & Pick<PropertyInsuranceProductOffer, 'id' | 'uuid' | 'price' | 'insuranceType'>
      & { product: (
        { __typename?: 'PropertyInsuranceProduct' }
        & Pick<PropertyInsuranceProduct, 'id' | 'shortName' | 'name' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'type' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'risksHousehold' | 'risksProperty'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )>, subLimitsHousehold: Array<(
          { __typename?: 'PropertyInsuranceSubLimit' }
          & Pick<PropertyInsuranceSubLimit, 'id' | 'label' | 'description' | 'value'>
        )>, faqs: Array<(
          { __typename?: 'FaqPreview' }
          & Pick<FaqPreview, 'id' | 'question' | 'answerMD' | 'hasDetail' | 'seoName'>
        )>, subLimitsProperty: Array<(
          { __typename?: 'PropertyInsuranceSubLimit' }
          & Pick<PropertyInsuranceSubLimit, 'id' | 'label' | 'description' | 'value'>
        )> }
      ) }
    )>, contactPerson: Maybe<(
      { __typename?: 'PropertyInsuranceContactPerson' }
      & Pick<PropertyInsuranceContactPerson, 'id' | 'agreementProcessPersonalData' | 'agreementTermsAndConditions' | 'email' | 'name' | 'phone' | 'surname'>
    )> }
  ) }
);

export type GetPropertyInsuranceOrderQueryVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;


export type GetPropertyInsuranceOrderQuery = (
  { __typename?: 'Query' }
  & { order: (
    { __typename?: 'PropertyInsuranceOrder' }
    & Pick<PropertyInsuranceOrder, 'uuid' | 'buildingPrice' | 'buildingSize' | 'buildingType' | 'groundFloorApartment' | 'householdPrice' | 'householdSize' | 'insuranceType' | 'insureSecondaryBuilding' | 'propertyTenantRelation' | 'resultEmail' | 'resultPhone' | 'createdAt' | 'updatedAt' | 'status'>
    & { insuredAddress: (
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
      & { extended: Maybe<(
        { __typename?: 'SmartAddressExtendedInfo' }
        & Pick<SmartAddressExtendedInfo, 'id'>
        & { objectType: Maybe<(
          { __typename?: 'SmartAddressObjectType' }
          & Pick<SmartAddressObjectType, 'id' | 'nazev'>
        )> }
      )>, extendedV2: Maybe<(
        { __typename?: 'SmartAddressSdkExtendedInfo' }
        & { administrativniUdaje: Maybe<(
          { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
          & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )> }
      )> }
    ), requirements: Maybe<(
      { __typename?: 'PropertyInsuranceRequirements' }
      & Pick<PropertyInsuranceRequirements, 'complementLiabilityCommonRequirement' | 'complementLiabilityPropertyRequirement' | 'floodInsuranceRequirement'>
    )>, riscIndicators: Maybe<(
      { __typename?: 'PropertyInsuranceRiscIndicators' }
      & Pick<PropertyInsuranceRiscIndicators, 'floodCount20YearsBuilding' | 'floodCount20YearsHouseHold'>
    )>, insuranceInfo: (
      { __typename?: 'PropertyInsuranceInfo' }
      & Pick<PropertyInsuranceInfo, 'sumInsuredBuilding' | 'sumInsuredHousehold'>
    ), selectedOffer: Maybe<(
      { __typename?: 'PropertyInsuranceProductOffer' }
      & Pick<PropertyInsuranceProductOffer, 'id' | 'uuid' | 'price' | 'insuranceType'>
      & { product: (
        { __typename?: 'PropertyInsuranceProduct' }
        & Pick<PropertyInsuranceProduct, 'id' | 'shortName' | 'name' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'type' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'risksHousehold' | 'risksProperty'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )>, subLimitsHousehold: Array<(
          { __typename?: 'PropertyInsuranceSubLimit' }
          & Pick<PropertyInsuranceSubLimit, 'id' | 'label' | 'description' | 'value'>
        )>, faqs: Array<(
          { __typename?: 'FaqPreview' }
          & Pick<FaqPreview, 'id' | 'question' | 'answerMD' | 'hasDetail' | 'seoName'>
        )>, subLimitsProperty: Array<(
          { __typename?: 'PropertyInsuranceSubLimit' }
          & Pick<PropertyInsuranceSubLimit, 'id' | 'label' | 'description' | 'value'>
        )> }
      ) }
    )>, contactPerson: Maybe<(
      { __typename?: 'PropertyInsuranceContactPerson' }
      & Pick<PropertyInsuranceContactPerson, 'id' | 'agreementProcessPersonalData' | 'agreementTermsAndConditions' | 'email' | 'name' | 'phone' | 'surname'>
    )> }
  ) }
);

export type GetPropertyInsuranceCalculationResultQueryVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;


export type GetPropertyInsuranceCalculationResultQuery = (
  { __typename?: 'Query' }
  & { state: (
    { __typename?: 'PropertyInsuranceCalculationStateResult' }
    & { state: (
      { __typename?: 'PropertyInsuranceCalculationState' }
      & Pick<PropertyInsuranceCalculationState, 'maximumSteps' | 'finishedSteps' | 'finished' | 'error'>
    ), result: Maybe<(
      { __typename?: 'PropertyInsuranceCalculationResult' }
      & Pick<PropertyInsuranceCalculationResult, 'id'>
      & { order: (
        { __typename?: 'PropertyInsuranceOrder' }
        & Pick<PropertyInsuranceOrder, 'uuid' | 'buildingPrice' | 'buildingSize' | 'buildingType' | 'groundFloorApartment' | 'householdPrice' | 'householdSize' | 'insuranceType' | 'insureSecondaryBuilding' | 'propertyTenantRelation' | 'resultEmail' | 'resultPhone' | 'createdAt' | 'updatedAt' | 'status'>
        & { insuredAddress: (
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        ), requirements: Maybe<(
          { __typename?: 'PropertyInsuranceRequirements' }
          & Pick<PropertyInsuranceRequirements, 'complementLiabilityCommonRequirement' | 'complementLiabilityPropertyRequirement' | 'floodInsuranceRequirement'>
        )>, riscIndicators: Maybe<(
          { __typename?: 'PropertyInsuranceRiscIndicators' }
          & Pick<PropertyInsuranceRiscIndicators, 'floodCount20YearsBuilding' | 'floodCount20YearsHouseHold'>
        )>, insuranceInfo: (
          { __typename?: 'PropertyInsuranceInfo' }
          & Pick<PropertyInsuranceInfo, 'sumInsuredBuilding' | 'sumInsuredHousehold'>
        ), selectedOffer: Maybe<(
          { __typename?: 'PropertyInsuranceProductOffer' }
          & Pick<PropertyInsuranceProductOffer, 'id' | 'uuid' | 'price' | 'insuranceType'>
          & { product: (
            { __typename?: 'PropertyInsuranceProduct' }
            & Pick<PropertyInsuranceProduct, 'id' | 'shortName' | 'name' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'type' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'risksHousehold' | 'risksProperty'>
            & { partner: (
              { __typename?: 'ZeteoInsurancePartner' }
              & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
              & { logo: Maybe<(
                { __typename?: 'File' }
                & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
              )>, logoSquare: Maybe<(
                { __typename?: 'File' }
                & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
              )>, rating2: Maybe<(
                { __typename?: 'NewRating' }
                & Pick<NewRating, 'id' | 'total'>
                & { categories: Array<Maybe<(
                  { __typename?: 'RatingCategory' }
                  & Pick<RatingCategory, 'id' | 'label' | 'value'>
                )>> }
              )> }
            ), productDetailFile: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, subLimitsHousehold: Array<(
              { __typename?: 'PropertyInsuranceSubLimit' }
              & Pick<PropertyInsuranceSubLimit, 'id' | 'label' | 'description' | 'value'>
            )>, faqs: Array<(
              { __typename?: 'FaqPreview' }
              & Pick<FaqPreview, 'id' | 'question' | 'answerMD' | 'hasDetail' | 'seoName'>
            )>, subLimitsProperty: Array<(
              { __typename?: 'PropertyInsuranceSubLimit' }
              & Pick<PropertyInsuranceSubLimit, 'id' | 'label' | 'description' | 'value'>
            )> }
          ) }
        )>, contactPerson: Maybe<(
          { __typename?: 'PropertyInsuranceContactPerson' }
          & Pick<PropertyInsuranceContactPerson, 'id' | 'agreementProcessPersonalData' | 'agreementTermsAndConditions' | 'email' | 'name' | 'phone' | 'surname'>
        )> }
      ), packages: Array<(
        { __typename?: 'PropertyInsuranceInsurancePackage' }
        & Pick<PropertyInsuranceInsurancePackage, 'id' | 'title' | 'description' | 'type' | 'lowestPrice'>
        & { productOffers: Array<(
          { __typename?: 'PropertyInsuranceProductOffer' }
          & Pick<PropertyInsuranceProductOffer, 'id' | 'uuid' | 'price' | 'insuranceType'>
          & { product: (
            { __typename?: 'PropertyInsuranceProduct' }
            & Pick<PropertyInsuranceProduct, 'id' | 'shortName' | 'name' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'type' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'risksHousehold' | 'risksProperty'>
            & { partner: (
              { __typename?: 'ZeteoInsurancePartner' }
              & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
              & { logo: Maybe<(
                { __typename?: 'File' }
                & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
              )>, logoSquare: Maybe<(
                { __typename?: 'File' }
                & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
              )>, rating2: Maybe<(
                { __typename?: 'NewRating' }
                & Pick<NewRating, 'id' | 'total'>
                & { categories: Array<Maybe<(
                  { __typename?: 'RatingCategory' }
                  & Pick<RatingCategory, 'id' | 'label' | 'value'>
                )>> }
              )> }
            ), productDetailFile: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, subLimitsHousehold: Array<(
              { __typename?: 'PropertyInsuranceSubLimit' }
              & Pick<PropertyInsuranceSubLimit, 'id' | 'label' | 'description' | 'value'>
            )>, faqs: Array<(
              { __typename?: 'FaqPreview' }
              & Pick<FaqPreview, 'id' | 'question' | 'answerMD' | 'hasDetail' | 'seoName'>
            )>, subLimitsProperty: Array<(
              { __typename?: 'PropertyInsuranceSubLimit' }
              & Pick<PropertyInsuranceSubLimit, 'id' | 'label' | 'description' | 'value'>
            )> }
          ) }
        )> }
      )>, cheapestOffer: Maybe<(
        { __typename?: 'PropertyInsuranceProductOffer' }
        & Pick<PropertyInsuranceProductOffer, 'id' | 'uuid' | 'price' | 'insuranceType'>
        & { product: (
          { __typename?: 'PropertyInsuranceProduct' }
          & Pick<PropertyInsuranceProduct, 'id' | 'shortName' | 'name' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'type' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'risksHousehold' | 'risksProperty'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, subLimitsHousehold: Array<(
            { __typename?: 'PropertyInsuranceSubLimit' }
            & Pick<PropertyInsuranceSubLimit, 'id' | 'label' | 'description' | 'value'>
          )>, faqs: Array<(
            { __typename?: 'FaqPreview' }
            & Pick<FaqPreview, 'id' | 'question' | 'answerMD' | 'hasDetail' | 'seoName'>
          )>, subLimitsProperty: Array<(
            { __typename?: 'PropertyInsuranceSubLimit' }
            & Pick<PropertyInsuranceSubLimit, 'id' | 'label' | 'description' | 'value'>
          )> }
        ) }
      )>, watchWolf: Maybe<(
        { __typename?: 'WatchWolfPreview' }
        & Pick<WatchWolfPreview, 'uuid' | 'name' | 'type' | 'phone' | 'preferredEmail' | 'minSaving' | 'commitmentEnd' | 'vertical' | 'subVertical' | 'createdAt'>
      )> }
    )> }
  ) }
);

export type AresDataFragment = (
  { __typename?: 'AresData' }
  & Pick<AresData, 'ico' | 'name' | 'smartAddressId' | 'vatPayer' | 'vatNumber' | 'firstname' | 'surname'>
);

export type PublicEnergoCalculationLinkMutationVariables = Exact<{
  calculationUuid: Scalars['String']['input'];
  profileUuid: Scalars['String']['input'];
}>;


export type PublicEnergoCalculationLinkMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'publicEnergoCalculationLink'>
);

export type PublicVehicleInsuranceCalculationLinkMutationVariables = Exact<{
  calculationUuid: Scalars['String']['input'];
  profileUuid: Scalars['String']['input'];
}>;


export type PublicVehicleInsuranceCalculationLinkMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'vehicleInsurancePublicCalculationLink'>
);

export type GetAresDataQueryVariables = Exact<{
  ico: Scalars['String']['input'];
}>;


export type GetAresDataQuery = (
  { __typename?: 'Query' }
  & { getAresData: (
    { __typename?: 'AresData' }
    & Pick<AresData, 'ico' | 'name' | 'smartAddressId' | 'vatPayer' | 'vatNumber' | 'firstname' | 'surname'>
  ) }
);

export type RatingCategoryFragment = (
  { __typename?: 'RatingCategory' }
  & Pick<RatingCategory, 'id' | 'label' | 'value'>
);

export type NewRatingFragment = (
  { __typename?: 'NewRating' }
  & Pick<NewRating, 'id' | 'total'>
  & { categories: Array<Maybe<(
    { __typename?: 'RatingCategory' }
    & Pick<RatingCategory, 'id' | 'label' | 'value'>
  )>> }
);

export type SmartAddressFragment = (
  { __typename?: 'SmartAddress' }
  & Pick<SmartAddress, 'id' | 'level'>
  & { street: SmartAddress['ulice'], city: SmartAddress['obec'], zip: SmartAddress['psc'] }
  & { gps: (
    { __typename?: 'Gps' }
    & Pick<Gps, 'latitude' | 'longitude'>
  ) }
);

export type NewSmartAddressFragment = (
  { __typename?: 'NewSmartAddress' }
  & Pick<NewSmartAddress, 'id' | 'street' | 'city' | 'zip' | 'level'>
  & { gps: Maybe<(
    { __typename?: 'Gps' }
    & Pick<Gps, 'latitude' | 'longitude'>
  )> }
);

export type SmartAddressExtendedInfoFragment = (
  { __typename?: 'SmartAddressExtendedInfo' }
  & Pick<SmartAddressExtendedInfo, 'id'>
  & { objectType: Maybe<(
    { __typename?: 'SmartAddressObjectType' }
    & Pick<SmartAddressObjectType, 'id' | 'nazev'>
  )> }
);

export type SmartAddressObjectTypeFragment = (
  { __typename?: 'SmartAddressObjectType' }
  & Pick<SmartAddressObjectType, 'id' | 'nazev'>
);

export type SmartAddressSdkExtendedInfoFragment = (
  { __typename?: 'SmartAddressSdkExtendedInfo' }
  & { administrativniUdaje: Maybe<(
    { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
    & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
    & { objectType: Maybe<(
      { __typename?: 'SmartAddressObjectType' }
      & Pick<SmartAddressObjectType, 'id' | 'nazev'>
    )> }
  )> }
);

export type SmartAddressAddressExtendedAdministrativeResponseFragment = (
  { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
  & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
  & { objectType: Maybe<(
    { __typename?: 'SmartAddressObjectType' }
    & Pick<SmartAddressObjectType, 'id' | 'nazev'>
  )> }
);

export type LocationAutocompleteQueryVariables = Exact<{
  term: Scalars['String']['input'];
  level: InputMaybe<Scalars['Int']['input']>;
  zip: InputMaybe<Scalars['Int']['input']>;
}>;


export type LocationAutocompleteQuery = (
  { __typename?: 'Query' }
  & { locationAutocomplete: Array<(
    { __typename?: 'SmartAddress' }
    & Pick<SmartAddress, 'id' | 'level'>
    & { street: SmartAddress['ulice'], city: SmartAddress['obec'], zip: SmartAddress['psc'] }
    & { gps: (
      { __typename?: 'Gps' }
      & Pick<Gps, 'latitude' | 'longitude'>
    ) }
  )> }
);

export type ZipAutocompleteQueryVariables = Exact<{
  term: Scalars['String']['input'];
}>;


export type ZipAutocompleteQuery = (
  { __typename?: 'Query' }
  & { zipAutocomplete: Array<(
    { __typename?: 'SmartAddress' }
    & Pick<SmartAddress, 'id' | 'level'>
    & { street: SmartAddress['ulice'], city: SmartAddress['obec'], zip: SmartAddress['psc'] }
    & { gps: (
      { __typename?: 'Gps' }
      & Pick<Gps, 'latitude' | 'longitude'>
    ) }
  )> }
);

export type LocationFindQueryVariables = Exact<{
  smartAddressId: Scalars['Int']['input'];
}>;


export type LocationFindQuery = (
  { __typename?: 'Query' }
  & { locationFind: Maybe<(
    { __typename?: 'SmartAddress' }
    & Pick<SmartAddress, 'id' | 'level'>
    & { street: SmartAddress['ulice'], city: SmartAddress['obec'], zip: SmartAddress['psc'] }
    & { gps: (
      { __typename?: 'Gps' }
      & Pick<Gps, 'latitude' | 'longitude'>
    ) }
  )> }
);

export type LocationExtendedInfoQueryVariables = Exact<{
  smartAddressId: Scalars['Int']['input'];
}>;


export type LocationExtendedInfoQuery = (
  { __typename?: 'Query' }
  & { locationExtendedInfo: Maybe<(
    { __typename?: 'SmartAddressExtendedInfo' }
    & Pick<SmartAddressExtendedInfo, 'id'>
    & { objectType: Maybe<(
      { __typename?: 'SmartAddressObjectType' }
      & Pick<SmartAddressObjectType, 'id' | 'nazev'>
    )> }
  )> }
);

export type LocationFindWithExtendedInfoQueryVariables = Exact<{
  smartAddressId: Scalars['Int']['input'];
}>;


export type LocationFindWithExtendedInfoQuery = (
  { __typename?: 'Query' }
  & { location: Maybe<(
    { __typename?: 'SmartAddress' }
    & Pick<SmartAddress, 'id' | 'level'>
    & { street: SmartAddress['ulice'], city: SmartAddress['obec'], zip: SmartAddress['psc'] }
    & { gps: (
      { __typename?: 'Gps' }
      & Pick<Gps, 'latitude' | 'longitude'>
    ) }
  )>, extended: Maybe<(
    { __typename?: 'SmartAddressExtendedInfo' }
    & Pick<SmartAddressExtendedInfo, 'id'>
    & { objectType: Maybe<(
      { __typename?: 'SmartAddressObjectType' }
      & Pick<SmartAddressObjectType, 'id' | 'nazev'>
    )> }
  )> }
);

export type UploadFileMutationVariables = Exact<{
  namespace: InputMaybe<Scalars['String']['input']>;
  file: FileInput;
}>;


export type UploadFileMutation = (
  { __typename?: 'Mutation' }
  & { uploadFile: (
    { __typename?: 'File' }
    & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
  ) }
);

export type TelcoCalculationFragment = (
  { __typename?: 'TelcoCalculation' }
  & Pick<TelcoCalculation, 'id' | 'uuid' | 'email' | 'phone' | 'interests'>
  & { mobile: Maybe<(
    { __typename?: 'TelcoCalculationMobile' }
    & Pick<TelcoCalculationMobile, 'id' | 'includedMinutes' | 'includedData' | 'isStudent' | 'isSenior' | 'isBusiness' | 'hasMultiplePhoneNumbers'>
  )>, internet: Maybe<(
    { __typename?: 'TelcoCalculationInternet' }
    & Pick<TelcoCalculationInternet, 'id' | 'typeOfUser' | 'speed'>
  )>, tv: Maybe<(
    { __typename?: 'TelcoCalculationTv' }
    & Pick<TelcoCalculationTv, 'id' | 'programTypes' | 'programOffer'>
  )> }
);

export type TelcoCalculationMobileFragment = (
  { __typename?: 'TelcoCalculationMobile' }
  & Pick<TelcoCalculationMobile, 'id' | 'includedMinutes' | 'includedData' | 'isStudent' | 'isSenior' | 'isBusiness' | 'hasMultiplePhoneNumbers'>
);

export type TelcoCalculationInternetFragment = (
  { __typename?: 'TelcoCalculationInternet' }
  & Pick<TelcoCalculationInternet, 'id' | 'typeOfUser' | 'speed'>
);

export type TelcoCalculationTvFragment = (
  { __typename?: 'TelcoCalculationTv' }
  & Pick<TelcoCalculationTv, 'id' | 'programTypes' | 'programOffer'>
);

export type TelcoPartnerPreviewFragment = (
  { __typename?: 'TelcoPartner' }
  & Pick<TelcoPartner, 'id' | 'name'>
  & { logo: Maybe<(
    { __typename?: 'Image' }
    & Pick<Image, 'id' | 'name' | 'uuid' | 'type' | 'createdAt' | 'extension' | 'height' | 'namespace' | 'size' | 'width'>
  )>, logoSquare: Maybe<(
    { __typename?: 'Image' }
    & Pick<Image, 'id' | 'name' | 'uuid' | 'type' | 'createdAt' | 'extension' | 'height' | 'namespace' | 'size' | 'width'>
  )> }
);

export type InternetProductLineFragment = (
  { __typename?: 'InternetProductLine' }
  & Pick<InternetProductLine, 'id' | 'name'>
  & { partner: (
    { __typename?: 'TelcoPartner' }
    & Pick<TelcoPartner, 'id' | 'name'>
    & { logo: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'id' | 'name' | 'uuid' | 'type' | 'createdAt' | 'extension' | 'height' | 'namespace' | 'size' | 'width'>
    )>, logoSquare: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'id' | 'name' | 'uuid' | 'type' | 'createdAt' | 'extension' | 'height' | 'namespace' | 'size' | 'width'>
    )> }
  ) }
);

export type TelcoTagFragment = (
  { __typename?: 'TelcoTag' }
  & Pick<TelcoTag, 'label' | 'tooltip'>
);

export type InternetProductFragment = (
  { __typename?: 'InternetProduct' }
  & Pick<InternetProduct, 'id' | 'name' | 'description' | 'downloadSpeed' | 'uploadSpeed' | 'technology' | 'commitment' | 'device'>
  & { priceDevice: Maybe<(
    { __typename?: 'Money' }
    & Pick<Money, 'currency' | 'amount'>
  )>, priceStandard: (
    { __typename?: 'Money' }
    & Pick<Money, 'currency' | 'amount'>
  ), priceForCombination: Maybe<(
    { __typename?: 'Money' }
    & Pick<Money, 'currency' | 'amount'>
  )>, internetProductLine: (
    { __typename?: 'InternetProductLine' }
    & Pick<InternetProductLine, 'id' | 'name'>
    & { partner: (
      { __typename?: 'TelcoPartner' }
      & Pick<TelcoPartner, 'id' | 'name'>
      & { logo: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'id' | 'name' | 'uuid' | 'type' | 'createdAt' | 'extension' | 'height' | 'namespace' | 'size' | 'width'>
      )>, logoSquare: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'id' | 'name' | 'uuid' | 'type' | 'createdAt' | 'extension' | 'height' | 'namespace' | 'size' | 'width'>
      )> }
    ) }
  ), tags: Array<(
    { __typename?: 'TelcoTag' }
    & Pick<TelcoTag, 'label' | 'tooltip'>
  )> }
);

export type MobileProductLineFragment = (
  { __typename?: 'MobileProductLine' }
  & Pick<MobileProductLine, 'id' | 'name'>
  & { partner: (
    { __typename?: 'TelcoPartner' }
    & Pick<TelcoPartner, 'id' | 'name'>
    & { logo: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'id' | 'name' | 'uuid' | 'type' | 'createdAt' | 'extension' | 'height' | 'namespace' | 'size' | 'width'>
    )>, logoSquare: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'id' | 'name' | 'uuid' | 'type' | 'createdAt' | 'extension' | 'height' | 'namespace' | 'size' | 'width'>
    )> }
  ) }
);

export type MobileProductFragment = (
  { __typename?: 'MobileProduct' }
  & Pick<MobileProduct, 'id' | 'name' | 'description' | 'includedData' | 'includedMinutes' | 'includedSms' | 'unlimitedData' | 'unlimitedMinutes' | 'unlimitedSms' | 'commitment' | 'dataSpeed'>
  & { priceCall: Maybe<(
    { __typename?: 'Money' }
    & Pick<Money, 'currency' | 'amount'>
  )>, priceData: Maybe<(
    { __typename?: 'Money' }
    & Pick<Money, 'currency' | 'amount'>
  )>, priceSms: Maybe<(
    { __typename?: 'Money' }
    & Pick<Money, 'currency' | 'amount'>
  )>, priceStandard: (
    { __typename?: 'Money' }
    & Pick<Money, 'currency' | 'amount'>
  ), priceForCombination: Maybe<(
    { __typename?: 'Money' }
    & Pick<Money, 'currency' | 'amount'>
  )>, mobileProductLine: (
    { __typename?: 'MobileProductLine' }
    & Pick<MobileProductLine, 'id' | 'name'>
    & { partner: (
      { __typename?: 'TelcoPartner' }
      & Pick<TelcoPartner, 'id' | 'name'>
      & { logo: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'id' | 'name' | 'uuid' | 'type' | 'createdAt' | 'extension' | 'height' | 'namespace' | 'size' | 'width'>
      )>, logoSquare: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'id' | 'name' | 'uuid' | 'type' | 'createdAt' | 'extension' | 'height' | 'namespace' | 'size' | 'width'>
      )> }
    ) }
  ), tags: Array<(
    { __typename?: 'TelcoTag' }
    & Pick<TelcoTag, 'label' | 'tooltip'>
  )> }
);

export type TvProductLineFragment = (
  { __typename?: 'TvProductLine' }
  & Pick<TvProductLine, 'id' | 'name'>
  & { partner: (
    { __typename?: 'TelcoPartner' }
    & Pick<TelcoPartner, 'id' | 'name'>
    & { logo: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'id' | 'name' | 'uuid' | 'type' | 'createdAt' | 'extension' | 'height' | 'namespace' | 'size' | 'width'>
    )>, logoSquare: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'id' | 'name' | 'uuid' | 'type' | 'createdAt' | 'extension' | 'height' | 'namespace' | 'size' | 'width'>
    )> }
  ) }
);

export type TvProductFragment = (
  { __typename?: 'TvProduct' }
  & Pick<TvProduct, 'id' | 'name' | 'description' | 'commitment' | 'tvChannelsCount' | 'hdTvChannelsCount'>
  & { tvExtensionPackages: Array<(
    { __typename?: 'TvExtensionPackage' }
    & Pick<TvExtensionPackage, 'id' | 'name' | 'description'>
    & { price: (
      { __typename?: 'Money' }
      & Pick<Money, 'currency' | 'amount'>
    ) }
  )>, priceStandard: (
    { __typename?: 'Money' }
    & Pick<Money, 'currency' | 'amount'>
  ), priceForCombination: Maybe<(
    { __typename?: 'Money' }
    & Pick<Money, 'currency' | 'amount'>
  )>, tvProductLine: (
    { __typename?: 'TvProductLine' }
    & Pick<TvProductLine, 'id' | 'name'>
    & { partner: (
      { __typename?: 'TelcoPartner' }
      & Pick<TelcoPartner, 'id' | 'name'>
      & { logo: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'id' | 'name' | 'uuid' | 'type' | 'createdAt' | 'extension' | 'height' | 'namespace' | 'size' | 'width'>
      )>, logoSquare: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'id' | 'name' | 'uuid' | 'type' | 'createdAt' | 'extension' | 'height' | 'namespace' | 'size' | 'width'>
      )> }
    ) }
  ), tags: Array<(
    { __typename?: 'TelcoTag' }
    & Pick<TelcoTag, 'label' | 'tooltip'>
  )> }
);

export type TelcoCalculationResultsFragment = (
  { __typename?: 'TelcoCalculationResults' }
  & { results: Array<(
    { __typename?: 'TelcoCalculationResultItem' }
    & Pick<TelcoCalculationResultItem, 'uniqueKey'>
    & { partner: (
      { __typename?: 'TelcoPartner' }
      & Pick<TelcoPartner, 'id' | 'name'>
      & { logo: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'id' | 'name' | 'uuid' | 'type' | 'createdAt' | 'extension' | 'height' | 'namespace' | 'size' | 'width'>
      )>, logoSquare: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'id' | 'name' | 'uuid' | 'type' | 'createdAt' | 'extension' | 'height' | 'namespace' | 'size' | 'width'>
      )> }
    ), priceBasic: (
      { __typename?: 'Money' }
      & Pick<Money, 'currency' | 'amount'>
    ), priceFinal: (
      { __typename?: 'Money' }
      & Pick<Money, 'currency' | 'amount'>
    ), productInternet: Maybe<(
      { __typename?: 'InternetProduct' }
      & Pick<InternetProduct, 'id' | 'name' | 'description' | 'downloadSpeed' | 'uploadSpeed' | 'technology' | 'commitment' | 'device'>
      & { priceDevice: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'amount'>
      )>, priceStandard: (
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'amount'>
      ), priceForCombination: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'amount'>
      )>, internetProductLine: (
        { __typename?: 'InternetProductLine' }
        & Pick<InternetProductLine, 'id' | 'name'>
        & { partner: (
          { __typename?: 'TelcoPartner' }
          & Pick<TelcoPartner, 'id' | 'name'>
          & { logo: Maybe<(
            { __typename?: 'Image' }
            & Pick<Image, 'id' | 'name' | 'uuid' | 'type' | 'createdAt' | 'extension' | 'height' | 'namespace' | 'size' | 'width'>
          )>, logoSquare: Maybe<(
            { __typename?: 'Image' }
            & Pick<Image, 'id' | 'name' | 'uuid' | 'type' | 'createdAt' | 'extension' | 'height' | 'namespace' | 'size' | 'width'>
          )> }
        ) }
      ), tags: Array<(
        { __typename?: 'TelcoTag' }
        & Pick<TelcoTag, 'label' | 'tooltip'>
      )> }
    )>, productMobile: Maybe<(
      { __typename?: 'MobileProduct' }
      & Pick<MobileProduct, 'id' | 'name' | 'description' | 'includedData' | 'includedMinutes' | 'includedSms' | 'unlimitedData' | 'unlimitedMinutes' | 'unlimitedSms' | 'commitment' | 'dataSpeed'>
      & { priceCall: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'amount'>
      )>, priceData: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'amount'>
      )>, priceSms: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'amount'>
      )>, priceStandard: (
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'amount'>
      ), priceForCombination: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'amount'>
      )>, mobileProductLine: (
        { __typename?: 'MobileProductLine' }
        & Pick<MobileProductLine, 'id' | 'name'>
        & { partner: (
          { __typename?: 'TelcoPartner' }
          & Pick<TelcoPartner, 'id' | 'name'>
          & { logo: Maybe<(
            { __typename?: 'Image' }
            & Pick<Image, 'id' | 'name' | 'uuid' | 'type' | 'createdAt' | 'extension' | 'height' | 'namespace' | 'size' | 'width'>
          )>, logoSquare: Maybe<(
            { __typename?: 'Image' }
            & Pick<Image, 'id' | 'name' | 'uuid' | 'type' | 'createdAt' | 'extension' | 'height' | 'namespace' | 'size' | 'width'>
          )> }
        ) }
      ), tags: Array<(
        { __typename?: 'TelcoTag' }
        & Pick<TelcoTag, 'label' | 'tooltip'>
      )> }
    )>, productTv: Maybe<(
      { __typename?: 'TvProduct' }
      & Pick<TvProduct, 'id' | 'name' | 'description' | 'commitment' | 'tvChannelsCount' | 'hdTvChannelsCount'>
      & { tvExtensionPackages: Array<(
        { __typename?: 'TvExtensionPackage' }
        & Pick<TvExtensionPackage, 'id' | 'name' | 'description'>
        & { price: (
          { __typename?: 'Money' }
          & Pick<Money, 'currency' | 'amount'>
        ) }
      )>, priceStandard: (
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'amount'>
      ), priceForCombination: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'amount'>
      )>, tvProductLine: (
        { __typename?: 'TvProductLine' }
        & Pick<TvProductLine, 'id' | 'name'>
        & { partner: (
          { __typename?: 'TelcoPartner' }
          & Pick<TelcoPartner, 'id' | 'name'>
          & { logo: Maybe<(
            { __typename?: 'Image' }
            & Pick<Image, 'id' | 'name' | 'uuid' | 'type' | 'createdAt' | 'extension' | 'height' | 'namespace' | 'size' | 'width'>
          )>, logoSquare: Maybe<(
            { __typename?: 'Image' }
            & Pick<Image, 'id' | 'name' | 'uuid' | 'type' | 'createdAt' | 'extension' | 'height' | 'namespace' | 'size' | 'width'>
          )> }
        ) }
      ), tags: Array<(
        { __typename?: 'TelcoTag' }
        & Pick<TelcoTag, 'label' | 'tooltip'>
      )> }
    )> }
  )> }
);

export type TelcoLeadFragment = (
  { __typename?: 'TelcoLead' }
  & Pick<TelcoLead, 'uuid' | 'email' | 'phone' | 'name' | 'surname'>
  & { mobileProduct: Maybe<(
    { __typename?: 'MobileProduct' }
    & Pick<MobileProduct, 'id' | 'name' | 'description' | 'includedData' | 'includedMinutes' | 'includedSms' | 'unlimitedData' | 'unlimitedMinutes' | 'unlimitedSms' | 'commitment' | 'dataSpeed'>
    & { priceCall: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'currency' | 'amount'>
    )>, priceData: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'currency' | 'amount'>
    )>, priceSms: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'currency' | 'amount'>
    )>, priceStandard: (
      { __typename?: 'Money' }
      & Pick<Money, 'currency' | 'amount'>
    ), priceForCombination: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'currency' | 'amount'>
    )>, mobileProductLine: (
      { __typename?: 'MobileProductLine' }
      & Pick<MobileProductLine, 'id' | 'name'>
      & { partner: (
        { __typename?: 'TelcoPartner' }
        & Pick<TelcoPartner, 'id' | 'name'>
        & { logo: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'id' | 'name' | 'uuid' | 'type' | 'createdAt' | 'extension' | 'height' | 'namespace' | 'size' | 'width'>
        )>, logoSquare: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'id' | 'name' | 'uuid' | 'type' | 'createdAt' | 'extension' | 'height' | 'namespace' | 'size' | 'width'>
        )> }
      ) }
    ), tags: Array<(
      { __typename?: 'TelcoTag' }
      & Pick<TelcoTag, 'label' | 'tooltip'>
    )> }
  )>, internetProduct: Maybe<(
    { __typename?: 'InternetProduct' }
    & Pick<InternetProduct, 'id' | 'name' | 'description' | 'downloadSpeed' | 'uploadSpeed' | 'technology' | 'commitment' | 'device'>
    & { priceDevice: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'currency' | 'amount'>
    )>, priceStandard: (
      { __typename?: 'Money' }
      & Pick<Money, 'currency' | 'amount'>
    ), priceForCombination: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'currency' | 'amount'>
    )>, internetProductLine: (
      { __typename?: 'InternetProductLine' }
      & Pick<InternetProductLine, 'id' | 'name'>
      & { partner: (
        { __typename?: 'TelcoPartner' }
        & Pick<TelcoPartner, 'id' | 'name'>
        & { logo: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'id' | 'name' | 'uuid' | 'type' | 'createdAt' | 'extension' | 'height' | 'namespace' | 'size' | 'width'>
        )>, logoSquare: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'id' | 'name' | 'uuid' | 'type' | 'createdAt' | 'extension' | 'height' | 'namespace' | 'size' | 'width'>
        )> }
      ) }
    ), tags: Array<(
      { __typename?: 'TelcoTag' }
      & Pick<TelcoTag, 'label' | 'tooltip'>
    )> }
  )>, tvProduct: Maybe<(
    { __typename?: 'TvProduct' }
    & Pick<TvProduct, 'id' | 'name' | 'description' | 'commitment' | 'tvChannelsCount' | 'hdTvChannelsCount'>
    & { tvExtensionPackages: Array<(
      { __typename?: 'TvExtensionPackage' }
      & Pick<TvExtensionPackage, 'id' | 'name' | 'description'>
      & { price: (
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'amount'>
      ) }
    )>, priceStandard: (
      { __typename?: 'Money' }
      & Pick<Money, 'currency' | 'amount'>
    ), priceForCombination: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'currency' | 'amount'>
    )>, tvProductLine: (
      { __typename?: 'TvProductLine' }
      & Pick<TvProductLine, 'id' | 'name'>
      & { partner: (
        { __typename?: 'TelcoPartner' }
        & Pick<TelcoPartner, 'id' | 'name'>
        & { logo: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'id' | 'name' | 'uuid' | 'type' | 'createdAt' | 'extension' | 'height' | 'namespace' | 'size' | 'width'>
        )>, logoSquare: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'id' | 'name' | 'uuid' | 'type' | 'createdAt' | 'extension' | 'height' | 'namespace' | 'size' | 'width'>
        )> }
      ) }
    ), tags: Array<(
      { __typename?: 'TelcoTag' }
      & Pick<TelcoTag, 'label' | 'tooltip'>
    )> }
  )>, address: Maybe<(
    { __typename?: 'NewSmartAddress' }
    & Pick<NewSmartAddress, 'id' | 'street' | 'city' | 'zip' | 'level'>
    & { gps: Maybe<(
      { __typename?: 'Gps' }
      & Pick<Gps, 'latitude' | 'longitude'>
    )> }
  )>, calculation: (
    { __typename?: 'TelcoCalculation' }
    & Pick<TelcoCalculation, 'id' | 'uuid' | 'email' | 'phone' | 'interests'>
    & { mobile: Maybe<(
      { __typename?: 'TelcoCalculationMobile' }
      & Pick<TelcoCalculationMobile, 'id' | 'includedMinutes' | 'includedData' | 'isStudent' | 'isSenior' | 'isBusiness' | 'hasMultiplePhoneNumbers'>
    )>, internet: Maybe<(
      { __typename?: 'TelcoCalculationInternet' }
      & Pick<TelcoCalculationInternet, 'id' | 'typeOfUser' | 'speed'>
    )>, tv: Maybe<(
      { __typename?: 'TelcoCalculationTv' }
      & Pick<TelcoCalculationTv, 'id' | 'programTypes' | 'programOffer'>
    )> }
  ), priceForCombination: Maybe<(
    { __typename?: 'Money' }
    & Pick<Money, 'currency' | 'amount'>
  )>, priceStandard: Maybe<(
    { __typename?: 'Money' }
    & Pick<Money, 'currency' | 'amount'>
  )> }
);

export type TelcoCalculationUpsellPricesFragment = (
  { __typename?: 'TelcoCalculationUpsellPrices' }
  & { priceMobile: Maybe<(
    { __typename?: 'Money' }
    & Pick<Money, 'currency' | 'amount'>
  )>, priceInternet: Maybe<(
    { __typename?: 'Money' }
    & Pick<Money, 'currency' | 'amount'>
  )>, priceTv: Maybe<(
    { __typename?: 'Money' }
    & Pick<Money, 'currency' | 'amount'>
  )> }
);

export type TelcoCalculationCreateMutationVariables = Exact<{
  data: TelcoCalculationCreateInput;
}>;


export type TelcoCalculationCreateMutation = (
  { __typename?: 'Mutation' }
  & { calculation: (
    { __typename?: 'TelcoCalculation' }
    & Pick<TelcoCalculation, 'id' | 'uuid' | 'email' | 'phone' | 'interests'>
    & { mobile: Maybe<(
      { __typename?: 'TelcoCalculationMobile' }
      & Pick<TelcoCalculationMobile, 'id' | 'includedMinutes' | 'includedData' | 'isStudent' | 'isSenior' | 'isBusiness' | 'hasMultiplePhoneNumbers'>
    )>, internet: Maybe<(
      { __typename?: 'TelcoCalculationInternet' }
      & Pick<TelcoCalculationInternet, 'id' | 'typeOfUser' | 'speed'>
    )>, tv: Maybe<(
      { __typename?: 'TelcoCalculationTv' }
      & Pick<TelcoCalculationTv, 'id' | 'programTypes' | 'programOffer'>
    )> }
  ) }
);

export type TelcoCalculationUpdateMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  data: TelcoCalculationUpdateInput;
}>;


export type TelcoCalculationUpdateMutation = (
  { __typename?: 'Mutation' }
  & { calculation: (
    { __typename?: 'TelcoCalculation' }
    & Pick<TelcoCalculation, 'id' | 'uuid' | 'email' | 'phone' | 'interests'>
    & { mobile: Maybe<(
      { __typename?: 'TelcoCalculationMobile' }
      & Pick<TelcoCalculationMobile, 'id' | 'includedMinutes' | 'includedData' | 'isStudent' | 'isSenior' | 'isBusiness' | 'hasMultiplePhoneNumbers'>
    )>, internet: Maybe<(
      { __typename?: 'TelcoCalculationInternet' }
      & Pick<TelcoCalculationInternet, 'id' | 'typeOfUser' | 'speed'>
    )>, tv: Maybe<(
      { __typename?: 'TelcoCalculationTv' }
      & Pick<TelcoCalculationTv, 'id' | 'programTypes' | 'programOffer'>
    )> }
  ) }
);

export type TelcoLeadCreateMutationVariables = Exact<{
  data: TelcoLeadCreateInput;
}>;


export type TelcoLeadCreateMutation = (
  { __typename?: 'Mutation' }
  & { lead: (
    { __typename?: 'TelcoLead' }
    & Pick<TelcoLead, 'uuid' | 'email' | 'phone' | 'name' | 'surname'>
    & { mobileProduct: Maybe<(
      { __typename?: 'MobileProduct' }
      & Pick<MobileProduct, 'id' | 'name' | 'description' | 'includedData' | 'includedMinutes' | 'includedSms' | 'unlimitedData' | 'unlimitedMinutes' | 'unlimitedSms' | 'commitment' | 'dataSpeed'>
      & { priceCall: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'amount'>
      )>, priceData: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'amount'>
      )>, priceSms: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'amount'>
      )>, priceStandard: (
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'amount'>
      ), priceForCombination: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'amount'>
      )>, mobileProductLine: (
        { __typename?: 'MobileProductLine' }
        & Pick<MobileProductLine, 'id' | 'name'>
        & { partner: (
          { __typename?: 'TelcoPartner' }
          & Pick<TelcoPartner, 'id' | 'name'>
          & { logo: Maybe<(
            { __typename?: 'Image' }
            & Pick<Image, 'id' | 'name' | 'uuid' | 'type' | 'createdAt' | 'extension' | 'height' | 'namespace' | 'size' | 'width'>
          )>, logoSquare: Maybe<(
            { __typename?: 'Image' }
            & Pick<Image, 'id' | 'name' | 'uuid' | 'type' | 'createdAt' | 'extension' | 'height' | 'namespace' | 'size' | 'width'>
          )> }
        ) }
      ), tags: Array<(
        { __typename?: 'TelcoTag' }
        & Pick<TelcoTag, 'label' | 'tooltip'>
      )> }
    )>, internetProduct: Maybe<(
      { __typename?: 'InternetProduct' }
      & Pick<InternetProduct, 'id' | 'name' | 'description' | 'downloadSpeed' | 'uploadSpeed' | 'technology' | 'commitment' | 'device'>
      & { priceDevice: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'amount'>
      )>, priceStandard: (
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'amount'>
      ), priceForCombination: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'amount'>
      )>, internetProductLine: (
        { __typename?: 'InternetProductLine' }
        & Pick<InternetProductLine, 'id' | 'name'>
        & { partner: (
          { __typename?: 'TelcoPartner' }
          & Pick<TelcoPartner, 'id' | 'name'>
          & { logo: Maybe<(
            { __typename?: 'Image' }
            & Pick<Image, 'id' | 'name' | 'uuid' | 'type' | 'createdAt' | 'extension' | 'height' | 'namespace' | 'size' | 'width'>
          )>, logoSquare: Maybe<(
            { __typename?: 'Image' }
            & Pick<Image, 'id' | 'name' | 'uuid' | 'type' | 'createdAt' | 'extension' | 'height' | 'namespace' | 'size' | 'width'>
          )> }
        ) }
      ), tags: Array<(
        { __typename?: 'TelcoTag' }
        & Pick<TelcoTag, 'label' | 'tooltip'>
      )> }
    )>, tvProduct: Maybe<(
      { __typename?: 'TvProduct' }
      & Pick<TvProduct, 'id' | 'name' | 'description' | 'commitment' | 'tvChannelsCount' | 'hdTvChannelsCount'>
      & { tvExtensionPackages: Array<(
        { __typename?: 'TvExtensionPackage' }
        & Pick<TvExtensionPackage, 'id' | 'name' | 'description'>
        & { price: (
          { __typename?: 'Money' }
          & Pick<Money, 'currency' | 'amount'>
        ) }
      )>, priceStandard: (
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'amount'>
      ), priceForCombination: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'amount'>
      )>, tvProductLine: (
        { __typename?: 'TvProductLine' }
        & Pick<TvProductLine, 'id' | 'name'>
        & { partner: (
          { __typename?: 'TelcoPartner' }
          & Pick<TelcoPartner, 'id' | 'name'>
          & { logo: Maybe<(
            { __typename?: 'Image' }
            & Pick<Image, 'id' | 'name' | 'uuid' | 'type' | 'createdAt' | 'extension' | 'height' | 'namespace' | 'size' | 'width'>
          )>, logoSquare: Maybe<(
            { __typename?: 'Image' }
            & Pick<Image, 'id' | 'name' | 'uuid' | 'type' | 'createdAt' | 'extension' | 'height' | 'namespace' | 'size' | 'width'>
          )> }
        ) }
      ), tags: Array<(
        { __typename?: 'TelcoTag' }
        & Pick<TelcoTag, 'label' | 'tooltip'>
      )> }
    )>, address: Maybe<(
      { __typename?: 'NewSmartAddress' }
      & Pick<NewSmartAddress, 'id' | 'street' | 'city' | 'zip' | 'level'>
      & { gps: Maybe<(
        { __typename?: 'Gps' }
        & Pick<Gps, 'latitude' | 'longitude'>
      )> }
    )>, calculation: (
      { __typename?: 'TelcoCalculation' }
      & Pick<TelcoCalculation, 'id' | 'uuid' | 'email' | 'phone' | 'interests'>
      & { mobile: Maybe<(
        { __typename?: 'TelcoCalculationMobile' }
        & Pick<TelcoCalculationMobile, 'id' | 'includedMinutes' | 'includedData' | 'isStudent' | 'isSenior' | 'isBusiness' | 'hasMultiplePhoneNumbers'>
      )>, internet: Maybe<(
        { __typename?: 'TelcoCalculationInternet' }
        & Pick<TelcoCalculationInternet, 'id' | 'typeOfUser' | 'speed'>
      )>, tv: Maybe<(
        { __typename?: 'TelcoCalculationTv' }
        & Pick<TelcoCalculationTv, 'id' | 'programTypes' | 'programOffer'>
      )> }
    ), priceForCombination: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'currency' | 'amount'>
    )>, priceStandard: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'currency' | 'amount'>
    )> }
  ) }
);

export type TelcoLeadUpdateMutationVariables = Exact<{
  lead: Scalars['UUID']['input'];
  data: TelcoLeadUpdateInput;
}>;


export type TelcoLeadUpdateMutation = (
  { __typename?: 'Mutation' }
  & { lead: (
    { __typename?: 'TelcoLead' }
    & Pick<TelcoLead, 'uuid' | 'email' | 'phone' | 'name' | 'surname'>
    & { mobileProduct: Maybe<(
      { __typename?: 'MobileProduct' }
      & Pick<MobileProduct, 'id' | 'name' | 'description' | 'includedData' | 'includedMinutes' | 'includedSms' | 'unlimitedData' | 'unlimitedMinutes' | 'unlimitedSms' | 'commitment' | 'dataSpeed'>
      & { priceCall: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'amount'>
      )>, priceData: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'amount'>
      )>, priceSms: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'amount'>
      )>, priceStandard: (
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'amount'>
      ), priceForCombination: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'amount'>
      )>, mobileProductLine: (
        { __typename?: 'MobileProductLine' }
        & Pick<MobileProductLine, 'id' | 'name'>
        & { partner: (
          { __typename?: 'TelcoPartner' }
          & Pick<TelcoPartner, 'id' | 'name'>
          & { logo: Maybe<(
            { __typename?: 'Image' }
            & Pick<Image, 'id' | 'name' | 'uuid' | 'type' | 'createdAt' | 'extension' | 'height' | 'namespace' | 'size' | 'width'>
          )>, logoSquare: Maybe<(
            { __typename?: 'Image' }
            & Pick<Image, 'id' | 'name' | 'uuid' | 'type' | 'createdAt' | 'extension' | 'height' | 'namespace' | 'size' | 'width'>
          )> }
        ) }
      ), tags: Array<(
        { __typename?: 'TelcoTag' }
        & Pick<TelcoTag, 'label' | 'tooltip'>
      )> }
    )>, internetProduct: Maybe<(
      { __typename?: 'InternetProduct' }
      & Pick<InternetProduct, 'id' | 'name' | 'description' | 'downloadSpeed' | 'uploadSpeed' | 'technology' | 'commitment' | 'device'>
      & { priceDevice: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'amount'>
      )>, priceStandard: (
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'amount'>
      ), priceForCombination: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'amount'>
      )>, internetProductLine: (
        { __typename?: 'InternetProductLine' }
        & Pick<InternetProductLine, 'id' | 'name'>
        & { partner: (
          { __typename?: 'TelcoPartner' }
          & Pick<TelcoPartner, 'id' | 'name'>
          & { logo: Maybe<(
            { __typename?: 'Image' }
            & Pick<Image, 'id' | 'name' | 'uuid' | 'type' | 'createdAt' | 'extension' | 'height' | 'namespace' | 'size' | 'width'>
          )>, logoSquare: Maybe<(
            { __typename?: 'Image' }
            & Pick<Image, 'id' | 'name' | 'uuid' | 'type' | 'createdAt' | 'extension' | 'height' | 'namespace' | 'size' | 'width'>
          )> }
        ) }
      ), tags: Array<(
        { __typename?: 'TelcoTag' }
        & Pick<TelcoTag, 'label' | 'tooltip'>
      )> }
    )>, tvProduct: Maybe<(
      { __typename?: 'TvProduct' }
      & Pick<TvProduct, 'id' | 'name' | 'description' | 'commitment' | 'tvChannelsCount' | 'hdTvChannelsCount'>
      & { tvExtensionPackages: Array<(
        { __typename?: 'TvExtensionPackage' }
        & Pick<TvExtensionPackage, 'id' | 'name' | 'description'>
        & { price: (
          { __typename?: 'Money' }
          & Pick<Money, 'currency' | 'amount'>
        ) }
      )>, priceStandard: (
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'amount'>
      ), priceForCombination: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'amount'>
      )>, tvProductLine: (
        { __typename?: 'TvProductLine' }
        & Pick<TvProductLine, 'id' | 'name'>
        & { partner: (
          { __typename?: 'TelcoPartner' }
          & Pick<TelcoPartner, 'id' | 'name'>
          & { logo: Maybe<(
            { __typename?: 'Image' }
            & Pick<Image, 'id' | 'name' | 'uuid' | 'type' | 'createdAt' | 'extension' | 'height' | 'namespace' | 'size' | 'width'>
          )>, logoSquare: Maybe<(
            { __typename?: 'Image' }
            & Pick<Image, 'id' | 'name' | 'uuid' | 'type' | 'createdAt' | 'extension' | 'height' | 'namespace' | 'size' | 'width'>
          )> }
        ) }
      ), tags: Array<(
        { __typename?: 'TelcoTag' }
        & Pick<TelcoTag, 'label' | 'tooltip'>
      )> }
    )>, address: Maybe<(
      { __typename?: 'NewSmartAddress' }
      & Pick<NewSmartAddress, 'id' | 'street' | 'city' | 'zip' | 'level'>
      & { gps: Maybe<(
        { __typename?: 'Gps' }
        & Pick<Gps, 'latitude' | 'longitude'>
      )> }
    )>, calculation: (
      { __typename?: 'TelcoCalculation' }
      & Pick<TelcoCalculation, 'id' | 'uuid' | 'email' | 'phone' | 'interests'>
      & { mobile: Maybe<(
        { __typename?: 'TelcoCalculationMobile' }
        & Pick<TelcoCalculationMobile, 'id' | 'includedMinutes' | 'includedData' | 'isStudent' | 'isSenior' | 'isBusiness' | 'hasMultiplePhoneNumbers'>
      )>, internet: Maybe<(
        { __typename?: 'TelcoCalculationInternet' }
        & Pick<TelcoCalculationInternet, 'id' | 'typeOfUser' | 'speed'>
      )>, tv: Maybe<(
        { __typename?: 'TelcoCalculationTv' }
        & Pick<TelcoCalculationTv, 'id' | 'programTypes' | 'programOffer'>
      )> }
    ), priceForCombination: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'currency' | 'amount'>
    )>, priceStandard: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'currency' | 'amount'>
    )> }
  ) }
);

export type TelcoCalculationGetQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;


export type TelcoCalculationGetQuery = (
  { __typename?: 'Query' }
  & { telcoCalculationGet: (
    { __typename?: 'TelcoCalculation' }
    & Pick<TelcoCalculation, 'id' | 'uuid' | 'email' | 'phone' | 'interests'>
    & { mobile: Maybe<(
      { __typename?: 'TelcoCalculationMobile' }
      & Pick<TelcoCalculationMobile, 'id' | 'includedMinutes' | 'includedData' | 'isStudent' | 'isSenior' | 'isBusiness' | 'hasMultiplePhoneNumbers'>
    )>, internet: Maybe<(
      { __typename?: 'TelcoCalculationInternet' }
      & Pick<TelcoCalculationInternet, 'id' | 'typeOfUser' | 'speed'>
    )>, tv: Maybe<(
      { __typename?: 'TelcoCalculationTv' }
      & Pick<TelcoCalculationTv, 'id' | 'programTypes' | 'programOffer'>
    )> }
  ) }
);

export type TelcoCalculationResultsGetQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;


export type TelcoCalculationResultsGetQuery = (
  { __typename?: 'Query' }
  & { calculation: (
    { __typename?: 'TelcoCalculation' }
    & Pick<TelcoCalculation, 'id' | 'uuid' | 'email' | 'phone' | 'interests'>
    & { mobile: Maybe<(
      { __typename?: 'TelcoCalculationMobile' }
      & Pick<TelcoCalculationMobile, 'id' | 'includedMinutes' | 'includedData' | 'isStudent' | 'isSenior' | 'isBusiness' | 'hasMultiplePhoneNumbers'>
    )>, internet: Maybe<(
      { __typename?: 'TelcoCalculationInternet' }
      & Pick<TelcoCalculationInternet, 'id' | 'typeOfUser' | 'speed'>
    )>, tv: Maybe<(
      { __typename?: 'TelcoCalculationTv' }
      & Pick<TelcoCalculationTv, 'id' | 'programTypes' | 'programOffer'>
    )> }
  ), results: (
    { __typename?: 'TelcoCalculationResults' }
    & { results: Array<(
      { __typename?: 'TelcoCalculationResultItem' }
      & Pick<TelcoCalculationResultItem, 'uniqueKey'>
      & { partner: (
        { __typename?: 'TelcoPartner' }
        & Pick<TelcoPartner, 'id' | 'name'>
        & { logo: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'id' | 'name' | 'uuid' | 'type' | 'createdAt' | 'extension' | 'height' | 'namespace' | 'size' | 'width'>
        )>, logoSquare: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'id' | 'name' | 'uuid' | 'type' | 'createdAt' | 'extension' | 'height' | 'namespace' | 'size' | 'width'>
        )> }
      ), priceBasic: (
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'amount'>
      ), priceFinal: (
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'amount'>
      ), productInternet: Maybe<(
        { __typename?: 'InternetProduct' }
        & Pick<InternetProduct, 'id' | 'name' | 'description' | 'downloadSpeed' | 'uploadSpeed' | 'technology' | 'commitment' | 'device'>
        & { priceDevice: Maybe<(
          { __typename?: 'Money' }
          & Pick<Money, 'currency' | 'amount'>
        )>, priceStandard: (
          { __typename?: 'Money' }
          & Pick<Money, 'currency' | 'amount'>
        ), priceForCombination: Maybe<(
          { __typename?: 'Money' }
          & Pick<Money, 'currency' | 'amount'>
        )>, internetProductLine: (
          { __typename?: 'InternetProductLine' }
          & Pick<InternetProductLine, 'id' | 'name'>
          & { partner: (
            { __typename?: 'TelcoPartner' }
            & Pick<TelcoPartner, 'id' | 'name'>
            & { logo: Maybe<(
              { __typename?: 'Image' }
              & Pick<Image, 'id' | 'name' | 'uuid' | 'type' | 'createdAt' | 'extension' | 'height' | 'namespace' | 'size' | 'width'>
            )>, logoSquare: Maybe<(
              { __typename?: 'Image' }
              & Pick<Image, 'id' | 'name' | 'uuid' | 'type' | 'createdAt' | 'extension' | 'height' | 'namespace' | 'size' | 'width'>
            )> }
          ) }
        ), tags: Array<(
          { __typename?: 'TelcoTag' }
          & Pick<TelcoTag, 'label' | 'tooltip'>
        )> }
      )>, productMobile: Maybe<(
        { __typename?: 'MobileProduct' }
        & Pick<MobileProduct, 'id' | 'name' | 'description' | 'includedData' | 'includedMinutes' | 'includedSms' | 'unlimitedData' | 'unlimitedMinutes' | 'unlimitedSms' | 'commitment' | 'dataSpeed'>
        & { priceCall: Maybe<(
          { __typename?: 'Money' }
          & Pick<Money, 'currency' | 'amount'>
        )>, priceData: Maybe<(
          { __typename?: 'Money' }
          & Pick<Money, 'currency' | 'amount'>
        )>, priceSms: Maybe<(
          { __typename?: 'Money' }
          & Pick<Money, 'currency' | 'amount'>
        )>, priceStandard: (
          { __typename?: 'Money' }
          & Pick<Money, 'currency' | 'amount'>
        ), priceForCombination: Maybe<(
          { __typename?: 'Money' }
          & Pick<Money, 'currency' | 'amount'>
        )>, mobileProductLine: (
          { __typename?: 'MobileProductLine' }
          & Pick<MobileProductLine, 'id' | 'name'>
          & { partner: (
            { __typename?: 'TelcoPartner' }
            & Pick<TelcoPartner, 'id' | 'name'>
            & { logo: Maybe<(
              { __typename?: 'Image' }
              & Pick<Image, 'id' | 'name' | 'uuid' | 'type' | 'createdAt' | 'extension' | 'height' | 'namespace' | 'size' | 'width'>
            )>, logoSquare: Maybe<(
              { __typename?: 'Image' }
              & Pick<Image, 'id' | 'name' | 'uuid' | 'type' | 'createdAt' | 'extension' | 'height' | 'namespace' | 'size' | 'width'>
            )> }
          ) }
        ), tags: Array<(
          { __typename?: 'TelcoTag' }
          & Pick<TelcoTag, 'label' | 'tooltip'>
        )> }
      )>, productTv: Maybe<(
        { __typename?: 'TvProduct' }
        & Pick<TvProduct, 'id' | 'name' | 'description' | 'commitment' | 'tvChannelsCount' | 'hdTvChannelsCount'>
        & { tvExtensionPackages: Array<(
          { __typename?: 'TvExtensionPackage' }
          & Pick<TvExtensionPackage, 'id' | 'name' | 'description'>
          & { price: (
            { __typename?: 'Money' }
            & Pick<Money, 'currency' | 'amount'>
          ) }
        )>, priceStandard: (
          { __typename?: 'Money' }
          & Pick<Money, 'currency' | 'amount'>
        ), priceForCombination: Maybe<(
          { __typename?: 'Money' }
          & Pick<Money, 'currency' | 'amount'>
        )>, tvProductLine: (
          { __typename?: 'TvProductLine' }
          & Pick<TvProductLine, 'id' | 'name'>
          & { partner: (
            { __typename?: 'TelcoPartner' }
            & Pick<TelcoPartner, 'id' | 'name'>
            & { logo: Maybe<(
              { __typename?: 'Image' }
              & Pick<Image, 'id' | 'name' | 'uuid' | 'type' | 'createdAt' | 'extension' | 'height' | 'namespace' | 'size' | 'width'>
            )>, logoSquare: Maybe<(
              { __typename?: 'Image' }
              & Pick<Image, 'id' | 'name' | 'uuid' | 'type' | 'createdAt' | 'extension' | 'height' | 'namespace' | 'size' | 'width'>
            )> }
          ) }
        ), tags: Array<(
          { __typename?: 'TelcoTag' }
          & Pick<TelcoTag, 'label' | 'tooltip'>
        )> }
      )> }
    )> }
  ) }
);

export type TelcoLeadGetQueryVariables = Exact<{
  lead: Scalars['UUID']['input'];
}>;


export type TelcoLeadGetQuery = (
  { __typename?: 'Query' }
  & { lead: (
    { __typename?: 'TelcoLead' }
    & Pick<TelcoLead, 'uuid' | 'email' | 'phone' | 'name' | 'surname'>
    & { mobileProduct: Maybe<(
      { __typename?: 'MobileProduct' }
      & Pick<MobileProduct, 'id' | 'name' | 'description' | 'includedData' | 'includedMinutes' | 'includedSms' | 'unlimitedData' | 'unlimitedMinutes' | 'unlimitedSms' | 'commitment' | 'dataSpeed'>
      & { priceCall: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'amount'>
      )>, priceData: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'amount'>
      )>, priceSms: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'amount'>
      )>, priceStandard: (
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'amount'>
      ), priceForCombination: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'amount'>
      )>, mobileProductLine: (
        { __typename?: 'MobileProductLine' }
        & Pick<MobileProductLine, 'id' | 'name'>
        & { partner: (
          { __typename?: 'TelcoPartner' }
          & Pick<TelcoPartner, 'id' | 'name'>
          & { logo: Maybe<(
            { __typename?: 'Image' }
            & Pick<Image, 'id' | 'name' | 'uuid' | 'type' | 'createdAt' | 'extension' | 'height' | 'namespace' | 'size' | 'width'>
          )>, logoSquare: Maybe<(
            { __typename?: 'Image' }
            & Pick<Image, 'id' | 'name' | 'uuid' | 'type' | 'createdAt' | 'extension' | 'height' | 'namespace' | 'size' | 'width'>
          )> }
        ) }
      ), tags: Array<(
        { __typename?: 'TelcoTag' }
        & Pick<TelcoTag, 'label' | 'tooltip'>
      )> }
    )>, internetProduct: Maybe<(
      { __typename?: 'InternetProduct' }
      & Pick<InternetProduct, 'id' | 'name' | 'description' | 'downloadSpeed' | 'uploadSpeed' | 'technology' | 'commitment' | 'device'>
      & { priceDevice: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'amount'>
      )>, priceStandard: (
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'amount'>
      ), priceForCombination: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'amount'>
      )>, internetProductLine: (
        { __typename?: 'InternetProductLine' }
        & Pick<InternetProductLine, 'id' | 'name'>
        & { partner: (
          { __typename?: 'TelcoPartner' }
          & Pick<TelcoPartner, 'id' | 'name'>
          & { logo: Maybe<(
            { __typename?: 'Image' }
            & Pick<Image, 'id' | 'name' | 'uuid' | 'type' | 'createdAt' | 'extension' | 'height' | 'namespace' | 'size' | 'width'>
          )>, logoSquare: Maybe<(
            { __typename?: 'Image' }
            & Pick<Image, 'id' | 'name' | 'uuid' | 'type' | 'createdAt' | 'extension' | 'height' | 'namespace' | 'size' | 'width'>
          )> }
        ) }
      ), tags: Array<(
        { __typename?: 'TelcoTag' }
        & Pick<TelcoTag, 'label' | 'tooltip'>
      )> }
    )>, tvProduct: Maybe<(
      { __typename?: 'TvProduct' }
      & Pick<TvProduct, 'id' | 'name' | 'description' | 'commitment' | 'tvChannelsCount' | 'hdTvChannelsCount'>
      & { tvExtensionPackages: Array<(
        { __typename?: 'TvExtensionPackage' }
        & Pick<TvExtensionPackage, 'id' | 'name' | 'description'>
        & { price: (
          { __typename?: 'Money' }
          & Pick<Money, 'currency' | 'amount'>
        ) }
      )>, priceStandard: (
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'amount'>
      ), priceForCombination: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'currency' | 'amount'>
      )>, tvProductLine: (
        { __typename?: 'TvProductLine' }
        & Pick<TvProductLine, 'id' | 'name'>
        & { partner: (
          { __typename?: 'TelcoPartner' }
          & Pick<TelcoPartner, 'id' | 'name'>
          & { logo: Maybe<(
            { __typename?: 'Image' }
            & Pick<Image, 'id' | 'name' | 'uuid' | 'type' | 'createdAt' | 'extension' | 'height' | 'namespace' | 'size' | 'width'>
          )>, logoSquare: Maybe<(
            { __typename?: 'Image' }
            & Pick<Image, 'id' | 'name' | 'uuid' | 'type' | 'createdAt' | 'extension' | 'height' | 'namespace' | 'size' | 'width'>
          )> }
        ) }
      ), tags: Array<(
        { __typename?: 'TelcoTag' }
        & Pick<TelcoTag, 'label' | 'tooltip'>
      )> }
    )>, address: Maybe<(
      { __typename?: 'NewSmartAddress' }
      & Pick<NewSmartAddress, 'id' | 'street' | 'city' | 'zip' | 'level'>
      & { gps: Maybe<(
        { __typename?: 'Gps' }
        & Pick<Gps, 'latitude' | 'longitude'>
      )> }
    )>, calculation: (
      { __typename?: 'TelcoCalculation' }
      & Pick<TelcoCalculation, 'id' | 'uuid' | 'email' | 'phone' | 'interests'>
      & { mobile: Maybe<(
        { __typename?: 'TelcoCalculationMobile' }
        & Pick<TelcoCalculationMobile, 'id' | 'includedMinutes' | 'includedData' | 'isStudent' | 'isSenior' | 'isBusiness' | 'hasMultiplePhoneNumbers'>
      )>, internet: Maybe<(
        { __typename?: 'TelcoCalculationInternet' }
        & Pick<TelcoCalculationInternet, 'id' | 'typeOfUser' | 'speed'>
      )>, tv: Maybe<(
        { __typename?: 'TelcoCalculationTv' }
        & Pick<TelcoCalculationTv, 'id' | 'programTypes' | 'programOffer'>
      )> }
    ), priceForCombination: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'currency' | 'amount'>
    )>, priceStandard: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'currency' | 'amount'>
    )> }
  ) }
);

export type TelcoCalculationUpsellPricesQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;


export type TelcoCalculationUpsellPricesQuery = (
  { __typename?: 'Query' }
  & { upsellPrices: (
    { __typename?: 'TelcoCalculationUpsellPrices' }
    & { priceMobile: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'currency' | 'amount'>
    )>, priceInternet: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'currency' | 'amount'>
    )>, priceTv: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'currency' | 'amount'>
    )> }
  ) }
);

export type TravelInsuranceCodeBooksFragment = (
  { __typename?: 'TravelInsuranceCodeBooks' }
  & { country: Array<(
    { __typename?: 'CodeBook' }
    & Pick<CodeBook, 'id' | 'label'>
  )>, destination: Array<(
    { __typename?: 'CodeBook' }
    & Pick<CodeBook, 'id' | 'label'>
  )>, sport: Array<(
    { __typename?: 'CodeBook' }
    & Pick<CodeBook, 'id' | 'label'>
  )> }
);

export type PersonFragment = (
  { __typename?: 'Person' }
  & Pick<Person, 'id' | 'uuid' | 'name' | 'surname' | 'birthDate' | 'birthNumber' | 'phone' | 'email' | 'policyHolder' | 'insured' | 'age'>
  & { address: Maybe<(
    { __typename?: 'Address' }
    & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
    & { extended: Maybe<(
      { __typename?: 'SmartAddressExtendedInfo' }
      & Pick<SmartAddressExtendedInfo, 'id'>
      & { objectType: Maybe<(
        { __typename?: 'SmartAddressObjectType' }
        & Pick<SmartAddressObjectType, 'id' | 'nazev'>
      )> }
    )>, extendedV2: Maybe<(
      { __typename?: 'SmartAddressSdkExtendedInfo' }
      & { administrativniUdaje: Maybe<(
        { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
        & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
        & { objectType: Maybe<(
          { __typename?: 'SmartAddressObjectType' }
          & Pick<SmartAddressObjectType, 'id' | 'nazev'>
        )> }
      )> }
    )> }
  )>, nationality: Maybe<(
    { __typename?: 'CodeBook' }
    & Pick<CodeBook, 'id' | 'label'>
  )> }
);

export type TravelInsuranceOrderFragment = (
  { __typename?: 'TravelInsuranceOrder' }
  & Pick<TravelInsuranceOrder, 'id' | 'uuid' | 'dateFrom' | 'dateTo' | 'insuranceType' | 'travelPurpose' | 'family' | 'resultEmail' | 'createdAt' | 'updatedAt' | 'status'>
  & { destination: (
    { __typename?: 'CodeBook' }
    & Pick<CodeBook, 'id' | 'label'>
  ), policyHolder: Maybe<(
    { __typename?: 'Person' }
    & Pick<Person, 'id' | 'uuid' | 'name' | 'surname' | 'birthDate' | 'birthNumber' | 'phone' | 'email' | 'policyHolder' | 'insured' | 'age'>
    & { address: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
      & { extended: Maybe<(
        { __typename?: 'SmartAddressExtendedInfo' }
        & Pick<SmartAddressExtendedInfo, 'id'>
        & { objectType: Maybe<(
          { __typename?: 'SmartAddressObjectType' }
          & Pick<SmartAddressObjectType, 'id' | 'nazev'>
        )> }
      )>, extendedV2: Maybe<(
        { __typename?: 'SmartAddressSdkExtendedInfo' }
        & { administrativniUdaje: Maybe<(
          { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
          & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )> }
      )> }
    )>, nationality: Maybe<(
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    )> }
  )>, insuredPersons: Array<(
    { __typename?: 'Person' }
    & Pick<Person, 'id' | 'uuid' | 'name' | 'surname' | 'birthDate' | 'birthNumber' | 'phone' | 'email' | 'policyHolder' | 'insured' | 'age'>
    & { address: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
      & { extended: Maybe<(
        { __typename?: 'SmartAddressExtendedInfo' }
        & Pick<SmartAddressExtendedInfo, 'id'>
        & { objectType: Maybe<(
          { __typename?: 'SmartAddressObjectType' }
          & Pick<SmartAddressObjectType, 'id' | 'nazev'>
        )> }
      )>, extendedV2: Maybe<(
        { __typename?: 'SmartAddressSdkExtendedInfo' }
        & { administrativniUdaje: Maybe<(
          { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
          & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )> }
      )> }
    )>, nationality: Maybe<(
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    )> }
  )>, insuredSports: Array<(
    { __typename?: 'CodeBook' }
    & Pick<CodeBook, 'id' | 'label'>
  )>, selectedOffer: Maybe<(
    { __typename?: 'TravelInsuranceProductOffer' }
    & Pick<TravelInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'pricePerDay' | 'hasUpsellOffer' | 'packageType'>
    & { product: Maybe<(
      { __typename?: 'TravelInsuranceProduct' }
      & Pick<TravelInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'medicalExpenseCover' | 'liabilityHealthCover' | 'liabilityPropertyCover' | 'baggageCover' | 'injuryCover' | 'type' | 'addedValue' | 'gift' | 'bonus' | 'label' | 'labelTooltip'>
      & { partner: (
        { __typename?: 'ZeteoInsurancePartner' }
        & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
        & { logo: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )>, logoSquare: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )>, rating2: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), productDetailFile: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )> }
    )> }
  )>, contract: Maybe<(
    { __typename?: 'TravelInsuranceContract' }
    & Pick<TravelInsuranceContract, 'id' | 'uuid' | 'payed'>
    & { price: (
      { __typename?: 'Money' }
      & Pick<Money, 'amount' | 'currency'>
    ), annualPrice: (
      { __typename?: 'Money' }
      & Pick<Money, 'amount' | 'currency'>
    ), documents: Array<Maybe<(
      { __typename?: 'TravelInsuranceContractDocument' }
      & Pick<TravelInsuranceContractDocument, 'id' | 'name' | 'documentType'>
      & { documentCategory: (
        { __typename?: 'TravelInsuranceCodeBook' }
        & Pick<TravelInsuranceCodeBook, 'id' | 'label'>
      ), file: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )> }
    )>>, paymentInfo: Maybe<(
      { __typename?: 'TravelInsuranceContractPaymentInfo' }
      & Pick<TravelInsuranceContractPaymentInfo, 'id' | 'account' | 'bankCode' | 'constantSymbol' | 'specificSymbol' | 'variableSymbol' | 'message' | 'paymentUrl'>
      & { amount: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency'>
      ), qrCodeFile: (
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      ) }
    )> }
  )> }
);

export type TravelInsuranceLastOrderFragment = (
  { __typename?: 'TravelInsuranceLastOrder' }
  & Pick<TravelInsuranceLastOrder, 'id' | 'insuranceType'>
  & { destination: (
    { __typename?: 'CodeBook' }
    & Pick<CodeBook, 'id' | 'label'>
  ) }
);

export type TravelInsuranceProductFragment = (
  { __typename?: 'TravelInsuranceProduct' }
  & Pick<TravelInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'medicalExpenseCover' | 'liabilityHealthCover' | 'liabilityPropertyCover' | 'baggageCover' | 'injuryCover' | 'type' | 'addedValue' | 'gift' | 'bonus' | 'label' | 'labelTooltip'>
  & { partner: (
    { __typename?: 'ZeteoInsurancePartner' }
    & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
    & { logo: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    )>, logoSquare: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    )>, rating2: Maybe<(
      { __typename?: 'NewRating' }
      & Pick<NewRating, 'id' | 'total'>
      & { categories: Array<Maybe<(
        { __typename?: 'RatingCategory' }
        & Pick<RatingCategory, 'id' | 'label' | 'value'>
      )>> }
    )> }
  ), productDetailFile: Maybe<(
    { __typename?: 'File' }
    & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
  )> }
);

export type TravelInsuranceProductOfferFragment = (
  { __typename?: 'TravelInsuranceProductOffer' }
  & Pick<TravelInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'pricePerDay' | 'hasUpsellOffer' | 'packageType'>
  & { product: Maybe<(
    { __typename?: 'TravelInsuranceProduct' }
    & Pick<TravelInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'medicalExpenseCover' | 'liabilityHealthCover' | 'liabilityPropertyCover' | 'baggageCover' | 'injuryCover' | 'type' | 'addedValue' | 'gift' | 'bonus' | 'label' | 'labelTooltip'>
    & { partner: (
      { __typename?: 'ZeteoInsurancePartner' }
      & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
      & { logo: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )>, logoSquare: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )>, rating2: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    ), productDetailFile: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    )> }
  )> }
);

export type TravelInsurancePackageFragment = (
  { __typename?: 'TravelInsurancePackage' }
  & Pick<TravelInsurancePackage, 'id' | 'uuid' | 'type' | 'name' | 'baggageRequirement' | 'injuryRequirement' | 'liabilityRequirement' | 'lowestPrice' | 'mobileOrder'>
  & { medicalExpenseCovers: Array<(
    { __typename?: 'CodeBook' }
    & Pick<CodeBook, 'id' | 'label'>
  )>, productOffers: Array<(
    { __typename?: 'TravelInsuranceProductOffer' }
    & Pick<TravelInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'pricePerDay' | 'hasUpsellOffer' | 'packageType'>
    & { product: Maybe<(
      { __typename?: 'TravelInsuranceProduct' }
      & Pick<TravelInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'medicalExpenseCover' | 'liabilityHealthCover' | 'liabilityPropertyCover' | 'baggageCover' | 'injuryCover' | 'type' | 'addedValue' | 'gift' | 'bonus' | 'label' | 'labelTooltip'>
      & { partner: (
        { __typename?: 'ZeteoInsurancePartner' }
        & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
        & { logo: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )>, logoSquare: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )>, rating2: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), productDetailFile: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )> }
    )> }
  )> }
);

export type TravelInsuranceOrderResultFragment = (
  { __typename?: 'TravelInsuranceOrderResult' }
  & Pick<TravelInsuranceOrderResult, 'id'>
  & { order: (
    { __typename?: 'TravelInsuranceOrder' }
    & Pick<TravelInsuranceOrder, 'id' | 'uuid' | 'dateFrom' | 'dateTo' | 'insuranceType' | 'travelPurpose' | 'family' | 'resultEmail' | 'createdAt' | 'updatedAt' | 'status'>
    & { destination: (
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    ), policyHolder: Maybe<(
      { __typename?: 'Person' }
      & Pick<Person, 'id' | 'uuid' | 'name' | 'surname' | 'birthDate' | 'birthNumber' | 'phone' | 'email' | 'policyHolder' | 'insured' | 'age'>
      & { address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, nationality: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    )>, insuredPersons: Array<(
      { __typename?: 'Person' }
      & Pick<Person, 'id' | 'uuid' | 'name' | 'surname' | 'birthDate' | 'birthNumber' | 'phone' | 'email' | 'policyHolder' | 'insured' | 'age'>
      & { address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, nationality: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    )>, insuredSports: Array<(
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    )>, selectedOffer: Maybe<(
      { __typename?: 'TravelInsuranceProductOffer' }
      & Pick<TravelInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'pricePerDay' | 'hasUpsellOffer' | 'packageType'>
      & { product: Maybe<(
        { __typename?: 'TravelInsuranceProduct' }
        & Pick<TravelInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'medicalExpenseCover' | 'liabilityHealthCover' | 'liabilityPropertyCover' | 'baggageCover' | 'injuryCover' | 'type' | 'addedValue' | 'gift' | 'bonus' | 'label' | 'labelTooltip'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )> }
    )>, contract: Maybe<(
      { __typename?: 'TravelInsuranceContract' }
      & Pick<TravelInsuranceContract, 'id' | 'uuid' | 'payed'>
      & { price: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency'>
      ), annualPrice: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency'>
      ), documents: Array<Maybe<(
        { __typename?: 'TravelInsuranceContractDocument' }
        & Pick<TravelInsuranceContractDocument, 'id' | 'name' | 'documentType'>
        & { documentCategory: (
          { __typename?: 'TravelInsuranceCodeBook' }
          & Pick<TravelInsuranceCodeBook, 'id' | 'label'>
        ), file: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>>, paymentInfo: Maybe<(
        { __typename?: 'TravelInsuranceContractPaymentInfo' }
        & Pick<TravelInsuranceContractPaymentInfo, 'id' | 'account' | 'bankCode' | 'constantSymbol' | 'specificSymbol' | 'variableSymbol' | 'message' | 'paymentUrl'>
        & { amount: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'currency'>
        ), qrCodeFile: (
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        ) }
      )> }
    )> }
  ), packages: Array<(
    { __typename?: 'TravelInsurancePackage' }
    & Pick<TravelInsurancePackage, 'id' | 'uuid' | 'type' | 'name' | 'baggageRequirement' | 'injuryRequirement' | 'liabilityRequirement' | 'lowestPrice' | 'mobileOrder'>
    & { medicalExpenseCovers: Array<(
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    )>, productOffers: Array<(
      { __typename?: 'TravelInsuranceProductOffer' }
      & Pick<TravelInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'pricePerDay' | 'hasUpsellOffer' | 'packageType'>
      & { product: Maybe<(
        { __typename?: 'TravelInsuranceProduct' }
        & Pick<TravelInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'medicalExpenseCover' | 'liabilityHealthCover' | 'liabilityPropertyCover' | 'baggageCover' | 'injuryCover' | 'type' | 'addedValue' | 'gift' | 'bonus' | 'label' | 'labelTooltip'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )> }
    )> }
  )>, cheapestOffer: Maybe<(
    { __typename?: 'TravelInsuranceProductOffer' }
    & Pick<TravelInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'pricePerDay' | 'hasUpsellOffer' | 'packageType'>
    & { product: Maybe<(
      { __typename?: 'TravelInsuranceProduct' }
      & Pick<TravelInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'medicalExpenseCover' | 'liabilityHealthCover' | 'liabilityPropertyCover' | 'baggageCover' | 'injuryCover' | 'type' | 'addedValue' | 'gift' | 'bonus' | 'label' | 'labelTooltip'>
      & { partner: (
        { __typename?: 'ZeteoInsurancePartner' }
        & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
        & { logo: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )>, logoSquare: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )>, rating2: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), productDetailFile: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )> }
    )> }
  )> }
);

export type TravelInsuranceCalculationStateResultFragment = (
  { __typename?: 'TravelInsuranceCalculationStateResult' }
  & { state: (
    { __typename?: 'ZeteoState' }
    & Pick<ZeteoState, 'maximumSteps' | 'finishedSteps' | 'finished' | 'error'>
  ), result: Maybe<(
    { __typename?: 'TravelInsuranceOrderResult' }
    & Pick<TravelInsuranceOrderResult, 'id'>
    & { order: (
      { __typename?: 'TravelInsuranceOrder' }
      & Pick<TravelInsuranceOrder, 'id' | 'uuid' | 'dateFrom' | 'dateTo' | 'insuranceType' | 'travelPurpose' | 'family' | 'resultEmail' | 'createdAt' | 'updatedAt' | 'status'>
      & { destination: (
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      ), policyHolder: Maybe<(
        { __typename?: 'Person' }
        & Pick<Person, 'id' | 'uuid' | 'name' | 'surname' | 'birthDate' | 'birthNumber' | 'phone' | 'email' | 'policyHolder' | 'insured' | 'age'>
        & { address: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, nationality: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )> }
      )>, insuredPersons: Array<(
        { __typename?: 'Person' }
        & Pick<Person, 'id' | 'uuid' | 'name' | 'surname' | 'birthDate' | 'birthNumber' | 'phone' | 'email' | 'policyHolder' | 'insured' | 'age'>
        & { address: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, nationality: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )> }
      )>, insuredSports: Array<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, selectedOffer: Maybe<(
        { __typename?: 'TravelInsuranceProductOffer' }
        & Pick<TravelInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'pricePerDay' | 'hasUpsellOffer' | 'packageType'>
        & { product: Maybe<(
          { __typename?: 'TravelInsuranceProduct' }
          & Pick<TravelInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'medicalExpenseCover' | 'liabilityHealthCover' | 'liabilityPropertyCover' | 'baggageCover' | 'injuryCover' | 'type' | 'addedValue' | 'gift' | 'bonus' | 'label' | 'labelTooltip'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )> }
      )>, contract: Maybe<(
        { __typename?: 'TravelInsuranceContract' }
        & Pick<TravelInsuranceContract, 'id' | 'uuid' | 'payed'>
        & { price: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'currency'>
        ), annualPrice: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'currency'>
        ), documents: Array<Maybe<(
          { __typename?: 'TravelInsuranceContractDocument' }
          & Pick<TravelInsuranceContractDocument, 'id' | 'name' | 'documentType'>
          & { documentCategory: (
            { __typename?: 'TravelInsuranceCodeBook' }
            & Pick<TravelInsuranceCodeBook, 'id' | 'label'>
          ), file: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>>, paymentInfo: Maybe<(
          { __typename?: 'TravelInsuranceContractPaymentInfo' }
          & Pick<TravelInsuranceContractPaymentInfo, 'id' | 'account' | 'bankCode' | 'constantSymbol' | 'specificSymbol' | 'variableSymbol' | 'message' | 'paymentUrl'>
          & { amount: (
            { __typename?: 'Money' }
            & Pick<Money, 'amount' | 'currency'>
          ), qrCodeFile: (
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          ) }
        )> }
      )> }
    ), packages: Array<(
      { __typename?: 'TravelInsurancePackage' }
      & Pick<TravelInsurancePackage, 'id' | 'uuid' | 'type' | 'name' | 'baggageRequirement' | 'injuryRequirement' | 'liabilityRequirement' | 'lowestPrice' | 'mobileOrder'>
      & { medicalExpenseCovers: Array<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, productOffers: Array<(
        { __typename?: 'TravelInsuranceProductOffer' }
        & Pick<TravelInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'pricePerDay' | 'hasUpsellOffer' | 'packageType'>
        & { product: Maybe<(
          { __typename?: 'TravelInsuranceProduct' }
          & Pick<TravelInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'medicalExpenseCover' | 'liabilityHealthCover' | 'liabilityPropertyCover' | 'baggageCover' | 'injuryCover' | 'type' | 'addedValue' | 'gift' | 'bonus' | 'label' | 'labelTooltip'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )> }
      )> }
    )>, cheapestOffer: Maybe<(
      { __typename?: 'TravelInsuranceProductOffer' }
      & Pick<TravelInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'pricePerDay' | 'hasUpsellOffer' | 'packageType'>
      & { product: Maybe<(
        { __typename?: 'TravelInsuranceProduct' }
        & Pick<TravelInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'medicalExpenseCover' | 'liabilityHealthCover' | 'liabilityPropertyCover' | 'baggageCover' | 'injuryCover' | 'type' | 'addedValue' | 'gift' | 'bonus' | 'label' | 'labelTooltip'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )> }
    )> }
  )> }
);

export type TravelInsuranceCodeBookFragment = (
  { __typename?: 'TravelInsuranceCodeBook' }
  & Pick<TravelInsuranceCodeBook, 'id' | 'label'>
);

export type TravelInsuranceContractDocumentFragment = (
  { __typename?: 'TravelInsuranceContractDocument' }
  & Pick<TravelInsuranceContractDocument, 'id' | 'name' | 'documentType'>
  & { documentCategory: (
    { __typename?: 'TravelInsuranceCodeBook' }
    & Pick<TravelInsuranceCodeBook, 'id' | 'label'>
  ), file: Maybe<(
    { __typename?: 'File' }
    & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
  )> }
);

export type TravelInsuranceContractPaymentInfoFragment = (
  { __typename?: 'TravelInsuranceContractPaymentInfo' }
  & Pick<TravelInsuranceContractPaymentInfo, 'id' | 'account' | 'bankCode' | 'constantSymbol' | 'specificSymbol' | 'variableSymbol' | 'message' | 'paymentUrl'>
  & { amount: (
    { __typename?: 'Money' }
    & Pick<Money, 'amount' | 'currency'>
  ), qrCodeFile: (
    { __typename?: 'File' }
    & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
  ) }
);

export type TravelInsuranceContractFragment = (
  { __typename?: 'TravelInsuranceContract' }
  & Pick<TravelInsuranceContract, 'id' | 'uuid' | 'payed'>
  & { price: (
    { __typename?: 'Money' }
    & Pick<Money, 'amount' | 'currency'>
  ), annualPrice: (
    { __typename?: 'Money' }
    & Pick<Money, 'amount' | 'currency'>
  ), documents: Array<Maybe<(
    { __typename?: 'TravelInsuranceContractDocument' }
    & Pick<TravelInsuranceContractDocument, 'id' | 'name' | 'documentType'>
    & { documentCategory: (
      { __typename?: 'TravelInsuranceCodeBook' }
      & Pick<TravelInsuranceCodeBook, 'id' | 'label'>
    ), file: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    )> }
  )>>, paymentInfo: Maybe<(
    { __typename?: 'TravelInsuranceContractPaymentInfo' }
    & Pick<TravelInsuranceContractPaymentInfo, 'id' | 'account' | 'bankCode' | 'constantSymbol' | 'specificSymbol' | 'variableSymbol' | 'message' | 'paymentUrl'>
    & { amount: (
      { __typename?: 'Money' }
      & Pick<Money, 'amount' | 'currency'>
    ), qrCodeFile: (
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    ) }
  )> }
);

export type TravelInsuranceRecapitulationInfoFragment = (
  { __typename?: 'TravelInsuranceRecapitulationInfo' }
  & Pick<TravelInsuranceRecapitulationInfo, 'oldPrice' | 'priceHasChanged'>
);

export type TravelInsuranceContractRecapitulationFragment = (
  { __typename?: 'TravelInsuranceContractRecapitulation' }
  & { order: Maybe<(
    { __typename?: 'TravelInsuranceOrder' }
    & Pick<TravelInsuranceOrder, 'id' | 'uuid' | 'dateFrom' | 'dateTo' | 'insuranceType' | 'travelPurpose' | 'family' | 'resultEmail' | 'createdAt' | 'updatedAt' | 'status'>
    & { destination: (
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    ), policyHolder: Maybe<(
      { __typename?: 'Person' }
      & Pick<Person, 'id' | 'uuid' | 'name' | 'surname' | 'birthDate' | 'birthNumber' | 'phone' | 'email' | 'policyHolder' | 'insured' | 'age'>
      & { address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, nationality: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    )>, insuredPersons: Array<(
      { __typename?: 'Person' }
      & Pick<Person, 'id' | 'uuid' | 'name' | 'surname' | 'birthDate' | 'birthNumber' | 'phone' | 'email' | 'policyHolder' | 'insured' | 'age'>
      & { address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, nationality: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    )>, insuredSports: Array<(
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    )>, selectedOffer: Maybe<(
      { __typename?: 'TravelInsuranceProductOffer' }
      & Pick<TravelInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'pricePerDay' | 'hasUpsellOffer' | 'packageType'>
      & { product: Maybe<(
        { __typename?: 'TravelInsuranceProduct' }
        & Pick<TravelInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'medicalExpenseCover' | 'liabilityHealthCover' | 'liabilityPropertyCover' | 'baggageCover' | 'injuryCover' | 'type' | 'addedValue' | 'gift' | 'bonus' | 'label' | 'labelTooltip'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )> }
    )>, contract: Maybe<(
      { __typename?: 'TravelInsuranceContract' }
      & Pick<TravelInsuranceContract, 'id' | 'uuid' | 'payed'>
      & { price: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency'>
      ), annualPrice: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency'>
      ), documents: Array<Maybe<(
        { __typename?: 'TravelInsuranceContractDocument' }
        & Pick<TravelInsuranceContractDocument, 'id' | 'name' | 'documentType'>
        & { documentCategory: (
          { __typename?: 'TravelInsuranceCodeBook' }
          & Pick<TravelInsuranceCodeBook, 'id' | 'label'>
        ), file: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>>, paymentInfo: Maybe<(
        { __typename?: 'TravelInsuranceContractPaymentInfo' }
        & Pick<TravelInsuranceContractPaymentInfo, 'id' | 'account' | 'bankCode' | 'constantSymbol' | 'specificSymbol' | 'variableSymbol' | 'message' | 'paymentUrl'>
        & { amount: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'currency'>
        ), qrCodeFile: (
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        ) }
      )> }
    )> }
  )>, recapitulationState: Maybe<(
    { __typename?: 'ZeteoState' }
    & Pick<ZeteoState, 'maximumSteps' | 'finishedSteps' | 'finished' | 'error'>
  )>, recapitulation: Maybe<(
    { __typename?: 'TravelInsuranceRecapitulationInfo' }
    & Pick<TravelInsuranceRecapitulationInfo, 'oldPrice' | 'priceHasChanged'>
  )> }
);

export type TravelInsuranceContractCompleteOrderFragment = (
  { __typename?: 'TravelInsuranceContractCompleteOrder' }
  & { finishOrderState: Maybe<(
    { __typename?: 'ZeteoState' }
    & Pick<ZeteoState, 'maximumSteps' | 'finishedSteps' | 'finished' | 'error'>
  )>, order: (
    { __typename?: 'TravelInsuranceOrder' }
    & Pick<TravelInsuranceOrder, 'id' | 'uuid' | 'dateFrom' | 'dateTo' | 'insuranceType' | 'travelPurpose' | 'family' | 'resultEmail' | 'createdAt' | 'updatedAt' | 'status'>
    & { destination: (
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    ), policyHolder: Maybe<(
      { __typename?: 'Person' }
      & Pick<Person, 'id' | 'uuid' | 'name' | 'surname' | 'birthDate' | 'birthNumber' | 'phone' | 'email' | 'policyHolder' | 'insured' | 'age'>
      & { address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, nationality: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    )>, insuredPersons: Array<(
      { __typename?: 'Person' }
      & Pick<Person, 'id' | 'uuid' | 'name' | 'surname' | 'birthDate' | 'birthNumber' | 'phone' | 'email' | 'policyHolder' | 'insured' | 'age'>
      & { address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, nationality: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    )>, insuredSports: Array<(
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    )>, selectedOffer: Maybe<(
      { __typename?: 'TravelInsuranceProductOffer' }
      & Pick<TravelInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'pricePerDay' | 'hasUpsellOffer' | 'packageType'>
      & { product: Maybe<(
        { __typename?: 'TravelInsuranceProduct' }
        & Pick<TravelInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'medicalExpenseCover' | 'liabilityHealthCover' | 'liabilityPropertyCover' | 'baggageCover' | 'injuryCover' | 'type' | 'addedValue' | 'gift' | 'bonus' | 'label' | 'labelTooltip'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )> }
    )>, contract: Maybe<(
      { __typename?: 'TravelInsuranceContract' }
      & Pick<TravelInsuranceContract, 'id' | 'uuid' | 'payed'>
      & { price: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency'>
      ), annualPrice: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency'>
      ), documents: Array<Maybe<(
        { __typename?: 'TravelInsuranceContractDocument' }
        & Pick<TravelInsuranceContractDocument, 'id' | 'name' | 'documentType'>
        & { documentCategory: (
          { __typename?: 'TravelInsuranceCodeBook' }
          & Pick<TravelInsuranceCodeBook, 'id' | 'label'>
        ), file: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>>, paymentInfo: Maybe<(
        { __typename?: 'TravelInsuranceContractPaymentInfo' }
        & Pick<TravelInsuranceContractPaymentInfo, 'id' | 'account' | 'bankCode' | 'constantSymbol' | 'specificSymbol' | 'variableSymbol' | 'message' | 'paymentUrl'>
        & { amount: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'currency'>
        ), qrCodeFile: (
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        ) }
      )> }
    )> }
  ) }
);

export type TravelInsuranceUpsellOfferFragment = (
  { __typename?: 'TravelInsuranceUpsellOffer' }
  & Pick<TravelInsuranceUpsellOffer, 'selectedPackageType' | 'upsellStoryContentSerialized'>
  & { selectedOffer: (
    { __typename?: 'TravelInsuranceProductOffer' }
    & Pick<TravelInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'pricePerDay' | 'hasUpsellOffer' | 'packageType'>
    & { product: Maybe<(
      { __typename?: 'TravelInsuranceProduct' }
      & Pick<TravelInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'medicalExpenseCover' | 'liabilityHealthCover' | 'liabilityPropertyCover' | 'baggageCover' | 'injuryCover' | 'type' | 'addedValue' | 'gift' | 'bonus' | 'label' | 'labelTooltip'>
      & { partner: (
        { __typename?: 'ZeteoInsurancePartner' }
        & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
        & { logo: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )>, logoSquare: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )>, rating2: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), productDetailFile: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )> }
    )> }
  ), upsellOfferToOptimum: Maybe<(
    { __typename?: 'TravelInsuranceProductOffer' }
    & Pick<TravelInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'pricePerDay' | 'hasUpsellOffer' | 'packageType'>
    & { product: Maybe<(
      { __typename?: 'TravelInsuranceProduct' }
      & Pick<TravelInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'medicalExpenseCover' | 'liabilityHealthCover' | 'liabilityPropertyCover' | 'baggageCover' | 'injuryCover' | 'type' | 'addedValue' | 'gift' | 'bonus' | 'label' | 'labelTooltip'>
      & { partner: (
        { __typename?: 'ZeteoInsurancePartner' }
        & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
        & { logo: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )>, logoSquare: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )>, rating2: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), productDetailFile: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )> }
    )> }
  )>, upsellOfferToPremium: Maybe<(
    { __typename?: 'TravelInsuranceProductOffer' }
    & Pick<TravelInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'pricePerDay' | 'hasUpsellOffer' | 'packageType'>
    & { product: Maybe<(
      { __typename?: 'TravelInsuranceProduct' }
      & Pick<TravelInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'medicalExpenseCover' | 'liabilityHealthCover' | 'liabilityPropertyCover' | 'baggageCover' | 'injuryCover' | 'type' | 'addedValue' | 'gift' | 'bonus' | 'label' | 'labelTooltip'>
      & { partner: (
        { __typename?: 'ZeteoInsurancePartner' }
        & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
        & { logo: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )>, logoSquare: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )>, rating2: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), productDetailFile: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )> }
    )> }
  )> }
);

export type InitTravelInsuranceOrderMutationVariables = Exact<{
  data: TravelInsuranceOrderInitInput;
}>;


export type InitTravelInsuranceOrderMutation = (
  { __typename?: 'Mutation' }
  & { order: (
    { __typename?: 'TravelInsuranceOrder' }
    & Pick<TravelInsuranceOrder, 'id' | 'uuid' | 'dateFrom' | 'dateTo' | 'insuranceType' | 'travelPurpose' | 'family' | 'resultEmail' | 'createdAt' | 'updatedAt' | 'status'>
    & { destination: (
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    ), policyHolder: Maybe<(
      { __typename?: 'Person' }
      & Pick<Person, 'id' | 'uuid' | 'name' | 'surname' | 'birthDate' | 'birthNumber' | 'phone' | 'email' | 'policyHolder' | 'insured' | 'age'>
      & { address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, nationality: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    )>, insuredPersons: Array<(
      { __typename?: 'Person' }
      & Pick<Person, 'id' | 'uuid' | 'name' | 'surname' | 'birthDate' | 'birthNumber' | 'phone' | 'email' | 'policyHolder' | 'insured' | 'age'>
      & { address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, nationality: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    )>, insuredSports: Array<(
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    )>, selectedOffer: Maybe<(
      { __typename?: 'TravelInsuranceProductOffer' }
      & Pick<TravelInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'pricePerDay' | 'hasUpsellOffer' | 'packageType'>
      & { product: Maybe<(
        { __typename?: 'TravelInsuranceProduct' }
        & Pick<TravelInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'medicalExpenseCover' | 'liabilityHealthCover' | 'liabilityPropertyCover' | 'baggageCover' | 'injuryCover' | 'type' | 'addedValue' | 'gift' | 'bonus' | 'label' | 'labelTooltip'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )> }
    )>, contract: Maybe<(
      { __typename?: 'TravelInsuranceContract' }
      & Pick<TravelInsuranceContract, 'id' | 'uuid' | 'payed'>
      & { price: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency'>
      ), annualPrice: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency'>
      ), documents: Array<Maybe<(
        { __typename?: 'TravelInsuranceContractDocument' }
        & Pick<TravelInsuranceContractDocument, 'id' | 'name' | 'documentType'>
        & { documentCategory: (
          { __typename?: 'TravelInsuranceCodeBook' }
          & Pick<TravelInsuranceCodeBook, 'id' | 'label'>
        ), file: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>>, paymentInfo: Maybe<(
        { __typename?: 'TravelInsuranceContractPaymentInfo' }
        & Pick<TravelInsuranceContractPaymentInfo, 'id' | 'account' | 'bankCode' | 'constantSymbol' | 'specificSymbol' | 'variableSymbol' | 'message' | 'paymentUrl'>
        & { amount: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'currency'>
        ), qrCodeFile: (
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        ) }
      )> }
    )> }
  ) }
);

export type UpdateTravelInsuranceOrderMutationVariables = Exact<{
  uuid: Scalars['String']['input'];
  data: TravelInsuranceOrderUpdateInput;
}>;


export type UpdateTravelInsuranceOrderMutation = (
  { __typename?: 'Mutation' }
  & { order: (
    { __typename?: 'TravelInsuranceOrder' }
    & Pick<TravelInsuranceOrder, 'id' | 'uuid' | 'dateFrom' | 'dateTo' | 'insuranceType' | 'travelPurpose' | 'family' | 'resultEmail' | 'createdAt' | 'updatedAt' | 'status'>
    & { destination: (
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    ), policyHolder: Maybe<(
      { __typename?: 'Person' }
      & Pick<Person, 'id' | 'uuid' | 'name' | 'surname' | 'birthDate' | 'birthNumber' | 'phone' | 'email' | 'policyHolder' | 'insured' | 'age'>
      & { address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, nationality: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    )>, insuredPersons: Array<(
      { __typename?: 'Person' }
      & Pick<Person, 'id' | 'uuid' | 'name' | 'surname' | 'birthDate' | 'birthNumber' | 'phone' | 'email' | 'policyHolder' | 'insured' | 'age'>
      & { address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, nationality: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    )>, insuredSports: Array<(
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    )>, selectedOffer: Maybe<(
      { __typename?: 'TravelInsuranceProductOffer' }
      & Pick<TravelInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'pricePerDay' | 'hasUpsellOffer' | 'packageType'>
      & { product: Maybe<(
        { __typename?: 'TravelInsuranceProduct' }
        & Pick<TravelInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'medicalExpenseCover' | 'liabilityHealthCover' | 'liabilityPropertyCover' | 'baggageCover' | 'injuryCover' | 'type' | 'addedValue' | 'gift' | 'bonus' | 'label' | 'labelTooltip'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )> }
    )>, contract: Maybe<(
      { __typename?: 'TravelInsuranceContract' }
      & Pick<TravelInsuranceContract, 'id' | 'uuid' | 'payed'>
      & { price: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency'>
      ), annualPrice: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency'>
      ), documents: Array<Maybe<(
        { __typename?: 'TravelInsuranceContractDocument' }
        & Pick<TravelInsuranceContractDocument, 'id' | 'name' | 'documentType'>
        & { documentCategory: (
          { __typename?: 'TravelInsuranceCodeBook' }
          & Pick<TravelInsuranceCodeBook, 'id' | 'label'>
        ), file: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>>, paymentInfo: Maybe<(
        { __typename?: 'TravelInsuranceContractPaymentInfo' }
        & Pick<TravelInsuranceContractPaymentInfo, 'id' | 'account' | 'bankCode' | 'constantSymbol' | 'specificSymbol' | 'variableSymbol' | 'message' | 'paymentUrl'>
        & { amount: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'currency'>
        ), qrCodeFile: (
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        ) }
      )> }
    )> }
  ) }
);

export type TravelInsuranceCalculationStartMutationVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;


export type TravelInsuranceCalculationStartMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'travelInsuranceOrderCalculationStart'>
);

export type TravelInsuranceOrderSelectOfferMutationVariables = Exact<{
  uuid: Scalars['String']['input'];
  data: TravelInsuranceSelectOfferInput;
}>;


export type TravelInsuranceOrderSelectOfferMutation = (
  { __typename?: 'Mutation' }
  & { travelInsuranceOrderSelectOffer: (
    { __typename?: 'TravelInsuranceOrder' }
    & Pick<TravelInsuranceOrder, 'id' | 'uuid' | 'dateFrom' | 'dateTo' | 'insuranceType' | 'travelPurpose' | 'family' | 'resultEmail' | 'createdAt' | 'updatedAt' | 'status'>
    & { destination: (
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    ), policyHolder: Maybe<(
      { __typename?: 'Person' }
      & Pick<Person, 'id' | 'uuid' | 'name' | 'surname' | 'birthDate' | 'birthNumber' | 'phone' | 'email' | 'policyHolder' | 'insured' | 'age'>
      & { address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, nationality: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    )>, insuredPersons: Array<(
      { __typename?: 'Person' }
      & Pick<Person, 'id' | 'uuid' | 'name' | 'surname' | 'birthDate' | 'birthNumber' | 'phone' | 'email' | 'policyHolder' | 'insured' | 'age'>
      & { address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, nationality: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    )>, insuredSports: Array<(
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    )>, selectedOffer: Maybe<(
      { __typename?: 'TravelInsuranceProductOffer' }
      & Pick<TravelInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'pricePerDay' | 'hasUpsellOffer' | 'packageType'>
      & { product: Maybe<(
        { __typename?: 'TravelInsuranceProduct' }
        & Pick<TravelInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'medicalExpenseCover' | 'liabilityHealthCover' | 'liabilityPropertyCover' | 'baggageCover' | 'injuryCover' | 'type' | 'addedValue' | 'gift' | 'bonus' | 'label' | 'labelTooltip'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )> }
    )>, contract: Maybe<(
      { __typename?: 'TravelInsuranceContract' }
      & Pick<TravelInsuranceContract, 'id' | 'uuid' | 'payed'>
      & { price: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency'>
      ), annualPrice: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency'>
      ), documents: Array<Maybe<(
        { __typename?: 'TravelInsuranceContractDocument' }
        & Pick<TravelInsuranceContractDocument, 'id' | 'name' | 'documentType'>
        & { documentCategory: (
          { __typename?: 'TravelInsuranceCodeBook' }
          & Pick<TravelInsuranceCodeBook, 'id' | 'label'>
        ), file: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>>, paymentInfo: Maybe<(
        { __typename?: 'TravelInsuranceContractPaymentInfo' }
        & Pick<TravelInsuranceContractPaymentInfo, 'id' | 'account' | 'bankCode' | 'constantSymbol' | 'specificSymbol' | 'variableSymbol' | 'message' | 'paymentUrl'>
        & { amount: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'currency'>
        ), qrCodeFile: (
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        ) }
      )> }
    )> }
  ) }
);

export type TravelInsuranceUpdatePolicyHolderMutationVariables = Exact<{
  uuid: Scalars['String']['input'];
  data: TravelInsurancePolicyHolderInput;
}>;


export type TravelInsuranceUpdatePolicyHolderMutation = (
  { __typename?: 'Mutation' }
  & { travelInsuranceUpdatePolicyHolder: (
    { __typename?: 'TravelInsuranceOrder' }
    & Pick<TravelInsuranceOrder, 'id' | 'uuid' | 'dateFrom' | 'dateTo' | 'insuranceType' | 'travelPurpose' | 'family' | 'resultEmail' | 'createdAt' | 'updatedAt' | 'status'>
    & { destination: (
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    ), policyHolder: Maybe<(
      { __typename?: 'Person' }
      & Pick<Person, 'id' | 'uuid' | 'name' | 'surname' | 'birthDate' | 'birthNumber' | 'phone' | 'email' | 'policyHolder' | 'insured' | 'age'>
      & { address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, nationality: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    )>, insuredPersons: Array<(
      { __typename?: 'Person' }
      & Pick<Person, 'id' | 'uuid' | 'name' | 'surname' | 'birthDate' | 'birthNumber' | 'phone' | 'email' | 'policyHolder' | 'insured' | 'age'>
      & { address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, nationality: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    )>, insuredSports: Array<(
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    )>, selectedOffer: Maybe<(
      { __typename?: 'TravelInsuranceProductOffer' }
      & Pick<TravelInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'pricePerDay' | 'hasUpsellOffer' | 'packageType'>
      & { product: Maybe<(
        { __typename?: 'TravelInsuranceProduct' }
        & Pick<TravelInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'medicalExpenseCover' | 'liabilityHealthCover' | 'liabilityPropertyCover' | 'baggageCover' | 'injuryCover' | 'type' | 'addedValue' | 'gift' | 'bonus' | 'label' | 'labelTooltip'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )> }
    )>, contract: Maybe<(
      { __typename?: 'TravelInsuranceContract' }
      & Pick<TravelInsuranceContract, 'id' | 'uuid' | 'payed'>
      & { price: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency'>
      ), annualPrice: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency'>
      ), documents: Array<Maybe<(
        { __typename?: 'TravelInsuranceContractDocument' }
        & Pick<TravelInsuranceContractDocument, 'id' | 'name' | 'documentType'>
        & { documentCategory: (
          { __typename?: 'TravelInsuranceCodeBook' }
          & Pick<TravelInsuranceCodeBook, 'id' | 'label'>
        ), file: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>>, paymentInfo: Maybe<(
        { __typename?: 'TravelInsuranceContractPaymentInfo' }
        & Pick<TravelInsuranceContractPaymentInfo, 'id' | 'account' | 'bankCode' | 'constantSymbol' | 'specificSymbol' | 'variableSymbol' | 'message' | 'paymentUrl'>
        & { amount: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'currency'>
        ), qrCodeFile: (
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        ) }
      )> }
    )> }
  ) }
);

export type TravelInsuranceUpdateInsuredPersonsMutationVariables = Exact<{
  uuid: Scalars['String']['input'];
  data: TravelInsuranceInsuredPersonsInput;
}>;


export type TravelInsuranceUpdateInsuredPersonsMutation = (
  { __typename?: 'Mutation' }
  & { travelInsuranceUpdateInsuredPersons: (
    { __typename?: 'TravelInsuranceOrder' }
    & Pick<TravelInsuranceOrder, 'id' | 'uuid' | 'dateFrom' | 'dateTo' | 'insuranceType' | 'travelPurpose' | 'family' | 'resultEmail' | 'createdAt' | 'updatedAt' | 'status'>
    & { destination: (
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    ), policyHolder: Maybe<(
      { __typename?: 'Person' }
      & Pick<Person, 'id' | 'uuid' | 'name' | 'surname' | 'birthDate' | 'birthNumber' | 'phone' | 'email' | 'policyHolder' | 'insured' | 'age'>
      & { address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, nationality: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    )>, insuredPersons: Array<(
      { __typename?: 'Person' }
      & Pick<Person, 'id' | 'uuid' | 'name' | 'surname' | 'birthDate' | 'birthNumber' | 'phone' | 'email' | 'policyHolder' | 'insured' | 'age'>
      & { address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, nationality: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    )>, insuredSports: Array<(
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    )>, selectedOffer: Maybe<(
      { __typename?: 'TravelInsuranceProductOffer' }
      & Pick<TravelInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'pricePerDay' | 'hasUpsellOffer' | 'packageType'>
      & { product: Maybe<(
        { __typename?: 'TravelInsuranceProduct' }
        & Pick<TravelInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'medicalExpenseCover' | 'liabilityHealthCover' | 'liabilityPropertyCover' | 'baggageCover' | 'injuryCover' | 'type' | 'addedValue' | 'gift' | 'bonus' | 'label' | 'labelTooltip'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )> }
    )>, contract: Maybe<(
      { __typename?: 'TravelInsuranceContract' }
      & Pick<TravelInsuranceContract, 'id' | 'uuid' | 'payed'>
      & { price: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency'>
      ), annualPrice: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency'>
      ), documents: Array<Maybe<(
        { __typename?: 'TravelInsuranceContractDocument' }
        & Pick<TravelInsuranceContractDocument, 'id' | 'name' | 'documentType'>
        & { documentCategory: (
          { __typename?: 'TravelInsuranceCodeBook' }
          & Pick<TravelInsuranceCodeBook, 'id' | 'label'>
        ), file: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>>, paymentInfo: Maybe<(
        { __typename?: 'TravelInsuranceContractPaymentInfo' }
        & Pick<TravelInsuranceContractPaymentInfo, 'id' | 'account' | 'bankCode' | 'constantSymbol' | 'specificSymbol' | 'variableSymbol' | 'message' | 'paymentUrl'>
        & { amount: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'currency'>
        ), qrCodeFile: (
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        ) }
      )> }
    )> }
  ) }
);

export type TravelInsuranceCodeBooksGetQueryVariables = Exact<{ [key: string]: never; }>;


export type TravelInsuranceCodeBooksGetQuery = (
  { __typename?: 'Query' }
  & { codeBooks: (
    { __typename?: 'TravelInsuranceCodeBooks' }
    & { country: Array<(
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    )>, destination: Array<(
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    )>, sport: Array<(
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    )> }
  ) }
);

export type TravelInsuranceOrderGetLastQueryVariables = Exact<{ [key: string]: never; }>;


export type TravelInsuranceOrderGetLastQuery = (
  { __typename?: 'Query' }
  & { travelInsuranceOrderGetLast: Maybe<(
    { __typename?: 'TravelInsuranceLastOrder' }
    & Pick<TravelInsuranceLastOrder, 'id' | 'insuranceType'>
    & { destination: (
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    ) }
  )> }
);

export type TravelInsuranceOrderGetQueryVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;


export type TravelInsuranceOrderGetQuery = (
  { __typename?: 'Query' }
  & { order: (
    { __typename?: 'TravelInsuranceOrder' }
    & Pick<TravelInsuranceOrder, 'id' | 'uuid' | 'dateFrom' | 'dateTo' | 'insuranceType' | 'travelPurpose' | 'family' | 'resultEmail' | 'createdAt' | 'updatedAt' | 'status'>
    & { destination: (
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    ), policyHolder: Maybe<(
      { __typename?: 'Person' }
      & Pick<Person, 'id' | 'uuid' | 'name' | 'surname' | 'birthDate' | 'birthNumber' | 'phone' | 'email' | 'policyHolder' | 'insured' | 'age'>
      & { address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, nationality: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    )>, insuredPersons: Array<(
      { __typename?: 'Person' }
      & Pick<Person, 'id' | 'uuid' | 'name' | 'surname' | 'birthDate' | 'birthNumber' | 'phone' | 'email' | 'policyHolder' | 'insured' | 'age'>
      & { address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, nationality: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    )>, insuredSports: Array<(
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    )>, selectedOffer: Maybe<(
      { __typename?: 'TravelInsuranceProductOffer' }
      & Pick<TravelInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'pricePerDay' | 'hasUpsellOffer' | 'packageType'>
      & { product: Maybe<(
        { __typename?: 'TravelInsuranceProduct' }
        & Pick<TravelInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'medicalExpenseCover' | 'liabilityHealthCover' | 'liabilityPropertyCover' | 'baggageCover' | 'injuryCover' | 'type' | 'addedValue' | 'gift' | 'bonus' | 'label' | 'labelTooltip'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )> }
    )>, contract: Maybe<(
      { __typename?: 'TravelInsuranceContract' }
      & Pick<TravelInsuranceContract, 'id' | 'uuid' | 'payed'>
      & { price: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency'>
      ), annualPrice: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency'>
      ), documents: Array<Maybe<(
        { __typename?: 'TravelInsuranceContractDocument' }
        & Pick<TravelInsuranceContractDocument, 'id' | 'name' | 'documentType'>
        & { documentCategory: (
          { __typename?: 'TravelInsuranceCodeBook' }
          & Pick<TravelInsuranceCodeBook, 'id' | 'label'>
        ), file: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>>, paymentInfo: Maybe<(
        { __typename?: 'TravelInsuranceContractPaymentInfo' }
        & Pick<TravelInsuranceContractPaymentInfo, 'id' | 'account' | 'bankCode' | 'constantSymbol' | 'specificSymbol' | 'variableSymbol' | 'message' | 'paymentUrl'>
        & { amount: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'currency'>
        ), qrCodeFile: (
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        ) }
      )> }
    )> }
  ) }
);

export type TravelInsuranceOrderCalculationResultGetQueryVariables = Exact<{
  orderUuid: Scalars['String']['input'];
}>;


export type TravelInsuranceOrderCalculationResultGetQuery = (
  { __typename?: 'Query' }
  & { calculationResult: (
    { __typename?: 'TravelInsuranceCalculationStateResult' }
    & { state: (
      { __typename?: 'ZeteoState' }
      & Pick<ZeteoState, 'maximumSteps' | 'finishedSteps' | 'finished' | 'error'>
    ), result: Maybe<(
      { __typename?: 'TravelInsuranceOrderResult' }
      & Pick<TravelInsuranceOrderResult, 'id'>
      & { order: (
        { __typename?: 'TravelInsuranceOrder' }
        & Pick<TravelInsuranceOrder, 'id' | 'uuid' | 'dateFrom' | 'dateTo' | 'insuranceType' | 'travelPurpose' | 'family' | 'resultEmail' | 'createdAt' | 'updatedAt' | 'status'>
        & { destination: (
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        ), policyHolder: Maybe<(
          { __typename?: 'Person' }
          & Pick<Person, 'id' | 'uuid' | 'name' | 'surname' | 'birthDate' | 'birthNumber' | 'phone' | 'email' | 'policyHolder' | 'insured' | 'age'>
          & { address: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
            & { extended: Maybe<(
              { __typename?: 'SmartAddressExtendedInfo' }
              & Pick<SmartAddressExtendedInfo, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )>, extendedV2: Maybe<(
              { __typename?: 'SmartAddressSdkExtendedInfo' }
              & { administrativniUdaje: Maybe<(
                { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
                & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
                & { objectType: Maybe<(
                  { __typename?: 'SmartAddressObjectType' }
                  & Pick<SmartAddressObjectType, 'id' | 'nazev'>
                )> }
              )> }
            )> }
          )>, nationality: Maybe<(
            { __typename?: 'CodeBook' }
            & Pick<CodeBook, 'id' | 'label'>
          )> }
        )>, insuredPersons: Array<(
          { __typename?: 'Person' }
          & Pick<Person, 'id' | 'uuid' | 'name' | 'surname' | 'birthDate' | 'birthNumber' | 'phone' | 'email' | 'policyHolder' | 'insured' | 'age'>
          & { address: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
            & { extended: Maybe<(
              { __typename?: 'SmartAddressExtendedInfo' }
              & Pick<SmartAddressExtendedInfo, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )>, extendedV2: Maybe<(
              { __typename?: 'SmartAddressSdkExtendedInfo' }
              & { administrativniUdaje: Maybe<(
                { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
                & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
                & { objectType: Maybe<(
                  { __typename?: 'SmartAddressObjectType' }
                  & Pick<SmartAddressObjectType, 'id' | 'nazev'>
                )> }
              )> }
            )> }
          )>, nationality: Maybe<(
            { __typename?: 'CodeBook' }
            & Pick<CodeBook, 'id' | 'label'>
          )> }
        )>, insuredSports: Array<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, selectedOffer: Maybe<(
          { __typename?: 'TravelInsuranceProductOffer' }
          & Pick<TravelInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'pricePerDay' | 'hasUpsellOffer' | 'packageType'>
          & { product: Maybe<(
            { __typename?: 'TravelInsuranceProduct' }
            & Pick<TravelInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'medicalExpenseCover' | 'liabilityHealthCover' | 'liabilityPropertyCover' | 'baggageCover' | 'injuryCover' | 'type' | 'addedValue' | 'gift' | 'bonus' | 'label' | 'labelTooltip'>
            & { partner: (
              { __typename?: 'ZeteoInsurancePartner' }
              & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
              & { logo: Maybe<(
                { __typename?: 'File' }
                & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
              )>, logoSquare: Maybe<(
                { __typename?: 'File' }
                & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
              )>, rating2: Maybe<(
                { __typename?: 'NewRating' }
                & Pick<NewRating, 'id' | 'total'>
                & { categories: Array<Maybe<(
                  { __typename?: 'RatingCategory' }
                  & Pick<RatingCategory, 'id' | 'label' | 'value'>
                )>> }
              )> }
            ), productDetailFile: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )> }
          )> }
        )>, contract: Maybe<(
          { __typename?: 'TravelInsuranceContract' }
          & Pick<TravelInsuranceContract, 'id' | 'uuid' | 'payed'>
          & { price: (
            { __typename?: 'Money' }
            & Pick<Money, 'amount' | 'currency'>
          ), annualPrice: (
            { __typename?: 'Money' }
            & Pick<Money, 'amount' | 'currency'>
          ), documents: Array<Maybe<(
            { __typename?: 'TravelInsuranceContractDocument' }
            & Pick<TravelInsuranceContractDocument, 'id' | 'name' | 'documentType'>
            & { documentCategory: (
              { __typename?: 'TravelInsuranceCodeBook' }
              & Pick<TravelInsuranceCodeBook, 'id' | 'label'>
            ), file: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )> }
          )>>, paymentInfo: Maybe<(
            { __typename?: 'TravelInsuranceContractPaymentInfo' }
            & Pick<TravelInsuranceContractPaymentInfo, 'id' | 'account' | 'bankCode' | 'constantSymbol' | 'specificSymbol' | 'variableSymbol' | 'message' | 'paymentUrl'>
            & { amount: (
              { __typename?: 'Money' }
              & Pick<Money, 'amount' | 'currency'>
            ), qrCodeFile: (
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            ) }
          )> }
        )> }
      ), packages: Array<(
        { __typename?: 'TravelInsurancePackage' }
        & Pick<TravelInsurancePackage, 'id' | 'uuid' | 'type' | 'name' | 'baggageRequirement' | 'injuryRequirement' | 'liabilityRequirement' | 'lowestPrice' | 'mobileOrder'>
        & { medicalExpenseCovers: Array<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, productOffers: Array<(
          { __typename?: 'TravelInsuranceProductOffer' }
          & Pick<TravelInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'pricePerDay' | 'hasUpsellOffer' | 'packageType'>
          & { product: Maybe<(
            { __typename?: 'TravelInsuranceProduct' }
            & Pick<TravelInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'medicalExpenseCover' | 'liabilityHealthCover' | 'liabilityPropertyCover' | 'baggageCover' | 'injuryCover' | 'type' | 'addedValue' | 'gift' | 'bonus' | 'label' | 'labelTooltip'>
            & { partner: (
              { __typename?: 'ZeteoInsurancePartner' }
              & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
              & { logo: Maybe<(
                { __typename?: 'File' }
                & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
              )>, logoSquare: Maybe<(
                { __typename?: 'File' }
                & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
              )>, rating2: Maybe<(
                { __typename?: 'NewRating' }
                & Pick<NewRating, 'id' | 'total'>
                & { categories: Array<Maybe<(
                  { __typename?: 'RatingCategory' }
                  & Pick<RatingCategory, 'id' | 'label' | 'value'>
                )>> }
              )> }
            ), productDetailFile: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )> }
          )> }
        )> }
      )>, cheapestOffer: Maybe<(
        { __typename?: 'TravelInsuranceProductOffer' }
        & Pick<TravelInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'pricePerDay' | 'hasUpsellOffer' | 'packageType'>
        & { product: Maybe<(
          { __typename?: 'TravelInsuranceProduct' }
          & Pick<TravelInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'medicalExpenseCover' | 'liabilityHealthCover' | 'liabilityPropertyCover' | 'baggageCover' | 'injuryCover' | 'type' | 'addedValue' | 'gift' | 'bonus' | 'label' | 'labelTooltip'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )> }
      )> }
    )> }
  ) }
);

export type TravelInsuranceContractRecapitulationQueryVariables = Exact<{
  orderUuid: Scalars['String']['input'];
}>;


export type TravelInsuranceContractRecapitulationQuery = (
  { __typename?: 'Query' }
  & { contractRecapitulation: (
    { __typename?: 'TravelInsuranceContractRecapitulation' }
    & { order: Maybe<(
      { __typename?: 'TravelInsuranceOrder' }
      & Pick<TravelInsuranceOrder, 'id' | 'uuid' | 'dateFrom' | 'dateTo' | 'insuranceType' | 'travelPurpose' | 'family' | 'resultEmail' | 'createdAt' | 'updatedAt' | 'status'>
      & { destination: (
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      ), policyHolder: Maybe<(
        { __typename?: 'Person' }
        & Pick<Person, 'id' | 'uuid' | 'name' | 'surname' | 'birthDate' | 'birthNumber' | 'phone' | 'email' | 'policyHolder' | 'insured' | 'age'>
        & { address: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, nationality: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )> }
      )>, insuredPersons: Array<(
        { __typename?: 'Person' }
        & Pick<Person, 'id' | 'uuid' | 'name' | 'surname' | 'birthDate' | 'birthNumber' | 'phone' | 'email' | 'policyHolder' | 'insured' | 'age'>
        & { address: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, nationality: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )> }
      )>, insuredSports: Array<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, selectedOffer: Maybe<(
        { __typename?: 'TravelInsuranceProductOffer' }
        & Pick<TravelInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'pricePerDay' | 'hasUpsellOffer' | 'packageType'>
        & { product: Maybe<(
          { __typename?: 'TravelInsuranceProduct' }
          & Pick<TravelInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'medicalExpenseCover' | 'liabilityHealthCover' | 'liabilityPropertyCover' | 'baggageCover' | 'injuryCover' | 'type' | 'addedValue' | 'gift' | 'bonus' | 'label' | 'labelTooltip'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )> }
      )>, contract: Maybe<(
        { __typename?: 'TravelInsuranceContract' }
        & Pick<TravelInsuranceContract, 'id' | 'uuid' | 'payed'>
        & { price: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'currency'>
        ), annualPrice: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'currency'>
        ), documents: Array<Maybe<(
          { __typename?: 'TravelInsuranceContractDocument' }
          & Pick<TravelInsuranceContractDocument, 'id' | 'name' | 'documentType'>
          & { documentCategory: (
            { __typename?: 'TravelInsuranceCodeBook' }
            & Pick<TravelInsuranceCodeBook, 'id' | 'label'>
          ), file: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>>, paymentInfo: Maybe<(
          { __typename?: 'TravelInsuranceContractPaymentInfo' }
          & Pick<TravelInsuranceContractPaymentInfo, 'id' | 'account' | 'bankCode' | 'constantSymbol' | 'specificSymbol' | 'variableSymbol' | 'message' | 'paymentUrl'>
          & { amount: (
            { __typename?: 'Money' }
            & Pick<Money, 'amount' | 'currency'>
          ), qrCodeFile: (
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          ) }
        )> }
      )> }
    )>, recapitulationState: Maybe<(
      { __typename?: 'ZeteoState' }
      & Pick<ZeteoState, 'maximumSteps' | 'finishedSteps' | 'finished' | 'error'>
    )>, recapitulation: Maybe<(
      { __typename?: 'TravelInsuranceRecapitulationInfo' }
      & Pick<TravelInsuranceRecapitulationInfo, 'oldPrice' | 'priceHasChanged'>
    )> }
  ) }
);

export type TravelInsuranceContractOrderCompletedQueryVariables = Exact<{
  orderUuid: Scalars['String']['input'];
}>;


export type TravelInsuranceContractOrderCompletedQuery = (
  { __typename?: 'Query' }
  & { completedOrder: (
    { __typename?: 'TravelInsuranceContractCompleteOrder' }
    & { finishOrderState: Maybe<(
      { __typename?: 'ZeteoState' }
      & Pick<ZeteoState, 'maximumSteps' | 'finishedSteps' | 'finished' | 'error'>
    )>, order: (
      { __typename?: 'TravelInsuranceOrder' }
      & Pick<TravelInsuranceOrder, 'id' | 'uuid' | 'dateFrom' | 'dateTo' | 'insuranceType' | 'travelPurpose' | 'family' | 'resultEmail' | 'createdAt' | 'updatedAt' | 'status'>
      & { destination: (
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      ), policyHolder: Maybe<(
        { __typename?: 'Person' }
        & Pick<Person, 'id' | 'uuid' | 'name' | 'surname' | 'birthDate' | 'birthNumber' | 'phone' | 'email' | 'policyHolder' | 'insured' | 'age'>
        & { address: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, nationality: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )> }
      )>, insuredPersons: Array<(
        { __typename?: 'Person' }
        & Pick<Person, 'id' | 'uuid' | 'name' | 'surname' | 'birthDate' | 'birthNumber' | 'phone' | 'email' | 'policyHolder' | 'insured' | 'age'>
        & { address: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, nationality: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )> }
      )>, insuredSports: Array<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, selectedOffer: Maybe<(
        { __typename?: 'TravelInsuranceProductOffer' }
        & Pick<TravelInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'pricePerDay' | 'hasUpsellOffer' | 'packageType'>
        & { product: Maybe<(
          { __typename?: 'TravelInsuranceProduct' }
          & Pick<TravelInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'medicalExpenseCover' | 'liabilityHealthCover' | 'liabilityPropertyCover' | 'baggageCover' | 'injuryCover' | 'type' | 'addedValue' | 'gift' | 'bonus' | 'label' | 'labelTooltip'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )> }
      )>, contract: Maybe<(
        { __typename?: 'TravelInsuranceContract' }
        & Pick<TravelInsuranceContract, 'id' | 'uuid' | 'payed'>
        & { price: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'currency'>
        ), annualPrice: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'currency'>
        ), documents: Array<Maybe<(
          { __typename?: 'TravelInsuranceContractDocument' }
          & Pick<TravelInsuranceContractDocument, 'id' | 'name' | 'documentType'>
          & { documentCategory: (
            { __typename?: 'TravelInsuranceCodeBook' }
            & Pick<TravelInsuranceCodeBook, 'id' | 'label'>
          ), file: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>>, paymentInfo: Maybe<(
          { __typename?: 'TravelInsuranceContractPaymentInfo' }
          & Pick<TravelInsuranceContractPaymentInfo, 'id' | 'account' | 'bankCode' | 'constantSymbol' | 'specificSymbol' | 'variableSymbol' | 'message' | 'paymentUrl'>
          & { amount: (
            { __typename?: 'Money' }
            & Pick<Money, 'amount' | 'currency'>
          ), qrCodeFile: (
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          ) }
        )> }
      )> }
    ) }
  ) }
);

export type TravelInsuranceUpsellOfferGetQueryVariables = Exact<{
  orderUuid: Scalars['String']['input'];
}>;


export type TravelInsuranceUpsellOfferGetQuery = (
  { __typename?: 'Query' }
  & { upsellOffer: (
    { __typename?: 'TravelInsuranceUpsellOffer' }
    & Pick<TravelInsuranceUpsellOffer, 'selectedPackageType' | 'upsellStoryContentSerialized'>
    & { selectedOffer: (
      { __typename?: 'TravelInsuranceProductOffer' }
      & Pick<TravelInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'pricePerDay' | 'hasUpsellOffer' | 'packageType'>
      & { product: Maybe<(
        { __typename?: 'TravelInsuranceProduct' }
        & Pick<TravelInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'medicalExpenseCover' | 'liabilityHealthCover' | 'liabilityPropertyCover' | 'baggageCover' | 'injuryCover' | 'type' | 'addedValue' | 'gift' | 'bonus' | 'label' | 'labelTooltip'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )> }
    ), upsellOfferToOptimum: Maybe<(
      { __typename?: 'TravelInsuranceProductOffer' }
      & Pick<TravelInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'pricePerDay' | 'hasUpsellOffer' | 'packageType'>
      & { product: Maybe<(
        { __typename?: 'TravelInsuranceProduct' }
        & Pick<TravelInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'medicalExpenseCover' | 'liabilityHealthCover' | 'liabilityPropertyCover' | 'baggageCover' | 'injuryCover' | 'type' | 'addedValue' | 'gift' | 'bonus' | 'label' | 'labelTooltip'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )> }
    )>, upsellOfferToPremium: Maybe<(
      { __typename?: 'TravelInsuranceProductOffer' }
      & Pick<TravelInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'pricePerDay' | 'hasUpsellOffer' | 'packageType'>
      & { product: Maybe<(
        { __typename?: 'TravelInsuranceProduct' }
        & Pick<TravelInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'medicalExpenseCover' | 'liabilityHealthCover' | 'liabilityPropertyCover' | 'baggageCover' | 'injuryCover' | 'type' | 'addedValue' | 'gift' | 'bonus' | 'label' | 'labelTooltip'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )> }
    )> }
  ) }
);

export type CityPartFragment = (
  { __typename?: 'CityPart' }
  & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
);

export type CodeBookFragment = (
  { __typename?: 'CodeBook' }
  & Pick<CodeBook, 'id' | 'label'>
);

export type VehicleInsuranceCodeBooksFragment = (
  { __typename?: 'VehicleInsuranceCodeBooks' }
  & { carUsageMethod: Array<(
    { __typename?: 'CodeBook' }
    & Pick<CodeBook, 'id' | 'label'>
  )>, country: Array<(
    { __typename?: 'CodeBook' }
    & Pick<CodeBook, 'id' | 'label'>
  )>, kilometersPerYearVehicle: Array<(
    { __typename?: 'CodeBook' }
    & Pick<CodeBook, 'id' | 'label'>
  )>, vehicleAcquisitionMethod: Array<(
    { __typename?: 'CodeBook' }
    & Pick<CodeBook, 'id' | 'label'>
  )>, additionalEquipment: Array<(
    { __typename?: 'CodeBook' }
    & Pick<CodeBook, 'id' | 'label'>
  )>, representativeFunctions: Array<(
    { __typename?: 'CodeBook' }
    & Pick<CodeBook, 'id' | 'label'>
  )> }
);

export type VehicleSearchParametersResponseFragment = (
  { __typename?: 'VehicleSearchParametersResponse' }
  & Pick<VehicleSearchParametersResponse, 'vehicleType'>
  & { modelLine: Array<(
    { __typename?: 'CodeBook' }
    & Pick<CodeBook, 'id' | 'label'>
  )>, model: Array<(
    { __typename?: 'CodeBook' }
    & Pick<CodeBook, 'id' | 'label'>
  )>, modelType: Array<(
    { __typename?: 'CodeBook' }
    & Pick<CodeBook, 'id' | 'label'>
  )>, manufacturer: Array<(
    { __typename?: 'CodeBook' }
    & Pick<CodeBook, 'id' | 'label'>
  )>, fuel: Array<(
    { __typename?: 'CodeBook' }
    & Pick<CodeBook, 'id' | 'label'>
  )>, engineDisplacement: Array<(
    { __typename?: 'CodeBook' }
    & Pick<CodeBook, 'id' | 'label'>
  )>, maxPower: Array<(
    { __typename?: 'CodeBook' }
    & Pick<CodeBook, 'id' | 'label'>
  )>, countOfSeats: Array<(
    { __typename?: 'CodeBook' }
    & Pick<CodeBook, 'id' | 'label'>
  )>, maxWeight: Array<(
    { __typename?: 'CodeBook' }
    & Pick<CodeBook, 'id' | 'label'>
  )> }
);

export type VehicleInsurancePackageFragment = (
  { __typename?: 'VehicleInsurancePackage' }
  & Pick<VehicleInsurancePackage, 'id' | 'uuid' | 'type' | 'name' | 'protectionCover' | 'lowestPrice' | 'participation' | 'mobileOrder'>
  & { productOffers: Array<(
    { __typename?: 'VehicleInsuranceProductOffer' }
    & Pick<VehicleInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'paymentFrequency' | 'calculatedPaymentFrequency'>
    & { mtplProduct: Maybe<(
      { __typename?: 'VehicleInsuranceProduct' }
      & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
      & { partner: (
        { __typename?: 'ZeteoInsurancePartner' }
        & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
        & { logo: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )>, logoSquare: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )>, rating2: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), productDetailFile: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )> }
    )>, cascoProduct: Maybe<(
      { __typename?: 'VehicleInsuranceProduct' }
      & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
      & { partner: (
        { __typename?: 'ZeteoInsurancePartner' }
        & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
        & { logo: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )>, logoSquare: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )>, rating2: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), productDetailFile: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )> }
    )>, estimatedPrice: (
      { __typename?: 'VehicleInsuranceEstimatedPrice' }
      & Pick<VehicleInsuranceEstimatedPrice, 'yearly' | 'quarterly' | 'halfYearly' | 'monthly'>
    ) }
  )>, additionalRequirements: (
    { __typename?: 'VehicleInsuranceAdditionalRequirements' }
    & Pick<VehicleInsuranceAdditionalRequirements, 'id'>
    & { glass: (
      { __typename?: 'VehicleInsuranceGlassRequirement' }
      & Pick<VehicleInsuranceGlassRequirement, 'enabled' | 'value'>
    ), animalAccident: (
      { __typename?: 'VehicleInsuranceAnimalAccidentRequirement' }
      & Pick<VehicleInsuranceAnimalAccidentRequirement, 'enabled' | 'value'>
    ) }
  ) }
);

export type VehicleInsuranceProductPreviewFragment = (
  { __typename?: 'VehicleInsuranceProduct' }
  & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName'>
);

export type VehicleInsuranceProductFragment = (
  { __typename?: 'VehicleInsuranceProduct' }
  & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
  & { partner: (
    { __typename?: 'ZeteoInsurancePartner' }
    & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
    & { logo: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    )>, logoSquare: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    )>, rating2: Maybe<(
      { __typename?: 'NewRating' }
      & Pick<NewRating, 'id' | 'total'>
      & { categories: Array<Maybe<(
        { __typename?: 'RatingCategory' }
        & Pick<RatingCategory, 'id' | 'label' | 'value'>
      )>> }
    )> }
  ), productDetailFile: Maybe<(
    { __typename?: 'File' }
    & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
  )> }
);

export type VehicleInsuranceEstimatedPriceFragment = (
  { __typename?: 'VehicleInsuranceEstimatedPrice' }
  & Pick<VehicleInsuranceEstimatedPrice, 'yearly' | 'quarterly' | 'halfYearly' | 'monthly'>
);

export type VehicleInsuranceProductOfferFragment = (
  { __typename?: 'VehicleInsuranceProductOffer' }
  & Pick<VehicleInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'paymentFrequency' | 'calculatedPaymentFrequency'>
  & { mtplProduct: Maybe<(
    { __typename?: 'VehicleInsuranceProduct' }
    & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
    & { partner: (
      { __typename?: 'ZeteoInsurancePartner' }
      & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
      & { logo: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )>, logoSquare: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )>, rating2: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    ), productDetailFile: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    )> }
  )>, cascoProduct: Maybe<(
    { __typename?: 'VehicleInsuranceProduct' }
    & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
    & { partner: (
      { __typename?: 'ZeteoInsurancePartner' }
      & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
      & { logo: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )>, logoSquare: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )>, rating2: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    ), productDetailFile: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    )> }
  )>, estimatedPrice: (
    { __typename?: 'VehicleInsuranceEstimatedPrice' }
    & Pick<VehicleInsuranceEstimatedPrice, 'yearly' | 'quarterly' | 'halfYearly' | 'monthly'>
  ) }
);

export type VehicleInsuranceOrderResultFragment = (
  { __typename?: 'VehicleInsuranceOrderResult' }
  & Pick<VehicleInsuranceOrderResult, 'id' | 'lowestPrice'>
  & { order: (
    { __typename?: 'VehicleInsuranceOrder' }
    & Pick<VehicleInsuranceOrder, 'id' | 'uuid' | 'createdAt' | 'status' | 'packagesInsuranceType' | 'selectedInsuranceType' | 'insurableInterest' | 'resultEmail' | 'phone' | 'calculationCurrentStep' | 'orderCurrentStep' | 'updatedAt' | 'hasSpecificPackages' | 'beginDate'>
    & { vehicle: (
      { __typename?: 'InsuredVehicle' }
      & Pick<InsuredVehicle, 'uuid' | 'vehicleType' | 'registrationNumber' | 'vin' | 'kilometersTotal' | 'ownedMoreThanAYear' | 'newVehicle' | 'contractualCarService' | 'vehicleValue' | 'vehicleValueWithVat' | 'firstRegistrationDate' | 'numberOfRegistrationCertificate' | 'vehicleSearchStatus'>
      & { expectedKilometersPerYear: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, manufacturer: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, fuel: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, engineDisplacement: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, maxPower: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, countOfSeats: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, maxWeight: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, modelLine: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, model: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, modelType: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, security: (
        { __typename?: 'VehicleSecurity' }
        & Pick<VehicleSecurity, 'none' | 'mechanical' | 'passiveSearch' | 'activeSearch' | 'glassVin' | 'immobilizer' | 'alarm'>
      ), carUsageMethod: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, additionalEquipment: Array<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    ), subjectInsured: (
      { __typename?: 'InsuredSubject' }
      & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
      & { cityPart: Maybe<(
        { __typename?: 'CityPart' }
        & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
      )>, address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, correspondenceAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, functionOfRepresentative: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    ), subjectCarHolder: Maybe<(
      { __typename?: 'InsuredSubject' }
      & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
      & { cityPart: Maybe<(
        { __typename?: 'CityPart' }
        & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
      )>, address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, correspondenceAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, functionOfRepresentative: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    )>, subjectCarOwner: Maybe<(
      { __typename?: 'InsuredSubject' }
      & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
      & { cityPart: Maybe<(
        { __typename?: 'CityPart' }
        & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
      )>, address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, correspondenceAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, functionOfRepresentative: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    )>, selectedOffer: Maybe<(
      { __typename?: 'VehicleInsuranceProductOffer' }
      & Pick<VehicleInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'paymentFrequency' | 'calculatedPaymentFrequency'>
      & { mtplProduct: Maybe<(
        { __typename?: 'VehicleInsuranceProduct' }
        & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, cascoProduct: Maybe<(
        { __typename?: 'VehicleInsuranceProduct' }
        & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, estimatedPrice: (
        { __typename?: 'VehicleInsuranceEstimatedPrice' }
        & Pick<VehicleInsuranceEstimatedPrice, 'yearly' | 'quarterly' | 'halfYearly' | 'monthly'>
      ) }
    )>, contactPerson: Maybe<(
      { __typename?: 'VehicleInsuranceContactPerson' }
      & Pick<VehicleInsuranceContactPerson, 'name' | 'surname' | 'email' | 'phone' | 'agreementProcessPersonalData' | 'agreementTermsAndConditions'>
    )>, contract: Maybe<(
      { __typename?: 'VehicleInsuranceContract' }
      & Pick<VehicleInsuranceContract, 'id' | 'uuid' | 'payed' | 'paymentUrl'>
      & { price: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency'>
      ), annualPrice: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency'>
      ), documents: Array<Maybe<(
        { __typename?: 'VehicleInsuranceContractDocument' }
        & Pick<VehicleInsuranceContractDocument, 'id' | 'name' | 'documentType'>
        & { documentCategory: (
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        ), file: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>>, paymentInfo: Maybe<(
        { __typename?: 'VehicleInsuranceContractPaymentInfo' }
        & Pick<VehicleInsuranceContractPaymentInfo, 'account' | 'bankCode' | 'constantSymbol' | 'specificSymbol' | 'variableSymbol' | 'message' | 'paymentUrl'>
        & { amount: Maybe<(
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'currency'>
        )>, qrCodeFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, casco: Maybe<(
        { __typename?: 'VehicleInsuranceProduct' }
        & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, mtpl: Maybe<(
        { __typename?: 'VehicleInsuranceProduct' }
        & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )> }
    )>, additionalOptimumRequirements: (
      { __typename?: 'VehicleInsuranceAdditionalRequirementsFromClient' }
      & Pick<VehicleInsuranceAdditionalRequirementsFromClient, 'glass' | 'animalAccident'>
    ) }
  ), packages: Array<(
    { __typename?: 'VehicleInsurancePackage' }
    & Pick<VehicleInsurancePackage, 'id' | 'uuid' | 'type' | 'name' | 'protectionCover' | 'lowestPrice' | 'participation' | 'mobileOrder'>
    & { productOffers: Array<(
      { __typename?: 'VehicleInsuranceProductOffer' }
      & Pick<VehicleInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'paymentFrequency' | 'calculatedPaymentFrequency'>
      & { mtplProduct: Maybe<(
        { __typename?: 'VehicleInsuranceProduct' }
        & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, cascoProduct: Maybe<(
        { __typename?: 'VehicleInsuranceProduct' }
        & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, estimatedPrice: (
        { __typename?: 'VehicleInsuranceEstimatedPrice' }
        & Pick<VehicleInsuranceEstimatedPrice, 'yearly' | 'quarterly' | 'halfYearly' | 'monthly'>
      ) }
    )>, additionalRequirements: (
      { __typename?: 'VehicleInsuranceAdditionalRequirements' }
      & Pick<VehicleInsuranceAdditionalRequirements, 'id'>
      & { glass: (
        { __typename?: 'VehicleInsuranceGlassRequirement' }
        & Pick<VehicleInsuranceGlassRequirement, 'enabled' | 'value'>
      ), animalAccident: (
        { __typename?: 'VehicleInsuranceAnimalAccidentRequirement' }
        & Pick<VehicleInsuranceAnimalAccidentRequirement, 'enabled' | 'value'>
      ) }
    ) }
  )>, cheapestOffer: Maybe<(
    { __typename?: 'VehicleInsuranceProductOffer' }
    & Pick<VehicleInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'paymentFrequency' | 'calculatedPaymentFrequency'>
    & { mtplProduct: Maybe<(
      { __typename?: 'VehicleInsuranceProduct' }
      & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
      & { partner: (
        { __typename?: 'ZeteoInsurancePartner' }
        & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
        & { logo: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )>, logoSquare: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )>, rating2: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), productDetailFile: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )> }
    )>, cascoProduct: Maybe<(
      { __typename?: 'VehicleInsuranceProduct' }
      & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
      & { partner: (
        { __typename?: 'ZeteoInsurancePartner' }
        & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
        & { logo: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )>, logoSquare: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )>, rating2: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), productDetailFile: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )> }
    )>, estimatedPrice: (
      { __typename?: 'VehicleInsuranceEstimatedPrice' }
      & Pick<VehicleInsuranceEstimatedPrice, 'yearly' | 'quarterly' | 'halfYearly' | 'monthly'>
    ) }
  )> }
);

export type VehicleInsuranceCalculationStateResultFragment = (
  { __typename?: 'VehicleInsuranceCalculationStateResult' }
  & { state: (
    { __typename?: 'ZeteoState' }
    & Pick<ZeteoState, 'maximumSteps' | 'finishedSteps' | 'finished' | 'error'>
  ), result: Maybe<(
    { __typename?: 'VehicleInsuranceOrderResult' }
    & Pick<VehicleInsuranceOrderResult, 'id' | 'lowestPrice'>
    & { order: (
      { __typename?: 'VehicleInsuranceOrder' }
      & Pick<VehicleInsuranceOrder, 'id' | 'uuid' | 'createdAt' | 'status' | 'packagesInsuranceType' | 'selectedInsuranceType' | 'insurableInterest' | 'resultEmail' | 'phone' | 'calculationCurrentStep' | 'orderCurrentStep' | 'updatedAt' | 'hasSpecificPackages' | 'beginDate'>
      & { vehicle: (
        { __typename?: 'InsuredVehicle' }
        & Pick<InsuredVehicle, 'uuid' | 'vehicleType' | 'registrationNumber' | 'vin' | 'kilometersTotal' | 'ownedMoreThanAYear' | 'newVehicle' | 'contractualCarService' | 'vehicleValue' | 'vehicleValueWithVat' | 'firstRegistrationDate' | 'numberOfRegistrationCertificate' | 'vehicleSearchStatus'>
        & { expectedKilometersPerYear: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, manufacturer: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, fuel: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, engineDisplacement: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, maxPower: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, countOfSeats: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, maxWeight: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, modelLine: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, model: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, modelType: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, security: (
          { __typename?: 'VehicleSecurity' }
          & Pick<VehicleSecurity, 'none' | 'mechanical' | 'passiveSearch' | 'activeSearch' | 'glassVin' | 'immobilizer' | 'alarm'>
        ), carUsageMethod: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, additionalEquipment: Array<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )> }
      ), subjectInsured: (
        { __typename?: 'InsuredSubject' }
        & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
        & { cityPart: Maybe<(
          { __typename?: 'CityPart' }
          & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
        )>, address: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, correspondenceAddress: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, functionOfRepresentative: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )> }
      ), subjectCarHolder: Maybe<(
        { __typename?: 'InsuredSubject' }
        & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
        & { cityPart: Maybe<(
          { __typename?: 'CityPart' }
          & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
        )>, address: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, correspondenceAddress: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, functionOfRepresentative: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )> }
      )>, subjectCarOwner: Maybe<(
        { __typename?: 'InsuredSubject' }
        & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
        & { cityPart: Maybe<(
          { __typename?: 'CityPart' }
          & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
        )>, address: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, correspondenceAddress: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, functionOfRepresentative: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )> }
      )>, selectedOffer: Maybe<(
        { __typename?: 'VehicleInsuranceProductOffer' }
        & Pick<VehicleInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'paymentFrequency' | 'calculatedPaymentFrequency'>
        & { mtplProduct: Maybe<(
          { __typename?: 'VehicleInsuranceProduct' }
          & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, cascoProduct: Maybe<(
          { __typename?: 'VehicleInsuranceProduct' }
          & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, estimatedPrice: (
          { __typename?: 'VehicleInsuranceEstimatedPrice' }
          & Pick<VehicleInsuranceEstimatedPrice, 'yearly' | 'quarterly' | 'halfYearly' | 'monthly'>
        ) }
      )>, contactPerson: Maybe<(
        { __typename?: 'VehicleInsuranceContactPerson' }
        & Pick<VehicleInsuranceContactPerson, 'name' | 'surname' | 'email' | 'phone' | 'agreementProcessPersonalData' | 'agreementTermsAndConditions'>
      )>, contract: Maybe<(
        { __typename?: 'VehicleInsuranceContract' }
        & Pick<VehicleInsuranceContract, 'id' | 'uuid' | 'payed' | 'paymentUrl'>
        & { price: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'currency'>
        ), annualPrice: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'currency'>
        ), documents: Array<Maybe<(
          { __typename?: 'VehicleInsuranceContractDocument' }
          & Pick<VehicleInsuranceContractDocument, 'id' | 'name' | 'documentType'>
          & { documentCategory: (
            { __typename?: 'CodeBook' }
            & Pick<CodeBook, 'id' | 'label'>
          ), file: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>>, paymentInfo: Maybe<(
          { __typename?: 'VehicleInsuranceContractPaymentInfo' }
          & Pick<VehicleInsuranceContractPaymentInfo, 'account' | 'bankCode' | 'constantSymbol' | 'specificSymbol' | 'variableSymbol' | 'message' | 'paymentUrl'>
          & { amount: Maybe<(
            { __typename?: 'Money' }
            & Pick<Money, 'amount' | 'currency'>
          )>, qrCodeFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, casco: Maybe<(
          { __typename?: 'VehicleInsuranceProduct' }
          & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, mtpl: Maybe<(
          { __typename?: 'VehicleInsuranceProduct' }
          & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )> }
      )>, additionalOptimumRequirements: (
        { __typename?: 'VehicleInsuranceAdditionalRequirementsFromClient' }
        & Pick<VehicleInsuranceAdditionalRequirementsFromClient, 'glass' | 'animalAccident'>
      ) }
    ), packages: Array<(
      { __typename?: 'VehicleInsurancePackage' }
      & Pick<VehicleInsurancePackage, 'id' | 'uuid' | 'type' | 'name' | 'protectionCover' | 'lowestPrice' | 'participation' | 'mobileOrder'>
      & { productOffers: Array<(
        { __typename?: 'VehicleInsuranceProductOffer' }
        & Pick<VehicleInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'paymentFrequency' | 'calculatedPaymentFrequency'>
        & { mtplProduct: Maybe<(
          { __typename?: 'VehicleInsuranceProduct' }
          & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, cascoProduct: Maybe<(
          { __typename?: 'VehicleInsuranceProduct' }
          & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, estimatedPrice: (
          { __typename?: 'VehicleInsuranceEstimatedPrice' }
          & Pick<VehicleInsuranceEstimatedPrice, 'yearly' | 'quarterly' | 'halfYearly' | 'monthly'>
        ) }
      )>, additionalRequirements: (
        { __typename?: 'VehicleInsuranceAdditionalRequirements' }
        & Pick<VehicleInsuranceAdditionalRequirements, 'id'>
        & { glass: (
          { __typename?: 'VehicleInsuranceGlassRequirement' }
          & Pick<VehicleInsuranceGlassRequirement, 'enabled' | 'value'>
        ), animalAccident: (
          { __typename?: 'VehicleInsuranceAnimalAccidentRequirement' }
          & Pick<VehicleInsuranceAnimalAccidentRequirement, 'enabled' | 'value'>
        ) }
      ) }
    )>, cheapestOffer: Maybe<(
      { __typename?: 'VehicleInsuranceProductOffer' }
      & Pick<VehicleInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'paymentFrequency' | 'calculatedPaymentFrequency'>
      & { mtplProduct: Maybe<(
        { __typename?: 'VehicleInsuranceProduct' }
        & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, cascoProduct: Maybe<(
        { __typename?: 'VehicleInsuranceProduct' }
        & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, estimatedPrice: (
        { __typename?: 'VehicleInsuranceEstimatedPrice' }
        & Pick<VehicleInsuranceEstimatedPrice, 'yearly' | 'quarterly' | 'halfYearly' | 'monthly'>
      ) }
    )> }
  )> }
);

export type VehicleSecurityFragment = (
  { __typename?: 'VehicleSecurity' }
  & Pick<VehicleSecurity, 'none' | 'mechanical' | 'passiveSearch' | 'activeSearch' | 'glassVin' | 'immobilizer' | 'alarm'>
);

export type InsuredVehicleFragment = (
  { __typename?: 'InsuredVehicle' }
  & Pick<InsuredVehicle, 'uuid' | 'vehicleType' | 'registrationNumber' | 'vin' | 'kilometersTotal' | 'ownedMoreThanAYear' | 'newVehicle' | 'contractualCarService' | 'vehicleValue' | 'vehicleValueWithVat' | 'firstRegistrationDate' | 'numberOfRegistrationCertificate' | 'vehicleSearchStatus'>
  & { expectedKilometersPerYear: Maybe<(
    { __typename?: 'CodeBook' }
    & Pick<CodeBook, 'id' | 'label'>
  )>, manufacturer: Maybe<(
    { __typename?: 'CodeBook' }
    & Pick<CodeBook, 'id' | 'label'>
  )>, fuel: Maybe<(
    { __typename?: 'CodeBook' }
    & Pick<CodeBook, 'id' | 'label'>
  )>, engineDisplacement: Maybe<(
    { __typename?: 'CodeBook' }
    & Pick<CodeBook, 'id' | 'label'>
  )>, maxPower: Maybe<(
    { __typename?: 'CodeBook' }
    & Pick<CodeBook, 'id' | 'label'>
  )>, countOfSeats: Maybe<(
    { __typename?: 'CodeBook' }
    & Pick<CodeBook, 'id' | 'label'>
  )>, maxWeight: Maybe<(
    { __typename?: 'CodeBook' }
    & Pick<CodeBook, 'id' | 'label'>
  )>, modelLine: Maybe<(
    { __typename?: 'CodeBook' }
    & Pick<CodeBook, 'id' | 'label'>
  )>, model: Maybe<(
    { __typename?: 'CodeBook' }
    & Pick<CodeBook, 'id' | 'label'>
  )>, modelType: Maybe<(
    { __typename?: 'CodeBook' }
    & Pick<CodeBook, 'id' | 'label'>
  )>, security: (
    { __typename?: 'VehicleSecurity' }
    & Pick<VehicleSecurity, 'none' | 'mechanical' | 'passiveSearch' | 'activeSearch' | 'glassVin' | 'immobilizer' | 'alarm'>
  ), carUsageMethod: Maybe<(
    { __typename?: 'CodeBook' }
    & Pick<CodeBook, 'id' | 'label'>
  )>, additionalEquipment: Array<(
    { __typename?: 'CodeBook' }
    & Pick<CodeBook, 'id' | 'label'>
  )> }
);

export type InsuredSubjectFragment = (
  { __typename?: 'InsuredSubject' }
  & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
  & { cityPart: Maybe<(
    { __typename?: 'CityPart' }
    & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
  )>, address: Maybe<(
    { __typename?: 'Address' }
    & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
    & { extended: Maybe<(
      { __typename?: 'SmartAddressExtendedInfo' }
      & Pick<SmartAddressExtendedInfo, 'id'>
      & { objectType: Maybe<(
        { __typename?: 'SmartAddressObjectType' }
        & Pick<SmartAddressObjectType, 'id' | 'nazev'>
      )> }
    )>, extendedV2: Maybe<(
      { __typename?: 'SmartAddressSdkExtendedInfo' }
      & { administrativniUdaje: Maybe<(
        { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
        & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
        & { objectType: Maybe<(
          { __typename?: 'SmartAddressObjectType' }
          & Pick<SmartAddressObjectType, 'id' | 'nazev'>
        )> }
      )> }
    )> }
  )>, correspondenceAddress: Maybe<(
    { __typename?: 'Address' }
    & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
    & { extended: Maybe<(
      { __typename?: 'SmartAddressExtendedInfo' }
      & Pick<SmartAddressExtendedInfo, 'id'>
      & { objectType: Maybe<(
        { __typename?: 'SmartAddressObjectType' }
        & Pick<SmartAddressObjectType, 'id' | 'nazev'>
      )> }
    )>, extendedV2: Maybe<(
      { __typename?: 'SmartAddressSdkExtendedInfo' }
      & { administrativniUdaje: Maybe<(
        { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
        & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
        & { objectType: Maybe<(
          { __typename?: 'SmartAddressObjectType' }
          & Pick<SmartAddressObjectType, 'id' | 'nazev'>
        )> }
      )> }
    )> }
  )>, functionOfRepresentative: Maybe<(
    { __typename?: 'CodeBook' }
    & Pick<CodeBook, 'id' | 'label'>
  )> }
);

export type ContactPersonFragment = (
  { __typename?: 'VehicleInsuranceContactPerson' }
  & Pick<VehicleInsuranceContactPerson, 'name' | 'surname' | 'email' | 'phone' | 'agreementProcessPersonalData' | 'agreementTermsAndConditions'>
);

export type VehicleInsuranceAdditionalRequirementsFromClientFragment = (
  { __typename?: 'VehicleInsuranceAdditionalRequirementsFromClient' }
  & Pick<VehicleInsuranceAdditionalRequirementsFromClient, 'glass' | 'animalAccident'>
);

export type VehicleInsuranceOrderFragment = (
  { __typename?: 'VehicleInsuranceOrder' }
  & Pick<VehicleInsuranceOrder, 'id' | 'uuid' | 'createdAt' | 'status' | 'packagesInsuranceType' | 'selectedInsuranceType' | 'insurableInterest' | 'resultEmail' | 'phone' | 'calculationCurrentStep' | 'orderCurrentStep' | 'updatedAt' | 'hasSpecificPackages' | 'beginDate'>
  & { vehicle: (
    { __typename?: 'InsuredVehicle' }
    & Pick<InsuredVehicle, 'uuid' | 'vehicleType' | 'registrationNumber' | 'vin' | 'kilometersTotal' | 'ownedMoreThanAYear' | 'newVehicle' | 'contractualCarService' | 'vehicleValue' | 'vehicleValueWithVat' | 'firstRegistrationDate' | 'numberOfRegistrationCertificate' | 'vehicleSearchStatus'>
    & { expectedKilometersPerYear: Maybe<(
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    )>, manufacturer: Maybe<(
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    )>, fuel: Maybe<(
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    )>, engineDisplacement: Maybe<(
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    )>, maxPower: Maybe<(
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    )>, countOfSeats: Maybe<(
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    )>, maxWeight: Maybe<(
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    )>, modelLine: Maybe<(
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    )>, model: Maybe<(
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    )>, modelType: Maybe<(
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    )>, security: (
      { __typename?: 'VehicleSecurity' }
      & Pick<VehicleSecurity, 'none' | 'mechanical' | 'passiveSearch' | 'activeSearch' | 'glassVin' | 'immobilizer' | 'alarm'>
    ), carUsageMethod: Maybe<(
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    )>, additionalEquipment: Array<(
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    )> }
  ), subjectInsured: (
    { __typename?: 'InsuredSubject' }
    & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
    & { cityPart: Maybe<(
      { __typename?: 'CityPart' }
      & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
    )>, address: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
      & { extended: Maybe<(
        { __typename?: 'SmartAddressExtendedInfo' }
        & Pick<SmartAddressExtendedInfo, 'id'>
        & { objectType: Maybe<(
          { __typename?: 'SmartAddressObjectType' }
          & Pick<SmartAddressObjectType, 'id' | 'nazev'>
        )> }
      )>, extendedV2: Maybe<(
        { __typename?: 'SmartAddressSdkExtendedInfo' }
        & { administrativniUdaje: Maybe<(
          { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
          & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )> }
      )> }
    )>, correspondenceAddress: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
      & { extended: Maybe<(
        { __typename?: 'SmartAddressExtendedInfo' }
        & Pick<SmartAddressExtendedInfo, 'id'>
        & { objectType: Maybe<(
          { __typename?: 'SmartAddressObjectType' }
          & Pick<SmartAddressObjectType, 'id' | 'nazev'>
        )> }
      )>, extendedV2: Maybe<(
        { __typename?: 'SmartAddressSdkExtendedInfo' }
        & { administrativniUdaje: Maybe<(
          { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
          & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )> }
      )> }
    )>, functionOfRepresentative: Maybe<(
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    )> }
  ), subjectCarHolder: Maybe<(
    { __typename?: 'InsuredSubject' }
    & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
    & { cityPart: Maybe<(
      { __typename?: 'CityPart' }
      & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
    )>, address: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
      & { extended: Maybe<(
        { __typename?: 'SmartAddressExtendedInfo' }
        & Pick<SmartAddressExtendedInfo, 'id'>
        & { objectType: Maybe<(
          { __typename?: 'SmartAddressObjectType' }
          & Pick<SmartAddressObjectType, 'id' | 'nazev'>
        )> }
      )>, extendedV2: Maybe<(
        { __typename?: 'SmartAddressSdkExtendedInfo' }
        & { administrativniUdaje: Maybe<(
          { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
          & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )> }
      )> }
    )>, correspondenceAddress: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
      & { extended: Maybe<(
        { __typename?: 'SmartAddressExtendedInfo' }
        & Pick<SmartAddressExtendedInfo, 'id'>
        & { objectType: Maybe<(
          { __typename?: 'SmartAddressObjectType' }
          & Pick<SmartAddressObjectType, 'id' | 'nazev'>
        )> }
      )>, extendedV2: Maybe<(
        { __typename?: 'SmartAddressSdkExtendedInfo' }
        & { administrativniUdaje: Maybe<(
          { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
          & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )> }
      )> }
    )>, functionOfRepresentative: Maybe<(
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    )> }
  )>, subjectCarOwner: Maybe<(
    { __typename?: 'InsuredSubject' }
    & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
    & { cityPart: Maybe<(
      { __typename?: 'CityPart' }
      & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
    )>, address: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
      & { extended: Maybe<(
        { __typename?: 'SmartAddressExtendedInfo' }
        & Pick<SmartAddressExtendedInfo, 'id'>
        & { objectType: Maybe<(
          { __typename?: 'SmartAddressObjectType' }
          & Pick<SmartAddressObjectType, 'id' | 'nazev'>
        )> }
      )>, extendedV2: Maybe<(
        { __typename?: 'SmartAddressSdkExtendedInfo' }
        & { administrativniUdaje: Maybe<(
          { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
          & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )> }
      )> }
    )>, correspondenceAddress: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
      & { extended: Maybe<(
        { __typename?: 'SmartAddressExtendedInfo' }
        & Pick<SmartAddressExtendedInfo, 'id'>
        & { objectType: Maybe<(
          { __typename?: 'SmartAddressObjectType' }
          & Pick<SmartAddressObjectType, 'id' | 'nazev'>
        )> }
      )>, extendedV2: Maybe<(
        { __typename?: 'SmartAddressSdkExtendedInfo' }
        & { administrativniUdaje: Maybe<(
          { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
          & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )> }
      )> }
    )>, functionOfRepresentative: Maybe<(
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    )> }
  )>, selectedOffer: Maybe<(
    { __typename?: 'VehicleInsuranceProductOffer' }
    & Pick<VehicleInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'paymentFrequency' | 'calculatedPaymentFrequency'>
    & { mtplProduct: Maybe<(
      { __typename?: 'VehicleInsuranceProduct' }
      & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
      & { partner: (
        { __typename?: 'ZeteoInsurancePartner' }
        & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
        & { logo: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )>, logoSquare: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )>, rating2: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), productDetailFile: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )> }
    )>, cascoProduct: Maybe<(
      { __typename?: 'VehicleInsuranceProduct' }
      & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
      & { partner: (
        { __typename?: 'ZeteoInsurancePartner' }
        & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
        & { logo: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )>, logoSquare: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )>, rating2: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), productDetailFile: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )> }
    )>, estimatedPrice: (
      { __typename?: 'VehicleInsuranceEstimatedPrice' }
      & Pick<VehicleInsuranceEstimatedPrice, 'yearly' | 'quarterly' | 'halfYearly' | 'monthly'>
    ) }
  )>, contactPerson: Maybe<(
    { __typename?: 'VehicleInsuranceContactPerson' }
    & Pick<VehicleInsuranceContactPerson, 'name' | 'surname' | 'email' | 'phone' | 'agreementProcessPersonalData' | 'agreementTermsAndConditions'>
  )>, contract: Maybe<(
    { __typename?: 'VehicleInsuranceContract' }
    & Pick<VehicleInsuranceContract, 'id' | 'uuid' | 'payed' | 'paymentUrl'>
    & { price: (
      { __typename?: 'Money' }
      & Pick<Money, 'amount' | 'currency'>
    ), annualPrice: (
      { __typename?: 'Money' }
      & Pick<Money, 'amount' | 'currency'>
    ), documents: Array<Maybe<(
      { __typename?: 'VehicleInsuranceContractDocument' }
      & Pick<VehicleInsuranceContractDocument, 'id' | 'name' | 'documentType'>
      & { documentCategory: (
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      ), file: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )> }
    )>>, paymentInfo: Maybe<(
      { __typename?: 'VehicleInsuranceContractPaymentInfo' }
      & Pick<VehicleInsuranceContractPaymentInfo, 'account' | 'bankCode' | 'constantSymbol' | 'specificSymbol' | 'variableSymbol' | 'message' | 'paymentUrl'>
      & { amount: Maybe<(
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency'>
      )>, qrCodeFile: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )> }
    )>, casco: Maybe<(
      { __typename?: 'VehicleInsuranceProduct' }
      & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
      & { partner: (
        { __typename?: 'ZeteoInsurancePartner' }
        & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
        & { logo: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )>, logoSquare: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )>, rating2: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), productDetailFile: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )> }
    )>, mtpl: Maybe<(
      { __typename?: 'VehicleInsuranceProduct' }
      & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
      & { partner: (
        { __typename?: 'ZeteoInsurancePartner' }
        & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
        & { logo: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )>, logoSquare: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )>, rating2: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), productDetailFile: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )> }
    )> }
  )>, additionalOptimumRequirements: (
    { __typename?: 'VehicleInsuranceAdditionalRequirementsFromClient' }
    & Pick<VehicleInsuranceAdditionalRequirementsFromClient, 'glass' | 'animalAccident'>
  ) }
);

export type VehicleFindByRegistrationNumberFragment = (
  { __typename?: 'VehicleFindByRegistrationNumberResponse' }
  & Pick<VehicleFindByRegistrationNumberResponse, 'vehicleType'>
  & { modelLine: Array<(
    { __typename?: 'CodeBook' }
    & Pick<CodeBook, 'id' | 'label'>
  )>, manufacturer: Array<(
    { __typename?: 'CodeBook' }
    & Pick<CodeBook, 'id' | 'label'>
  )>, fuel: Array<(
    { __typename?: 'CodeBook' }
    & Pick<CodeBook, 'id' | 'label'>
  )>, engineDisplacement: Array<(
    { __typename?: 'CodeBook' }
    & Pick<CodeBook, 'id' | 'label'>
  )>, maxPower: Array<(
    { __typename?: 'CodeBook' }
    & Pick<CodeBook, 'id' | 'label'>
  )>, countOfSeats: Array<(
    { __typename?: 'CodeBook' }
    & Pick<CodeBook, 'id' | 'label'>
  )>, maxWeight: Array<(
    { __typename?: 'CodeBook' }
    & Pick<CodeBook, 'id' | 'label'>
  )> }
);

export type VehicleInsuranceLastOrderFragment = (
  { __typename?: 'VehicleInsuranceLastOrder' }
  & Pick<VehicleInsuranceLastOrder, 'id' | 'registrationNumber'>
  & { cityPart: (
    { __typename?: 'CityPart' }
    & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
  ) }
);

export type VehicleInsuranceContractDocumentFragment = (
  { __typename?: 'VehicleInsuranceContractDocument' }
  & Pick<VehicleInsuranceContractDocument, 'id' | 'name' | 'documentType'>
  & { documentCategory: (
    { __typename?: 'CodeBook' }
    & Pick<CodeBook, 'id' | 'label'>
  ), file: Maybe<(
    { __typename?: 'File' }
    & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
  )> }
);

export type VehicleInsuranceContractFragment = (
  { __typename?: 'VehicleInsuranceContract' }
  & Pick<VehicleInsuranceContract, 'id' | 'uuid' | 'payed' | 'paymentUrl'>
  & { price: (
    { __typename?: 'Money' }
    & Pick<Money, 'amount' | 'currency'>
  ), annualPrice: (
    { __typename?: 'Money' }
    & Pick<Money, 'amount' | 'currency'>
  ), documents: Array<Maybe<(
    { __typename?: 'VehicleInsuranceContractDocument' }
    & Pick<VehicleInsuranceContractDocument, 'id' | 'name' | 'documentType'>
    & { documentCategory: (
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    ), file: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    )> }
  )>>, paymentInfo: Maybe<(
    { __typename?: 'VehicleInsuranceContractPaymentInfo' }
    & Pick<VehicleInsuranceContractPaymentInfo, 'account' | 'bankCode' | 'constantSymbol' | 'specificSymbol' | 'variableSymbol' | 'message' | 'paymentUrl'>
    & { amount: Maybe<(
      { __typename?: 'Money' }
      & Pick<Money, 'amount' | 'currency'>
    )>, qrCodeFile: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    )> }
  )>, casco: Maybe<(
    { __typename?: 'VehicleInsuranceProduct' }
    & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
    & { partner: (
      { __typename?: 'ZeteoInsurancePartner' }
      & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
      & { logo: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )>, logoSquare: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )>, rating2: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    ), productDetailFile: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    )> }
  )>, mtpl: Maybe<(
    { __typename?: 'VehicleInsuranceProduct' }
    & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
    & { partner: (
      { __typename?: 'ZeteoInsurancePartner' }
      & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
      & { logo: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )>, logoSquare: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )>, rating2: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    ), productDetailFile: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    )> }
  )> }
);

export type VehicleInsuranceContractPaymentInfoFragment = (
  { __typename?: 'VehicleInsuranceContractPaymentInfo' }
  & Pick<VehicleInsuranceContractPaymentInfo, 'account' | 'bankCode' | 'constantSymbol' | 'specificSymbol' | 'variableSymbol' | 'message' | 'paymentUrl'>
  & { amount: Maybe<(
    { __typename?: 'Money' }
    & Pick<Money, 'amount' | 'currency'>
  )>, qrCodeFile: Maybe<(
    { __typename?: 'File' }
    & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
  )> }
);

export type VehicleInsuranceRecapitulationInfoFragment = (
  { __typename?: 'VehicleInsuranceRecapitulationInfo' }
  & Pick<VehicleInsuranceRecapitulationInfo, 'oldPrice' | 'price' | 'paymentFrequency'>
);

export type VehicleInsuranceContractRecapitulationFragment = (
  { __typename?: 'VehicleInsuranceContractRecapitulation' }
  & { order: Maybe<(
    { __typename?: 'VehicleInsuranceOrder' }
    & Pick<VehicleInsuranceOrder, 'id' | 'uuid' | 'createdAt' | 'status' | 'packagesInsuranceType' | 'selectedInsuranceType' | 'insurableInterest' | 'resultEmail' | 'phone' | 'calculationCurrentStep' | 'orderCurrentStep' | 'updatedAt' | 'hasSpecificPackages' | 'beginDate'>
    & { vehicle: (
      { __typename?: 'InsuredVehicle' }
      & Pick<InsuredVehicle, 'uuid' | 'vehicleType' | 'registrationNumber' | 'vin' | 'kilometersTotal' | 'ownedMoreThanAYear' | 'newVehicle' | 'contractualCarService' | 'vehicleValue' | 'vehicleValueWithVat' | 'firstRegistrationDate' | 'numberOfRegistrationCertificate' | 'vehicleSearchStatus'>
      & { expectedKilometersPerYear: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, manufacturer: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, fuel: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, engineDisplacement: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, maxPower: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, countOfSeats: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, maxWeight: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, modelLine: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, model: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, modelType: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, security: (
        { __typename?: 'VehicleSecurity' }
        & Pick<VehicleSecurity, 'none' | 'mechanical' | 'passiveSearch' | 'activeSearch' | 'glassVin' | 'immobilizer' | 'alarm'>
      ), carUsageMethod: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, additionalEquipment: Array<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    ), subjectInsured: (
      { __typename?: 'InsuredSubject' }
      & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
      & { cityPart: Maybe<(
        { __typename?: 'CityPart' }
        & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
      )>, address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, correspondenceAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, functionOfRepresentative: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    ), subjectCarHolder: Maybe<(
      { __typename?: 'InsuredSubject' }
      & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
      & { cityPart: Maybe<(
        { __typename?: 'CityPart' }
        & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
      )>, address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, correspondenceAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, functionOfRepresentative: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    )>, subjectCarOwner: Maybe<(
      { __typename?: 'InsuredSubject' }
      & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
      & { cityPart: Maybe<(
        { __typename?: 'CityPart' }
        & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
      )>, address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, correspondenceAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, functionOfRepresentative: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    )>, selectedOffer: Maybe<(
      { __typename?: 'VehicleInsuranceProductOffer' }
      & Pick<VehicleInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'paymentFrequency' | 'calculatedPaymentFrequency'>
      & { mtplProduct: Maybe<(
        { __typename?: 'VehicleInsuranceProduct' }
        & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, cascoProduct: Maybe<(
        { __typename?: 'VehicleInsuranceProduct' }
        & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, estimatedPrice: (
        { __typename?: 'VehicleInsuranceEstimatedPrice' }
        & Pick<VehicleInsuranceEstimatedPrice, 'yearly' | 'quarterly' | 'halfYearly' | 'monthly'>
      ) }
    )>, contactPerson: Maybe<(
      { __typename?: 'VehicleInsuranceContactPerson' }
      & Pick<VehicleInsuranceContactPerson, 'name' | 'surname' | 'email' | 'phone' | 'agreementProcessPersonalData' | 'agreementTermsAndConditions'>
    )>, contract: Maybe<(
      { __typename?: 'VehicleInsuranceContract' }
      & Pick<VehicleInsuranceContract, 'id' | 'uuid' | 'payed' | 'paymentUrl'>
      & { price: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency'>
      ), annualPrice: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency'>
      ), documents: Array<Maybe<(
        { __typename?: 'VehicleInsuranceContractDocument' }
        & Pick<VehicleInsuranceContractDocument, 'id' | 'name' | 'documentType'>
        & { documentCategory: (
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        ), file: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>>, paymentInfo: Maybe<(
        { __typename?: 'VehicleInsuranceContractPaymentInfo' }
        & Pick<VehicleInsuranceContractPaymentInfo, 'account' | 'bankCode' | 'constantSymbol' | 'specificSymbol' | 'variableSymbol' | 'message' | 'paymentUrl'>
        & { amount: Maybe<(
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'currency'>
        )>, qrCodeFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, casco: Maybe<(
        { __typename?: 'VehicleInsuranceProduct' }
        & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, mtpl: Maybe<(
        { __typename?: 'VehicleInsuranceProduct' }
        & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )> }
    )>, additionalOptimumRequirements: (
      { __typename?: 'VehicleInsuranceAdditionalRequirementsFromClient' }
      & Pick<VehicleInsuranceAdditionalRequirementsFromClient, 'glass' | 'animalAccident'>
    ) }
  )>, recalculationState: (
    { __typename?: 'ZeteoState' }
    & Pick<ZeteoState, 'maximumSteps' | 'finishedSteps' | 'finished' | 'error'>
  ), recapitulationState: Maybe<(
    { __typename?: 'ZeteoState' }
    & Pick<ZeteoState, 'maximumSteps' | 'finishedSteps' | 'finished' | 'error'>
  )>, recapitulation: Maybe<(
    { __typename?: 'VehicleInsuranceRecapitulationInfo' }
    & Pick<VehicleInsuranceRecapitulationInfo, 'oldPrice' | 'price' | 'paymentFrequency'>
  )> }
);

export type VehicleInsuranceContractCompleteOrderFragment = (
  { __typename?: 'VehicleInsuranceContractCompleteOrder' }
  & { finishOrderState: Maybe<(
    { __typename?: 'ZeteoState' }
    & Pick<ZeteoState, 'maximumSteps' | 'finishedSteps' | 'finished' | 'error'>
  )>, order: (
    { __typename?: 'VehicleInsuranceOrder' }
    & Pick<VehicleInsuranceOrder, 'id' | 'uuid' | 'createdAt' | 'status' | 'packagesInsuranceType' | 'selectedInsuranceType' | 'insurableInterest' | 'resultEmail' | 'phone' | 'calculationCurrentStep' | 'orderCurrentStep' | 'updatedAt' | 'hasSpecificPackages' | 'beginDate'>
    & { vehicle: (
      { __typename?: 'InsuredVehicle' }
      & Pick<InsuredVehicle, 'uuid' | 'vehicleType' | 'registrationNumber' | 'vin' | 'kilometersTotal' | 'ownedMoreThanAYear' | 'newVehicle' | 'contractualCarService' | 'vehicleValue' | 'vehicleValueWithVat' | 'firstRegistrationDate' | 'numberOfRegistrationCertificate' | 'vehicleSearchStatus'>
      & { expectedKilometersPerYear: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, manufacturer: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, fuel: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, engineDisplacement: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, maxPower: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, countOfSeats: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, maxWeight: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, modelLine: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, model: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, modelType: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, security: (
        { __typename?: 'VehicleSecurity' }
        & Pick<VehicleSecurity, 'none' | 'mechanical' | 'passiveSearch' | 'activeSearch' | 'glassVin' | 'immobilizer' | 'alarm'>
      ), carUsageMethod: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, additionalEquipment: Array<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    ), subjectInsured: (
      { __typename?: 'InsuredSubject' }
      & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
      & { cityPart: Maybe<(
        { __typename?: 'CityPart' }
        & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
      )>, address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, correspondenceAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, functionOfRepresentative: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    ), subjectCarHolder: Maybe<(
      { __typename?: 'InsuredSubject' }
      & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
      & { cityPart: Maybe<(
        { __typename?: 'CityPart' }
        & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
      )>, address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, correspondenceAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, functionOfRepresentative: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    )>, subjectCarOwner: Maybe<(
      { __typename?: 'InsuredSubject' }
      & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
      & { cityPart: Maybe<(
        { __typename?: 'CityPart' }
        & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
      )>, address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, correspondenceAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, functionOfRepresentative: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    )>, selectedOffer: Maybe<(
      { __typename?: 'VehicleInsuranceProductOffer' }
      & Pick<VehicleInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'paymentFrequency' | 'calculatedPaymentFrequency'>
      & { mtplProduct: Maybe<(
        { __typename?: 'VehicleInsuranceProduct' }
        & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, cascoProduct: Maybe<(
        { __typename?: 'VehicleInsuranceProduct' }
        & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, estimatedPrice: (
        { __typename?: 'VehicleInsuranceEstimatedPrice' }
        & Pick<VehicleInsuranceEstimatedPrice, 'yearly' | 'quarterly' | 'halfYearly' | 'monthly'>
      ) }
    )>, contactPerson: Maybe<(
      { __typename?: 'VehicleInsuranceContactPerson' }
      & Pick<VehicleInsuranceContactPerson, 'name' | 'surname' | 'email' | 'phone' | 'agreementProcessPersonalData' | 'agreementTermsAndConditions'>
    )>, contract: Maybe<(
      { __typename?: 'VehicleInsuranceContract' }
      & Pick<VehicleInsuranceContract, 'id' | 'uuid' | 'payed' | 'paymentUrl'>
      & { price: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency'>
      ), annualPrice: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency'>
      ), documents: Array<Maybe<(
        { __typename?: 'VehicleInsuranceContractDocument' }
        & Pick<VehicleInsuranceContractDocument, 'id' | 'name' | 'documentType'>
        & { documentCategory: (
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        ), file: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>>, paymentInfo: Maybe<(
        { __typename?: 'VehicleInsuranceContractPaymentInfo' }
        & Pick<VehicleInsuranceContractPaymentInfo, 'account' | 'bankCode' | 'constantSymbol' | 'specificSymbol' | 'variableSymbol' | 'message' | 'paymentUrl'>
        & { amount: Maybe<(
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'currency'>
        )>, qrCodeFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, casco: Maybe<(
        { __typename?: 'VehicleInsuranceProduct' }
        & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, mtpl: Maybe<(
        { __typename?: 'VehicleInsuranceProduct' }
        & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )> }
    )>, additionalOptimumRequirements: (
      { __typename?: 'VehicleInsuranceAdditionalRequirementsFromClient' }
      & Pick<VehicleInsuranceAdditionalRequirementsFromClient, 'glass' | 'animalAccident'>
    ) }
  ) }
);

export type InitVehicleInsuranceOrderMutationVariables = Exact<{
  data: VehicleInsuranceOrderInitInput;
}>;


export type InitVehicleInsuranceOrderMutation = (
  { __typename?: 'Mutation' }
  & { order: (
    { __typename?: 'VehicleInsuranceOrder' }
    & Pick<VehicleInsuranceOrder, 'id' | 'uuid' | 'createdAt' | 'status' | 'packagesInsuranceType' | 'selectedInsuranceType' | 'insurableInterest' | 'resultEmail' | 'phone' | 'calculationCurrentStep' | 'orderCurrentStep' | 'updatedAt' | 'hasSpecificPackages' | 'beginDate'>
    & { vehicle: (
      { __typename?: 'InsuredVehicle' }
      & Pick<InsuredVehicle, 'uuid' | 'vehicleType' | 'registrationNumber' | 'vin' | 'kilometersTotal' | 'ownedMoreThanAYear' | 'newVehicle' | 'contractualCarService' | 'vehicleValue' | 'vehicleValueWithVat' | 'firstRegistrationDate' | 'numberOfRegistrationCertificate' | 'vehicleSearchStatus'>
      & { expectedKilometersPerYear: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, manufacturer: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, fuel: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, engineDisplacement: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, maxPower: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, countOfSeats: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, maxWeight: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, modelLine: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, model: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, modelType: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, security: (
        { __typename?: 'VehicleSecurity' }
        & Pick<VehicleSecurity, 'none' | 'mechanical' | 'passiveSearch' | 'activeSearch' | 'glassVin' | 'immobilizer' | 'alarm'>
      ), carUsageMethod: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, additionalEquipment: Array<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    ), subjectInsured: (
      { __typename?: 'InsuredSubject' }
      & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
      & { cityPart: Maybe<(
        { __typename?: 'CityPart' }
        & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
      )>, address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, correspondenceAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, functionOfRepresentative: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    ), subjectCarHolder: Maybe<(
      { __typename?: 'InsuredSubject' }
      & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
      & { cityPart: Maybe<(
        { __typename?: 'CityPart' }
        & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
      )>, address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, correspondenceAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, functionOfRepresentative: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    )>, subjectCarOwner: Maybe<(
      { __typename?: 'InsuredSubject' }
      & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
      & { cityPart: Maybe<(
        { __typename?: 'CityPart' }
        & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
      )>, address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, correspondenceAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, functionOfRepresentative: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    )>, selectedOffer: Maybe<(
      { __typename?: 'VehicleInsuranceProductOffer' }
      & Pick<VehicleInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'paymentFrequency' | 'calculatedPaymentFrequency'>
      & { mtplProduct: Maybe<(
        { __typename?: 'VehicleInsuranceProduct' }
        & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, cascoProduct: Maybe<(
        { __typename?: 'VehicleInsuranceProduct' }
        & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, estimatedPrice: (
        { __typename?: 'VehicleInsuranceEstimatedPrice' }
        & Pick<VehicleInsuranceEstimatedPrice, 'yearly' | 'quarterly' | 'halfYearly' | 'monthly'>
      ) }
    )>, contactPerson: Maybe<(
      { __typename?: 'VehicleInsuranceContactPerson' }
      & Pick<VehicleInsuranceContactPerson, 'name' | 'surname' | 'email' | 'phone' | 'agreementProcessPersonalData' | 'agreementTermsAndConditions'>
    )>, contract: Maybe<(
      { __typename?: 'VehicleInsuranceContract' }
      & Pick<VehicleInsuranceContract, 'id' | 'uuid' | 'payed' | 'paymentUrl'>
      & { price: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency'>
      ), annualPrice: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency'>
      ), documents: Array<Maybe<(
        { __typename?: 'VehicleInsuranceContractDocument' }
        & Pick<VehicleInsuranceContractDocument, 'id' | 'name' | 'documentType'>
        & { documentCategory: (
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        ), file: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>>, paymentInfo: Maybe<(
        { __typename?: 'VehicleInsuranceContractPaymentInfo' }
        & Pick<VehicleInsuranceContractPaymentInfo, 'account' | 'bankCode' | 'constantSymbol' | 'specificSymbol' | 'variableSymbol' | 'message' | 'paymentUrl'>
        & { amount: Maybe<(
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'currency'>
        )>, qrCodeFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, casco: Maybe<(
        { __typename?: 'VehicleInsuranceProduct' }
        & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, mtpl: Maybe<(
        { __typename?: 'VehicleInsuranceProduct' }
        & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )> }
    )>, additionalOptimumRequirements: (
      { __typename?: 'VehicleInsuranceAdditionalRequirementsFromClient' }
      & Pick<VehicleInsuranceAdditionalRequirementsFromClient, 'glass' | 'animalAccident'>
    ) }
  ) }
);

export type UpdateVehicleInsuranceOrderMutationVariables = Exact<{
  uuid: Scalars['String']['input'];
  data: VehicleInsuranceOrderUpdateInput;
}>;


export type UpdateVehicleInsuranceOrderMutation = (
  { __typename?: 'Mutation' }
  & { order: (
    { __typename?: 'VehicleInsuranceOrder' }
    & Pick<VehicleInsuranceOrder, 'id' | 'uuid' | 'createdAt' | 'status' | 'packagesInsuranceType' | 'selectedInsuranceType' | 'insurableInterest' | 'resultEmail' | 'phone' | 'calculationCurrentStep' | 'orderCurrentStep' | 'updatedAt' | 'hasSpecificPackages' | 'beginDate'>
    & { vehicle: (
      { __typename?: 'InsuredVehicle' }
      & Pick<InsuredVehicle, 'uuid' | 'vehicleType' | 'registrationNumber' | 'vin' | 'kilometersTotal' | 'ownedMoreThanAYear' | 'newVehicle' | 'contractualCarService' | 'vehicleValue' | 'vehicleValueWithVat' | 'firstRegistrationDate' | 'numberOfRegistrationCertificate' | 'vehicleSearchStatus'>
      & { expectedKilometersPerYear: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, manufacturer: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, fuel: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, engineDisplacement: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, maxPower: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, countOfSeats: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, maxWeight: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, modelLine: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, model: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, modelType: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, security: (
        { __typename?: 'VehicleSecurity' }
        & Pick<VehicleSecurity, 'none' | 'mechanical' | 'passiveSearch' | 'activeSearch' | 'glassVin' | 'immobilizer' | 'alarm'>
      ), carUsageMethod: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, additionalEquipment: Array<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    ), subjectInsured: (
      { __typename?: 'InsuredSubject' }
      & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
      & { cityPart: Maybe<(
        { __typename?: 'CityPart' }
        & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
      )>, address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, correspondenceAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, functionOfRepresentative: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    ), subjectCarHolder: Maybe<(
      { __typename?: 'InsuredSubject' }
      & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
      & { cityPart: Maybe<(
        { __typename?: 'CityPart' }
        & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
      )>, address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, correspondenceAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, functionOfRepresentative: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    )>, subjectCarOwner: Maybe<(
      { __typename?: 'InsuredSubject' }
      & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
      & { cityPart: Maybe<(
        { __typename?: 'CityPart' }
        & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
      )>, address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, correspondenceAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, functionOfRepresentative: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    )>, selectedOffer: Maybe<(
      { __typename?: 'VehicleInsuranceProductOffer' }
      & Pick<VehicleInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'paymentFrequency' | 'calculatedPaymentFrequency'>
      & { mtplProduct: Maybe<(
        { __typename?: 'VehicleInsuranceProduct' }
        & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, cascoProduct: Maybe<(
        { __typename?: 'VehicleInsuranceProduct' }
        & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, estimatedPrice: (
        { __typename?: 'VehicleInsuranceEstimatedPrice' }
        & Pick<VehicleInsuranceEstimatedPrice, 'yearly' | 'quarterly' | 'halfYearly' | 'monthly'>
      ) }
    )>, contactPerson: Maybe<(
      { __typename?: 'VehicleInsuranceContactPerson' }
      & Pick<VehicleInsuranceContactPerson, 'name' | 'surname' | 'email' | 'phone' | 'agreementProcessPersonalData' | 'agreementTermsAndConditions'>
    )>, contract: Maybe<(
      { __typename?: 'VehicleInsuranceContract' }
      & Pick<VehicleInsuranceContract, 'id' | 'uuid' | 'payed' | 'paymentUrl'>
      & { price: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency'>
      ), annualPrice: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency'>
      ), documents: Array<Maybe<(
        { __typename?: 'VehicleInsuranceContractDocument' }
        & Pick<VehicleInsuranceContractDocument, 'id' | 'name' | 'documentType'>
        & { documentCategory: (
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        ), file: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>>, paymentInfo: Maybe<(
        { __typename?: 'VehicleInsuranceContractPaymentInfo' }
        & Pick<VehicleInsuranceContractPaymentInfo, 'account' | 'bankCode' | 'constantSymbol' | 'specificSymbol' | 'variableSymbol' | 'message' | 'paymentUrl'>
        & { amount: Maybe<(
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'currency'>
        )>, qrCodeFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, casco: Maybe<(
        { __typename?: 'VehicleInsuranceProduct' }
        & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, mtpl: Maybe<(
        { __typename?: 'VehicleInsuranceProduct' }
        & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )> }
    )>, additionalOptimumRequirements: (
      { __typename?: 'VehicleInsuranceAdditionalRequirementsFromClient' }
      & Pick<VehicleInsuranceAdditionalRequirementsFromClient, 'glass' | 'animalAccident'>
    ) }
  ) }
);

export type VehicleSearchParametersMutationVariables = Exact<{
  data: VehicleSearchParametersInput;
}>;


export type VehicleSearchParametersMutation = (
  { __typename?: 'Mutation' }
  & { vehicleSearchParameters: (
    { __typename?: 'VehicleSearchParametersResponse' }
    & Pick<VehicleSearchParametersResponse, 'vehicleType'>
    & { modelLine: Array<(
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    )>, model: Array<(
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    )>, modelType: Array<(
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    )>, manufacturer: Array<(
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    )>, fuel: Array<(
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    )>, engineDisplacement: Array<(
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    )>, maxPower: Array<(
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    )>, countOfSeats: Array<(
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    )>, maxWeight: Array<(
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    )> }
  ) }
);

export type FindVehicleByRegistrationNumberMutationVariables = Exact<{
  data: VehicleFindByRegistrationNumberInput;
}>;


export type FindVehicleByRegistrationNumberMutation = (
  { __typename?: 'Mutation' }
  & { vehicleFindByRegistrationNumber: (
    { __typename?: 'VehicleFindByRegistrationNumberResponse' }
    & Pick<VehicleFindByRegistrationNumberResponse, 'vehicleType'>
    & { modelLine: Array<(
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    )>, manufacturer: Array<(
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    )>, fuel: Array<(
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    )>, engineDisplacement: Array<(
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    )>, maxPower: Array<(
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    )>, countOfSeats: Array<(
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    )>, maxWeight: Array<(
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    )> }
  ) }
);

export type VehicleInsuranceOrderCalculationSelectOfferMutationVariables = Exact<{
  uuid: Scalars['String']['input'];
  data: VehicleInsuranceSelectOfferInput;
}>;


export type VehicleInsuranceOrderCalculationSelectOfferMutation = (
  { __typename?: 'Mutation' }
  & { vehicleInsuranceOrderCalculationSelectOffer: (
    { __typename?: 'VehicleInsuranceOrder' }
    & Pick<VehicleInsuranceOrder, 'id'>
    & { selectedOffer: Maybe<(
      { __typename?: 'VehicleInsuranceProductOffer' }
      & Pick<VehicleInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'paymentFrequency' | 'calculatedPaymentFrequency'>
      & { mtplProduct: Maybe<(
        { __typename?: 'VehicleInsuranceProduct' }
        & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, cascoProduct: Maybe<(
        { __typename?: 'VehicleInsuranceProduct' }
        & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, estimatedPrice: (
        { __typename?: 'VehicleInsuranceEstimatedPrice' }
        & Pick<VehicleInsuranceEstimatedPrice, 'yearly' | 'quarterly' | 'halfYearly' | 'monthly'>
      ) }
    )> }
  ) }
);

export type VehicleInsuranceOrderSaveContactPersonMutationVariables = Exact<{
  uuid: Scalars['String']['input'];
  data: VehicleInsuranceContactPersonInput;
}>;


export type VehicleInsuranceOrderSaveContactPersonMutation = (
  { __typename?: 'Mutation' }
  & { vehicleInsuranceOrderSaveContactPerson: (
    { __typename?: 'VehicleInsuranceOrder' }
    & Pick<VehicleInsuranceOrder, 'id' | 'uuid' | 'createdAt' | 'status' | 'packagesInsuranceType' | 'selectedInsuranceType' | 'insurableInterest' | 'resultEmail' | 'phone' | 'calculationCurrentStep' | 'orderCurrentStep' | 'updatedAt' | 'hasSpecificPackages' | 'beginDate'>
    & { vehicle: (
      { __typename?: 'InsuredVehicle' }
      & Pick<InsuredVehicle, 'uuid' | 'vehicleType' | 'registrationNumber' | 'vin' | 'kilometersTotal' | 'ownedMoreThanAYear' | 'newVehicle' | 'contractualCarService' | 'vehicleValue' | 'vehicleValueWithVat' | 'firstRegistrationDate' | 'numberOfRegistrationCertificate' | 'vehicleSearchStatus'>
      & { expectedKilometersPerYear: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, manufacturer: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, fuel: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, engineDisplacement: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, maxPower: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, countOfSeats: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, maxWeight: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, modelLine: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, model: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, modelType: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, security: (
        { __typename?: 'VehicleSecurity' }
        & Pick<VehicleSecurity, 'none' | 'mechanical' | 'passiveSearch' | 'activeSearch' | 'glassVin' | 'immobilizer' | 'alarm'>
      ), carUsageMethod: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, additionalEquipment: Array<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    ), subjectInsured: (
      { __typename?: 'InsuredSubject' }
      & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
      & { cityPart: Maybe<(
        { __typename?: 'CityPart' }
        & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
      )>, address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, correspondenceAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, functionOfRepresentative: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    ), subjectCarHolder: Maybe<(
      { __typename?: 'InsuredSubject' }
      & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
      & { cityPart: Maybe<(
        { __typename?: 'CityPart' }
        & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
      )>, address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, correspondenceAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, functionOfRepresentative: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    )>, subjectCarOwner: Maybe<(
      { __typename?: 'InsuredSubject' }
      & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
      & { cityPart: Maybe<(
        { __typename?: 'CityPart' }
        & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
      )>, address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, correspondenceAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, functionOfRepresentative: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    )>, selectedOffer: Maybe<(
      { __typename?: 'VehicleInsuranceProductOffer' }
      & Pick<VehicleInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'paymentFrequency' | 'calculatedPaymentFrequency'>
      & { mtplProduct: Maybe<(
        { __typename?: 'VehicleInsuranceProduct' }
        & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, cascoProduct: Maybe<(
        { __typename?: 'VehicleInsuranceProduct' }
        & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, estimatedPrice: (
        { __typename?: 'VehicleInsuranceEstimatedPrice' }
        & Pick<VehicleInsuranceEstimatedPrice, 'yearly' | 'quarterly' | 'halfYearly' | 'monthly'>
      ) }
    )>, contactPerson: Maybe<(
      { __typename?: 'VehicleInsuranceContactPerson' }
      & Pick<VehicleInsuranceContactPerson, 'name' | 'surname' | 'email' | 'phone' | 'agreementProcessPersonalData' | 'agreementTermsAndConditions'>
    )>, contract: Maybe<(
      { __typename?: 'VehicleInsuranceContract' }
      & Pick<VehicleInsuranceContract, 'id' | 'uuid' | 'payed' | 'paymentUrl'>
      & { price: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency'>
      ), annualPrice: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency'>
      ), documents: Array<Maybe<(
        { __typename?: 'VehicleInsuranceContractDocument' }
        & Pick<VehicleInsuranceContractDocument, 'id' | 'name' | 'documentType'>
        & { documentCategory: (
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        ), file: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>>, paymentInfo: Maybe<(
        { __typename?: 'VehicleInsuranceContractPaymentInfo' }
        & Pick<VehicleInsuranceContractPaymentInfo, 'account' | 'bankCode' | 'constantSymbol' | 'specificSymbol' | 'variableSymbol' | 'message' | 'paymentUrl'>
        & { amount: Maybe<(
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'currency'>
        )>, qrCodeFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, casco: Maybe<(
        { __typename?: 'VehicleInsuranceProduct' }
        & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, mtpl: Maybe<(
        { __typename?: 'VehicleInsuranceProduct' }
        & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )> }
    )>, additionalOptimumRequirements: (
      { __typename?: 'VehicleInsuranceAdditionalRequirementsFromClient' }
      & Pick<VehicleInsuranceAdditionalRequirementsFromClient, 'glass' | 'animalAccident'>
    ) }
  ) }
);

export type VehicleInsuranceOrderInquiryCreateMutationVariables = Exact<{
  uuid: Scalars['String']['input'];
  data: VehicleInsuranceInquiryCreateInput;
}>;


export type VehicleInsuranceOrderInquiryCreateMutation = (
  { __typename?: 'Mutation' }
  & { vehicleInsuranceOrderInquiryCreate: (
    { __typename?: 'VehicleInsuranceOrder' }
    & Pick<VehicleInsuranceOrder, 'id' | 'uuid' | 'createdAt' | 'status' | 'packagesInsuranceType' | 'selectedInsuranceType' | 'insurableInterest' | 'resultEmail' | 'phone' | 'calculationCurrentStep' | 'orderCurrentStep' | 'updatedAt' | 'hasSpecificPackages' | 'beginDate'>
    & { vehicle: (
      { __typename?: 'InsuredVehicle' }
      & Pick<InsuredVehicle, 'uuid' | 'vehicleType' | 'registrationNumber' | 'vin' | 'kilometersTotal' | 'ownedMoreThanAYear' | 'newVehicle' | 'contractualCarService' | 'vehicleValue' | 'vehicleValueWithVat' | 'firstRegistrationDate' | 'numberOfRegistrationCertificate' | 'vehicleSearchStatus'>
      & { expectedKilometersPerYear: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, manufacturer: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, fuel: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, engineDisplacement: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, maxPower: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, countOfSeats: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, maxWeight: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, modelLine: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, model: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, modelType: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, security: (
        { __typename?: 'VehicleSecurity' }
        & Pick<VehicleSecurity, 'none' | 'mechanical' | 'passiveSearch' | 'activeSearch' | 'glassVin' | 'immobilizer' | 'alarm'>
      ), carUsageMethod: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, additionalEquipment: Array<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    ), subjectInsured: (
      { __typename?: 'InsuredSubject' }
      & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
      & { cityPart: Maybe<(
        { __typename?: 'CityPart' }
        & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
      )>, address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, correspondenceAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, functionOfRepresentative: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    ), subjectCarHolder: Maybe<(
      { __typename?: 'InsuredSubject' }
      & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
      & { cityPart: Maybe<(
        { __typename?: 'CityPart' }
        & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
      )>, address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, correspondenceAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, functionOfRepresentative: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    )>, subjectCarOwner: Maybe<(
      { __typename?: 'InsuredSubject' }
      & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
      & { cityPart: Maybe<(
        { __typename?: 'CityPart' }
        & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
      )>, address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, correspondenceAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, functionOfRepresentative: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    )>, selectedOffer: Maybe<(
      { __typename?: 'VehicleInsuranceProductOffer' }
      & Pick<VehicleInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'paymentFrequency' | 'calculatedPaymentFrequency'>
      & { mtplProduct: Maybe<(
        { __typename?: 'VehicleInsuranceProduct' }
        & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, cascoProduct: Maybe<(
        { __typename?: 'VehicleInsuranceProduct' }
        & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, estimatedPrice: (
        { __typename?: 'VehicleInsuranceEstimatedPrice' }
        & Pick<VehicleInsuranceEstimatedPrice, 'yearly' | 'quarterly' | 'halfYearly' | 'monthly'>
      ) }
    )>, contactPerson: Maybe<(
      { __typename?: 'VehicleInsuranceContactPerson' }
      & Pick<VehicleInsuranceContactPerson, 'name' | 'surname' | 'email' | 'phone' | 'agreementProcessPersonalData' | 'agreementTermsAndConditions'>
    )>, contract: Maybe<(
      { __typename?: 'VehicleInsuranceContract' }
      & Pick<VehicleInsuranceContract, 'id' | 'uuid' | 'payed' | 'paymentUrl'>
      & { price: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency'>
      ), annualPrice: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency'>
      ), documents: Array<Maybe<(
        { __typename?: 'VehicleInsuranceContractDocument' }
        & Pick<VehicleInsuranceContractDocument, 'id' | 'name' | 'documentType'>
        & { documentCategory: (
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        ), file: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>>, paymentInfo: Maybe<(
        { __typename?: 'VehicleInsuranceContractPaymentInfo' }
        & Pick<VehicleInsuranceContractPaymentInfo, 'account' | 'bankCode' | 'constantSymbol' | 'specificSymbol' | 'variableSymbol' | 'message' | 'paymentUrl'>
        & { amount: Maybe<(
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'currency'>
        )>, qrCodeFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, casco: Maybe<(
        { __typename?: 'VehicleInsuranceProduct' }
        & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, mtpl: Maybe<(
        { __typename?: 'VehicleInsuranceProduct' }
        & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )> }
    )>, additionalOptimumRequirements: (
      { __typename?: 'VehicleInsuranceAdditionalRequirementsFromClient' }
      & Pick<VehicleInsuranceAdditionalRequirementsFromClient, 'glass' | 'animalAccident'>
    ) }
  ) }
);

export type VehicleInsuranceContractAdditionalInfoMutationVariables = Exact<{
  uuid: Scalars['String']['input'];
  data: VehicleInsuranceContractAdditionalInfoInput;
}>;


export type VehicleInsuranceContractAdditionalInfoMutation = (
  { __typename?: 'Mutation' }
  & { vehicleInsuranceOrderContractUpdateAdditionalInfo: (
    { __typename?: 'VehicleInsuranceOrder' }
    & Pick<VehicleInsuranceOrder, 'id' | 'uuid' | 'createdAt' | 'status' | 'packagesInsuranceType' | 'selectedInsuranceType' | 'insurableInterest' | 'resultEmail' | 'phone' | 'calculationCurrentStep' | 'orderCurrentStep' | 'updatedAt' | 'hasSpecificPackages' | 'beginDate'>
    & { vehicle: (
      { __typename?: 'InsuredVehicle' }
      & Pick<InsuredVehicle, 'uuid' | 'vehicleType' | 'registrationNumber' | 'vin' | 'kilometersTotal' | 'ownedMoreThanAYear' | 'newVehicle' | 'contractualCarService' | 'vehicleValue' | 'vehicleValueWithVat' | 'firstRegistrationDate' | 'numberOfRegistrationCertificate' | 'vehicleSearchStatus'>
      & { expectedKilometersPerYear: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, manufacturer: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, fuel: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, engineDisplacement: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, maxPower: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, countOfSeats: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, maxWeight: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, modelLine: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, model: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, modelType: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, security: (
        { __typename?: 'VehicleSecurity' }
        & Pick<VehicleSecurity, 'none' | 'mechanical' | 'passiveSearch' | 'activeSearch' | 'glassVin' | 'immobilizer' | 'alarm'>
      ), carUsageMethod: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, additionalEquipment: Array<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    ), subjectInsured: (
      { __typename?: 'InsuredSubject' }
      & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
      & { cityPart: Maybe<(
        { __typename?: 'CityPart' }
        & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
      )>, address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, correspondenceAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, functionOfRepresentative: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    ), subjectCarHolder: Maybe<(
      { __typename?: 'InsuredSubject' }
      & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
      & { cityPart: Maybe<(
        { __typename?: 'CityPart' }
        & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
      )>, address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, correspondenceAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, functionOfRepresentative: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    )>, subjectCarOwner: Maybe<(
      { __typename?: 'InsuredSubject' }
      & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
      & { cityPart: Maybe<(
        { __typename?: 'CityPart' }
        & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
      )>, address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, correspondenceAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, functionOfRepresentative: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    )>, selectedOffer: Maybe<(
      { __typename?: 'VehicleInsuranceProductOffer' }
      & Pick<VehicleInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'paymentFrequency' | 'calculatedPaymentFrequency'>
      & { mtplProduct: Maybe<(
        { __typename?: 'VehicleInsuranceProduct' }
        & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, cascoProduct: Maybe<(
        { __typename?: 'VehicleInsuranceProduct' }
        & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, estimatedPrice: (
        { __typename?: 'VehicleInsuranceEstimatedPrice' }
        & Pick<VehicleInsuranceEstimatedPrice, 'yearly' | 'quarterly' | 'halfYearly' | 'monthly'>
      ) }
    )>, contactPerson: Maybe<(
      { __typename?: 'VehicleInsuranceContactPerson' }
      & Pick<VehicleInsuranceContactPerson, 'name' | 'surname' | 'email' | 'phone' | 'agreementProcessPersonalData' | 'agreementTermsAndConditions'>
    )>, contract: Maybe<(
      { __typename?: 'VehicleInsuranceContract' }
      & Pick<VehicleInsuranceContract, 'id' | 'uuid' | 'payed' | 'paymentUrl'>
      & { price: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency'>
      ), annualPrice: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency'>
      ), documents: Array<Maybe<(
        { __typename?: 'VehicleInsuranceContractDocument' }
        & Pick<VehicleInsuranceContractDocument, 'id' | 'name' | 'documentType'>
        & { documentCategory: (
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        ), file: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>>, paymentInfo: Maybe<(
        { __typename?: 'VehicleInsuranceContractPaymentInfo' }
        & Pick<VehicleInsuranceContractPaymentInfo, 'account' | 'bankCode' | 'constantSymbol' | 'specificSymbol' | 'variableSymbol' | 'message' | 'paymentUrl'>
        & { amount: Maybe<(
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'currency'>
        )>, qrCodeFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, casco: Maybe<(
        { __typename?: 'VehicleInsuranceProduct' }
        & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, mtpl: Maybe<(
        { __typename?: 'VehicleInsuranceProduct' }
        & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )> }
    )>, additionalOptimumRequirements: (
      { __typename?: 'VehicleInsuranceAdditionalRequirementsFromClient' }
      & Pick<VehicleInsuranceAdditionalRequirementsFromClient, 'glass' | 'animalAccident'>
    ) }
  ) }
);

export type VehicleInsuranceCalculationStartMutationVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;


export type VehicleInsuranceCalculationStartMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'vehicleInsuranceOrderCalculationStart'>
);

export type VehicleInsuranceCalculationStartWithSpecificInsuranceTypeMutationVariables = Exact<{
  uuid: Scalars['String']['input'];
  data: VehicleInsuranceCalculationStartWithSpecificInsuranceTypeInput;
}>;


export type VehicleInsuranceCalculationStartWithSpecificInsuranceTypeMutation = (
  { __typename?: 'Mutation' }
  & { vehicleInsuranceOrderCalculationStartWithSpecificInsuranceType: (
    { __typename?: 'VehicleInsuranceOrder' }
    & Pick<VehicleInsuranceOrder, 'id'>
  ) }
);

export type VehicleInsuranceSetCalculationCurrentStepMutationVariables = Exact<{
  uuid: Scalars['String']['input'];
  data: VehicleInsuranceSetCalculationCurrentStepInput;
}>;


export type VehicleInsuranceSetCalculationCurrentStepMutation = (
  { __typename?: 'Mutation' }
  & { vehicleInsuranceSetCalculationCurrentStep: (
    { __typename?: 'VehicleInsuranceOrder' }
    & Pick<VehicleInsuranceOrder, 'id' | 'calculationCurrentStep'>
  ) }
);

export type VehicleInsuranceSetOrderCurrentStepMutationVariables = Exact<{
  uuid: Scalars['String']['input'];
  data: VehicleInsuranceSetOrderCurrentStepInput;
}>;


export type VehicleInsuranceSetOrderCurrentStepMutation = (
  { __typename?: 'Mutation' }
  & { vehicleInsuranceSetOrderCurrentStep: (
    { __typename?: 'VehicleInsuranceOrder' }
    & Pick<VehicleInsuranceOrder, 'id' | 'orderCurrentStep'>
  ) }
);

export type VehicleInsuranceOfferSetPaymentFrequencyMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  data: VehicleInsuranceOfferSetPaymentFrequencyInput;
}>;


export type VehicleInsuranceOfferSetPaymentFrequencyMutation = (
  { __typename?: 'Mutation' }
  & { vehicleInsuranceOfferSetPaymentFrequency: (
    { __typename?: 'VehicleInsuranceProductOffer' }
    & Pick<VehicleInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'paymentFrequency' | 'calculatedPaymentFrequency'>
    & { mtplProduct: Maybe<(
      { __typename?: 'VehicleInsuranceProduct' }
      & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
      & { partner: (
        { __typename?: 'ZeteoInsurancePartner' }
        & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
        & { logo: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )>, logoSquare: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )>, rating2: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), productDetailFile: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )> }
    )>, cascoProduct: Maybe<(
      { __typename?: 'VehicleInsuranceProduct' }
      & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
      & { partner: (
        { __typename?: 'ZeteoInsurancePartner' }
        & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
        & { logo: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )>, logoSquare: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )>, rating2: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), productDetailFile: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )> }
    )>, estimatedPrice: (
      { __typename?: 'VehicleInsuranceEstimatedPrice' }
      & Pick<VehicleInsuranceEstimatedPrice, 'yearly' | 'quarterly' | 'halfYearly' | 'monthly'>
    ) }
  ) }
);

export type VehicleInsuranceOrderChangeBeginDateMutationVariables = Exact<{
  orderUuid: Scalars['String']['input'];
  data: VehicleInsuranceChangeBeginDateInput;
}>;


export type VehicleInsuranceOrderChangeBeginDateMutation = (
  { __typename?: 'Mutation' }
  & { vehicleInsuranceChangeBeginDate: (
    { __typename?: 'VehicleInsuranceOrder' }
    & Pick<VehicleInsuranceOrder, 'beginDate'>
  ) }
);

export type VehicleInsuranceContractRecalculateMutationVariables = Exact<{
  orderUuid: Scalars['String']['input'];
}>;


export type VehicleInsuranceContractRecalculateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'vehicleInsuranceContractRecalculate'>
);

export type VehicleInsuranceSetOneClickOrderStatusMutationVariables = Exact<{
  uuid: Scalars['String']['input'];
  data: VehicleInsuranceSetOrderStatusInput;
}>;


export type VehicleInsuranceSetOneClickOrderStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'vehicleInsuranceSetOrderStatus'>
);

export type CopyVehicleInsuranceOrderMutationVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;


export type CopyVehicleInsuranceOrderMutation = (
  { __typename?: 'Mutation' }
  & { order: (
    { __typename?: 'VehicleInsuranceOrder' }
    & Pick<VehicleInsuranceOrder, 'id' | 'uuid' | 'createdAt' | 'status' | 'packagesInsuranceType' | 'selectedInsuranceType' | 'insurableInterest' | 'resultEmail' | 'phone' | 'calculationCurrentStep' | 'orderCurrentStep' | 'updatedAt' | 'hasSpecificPackages' | 'beginDate'>
    & { vehicle: (
      { __typename?: 'InsuredVehicle' }
      & Pick<InsuredVehicle, 'uuid' | 'vehicleType' | 'registrationNumber' | 'vin' | 'kilometersTotal' | 'ownedMoreThanAYear' | 'newVehicle' | 'contractualCarService' | 'vehicleValue' | 'vehicleValueWithVat' | 'firstRegistrationDate' | 'numberOfRegistrationCertificate' | 'vehicleSearchStatus'>
      & { expectedKilometersPerYear: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, manufacturer: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, fuel: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, engineDisplacement: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, maxPower: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, countOfSeats: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, maxWeight: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, modelLine: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, model: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, modelType: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, security: (
        { __typename?: 'VehicleSecurity' }
        & Pick<VehicleSecurity, 'none' | 'mechanical' | 'passiveSearch' | 'activeSearch' | 'glassVin' | 'immobilizer' | 'alarm'>
      ), carUsageMethod: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, additionalEquipment: Array<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    ), subjectInsured: (
      { __typename?: 'InsuredSubject' }
      & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
      & { cityPart: Maybe<(
        { __typename?: 'CityPart' }
        & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
      )>, address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, correspondenceAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, functionOfRepresentative: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    ), subjectCarHolder: Maybe<(
      { __typename?: 'InsuredSubject' }
      & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
      & { cityPart: Maybe<(
        { __typename?: 'CityPart' }
        & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
      )>, address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, correspondenceAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, functionOfRepresentative: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    )>, subjectCarOwner: Maybe<(
      { __typename?: 'InsuredSubject' }
      & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
      & { cityPart: Maybe<(
        { __typename?: 'CityPart' }
        & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
      )>, address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, correspondenceAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, functionOfRepresentative: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    )>, selectedOffer: Maybe<(
      { __typename?: 'VehicleInsuranceProductOffer' }
      & Pick<VehicleInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'paymentFrequency' | 'calculatedPaymentFrequency'>
      & { mtplProduct: Maybe<(
        { __typename?: 'VehicleInsuranceProduct' }
        & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, cascoProduct: Maybe<(
        { __typename?: 'VehicleInsuranceProduct' }
        & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, estimatedPrice: (
        { __typename?: 'VehicleInsuranceEstimatedPrice' }
        & Pick<VehicleInsuranceEstimatedPrice, 'yearly' | 'quarterly' | 'halfYearly' | 'monthly'>
      ) }
    )>, contactPerson: Maybe<(
      { __typename?: 'VehicleInsuranceContactPerson' }
      & Pick<VehicleInsuranceContactPerson, 'name' | 'surname' | 'email' | 'phone' | 'agreementProcessPersonalData' | 'agreementTermsAndConditions'>
    )>, contract: Maybe<(
      { __typename?: 'VehicleInsuranceContract' }
      & Pick<VehicleInsuranceContract, 'id' | 'uuid' | 'payed' | 'paymentUrl'>
      & { price: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency'>
      ), annualPrice: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency'>
      ), documents: Array<Maybe<(
        { __typename?: 'VehicleInsuranceContractDocument' }
        & Pick<VehicleInsuranceContractDocument, 'id' | 'name' | 'documentType'>
        & { documentCategory: (
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        ), file: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>>, paymentInfo: Maybe<(
        { __typename?: 'VehicleInsuranceContractPaymentInfo' }
        & Pick<VehicleInsuranceContractPaymentInfo, 'account' | 'bankCode' | 'constantSymbol' | 'specificSymbol' | 'variableSymbol' | 'message' | 'paymentUrl'>
        & { amount: Maybe<(
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'currency'>
        )>, qrCodeFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, casco: Maybe<(
        { __typename?: 'VehicleInsuranceProduct' }
        & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, mtpl: Maybe<(
        { __typename?: 'VehicleInsuranceProduct' }
        & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )> }
    )>, additionalOptimumRequirements: (
      { __typename?: 'VehicleInsuranceAdditionalRequirementsFromClient' }
      & Pick<VehicleInsuranceAdditionalRequirementsFromClient, 'glass' | 'animalAccident'>
    ) }
  ) }
);

export type AutocompleteZeteoAddressQueryVariables = Exact<{
  zipCode: Scalars['String']['input'];
}>;


export type AutocompleteZeteoAddressQuery = (
  { __typename?: 'Query' }
  & { addresses: Array<(
    { __typename?: 'CityPart' }
    & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
  )> }
);

export type VehicleInsuranceCodeBooksGetQueryVariables = Exact<{ [key: string]: never; }>;


export type VehicleInsuranceCodeBooksGetQuery = (
  { __typename?: 'Query' }
  & { codeBooks: (
    { __typename?: 'VehicleInsuranceCodeBooks' }
    & { carUsageMethod: Array<(
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    )>, country: Array<(
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    )>, kilometersPerYearVehicle: Array<(
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    )>, vehicleAcquisitionMethod: Array<(
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    )>, additionalEquipment: Array<(
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    )>, representativeFunctions: Array<(
      { __typename?: 'CodeBook' }
      & Pick<CodeBook, 'id' | 'label'>
    )> }
  ) }
);

export type VehicleInsuranceOrderGetQueryVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;


export type VehicleInsuranceOrderGetQuery = (
  { __typename?: 'Query' }
  & { order: (
    { __typename?: 'VehicleInsuranceOrder' }
    & Pick<VehicleInsuranceOrder, 'id' | 'uuid' | 'createdAt' | 'status' | 'packagesInsuranceType' | 'selectedInsuranceType' | 'insurableInterest' | 'resultEmail' | 'phone' | 'calculationCurrentStep' | 'orderCurrentStep' | 'updatedAt' | 'hasSpecificPackages' | 'beginDate'>
    & { vehicle: (
      { __typename?: 'InsuredVehicle' }
      & Pick<InsuredVehicle, 'uuid' | 'vehicleType' | 'registrationNumber' | 'vin' | 'kilometersTotal' | 'ownedMoreThanAYear' | 'newVehicle' | 'contractualCarService' | 'vehicleValue' | 'vehicleValueWithVat' | 'firstRegistrationDate' | 'numberOfRegistrationCertificate' | 'vehicleSearchStatus'>
      & { expectedKilometersPerYear: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, manufacturer: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, fuel: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, engineDisplacement: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, maxPower: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, countOfSeats: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, maxWeight: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, modelLine: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, model: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, modelType: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, security: (
        { __typename?: 'VehicleSecurity' }
        & Pick<VehicleSecurity, 'none' | 'mechanical' | 'passiveSearch' | 'activeSearch' | 'glassVin' | 'immobilizer' | 'alarm'>
      ), carUsageMethod: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, additionalEquipment: Array<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    ), subjectInsured: (
      { __typename?: 'InsuredSubject' }
      & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
      & { cityPart: Maybe<(
        { __typename?: 'CityPart' }
        & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
      )>, address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, correspondenceAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, functionOfRepresentative: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    ), subjectCarHolder: Maybe<(
      { __typename?: 'InsuredSubject' }
      & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
      & { cityPart: Maybe<(
        { __typename?: 'CityPart' }
        & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
      )>, address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, correspondenceAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, functionOfRepresentative: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    )>, subjectCarOwner: Maybe<(
      { __typename?: 'InsuredSubject' }
      & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
      & { cityPart: Maybe<(
        { __typename?: 'CityPart' }
        & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
      )>, address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, correspondenceAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, functionOfRepresentative: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    )>, selectedOffer: Maybe<(
      { __typename?: 'VehicleInsuranceProductOffer' }
      & Pick<VehicleInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'paymentFrequency' | 'calculatedPaymentFrequency'>
      & { mtplProduct: Maybe<(
        { __typename?: 'VehicleInsuranceProduct' }
        & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, cascoProduct: Maybe<(
        { __typename?: 'VehicleInsuranceProduct' }
        & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, estimatedPrice: (
        { __typename?: 'VehicleInsuranceEstimatedPrice' }
        & Pick<VehicleInsuranceEstimatedPrice, 'yearly' | 'quarterly' | 'halfYearly' | 'monthly'>
      ) }
    )>, contactPerson: Maybe<(
      { __typename?: 'VehicleInsuranceContactPerson' }
      & Pick<VehicleInsuranceContactPerson, 'name' | 'surname' | 'email' | 'phone' | 'agreementProcessPersonalData' | 'agreementTermsAndConditions'>
    )>, contract: Maybe<(
      { __typename?: 'VehicleInsuranceContract' }
      & Pick<VehicleInsuranceContract, 'id' | 'uuid' | 'payed' | 'paymentUrl'>
      & { price: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency'>
      ), annualPrice: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency'>
      ), documents: Array<Maybe<(
        { __typename?: 'VehicleInsuranceContractDocument' }
        & Pick<VehicleInsuranceContractDocument, 'id' | 'name' | 'documentType'>
        & { documentCategory: (
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        ), file: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>>, paymentInfo: Maybe<(
        { __typename?: 'VehicleInsuranceContractPaymentInfo' }
        & Pick<VehicleInsuranceContractPaymentInfo, 'account' | 'bankCode' | 'constantSymbol' | 'specificSymbol' | 'variableSymbol' | 'message' | 'paymentUrl'>
        & { amount: Maybe<(
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'currency'>
        )>, qrCodeFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, casco: Maybe<(
        { __typename?: 'VehicleInsuranceProduct' }
        & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, mtpl: Maybe<(
        { __typename?: 'VehicleInsuranceProduct' }
        & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )> }
    )>, additionalOptimumRequirements: (
      { __typename?: 'VehicleInsuranceAdditionalRequirementsFromClient' }
      & Pick<VehicleInsuranceAdditionalRequirementsFromClient, 'glass' | 'animalAccident'>
    ) }
  ) }
);

export type VehicleInsuranceOrderCalculationResultGetQueryVariables = Exact<{
  orderUuid: Scalars['String']['input'];
}>;


export type VehicleInsuranceOrderCalculationResultGetQuery = (
  { __typename?: 'Query' }
  & { calculationResult: (
    { __typename?: 'VehicleInsuranceCalculationStateResult' }
    & { state: (
      { __typename?: 'ZeteoState' }
      & Pick<ZeteoState, 'maximumSteps' | 'finishedSteps' | 'finished' | 'error'>
    ), result: Maybe<(
      { __typename?: 'VehicleInsuranceOrderResult' }
      & Pick<VehicleInsuranceOrderResult, 'id' | 'lowestPrice'>
      & { order: (
        { __typename?: 'VehicleInsuranceOrder' }
        & Pick<VehicleInsuranceOrder, 'id' | 'uuid' | 'createdAt' | 'status' | 'packagesInsuranceType' | 'selectedInsuranceType' | 'insurableInterest' | 'resultEmail' | 'phone' | 'calculationCurrentStep' | 'orderCurrentStep' | 'updatedAt' | 'hasSpecificPackages' | 'beginDate'>
        & { vehicle: (
          { __typename?: 'InsuredVehicle' }
          & Pick<InsuredVehicle, 'uuid' | 'vehicleType' | 'registrationNumber' | 'vin' | 'kilometersTotal' | 'ownedMoreThanAYear' | 'newVehicle' | 'contractualCarService' | 'vehicleValue' | 'vehicleValueWithVat' | 'firstRegistrationDate' | 'numberOfRegistrationCertificate' | 'vehicleSearchStatus'>
          & { expectedKilometersPerYear: Maybe<(
            { __typename?: 'CodeBook' }
            & Pick<CodeBook, 'id' | 'label'>
          )>, manufacturer: Maybe<(
            { __typename?: 'CodeBook' }
            & Pick<CodeBook, 'id' | 'label'>
          )>, fuel: Maybe<(
            { __typename?: 'CodeBook' }
            & Pick<CodeBook, 'id' | 'label'>
          )>, engineDisplacement: Maybe<(
            { __typename?: 'CodeBook' }
            & Pick<CodeBook, 'id' | 'label'>
          )>, maxPower: Maybe<(
            { __typename?: 'CodeBook' }
            & Pick<CodeBook, 'id' | 'label'>
          )>, countOfSeats: Maybe<(
            { __typename?: 'CodeBook' }
            & Pick<CodeBook, 'id' | 'label'>
          )>, maxWeight: Maybe<(
            { __typename?: 'CodeBook' }
            & Pick<CodeBook, 'id' | 'label'>
          )>, modelLine: Maybe<(
            { __typename?: 'CodeBook' }
            & Pick<CodeBook, 'id' | 'label'>
          )>, model: Maybe<(
            { __typename?: 'CodeBook' }
            & Pick<CodeBook, 'id' | 'label'>
          )>, modelType: Maybe<(
            { __typename?: 'CodeBook' }
            & Pick<CodeBook, 'id' | 'label'>
          )>, security: (
            { __typename?: 'VehicleSecurity' }
            & Pick<VehicleSecurity, 'none' | 'mechanical' | 'passiveSearch' | 'activeSearch' | 'glassVin' | 'immobilizer' | 'alarm'>
          ), carUsageMethod: Maybe<(
            { __typename?: 'CodeBook' }
            & Pick<CodeBook, 'id' | 'label'>
          )>, additionalEquipment: Array<(
            { __typename?: 'CodeBook' }
            & Pick<CodeBook, 'id' | 'label'>
          )> }
        ), subjectInsured: (
          { __typename?: 'InsuredSubject' }
          & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
          & { cityPart: Maybe<(
            { __typename?: 'CityPart' }
            & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
          )>, address: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
            & { extended: Maybe<(
              { __typename?: 'SmartAddressExtendedInfo' }
              & Pick<SmartAddressExtendedInfo, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )>, extendedV2: Maybe<(
              { __typename?: 'SmartAddressSdkExtendedInfo' }
              & { administrativniUdaje: Maybe<(
                { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
                & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
                & { objectType: Maybe<(
                  { __typename?: 'SmartAddressObjectType' }
                  & Pick<SmartAddressObjectType, 'id' | 'nazev'>
                )> }
              )> }
            )> }
          )>, correspondenceAddress: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
            & { extended: Maybe<(
              { __typename?: 'SmartAddressExtendedInfo' }
              & Pick<SmartAddressExtendedInfo, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )>, extendedV2: Maybe<(
              { __typename?: 'SmartAddressSdkExtendedInfo' }
              & { administrativniUdaje: Maybe<(
                { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
                & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
                & { objectType: Maybe<(
                  { __typename?: 'SmartAddressObjectType' }
                  & Pick<SmartAddressObjectType, 'id' | 'nazev'>
                )> }
              )> }
            )> }
          )>, functionOfRepresentative: Maybe<(
            { __typename?: 'CodeBook' }
            & Pick<CodeBook, 'id' | 'label'>
          )> }
        ), subjectCarHolder: Maybe<(
          { __typename?: 'InsuredSubject' }
          & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
          & { cityPart: Maybe<(
            { __typename?: 'CityPart' }
            & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
          )>, address: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
            & { extended: Maybe<(
              { __typename?: 'SmartAddressExtendedInfo' }
              & Pick<SmartAddressExtendedInfo, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )>, extendedV2: Maybe<(
              { __typename?: 'SmartAddressSdkExtendedInfo' }
              & { administrativniUdaje: Maybe<(
                { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
                & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
                & { objectType: Maybe<(
                  { __typename?: 'SmartAddressObjectType' }
                  & Pick<SmartAddressObjectType, 'id' | 'nazev'>
                )> }
              )> }
            )> }
          )>, correspondenceAddress: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
            & { extended: Maybe<(
              { __typename?: 'SmartAddressExtendedInfo' }
              & Pick<SmartAddressExtendedInfo, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )>, extendedV2: Maybe<(
              { __typename?: 'SmartAddressSdkExtendedInfo' }
              & { administrativniUdaje: Maybe<(
                { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
                & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
                & { objectType: Maybe<(
                  { __typename?: 'SmartAddressObjectType' }
                  & Pick<SmartAddressObjectType, 'id' | 'nazev'>
                )> }
              )> }
            )> }
          )>, functionOfRepresentative: Maybe<(
            { __typename?: 'CodeBook' }
            & Pick<CodeBook, 'id' | 'label'>
          )> }
        )>, subjectCarOwner: Maybe<(
          { __typename?: 'InsuredSubject' }
          & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
          & { cityPart: Maybe<(
            { __typename?: 'CityPart' }
            & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
          )>, address: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
            & { extended: Maybe<(
              { __typename?: 'SmartAddressExtendedInfo' }
              & Pick<SmartAddressExtendedInfo, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )>, extendedV2: Maybe<(
              { __typename?: 'SmartAddressSdkExtendedInfo' }
              & { administrativniUdaje: Maybe<(
                { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
                & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
                & { objectType: Maybe<(
                  { __typename?: 'SmartAddressObjectType' }
                  & Pick<SmartAddressObjectType, 'id' | 'nazev'>
                )> }
              )> }
            )> }
          )>, correspondenceAddress: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
            & { extended: Maybe<(
              { __typename?: 'SmartAddressExtendedInfo' }
              & Pick<SmartAddressExtendedInfo, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )>, extendedV2: Maybe<(
              { __typename?: 'SmartAddressSdkExtendedInfo' }
              & { administrativniUdaje: Maybe<(
                { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
                & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
                & { objectType: Maybe<(
                  { __typename?: 'SmartAddressObjectType' }
                  & Pick<SmartAddressObjectType, 'id' | 'nazev'>
                )> }
              )> }
            )> }
          )>, functionOfRepresentative: Maybe<(
            { __typename?: 'CodeBook' }
            & Pick<CodeBook, 'id' | 'label'>
          )> }
        )>, selectedOffer: Maybe<(
          { __typename?: 'VehicleInsuranceProductOffer' }
          & Pick<VehicleInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'paymentFrequency' | 'calculatedPaymentFrequency'>
          & { mtplProduct: Maybe<(
            { __typename?: 'VehicleInsuranceProduct' }
            & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
            & { partner: (
              { __typename?: 'ZeteoInsurancePartner' }
              & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
              & { logo: Maybe<(
                { __typename?: 'File' }
                & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
              )>, logoSquare: Maybe<(
                { __typename?: 'File' }
                & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
              )>, rating2: Maybe<(
                { __typename?: 'NewRating' }
                & Pick<NewRating, 'id' | 'total'>
                & { categories: Array<Maybe<(
                  { __typename?: 'RatingCategory' }
                  & Pick<RatingCategory, 'id' | 'label' | 'value'>
                )>> }
              )> }
            ), productDetailFile: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )> }
          )>, cascoProduct: Maybe<(
            { __typename?: 'VehicleInsuranceProduct' }
            & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
            & { partner: (
              { __typename?: 'ZeteoInsurancePartner' }
              & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
              & { logo: Maybe<(
                { __typename?: 'File' }
                & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
              )>, logoSquare: Maybe<(
                { __typename?: 'File' }
                & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
              )>, rating2: Maybe<(
                { __typename?: 'NewRating' }
                & Pick<NewRating, 'id' | 'total'>
                & { categories: Array<Maybe<(
                  { __typename?: 'RatingCategory' }
                  & Pick<RatingCategory, 'id' | 'label' | 'value'>
                )>> }
              )> }
            ), productDetailFile: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )> }
          )>, estimatedPrice: (
            { __typename?: 'VehicleInsuranceEstimatedPrice' }
            & Pick<VehicleInsuranceEstimatedPrice, 'yearly' | 'quarterly' | 'halfYearly' | 'monthly'>
          ) }
        )>, contactPerson: Maybe<(
          { __typename?: 'VehicleInsuranceContactPerson' }
          & Pick<VehicleInsuranceContactPerson, 'name' | 'surname' | 'email' | 'phone' | 'agreementProcessPersonalData' | 'agreementTermsAndConditions'>
        )>, contract: Maybe<(
          { __typename?: 'VehicleInsuranceContract' }
          & Pick<VehicleInsuranceContract, 'id' | 'uuid' | 'payed' | 'paymentUrl'>
          & { price: (
            { __typename?: 'Money' }
            & Pick<Money, 'amount' | 'currency'>
          ), annualPrice: (
            { __typename?: 'Money' }
            & Pick<Money, 'amount' | 'currency'>
          ), documents: Array<Maybe<(
            { __typename?: 'VehicleInsuranceContractDocument' }
            & Pick<VehicleInsuranceContractDocument, 'id' | 'name' | 'documentType'>
            & { documentCategory: (
              { __typename?: 'CodeBook' }
              & Pick<CodeBook, 'id' | 'label'>
            ), file: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )> }
          )>>, paymentInfo: Maybe<(
            { __typename?: 'VehicleInsuranceContractPaymentInfo' }
            & Pick<VehicleInsuranceContractPaymentInfo, 'account' | 'bankCode' | 'constantSymbol' | 'specificSymbol' | 'variableSymbol' | 'message' | 'paymentUrl'>
            & { amount: Maybe<(
              { __typename?: 'Money' }
              & Pick<Money, 'amount' | 'currency'>
            )>, qrCodeFile: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )> }
          )>, casco: Maybe<(
            { __typename?: 'VehicleInsuranceProduct' }
            & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
            & { partner: (
              { __typename?: 'ZeteoInsurancePartner' }
              & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
              & { logo: Maybe<(
                { __typename?: 'File' }
                & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
              )>, logoSquare: Maybe<(
                { __typename?: 'File' }
                & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
              )>, rating2: Maybe<(
                { __typename?: 'NewRating' }
                & Pick<NewRating, 'id' | 'total'>
                & { categories: Array<Maybe<(
                  { __typename?: 'RatingCategory' }
                  & Pick<RatingCategory, 'id' | 'label' | 'value'>
                )>> }
              )> }
            ), productDetailFile: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )> }
          )>, mtpl: Maybe<(
            { __typename?: 'VehicleInsuranceProduct' }
            & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
            & { partner: (
              { __typename?: 'ZeteoInsurancePartner' }
              & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
              & { logo: Maybe<(
                { __typename?: 'File' }
                & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
              )>, logoSquare: Maybe<(
                { __typename?: 'File' }
                & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
              )>, rating2: Maybe<(
                { __typename?: 'NewRating' }
                & Pick<NewRating, 'id' | 'total'>
                & { categories: Array<Maybe<(
                  { __typename?: 'RatingCategory' }
                  & Pick<RatingCategory, 'id' | 'label' | 'value'>
                )>> }
              )> }
            ), productDetailFile: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )> }
          )> }
        )>, additionalOptimumRequirements: (
          { __typename?: 'VehicleInsuranceAdditionalRequirementsFromClient' }
          & Pick<VehicleInsuranceAdditionalRequirementsFromClient, 'glass' | 'animalAccident'>
        ) }
      ), packages: Array<(
        { __typename?: 'VehicleInsurancePackage' }
        & Pick<VehicleInsurancePackage, 'id' | 'uuid' | 'type' | 'name' | 'protectionCover' | 'lowestPrice' | 'participation' | 'mobileOrder'>
        & { productOffers: Array<(
          { __typename?: 'VehicleInsuranceProductOffer' }
          & Pick<VehicleInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'paymentFrequency' | 'calculatedPaymentFrequency'>
          & { mtplProduct: Maybe<(
            { __typename?: 'VehicleInsuranceProduct' }
            & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
            & { partner: (
              { __typename?: 'ZeteoInsurancePartner' }
              & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
              & { logo: Maybe<(
                { __typename?: 'File' }
                & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
              )>, logoSquare: Maybe<(
                { __typename?: 'File' }
                & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
              )>, rating2: Maybe<(
                { __typename?: 'NewRating' }
                & Pick<NewRating, 'id' | 'total'>
                & { categories: Array<Maybe<(
                  { __typename?: 'RatingCategory' }
                  & Pick<RatingCategory, 'id' | 'label' | 'value'>
                )>> }
              )> }
            ), productDetailFile: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )> }
          )>, cascoProduct: Maybe<(
            { __typename?: 'VehicleInsuranceProduct' }
            & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
            & { partner: (
              { __typename?: 'ZeteoInsurancePartner' }
              & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
              & { logo: Maybe<(
                { __typename?: 'File' }
                & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
              )>, logoSquare: Maybe<(
                { __typename?: 'File' }
                & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
              )>, rating2: Maybe<(
                { __typename?: 'NewRating' }
                & Pick<NewRating, 'id' | 'total'>
                & { categories: Array<Maybe<(
                  { __typename?: 'RatingCategory' }
                  & Pick<RatingCategory, 'id' | 'label' | 'value'>
                )>> }
              )> }
            ), productDetailFile: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )> }
          )>, estimatedPrice: (
            { __typename?: 'VehicleInsuranceEstimatedPrice' }
            & Pick<VehicleInsuranceEstimatedPrice, 'yearly' | 'quarterly' | 'halfYearly' | 'monthly'>
          ) }
        )>, additionalRequirements: (
          { __typename?: 'VehicleInsuranceAdditionalRequirements' }
          & Pick<VehicleInsuranceAdditionalRequirements, 'id'>
          & { glass: (
            { __typename?: 'VehicleInsuranceGlassRequirement' }
            & Pick<VehicleInsuranceGlassRequirement, 'enabled' | 'value'>
          ), animalAccident: (
            { __typename?: 'VehicleInsuranceAnimalAccidentRequirement' }
            & Pick<VehicleInsuranceAnimalAccidentRequirement, 'enabled' | 'value'>
          ) }
        ) }
      )>, cheapestOffer: Maybe<(
        { __typename?: 'VehicleInsuranceProductOffer' }
        & Pick<VehicleInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'paymentFrequency' | 'calculatedPaymentFrequency'>
        & { mtplProduct: Maybe<(
          { __typename?: 'VehicleInsuranceProduct' }
          & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, cascoProduct: Maybe<(
          { __typename?: 'VehicleInsuranceProduct' }
          & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, estimatedPrice: (
          { __typename?: 'VehicleInsuranceEstimatedPrice' }
          & Pick<VehicleInsuranceEstimatedPrice, 'yearly' | 'quarterly' | 'halfYearly' | 'monthly'>
        ) }
      )> }
    )> }
  ) }
);

export type VehicleInsurancePartnerListGetQueryVariables = Exact<{ [key: string]: never; }>;


export type VehicleInsurancePartnerListGetQuery = (
  { __typename?: 'Query' }
  & { vehicleInsurancePartnerGetList: Array<(
    { __typename?: 'ZeteoInsurancePartner' }
    & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
    & { logo: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    )>, logoSquare: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    )>, rating2: Maybe<(
      { __typename?: 'NewRating' }
      & Pick<NewRating, 'id' | 'total'>
      & { categories: Array<Maybe<(
        { __typename?: 'RatingCategory' }
        & Pick<RatingCategory, 'id' | 'label' | 'value'>
      )>> }
    )> }
  )> }
);

export type VehicleInsurancePartnerDetailGetQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type VehicleInsurancePartnerDetailGetQuery = (
  { __typename?: 'Query' }
  & { vehicleInsurancePartnerGetDetail: (
    { __typename?: 'ZeteoInsurancePartnerDetail' }
    & Pick<ZeteoInsurancePartnerDetail, 'id' | 'name' | 'seoName' | 'descriptionMD' | 'active' | 'tollFreePhone' | 'web' | 'validTo' | 'validFrom' | 'contactEmail' | 'urlPricelist' | 'urlVop' | 'companyId' | 'companyAddress' | 'longArticleMD' | 'latitude' | 'longitude' | 'order' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'internalLinkLabel1' | 'internalLinkLabel2' | 'internalLinkLabel3' | 'internalLinkUrl1' | 'internalLinkUrl2' | 'internalLinkUrl3'>
    & { logo: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    )>, logoSquare: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    )>, faqs: Array<(
      { __typename?: 'Faq' }
      & Pick<Faq, 'id' | 'question' | 'answerMD'>
    )>, rating2: Maybe<(
      { __typename?: 'NewRating' }
      & Pick<NewRating, 'id' | 'total'>
      & { categories: Array<Maybe<(
        { __typename?: 'RatingCategory' }
        & Pick<RatingCategory, 'id' | 'label' | 'value'>
      )>> }
    )> }
  ) }
);

export type VehicleInsuranceProductDetailGetQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type VehicleInsuranceProductDetailGetQuery = (
  { __typename?: 'Query' }
  & { vehicleInsuranceProductGetDetail: (
    { __typename?: 'VehicleInsuranceProduct' }
    & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
    & { partner: (
      { __typename?: 'ZeteoInsurancePartner' }
      & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
      & { logo: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )>, logoSquare: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )>, rating2: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    ), productDetailFile: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    )> }
  ) }
);

export type VehicleInsuranceProductsByPartnerQueryVariables = Exact<{
  partnerId: Scalars['Int']['input'];
}>;


export type VehicleInsuranceProductsByPartnerQuery = (
  { __typename?: 'Query' }
  & { products: Array<(
    { __typename?: 'VehicleInsuranceProduct' }
    & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
    & { partner: (
      { __typename?: 'ZeteoInsurancePartner' }
      & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
      & { logo: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )>, logoSquare: Maybe<(
        { __typename?: 'File' }
        & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
      )>, rating2: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    ), productDetailFile: Maybe<(
      { __typename?: 'File' }
      & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
    )> }
  )> }
);

export type VehicleInsuranceOrderGetLastQueryVariables = Exact<{ [key: string]: never; }>;


export type VehicleInsuranceOrderGetLastQuery = (
  { __typename?: 'Query' }
  & { vehicleInsuranceOrderGetLast: Maybe<(
    { __typename?: 'VehicleInsuranceLastOrder' }
    & Pick<VehicleInsuranceLastOrder, 'id' | 'registrationNumber'>
    & { cityPart: (
      { __typename?: 'CityPart' }
      & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
    ) }
  )> }
);

export type VehicleInsuranceContractRecapitulationQueryVariables = Exact<{
  orderUuid: Scalars['String']['input'];
}>;


export type VehicleInsuranceContractRecapitulationQuery = (
  { __typename?: 'Query' }
  & { vehicleInsuranceContractRecapitulation: (
    { __typename?: 'VehicleInsuranceContractRecapitulation' }
    & { order: Maybe<(
      { __typename?: 'VehicleInsuranceOrder' }
      & Pick<VehicleInsuranceOrder, 'id' | 'uuid' | 'createdAt' | 'status' | 'packagesInsuranceType' | 'selectedInsuranceType' | 'insurableInterest' | 'resultEmail' | 'phone' | 'calculationCurrentStep' | 'orderCurrentStep' | 'updatedAt' | 'hasSpecificPackages' | 'beginDate'>
      & { vehicle: (
        { __typename?: 'InsuredVehicle' }
        & Pick<InsuredVehicle, 'uuid' | 'vehicleType' | 'registrationNumber' | 'vin' | 'kilometersTotal' | 'ownedMoreThanAYear' | 'newVehicle' | 'contractualCarService' | 'vehicleValue' | 'vehicleValueWithVat' | 'firstRegistrationDate' | 'numberOfRegistrationCertificate' | 'vehicleSearchStatus'>
        & { expectedKilometersPerYear: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, manufacturer: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, fuel: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, engineDisplacement: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, maxPower: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, countOfSeats: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, maxWeight: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, modelLine: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, model: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, modelType: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, security: (
          { __typename?: 'VehicleSecurity' }
          & Pick<VehicleSecurity, 'none' | 'mechanical' | 'passiveSearch' | 'activeSearch' | 'glassVin' | 'immobilizer' | 'alarm'>
        ), carUsageMethod: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, additionalEquipment: Array<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )> }
      ), subjectInsured: (
        { __typename?: 'InsuredSubject' }
        & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
        & { cityPart: Maybe<(
          { __typename?: 'CityPart' }
          & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
        )>, address: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, correspondenceAddress: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, functionOfRepresentative: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )> }
      ), subjectCarHolder: Maybe<(
        { __typename?: 'InsuredSubject' }
        & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
        & { cityPart: Maybe<(
          { __typename?: 'CityPart' }
          & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
        )>, address: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, correspondenceAddress: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, functionOfRepresentative: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )> }
      )>, subjectCarOwner: Maybe<(
        { __typename?: 'InsuredSubject' }
        & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
        & { cityPart: Maybe<(
          { __typename?: 'CityPart' }
          & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
        )>, address: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, correspondenceAddress: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, functionOfRepresentative: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )> }
      )>, selectedOffer: Maybe<(
        { __typename?: 'VehicleInsuranceProductOffer' }
        & Pick<VehicleInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'paymentFrequency' | 'calculatedPaymentFrequency'>
        & { mtplProduct: Maybe<(
          { __typename?: 'VehicleInsuranceProduct' }
          & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, cascoProduct: Maybe<(
          { __typename?: 'VehicleInsuranceProduct' }
          & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, estimatedPrice: (
          { __typename?: 'VehicleInsuranceEstimatedPrice' }
          & Pick<VehicleInsuranceEstimatedPrice, 'yearly' | 'quarterly' | 'halfYearly' | 'monthly'>
        ) }
      )>, contactPerson: Maybe<(
        { __typename?: 'VehicleInsuranceContactPerson' }
        & Pick<VehicleInsuranceContactPerson, 'name' | 'surname' | 'email' | 'phone' | 'agreementProcessPersonalData' | 'agreementTermsAndConditions'>
      )>, contract: Maybe<(
        { __typename?: 'VehicleInsuranceContract' }
        & Pick<VehicleInsuranceContract, 'id' | 'uuid' | 'payed' | 'paymentUrl'>
        & { price: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'currency'>
        ), annualPrice: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'currency'>
        ), documents: Array<Maybe<(
          { __typename?: 'VehicleInsuranceContractDocument' }
          & Pick<VehicleInsuranceContractDocument, 'id' | 'name' | 'documentType'>
          & { documentCategory: (
            { __typename?: 'CodeBook' }
            & Pick<CodeBook, 'id' | 'label'>
          ), file: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>>, paymentInfo: Maybe<(
          { __typename?: 'VehicleInsuranceContractPaymentInfo' }
          & Pick<VehicleInsuranceContractPaymentInfo, 'account' | 'bankCode' | 'constantSymbol' | 'specificSymbol' | 'variableSymbol' | 'message' | 'paymentUrl'>
          & { amount: Maybe<(
            { __typename?: 'Money' }
            & Pick<Money, 'amount' | 'currency'>
          )>, qrCodeFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, casco: Maybe<(
          { __typename?: 'VehicleInsuranceProduct' }
          & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, mtpl: Maybe<(
          { __typename?: 'VehicleInsuranceProduct' }
          & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )> }
      )>, additionalOptimumRequirements: (
        { __typename?: 'VehicleInsuranceAdditionalRequirementsFromClient' }
        & Pick<VehicleInsuranceAdditionalRequirementsFromClient, 'glass' | 'animalAccident'>
      ) }
    )>, recalculationState: (
      { __typename?: 'ZeteoState' }
      & Pick<ZeteoState, 'maximumSteps' | 'finishedSteps' | 'finished' | 'error'>
    ), recapitulationState: Maybe<(
      { __typename?: 'ZeteoState' }
      & Pick<ZeteoState, 'maximumSteps' | 'finishedSteps' | 'finished' | 'error'>
    )>, recapitulation: Maybe<(
      { __typename?: 'VehicleInsuranceRecapitulationInfo' }
      & Pick<VehicleInsuranceRecapitulationInfo, 'oldPrice' | 'price' | 'paymentFrequency'>
    )> }
  ) }
);

export type VehicleInsuranceContractOrderCompletedQueryVariables = Exact<{
  orderUuid: Scalars['String']['input'];
}>;


export type VehicleInsuranceContractOrderCompletedQuery = (
  { __typename?: 'Query' }
  & { vehicleInsuranceContractOrderCompleted: (
    { __typename?: 'VehicleInsuranceContractCompleteOrder' }
    & { finishOrderState: Maybe<(
      { __typename?: 'ZeteoState' }
      & Pick<ZeteoState, 'maximumSteps' | 'finishedSteps' | 'finished' | 'error'>
    )>, order: (
      { __typename?: 'VehicleInsuranceOrder' }
      & Pick<VehicleInsuranceOrder, 'id' | 'uuid' | 'createdAt' | 'status' | 'packagesInsuranceType' | 'selectedInsuranceType' | 'insurableInterest' | 'resultEmail' | 'phone' | 'calculationCurrentStep' | 'orderCurrentStep' | 'updatedAt' | 'hasSpecificPackages' | 'beginDate'>
      & { vehicle: (
        { __typename?: 'InsuredVehicle' }
        & Pick<InsuredVehicle, 'uuid' | 'vehicleType' | 'registrationNumber' | 'vin' | 'kilometersTotal' | 'ownedMoreThanAYear' | 'newVehicle' | 'contractualCarService' | 'vehicleValue' | 'vehicleValueWithVat' | 'firstRegistrationDate' | 'numberOfRegistrationCertificate' | 'vehicleSearchStatus'>
        & { expectedKilometersPerYear: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, manufacturer: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, fuel: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, engineDisplacement: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, maxPower: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, countOfSeats: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, maxWeight: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, modelLine: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, model: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, modelType: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, security: (
          { __typename?: 'VehicleSecurity' }
          & Pick<VehicleSecurity, 'none' | 'mechanical' | 'passiveSearch' | 'activeSearch' | 'glassVin' | 'immobilizer' | 'alarm'>
        ), carUsageMethod: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, additionalEquipment: Array<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )> }
      ), subjectInsured: (
        { __typename?: 'InsuredSubject' }
        & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
        & { cityPart: Maybe<(
          { __typename?: 'CityPart' }
          & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
        )>, address: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, correspondenceAddress: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, functionOfRepresentative: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )> }
      ), subjectCarHolder: Maybe<(
        { __typename?: 'InsuredSubject' }
        & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
        & { cityPart: Maybe<(
          { __typename?: 'CityPart' }
          & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
        )>, address: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, correspondenceAddress: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, functionOfRepresentative: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )> }
      )>, subjectCarOwner: Maybe<(
        { __typename?: 'InsuredSubject' }
        & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
        & { cityPart: Maybe<(
          { __typename?: 'CityPart' }
          & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
        )>, address: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, correspondenceAddress: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, functionOfRepresentative: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )> }
      )>, selectedOffer: Maybe<(
        { __typename?: 'VehicleInsuranceProductOffer' }
        & Pick<VehicleInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'paymentFrequency' | 'calculatedPaymentFrequency'>
        & { mtplProduct: Maybe<(
          { __typename?: 'VehicleInsuranceProduct' }
          & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, cascoProduct: Maybe<(
          { __typename?: 'VehicleInsuranceProduct' }
          & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, estimatedPrice: (
          { __typename?: 'VehicleInsuranceEstimatedPrice' }
          & Pick<VehicleInsuranceEstimatedPrice, 'yearly' | 'quarterly' | 'halfYearly' | 'monthly'>
        ) }
      )>, contactPerson: Maybe<(
        { __typename?: 'VehicleInsuranceContactPerson' }
        & Pick<VehicleInsuranceContactPerson, 'name' | 'surname' | 'email' | 'phone' | 'agreementProcessPersonalData' | 'agreementTermsAndConditions'>
      )>, contract: Maybe<(
        { __typename?: 'VehicleInsuranceContract' }
        & Pick<VehicleInsuranceContract, 'id' | 'uuid' | 'payed' | 'paymentUrl'>
        & { price: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'currency'>
        ), annualPrice: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'currency'>
        ), documents: Array<Maybe<(
          { __typename?: 'VehicleInsuranceContractDocument' }
          & Pick<VehicleInsuranceContractDocument, 'id' | 'name' | 'documentType'>
          & { documentCategory: (
            { __typename?: 'CodeBook' }
            & Pick<CodeBook, 'id' | 'label'>
          ), file: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>>, paymentInfo: Maybe<(
          { __typename?: 'VehicleInsuranceContractPaymentInfo' }
          & Pick<VehicleInsuranceContractPaymentInfo, 'account' | 'bankCode' | 'constantSymbol' | 'specificSymbol' | 'variableSymbol' | 'message' | 'paymentUrl'>
          & { amount: Maybe<(
            { __typename?: 'Money' }
            & Pick<Money, 'amount' | 'currency'>
          )>, qrCodeFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, casco: Maybe<(
          { __typename?: 'VehicleInsuranceProduct' }
          & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, mtpl: Maybe<(
          { __typename?: 'VehicleInsuranceProduct' }
          & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )> }
      )>, additionalOptimumRequirements: (
        { __typename?: 'VehicleInsuranceAdditionalRequirementsFromClient' }
        & Pick<VehicleInsuranceAdditionalRequirementsFromClient, 'glass' | 'animalAccident'>
      ) }
    ) }
  ) }
);

export type WatchWolfFragment = (
  { __typename?: 'WatchWolf' }
  & Pick<WatchWolf, 'uuid' | 'type' | 'phone' | 'preferredEmail' | 'minSaving' | 'commitmentEnd' | 'vertical' | 'subVertical' | 'createdAt' | 'name'>
  & { energoCalculation: Maybe<(
    { __typename?: 'EnergoCalculation' }
    & Pick<EnergoCalculation, 'id' | 'uuid' | 'type' | 'source' | 'yearlyConsumption' | 'yearlyConsumptionUnit' | 'yearlyConsumptionLowTariff' | 'yearlyConsumptionLowTariffUnit' | 'yearlyPayment' | 'gasYearlyPayment' | 'gasYearlyConsumption' | 'gasYearlyConsumptionUnit' | 'gasMonthlyAdvances' | 'gasUnknownMonthlyAdvances' | 'monthlyAdvances' | 'unknownMonthlyAdvances' | 'email' | 'saving' | 'createdAt' | 'updatedAt' | 'lastViewed' | 'currentProductId' | 'currentProductName' | 'currentProductFollowingId' | 'unknownCurrentProduct' | 'gasCurrentProductId' | 'gasCurrentProductName' | 'gasCurrentProductFollowingId' | 'gasUnknownCurrentProduct' | 'phone' | 'individualPriceExpectedMonthly' | 'individualPriceExpectedMWhHighTariff' | 'individualPriceExpectedMWhLowTariff' | 'gasIndividualPriceExpectedMonthly' | 'gasIndividualPriceExpectedMWh' | 'individualOfferName' | 'reason'>
    & { address: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
      & { extended: Maybe<(
        { __typename?: 'SmartAddressExtendedInfo' }
        & Pick<SmartAddressExtendedInfo, 'id'>
        & { objectType: Maybe<(
          { __typename?: 'SmartAddressObjectType' }
          & Pick<SmartAddressObjectType, 'id' | 'nazev'>
        )> }
      )>, extendedV2: Maybe<(
        { __typename?: 'SmartAddressSdkExtendedInfo' }
        & { administrativniUdaje: Maybe<(
          { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
          & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )> }
      )> }
    )>, partner: Maybe<(
      { __typename?: 'EnergoElectricityPartner' }
      & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    )>, gasPartner: Maybe<(
      { __typename?: 'EnergoGasPartner' }
      & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
      & { rating: Maybe<(
        { __typename?: 'NewRating' }
        & Pick<NewRating, 'id' | 'total'>
        & { categories: Array<Maybe<(
          { __typename?: 'RatingCategory' }
          & Pick<RatingCategory, 'id' | 'label' | 'value'>
        )>> }
      )> }
    )>, gasUsages: Maybe<(
      { __typename?: 'GasUsages' }
      & Pick<GasUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
    )>, electricityUsages: Maybe<(
      { __typename?: 'ElectricityUsages' }
      & Pick<ElectricityUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
    )>, floorAreaRange: Maybe<(
      { __typename?: 'EnergoFloorAreaRangeCodebook' }
      & Pick<EnergoFloorAreaRangeCodebook, 'id'>
      & { label: EnergoFloorAreaRangeCodebook['name'] }
    )>, isolation: Maybe<(
      { __typename?: 'EnergoIsolationCodebook' }
      & Pick<EnergoIsolationCodebook, 'id'>
      & { label: EnergoIsolationCodebook['name'] }
    )>, personsCountRange: Maybe<(
      { __typename?: 'EnergoPersonsCountRangeCodebook' }
      & Pick<EnergoPersonsCountRangeCodebook, 'id'>
      & { label: EnergoPersonsCountRangeCodebook['name'] }
    )>, distributionRate: Maybe<(
      { __typename?: 'EnergoDistributionRate' }
      & Pick<EnergoDistributionRate, 'id' | 'name' | 'lowTariff'>
    )>, breaker: Maybe<(
      { __typename?: 'EnergoBreaker' }
      & Pick<EnergoBreaker, 'id' | 'name'>
    )>, standaloneInvoice: Maybe<(
      { __typename?: 'EnergoStandaloneInvoice' }
      & Pick<EnergoStandaloneInvoice, 'id' | 'uuid'>
    )>, bestResultItem: Maybe<(
      { __typename?: 'EnergoElectricityResultItem' }
      & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoElectricityPartner' }
        & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoElectricityProduct' }
        & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoElectricityResultPriceDetail' }
        & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    )>, bestGasResultItem: Maybe<(
      { __typename?: 'EnergoGasResultItem' }
      & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
      & { partner: (
        { __typename?: 'EnergoGasPartner' }
        & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      ), product: (
        { __typename?: 'EnergoGasProduct' }
        & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
      ), priceExpectedDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ), priceResultDetail: (
        { __typename?: 'EnergoGasResultPriceDetail' }
        & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
      ) }
    )> }
  )>, vehicleInsuranceOrder: Maybe<(
    { __typename?: 'VehicleInsuranceOrder' }
    & Pick<VehicleInsuranceOrder, 'id' | 'uuid' | 'createdAt' | 'status' | 'packagesInsuranceType' | 'selectedInsuranceType' | 'insurableInterest' | 'resultEmail' | 'phone' | 'calculationCurrentStep' | 'orderCurrentStep' | 'updatedAt' | 'hasSpecificPackages' | 'beginDate'>
    & { vehicle: (
      { __typename?: 'InsuredVehicle' }
      & Pick<InsuredVehicle, 'uuid' | 'vehicleType' | 'registrationNumber' | 'vin' | 'kilometersTotal' | 'ownedMoreThanAYear' | 'newVehicle' | 'contractualCarService' | 'vehicleValue' | 'vehicleValueWithVat' | 'firstRegistrationDate' | 'numberOfRegistrationCertificate' | 'vehicleSearchStatus'>
      & { expectedKilometersPerYear: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, manufacturer: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, fuel: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, engineDisplacement: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, maxPower: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, countOfSeats: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, maxWeight: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, modelLine: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, model: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, modelType: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, security: (
        { __typename?: 'VehicleSecurity' }
        & Pick<VehicleSecurity, 'none' | 'mechanical' | 'passiveSearch' | 'activeSearch' | 'glassVin' | 'immobilizer' | 'alarm'>
      ), carUsageMethod: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )>, additionalEquipment: Array<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    ), subjectInsured: (
      { __typename?: 'InsuredSubject' }
      & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
      & { cityPart: Maybe<(
        { __typename?: 'CityPart' }
        & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
      )>, address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, correspondenceAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, functionOfRepresentative: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    ), subjectCarHolder: Maybe<(
      { __typename?: 'InsuredSubject' }
      & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
      & { cityPart: Maybe<(
        { __typename?: 'CityPart' }
        & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
      )>, address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, correspondenceAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, functionOfRepresentative: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    )>, subjectCarOwner: Maybe<(
      { __typename?: 'InsuredSubject' }
      & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
      & { cityPart: Maybe<(
        { __typename?: 'CityPart' }
        & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
      )>, address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, correspondenceAddress: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, functionOfRepresentative: Maybe<(
        { __typename?: 'CodeBook' }
        & Pick<CodeBook, 'id' | 'label'>
      )> }
    )>, selectedOffer: Maybe<(
      { __typename?: 'VehicleInsuranceProductOffer' }
      & Pick<VehicleInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'paymentFrequency' | 'calculatedPaymentFrequency'>
      & { mtplProduct: Maybe<(
        { __typename?: 'VehicleInsuranceProduct' }
        & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, cascoProduct: Maybe<(
        { __typename?: 'VehicleInsuranceProduct' }
        & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, estimatedPrice: (
        { __typename?: 'VehicleInsuranceEstimatedPrice' }
        & Pick<VehicleInsuranceEstimatedPrice, 'yearly' | 'quarterly' | 'halfYearly' | 'monthly'>
      ) }
    )>, contactPerson: Maybe<(
      { __typename?: 'VehicleInsuranceContactPerson' }
      & Pick<VehicleInsuranceContactPerson, 'name' | 'surname' | 'email' | 'phone' | 'agreementProcessPersonalData' | 'agreementTermsAndConditions'>
    )>, contract: Maybe<(
      { __typename?: 'VehicleInsuranceContract' }
      & Pick<VehicleInsuranceContract, 'id' | 'uuid' | 'payed' | 'paymentUrl'>
      & { price: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency'>
      ), annualPrice: (
        { __typename?: 'Money' }
        & Pick<Money, 'amount' | 'currency'>
      ), documents: Array<Maybe<(
        { __typename?: 'VehicleInsuranceContractDocument' }
        & Pick<VehicleInsuranceContractDocument, 'id' | 'name' | 'documentType'>
        & { documentCategory: (
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        ), file: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>>, paymentInfo: Maybe<(
        { __typename?: 'VehicleInsuranceContractPaymentInfo' }
        & Pick<VehicleInsuranceContractPaymentInfo, 'account' | 'bankCode' | 'constantSymbol' | 'specificSymbol' | 'variableSymbol' | 'message' | 'paymentUrl'>
        & { amount: Maybe<(
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'currency'>
        )>, qrCodeFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, casco: Maybe<(
        { __typename?: 'VehicleInsuranceProduct' }
        & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )>, mtpl: Maybe<(
        { __typename?: 'VehicleInsuranceProduct' }
        & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
        & { partner: (
          { __typename?: 'ZeteoInsurancePartner' }
          & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
          & { logo: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, logoSquare: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )>, rating2: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), productDetailFile: Maybe<(
          { __typename?: 'File' }
          & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
        )> }
      )> }
    )>, additionalOptimumRequirements: (
      { __typename?: 'VehicleInsuranceAdditionalRequirementsFromClient' }
      & Pick<VehicleInsuranceAdditionalRequirementsFromClient, 'glass' | 'animalAccident'>
    ) }
  )> }
);

export type WatchWolfEmptyEnergoFragment = (
  { __typename?: 'WatchWolfEmptyEnergo' }
  & Pick<WatchWolfEmptyEnergo, 'watchWolfUuid' | 'hasEnergoElectricityCalculations' | 'hasEnergoGasCalculations'>
);

export type WatchWolfCreateMutationVariables = Exact<{
  data: CreateWatchWolfInput;
}>;


export type WatchWolfCreateMutation = (
  { __typename?: 'Mutation' }
  & { watchWolfCreate: (
    { __typename?: 'WatchWolf' }
    & Pick<WatchWolf, 'uuid' | 'type' | 'phone' | 'preferredEmail' | 'minSaving' | 'commitmentEnd' | 'vertical' | 'subVertical' | 'createdAt' | 'name'>
    & { energoCalculation: Maybe<(
      { __typename?: 'EnergoCalculation' }
      & Pick<EnergoCalculation, 'id' | 'uuid' | 'type' | 'source' | 'yearlyConsumption' | 'yearlyConsumptionUnit' | 'yearlyConsumptionLowTariff' | 'yearlyConsumptionLowTariffUnit' | 'yearlyPayment' | 'gasYearlyPayment' | 'gasYearlyConsumption' | 'gasYearlyConsumptionUnit' | 'gasMonthlyAdvances' | 'gasUnknownMonthlyAdvances' | 'monthlyAdvances' | 'unknownMonthlyAdvances' | 'email' | 'saving' | 'createdAt' | 'updatedAt' | 'lastViewed' | 'currentProductId' | 'currentProductName' | 'currentProductFollowingId' | 'unknownCurrentProduct' | 'gasCurrentProductId' | 'gasCurrentProductName' | 'gasCurrentProductFollowingId' | 'gasUnknownCurrentProduct' | 'phone' | 'individualPriceExpectedMonthly' | 'individualPriceExpectedMWhHighTariff' | 'individualPriceExpectedMWhLowTariff' | 'gasIndividualPriceExpectedMonthly' | 'gasIndividualPriceExpectedMWh' | 'individualOfferName' | 'reason'>
      & { address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, partner: Maybe<(
        { __typename?: 'EnergoElectricityPartner' }
        & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      )>, gasPartner: Maybe<(
        { __typename?: 'EnergoGasPartner' }
        & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      )>, gasUsages: Maybe<(
        { __typename?: 'GasUsages' }
        & Pick<GasUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
      )>, electricityUsages: Maybe<(
        { __typename?: 'ElectricityUsages' }
        & Pick<ElectricityUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
      )>, floorAreaRange: Maybe<(
        { __typename?: 'EnergoFloorAreaRangeCodebook' }
        & Pick<EnergoFloorAreaRangeCodebook, 'id'>
        & { label: EnergoFloorAreaRangeCodebook['name'] }
      )>, isolation: Maybe<(
        { __typename?: 'EnergoIsolationCodebook' }
        & Pick<EnergoIsolationCodebook, 'id'>
        & { label: EnergoIsolationCodebook['name'] }
      )>, personsCountRange: Maybe<(
        { __typename?: 'EnergoPersonsCountRangeCodebook' }
        & Pick<EnergoPersonsCountRangeCodebook, 'id'>
        & { label: EnergoPersonsCountRangeCodebook['name'] }
      )>, distributionRate: Maybe<(
        { __typename?: 'EnergoDistributionRate' }
        & Pick<EnergoDistributionRate, 'id' | 'name' | 'lowTariff'>
      )>, breaker: Maybe<(
        { __typename?: 'EnergoBreaker' }
        & Pick<EnergoBreaker, 'id' | 'name'>
      )>, standaloneInvoice: Maybe<(
        { __typename?: 'EnergoStandaloneInvoice' }
        & Pick<EnergoStandaloneInvoice, 'id' | 'uuid'>
      )>, bestResultItem: Maybe<(
        { __typename?: 'EnergoElectricityResultItem' }
        & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
        & { partner: (
          { __typename?: 'EnergoElectricityPartner' }
          & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
          & { rating: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), product: (
          { __typename?: 'EnergoElectricityProduct' }
          & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
        ), priceExpectedDetail: (
          { __typename?: 'EnergoElectricityResultPriceDetail' }
          & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ), priceResultDetail: (
          { __typename?: 'EnergoElectricityResultPriceDetail' }
          & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ) }
      )>, bestGasResultItem: Maybe<(
        { __typename?: 'EnergoGasResultItem' }
        & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
        & { partner: (
          { __typename?: 'EnergoGasPartner' }
          & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
          & { rating: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), product: (
          { __typename?: 'EnergoGasProduct' }
          & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
        ), priceExpectedDetail: (
          { __typename?: 'EnergoGasResultPriceDetail' }
          & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ), priceResultDetail: (
          { __typename?: 'EnergoGasResultPriceDetail' }
          & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ) }
      )> }
    )>, vehicleInsuranceOrder: Maybe<(
      { __typename?: 'VehicleInsuranceOrder' }
      & Pick<VehicleInsuranceOrder, 'id' | 'uuid' | 'createdAt' | 'status' | 'packagesInsuranceType' | 'selectedInsuranceType' | 'insurableInterest' | 'resultEmail' | 'phone' | 'calculationCurrentStep' | 'orderCurrentStep' | 'updatedAt' | 'hasSpecificPackages' | 'beginDate'>
      & { vehicle: (
        { __typename?: 'InsuredVehicle' }
        & Pick<InsuredVehicle, 'uuid' | 'vehicleType' | 'registrationNumber' | 'vin' | 'kilometersTotal' | 'ownedMoreThanAYear' | 'newVehicle' | 'contractualCarService' | 'vehicleValue' | 'vehicleValueWithVat' | 'firstRegistrationDate' | 'numberOfRegistrationCertificate' | 'vehicleSearchStatus'>
        & { expectedKilometersPerYear: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, manufacturer: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, fuel: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, engineDisplacement: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, maxPower: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, countOfSeats: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, maxWeight: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, modelLine: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, model: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, modelType: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, security: (
          { __typename?: 'VehicleSecurity' }
          & Pick<VehicleSecurity, 'none' | 'mechanical' | 'passiveSearch' | 'activeSearch' | 'glassVin' | 'immobilizer' | 'alarm'>
        ), carUsageMethod: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, additionalEquipment: Array<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )> }
      ), subjectInsured: (
        { __typename?: 'InsuredSubject' }
        & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
        & { cityPart: Maybe<(
          { __typename?: 'CityPart' }
          & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
        )>, address: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, correspondenceAddress: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, functionOfRepresentative: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )> }
      ), subjectCarHolder: Maybe<(
        { __typename?: 'InsuredSubject' }
        & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
        & { cityPart: Maybe<(
          { __typename?: 'CityPart' }
          & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
        )>, address: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, correspondenceAddress: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, functionOfRepresentative: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )> }
      )>, subjectCarOwner: Maybe<(
        { __typename?: 'InsuredSubject' }
        & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
        & { cityPart: Maybe<(
          { __typename?: 'CityPart' }
          & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
        )>, address: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, correspondenceAddress: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, functionOfRepresentative: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )> }
      )>, selectedOffer: Maybe<(
        { __typename?: 'VehicleInsuranceProductOffer' }
        & Pick<VehicleInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'paymentFrequency' | 'calculatedPaymentFrequency'>
        & { mtplProduct: Maybe<(
          { __typename?: 'VehicleInsuranceProduct' }
          & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, cascoProduct: Maybe<(
          { __typename?: 'VehicleInsuranceProduct' }
          & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, estimatedPrice: (
          { __typename?: 'VehicleInsuranceEstimatedPrice' }
          & Pick<VehicleInsuranceEstimatedPrice, 'yearly' | 'quarterly' | 'halfYearly' | 'monthly'>
        ) }
      )>, contactPerson: Maybe<(
        { __typename?: 'VehicleInsuranceContactPerson' }
        & Pick<VehicleInsuranceContactPerson, 'name' | 'surname' | 'email' | 'phone' | 'agreementProcessPersonalData' | 'agreementTermsAndConditions'>
      )>, contract: Maybe<(
        { __typename?: 'VehicleInsuranceContract' }
        & Pick<VehicleInsuranceContract, 'id' | 'uuid' | 'payed' | 'paymentUrl'>
        & { price: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'currency'>
        ), annualPrice: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'currency'>
        ), documents: Array<Maybe<(
          { __typename?: 'VehicleInsuranceContractDocument' }
          & Pick<VehicleInsuranceContractDocument, 'id' | 'name' | 'documentType'>
          & { documentCategory: (
            { __typename?: 'CodeBook' }
            & Pick<CodeBook, 'id' | 'label'>
          ), file: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>>, paymentInfo: Maybe<(
          { __typename?: 'VehicleInsuranceContractPaymentInfo' }
          & Pick<VehicleInsuranceContractPaymentInfo, 'account' | 'bankCode' | 'constantSymbol' | 'specificSymbol' | 'variableSymbol' | 'message' | 'paymentUrl'>
          & { amount: Maybe<(
            { __typename?: 'Money' }
            & Pick<Money, 'amount' | 'currency'>
          )>, qrCodeFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, casco: Maybe<(
          { __typename?: 'VehicleInsuranceProduct' }
          & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, mtpl: Maybe<(
          { __typename?: 'VehicleInsuranceProduct' }
          & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )> }
      )>, additionalOptimumRequirements: (
        { __typename?: 'VehicleInsuranceAdditionalRequirementsFromClient' }
        & Pick<VehicleInsuranceAdditionalRequirementsFromClient, 'glass' | 'animalAccident'>
      ) }
    )> }
  ) }
);

export type WatchWolfUpdateMutationVariables = Exact<{
  watchWolfUuid: Scalars['UUID']['input'];
  data: WatchWolfUpdateInput;
}>;


export type WatchWolfUpdateMutation = (
  { __typename?: 'Mutation' }
  & { watchWolfUpdate: (
    { __typename?: 'WatchWolf' }
    & Pick<WatchWolf, 'uuid' | 'type' | 'phone' | 'preferredEmail' | 'minSaving' | 'commitmentEnd' | 'vertical' | 'subVertical' | 'createdAt' | 'name'>
    & { energoCalculation: Maybe<(
      { __typename?: 'EnergoCalculation' }
      & Pick<EnergoCalculation, 'id' | 'uuid' | 'type' | 'source' | 'yearlyConsumption' | 'yearlyConsumptionUnit' | 'yearlyConsumptionLowTariff' | 'yearlyConsumptionLowTariffUnit' | 'yearlyPayment' | 'gasYearlyPayment' | 'gasYearlyConsumption' | 'gasYearlyConsumptionUnit' | 'gasMonthlyAdvances' | 'gasUnknownMonthlyAdvances' | 'monthlyAdvances' | 'unknownMonthlyAdvances' | 'email' | 'saving' | 'createdAt' | 'updatedAt' | 'lastViewed' | 'currentProductId' | 'currentProductName' | 'currentProductFollowingId' | 'unknownCurrentProduct' | 'gasCurrentProductId' | 'gasCurrentProductName' | 'gasCurrentProductFollowingId' | 'gasUnknownCurrentProduct' | 'phone' | 'individualPriceExpectedMonthly' | 'individualPriceExpectedMWhHighTariff' | 'individualPriceExpectedMWhLowTariff' | 'gasIndividualPriceExpectedMonthly' | 'gasIndividualPriceExpectedMWh' | 'individualOfferName' | 'reason'>
      & { address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, partner: Maybe<(
        { __typename?: 'EnergoElectricityPartner' }
        & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      )>, gasPartner: Maybe<(
        { __typename?: 'EnergoGasPartner' }
        & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      )>, gasUsages: Maybe<(
        { __typename?: 'GasUsages' }
        & Pick<GasUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
      )>, electricityUsages: Maybe<(
        { __typename?: 'ElectricityUsages' }
        & Pick<ElectricityUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
      )>, floorAreaRange: Maybe<(
        { __typename?: 'EnergoFloorAreaRangeCodebook' }
        & Pick<EnergoFloorAreaRangeCodebook, 'id'>
        & { label: EnergoFloorAreaRangeCodebook['name'] }
      )>, isolation: Maybe<(
        { __typename?: 'EnergoIsolationCodebook' }
        & Pick<EnergoIsolationCodebook, 'id'>
        & { label: EnergoIsolationCodebook['name'] }
      )>, personsCountRange: Maybe<(
        { __typename?: 'EnergoPersonsCountRangeCodebook' }
        & Pick<EnergoPersonsCountRangeCodebook, 'id'>
        & { label: EnergoPersonsCountRangeCodebook['name'] }
      )>, distributionRate: Maybe<(
        { __typename?: 'EnergoDistributionRate' }
        & Pick<EnergoDistributionRate, 'id' | 'name' | 'lowTariff'>
      )>, breaker: Maybe<(
        { __typename?: 'EnergoBreaker' }
        & Pick<EnergoBreaker, 'id' | 'name'>
      )>, standaloneInvoice: Maybe<(
        { __typename?: 'EnergoStandaloneInvoice' }
        & Pick<EnergoStandaloneInvoice, 'id' | 'uuid'>
      )>, bestResultItem: Maybe<(
        { __typename?: 'EnergoElectricityResultItem' }
        & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
        & { partner: (
          { __typename?: 'EnergoElectricityPartner' }
          & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
          & { rating: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), product: (
          { __typename?: 'EnergoElectricityProduct' }
          & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
        ), priceExpectedDetail: (
          { __typename?: 'EnergoElectricityResultPriceDetail' }
          & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ), priceResultDetail: (
          { __typename?: 'EnergoElectricityResultPriceDetail' }
          & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ) }
      )>, bestGasResultItem: Maybe<(
        { __typename?: 'EnergoGasResultItem' }
        & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
        & { partner: (
          { __typename?: 'EnergoGasPartner' }
          & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
          & { rating: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), product: (
          { __typename?: 'EnergoGasProduct' }
          & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
        ), priceExpectedDetail: (
          { __typename?: 'EnergoGasResultPriceDetail' }
          & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ), priceResultDetail: (
          { __typename?: 'EnergoGasResultPriceDetail' }
          & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ) }
      )> }
    )>, vehicleInsuranceOrder: Maybe<(
      { __typename?: 'VehicleInsuranceOrder' }
      & Pick<VehicleInsuranceOrder, 'id' | 'uuid' | 'createdAt' | 'status' | 'packagesInsuranceType' | 'selectedInsuranceType' | 'insurableInterest' | 'resultEmail' | 'phone' | 'calculationCurrentStep' | 'orderCurrentStep' | 'updatedAt' | 'hasSpecificPackages' | 'beginDate'>
      & { vehicle: (
        { __typename?: 'InsuredVehicle' }
        & Pick<InsuredVehicle, 'uuid' | 'vehicleType' | 'registrationNumber' | 'vin' | 'kilometersTotal' | 'ownedMoreThanAYear' | 'newVehicle' | 'contractualCarService' | 'vehicleValue' | 'vehicleValueWithVat' | 'firstRegistrationDate' | 'numberOfRegistrationCertificate' | 'vehicleSearchStatus'>
        & { expectedKilometersPerYear: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, manufacturer: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, fuel: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, engineDisplacement: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, maxPower: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, countOfSeats: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, maxWeight: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, modelLine: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, model: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, modelType: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, security: (
          { __typename?: 'VehicleSecurity' }
          & Pick<VehicleSecurity, 'none' | 'mechanical' | 'passiveSearch' | 'activeSearch' | 'glassVin' | 'immobilizer' | 'alarm'>
        ), carUsageMethod: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, additionalEquipment: Array<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )> }
      ), subjectInsured: (
        { __typename?: 'InsuredSubject' }
        & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
        & { cityPart: Maybe<(
          { __typename?: 'CityPart' }
          & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
        )>, address: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, correspondenceAddress: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, functionOfRepresentative: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )> }
      ), subjectCarHolder: Maybe<(
        { __typename?: 'InsuredSubject' }
        & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
        & { cityPart: Maybe<(
          { __typename?: 'CityPart' }
          & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
        )>, address: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, correspondenceAddress: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, functionOfRepresentative: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )> }
      )>, subjectCarOwner: Maybe<(
        { __typename?: 'InsuredSubject' }
        & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
        & { cityPart: Maybe<(
          { __typename?: 'CityPart' }
          & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
        )>, address: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, correspondenceAddress: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, functionOfRepresentative: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )> }
      )>, selectedOffer: Maybe<(
        { __typename?: 'VehicleInsuranceProductOffer' }
        & Pick<VehicleInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'paymentFrequency' | 'calculatedPaymentFrequency'>
        & { mtplProduct: Maybe<(
          { __typename?: 'VehicleInsuranceProduct' }
          & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, cascoProduct: Maybe<(
          { __typename?: 'VehicleInsuranceProduct' }
          & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, estimatedPrice: (
          { __typename?: 'VehicleInsuranceEstimatedPrice' }
          & Pick<VehicleInsuranceEstimatedPrice, 'yearly' | 'quarterly' | 'halfYearly' | 'monthly'>
        ) }
      )>, contactPerson: Maybe<(
        { __typename?: 'VehicleInsuranceContactPerson' }
        & Pick<VehicleInsuranceContactPerson, 'name' | 'surname' | 'email' | 'phone' | 'agreementProcessPersonalData' | 'agreementTermsAndConditions'>
      )>, contract: Maybe<(
        { __typename?: 'VehicleInsuranceContract' }
        & Pick<VehicleInsuranceContract, 'id' | 'uuid' | 'payed' | 'paymentUrl'>
        & { price: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'currency'>
        ), annualPrice: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'currency'>
        ), documents: Array<Maybe<(
          { __typename?: 'VehicleInsuranceContractDocument' }
          & Pick<VehicleInsuranceContractDocument, 'id' | 'name' | 'documentType'>
          & { documentCategory: (
            { __typename?: 'CodeBook' }
            & Pick<CodeBook, 'id' | 'label'>
          ), file: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>>, paymentInfo: Maybe<(
          { __typename?: 'VehicleInsuranceContractPaymentInfo' }
          & Pick<VehicleInsuranceContractPaymentInfo, 'account' | 'bankCode' | 'constantSymbol' | 'specificSymbol' | 'variableSymbol' | 'message' | 'paymentUrl'>
          & { amount: Maybe<(
            { __typename?: 'Money' }
            & Pick<Money, 'amount' | 'currency'>
          )>, qrCodeFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, casco: Maybe<(
          { __typename?: 'VehicleInsuranceProduct' }
          & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, mtpl: Maybe<(
          { __typename?: 'VehicleInsuranceProduct' }
          & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )> }
      )>, additionalOptimumRequirements: (
        { __typename?: 'VehicleInsuranceAdditionalRequirementsFromClient' }
        & Pick<VehicleInsuranceAdditionalRequirementsFromClient, 'glass' | 'animalAccident'>
      ) }
    )> }
  ) }
);

export type WatchWolfDeleteMutationVariables = Exact<{
  watchWolfUuid: Scalars['UUID']['input'];
}>;


export type WatchWolfDeleteMutation = (
  { __typename?: 'Mutation' }
  & { watchWolfDelete: (
    { __typename?: 'WatchWolf' }
    & Pick<WatchWolf, 'uuid' | 'type' | 'phone' | 'preferredEmail' | 'minSaving' | 'commitmentEnd' | 'vertical' | 'subVertical' | 'createdAt' | 'name'>
    & { energoCalculation: Maybe<(
      { __typename?: 'EnergoCalculation' }
      & Pick<EnergoCalculation, 'id' | 'uuid' | 'type' | 'source' | 'yearlyConsumption' | 'yearlyConsumptionUnit' | 'yearlyConsumptionLowTariff' | 'yearlyConsumptionLowTariffUnit' | 'yearlyPayment' | 'gasYearlyPayment' | 'gasYearlyConsumption' | 'gasYearlyConsumptionUnit' | 'gasMonthlyAdvances' | 'gasUnknownMonthlyAdvances' | 'monthlyAdvances' | 'unknownMonthlyAdvances' | 'email' | 'saving' | 'createdAt' | 'updatedAt' | 'lastViewed' | 'currentProductId' | 'currentProductName' | 'currentProductFollowingId' | 'unknownCurrentProduct' | 'gasCurrentProductId' | 'gasCurrentProductName' | 'gasCurrentProductFollowingId' | 'gasUnknownCurrentProduct' | 'phone' | 'individualPriceExpectedMonthly' | 'individualPriceExpectedMWhHighTariff' | 'individualPriceExpectedMWhLowTariff' | 'gasIndividualPriceExpectedMonthly' | 'gasIndividualPriceExpectedMWh' | 'individualOfferName' | 'reason'>
      & { address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, partner: Maybe<(
        { __typename?: 'EnergoElectricityPartner' }
        & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      )>, gasPartner: Maybe<(
        { __typename?: 'EnergoGasPartner' }
        & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      )>, gasUsages: Maybe<(
        { __typename?: 'GasUsages' }
        & Pick<GasUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
      )>, electricityUsages: Maybe<(
        { __typename?: 'ElectricityUsages' }
        & Pick<ElectricityUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
      )>, floorAreaRange: Maybe<(
        { __typename?: 'EnergoFloorAreaRangeCodebook' }
        & Pick<EnergoFloorAreaRangeCodebook, 'id'>
        & { label: EnergoFloorAreaRangeCodebook['name'] }
      )>, isolation: Maybe<(
        { __typename?: 'EnergoIsolationCodebook' }
        & Pick<EnergoIsolationCodebook, 'id'>
        & { label: EnergoIsolationCodebook['name'] }
      )>, personsCountRange: Maybe<(
        { __typename?: 'EnergoPersonsCountRangeCodebook' }
        & Pick<EnergoPersonsCountRangeCodebook, 'id'>
        & { label: EnergoPersonsCountRangeCodebook['name'] }
      )>, distributionRate: Maybe<(
        { __typename?: 'EnergoDistributionRate' }
        & Pick<EnergoDistributionRate, 'id' | 'name' | 'lowTariff'>
      )>, breaker: Maybe<(
        { __typename?: 'EnergoBreaker' }
        & Pick<EnergoBreaker, 'id' | 'name'>
      )>, standaloneInvoice: Maybe<(
        { __typename?: 'EnergoStandaloneInvoice' }
        & Pick<EnergoStandaloneInvoice, 'id' | 'uuid'>
      )>, bestResultItem: Maybe<(
        { __typename?: 'EnergoElectricityResultItem' }
        & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
        & { partner: (
          { __typename?: 'EnergoElectricityPartner' }
          & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
          & { rating: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), product: (
          { __typename?: 'EnergoElectricityProduct' }
          & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
        ), priceExpectedDetail: (
          { __typename?: 'EnergoElectricityResultPriceDetail' }
          & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ), priceResultDetail: (
          { __typename?: 'EnergoElectricityResultPriceDetail' }
          & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ) }
      )>, bestGasResultItem: Maybe<(
        { __typename?: 'EnergoGasResultItem' }
        & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
        & { partner: (
          { __typename?: 'EnergoGasPartner' }
          & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
          & { rating: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), product: (
          { __typename?: 'EnergoGasProduct' }
          & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
        ), priceExpectedDetail: (
          { __typename?: 'EnergoGasResultPriceDetail' }
          & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ), priceResultDetail: (
          { __typename?: 'EnergoGasResultPriceDetail' }
          & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ) }
      )> }
    )>, vehicleInsuranceOrder: Maybe<(
      { __typename?: 'VehicleInsuranceOrder' }
      & Pick<VehicleInsuranceOrder, 'id' | 'uuid' | 'createdAt' | 'status' | 'packagesInsuranceType' | 'selectedInsuranceType' | 'insurableInterest' | 'resultEmail' | 'phone' | 'calculationCurrentStep' | 'orderCurrentStep' | 'updatedAt' | 'hasSpecificPackages' | 'beginDate'>
      & { vehicle: (
        { __typename?: 'InsuredVehicle' }
        & Pick<InsuredVehicle, 'uuid' | 'vehicleType' | 'registrationNumber' | 'vin' | 'kilometersTotal' | 'ownedMoreThanAYear' | 'newVehicle' | 'contractualCarService' | 'vehicleValue' | 'vehicleValueWithVat' | 'firstRegistrationDate' | 'numberOfRegistrationCertificate' | 'vehicleSearchStatus'>
        & { expectedKilometersPerYear: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, manufacturer: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, fuel: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, engineDisplacement: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, maxPower: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, countOfSeats: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, maxWeight: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, modelLine: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, model: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, modelType: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, security: (
          { __typename?: 'VehicleSecurity' }
          & Pick<VehicleSecurity, 'none' | 'mechanical' | 'passiveSearch' | 'activeSearch' | 'glassVin' | 'immobilizer' | 'alarm'>
        ), carUsageMethod: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, additionalEquipment: Array<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )> }
      ), subjectInsured: (
        { __typename?: 'InsuredSubject' }
        & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
        & { cityPart: Maybe<(
          { __typename?: 'CityPart' }
          & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
        )>, address: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, correspondenceAddress: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, functionOfRepresentative: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )> }
      ), subjectCarHolder: Maybe<(
        { __typename?: 'InsuredSubject' }
        & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
        & { cityPart: Maybe<(
          { __typename?: 'CityPart' }
          & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
        )>, address: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, correspondenceAddress: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, functionOfRepresentative: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )> }
      )>, subjectCarOwner: Maybe<(
        { __typename?: 'InsuredSubject' }
        & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
        & { cityPart: Maybe<(
          { __typename?: 'CityPart' }
          & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
        )>, address: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, correspondenceAddress: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, functionOfRepresentative: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )> }
      )>, selectedOffer: Maybe<(
        { __typename?: 'VehicleInsuranceProductOffer' }
        & Pick<VehicleInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'paymentFrequency' | 'calculatedPaymentFrequency'>
        & { mtplProduct: Maybe<(
          { __typename?: 'VehicleInsuranceProduct' }
          & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, cascoProduct: Maybe<(
          { __typename?: 'VehicleInsuranceProduct' }
          & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, estimatedPrice: (
          { __typename?: 'VehicleInsuranceEstimatedPrice' }
          & Pick<VehicleInsuranceEstimatedPrice, 'yearly' | 'quarterly' | 'halfYearly' | 'monthly'>
        ) }
      )>, contactPerson: Maybe<(
        { __typename?: 'VehicleInsuranceContactPerson' }
        & Pick<VehicleInsuranceContactPerson, 'name' | 'surname' | 'email' | 'phone' | 'agreementProcessPersonalData' | 'agreementTermsAndConditions'>
      )>, contract: Maybe<(
        { __typename?: 'VehicleInsuranceContract' }
        & Pick<VehicleInsuranceContract, 'id' | 'uuid' | 'payed' | 'paymentUrl'>
        & { price: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'currency'>
        ), annualPrice: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'currency'>
        ), documents: Array<Maybe<(
          { __typename?: 'VehicleInsuranceContractDocument' }
          & Pick<VehicleInsuranceContractDocument, 'id' | 'name' | 'documentType'>
          & { documentCategory: (
            { __typename?: 'CodeBook' }
            & Pick<CodeBook, 'id' | 'label'>
          ), file: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>>, paymentInfo: Maybe<(
          { __typename?: 'VehicleInsuranceContractPaymentInfo' }
          & Pick<VehicleInsuranceContractPaymentInfo, 'account' | 'bankCode' | 'constantSymbol' | 'specificSymbol' | 'variableSymbol' | 'message' | 'paymentUrl'>
          & { amount: Maybe<(
            { __typename?: 'Money' }
            & Pick<Money, 'amount' | 'currency'>
          )>, qrCodeFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, casco: Maybe<(
          { __typename?: 'VehicleInsuranceProduct' }
          & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, mtpl: Maybe<(
          { __typename?: 'VehicleInsuranceProduct' }
          & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )> }
      )>, additionalOptimumRequirements: (
        { __typename?: 'VehicleInsuranceAdditionalRequirementsFromClient' }
        & Pick<VehicleInsuranceAdditionalRequirementsFromClient, 'glass' | 'animalAccident'>
      ) }
    )> }
  ) }
);

export type WatchWolfActivateMutationVariables = Exact<{
  watchWolfUuid: Scalars['UUID']['input'];
}>;


export type WatchWolfActivateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'watchWolfActivate'>
);

export type WatchWolfCreateCalculationMutationVariables = Exact<{
  data: WatchWolfCreateCalculationInput;
}>;


export type WatchWolfCreateCalculationMutation = (
  { __typename?: 'Mutation' }
  & { watchWolfCreateCalculation: (
    { __typename?: 'WatchWolf' }
    & Pick<WatchWolf, 'uuid' | 'type' | 'phone' | 'preferredEmail' | 'minSaving' | 'commitmentEnd' | 'vertical' | 'subVertical' | 'createdAt' | 'name'>
    & { energoCalculation: Maybe<(
      { __typename?: 'EnergoCalculation' }
      & Pick<EnergoCalculation, 'id' | 'uuid' | 'type' | 'source' | 'yearlyConsumption' | 'yearlyConsumptionUnit' | 'yearlyConsumptionLowTariff' | 'yearlyConsumptionLowTariffUnit' | 'yearlyPayment' | 'gasYearlyPayment' | 'gasYearlyConsumption' | 'gasYearlyConsumptionUnit' | 'gasMonthlyAdvances' | 'gasUnknownMonthlyAdvances' | 'monthlyAdvances' | 'unknownMonthlyAdvances' | 'email' | 'saving' | 'createdAt' | 'updatedAt' | 'lastViewed' | 'currentProductId' | 'currentProductName' | 'currentProductFollowingId' | 'unknownCurrentProduct' | 'gasCurrentProductId' | 'gasCurrentProductName' | 'gasCurrentProductFollowingId' | 'gasUnknownCurrentProduct' | 'phone' | 'individualPriceExpectedMonthly' | 'individualPriceExpectedMWhHighTariff' | 'individualPriceExpectedMWhLowTariff' | 'gasIndividualPriceExpectedMonthly' | 'gasIndividualPriceExpectedMWh' | 'individualOfferName' | 'reason'>
      & { address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, partner: Maybe<(
        { __typename?: 'EnergoElectricityPartner' }
        & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      )>, gasPartner: Maybe<(
        { __typename?: 'EnergoGasPartner' }
        & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      )>, gasUsages: Maybe<(
        { __typename?: 'GasUsages' }
        & Pick<GasUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
      )>, electricityUsages: Maybe<(
        { __typename?: 'ElectricityUsages' }
        & Pick<ElectricityUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
      )>, floorAreaRange: Maybe<(
        { __typename?: 'EnergoFloorAreaRangeCodebook' }
        & Pick<EnergoFloorAreaRangeCodebook, 'id'>
        & { label: EnergoFloorAreaRangeCodebook['name'] }
      )>, isolation: Maybe<(
        { __typename?: 'EnergoIsolationCodebook' }
        & Pick<EnergoIsolationCodebook, 'id'>
        & { label: EnergoIsolationCodebook['name'] }
      )>, personsCountRange: Maybe<(
        { __typename?: 'EnergoPersonsCountRangeCodebook' }
        & Pick<EnergoPersonsCountRangeCodebook, 'id'>
        & { label: EnergoPersonsCountRangeCodebook['name'] }
      )>, distributionRate: Maybe<(
        { __typename?: 'EnergoDistributionRate' }
        & Pick<EnergoDistributionRate, 'id' | 'name' | 'lowTariff'>
      )>, breaker: Maybe<(
        { __typename?: 'EnergoBreaker' }
        & Pick<EnergoBreaker, 'id' | 'name'>
      )>, standaloneInvoice: Maybe<(
        { __typename?: 'EnergoStandaloneInvoice' }
        & Pick<EnergoStandaloneInvoice, 'id' | 'uuid'>
      )>, bestResultItem: Maybe<(
        { __typename?: 'EnergoElectricityResultItem' }
        & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
        & { partner: (
          { __typename?: 'EnergoElectricityPartner' }
          & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
          & { rating: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), product: (
          { __typename?: 'EnergoElectricityProduct' }
          & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
        ), priceExpectedDetail: (
          { __typename?: 'EnergoElectricityResultPriceDetail' }
          & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ), priceResultDetail: (
          { __typename?: 'EnergoElectricityResultPriceDetail' }
          & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ) }
      )>, bestGasResultItem: Maybe<(
        { __typename?: 'EnergoGasResultItem' }
        & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
        & { partner: (
          { __typename?: 'EnergoGasPartner' }
          & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
          & { rating: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), product: (
          { __typename?: 'EnergoGasProduct' }
          & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
        ), priceExpectedDetail: (
          { __typename?: 'EnergoGasResultPriceDetail' }
          & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ), priceResultDetail: (
          { __typename?: 'EnergoGasResultPriceDetail' }
          & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ) }
      )> }
    )>, vehicleInsuranceOrder: Maybe<(
      { __typename?: 'VehicleInsuranceOrder' }
      & Pick<VehicleInsuranceOrder, 'id' | 'uuid' | 'createdAt' | 'status' | 'packagesInsuranceType' | 'selectedInsuranceType' | 'insurableInterest' | 'resultEmail' | 'phone' | 'calculationCurrentStep' | 'orderCurrentStep' | 'updatedAt' | 'hasSpecificPackages' | 'beginDate'>
      & { vehicle: (
        { __typename?: 'InsuredVehicle' }
        & Pick<InsuredVehicle, 'uuid' | 'vehicleType' | 'registrationNumber' | 'vin' | 'kilometersTotal' | 'ownedMoreThanAYear' | 'newVehicle' | 'contractualCarService' | 'vehicleValue' | 'vehicleValueWithVat' | 'firstRegistrationDate' | 'numberOfRegistrationCertificate' | 'vehicleSearchStatus'>
        & { expectedKilometersPerYear: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, manufacturer: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, fuel: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, engineDisplacement: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, maxPower: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, countOfSeats: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, maxWeight: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, modelLine: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, model: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, modelType: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, security: (
          { __typename?: 'VehicleSecurity' }
          & Pick<VehicleSecurity, 'none' | 'mechanical' | 'passiveSearch' | 'activeSearch' | 'glassVin' | 'immobilizer' | 'alarm'>
        ), carUsageMethod: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, additionalEquipment: Array<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )> }
      ), subjectInsured: (
        { __typename?: 'InsuredSubject' }
        & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
        & { cityPart: Maybe<(
          { __typename?: 'CityPart' }
          & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
        )>, address: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, correspondenceAddress: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, functionOfRepresentative: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )> }
      ), subjectCarHolder: Maybe<(
        { __typename?: 'InsuredSubject' }
        & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
        & { cityPart: Maybe<(
          { __typename?: 'CityPart' }
          & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
        )>, address: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, correspondenceAddress: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, functionOfRepresentative: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )> }
      )>, subjectCarOwner: Maybe<(
        { __typename?: 'InsuredSubject' }
        & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
        & { cityPart: Maybe<(
          { __typename?: 'CityPart' }
          & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
        )>, address: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, correspondenceAddress: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, functionOfRepresentative: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )> }
      )>, selectedOffer: Maybe<(
        { __typename?: 'VehicleInsuranceProductOffer' }
        & Pick<VehicleInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'paymentFrequency' | 'calculatedPaymentFrequency'>
        & { mtplProduct: Maybe<(
          { __typename?: 'VehicleInsuranceProduct' }
          & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, cascoProduct: Maybe<(
          { __typename?: 'VehicleInsuranceProduct' }
          & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, estimatedPrice: (
          { __typename?: 'VehicleInsuranceEstimatedPrice' }
          & Pick<VehicleInsuranceEstimatedPrice, 'yearly' | 'quarterly' | 'halfYearly' | 'monthly'>
        ) }
      )>, contactPerson: Maybe<(
        { __typename?: 'VehicleInsuranceContactPerson' }
        & Pick<VehicleInsuranceContactPerson, 'name' | 'surname' | 'email' | 'phone' | 'agreementProcessPersonalData' | 'agreementTermsAndConditions'>
      )>, contract: Maybe<(
        { __typename?: 'VehicleInsuranceContract' }
        & Pick<VehicleInsuranceContract, 'id' | 'uuid' | 'payed' | 'paymentUrl'>
        & { price: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'currency'>
        ), annualPrice: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'currency'>
        ), documents: Array<Maybe<(
          { __typename?: 'VehicleInsuranceContractDocument' }
          & Pick<VehicleInsuranceContractDocument, 'id' | 'name' | 'documentType'>
          & { documentCategory: (
            { __typename?: 'CodeBook' }
            & Pick<CodeBook, 'id' | 'label'>
          ), file: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>>, paymentInfo: Maybe<(
          { __typename?: 'VehicleInsuranceContractPaymentInfo' }
          & Pick<VehicleInsuranceContractPaymentInfo, 'account' | 'bankCode' | 'constantSymbol' | 'specificSymbol' | 'variableSymbol' | 'message' | 'paymentUrl'>
          & { amount: Maybe<(
            { __typename?: 'Money' }
            & Pick<Money, 'amount' | 'currency'>
          )>, qrCodeFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, casco: Maybe<(
          { __typename?: 'VehicleInsuranceProduct' }
          & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, mtpl: Maybe<(
          { __typename?: 'VehicleInsuranceProduct' }
          & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )> }
      )>, additionalOptimumRequirements: (
        { __typename?: 'VehicleInsuranceAdditionalRequirementsFromClient' }
        & Pick<VehicleInsuranceAdditionalRequirementsFromClient, 'glass' | 'animalAccident'>
      ) }
    )> }
  ) }
);

export type GetWatchWolfByEnergoCalculationUuidQueryVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;


export type GetWatchWolfByEnergoCalculationUuidQuery = (
  { __typename?: 'Query' }
  & { getWatchWolfByEnergoCalculationUuid: (
    { __typename?: 'WatchWolf' }
    & Pick<WatchWolf, 'uuid' | 'type' | 'phone' | 'preferredEmail' | 'minSaving' | 'commitmentEnd' | 'vertical' | 'subVertical' | 'createdAt' | 'name'>
    & { energoCalculation: Maybe<(
      { __typename?: 'EnergoCalculation' }
      & Pick<EnergoCalculation, 'id' | 'uuid' | 'type' | 'source' | 'yearlyConsumption' | 'yearlyConsumptionUnit' | 'yearlyConsumptionLowTariff' | 'yearlyConsumptionLowTariffUnit' | 'yearlyPayment' | 'gasYearlyPayment' | 'gasYearlyConsumption' | 'gasYearlyConsumptionUnit' | 'gasMonthlyAdvances' | 'gasUnknownMonthlyAdvances' | 'monthlyAdvances' | 'unknownMonthlyAdvances' | 'email' | 'saving' | 'createdAt' | 'updatedAt' | 'lastViewed' | 'currentProductId' | 'currentProductName' | 'currentProductFollowingId' | 'unknownCurrentProduct' | 'gasCurrentProductId' | 'gasCurrentProductName' | 'gasCurrentProductFollowingId' | 'gasUnknownCurrentProduct' | 'phone' | 'individualPriceExpectedMonthly' | 'individualPriceExpectedMWhHighTariff' | 'individualPriceExpectedMWhLowTariff' | 'gasIndividualPriceExpectedMonthly' | 'gasIndividualPriceExpectedMWh' | 'individualOfferName' | 'reason'>
      & { address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, partner: Maybe<(
        { __typename?: 'EnergoElectricityPartner' }
        & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      )>, gasPartner: Maybe<(
        { __typename?: 'EnergoGasPartner' }
        & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      )>, gasUsages: Maybe<(
        { __typename?: 'GasUsages' }
        & Pick<GasUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
      )>, electricityUsages: Maybe<(
        { __typename?: 'ElectricityUsages' }
        & Pick<ElectricityUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
      )>, floorAreaRange: Maybe<(
        { __typename?: 'EnergoFloorAreaRangeCodebook' }
        & Pick<EnergoFloorAreaRangeCodebook, 'id'>
        & { label: EnergoFloorAreaRangeCodebook['name'] }
      )>, isolation: Maybe<(
        { __typename?: 'EnergoIsolationCodebook' }
        & Pick<EnergoIsolationCodebook, 'id'>
        & { label: EnergoIsolationCodebook['name'] }
      )>, personsCountRange: Maybe<(
        { __typename?: 'EnergoPersonsCountRangeCodebook' }
        & Pick<EnergoPersonsCountRangeCodebook, 'id'>
        & { label: EnergoPersonsCountRangeCodebook['name'] }
      )>, distributionRate: Maybe<(
        { __typename?: 'EnergoDistributionRate' }
        & Pick<EnergoDistributionRate, 'id' | 'name' | 'lowTariff'>
      )>, breaker: Maybe<(
        { __typename?: 'EnergoBreaker' }
        & Pick<EnergoBreaker, 'id' | 'name'>
      )>, standaloneInvoice: Maybe<(
        { __typename?: 'EnergoStandaloneInvoice' }
        & Pick<EnergoStandaloneInvoice, 'id' | 'uuid'>
      )>, bestResultItem: Maybe<(
        { __typename?: 'EnergoElectricityResultItem' }
        & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
        & { partner: (
          { __typename?: 'EnergoElectricityPartner' }
          & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
          & { rating: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), product: (
          { __typename?: 'EnergoElectricityProduct' }
          & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
        ), priceExpectedDetail: (
          { __typename?: 'EnergoElectricityResultPriceDetail' }
          & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ), priceResultDetail: (
          { __typename?: 'EnergoElectricityResultPriceDetail' }
          & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ) }
      )>, bestGasResultItem: Maybe<(
        { __typename?: 'EnergoGasResultItem' }
        & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
        & { partner: (
          { __typename?: 'EnergoGasPartner' }
          & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
          & { rating: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), product: (
          { __typename?: 'EnergoGasProduct' }
          & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
        ), priceExpectedDetail: (
          { __typename?: 'EnergoGasResultPriceDetail' }
          & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ), priceResultDetail: (
          { __typename?: 'EnergoGasResultPriceDetail' }
          & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ) }
      )> }
    )>, vehicleInsuranceOrder: Maybe<(
      { __typename?: 'VehicleInsuranceOrder' }
      & Pick<VehicleInsuranceOrder, 'id' | 'uuid' | 'createdAt' | 'status' | 'packagesInsuranceType' | 'selectedInsuranceType' | 'insurableInterest' | 'resultEmail' | 'phone' | 'calculationCurrentStep' | 'orderCurrentStep' | 'updatedAt' | 'hasSpecificPackages' | 'beginDate'>
      & { vehicle: (
        { __typename?: 'InsuredVehicle' }
        & Pick<InsuredVehicle, 'uuid' | 'vehicleType' | 'registrationNumber' | 'vin' | 'kilometersTotal' | 'ownedMoreThanAYear' | 'newVehicle' | 'contractualCarService' | 'vehicleValue' | 'vehicleValueWithVat' | 'firstRegistrationDate' | 'numberOfRegistrationCertificate' | 'vehicleSearchStatus'>
        & { expectedKilometersPerYear: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, manufacturer: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, fuel: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, engineDisplacement: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, maxPower: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, countOfSeats: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, maxWeight: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, modelLine: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, model: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, modelType: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, security: (
          { __typename?: 'VehicleSecurity' }
          & Pick<VehicleSecurity, 'none' | 'mechanical' | 'passiveSearch' | 'activeSearch' | 'glassVin' | 'immobilizer' | 'alarm'>
        ), carUsageMethod: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, additionalEquipment: Array<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )> }
      ), subjectInsured: (
        { __typename?: 'InsuredSubject' }
        & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
        & { cityPart: Maybe<(
          { __typename?: 'CityPart' }
          & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
        )>, address: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, correspondenceAddress: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, functionOfRepresentative: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )> }
      ), subjectCarHolder: Maybe<(
        { __typename?: 'InsuredSubject' }
        & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
        & { cityPart: Maybe<(
          { __typename?: 'CityPart' }
          & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
        )>, address: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, correspondenceAddress: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, functionOfRepresentative: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )> }
      )>, subjectCarOwner: Maybe<(
        { __typename?: 'InsuredSubject' }
        & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
        & { cityPart: Maybe<(
          { __typename?: 'CityPart' }
          & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
        )>, address: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, correspondenceAddress: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, functionOfRepresentative: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )> }
      )>, selectedOffer: Maybe<(
        { __typename?: 'VehicleInsuranceProductOffer' }
        & Pick<VehicleInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'paymentFrequency' | 'calculatedPaymentFrequency'>
        & { mtplProduct: Maybe<(
          { __typename?: 'VehicleInsuranceProduct' }
          & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, cascoProduct: Maybe<(
          { __typename?: 'VehicleInsuranceProduct' }
          & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, estimatedPrice: (
          { __typename?: 'VehicleInsuranceEstimatedPrice' }
          & Pick<VehicleInsuranceEstimatedPrice, 'yearly' | 'quarterly' | 'halfYearly' | 'monthly'>
        ) }
      )>, contactPerson: Maybe<(
        { __typename?: 'VehicleInsuranceContactPerson' }
        & Pick<VehicleInsuranceContactPerson, 'name' | 'surname' | 'email' | 'phone' | 'agreementProcessPersonalData' | 'agreementTermsAndConditions'>
      )>, contract: Maybe<(
        { __typename?: 'VehicleInsuranceContract' }
        & Pick<VehicleInsuranceContract, 'id' | 'uuid' | 'payed' | 'paymentUrl'>
        & { price: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'currency'>
        ), annualPrice: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'currency'>
        ), documents: Array<Maybe<(
          { __typename?: 'VehicleInsuranceContractDocument' }
          & Pick<VehicleInsuranceContractDocument, 'id' | 'name' | 'documentType'>
          & { documentCategory: (
            { __typename?: 'CodeBook' }
            & Pick<CodeBook, 'id' | 'label'>
          ), file: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>>, paymentInfo: Maybe<(
          { __typename?: 'VehicleInsuranceContractPaymentInfo' }
          & Pick<VehicleInsuranceContractPaymentInfo, 'account' | 'bankCode' | 'constantSymbol' | 'specificSymbol' | 'variableSymbol' | 'message' | 'paymentUrl'>
          & { amount: Maybe<(
            { __typename?: 'Money' }
            & Pick<Money, 'amount' | 'currency'>
          )>, qrCodeFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, casco: Maybe<(
          { __typename?: 'VehicleInsuranceProduct' }
          & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, mtpl: Maybe<(
          { __typename?: 'VehicleInsuranceProduct' }
          & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )> }
      )>, additionalOptimumRequirements: (
        { __typename?: 'VehicleInsuranceAdditionalRequirementsFromClient' }
        & Pick<VehicleInsuranceAdditionalRequirementsFromClient, 'glass' | 'animalAccident'>
      ) }
    )> }
  ) }
);

export type GetWatchWolfByPropertyInsuranceOrderQueryVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;


export type GetWatchWolfByPropertyInsuranceOrderQuery = (
  { __typename?: 'Query' }
  & { getWatchWolfByPropertyInsuranceOrder: (
    { __typename?: 'WatchWolf' }
    & Pick<WatchWolf, 'uuid' | 'type' | 'phone' | 'preferredEmail' | 'minSaving' | 'commitmentEnd' | 'vertical' | 'subVertical' | 'createdAt' | 'name'>
    & { energoCalculation: Maybe<(
      { __typename?: 'EnergoCalculation' }
      & Pick<EnergoCalculation, 'id' | 'uuid' | 'type' | 'source' | 'yearlyConsumption' | 'yearlyConsumptionUnit' | 'yearlyConsumptionLowTariff' | 'yearlyConsumptionLowTariffUnit' | 'yearlyPayment' | 'gasYearlyPayment' | 'gasYearlyConsumption' | 'gasYearlyConsumptionUnit' | 'gasMonthlyAdvances' | 'gasUnknownMonthlyAdvances' | 'monthlyAdvances' | 'unknownMonthlyAdvances' | 'email' | 'saving' | 'createdAt' | 'updatedAt' | 'lastViewed' | 'currentProductId' | 'currentProductName' | 'currentProductFollowingId' | 'unknownCurrentProduct' | 'gasCurrentProductId' | 'gasCurrentProductName' | 'gasCurrentProductFollowingId' | 'gasUnknownCurrentProduct' | 'phone' | 'individualPriceExpectedMonthly' | 'individualPriceExpectedMWhHighTariff' | 'individualPriceExpectedMWhLowTariff' | 'gasIndividualPriceExpectedMonthly' | 'gasIndividualPriceExpectedMWh' | 'individualOfferName' | 'reason'>
      & { address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, partner: Maybe<(
        { __typename?: 'EnergoElectricityPartner' }
        & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      )>, gasPartner: Maybe<(
        { __typename?: 'EnergoGasPartner' }
        & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      )>, gasUsages: Maybe<(
        { __typename?: 'GasUsages' }
        & Pick<GasUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
      )>, electricityUsages: Maybe<(
        { __typename?: 'ElectricityUsages' }
        & Pick<ElectricityUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
      )>, floorAreaRange: Maybe<(
        { __typename?: 'EnergoFloorAreaRangeCodebook' }
        & Pick<EnergoFloorAreaRangeCodebook, 'id'>
        & { label: EnergoFloorAreaRangeCodebook['name'] }
      )>, isolation: Maybe<(
        { __typename?: 'EnergoIsolationCodebook' }
        & Pick<EnergoIsolationCodebook, 'id'>
        & { label: EnergoIsolationCodebook['name'] }
      )>, personsCountRange: Maybe<(
        { __typename?: 'EnergoPersonsCountRangeCodebook' }
        & Pick<EnergoPersonsCountRangeCodebook, 'id'>
        & { label: EnergoPersonsCountRangeCodebook['name'] }
      )>, distributionRate: Maybe<(
        { __typename?: 'EnergoDistributionRate' }
        & Pick<EnergoDistributionRate, 'id' | 'name' | 'lowTariff'>
      )>, breaker: Maybe<(
        { __typename?: 'EnergoBreaker' }
        & Pick<EnergoBreaker, 'id' | 'name'>
      )>, standaloneInvoice: Maybe<(
        { __typename?: 'EnergoStandaloneInvoice' }
        & Pick<EnergoStandaloneInvoice, 'id' | 'uuid'>
      )>, bestResultItem: Maybe<(
        { __typename?: 'EnergoElectricityResultItem' }
        & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
        & { partner: (
          { __typename?: 'EnergoElectricityPartner' }
          & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
          & { rating: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), product: (
          { __typename?: 'EnergoElectricityProduct' }
          & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
        ), priceExpectedDetail: (
          { __typename?: 'EnergoElectricityResultPriceDetail' }
          & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ), priceResultDetail: (
          { __typename?: 'EnergoElectricityResultPriceDetail' }
          & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ) }
      )>, bestGasResultItem: Maybe<(
        { __typename?: 'EnergoGasResultItem' }
        & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
        & { partner: (
          { __typename?: 'EnergoGasPartner' }
          & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
          & { rating: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), product: (
          { __typename?: 'EnergoGasProduct' }
          & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
        ), priceExpectedDetail: (
          { __typename?: 'EnergoGasResultPriceDetail' }
          & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ), priceResultDetail: (
          { __typename?: 'EnergoGasResultPriceDetail' }
          & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ) }
      )> }
    )>, vehicleInsuranceOrder: Maybe<(
      { __typename?: 'VehicleInsuranceOrder' }
      & Pick<VehicleInsuranceOrder, 'id' | 'uuid' | 'createdAt' | 'status' | 'packagesInsuranceType' | 'selectedInsuranceType' | 'insurableInterest' | 'resultEmail' | 'phone' | 'calculationCurrentStep' | 'orderCurrentStep' | 'updatedAt' | 'hasSpecificPackages' | 'beginDate'>
      & { vehicle: (
        { __typename?: 'InsuredVehicle' }
        & Pick<InsuredVehicle, 'uuid' | 'vehicleType' | 'registrationNumber' | 'vin' | 'kilometersTotal' | 'ownedMoreThanAYear' | 'newVehicle' | 'contractualCarService' | 'vehicleValue' | 'vehicleValueWithVat' | 'firstRegistrationDate' | 'numberOfRegistrationCertificate' | 'vehicleSearchStatus'>
        & { expectedKilometersPerYear: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, manufacturer: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, fuel: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, engineDisplacement: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, maxPower: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, countOfSeats: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, maxWeight: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, modelLine: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, model: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, modelType: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, security: (
          { __typename?: 'VehicleSecurity' }
          & Pick<VehicleSecurity, 'none' | 'mechanical' | 'passiveSearch' | 'activeSearch' | 'glassVin' | 'immobilizer' | 'alarm'>
        ), carUsageMethod: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, additionalEquipment: Array<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )> }
      ), subjectInsured: (
        { __typename?: 'InsuredSubject' }
        & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
        & { cityPart: Maybe<(
          { __typename?: 'CityPart' }
          & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
        )>, address: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, correspondenceAddress: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, functionOfRepresentative: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )> }
      ), subjectCarHolder: Maybe<(
        { __typename?: 'InsuredSubject' }
        & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
        & { cityPart: Maybe<(
          { __typename?: 'CityPart' }
          & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
        )>, address: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, correspondenceAddress: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, functionOfRepresentative: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )> }
      )>, subjectCarOwner: Maybe<(
        { __typename?: 'InsuredSubject' }
        & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
        & { cityPart: Maybe<(
          { __typename?: 'CityPart' }
          & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
        )>, address: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, correspondenceAddress: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, functionOfRepresentative: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )> }
      )>, selectedOffer: Maybe<(
        { __typename?: 'VehicleInsuranceProductOffer' }
        & Pick<VehicleInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'paymentFrequency' | 'calculatedPaymentFrequency'>
        & { mtplProduct: Maybe<(
          { __typename?: 'VehicleInsuranceProduct' }
          & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, cascoProduct: Maybe<(
          { __typename?: 'VehicleInsuranceProduct' }
          & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, estimatedPrice: (
          { __typename?: 'VehicleInsuranceEstimatedPrice' }
          & Pick<VehicleInsuranceEstimatedPrice, 'yearly' | 'quarterly' | 'halfYearly' | 'monthly'>
        ) }
      )>, contactPerson: Maybe<(
        { __typename?: 'VehicleInsuranceContactPerson' }
        & Pick<VehicleInsuranceContactPerson, 'name' | 'surname' | 'email' | 'phone' | 'agreementProcessPersonalData' | 'agreementTermsAndConditions'>
      )>, contract: Maybe<(
        { __typename?: 'VehicleInsuranceContract' }
        & Pick<VehicleInsuranceContract, 'id' | 'uuid' | 'payed' | 'paymentUrl'>
        & { price: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'currency'>
        ), annualPrice: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'currency'>
        ), documents: Array<Maybe<(
          { __typename?: 'VehicleInsuranceContractDocument' }
          & Pick<VehicleInsuranceContractDocument, 'id' | 'name' | 'documentType'>
          & { documentCategory: (
            { __typename?: 'CodeBook' }
            & Pick<CodeBook, 'id' | 'label'>
          ), file: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>>, paymentInfo: Maybe<(
          { __typename?: 'VehicleInsuranceContractPaymentInfo' }
          & Pick<VehicleInsuranceContractPaymentInfo, 'account' | 'bankCode' | 'constantSymbol' | 'specificSymbol' | 'variableSymbol' | 'message' | 'paymentUrl'>
          & { amount: Maybe<(
            { __typename?: 'Money' }
            & Pick<Money, 'amount' | 'currency'>
          )>, qrCodeFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, casco: Maybe<(
          { __typename?: 'VehicleInsuranceProduct' }
          & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, mtpl: Maybe<(
          { __typename?: 'VehicleInsuranceProduct' }
          & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )> }
      )>, additionalOptimumRequirements: (
        { __typename?: 'VehicleInsuranceAdditionalRequirementsFromClient' }
        & Pick<VehicleInsuranceAdditionalRequirementsFromClient, 'glass' | 'animalAccident'>
      ) }
    )> }
  ) }
);

export type GetWatchWolfByVehicleInsuranceOrderQueryVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;


export type GetWatchWolfByVehicleInsuranceOrderQuery = (
  { __typename?: 'Query' }
  & { getWatchWolfByVehicleInsuranceOrder: (
    { __typename?: 'WatchWolf' }
    & Pick<WatchWolf, 'uuid' | 'type' | 'phone' | 'preferredEmail' | 'minSaving' | 'commitmentEnd' | 'vertical' | 'subVertical' | 'createdAt' | 'name'>
    & { energoCalculation: Maybe<(
      { __typename?: 'EnergoCalculation' }
      & Pick<EnergoCalculation, 'id' | 'uuid' | 'type' | 'source' | 'yearlyConsumption' | 'yearlyConsumptionUnit' | 'yearlyConsumptionLowTariff' | 'yearlyConsumptionLowTariffUnit' | 'yearlyPayment' | 'gasYearlyPayment' | 'gasYearlyConsumption' | 'gasYearlyConsumptionUnit' | 'gasMonthlyAdvances' | 'gasUnknownMonthlyAdvances' | 'monthlyAdvances' | 'unknownMonthlyAdvances' | 'email' | 'saving' | 'createdAt' | 'updatedAt' | 'lastViewed' | 'currentProductId' | 'currentProductName' | 'currentProductFollowingId' | 'unknownCurrentProduct' | 'gasCurrentProductId' | 'gasCurrentProductName' | 'gasCurrentProductFollowingId' | 'gasUnknownCurrentProduct' | 'phone' | 'individualPriceExpectedMonthly' | 'individualPriceExpectedMWhHighTariff' | 'individualPriceExpectedMWhLowTariff' | 'gasIndividualPriceExpectedMonthly' | 'gasIndividualPriceExpectedMWh' | 'individualOfferName' | 'reason'>
      & { address: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
        & { extended: Maybe<(
          { __typename?: 'SmartAddressExtendedInfo' }
          & Pick<SmartAddressExtendedInfo, 'id'>
          & { objectType: Maybe<(
            { __typename?: 'SmartAddressObjectType' }
            & Pick<SmartAddressObjectType, 'id' | 'nazev'>
          )> }
        )>, extendedV2: Maybe<(
          { __typename?: 'SmartAddressSdkExtendedInfo' }
          & { administrativniUdaje: Maybe<(
            { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
            & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )> }
        )> }
      )>, partner: Maybe<(
        { __typename?: 'EnergoElectricityPartner' }
        & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      )>, gasPartner: Maybe<(
        { __typename?: 'EnergoGasPartner' }
        & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
        & { rating: Maybe<(
          { __typename?: 'NewRating' }
          & Pick<NewRating, 'id' | 'total'>
          & { categories: Array<Maybe<(
            { __typename?: 'RatingCategory' }
            & Pick<RatingCategory, 'id' | 'label' | 'value'>
          )>> }
        )> }
      )>, gasUsages: Maybe<(
        { __typename?: 'GasUsages' }
        & Pick<GasUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
      )>, electricityUsages: Maybe<(
        { __typename?: 'ElectricityUsages' }
        & Pick<ElectricityUsages, 'basicAppliances' | 'heating' | 'waterHeating'>
      )>, floorAreaRange: Maybe<(
        { __typename?: 'EnergoFloorAreaRangeCodebook' }
        & Pick<EnergoFloorAreaRangeCodebook, 'id'>
        & { label: EnergoFloorAreaRangeCodebook['name'] }
      )>, isolation: Maybe<(
        { __typename?: 'EnergoIsolationCodebook' }
        & Pick<EnergoIsolationCodebook, 'id'>
        & { label: EnergoIsolationCodebook['name'] }
      )>, personsCountRange: Maybe<(
        { __typename?: 'EnergoPersonsCountRangeCodebook' }
        & Pick<EnergoPersonsCountRangeCodebook, 'id'>
        & { label: EnergoPersonsCountRangeCodebook['name'] }
      )>, distributionRate: Maybe<(
        { __typename?: 'EnergoDistributionRate' }
        & Pick<EnergoDistributionRate, 'id' | 'name' | 'lowTariff'>
      )>, breaker: Maybe<(
        { __typename?: 'EnergoBreaker' }
        & Pick<EnergoBreaker, 'id' | 'name'>
      )>, standaloneInvoice: Maybe<(
        { __typename?: 'EnergoStandaloneInvoice' }
        & Pick<EnergoStandaloneInvoice, 'id' | 'uuid'>
      )>, bestResultItem: Maybe<(
        { __typename?: 'EnergoElectricityResultItem' }
        & Pick<EnergoElectricityResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
        & { partner: (
          { __typename?: 'EnergoElectricityPartner' }
          & Pick<EnergoElectricityPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'estimateDefaultProductId' | 'energoPartnerId' | 'termsOfServicePreview'>
          & { rating: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), product: (
          { __typename?: 'EnergoElectricityProduct' }
          & Pick<EnergoElectricityProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
        ), priceExpectedDetail: (
          { __typename?: 'EnergoElectricityResultPriceDetail' }
          & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ), priceResultDetail: (
          { __typename?: 'EnergoElectricityResultPriceDetail' }
          & Pick<EnergoElectricityResultPriceDetail, 'priceResult' | 'priceProductMWhLT' | 'priceProductMWhHT' | 'priceProductMonthly' | 'priceDistributionMWhLT' | 'priceDistributionMWhHT' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ) }
      )>, bestGasResultItem: Maybe<(
        { __typename?: 'EnergoGasResultItem' }
        & Pick<EnergoGasResultItem, 'id' | 'priceCurrent' | 'priceExpected' | 'priceCalculatedAnnually' | 'priceCalculatedMonthly' | 'saving' | 'savingEntirePeriod' | 'discountToInvoice' | 'discountToInvoiceWithoutVat' | 'kalkulatorTip' | 'maxSaving' | 'entirePeriodSavingIsMax'>
        & { partner: (
          { __typename?: 'EnergoGasPartner' }
          & Pick<EnergoGasPartner, 'id' | 'name' | 'seoName' | 'logoUrl' | 'logoSquareUrl' | 'descriptionMD' | 'termsOfServicePreview' | 'estimateDefaultProductId' | 'energoPartnerId'>
          & { rating: Maybe<(
            { __typename?: 'NewRating' }
            & Pick<NewRating, 'id' | 'total'>
            & { categories: Array<Maybe<(
              { __typename?: 'RatingCategory' }
              & Pick<RatingCategory, 'id' | 'label' | 'value'>
            )>> }
          )> }
        ), product: (
          { __typename?: 'EnergoGasProduct' }
          & Pick<EnergoGasProduct, 'id' | 'name' | 'shortName' | 'gift' | 'bonus' | 'descriptionMD' | 'partnerTransition' | 'isActiveForPurchase' | 'isActive' | 'productInfoListBasic' | 'productInfoListExtended' | 'lengthOfFixation' | 'lengthOfCommitment' | 'followingProductId' | 'fixedEndOfCommitment' | 'fixedEndOfFixation' | 'private' | 'preferredSale' | 'availableForSale' | 'label' | 'labelTooltip'>
        ), priceExpectedDetail: (
          { __typename?: 'EnergoGasResultPriceDetail' }
          & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ), priceResultDetail: (
          { __typename?: 'EnergoGasResultPriceDetail' }
          & Pick<EnergoGasResultPriceDetail, 'priceResult' | 'priceProductMWh' | 'priceProductMonthly' | 'priceDistributionMWh' | 'priceDistributionMonthly' | 'yearlyDistributionPrice' | 'yearlyPartnerPrice'>
        ) }
      )> }
    )>, vehicleInsuranceOrder: Maybe<(
      { __typename?: 'VehicleInsuranceOrder' }
      & Pick<VehicleInsuranceOrder, 'id' | 'uuid' | 'createdAt' | 'status' | 'packagesInsuranceType' | 'selectedInsuranceType' | 'insurableInterest' | 'resultEmail' | 'phone' | 'calculationCurrentStep' | 'orderCurrentStep' | 'updatedAt' | 'hasSpecificPackages' | 'beginDate'>
      & { vehicle: (
        { __typename?: 'InsuredVehicle' }
        & Pick<InsuredVehicle, 'uuid' | 'vehicleType' | 'registrationNumber' | 'vin' | 'kilometersTotal' | 'ownedMoreThanAYear' | 'newVehicle' | 'contractualCarService' | 'vehicleValue' | 'vehicleValueWithVat' | 'firstRegistrationDate' | 'numberOfRegistrationCertificate' | 'vehicleSearchStatus'>
        & { expectedKilometersPerYear: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, manufacturer: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, fuel: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, engineDisplacement: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, maxPower: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, countOfSeats: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, maxWeight: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, modelLine: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, model: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, modelType: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, security: (
          { __typename?: 'VehicleSecurity' }
          & Pick<VehicleSecurity, 'none' | 'mechanical' | 'passiveSearch' | 'activeSearch' | 'glassVin' | 'immobilizer' | 'alarm'>
        ), carUsageMethod: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )>, additionalEquipment: Array<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )> }
      ), subjectInsured: (
        { __typename?: 'InsuredSubject' }
        & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
        & { cityPart: Maybe<(
          { __typename?: 'CityPart' }
          & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
        )>, address: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, correspondenceAddress: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, functionOfRepresentative: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )> }
      ), subjectCarHolder: Maybe<(
        { __typename?: 'InsuredSubject' }
        & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
        & { cityPart: Maybe<(
          { __typename?: 'CityPart' }
          & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
        )>, address: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, correspondenceAddress: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, functionOfRepresentative: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )> }
      )>, subjectCarOwner: Maybe<(
        { __typename?: 'InsuredSubject' }
        & Pick<InsuredSubject, 'uuid' | 'name' | 'surname' | 'birthNumber' | 'companyIdNumber' | 'companyName' | 'taxIdNumber' | 'subjectType' | 'email' | 'phone' | 'nameOfRepresentative' | 'surnameOfRepresentative'>
        & { cityPart: Maybe<(
          { __typename?: 'CityPart' }
          & Pick<CityPart, 'cityPartId' | 'cityId' | 'cityName' | 'cityPartName' | 'zip'>
        )>, address: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, correspondenceAddress: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'id' | 'uniqueId' | 'smartAddressId' | 'street' | 'descriptiveNumber' | 'orientationNumber' | 'orientationNumberCharacter' | 'city' | 'zip' | 'cityPart' | 'district' | 'region' | 'level'>
          & { extended: Maybe<(
            { __typename?: 'SmartAddressExtendedInfo' }
            & Pick<SmartAddressExtendedInfo, 'id'>
            & { objectType: Maybe<(
              { __typename?: 'SmartAddressObjectType' }
              & Pick<SmartAddressObjectType, 'id' | 'nazev'>
            )> }
          )>, extendedV2: Maybe<(
            { __typename?: 'SmartAddressSdkExtendedInfo' }
            & { administrativniUdaje: Maybe<(
              { __typename?: 'SmartAddressAddressExtendedAdministrativeResponse' }
              & Pick<SmartAddressAddressExtendedAdministrativeResponse, 'id'>
              & { objectType: Maybe<(
                { __typename?: 'SmartAddressObjectType' }
                & Pick<SmartAddressObjectType, 'id' | 'nazev'>
              )> }
            )> }
          )> }
        )>, functionOfRepresentative: Maybe<(
          { __typename?: 'CodeBook' }
          & Pick<CodeBook, 'id' | 'label'>
        )> }
      )>, selectedOffer: Maybe<(
        { __typename?: 'VehicleInsuranceProductOffer' }
        & Pick<VehicleInsuranceProductOffer, 'id' | 'price' | 'insuranceType' | 'paymentFrequency' | 'calculatedPaymentFrequency'>
        & { mtplProduct: Maybe<(
          { __typename?: 'VehicleInsuranceProduct' }
          & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, cascoProduct: Maybe<(
          { __typename?: 'VehicleInsuranceProduct' }
          & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, estimatedPrice: (
          { __typename?: 'VehicleInsuranceEstimatedPrice' }
          & Pick<VehicleInsuranceEstimatedPrice, 'yearly' | 'quarterly' | 'halfYearly' | 'monthly'>
        ) }
      )>, contactPerson: Maybe<(
        { __typename?: 'VehicleInsuranceContactPerson' }
        & Pick<VehicleInsuranceContactPerson, 'name' | 'surname' | 'email' | 'phone' | 'agreementProcessPersonalData' | 'agreementTermsAndConditions'>
      )>, contract: Maybe<(
        { __typename?: 'VehicleInsuranceContract' }
        & Pick<VehicleInsuranceContract, 'id' | 'uuid' | 'payed' | 'paymentUrl'>
        & { price: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'currency'>
        ), annualPrice: (
          { __typename?: 'Money' }
          & Pick<Money, 'amount' | 'currency'>
        ), documents: Array<Maybe<(
          { __typename?: 'VehicleInsuranceContractDocument' }
          & Pick<VehicleInsuranceContractDocument, 'id' | 'name' | 'documentType'>
          & { documentCategory: (
            { __typename?: 'CodeBook' }
            & Pick<CodeBook, 'id' | 'label'>
          ), file: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>>, paymentInfo: Maybe<(
          { __typename?: 'VehicleInsuranceContractPaymentInfo' }
          & Pick<VehicleInsuranceContractPaymentInfo, 'account' | 'bankCode' | 'constantSymbol' | 'specificSymbol' | 'variableSymbol' | 'message' | 'paymentUrl'>
          & { amount: Maybe<(
            { __typename?: 'Money' }
            & Pick<Money, 'amount' | 'currency'>
          )>, qrCodeFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, casco: Maybe<(
          { __typename?: 'VehicleInsuranceProduct' }
          & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )>, mtpl: Maybe<(
          { __typename?: 'VehicleInsuranceProduct' }
          & Pick<VehicleInsuranceProduct, 'id' | 'name' | 'shortName' | 'infoList' | 'extendedInfoList' | 'descriptionMD' | 'protectionCover' | 'cascoParticipationPercent' | 'cascoParticipationMinimum' | 'type' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'gift' | 'bonus' | 'label' | 'labelTooltip' | 'glassRequirementInfoList' | 'animalAccidentRequirementInfoList'>
          & { partner: (
            { __typename?: 'ZeteoInsurancePartner' }
            & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
            & { logo: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, logoSquare: Maybe<(
              { __typename?: 'File' }
              & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
            )>, rating2: Maybe<(
              { __typename?: 'NewRating' }
              & Pick<NewRating, 'id' | 'total'>
              & { categories: Array<Maybe<(
                { __typename?: 'RatingCategory' }
                & Pick<RatingCategory, 'id' | 'label' | 'value'>
              )>> }
            )> }
          ), productDetailFile: Maybe<(
            { __typename?: 'File' }
            & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
          )> }
        )> }
      )>, additionalOptimumRequirements: (
        { __typename?: 'VehicleInsuranceAdditionalRequirementsFromClient' }
        & Pick<VehicleInsuranceAdditionalRequirementsFromClient, 'glass' | 'animalAccident'>
      ) }
    )> }
  ) }
);

export type ZeteoStateFragment = (
  { __typename?: 'ZeteoState' }
  & Pick<ZeteoState, 'maximumSteps' | 'finishedSteps' | 'finished' | 'error'>
);

export type ZeteoInsurancePartnerFragment = (
  { __typename?: 'ZeteoInsurancePartner' }
  & Pick<ZeteoInsurancePartner, 'id' | 'name' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'tollFreePhone' | 'companyAddress' | 'contactEmail' | 'web'>
  & { logo: Maybe<(
    { __typename?: 'File' }
    & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
  )>, logoSquare: Maybe<(
    { __typename?: 'File' }
    & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
  )>, rating2: Maybe<(
    { __typename?: 'NewRating' }
    & Pick<NewRating, 'id' | 'total'>
    & { categories: Array<Maybe<(
      { __typename?: 'RatingCategory' }
      & Pick<RatingCategory, 'id' | 'label' | 'value'>
    )>> }
  )> }
);

export type ZeteoInsurancePartnerDetailFragment = (
  { __typename?: 'ZeteoInsurancePartnerDetail' }
  & Pick<ZeteoInsurancePartnerDetail, 'id' | 'name' | 'seoName' | 'descriptionMD' | 'active' | 'tollFreePhone' | 'web' | 'validTo' | 'validFrom' | 'contactEmail' | 'urlPricelist' | 'urlVop' | 'companyId' | 'companyAddress' | 'longArticleMD' | 'latitude' | 'longitude' | 'order' | 'title' | 'metaTitle' | 'metaDescription' | 'titleSuffix' | 'internalLinkLabel1' | 'internalLinkLabel2' | 'internalLinkLabel3' | 'internalLinkUrl1' | 'internalLinkUrl2' | 'internalLinkUrl3'>
  & { logo: Maybe<(
    { __typename?: 'File' }
    & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
  )>, logoSquare: Maybe<(
    { __typename?: 'File' }
    & Pick<File, 'id' | 'uuid' | 'type' | 'extension' | 'namespace' | 'name'>
  )>, faqs: Array<(
    { __typename?: 'Faq' }
    & Pick<Faq, 'id' | 'question' | 'answerMD'>
  )>, rating2: Maybe<(
    { __typename?: 'NewRating' }
    & Pick<NewRating, 'id' | 'total'>
    & { categories: Array<Maybe<(
      { __typename?: 'RatingCategory' }
      & Pick<RatingCategory, 'id' | 'label' | 'value'>
    )>> }
  )> }
);
